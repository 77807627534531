<template>
  <div>
    <h3 v-if="showError">Something went wrong... please email support@shrpa.com</h3>
    <h3 v-else>Redirecting... hold tight!</h3>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { RouteHelper } from "@helpers/RouteHelper";
import { inject } from "vue";

export default defineComponent({
  name: "Redirect",

  data() {
    return {
      globalLog: inject("globalLog") as any,

      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      showError: false,
    };
  },

  async mounted() {
    this.redirect();
  },

  methods: {
    redirect() {
      // Check if there's a variable to store
      var joincommunity = RouteHelper.getQueryStringParam("joincommunity");
      var referredByUserId = RouteHelper.getQueryStringParam("referredByUserId");
      var redirectUri = null;
      if (joincommunity) {
        this.globalLog.info(`storing joincommunity=${joincommunity}`);
        localStorage.setItem("joincommunity", joincommunity);
        redirectUri = "https://explore.shrpa.com/community-engagement";
      } else if (referredByUserId) {
        this.globalLog.info(`storing referredByUserId=${referredByUserId}`);
        localStorage.setItem("referredByUserId", referredByUserId);
        // Note: Doing a redirect to the root to ensure auth
        // fires after the localStorage.setItem call above.
        // If the user isn't logged in this sits in localStorage until they do
        redirectUri = window.location.origin;
      }

      if (redirectUri !== null) {
        window.location.replace(redirectUri);
      } else {
        this.showError = true;
      }
    },
  },
});
</script>

<style scoped>
@media screen and (min-width: 1024px) {
  #sherpa-column {
    margin-bottom: 8em;
  }

  #meet-sherpa-button {
    position: absolute;
    bottom: -55px;
    right: 25%;
    left: 20%;
    width: 60%;
  }
}
</style>
