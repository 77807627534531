<template>
  <div
    :class="{
      'social-buttons-row': true,
      'social-buttons-row--edit-mode': props.isEditProfileMode,
    }"
  >
    <RequiredFieldNote v-if="props.enableRequiredFieldNote && !socialsLength" class="social-buttons-row__error-field-note">recommend at least 1</RequiredFieldNote>

    <div
      :class="{
        'social-buttons-row__buttons-wrap': true,
        'social-buttons-row__buttons-wrap--error': socialsLength < 1,
        'social-buttons-row__buttons-wrap--edit-mode': props.isEditProfileMode,
      }"
    >
      <input
        v-if="props.isEditProfileMode && !props.profileToBind.instagramHandle"
        class="global-text-input global-text-input--instagram-icon"
        type="text"
        v-model="_instagramHandle"
        placeholder="Instagram handle"
        style="margin-right: 10px"
        @blur="
          () => {
            props.profileToBind.instagramHandle = _instagramHandle;
            _instagramHandle = '';
            emit('saveUserProfile');
          }
        "
      />

      <SocialMediaLinksBar
        v-if="socialsLength"
        :class="{
          'social-buttons-row__media-links-bar': true,
          'social-buttons-row__media-links-bar--with-right-margin': props.isEditProfileMode,
        }"
        :socialFields="props.profileToBind"
        :isWithSeparateEditButtons="props.isEditProfileMode"
        @editField="
          fieldName => {
            isSingleSocialFieldModalVisible = true;
            currentlyEditedField = fieldName;
          }
        "
      />

      <!--<SrpButton v-if="props.isEditProfileMode" class="social-buttons-row__edit-button" size="tiny" fill="outlined" @click="isSocialFieldsModalVisible = true">-->
      <!--  <template #icon>-->
      <!--    <IconEmbedded :name="socialsLength ? 'pencil_2' : 'plus_3'" style="margin-left: -3px" :size="21" color="rgba(84, 148, 150, 1)" />-->
      <!--  </template>-->
      <!--  {{ socialsLength ? "Edit" : "Add another social" }}-->
      <!--</SrpButton>-->

      <template v-if="props.isEditProfileMode">
        <SrpButton
          class="social-buttons-row__edit-button"
          style="margin-right: 8px"
          v-for="socialName in socialNames.filter(s => {
            const emptyFields = [];
            if (!props.profileToBind.facebookURL) emptyFields.push('Facebook');
            if (!props.profileToBind.youtubeURL) emptyFields.push('YouTube');
            if (!props.profileToBind.tikTokHandle) emptyFields.push('TikTok');
            if (!props.profileToBind.twitterHandle) emptyFields.push('Twitter/X');

            return emptyFields.includes(s);
          })"
          :key="socialName"
          size="tiny"
          fill="outlined"
          @click="
            () => {
              currentlyEditedField = {
                'Twitter/X': 'twitterHandle',
                Facebook: 'facebookURL',
                YouTube: 'youtubeURL',
                TikTok: 'tikTokHandle',
              }[socialName];
              isSingleSocialFieldModalVisible = true;
            }
          "
        >
          Add
          <template #iconRight>
            <IconEmbedded
              :name="
                {
                  Facebook: 'facebook',
                  YouTube: 'youtube',
                  TikTok: 'tiktok',
                  'Twitter/X': 'xwitter',
                }[socialName]
              "
              style="margin: 0 -5px 0 -3px"
              :size="21"
              color="rgba(84, 148, 150, 1)"
            />
          </template>
        </SrpButton>
      </template>
    </div>

    <SrpModal v-model:isVisible="isSingleSocialFieldModalVisible" size="small">
      <template #content>
        <SocialMediaInputs :socialFields="props.profileToBind" :isPersonalWebsiteFieldsVisible="false" :showOnly="currentlyEditedField" />
      </template>
      <template #footer>
        <SrpButton @click="isSingleSocialFieldModalVisible = false" style="margin-right: 12px" fill="outlined" color="gray">Cancel</SrpButton>
        <SrpButton @click="isSingleSocialFieldModalVisible = false">
          <template #icon>
            <IconEmbedded name="save_2" style="margin-right: 1px" :size="23" color="rgba(255, 255, 255, 0.4)" />
          </template>
          Done
        </SrpButton>
      </template>
    </SrpModal>

    <SrpModal v-model:isVisible="isSocialFieldsModalVisible">
      <template #header>
        <h1 class="global-h1">Social Media buttons</h1>
      </template>
      <template #content>
        <SocialMediaInputs :socialFields="props.profileToBind" :isPersonalWebsiteFieldsVisible="false" />
      </template>
      <template #footer>
        <SrpButton @click="isSocialFieldsModalVisible = false" style="margin-right: 12px" fill="outlined" color="gray">Cancel</SrpButton>
        <SrpButton @click="isSocialFieldsModalVisible = false">
          <template #icon>
            <IconEmbedded name="save_2" style="margin-right: 1px" :size="23" color="rgba(255, 255, 255, 0.4)" />
          </template>
          Done
        </SrpButton>
      </template>
    </SrpModal>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import RequiredFieldNote from "@components/ui/RequiredFieldNote.vue";
import SocialMediaInputs from "@components/SocialMediaInputs.vue";
import SocialMediaLinksBar from "@components/SocialMediaLinksBar.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpModal from "@components/ui/SrpModal.vue";

// Types
import { ICreatorProfileViewModel } from "@views/CreatorProfilePublic/creatorProfileViewModel";
import { SocialMediaFieldsAndWebsite } from "@components/SocialMediaInputs.vue";

const props = withDefaults(
  defineProps<{
    isEditProfileMode: boolean;
    enableRequiredFieldNote: boolean;
    profileToBind: ICreatorProfileViewModel | null;
  }>(),
  {
    isEditProfileMode: false,
    enableRequiredFieldNote: false,
    profileToBind: null,
  }
);

const emit = defineEmits<{
  (e: "saveUserProfile"): void;
}>();

// Calc socials length ========================================================
const socialsLength = computed(() => {
  // prettier-ignore
  return [
    props.profileToBind?.instagramHandle,
    props.profileToBind?.facebookURL,
    props.profileToBind?.youtubeURL,
    props.profileToBind?.tikTokHandle,
    props.profileToBind?.twitterHandle
  ].filter(f => Boolean(f)).length;
});

// Toggle SocialFields modal ==================================================
const isSocialFieldsModalVisible = ref(false);

// InstagramHandle local copy =================================================
const _instagramHandle = ref<string>("");

watch(props.profileToBind, () => {
  _instagramHandle.value = props.profileToBind.instagramHandle;
});

// Single social field edit modal =============================================
const socialNames = ["Facebook", "YouTube", "TikTok", "Twitter/X"] as const;
type SocialName = (typeof socialNames)[number];

const isSingleSocialFieldModalVisible = ref<boolean>(false);
const currentlyEditedField = ref<SocialMediaFieldsAndWebsite | null>(null);
</script>

<style scoped lang="scss">
// Social buttons row =========================================================
.social-buttons-row {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__error-field-note {
    margin-bottom: 14px;
  }

  &__buttons-wrap {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 0;

    &::before {
      content: "";
      width: calc(100% + 20px);
      height: calc(100% + 18px);
      box-sizing: border-box;
      border: 1px rgba(180, 180, 180, 1) dashed;
      border-radius: 6px;
      position: absolute;
      inset: -9px auto auto -10px;
      z-index: -1;
      background: #f3f3f3;
      opacity: 0;
    }

    &--edit-mode {
      &::before {
        opacity: 1;
      }
    }

    &--error {
      &::before {
        box-shadow: 0 0 0 5px rgba(188, 73, 73, 0.35);
      }
    }
  }

  &__media-links-bar {
    &--with-right-margin {
      margin-right: 30px;
    }
  }

  &__edit-button {
    &::before,
    &:hover::before {
      background: rgba(255, 255, 255, 1);
    }
  }
}
</style>
