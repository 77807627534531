<template>
  <div
    :class="{
      'transitioned-height-auto': true,
      'transitioned-height-auto--no-margin': !_isOpened,
    }"
    :style="{ transitionDuration: props.transitionDuration + 'ms' }"
  >
    <div
      :class="{
        'transitioned-height-auto__in-wrap-1': true,
        'transitioned-height-auto__in-wrap-1--hidden': !_isOpened,
        'transitioned-height-auto__in-wrap-1--invisible': !_isOpened && !props.minHeight,
      }"
      :style="{
        transitionDuration: props.transitionDuration + 'ms',
        minHeight: props.minHeight ? `${props.minHeight}px` : 'unset',
      }"
    >
      <div
        v-if="props.isKeepAlive || isMounted"
        :class="{
          'transitioned-height-auto__in-wrap-2': true,
          'transitioned-height-auto__in-wrap-2--overflow-visible': !isOverflowHidden,
        }"
        :style="{ justifyContent: { top: 'flex-start', bottom: 'flex-end' }[props.contentVerticalAttachment] }"
      >
        <slot />
        <slot name="scoped" :toggleVisibility="toggleVisibility" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { watch, ref, onMounted } from "vue";

export interface Props {
  isOpened: boolean;
  transitionDuration?: number;
  isKeepAlive?: boolean;
  minHeight?: number | null;
  contentVerticalAttachment?: "top" | "bottom";
}
const props = withDefaults(defineProps<Props>(), {
  isOpened: true,
  transitionDuration: 70,
  isKeepAlive: false,
  minHeight: null,
  contentVerticalAttachment: "bottom",
});

const emit = defineEmits<{
  (e: "update:isOpened", event: boolean): void;
}>();

// Toggle visibility ==========================================================
const _isOpened = ref(false);

onMounted(() => (_isOpened.value = props.isOpened));
watch(
  () => props.isOpened,
  () => (_isOpened.value = props.isOpened)
);

function toggleVisibility(isOpened?: boolean): void {
  if (typeof isOpened === "boolean") _isOpened.value = isOpened;
  else _isOpened.value = !_isOpened.value;
}

// Unmount slot content on close ==============================================
const isMounted = ref(false);

onMounted(() => (isMounted.value = _isOpened.value));

let unmountTimeout = null;
watch(
  () => _isOpened.value,
  () => {
    clearTimeout(unmountTimeout);
    if (_isOpened.value) isMounted.value = true;
    else unmountTimeout = setTimeout(() => (isMounted.value = false), props.transitionDuration + 50);
  }
);

// Removes overflow hidden when possible ======================================
// it's needed to make position sticky work inside the content
const isOverflowHidden = ref(true);

let setOverflowTimeout = null;
watch(
  () => _isOpened.value,
  () => {
    clearTimeout(setOverflowTimeout);

    if (_isOpened.value) {
      setOverflowTimeout = setTimeout(() => (isOverflowHidden.value = false), props.transitionDuration + 50);
    } else {
      isOverflowHidden.value = true;
    }
  }
);
</script>

<style scoped lang="scss">
// Transitioned height auto ===================================================
.transitioned-height-auto {
  transition:
    padding 0.07s ease-in-out,
    margin 0.07s ease-in-out,
    border-width 0.07s ease-in-out;

  &--no-margin {
    border-width: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin: 0 !important;
  }

  &__in-wrap-1 {
    width: 100%;
    display: grid;
    grid-template-rows: 1fr;
    transition:
      grid-template-rows 0.07s ease-in-out,
      opacity 0.07s ease-in-out;

    &--hidden {
      grid-template-rows: 0fr;
    }

    &--invisible {
      opacity: 0;
    }
  }

  &__in-wrap-2 {
    grid-row-start: 1;
    grid-row-end: 3;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    &--overflow-visible {
      overflow: visible;
    }
  }
}
</style>
