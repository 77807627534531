<template>
  <i class="embedded-icon">
    <b
      class="embedded-icon__in-wrap"
      :style="{
        width: `${props.size}px`,
        height: `${props.size}px`,
      }"
      v-html="iconSvgCode"
      ref="domRefIcon"
    >
    </b>
    <strong class="embedded-icon__clip-path-bearer-wrap">
      <slot name="clipPathBearer" />
    </strong>
  </i>
</template>

<script setup lang="ts">
import { onMounted, ref, nextTick, watch } from "vue";

// Types
import { IconName } from "@contracts/IconName";

// Stores
import { useIconsStore } from "@stores/icons";
const iconsStore = useIconsStore();

const props = withDefaults(
  defineProps<{
    name: IconName | "";
    size?: number;
    color?: "brandColor" | string;
    clipPathId?: string;
  }>(),
  {
    name: "",
    size: 20,
    color: "rgba(0, 0, 0, 0.2)",
    clipPathId: "",
  }
);

// Load icon ==================================================================
const iconSvgCode = ref<string>("");

async function initIcon() {
  // Update: Don't use the fallback here. It's better for the icon to just appear when loaded than to swap it out.

  if (!props.name) {
    return;
  }

  iconSvgCode.value = await iconsStore.fetchIcon(props.name);
  iconSvgCode.value = iconSvgCode.value.replace(/fill=\"[a-zA-Z#0-9]+\"/gim, "");
  await nextTick();
  setIconColorAndSize();

  await setClipPath();
}

onMounted(async () => {
  await initIcon();
});
watch(() => [props.name, props.size, props.color], initIcon);

// Set color ==================================================================
const domRefIcon = ref<HTMLElement | null>(null);
const domRefPlaceholder = ref<HTMLElement | null>(null);

function setIconColorAndSize() {
  const svgNode = (domRefIcon.value?.childNodes?.[0] instanceof SVGElement ? domRefIcon.value?.childNodes?.[0] : domRefIcon.value?.childNodes?.[1]) as HTMLElement;

  // TODO: use the global brandColor variable
  svgNode?.setAttribute("fill", props.color === "brandColor" ? "rgba(5, 133, 135, 1)" : props.color);
  svgNode?.removeAttribute("width");
  svgNode?.removeAttribute("height");
}

// Set clip path ==============================================================
async function setClipPath() {
  if (!props.clipPathId) {
    return;
  }
  const svgNode = domRefIcon.value.childNodes[0] as HTMLElement;

  svgNode.innerHTML = `<clipPath id="clipPath-${props.clipPathId}">${svgNode.innerHTML}</clipPath>`;
}
</script>

<style scoped lang="scss">
// Embedded icon ==============================================================
.embedded-icon {
  aspect-ratio: 1/1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;

  &__in-wrap {
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  :deep(svg) {
    width: 100%;
    height: 100%;
  }

  &__clip-path-bearer-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: 1;

    :deep(*) {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
