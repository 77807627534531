<template>
  <div class="slider-wrapper">
    <div class="fade-left"></div>
    <div class="arrow-left" :class="{ disabled: position === 0 }" @click.stop="goLeft">
      <i class="angle left icon"></i>
    </div>
    <div class="slider" ref="slider" @scroll.prevent.stop="onSliderScroll">
      <div class="slider-item" v-for="(itinerary, index) in items" :key="index" ref="slides">
        <ItineraryTile :itinerary="itinerary" :source="'home'" :key="itinerary.id" :allowShortImage="false" />
      </div>
    </div>
    <div class="arrow-right" :class="{ disabled: isAtEndPosition }" @click.stop="goRight">
      <i class="angle right icon"></i>
    </div>
  </div>
</template>

<script>
// import { ContentLoader } from "vue-content-loader";
import ItineraryTile from "@components/ItineraryTile.vue";
export default {
  name: "Slider",

  components: {
    // ContentLoader,
    ItineraryTile,
  },

  props: {
    type: String,
    items: Array,
    loaded: { type: Boolean, default: true },
  },

  data() {
    return {
      position: 0,
      isAtEndPosition: false,
      slidesPositions: [],
      loaderTileHeight: 352,
    };
  },
  computed: {
    style() {
      return `height: 360px;`;
    },
  },
  mounted() {},
  methods: {
    onSliderScroll(e) {
      // Ensure the slidesPositions is calculated
      if (!this.slidesPositions) this.slidesPositions = this.$refs.slides.map(s => s.offsetLeft);

      const sliderCurrentPosition = this.$refs.slider.scrollLeft;
      const maxScrollLeft = this.$refs.slider.scrollWidth - this.$refs.slider.clientWidth;
      this.slidesPositions.forEach((slidePosition, index) => {
        if (sliderCurrentPosition >= slidePosition) {
          this.position = index;
        }
      });

      if (sliderCurrentPosition >= maxScrollLeft) {
        this.isAtEndPosition = true;
      } else {
        this.isAtEndPosition = false;
      }
    },
    goRight() {
      const slider = this.$refs.slider;
      const maxScrollLeft = slider.scrollWidth - slider.clientWidth;
      if (slider.scrollLeft < maxScrollLeft) {
        this.position++;
        this.$refs.slider.scrollLeft = this.$refs.slides[this.position].offsetLeft;

        const hasNextSlide = typeof this.$refs.slides[this.position + 1] !== "undefined";
        if (hasNextSlide) {
          const nextSlide = this.$refs.slides[this.position + 1];
          const newOffset = this.$refs.slides[this.position].offsetLeft;
          if (newOffset > maxScrollLeft && nextSlide.offsetLeft >= maxScrollLeft) {
            this.isAtEndPosition = true;
          }
        }
      }
    },
    goLeft() {
      if (this.position === 0) {
        return;
      }
      this.isAtEndPosition = false;
      this.position--;
      this.$refs.slider.scrollLeft = this.$refs.slides[this.position].offsetLeft;
    },
  },
};
</script>
<style scoped lang="scss">
.slider-wrapper {
  position: relative;
}

.arrow-left,
.arrow-right {
  position: absolute;
  z-index: 99;
  font-size: 3rem;
  top: 150px;
  &.community {
    top: 235px;
  }
}

.arrow-left.disabled,
.arrow-right.disabled {
  color: #999;
  cursor: default;
}

.arrow-left:hover,
.arrow-right:hover {
  cursor: pointer;
}

.arrow-left {
  left: -40px;
}

.arrow-right {
  right: -40px;
}

@media screen and (max-width: 767px) {
  .arrow-left,
  .arrow-right {
    display: none;
  }

  .slider {
    padding-left: 1em;
  }
}

.slider {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  width: 100%;
  transition: transform 0.2s ease-in;
  scroll-behavior: smooth;
  /* padding: 0 45px; */
}

.slider::-webkit-scrollbar {
  display: none;
}

.slider-item {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  max-width: 300px;
  margin-left: 1px;
  margin-top: 4px;
  margin-bottom: 3px;
}

.slider-item > .ui.card {
  flex: 1;
}

.slider-item .content {
  display: flex;
  flex-direction: column;
}

.slider-item .content > p {
  /* flex: 1; */
  margin-bottom: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 600;
}

.slider-item .content .bottom {
  display: flex;
  flex-direction: row;
}

.slider-item .content .bottom .location {
  flex: 1;
  color: #4c7b82;
  font-weight: 500;
}

.slider-item .content .bottom .duration {
  font-weight: 100;
}

.slider-item .slider-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.slider-item + .slider-item {
  margin-left: 1rem;
}

.slider-item .card {
  width: 300px;
}

.slider-item .card .content {
  padding: 0.25rem 0.5rem;
  border: 0.5px solid #a7a7a7 !important;
  background-color: #eee;
  box-shadow: none;
}

.slider-item .slider-content > .slider-title {
  margin-bottom: 0.5rem;
  flex-grow: 1;
}

.slider-item .slider-content > .slider-tags > ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.slider-item .slider-content > .slider-tags > ul > li {
  padding: 0;
  margin: 0 5px 0 0;
  display: inline-block;
}

.slider-item .slider-content > .slider-tags > ul > li a {
  border: 1px solid #4c7b82;
  border-radius: 2px;
  padding: 0 5px;
  color: #4c7b82;
  font-size: 10px;
  display: block;
}
</style>
