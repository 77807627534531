<template>
  <div class="portfolio-slide">
    <h2 class="global-h2 portfolio-slide__title">Create adventures to build an impressive portfolio for destinations</h2>

    <div class="portfolio-slide__creator-n-example-photos">
      <!-- Creator snippet -->
      <div class="creator-snippet portfolio-slide__creator-snippet">
        <img class="creator-snippet__avatar" :src="`${contentBaseUri}/cms/images/profiles/620bd5df-d694-488d-a67a-a0afc804c1c6.jpg`" alt="Creator" @load="emit('imageLoad')" />

        <div class="creator-snippet__name-n-about-me">
          <div class="global-h5 creator-snippet__name">Stephanie</div>
          <div class="global-h4 creator-snippet__about-me">I am a travel content creator from Wisconsin who loves to explore off the beaten path!</div>
        </div>
      </div>
      <!-- / Creator snippet -->

      <ul class="portfolio-slide__example-photos-list">
        <SrpFileThumbnail
          class="portfolio-slide__example-photo"
          v-for="image in imagesList"
          :key="image"
          :filePath="`${contentBaseUri}/cms/images/expeditions/thumbnocrop/${image}`"
          imageFit="cover"
          @imageLoad="emit('imageLoad')"
        />
      </ul>
    </div>

    <h2 class="global-h2 portfolio-slide__description">Add your own personal touch to your content to show destinations what it would be like to collaborate with you.</h2>
  </div>
</template>

<script setup lang="ts">
// Components
import SrpButton from "@components/ui/SrpButton.vue";
import SrpFileThumbnail from "@components/ui/SrpFileThumbnail.vue";

// Global variables
const contentBaseUri = globalThis.Bootstrap.Config.contentCdnBaseUri;

const emit = defineEmits<{
  (e: "imageLoad"): void;
}>();

// Images list ================================================================
const imagesList = ["33733b66-6f93-4d79-b1ee-57cd7cf938df.jpg", "4c62ac71-892c-4ae9-b39e-7a2965daae8a.jpg", "e9137b89-4484-4de4-9fbb-b0bbff36517e.jpg", "e3baa2ae-57e4-411f-8292-054711b6ceed.jpg"];
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Creator snippet ============================================================
.creator-snippet {
  display: flex;

  &__avatar {
    width: 50px;
    height: 50px;
    margin-right: 13px;
    border-radius: 200px;
    object-fit: cover;
  }

  &__name-n-about-me {
    width: 230px;
  }

  &__name {
    margin-bottom: 5px;
  }

  &__about-me {
    font-weight: 300;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .creator-snippet {
    flex-direction: column;
    align-items: center;

    &__avatar {
      margin: 0 0 15px 0;
    }

    &__name-n-about-me {
      width: auto;
    }

    &__name {
      text-align: center;
    }

    &__about-me {
      text-align: center;
    }
  }
}

// Portfolio slide ============================================================
.portfolio-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__title {
    margin-bottom: 33px;
    text-align: center;
  }

  &__creator-n-example-photos {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__creator-snippet {
    width: 290px;
    margin-right: 30px;
  }

  &__example-photos-list {
    width: 210px;
    min-width: 210px !important;
    padding: 0;
    margin: 0 0 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  &__example-photo {
    aspect-ratio: 1/1;
    width: calc(50% - 5px);
    margin: 0 10px 10px 0;

    &:nth-child(2n) {
      margin-right: 0;
    }

    &:nth-child(n + 3) {
      margin-bottom: 0;
    }
  }

  &__description {
    font-weight: 400;
    text-align: center;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .portfolio-slide {
    &__title {
      margin-bottom: 15px;
    }

    &__creator-n-example-photos {
      flex-direction: column;
    }

    &__creator-snippet {
      width: 100%;
      margin: 0 0 20px 0;
    }

    &__example-photos-list {
      width: 100%;
      min-width: 100%;
    }

    &__example-photo {
      width: calc(25% - 10px);
      margin: 0 5px 0 !important;
    }
  }
}
</style>
