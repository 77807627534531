<template>
  <ul class="star-rating-scale" @mouseleave="hoveredStarIndex = -1">
    <!-- Star -->
    <li
      class="star star-rating-scale__star"
      v-for="(star, index) in props.starsNumber"
      :key="index"
      @mouseover="hoveredStarIndex = index"
      @click="
        () => {
          emit('update:value', index + 1);
          _value = index + 1;
        }
      "
    >
      <div
        :class="{
          'star__icon-wrap': true,
          'star__icon-wrap--checked': index <= hoveredStarIndex || index <= _value - 1,
        }"
      >
        <IconEmbedded class="star__icon" name="star-solid" :size="24" />
      </div>
    </li>
    <!-- / Star -->
  </ul>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

const props = withDefaults(
  defineProps<{
    radioInputsName: string;
    starsNumber?: number;
    value: number;
  }>(),
  {
    radioInputsName: "",
    starsNumber: 5,
    value: 0,
  }
);

const emit = defineEmits<{
  (e: "update:value", value: number): void;
}>();

// Hovered star index =========================================================
const hoveredStarIndex = ref(-1);

// Local value ================================================================
const _value = ref<number>(0);

onMounted(() => (_value.value = props.value));
watch(
  () => props.value,
  () => (_value.value = props.value)
);

// Change rating ==============================================================
function changeRating(index: number) {
  emit("update:value", index + 1);
}
</script>

<style scoped lang="scss">
// Star =======================================================================
.star {
  width: 26px;
  min-width: 26px;
  height: 26px;
  position: relative;
  cursor: pointer !important;

  &__icon-wrap {
    pointer-events: none;

    :deep(svg) {
      fill: rgba(204, 204, 204, 1);
    }

    &--checked {
      :deep(svg) {
        fill: rgba(255, 220, 35, 1);
        stroke: rgba(208, 186, 105, 1);
        stroke-width: 1.3px;
        stroke-linejoin: round;
      }
    }
  }

  &__icon {
    pointer-events: none;
  }
}

// Star rating scale ==========================================================
.star-rating-scale {
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;

  &__star {
  }
}
</style>
