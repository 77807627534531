<template>
  <div class="sorting-radiobuttons">
    <template v-for="(radiobuttonKey, index) in Object.keys(props.valuesObj)" :key="radiobuttonKey">
      <SrpCheckbox class="sorting-radiobuttons__radiobutton sorting-radiobuttons__radiobutton--highlighted" :isWithHalo="false">
        <template #input>
          <input
            type="radio"
            :name="props.radiobuttonsName"
            :value="props.valuesObj[radiobuttonKey]"
            @change="emit('update:value', props.valuesObj[radiobuttonKey])"
            :checked="props.valuesObj[radiobuttonKey] === props.value"
          />
        </template>
        <template #text>{{ radiobuttonKey }}</template>
      </SrpCheckbox>

      <div v-if="index !== Object.keys(props.valuesObj).length - 1" class="sorting-radiobuttons__divider"></div>
    </template>
  </div>
</template>

<script setup lang="ts">
import generateRandomString from "@helpers/GenerateRandomString";

const props = withDefaults(
  defineProps<{
    valuesObj: { [key: string]: string };
    value: string;
    radiobuttonsName?: string;
  }>(),
  {
    valuesObj: () => ({}),
    value: "",
    radiobuttonsName: () => generateRandomString(20),
  }
);

const emit = defineEmits<{
  (e: "update:value", value: string): void;
}>();

// Components
import SrpCheckbox from "@components/ui/SrpCheckbox.vue";
</script>

<style scoped lang="scss">
// Sorting radiobuttons =======================================================
.sorting-radiobuttons {
  display: flex;
  align-items: center;

  &__radiobutton {
    margin-right: 17px !important;
    text-decoration: underline;
    text-decoration-color: rgba(12, 135, 138, 0.5);
    text-underline-offset: 2px;
    text-decoration-thickness: 1px;
    text-decoration-style: dashed;

    :deep(*) {
      color: rgba(12, 135, 138, 1) !important;
      font-family: sans-serif;
    }

    &:hover {
      text-decoration: none;
    }

    &:last-child {
      margin-right: 0 !important;
    }
  }

  &__divider {
    height: 28px;
    width: 0;
    margin: 0 17px 0 0;
    border-left: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__divider + &__divider {
    display: none;
  }
}
</style>
