<template>
  <div class="build-caption-form">
    <div class="build-caption-form__columns-section">
      <div class="build-caption-form__left-col">
        <template v-if="gptEnabled">
          <SrpButton class="build-caption-form__chat-gpt-button" @click="showGptGenModal">
            <template #icon><IconEmbedded name="ai_2" :size="23" color="rgba(255, 255, 255, 0.6)" /></template>
            Generate a caption with AI
          </SrpButton>

          <!-- "Or" divider -->
          <div class="or-divider build-caption-form__or-divider">
            <div class="or-divider__circle">or</div>
          </div>
          <!-- / "Or" divider -->
        </template>

        <!-- Text chunks spoilers -->
        <div v-if="shareStopSummaryStore.shareableSummaryDetails.adventureTextSummaries.length" class="text-chunks-spoilers build-caption-form__text-chunks-spoilers">
          <div class="global-h4 text-chunks-spoilers__title">Craft a caption for your post:</div>

          <!-- Big spoiler -->
          <SrpDetailsSummary
            class="big-spoiler text-chunks-spoilers__big-spoiler"
            v-for="(adventureSummary, index) in shareStopSummaryStore.shareableSummaryDetails.adventureTextSummaries"
            :key="adventureSummary.adventureId"
            :is-opened="index === 0"
          >
            <template #heading>
              <div class="global-h5 big-spoiler__summary">
                {{ adventureSummary.adventureTitle }}
                <router-link
                  @click.stop
                  target="_blank"
                  :to="{ name: 'ItineraryPreview', params: { itineraryId: adventureSummary.adventureId }, query: { partner: shareStopSummaryStore.editedPost?.customerId } }"
                  class="snippet__icon"
                  style="margin-left: 7px; margin-top: 2px"
                >
                  <IconEmbedded name="external-link_2" :size="17" />
                </router-link>
              </div>
            </template>
            <template #details>
              <div class="big-spoiler__content">
                <!-- Small spoiler -->
                <SrpDetailsSummary v-if="adventureSummary.adventureSummary" class="small-spoiler big-spoiler__small-spoiler" :is-opened="true">
                  <template #heading>
                    <div class="global-h6 small-spoiler__summary">
                      <b>{{ "Summary " }}</b>
                    </div>
                  </template>
                  <template #details>
                    <div class="small-spoiler__content">
                      {{ adventureSummary.adventureSummary }}

                      <CopyButton class="small-spoiler__copy-button" @on-copy="copyTextToTextarea(String(adventureSummary.adventureSummary).trim())" />
                    </div>
                  </template>
                </SrpDetailsSummary>
                <!-- / Small spoiler -->

                <!-- Small spoiler -->
                <SrpDetailsSummary
                  class="small-spoiler big-spoiler__small-spoiler"
                  v-for="(step, idx) in adventureSummary.steps"
                  :key="idx"
                  :is-opened="index === 0 && idx === 0 && !adventureSummary.adventureSummary"
                >
                  <template #heading>
                    <div class="global-h6 small-spoiler__summary">
                      <b>{{ "Step " + (idx + 1) }}</b
                      >: {{ step.locationName }}
                    </div>
                  </template>
                  <template #details>
                    <div class="small-spoiler__content">
                      {{ step.stepDetails }}

                      <CopyButton class="small-spoiler__copy-button" @on-copy="copyTextToTextarea(String(step.stepDetails).trim())" />
                    </div>
                  </template>
                </SrpDetailsSummary>
                <!-- / Small spoiler -->
              </div>
            </template>
          </SrpDetailsSummary>
          <!-- / Big spoiler -->
        </div>
        <!-- / Text chunks spoilers -->

        <SrpButton class="build-caption-form__next-button" @click="saveAndGoToNextSlide" color="orange" :is-disabled="!shareStopSummaryStore.editedPost.caption">
          Next
          <template #iconRight>
            <IconEmbedded v-if="isSaving" name="loader_3-5" color="rgba(255, 255, 255, 0.5)" :size="21" />
            <IconEmbedded v-else name="arrow-bottom_2-5" color="rgba(255, 255, 255, 0.5)" :size="21" />
          </template>
        </SrpButton>
      </div>

      <div class="build-caption-form__right-col">
        <!-- Post preview section -->
        <div class="post-preview-section build-caption-form__post-preview-section">
          <div class="global-h4 post-preview-section__title">Post preview</div>

          <ul class="post-preview-section__tabs-row">
            <!-- Tab with logo -->
            <li
              :class="{
                'tab-with-logo': true,
                'tab-with-logo--active': socialNetwork === postPreviewActiveTab,
                'post-preview-section__tab-with-logo': true,
              }"
              v-for="socialNetwork in ['Facebook', 'Instagram']"
              :key="socialNetwork"
              @click="postPreviewActiveTab = socialNetwork"
            >
              <IconEmbedded
                v-if="socialNetwork === 'Facebook'"
                name="facebook"
                :class="{
                  'tab-with-logo__logo': true,
                  'tab-with-logo__logo--no-right-margin': ['mobile'].includes(screenSize),
                }"
                color="rgba(59, 89, 152, 1)"
              />
              <IconEmbedded
                v-else-if="socialNetwork === 'Instagram'"
                name="instagram"
                :class="{
                  'tab-with-logo__logo': true,
                  'tab-with-logo__logo--no-right-margin': ['mobile'].includes(screenSize),
                  'tab-with-logo__logo--instagram': true,
                }"
                color="rgba(255, 255, 255, 1)"
              />

              <div v-if="!['mobile'].includes(screenSize)" class="tab-with-logo__text">{{ socialNetwork }}</div>
            </li>
            <!-- / Tab with logo -->
          </ul>

          <div class="post-preview-section__post-snippet-wrap">
            <FbPostPreview v-if="postPreviewActiveTab === 'Facebook'" class="post-preview-section__post-snippet" :images-list="shareStopSummaryStore.editedPost.assetIds">
              <template #textarea>
                <textarea class="post-preview-section__post-snippet-textarea" v-model="shareStopSummaryStore.editedPost.caption" ref="textareaFb" placeholder="Caption" />
              </template>
            </FbPostPreview>

            <InstPostPreview v-else-if="postPreviewActiveTab === 'Instagram'" class="post-preview-section__post-snippet" :images-list="shareStopSummaryStore.editedPost.assetIds">
              <template #textarea>
                <textarea class="post-preview-section__post-snippet-textarea" v-model="shareStopSummaryStore.editedPost.caption" ref="textareaInst" placeholder="Caption" />
              </template>
            </InstPostPreview>
          </div>
          <!--<TwPostPreview class="post-preview-section__snippet" />-->
        </div>
        <!-- / Post preview section -->
      </div>
    </div>

    <!-- GPT Caption Testing Modal -->
    <SrpModal :isVisible="showGptModal" @close="genTitleForCollab = null" :is-with-scroll="true" :isClosable="false" className="new-dates-modal-message">
      <template #header><h2 class="global-h2">Generate Title</h2></template>
      <template #content>
        <div style="margin-bottom: 10px">Generate Caption for this post?</div>
        <SrpButton :isDisabled="gptLoading === true" @click="gptCreateCaption" :size="'small'">Generate</SrpButton>
        <select v-model="gptModel" style="margin: 10px">
          <option value="">GPT model</option>
          <option value="gpt-4o">gpt-4o</option>
          <option value="gpt-4o-mini">gpt-4o-mini</option>
          <option value="gpt-3.5-turbo">gpt-3.5-turbo</option>
        </select>
        <b v-if="gptLoading">working...</b>
        <h3 class="ui header">Result</h3>
        <div style="white-space: pre-wrap" v-if="gptResult">
          <div>{{ gptResult.responseText }}</div>
          <div>
            <br />
            <b>Model:</b> {{ gptResult.modelUsed }}<br />
            <b>Prompt:</b> <br />
            <div v-for="prompt in gptResult.inputPrompts">
              {{ prompt }}
            </div>
            <br />
            <b>TokensUsed:</b> {{ gptResult.tokensUsed }} (approx cost: ${{ gptResult.approxCost }})<br />
            <b>Runtime:</b> {{ gptResult.runtimeInMs }}ms<br />
            <b>ErrorCode:</b> {{ gptResult.errorCode }}<br />
          </div>
        </div>
      </template>
      <template #footer>
        <div>
          <SrpButton :isDisabled="gptLoading === true" @click="showGptModal = false" color="gray" fill="outlined" style="margin-right: 10px">Close</SrpButton>
        </div>
      </template>
    </SrpModal>
    <!-- / GPT Caption Testing Modal -->
  </div>
</template>

<!-- prettier-ignore -->
<script lang="ts">export default { name: "BuildYourCaption" };</script>

<script setup lang="ts">
import { ref, nextTick, onMounted, inject, Ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";
import FeatureFlags from "@logic/FeatureFlags";
import type { FlagNames } from "@logic/FeatureFlags";
import SrpModal from "@components/ui/SrpModal.vue";

// Types
import { ScreenSize } from "@contracts/screenSize";

// Stores
import { useShareStopSummaryStore } from "@stores/shareStopSummary";

// Components
import AvatarWithFallback from "@components/AvatarWithFallback.vue";
import CopyButton from "@views/Community/ShareStopSummaryV2/slides/BuildYourCaption/CopyButton.vue";
import SocialButton from "@components/ui/SocialButton.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpDetailsSummary from "@components/ui/SrpDetailsSummary.vue";
import CopyTextNew from "@components/CopyTextNew.vue";
// post previews
import FbPostPreview from "./postPreviews/FbPostPreview.vue";
import InstPostPreview from "./postPreviews/InstPostPreview.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

const router = useRouter();
const route = useRoute();

const screenSize = inject("screenSize") as Ref<ScreenSize>;

const shareStopSummaryStore = useShareStopSummaryStore();

const emit = defineEmits<{
  (e: "goToNextSlide"): void;
}>();

// Switch post preview tabs ===================================================
const postPreviewActiveTab = ref("Facebook");

// Scroll textareas to bottom on text copy event ==============================
const textareaFb = ref(null);
const textareaInst = ref(null);

async function copyTextToTextarea(text: string): Promise<void> {
  if (shareStopSummaryStore.editedPost.caption === null) shareStopSummaryStore.editedPost.caption = "";
  shareStopSummaryStore.editedPost.caption += text;

  await nextTick();
  scrollTextareaToBottom();
}

function scrollTextareaToBottom() {
  let targetTextarea;

  if (textareaFb.value) targetTextarea = textareaFb;
  else if (textareaInst.value) targetTextarea = textareaInst;

  targetTextarea.value.scrollTop = targetTextarea.value.scrollHeight;
}

// Save and go to the next slide ==============================================
const isSaving = ref(false);
async function saveAndGoToNextSlide(): Promise<void> {
  isSaving.value = true;
  await shareStopSummaryStore.updatePost(shareStopSummaryStore.editedPost);

  isSaving.value = false;

  emit("goToNextSlide");
}

// Focus the textarea on load =================================================
onMounted(async () => {
  if (["mobile", "tablet"].includes(screenSize)) return;

  await nextTick();
  if (textareaFb.value) textareaFb.value.focus();
  if (textareaInst.value) textareaInst.value.focus();

  if (FeatureFlags.isFlagActive("SocialCaptionGPT")) {
    gptEnabled.value = true;
  }
});

// GPT Caption Loading ========================================================
let gptEnabled = ref(false);
let showGptModal = ref(false);
let gptLoading = ref(false);
let gptModel = ref("");
let genTitleForCollab = ref(null);
let gptResult = ref(null);

function showGptGenModal() {
  showGptModal.value = true;
  gptResult.value = null;
}

async function gptCreateCaption() {
  if (gptLoading.value === true) return;
  gptLoading.value = true;
  var uri = `${import.meta.env.VITE_API_URL}/social/v2/${shareStopSummaryStore.editedPost.customerId}/posts/${shareStopSummaryStore.editedPost.id}/generate-caption`;
  if (gptModel.value?.length > 0) uri += `?model=${gptModel.value}`;
  let result = await axios.get(uri);
  gptResult.value = result.data;
  gptLoading.value = false;
}
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";
@import "@/scss/mixins/animations/gradient-spin-white.scss";

// "Or" divider ===============================================================
.or-divider {
  height: 46px;
  padding-right: 80px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    width: 100%;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
    height: 0;
    position: absolute;
    inset: 50% auto auto 0;
    z-index: -1;
    font-size: 0;
    line-height: 0;
  }

  &::after {
    content: "";
    width: 125px;
    height: 100%;
    position: absolute;
    inset: 0 0 auto auto;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  }

  &__circle {
    width: 46px;
    min-width: 46px;
    height: 46px;
    padding-bottom: 3px;
    box-sizing: border-box;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font:
      700 19px/19px "Quicksand",
      sans-serif;
    background: #ececec;
  }
}

// Small spoiler ==============================================================
.small-spoiler {
  color: #5b5b5b;
  font: 14px/20px sans-serif;

  &__summary {
    outline: none;
    position: relative;
    color: #000;
    font-weight: 600;
    opacity: 1;
    cursor: pointer;
    transition: opacity 0.07s ease-in-out;

    b {
      font-weight: 700;
    }
  }

  &__content {
    padding: 11px 24px 13px 17px;
    margin: 7px 24px 0 14px;
    border: 1px #b4b4b4 dashed;
    border-radius: 6px;
    position: relative;
    white-space: pre-wrap;
  }

  &__copy-button {
    position: absolute;
    inset: calc(50% - 19px) -19px auto auto;
    z-index: 0;
  }
}

// Big spoiler ================================================================
.big-spoiler {
  padding: 15px 14px 17px;
  border-radius: 6px;
  color: #5b5b5b;
  font: 14px/20px sans-serif;
  background: rgba(0, 0, 0, 0.05);

  &__summary {
    padding-right: 5px;
    display: flex;
    position: relative;
    color: #000;
    font:
      700 16px/21px "Quicksand",
      sans-serif;
    opacity: 1;
    cursor: pointer;
    transition: opacity 0.07s ease-in-out;
  }

  &__content {
    padding: 12px 0 0 3px;
    margin-top: 13px;
    border-top: 1px rgba(0, 0, 0, 0.1) solid;
    white-space: pre-wrap;
  }

  &__small-spoiler {
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// Text chunks spoilers =======================================================
.text-chunks-spoilers {
  &__title {
    margin-bottom: 14px;
    font-weight: 500;
  }

  &__big-spoiler {
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// Tab with logo ==============================================================
.tab-with-logo {
  height: 42px;
  padding: 0 11px 2px 11px;
  box-sizing: border-box;
  border: 1px #d9d9d9 solid;
  border-width: 1px 1px 0 1px;
  border-radius: 6px 6px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font:
    700 15px/15px "Quicksand",
    sans-serif;
  background: #d9d9d9;
  cursor: pointer;
  user-select: none;

  &--active {
    background: #fff;
  }

  &__logo {
    width: 26px;
    height: 26px;
    margin-right: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 27px;
    line-height: 27px;
    text-align: center;

    &--instagram {
      width: 20px;
      height: 20px;
      margin-right: 6px;
      border-radius: 5px;
      color: #fff;
      font-size: 18px;
      line-height: 18px;
      background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
    }

    &--tiktok {
      width: 20px;
      height: 20px;
      padding: 1px 0 0 1px;
      box-sizing: border-box;
      margin-right: 6px;
      border-radius: 5px;
      color: #fff;
      font-size: 12px;
      line-height: 12px;
      background: black;

      &::before {
        filter: drop-shadow(1px 0px 0px #fd3e3e) drop-shadow(-1px -1px 0px #4de8f4);
      }
    }

    &--no-right-margin {
      margin-right: 0;
    }
  }

  &__text {
  }
}

// Post preview section =======================================================
.post-preview-section {
  &__title {
    margin-bottom: 14px;
    font-weight: 500;
  }

  &__tabs-row {
    padding: 0;
    margin: 0 0 -1px;
    display: flex;
    list-style: none;
  }

  &__tab-with-logo {
    margin-right: 5px;
  }

  &__post-snippet-wrap {
    padding: 12px 3px 13px;
    border: 1px #d7d7d7 solid;
    border-radius: 0 6px 6px 6px;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
  }

  &__post-snippet {
  }

  &__post-snippet-textarea {
    scroll-behavior: smooth;
  }
}

// Build cation form ==========================================================
.build-caption-form {
  &__columns-section {
    display: flex;
    justify-content: space-between;
    position: relative;

    &::before {
      content: "";
      width: 1px;
      height: 100%;
      position: absolute;
      inset: 0 auto auto 50%;
      background: rgba(0, 0, 0, 0.1);
      pointer-events: none;
    }

    &::after {
      content: "";
      width: 22px;
      height: 22px;
      box-sizing: border-box;
      border: 1px rgba(0, 0, 0, 0.15) solid;
      border-width: 1px 1px 0 0;
      position: absolute;
      inset: 200px auto auto calc(50% - 10px);
      transform: rotate(45deg);
      background: white;
      pointer-events: none;
    }
  }

  &__left-col {
    width: calc(50% - 54px);
  }

  &__chat-gpt-button {
    margin-bottom: 23px;

    &::before {
      border-color: #74aa9c;
      background: #74aa9c;
    }

    &:hover::before {
      border-color: #4d796f;
      background: #4d796f;
    }
  }

  &__or-divider {
    margin-bottom: 20px;
  }

  &__text-chunks-spoilers {
    margin-bottom: 30px;
  }

  &__right-col {
    width: calc(50% - 54px);
  }

  &__post-preview-section {
    margin-bottom: 35px;
  }

  &__next-button {
    outline: none;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .build-caption-form {
    &__left-col {
      width: calc(50% - 40px);
    }

    &__right-col {
      width: calc(50% - 40px);
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .build-caption-form {
    &__left-col {
      width: calc(50% - 49px);
    }

    &__right-col {
      width: calc(50% - 49px);
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .build-caption-form {
    &__columns-section {
      flex-direction: column;

      &::before {
        order: 2;
        width: 100%;
        margin-bottom: 7px;
        height: 1px;
        position: static;
        font-size: 0;
        line-height: 0;
      }

      &::after {
        order: 2;
        width: 22px;
        height: 22px;
        margin: 0 auto;
        border: 1px rgba(0, 0, 0, 0.15) solid;
        border-width: 1px 1px 0 0;
        position: relative;
        inset: -18px auto auto 0;
        transform: rotate(135deg);
      }
    }

    &__left-col {
      order: 1;
      width: 100%;
      margin-bottom: 35px;
    }

    &__right-col {
      order: 3;
      width: 100%;
    }

    &__post-preview-section {
      margin-bottom: 35px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .build-caption-form {
    &__columns-section {
      flex-direction: column;

      &::before {
        order: 2;
        width: 100%;
        margin-bottom: 7px;
        height: 1px;
        position: static;
        font-size: 0;
        line-height: 0;
      }

      &::after {
        order: 2;
        width: 22px;
        height: 22px;
        margin: 0 auto;
        border: 1px rgba(0, 0, 0, 0.15) solid;
        border-width: 1px 1px 0 0;
        position: relative;
        inset: -18px auto auto 0;
        transform: rotate(135deg);
      }
    }

    &__left-col {
      order: 1;
      width: 100%;
      margin-bottom: 35px;
    }

    &__right-col {
      order: 3;
      width: 100%;
    }

    &__post-preview-section {
      margin-bottom: 35px;
    }
  }
}
</style>
