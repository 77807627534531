import { defineStore } from "pinia";
import { ref, computed } from "vue";
import axios from "axios";
import { useRoute } from "vue-router";
import { checkIsStoreFresh } from "@helpers/CheckStoreFreshness";

// Types
import { AxiosResponse } from "axios";
import { Itinerary } from "@contracts/itinerary";

export const useItinerariesStore = defineStore("itineraries", () => {
  const isLoading = ref(false);
  let lastLoadTimestamp: number | null = null;

  const allItineraries = ref<Array<Itinerary>>();

  async function loadAllItineraries(pageId: string, isPublicFacing = true, isForceLoad = false): Promise<void> {
    if (checkIsStoreFresh(lastLoadTimestamp) && !isForceLoad) return;

    const uri = `${import.meta.env.VITE_API_URL}/pages/${pageId}?isPublicFacing=${isPublicFacing}`;

    isLoading.value = true;
    const response: AxiosResponse<any> = await axios.get(uri);
    lastLoadTimestamp = Date.now();
    allItineraries.value = response.data.itineraries;
    isLoading.value = false;
  }

  return {
    isLoading,
    allItineraries,
    loadAllItineraries,
  };
});
