<template>
  <li class="new-paid-creator-section">
    <EventTypeTag class="new-paid-creator-section__type-tag" color="purple">New Creator Joined</EventTypeTag>

    <!-- Col left -->
    <div class="new-paid-creator-section__col-left">
      <RouterLink
        class="new-paid-creator-section__logo-wrap new-paid-creator-section__logo-wrap--rounded"
        :to="{ name: 'CreatorProfilePublic', params: { creatorId: feedItem.creatorId } }"
        target="_blank"
      >
        <img class="new-paid-creator-section__logo new-paid-creator-section__logo--object-fit-cover" :src="`${contentBaseUri}/cms/images/profiles/${feedItem.profilePhoto}`" :alt="feedItem.name" />
      </RouterLink>

      <SocialMediaLinksBar class="new-paid-creator-section__social-media-links-bar" :socialFields="feedItem" :isFullSizeIcons="false" :iconsGap="5" />
    </div>
    <!-- / Col left -->

    <!-- Col right -->
    <div class="new-paid-creator-section__col-right">
      <div class="new-paid-creator-section__name-n-location">
        <RouterLink class="global-h5 new-paid-creator-section__name" :to="{ name: 'CreatorProfilePublic', params: { creatorId: feedItem.creatorId } }" target="_blank">
          {{ feedItem.name }}
        </RouterLink>
        <!-- Location snippet -->
        <div class="location-snippet new-paid-creator-section__location-snippet">
          <IconEmbedded class="location-snippet__icon" name="map-pin_2" />
          <div class="location-snippet__text">
            {{ `${feedItem.city}, ${feedItem.state}` }}
          </div>
        </div>
        <!-- / Location snippet -->
      </div>

      <!-- Media files rows -->
      <div v-if="feedItem.featuredMedia?.length" class="media-files-row media-files-row--one-row-visible new-paid-creator-section__media-files-row">
        <div class="media-files-row__in-wrap">
          <div class="media-files-row__thumbnail-wrap" v-for="fileName in feedItem.featuredMedia" :key="fileName">
            <SrpFileThumbnail
              class="media-files-row__thumbnail"
              :gallery-postfix="`gallery-activity-feed-${feedItem.eventDate + feedItem.type + feedItem.creatorId}`"
              :fileName="fileName"
              :thumbSize="'thumb-tiny'"
              :isFixedAspectRatio="false"
              :isEntireAreaClickable="true"
            />
          </div>
        </div>
      </div>
      <!-- / Media files rows -->
    </div>
    <!-- / Col right -->
  </li>
</template>

<script setup lang="ts">
// Components
import EventTypeTag from "./EventTypeTag.vue";

// Types
import { NewPaidCreatorFeedItem } from "@components/ActivityFeed/types";
import SrpFileThumbnail from "@components/ui/SrpFileThumbnail.vue";
import SocialMediaLinksBar from "@components/SocialMediaLinksBar.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

// Global variables
const contentBaseUri = globalThis.Bootstrap.Config.contentCdnBaseUri;

const props = withDefaults(
  defineProps<{
    feedItem: NewPaidCreatorFeedItem;
  }>(),
  {
    feedItem: null,
  }
);
</script>

<style scoped lang="scss">
@import "./commonStyle.scss";

// New paid creator section ===================================================
.new-paid-creator-section {
  @include eventContainer;
  display: flex;
  position: relative;
  z-index: 0;

  // col left -------------------------
  &__col-left {
    min-width: 70px;
    margin-right: 15px;
  }

  &__logo-wrap {
    width: 70px;
    height: 70px;
    margin-bottom: 6px;
    display: flex;
    justify-content: center;
    align-content: center;
    opacity: 1;
    transition: opacity 0.07s ease-in-out;

    &--rounded {
      border-radius: 200px;
      position: relative;
      overflow: hidden;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &__logo {
    width: 100%;
    height: 100%;
    object-fit: contain;

    &--object-fit-cover {
      object-fit: cover;
    }
  }

  &__social-media-links-bar {
    height: 23px;
    position: relative;
    overflow: hidden;
  }

  // col right ------------------------
  &__col-right {
  }

  &__name-n-location {
    margin-bottom: 14px;
  }

  &__name {
    max-width: calc(100% - 125px);
    margin-bottom: 4px;
    display: flex;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      text-decoration-color: rgba(0, 0, 0, 0.2);
      text-underline-offset: 2px;
      text-decoration-thickness: 1px;
      text-decoration-style: solid;
    }
  }

  &__location-snippet {
    margin-left: -4px;
  }

  &__media-files-row {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .new-paid-creator-section {
    &__name {
      max-width: 100%;
    }
  }
}
</style>
