<template>
  <div class="global-h1" style="padding: 80px 0 30px; text-align: center">Redirecting<EllipsisAnimated /></div>
</template>

<script setup lang="ts">
import { onBeforeMount, inject } from "vue";
import { useRoute } from "vue-router";
import { RemoteLogger } from "@helpers/RemoteLogger";
import Links from "@logic/ExternalLinks";

// Components
import EllipsisAnimated from "@components/ui/EllipsisAnimated.vue";

// Injections
const globalRemoteLogger = inject("globalRemoteLogger") as RemoteLogger;

const route = useRoute();

// Extract query value ========================================================
onBeforeMount(() => {
  if (route.query.utm_id) {
    localStorage.setItem("utm_id", String(route.query.utm_id));
    // Log: send immediately and wait for send (since we're about to redirect)
    globalRemoteLogger.info(`AdTracking utm_id=${route.query.utm_id}, referrer=${document?.referrer}`, true, true);
  } else if (route.query.ref) {
    localStorage.setItem("referredByUserId", String(route.query.ref));
    // Log: send immediately and wait for send (since we're about to redirect)
    globalRemoteLogger.info(`ReferTracking refCode=${route.query.ref}, referrer=${document?.referrer}`, true, true);
  } else {
    globalRemoteLogger.info(`AdTracking NO params, referrer=${document?.referrer}`, true, true);
  }
  // Redirect the user to the creator landing page
  window.location.href = Links.ExternalLinks["BecomingACreator"];
});
</script>

<style scoped lang="scss"></style>
