import { ApplicationInsights } from "@microsoft/applicationinsights-web";

// https://brettmckenzie.net/posts/things-i-wish-i-knew-earlier-about-distributed-tracing-in-azure-application-insights/
export const appInsights = new ApplicationInsights({
  config: {
    connectionString: globalThis.Bootstrap.Config.appInsightsConnectionString,
    enableAutoRouteTracking: true,
    // Enabling distributed tracing
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    enableCorsCorrelation: true, // NOTE! There's some risk here since we need to exclude certain domains from the correlation.  Could disable but then we lose correllation.
    correlationHeaderExcludedDomains: ["*.core.windows.net", "*.auth0.com", "auth.shrpa.com", "*.amplitude.com", "*.posthog.com"],
  },
});
