<template>
  <tr class="invite-table-line">
    <td class="invite-table-line__td-email">
      <span
        :class="{
          'global-h4': true,
          'invite-table-line__email': true,
          'invite-table-line__email--skeleton': isSkeleton,
        }"
      >
        {{ data.email }}
      </span>
      <br />

      <template v-if="['mobile', 'tablet'].includes(screenSize)">
        <div
          :class="{
            'global-h6': true,
            'invite-table-line__col-title': true,
            'invite-table-line__col-title--skeleton': isSkeleton,
          }"
        >
          Invited on
        </div>
        <br />
        <div
          :class="{
            'invite-table-line__invited-on': true,
            'invite-table-line__invited-on--skeleton': isSkeleton,
          }"
        >
          {{ formatInvitedDate(data.invitedOn) }}
        </div>

        <LinkWithIcon
          :class="{
            'invite-table-line__delete': true,
            'invite-table-line__delete--skeleton': isSkeleton,
          }"
          color="blue"
          @click="resendInvite"
        >
          <IconEmbedded v-if="isResending" name="loader_3" :size="22" style="margin-right: 4px" />
          <IconEmbedded v-else name="reload_2-5" :size="19" style="margin-right: 4px" />
          <span>Resend</span>
        </LinkWithIcon>
      </template>
    </td>
    <td v-if="['tablet-large', 'laptop', 'desktop'].includes(screenSize)" class="invite-table-line__td-invited-on">
      <div
        :class="{
          'invite-table-line__invited-on': true,
          'invite-table-line__invited-on--skeleton': isSkeleton,
        }"
      >
        {{ formatInvitedDate(data.invitedOn) }}
      </div>
    </td>

    <td v-if="['tablet-large', 'laptop', 'desktop'].includes(screenSize)" class="invite-table-line__td-delete">
      <LinkWithIcon
        :class="{
          'invite-table-line__delete': true,
          'invite-table-line__delete--skeleton': isSkeleton,
        }"
        color="blue"
        @click="resendInvite"
        :isDisabled="isResending"
      >
        <IconEmbedded v-if="isResending" name="loader_3" :size="22" style="margin-right: 4px" />
        <IconEmbedded v-else name="reload_2-5" :size="19" style="margin-right: 4px" />
        <span>Resend</span>
      </LinkWithIcon>
    </td>
    <td class="invite-table-line__td-delete">
      <LinkWithIcon
        :class="{
          'invite-table-line__delete': true,
          'invite-table-line__delete--skeleton': isSkeleton,
        }"
        color="red"
        @click="isRemoveModalVisible = true"
      >
        <IconEmbedded v-if="isResending" name="loader_3" :size="22" style="margin-right: 4px" />
        <IconEmbedded v-else name="reload_2-5" :size="19" style="margin-right: 4px" />
        <span>Delete</span>
      </LinkWithIcon>
    </td>

    <SrpModal v-model:isVisible="isResendModalVisible">
      <template #header>
        <h2 class="global-h2" style="color: #777; font-weight: 500">
          Invite for<br />
          <span style="color: #000; font-weight: 700">{{ data.email }}</span> <br />
          has been sent again
        </h2>
      </template>
      <template #footer>
        <SrpButton @click="isResendModalVisible = false" color="gray" fill="outlined">Close</SrpButton>
      </template>
    </SrpModal>

    <SrpModal v-model:isVisible="isRemoveModalVisible" size="small" :isClosable="!isRemoving">
      <template #header>
        <h1 class="global-h1">
          Remove invite for <span style="height: 17px; margin-top: 7px; display: flex; font-size: 17px; line-height: 17px">{{ data.email }}?</span>
        </h1>
      </template>
      <template #content>
        <p>Invite link will be deactivated</p>
      </template>
      <template #footer>
        <div class="ui buttons">
          <SrpButton :isDisabled="isRemoving" @click="isRemoveModalVisible = false" shape="rectangle" color="gray">Cancel</SrpButton>
          <div class="or"></div>
          <SrpButton :isLoading="isRemoving" :isDisabled="isRemoving" @click="deleteInvite" shape="rectangle" color="orange">
            <IconEmbedded v-if="isRemoving" name="loader_3" :size="22" style="margin-right: 2px" />
            Confirm
          </SrpButton>
        </div>
      </template>
    </SrpModal>
  </tr>
</template>

<script lang="ts">
import moment from "moment";
import { defineComponent, inject } from "vue";

// Types
import { InviteSlot } from "./index.vue";
import { ScreenSize } from "@contracts/screenSize";

// Components
import LinkWithIcon from "@components/LinkWithIcon.vue";
import axios from "axios";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export default defineComponent({
  name: "InviteTableLine",

  components: {
    IconEmbedded,
    SrpModal,
    SrpButton,
    LinkWithIcon,
  },

  props: {
    isSkeleton: { type: Boolean, default: false },
    data: { type: Object as () => InviteSlot, required: true },
    inviteFunc: { type: Function, required: true },
    deleteFunc: { type: Function, required: true },
  },

  emits: ["resend", "deleted"],

  data() {
    return {
      screenSize: inject("screenSize") as ScreenSize,

      pageId: "",

      isRemoving: false,
      isRemoveModalVisible: false,

      isResending: false,
      isResendModalVisible: false,
    };
  },

  mounted() {
    // @ts-ignore
    this.pageId = this.$route.params.pageId;
  },

  methods: {
    moment,
    formatInvitedDate: (date: string): string => `${moment(date).format("MMM Do YYYY")} (${moment(date).fromNow()})`,
    async deleteInvite(): Promise<void> {
      this.isRemoving = true;

      await this.deleteFunc(this.data.email);

      this.isRemoving = false;
      this.isRemoveModalVisible = false;

      this.$emit("deleted");
    },
    async resendInvite(): Promise<void> {
      this.isResending = true;

      await this.inviteFunc(this.data.email);

      this.isResendModalVisible = true;
      this.isResending = false;
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/mixins/animations/gradient-spin-blue.scss";
@import "@/scss/mixins/animations/gradient-spin-red.scss";
@import "@/scss/mixins/animations/gradient-spin-white.scss";
@import "@/scss/mixins/animations/loading-bg.scss";
@import "@/scss/screen-size-ranges.scss";

.spinningBlue {
  &::before {
    @include gradientSpinBlue;
  }
}
.spinningRed {
  &::before {
    @include gradientSpinRed;
  }
}
.spinningWhite {
  &::before {
    @include gradientSpinWhite;
  }
}
// Invite table line ==========================================================
.invite-table-line {
  &__title {
    margin-bottom: 22px;

    &--skeleton {
      @include loadingBgAnimation;
      color: transparent !important;
    }
  }

  td {
    padding: 14px 18px 10px;
    box-sizing: border-box;
    border: 1px rgba(0, 0, 0, 0.1) solid;
  }

  tbody td {
    padding-top: 18px;
    padding-bottom: 18px;
  }

  &__col-title {
    display: inline-flex;

    &--skeleton {
      @include loadingBgAnimation;
      color: transparent !important;
    }
  }

  &__td-email {
    font-weight: 500;
  }

  &__email {
    display: inline-flex;
    user-select: all;

    &--skeleton {
      @include loadingBgAnimation;
      color: transparent !important;
    }
  }

  &__td-invited-on {
  }

  &__invited-on {
    margin-bottom: 8px;
    display: flex;

    &--skeleton {
      @include loadingBgAnimation;
      color: transparent !important;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__td-status {
  }

  &__status {
    display: inline-flex;

    &--skeleton {
      @include loadingBgAnimation;
      color: transparent !important;
    }
  }

  &__td-delete {
  }

  &__delete {
    display: inline-flex;

    &--skeleton {
      @include loadingBgAnimation;
      color: transparent !important;

      i::before {
        color: transparent;
      }
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .invite-table-line {
    &__email {
      margin-bottom: 8px;
      display: inline-flex;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .invite-table-line {
    &__title {
      margin-bottom: 15px;
    }

    &__email {
      margin-bottom: 8px;
      display: inline-flex;
    }

    td {
      padding: 10px 12px;
    }
  }
}
</style>
