<template>
  <SrpModal :isVisible="isVisible" :size="'large'" isWithScroll @update:isVisible="emit('update:isVisible', $event)">
    <template #header>
      <h1 class="global-h1" style="margin-bottom: 8px">Your Guide to a Successful Collaboration</h1>
      <span style="color: #5b5b5b; font-family: sans-serif">This should take 2-3 minutes to complete</span>
    </template>
    <template #content="{ scrollToTheTop }">
      <CollabCreatorGuide
        :isCongratsVisible="isCongratsVisible"
        :communityName="communityName"
        :communityIcon="communityIcon"
        :requestedLocationCount="props.requestedLocationCount"
        :requestedAdventureCount="props.requestedAdventureCount"
        @slideChange="scrollToTheTop"
        @complete="emit('complete')"
      />
    </template>
  </SrpModal>
</template>

<script setup lang="ts">
import SrpModal from "@components/ui/SrpModal.vue";
import CollabCreatorGuide from "@components/CollabCreatorGuide/index.vue";
const props = withDefaults(
  defineProps<{
    isVisible: boolean;
    isCongratsVisible?: boolean;
    communityName?: string;
    communityIcon?: string;
    requestedLocationCount: number;
    requestedAdventureCount: number;
  }>(),
  {
    isCongratsVisible: false,
    communityName: "",
    communityIcon: "",
    requestedLocationCount: 10,
    requestedAdventureCount: 4,
  }
);
const emit = defineEmits<{
  (e: "update:isVisible", value: boolean): void;
  (e: "complete"): void;
}>();
</script>
