<template>
  <div
    :class="{
      'circle-btn': true,
      // Size
      'circle-btn--small': props.size === 'small',
      'circle-btn--normal': props.size === 'normal',
      'circle-btn--big': props.size === 'big',
      'circle-btn--huge': props.size === 'huge',
      // Color
      'circle-btn--white': props.color === 'white',
      'circle-btn--gray': props.color === 'gray',
      'circle-btn--black': props.color === 'black',
      'circle-btn--red': props.color === 'red',
      // Color hover
      'circle-btn--hover-white': props.colorHover === 'white',
      'circle-btn--hover-gray': props.colorHover === 'gray',
      'circle-btn--hover-black': props.colorHover === 'black',
      'circle-btn--hover-red': props.colorHover === 'red',
    }"
    :style="`--icon-color-hover: ${
      props.iconColorHover ||
      {
        white: 'rgba(91, 91, 91, 1)',
        gray: 'rgba(91, 91, 91, 1)',
        black: 'rgba(255, 255, 255, 1)',
        red: 'rgba(255, 255, 255, 1)',
      }[props.color]
    }`"
  >
    <IconEmbedded
      class="circle-btn__icon"
      :name="props.iconName"
      :size="
        props.iconSize ??
        {
          small: 12,
          normal: 17,
          big: 20,
          huge: 24,
        }[props.size]
      "
      :color="
        props.iconColor ??
        {
          white: 'rgba(91, 91, 91, 1)',
          gray: 'rgba(91, 91, 91, 1)',
          black: 'rgba(255, 255, 255, 1)',
          red: 'rgba(255, 255, 255, 1)',
        }[props.color]
      "
      :style="{ inset: props.iconInset }"
    />
  </div>
</template>

<script setup lang="ts">
// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";

// Types
import { IconName } from "@contracts/IconName";
type CircleColors = "white" | "gray" | "black" | "red";

const props = withDefaults(
  defineProps<{
    size?: "small" | "normal" | "big" | "huge";
    color?: CircleColors;
    colorHover?: CircleColors;
    iconName?: IconName;
    iconColor?: string;
    iconColorHover?: string;
    iconSize?: number;
    iconInset?: string;
  }>(),
  {
    size: "small",
    color: "black",
    colorHover: null,
    iconName: "image_2-5",
    iconColor: null,
    iconColorHover: null,
    iconSize: null,
    iconInset: "auto auto auto auto",
  }
);
</script>

<style scoped lang="scss">
$circle-color-white: rgba(255, 255, 255, 1);
$circle-color-gray: rgba(231, 237, 240, 1);
$circle-color-black: rgba(0, 0, 0, 1);
$circle-color-red: rgba(236, 86, 59, 1);

// Circle btn =================================================================
.circle-btn {
  width: 18px;
  min-width: 18px;
  height: 18px;
  min-height: 18px;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  user-select: none;

  &::before {
    width: calc(100% + 30px);
    min-width: calc(100% + 30px);
    height: calc(100% + 30px);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 100px;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -1;
    background: $circle-color-white;
    transition:
      transform 0.07s ease-in-out,
      background-color 0.07s ease-in-out;
  }

  // Size -----------------------------
  &--small {
    width: 18px;
    min-width: 18px;
    height: 18px;
    min-height: 18px;

    &:hover {
      &::after {
        transform: scale(1.22);
      }
    }
  }
  &--normal {
    width: 26px;
    min-width: 26px;
    height: 26px;
    min-height: 26px;

    &:hover {
      &::after {
        transform: scale(1.21);
      }
    }
  }
  &--big {
    width: 31px;
    min-width: 31px;
    height: 31px;
    min-height: 31px;

    &:hover {
      &::after {
        transform: scale(1.17);
      }
    }
  }
  &--huge {
    width: 37px;
    min-width: 37px;
    height: 37px;
    min-height: 37px;

    &:hover {
      &::after {
        transform: scale(1.17);
      }
    }
  }

  // Color ----------------------------
  &--white {
    &::after {
      background: $circle-color-white;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
    }
  }
  &--gray {
    &::after {
      background: $circle-color-gray;
      box-shadow: none;
    }
  }
  &--black {
    &::after {
      background: $circle-color-black;
      box-shadow: none;
    }
  }
  &--red {
    &::after {
      background: $circle-color-red;
      box-shadow: none;
    }
  }

  // Color hover ----------------------
  &--hover-white {
    &:hover::after {
      background: $circle-color-white;
    }
  }
  &--hover-gray {
    &:hover::after {
      background: $circle-color-gray;
    }
  }
  &--hover-black {
    &:hover::after {
      background: $circle-color-black;
    }
  }
  &--hover-red {
    &:hover::after {
      background: $circle-color-red;
    }
  }

  // Icon color hover -----------------
  &:hover {
    :deep(svg) {
      fill: var(--icon-color-hover);
    }
  }

  &__icon {
  }
}
</style>
