// Trying to keep most external links (ex. landing pages) in this page so we don't repeat them
// and have an easy inventory of what the site links off to
const ExternalLinks = {
  // --Landing page links--
  // Destinations
  ForCommunities: "https://explore.shrpa.com/community-intro/",
  CustomerContactUs: "https://explore.shrpa.com/contact-sales/",
  ForCreators: "https://explore.shrpa.com/become-a-creator-today/",
  PhotoWallIntro: "https://explore.shrpa.com/photo-walls-delivering-inspiration/",
  CommunityPaidCollabHelp: "https://explore.shrpa.com/support/communities/paid-collaborations/how-to-fill-out-collaboration-details/",
  CommunityPaidCollabOverview: "https://explore.shrpa.com/support/communities/paid-collaborations/how-does-setting-up-collaborations-work/",
  CommunityCollabInspire: "https://explore.shrpa.com/support/communities/paid-collaborations/create-your-collaboration-in-under-30-minutes/",
  CollabHotelCompTemplate: "https://explore.shrpa.com/support/communities/paid-collaborations/requesting-a-hotel-template/",
  // Creators
  BecomingACreator: "https://explore.shrpa.com/become-a-creator-today/", // Creator Dash Help Link
  CreatingAdventureTips: "https://explore.shrpa.com/creating-adventures/", // Creator Dash Help Link
  BecomingAPaidCreator: "https://explore.shrpa.com/join-the-shrpa-travel-network/", // Learn More when asking about paid creator interest
  CreatorReferralProgram: "https://explore.shrpa.com/referral-program",
  CreatorCollabAdvExp: "https://explore.shrpa.com/collaboration-expectations/", // Formerly "https://explore.shrpa.com/creating-quality-adventures-for-collaborations/",
  CollabExpectations: "https://explore.shrpa.com/collaboration-expectations/",
  // CollabCreditsSummary: "https://explore.shrpa.com/collaboration-credits", //Retired
  AvailableCollabsSummary: "https://explore.shrpa.com/creator-collaborations/",
  CollabGuidance: "https://explore.shrpa.com/ultimate-guide-for-collabs/",
  AdventureCreationTips: "https://explore.shrpa.com/creating-adventures/",
  CollabSocialPostTips: "https://explore.shrpa.com/createyoursocialpost/",
  ConvertingHeicToJpeg: "https://explore.shrpa.com/convertfiles",
  // Creator Slack we set up (should be set to never expire)
  SlackCreatorInvite: "https://join.slack.com/t/shrpacreators/shared_invite/zt-2p554wlo4-EEbxT6c9j5J4PNxY2iLQ3w",

  // Social
  Instagram: "https://instagram.com/tryshrpa/",
  Facebook: "https://facebook.com/tryshrpa",
} as const;

export function getGlobalLink(key: ExternalLinksKey): string {
  return ExternalLinks[key];
}

type ExternalLinksKey = keyof typeof ExternalLinks;

export default {
  ExternalLinks,
};
