<template>
  <component
    :class="{
      'srp-button': true,

      'srp-button--tiny': props.size === 'tiny',
      'srp-button--small': props.size === 'small',
      'srp-button--normal': props.size === 'normal',
      'srp-button--big': props.size === 'big',

      'srp-button--solid': props.fill === 'solid',
      'srp-button--outlined': props.fill === 'outlined',
      'srp-button--text': props.fill === 'text',

      'srp-button--rectangle': props.shape === 'rectangle',

      'srp-button--white': props.color === 'white',
      'srp-button--black': props.color === 'black',
      'srp-button--gray': props.color === 'gray',
      'srp-button--teal': props.color === 'teal',
      'srp-button--orange': props.color === 'orange',
      'srp-button--red': props.color === 'red',
      'srp-button--custom-color': props.customColor,

      'srp-button--bigger-side-paddings': props.isBiggerSidePaddings,

      'srp-button--disabled': props.isDisabled,
    }"
    :is="is"
    :to="props.to"
    :target="props.target"
    v-bind="dynamicComponentAttributes"
  >
    <span
      v-if="slots.icon"
      :class="{
        'srp-button__icon-wrap': true,
        'srp-button__icon-wrap--tiny': props.size === 'tiny',
        'srp-button__icon-wrap--small': props.size === 'small',
        'srp-button__icon-wrap--normal': props.size === 'normal',
        'srp-button__icon-wrap--big': props.size === 'big',
      }"
      :style="{ inset: props.iconInset }"
    >
      <slot name="icon" />
    </span>
    <span v-if="slots.default" class="srp-button__text" :style="props.customColor ? `color: ${textColorContrastingToBg}` : ''">
      <slot />
    </span>
    <span v-if="slots.iconRight" class="srp-button__icon-wrap srp-button__icon-wrap--right-aligned">
      <slot name="iconRight" />
    </span>

    <span
      v-if="props.isWithOrSeparator"
      :class="{
        'srp-button__or-separator': true,
        'srp-button__or-separator--tiny': props.size === 'tiny',
        'srp-button__or-separator--small': props.size === 'small',
        'srp-button__or-separator--normal': props.size === 'normal',
        'srp-button__or-separator--big': props.size === 'big',
      }"
    >
      or
    </span>
  </component>
</template>

<script setup lang="ts">
import { calcColorLuminance, convertHEXToRGB } from "@logic/Color";
import { computed, useSlots } from "vue";

export type SrpButtonColor = "white" | "gray" | "teal" | "orange" | "black" | "red";

const props = withDefaults(
  defineProps<{
    size?: "tiny" | "small" | "normal" | "big";
    fill?: "outlined" | "solid" | "text";
    shape?: "rounded" | "rectangle";
    color?: SrpButtonColor;
    isDisabled?: boolean;
    tag?: string;
    to?: object | null;
    href?: string;
    isWithOrSeparator?: boolean;
    isBiggerSidePaddings?: boolean;
    target?: "_blank" | "";
    iconInset?: string;
    customColor?: string;
  }>(),
  {
    size: "normal",
    fill: "solid",
    shape: "rectangle",
    color: "teal",
    isDisabled: false,
    tag: "",
    to: null,
    href: "",
    isWithOrSeparator: false,
    isBiggerSidePaddings: false,
    target: "",
    iconInset: "",
    customColor: "",
  }
);

const slots = useSlots();

// Define "is" value ==========================================================
const is = computed((): string => {
  if (props.tag) return props.tag;
  else if (props.href) return "a";
  else if (props.to) return "router-link";
  else return "div";
});

// Flexible href and to values ================================================
const dynamicComponentAttributes = computed((): any => {
  // Background - href: is an optional prop, and if we use the 'to:' prop instead, an empty href will be rendered,
  // which confuses the browser (things like copy link don't work).
  // So we dynamically apply the href prop ONLY if it's set.
  if (props.href)
    return {
      href: props.href,
    };
  else return {};
});

// Calc text color if custom color used =======================================
const textColorContrastingToBg = computed(() => {
  const bgColorLuminance: number = calcColorLuminance(convertHEXToRGB(props.customColor));

  return bgColorLuminance > 0.7 ? "#000" : "#fff";
});
</script>

<style scoped lang="scss">
// Sherpa Button ==============================================================
.srp-button {
  height: 41px;
  min-height: 41px;
  padding: 0 22px 4px;
  box-sizing: border-box;
  border: none;
  outline: none;
  border-radius: 100px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  font:
    600 16px/16px "Quicksand",
    sans-serif;
  cursor: pointer;
  user-select: none;

  &--bigger-side-paddings {
    padding-right: 33px;
    padding-left: 33px;
  }

  // Size =============================
  &--tiny {
    height: 28px;
    min-height: 28px;
    padding: 0 17px 3px;
    font-size: 13px;
    line-height: 13px;
  }
  &--small {
    height: 33px;
    min-height: 33px;
    padding-bottom: 2px;
    font-size: 14px;
    line-height: 14px;
  }
  &--big {
    height: 52px;
    min-height: 52px;
    padding: 0 32px 4px;
    font-size: 22px;
    line-height: 22px;
  }

  &--bigger-side-paddings.srp-button--tiny {
    padding-right: 24px;
    padding-left: 24px;
  }

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px #118689 solid;
    border-radius: 100px;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -1;
    background: #118689;
    transition:
      width 0.07s ease-in-out,
      border-color 0.07s ease-in-out,
      left 0.07s ease-in-out,
      background-color 0.07s ease-in-out;
  }

  &:focus::before {
    outline: 2px rgba(133, 183, 217, 0.8) solid;
  }

  &:hover::before {
    width: calc(100% + 4px);
    left: -2px;
  }

  // Color ============================
  &--black {
    &::before {
      border-color: black;
    }
  }

  &--white {
    color: white;
    :deep(svg) {
      fill: white;
    }
  }

  &--gray {
    color: #333;
    :deep(svg) {
      fill: rgba(51, 51, 51, 0.5);
    }

    &::before {
      border-color: #ccc;
      background: #ccc;
    }
    &:hover::before {
      border-color: #bbb;
      background: #bbb;
    }
  }
  &--gray.srp-button--outlined {
    :deep(svg) {
      fill: rgba(51, 51, 51, 0.5);
    }
  }

  &--red {
    color: #cb3d3d;

    :deep(svg) {
      fill: rgba(255, 255, 255, 0.5);
    }

    &::before {
      border-color: #b43535;
      background: #b43535;
    }

    &:hover {
      color: #a23030;

      :deep(svg) {
        fill: rgba(255, 255, 255, 0.5);
      }
    }

    &:hover::before {
      border-color: #b43535;
      background: #b43535;
    }
  }
  &--red.srp-button--outlined {
    :deep(svg) {
      fill: rgba(203, 61, 61, 0.5);
    }
  }

  &--teal {
    color: #0d686b;

    :deep(svg) {
      fill: rgba(255, 255, 255, 0.5);
    }

    &:hover {
      color: #102f41;

      :deep(svg) {
        fill: rgba(255, 255, 255, 0.5);
      }
    }

    &:hover::before {
      border-color: #102f41;
      background: #102f41;
    }
  }
  &--teal.srp-button--outlined {
    :deep(svg) {
      fill: rgba(13, 104, 107, 0.5);
    }
  }

  &--orange {
    color: #fff;

    :deep(svg) {
      fill: rgba(255, 255, 255, 0.5);
    }

    &::before {
      border-color: #ec563b;
      background: #ec563b;
    }
    &:hover::before {
      border-color: #ad402c;
      background: #ad402c;
    }
  }

  &--custom-color {
    color: v-bind(customColor);

    :deep(svg) {
      fill: rgba(255, 255, 255, 0.5);
    }

    &::before {
      border-color: v-bind(customColor);
      background: v-bind(customColor);
    }
    &:hover::before {
      border-color: v-bind(customColor);
      background: v-bind(customColor);
    }
  }

  // Shape ============================
  &--rounded {
  }
  &--rectangle {
    &::before {
      border-radius: 4px;
    }
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &__icon-wrap {
    min-width: 18px;
    min-height: 18px;
    margin: 0 5px -1px -2px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &:last-child {
      margin-right: 0;
    }

    &--tiny {
      min-width: 14px;
      min-height: 14px;
    }
    &--small {
      min-width: 16px;
      min-height: 16px;
    }
    &--normal {
      min-width: 18px;
      min-height: 18px;
    }
    &--big {
      min-width: 22px;
      min-height: 22px;
      margin-right: 7px;
      top: 2px;
    }

    div,
    span {
      display: flex;
    }

    &--right-aligned {
      margin-right: -2px;
      margin-left: 5px;
    }

    :deep(span),
    :deep(div),
    :deep(i) {
      position: relative;
    }
  }

  &__text {
    display: flex;
    align-items: center;
    white-space: nowrap;

    &--mix-blend-mode {
      mix-blend-mode: revert;
    }
  }

  // Fill =============================
  &--solid {
    color: #fff;

    .srp-button__text {
      color: #fff;
    }
  }
  &--solid.srp-button--gray &__text {
    color: #333 !important;
  }
  &--outlined.srp-button--orange &__text {
    color: #ec563b;
  }
  &--outlined {
    &::before {
      background: transparent;
    }

    &:hover {
      &::before {
        background: transparent;
      }
    }
  }
  &--text {
    height: 0;
    min-height: 0;
    padding: 0;
    &::before {
      background: transparent;
      border: none;
    }

    &:hover {
      &::before {
        background: transparent;
      }
    }
  }

  &__or-separator {
    height: calc(100% - 12px);
    aspect-ratio: 1/1;
    padding-bottom: 2px;
    box-sizing: border-box;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 6px auto auto -2px;
    transform: translateX(-50%);
    color: #5b5b5b;
    font: bold 14px/14px sans-serif;
    background: white;
    pointer-events: none;

    &--tiny {
      height: calc(100% - 8px);
      padding-bottom: 1px;
      inset: 4px auto auto -2px;
      font-size: 10px;
    }
    &--small {
      font-size: 11px;
    }
    &--normal {
    }
    &--big {
      height: calc(100% - 20px);
      inset: 10px auto auto -2px;
    }
  }
}
</style>
