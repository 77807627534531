import axios from "axios";

export default {
  // Uploads an image blob to the server.
  // Note: This is currently used by the adventure UploadPhotoForm (the profile and community icon use their own flow)
  async uploadItineraryImageBlob(imageBlobToUpload, processAsset: boolean | null, clientFileName: string): Promise<string | null> {
    // Note: We can now pass in a crop anchor (ex. &cropAnchor=Left)
    let postUrl = `${import.meta.env.VITE_API_URL}/images?type=itinerary`;
    if (processAsset != null) postUrl += `&processAsset=${processAsset}`;
    const formData = new FormData();
    formData.append("file", imageBlobToUpload, clientFileName);
    var result = await axios.post(postUrl, formData);
    // return the uploaded file's server-side identifier
    return result.data[0];
  },
};
