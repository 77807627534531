<template>
  <div class="contacts-slide">
    <!-- Header -->
    <div class="header contacts-slide__header">
      <h1 class="global-h1 header__h1">Destination Contact</h1>
      <div class="header__subtitle">For any last-minute questions.</div>
    </div>
    <!-- / Header -->

    <div class="field">
      <div class="global-h4">Contact Name</div>
      <p class="cms" style="margin-left: 0; margin-right: 0; color: rgba(91, 91, 91, 1); font-family: sans-serif">
        Who is the primary contact for this visit? (if the creator has questions or businesses ask who they are working with)
      </p>
      <input
        class="global-text-input"
        type="text"
        :value="props.destinationContactName"
        @input="$event => emit('update:destinationContactName', ($event.target as HTMLInputElement).value)"
        style="width: 320px; max-width: 320px; margin-bottom: 25px"
      />
    </div>

    <div class="field">
      <div class="global-h4">Contact Phone Number</div>
      <p class="cms" style="margin-left: 0; margin-right: 0; color: rgba(91, 91, 91, 1); font-family: sans-serif">
        What is a good phone number for the creator to call in case of last-minute coordination needs?
      </p>
      <input
        class="global-text-input"
        type="text"
        :value="props.destinationPhoneNumber"
        @input="$event => emit('update:destinationPhoneNumber', ($event.target as HTMLInputElement).value)"
        style="width: 320px; max-width: 320px; margin-bottom: 25px"
      />
    </div>

    <!-- Errors list -->
    <div v-if="isWarningVisible" class="errors-list contacts-slide__errors-list">
      <h4 class="global-h4 errors-list__title">Please specify a contact and phone number.</h4>
    </div>
    <!-- / Errors list -->
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";

const props = withDefaults(
  defineProps<{
    destinationContactName: string | null;
    destinationPhoneNumber: string | null;
  }>(),
  {
    destinationContactName: null,
    destinationPhoneNumber: null,
  }
);

const emit = defineEmits<{
  (e: "onMounted"): void;
  (e: "update:destinationPhoneNumber", value: string): void;
  (e: "update:destinationContactName", value: string): void;
}>();

// Validate form and toggle warning ===========================================
const isFormValid = computed<boolean>(() => Boolean(props.destinationContactName?.trim() && props.destinationPhoneNumber?.trim()));

const isWarningVisible = ref<boolean>(false);

// Inform the parent component that this component is shown ===================
onMounted(() => emit("onMounted"));

// Define expose ==============================================================
defineExpose({
  isFormValid,
  showWarning: () => (isWarningVisible.value = true),
});
</script>

<style scoped lang="scss">
// Header =====================================================================
.header {
  &__h1 {
    margin-bottom: 3px;
  }

  &__subtitle {
    color: rgba(91, 91, 91, 1);
    font-family: sans-serif;
  }
}

// Errors list ================================================================
.errors-list {
  color: firebrick;

  &__title {
    margin-bottom: 4px;
    color: firebrick;
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__li {
    margin-bottom: 5px;
    font: 14px/18px sans-serif;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// Text with edit button form =================================================
.text-with-edit-btn-form {
  vertical-align: center;

  &__textarea {
    height: 80px;
  }

  &__text {
    color: rgba(91, 91, 91, 1);
    font-family: sans-serif;
    white-space: pre-wrap;
  }

  &__edit-btn {
    margin-left: 5px;
    position: relative;
    top: 3px;
  }
}

// Content usage form =========================================================
.content-usage-form {
  &__textarea {
    height: 40px;
    min-height: 40px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__suggestions-list {
    color: rgba(91, 91, 91, 1);
    font-weight: bold;
    font-family: sans-serif;
  }

  &__suggestion {
    margin-right: 12px;
    font-weight: normal;
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.4);
    text-underline-offset: 2px;
    text-decoration-thickness: 1px;
    text-decoration-style: dashed;
    cursor: pointer;
    user-select: none;

    &:hover {
      text-decoration: none;
    }
  }
}

// Contacts slide =============================================================
.contacts-slide {
  &__header {
    margin-bottom: 20px;
  }

  &__h4 {
    margin-bottom: 5px;
  }

  &__divider {
    height: 0;
    margin-bottom: 20px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__errors-list {
    margin-bottom: 20px;
  }

  &__text-with-edit-btn-form {
    margin-bottom: 20px;
  }

  &__content-usage-form {
  }
}
</style>
