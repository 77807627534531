<template>
  <SrpModal v-model:isVisible="isVisible" :isClosable="!collabSuggestionsStore.isGenerating">
    <template #content>
      <div class="generate-suggestions-form">
        <div v-if="collabSuggestionsStore.isGenerating" class="generate-suggestions-form__loader-overlay">
          <AiLoader class="generate-suggestions-form__loader-icon" :size="120">Generating<EllipsisAnimated /></AiLoader>
        </div>

        <!-- Header -->
        <div class="header generate-suggestions-form__header">
          <h1 class="global-h1 header__title">Any special guidance?</h1>
        </div>
        <!-- / Header -->

        <div class="generate-suggestions-form__divider"></div>

        <!-- Input with title -->
        <div class="input-with-title generate-suggestions-form__input-with-title">
          <h5 class="global-h5 input-with-title__title">Theme (optional)</h5>

          <CollabThemePicker class="input-with-title__input" v-model="collabGenerateInputPayload.specificTheme" />
        </div>
        <!-- / Input with title -->

        <!-- Input with title -->
        <div class="input-with-title generate-suggestions-form__input-with-title">
          <h5 class="global-h5 input-with-title__title">Or additional guidance</h5>

          <textarea class="global-textarea input-with-title__textarea" v-model="collabGenerateInputPayload.userGuidance" placeholder="Ex. Stay within downtown, or focus on restaurants"></textarea>
        </div>
        <!-- / Input with title -->
      </div>
    </template>

    <template #footer>
      <SrpButton @click="requestGeneration" :isDisabled="collabSuggestionsStore.isGenerating">
        <template #icon>
          <LoadingIcon v-if="collabSuggestionsStore.isGenerating" :size="22" color="rgba(0, 0, 0, 0.2)" />
          <IconEmbedded v-else name="ai-solid" :size="22" color="rgba(0, 0, 0, 0.2)" />
        </template>
        Generate
      </SrpButton>
    </template>
  </SrpModal>
</template>

<script setup lang="ts">
import { ref } from "vue";

// Components
import CollabThemePicker from "@views/PaidCollab/PlannedCollabs/CollabThemePicker.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import LoadingIcon from "@components/ui/LoadingIcon.vue";
import SrpModal from "@components/ui/SrpModal.vue";

// Types
import { CollabSuggestion, CollabGenerateInputPayload } from "@contracts/collabSuggestions";
import AiLoader from "@components/AiLoader.vue";
import EllipsisAnimated from "@components/ui/EllipsisAnimated.vue";

// Stores
import { useCollabSuggestionsStore } from "@stores/collabSuggestionsStore";
const collabSuggestionsStore = useCollabSuggestionsStore();

const props = withDefaults(
  defineProps<{
    pageId: string | null;
  }>(),
  {
    pageId: null,
  }
);

const emit = defineEmits<{
  (e: "generate", collabSuggestionsArray: Array<CollabSuggestion>): void;
}>();

// Form data ==================================================================
const collabGenerateInputPayload = ref<CollabGenerateInputPayload>({
  specificTheme: null,
  userGuidance: "",
});

function resetFormData() {
  collabGenerateInputPayload.value = {
    specificTheme: null,
    userGuidance: "",
  };
}

// Request suggestions generation =============================================

async function requestGeneration(countToGenerate = 1): Promise<void> {
  const data = await collabSuggestionsStore.generateCollabSuggestions(props.pageId, collabGenerateInputPayload.value, countToGenerate);

  resetFormData();

  emit("generate", data);
}

// Toggle modal ===============================================================
const isVisible = ref<boolean>(false);

// Define expose ==============================================================
defineExpose({
  openModal: () => (isVisible.value = true),
  closeModal: () => (isVisible.value = false),
  generateSuggestions: requestGeneration,
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Header =====================================================================
.header {
  width: 100%;

  &__title {
  }

  &__subtitle {
  }
}

// Input with title ===========================================================
.input-with-title {
  &__title {
    margin-bottom: 2px;
  }

  &__subtitle {
    margin-bottom: 9px;
    color: rgba(91, 91, 91, 1);
    font: 14px/19px sans-serif;
  }

  &__input {
  }

  &__textarea {
    width: 100%;
    max-width: 480px;
    height: 80px;
    min-height: 80px;
  }
}

// Generate suggestions form ==================================================
.generate-suggestions-form {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 0;
  color: rgba(91, 91, 91, 1);
  font: 14px/19px sans-serif;

  &__loader-overlay {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: 2;
    background: rgba(255, 255, 255, 0.9);
  }

  &__loader-icon {
  }

  &__header {
    margin-bottom: 15px;
  }

  &__divider {
    width: 100%;
    margin-bottom: 15px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__input-with-title {
    width: 100%;
    margin-bottom: 27px;
    position: relative;
    z-index: 1;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .generate-suggestions-form {
    &__left-side {
      width: calc($laptop-grid-col-width * 4 + $laptop-grid-gap-width * 3);
      min-width: calc($laptop-grid-col-width * 4 + $laptop-grid-gap-width * 3);
      padding-right: $laptop-grid-gap-width;
      margin: 0 $laptop-grid-gap-width 27px 0;
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .generate-suggestions-form {
    &__left-side {
      width: calc($tablet-large-grid-col-width * 3 + $tablet-large-grid-gap-width * 2);
      min-width: calc($tablet-large-grid-col-width * 3 + $tablet-large-grid-gap-width * 2);
      padding-right: $tablet-large-grid-gap-width;
      margin: 0 $tablet-large-grid-gap-width 27px 0;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .generate-suggestions-form {
    &__left-side {
      width: 100%;
      min-width: 100%;
      padding: 0 0 24px 0;
      margin: 0 0 24px 0;
      border-width: 0 0 1px 0;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .generate-suggestions-form {
    &__left-side {
      width: 100%;
      min-width: 100%;
      padding: 0 0 24px 0;
      margin: 0 0 24px 0;
      border-width: 0 0 1px 0;
    }
  }
}
</style>
