<template>
  <div class="ui form">
    <div class="field">
      <div class="global-h4">Lodging Details</div>
      <p v-if="usingGrandfatheredLodging" class="cms">
        Do you have a lodging partner that is able to host a creator?<br />
        If not, Shrpa will provide the creator with ${{ hotelCoverageFromShrpa }} to help cover their costs.
      </p>
      <p v-else-if="collabRemainingCounts.mustCoverLodging" class="cms">Creators typically stay <strong>2 nights</strong> in your destination.</p>
      <p v-else class="cms">
        You have purchased the lodging add-on for a subset of your creator visits.<br />
        You can choose to use the add-on for this visit, or arrange lodging yourself.
      </p>
      <!--Note: Also in CollabSummary.vue-->
      <div
        v-if="usingGrandfatheredLodging || !collabRemainingCounts.mustCoverLodging"
        :class="{
          'form-error-highlight': true,
          'form-error-highlight--bg-visible': errors.includes('Lodging Details'),
        }"
      >
        <!--NOTE! Any changes here update VisitChecklist also!-->
        <select class="timeRequired" ref="hotelCovered" v-model="collabInput.hotelNightsCovered" @change="nightsCoveredChanged" style="width: 70vw; max-width: 350px">
          <option disabled :value="null">Please select one</option>
          <!--Note: These need to be numbers, and not strings-->
          <option :value="0">{{ customerNotCoveringLodgingText }}</option>
          <option :value="2">Can arrange a 2-night stay</option>
        </select>
      </div>
    </div>
    <NoteWithIcon v-if="usingGrandfatheredLodging" size="tiny" color="blue">
      <template #icon><IconEmbedded name="bulb_2-5" /></template>
      <template #text>
        <b>Creator Visits with lodging coverage get more applicants, especially from creators further away!</b>
        This is an excellent opportunity to connect with your lodging partners and offer them promotion opportunities.
      </template>
    </NoteWithIcon>
    <template v-if="collabInput.hotelNightsCovered && collabInput.hotelNightsCovered > 0">
      <div class="field" style="margin-top: 0.8em">
        <label class="section-title">Lodging Name</label>
        <p class="cms">Name of the lodging you will arrange? (Can be answered later)</p>
        <input type="text" v-model="collabInput.hotelName" />
      </div>
      <div class="field">
        <label class="section-title">Estimated Lodging Value</label>
        <p class="cms">
          We show this to the creator to convey the total value they're receiving.<br />
          If you aren't sure, just put in an estimate.
        </p>
        <i class="dollar sign icon" style="padding-top: 10px"></i>
        <div
          :class="{
            'form-error-highlight': true,
            'form-error-highlight--bg-visible': errors.includes('Estimated Lodging Value'),
          }"
        >
          <input type="number" v-model.number="collabInput.hotelEstimatedValue" style="width: 80px; display: inline" />
        </div>
      </div>
    </template>
    <div class="field" style="margin-top: 2em">
      <LinkWithIcon class="onboarding-slide__previous-step-link" tag="a" :href="globalGetLink('CollabHotelCompTemplate')" target="_blank" @click="$emit('goToPrevStep')">
        <template #icon><IconEmbedded name="bulb_2" :size="21" /></template>
        <span>Ideas for reaching out to lodging partners</span>
      </LinkWithIcon>
    </div>
  </div>
</template>

<script lang="ts">
import CollabValueCalculator from "@logic/CollabValueCalculator";
import { defineComponent } from "vue";

// Types
import { CollabInput, CustomerCollabRemainingCounts } from "@contracts/collab";
import { ItinerarySummary } from "@contracts/itinerary";

// Components
import CollabCompTags from "@views/PaidCollab/CollabCompTags.vue";
import ItineraryTile from "@components/ItineraryTile.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import SpoilerWithIcon from "@components/SpoilerWithIcon.vue";
import { inject } from "vue";
import logger from "@helpers/Logger";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export default defineComponent({
  name: "StepLodging",

  components: {
    IconEmbedded,
    CollabCompTags,
    ItineraryTile,
    LinkWithIcon,
    NoteWithIcon,
    SpoilerWithIcon,
  },

  props: {
    collabInput: { type: Object as () => CollabInput | null, required: true },
    collabRemainingCounts: { type: Object as () => CustomerCollabRemainingCounts | null, required: false, default: null },
    errors: { type: Array<string>, required: true },
  },

  emits: ["goToPrevStep"],

  data() {
    return {
      globalGetLink: inject("globalGetLink") as any,

      hotelCoverageFromShrpa: CollabValueCalculator.HotelCoverageFromShrpa,
    };
  },

  computed: {
    customerNotCoveringLodgingText(): string {
      if (this.collabInput.hotelCoverageUsed === "Grandfathered") return "Can't arrange Lodging";
      else {
        return `Use Paid Lodging AddOn (${this.collabRemainingCounts.collabHotelsPaidRemaining} remaining)`;
      }
    },
    usingGrandfatheredLodging(): boolean {
      return this.collabInput.hotelCoverageUsed === "Grandfathered";
    },
  },

  mounted() {
    this.$nextTick(() => {
      (this.$refs.hotelCovered as HTMLInputElement)?.focus();
    });
    if (this.collabRemainingCounts.mustCoverLodging) {
      // if the collab must have lodging covered, set to two nights.
      // when lodging is coverages is required, there is no option to change the number of nights
      this.collabInput.hotelNightsCovered = 2;
    }
  },
  methods: {
    nightsCoveredChanged() {
      // NOTE! Duplicated in VisitChecklist!
      if (this.collabInput.hotelCoverageUsed === "Grandfathered") {
        // Nothing to adjust in the old model
        return;
      }

      // Otherwise we update the the hotelCoverageUsed for tracking
      if (this.collabInput.hotelNightsCovered === 0) {
        this.collabInput.hotelCoverageUsed = "CustomerChosePaidAddOn";
      } else {
        this.collabInput.hotelCoverageUsed = "CustomerChoseToArrange";
      }
      logger.info("StepLodging nightsCoveredChanged", this.collabInput.hotelCoverageUsed);
    },
  },
});
</script>

<style scoped lang="scss">
@import "./form-error-highlight.scss";
</style>
