import { getGlobalRemoteLogger } from "@helpers/RemoteLogger";
import GoogleDirectionsService from "@logic/GoogleDirectionsService";
import GoogleMapGenerator from "@logic/GoogleMapGenerator";

// Types
import { Itinerary, ItineraryStep } from "@contracts/itinerary";

const globalRemoteLogger = getGlobalRemoteLogger();

// Should be called anytime a place is added, updated, or removed
export default function setMapFieldsOnAdventure(itinerary: Itinerary, callback?: () => void) {
  // Default the Location (if not set)
  if (!itinerary.location) {
    // find the first step in the itinerary that has a city
    const firstStepWithCity = itinerary.steps.find(s => s.city);
    if (firstStepWithCity) itinerary.location = firstStepWithCity.city;
  }

  // Regenerate the map (unless they override)
  try {
    if (!itinerary.manuallyCreatedMap) {
      itinerary.mapUrl = GoogleMapGenerator.generateMap(itinerary);
    }
  } catch (error) {
    globalRemoteLogger.error(`Failed to Generate Map for adv=${itinerary?.id} by ${itinerary?.sherpaId}: ${error}`, true);
  }

  // Sets as handful of fields from the directions service
  try {
    GoogleDirectionsService.setDirectionsFields(itinerary, callback);
  } catch (error) {
    globalRemoteLogger.error(`Failed to set Directions fields for adv=${itinerary?.id} by ${itinerary?.sherpaId}: ${error.message}`, true);
    // Fire the callback
    if (callback) callback();
  }
}
