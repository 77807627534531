<template>
  <div>
    <component v-if="props.isTitleVisible" :is="'h' + props.titleSize" :class="`global-h${props.titleSize}`" style="margin-bottom: 10px">When will you get paid?</component>

    <ul class="global-ul">
      <li>
        <div>
          <div style="margin-bottom: 4px">
            <b style="font-weight: 600">Shrpa will pay you ${{ props.totalPaymentFromShrpa }} once the content is delivered to the destination.</b>
          </div>
          <span>You have 10 days to create content after your visit, and the destination has 7 days after that to request changes.</span>
        </div>
      </li>
      <li>
        <div>
          <div style="margin-bottom: 4px">
            <b style="font-weight: 600">Shrpa payments are sent via Wingspan.</b>
          </div>
          <span style="margin-top: -4px; display: block">
            You will receive an email invitation from
            <a class="global-a" href="https://www.wingspan.app/" target="_blank">
              Wingspan<IconEmbedded name="external-link_2" :size="17" style="position: relative; top: 3px; left: 2px" color="rgba(0, 0, 0, 0.4)" />
            </a>
          </span>
        </div>
      </li>
      <li>
        <div>
          <div style="margin-bottom: 4px">
            <b style="font-weight: 600">All other comps and activities should be arranged with the destination.</b>
          </div>
          <span>Connect with the destination to ensure everything is arranged.</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";

const props = withDefaults(
  defineProps<{
    totalPaymentFromShrpa: number;
    titleSize?: 1 | 2 | 3 | 4 | 5 | 6;
    isTitleVisible?: boolean;
  }>(),
  {
    totalPaymentFromShrpa: 0,
    titleSize: 3,
    isTitleVisible: true,
  }
);
</script>
