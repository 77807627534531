<template>
  <component
    :class="{
      'link-with-icon': true,

      'link-with-icon--light-gray': props.color === 'light-gray',
      'link-with-icon--teal': props.color === 'teal',
      'link-with-icon--blue': props.color === 'blue',
      'link-with-icon--green': props.color === 'green',
      'link-with-icon--red': props.color === 'red',
      'link-with-icon--orange': props.color === 'orange',

      'link-with-icon--dotted-underline': props.isDottedUnderline,

      'link-with-icon--disabled': props.isDisabled,
    }"
    :is="props.tag === 'input' && props.type === 'file' ? 'div' : props.tag"
    :to="props.to"
    :target="props.target"
    :type="props.type"
    v-bind="$attrs"
  >
    <label class="link-with-icon__file-input-label">
      <input v-if="props.tag === 'input' && props.type === 'file'" class="link-with-icon__file-input" type="file" @change="$event => $emit('change', $event)" />
    </label>
    <span v-if="$slots.icon" class="link-with-icon__icon-wrap" :style="props.iconInset ? `inset: ${props.iconInset}` : ''">
      <slot name="icon" />
    </span>
    <slot />
    <span v-if="$slots.iconRight" class="link-with-icon__icon-wrap" :style="props.iconInset ? `inset: ${props.iconInset}` : ''">
      <slot name="iconRight" />
    </span>
  </component>
</template>

<script setup lang="ts">
export type LinkWithIconColors = "light-gray" | "gray" | "teal" | "blue" | "red" | "orange" | "green";

const props = withDefaults(
  defineProps<{
    tag?: string;
    type?: "" | "file";
    target?: "" | "_blank";
    to?: object;
    color?: LinkWithIconColors;
    isDottedUnderline?: boolean;
    isDisabled?: boolean;
    iconInset?: string;
  }>(),
  {
    tag: "div",
    type: "",
    target: "",
    to: () => ({}),
    color: "gray",
    isDottedUnderline: false,
    isDisabled: false,
    iconInset: "",
  }
);

const emit = defineEmits<{
  (e: "change", value: Event): void;
}>();
</script>

<style scoped lang="scss">
@import "@/scss/hoverCircle";

// Link with icon =============================================================
.link-with-icon {
  display: inline-flex;
  align-items: center;
  position: relative;
  color: #5b5b5b;
  font:
    400 14px/18px "Helvetica Neue",
    sans-serif;
  opacity: 1;
  user-select: none;
  cursor: pointer;
  transition: opacity 0.12s ease-in-out;

  &::before {
    content: "";
    width: calc(100% + 35px);
    height: calc(100% + 20px);
    border-radius: 100px;
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
  }

  &__icon-wrap {
    width: 21px !important;
    min-width: 21px !important;
    height: 21px !important;
    min-height: 21px !important;
    margin-right: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &::before {
      @include hoverCircleNotHovered;
    }
  }

  :deep(i) {
    svg {
      fill: #5b5b5b;
    }
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  :deep(span) {
    text-decoration: underline;
    text-decoration-color: rgba(91, 91, 91, 0.5);
    text-underline-offset: 2px;
    text-decoration-thickness: 1px;
  }

  &--dotted-underline {
    :deep(span) {
      height: 17px;
      display: inline-flex;
      text-decoration: underline;
      text-decoration-color: rgba(91, 91, 91, 0.5);
      text-underline-offset: 2px;
      text-decoration-thickness: 1px;
      text-decoration-style: dashed;
    }
  }

  &--dotted-underline:hover {
    :deep(span) {
      border-color: transparent;
    }
  }

  &:hover :deep(span) {
    text-decoration: none;
  }

  // prettier-ignore
  &--light-gray {
    color: #5b5b5b !important;

    .link-with-icon__icon-wrap::before { background: rgba(91, 91, 91, 0.15); }

    :deep(i) { 
      svg { fill: #aaa; }
    }
    :deep(span) {
      text-decoration-color: rgba(91, 91, 91, 0.5);
    }
  }
  // prettier-ignore
  &--teal {
    color: #118689 !important;

    .link-with-icon__icon-wrap::before { background: rgba(17, 134, 137, 0.15); }

    :deep(i) { 
      svg { fill: #0a6668; }
    }
    :deep(span) {
      text-decoration-color: rgba(17, 134, 137, 0.5);
    }
  }
  // prettier-ignore
  &--blue {
    color: #3583BB !important;

    .link-with-icon__icon-wrap::before { background: rgba(53, 131, 187, 0.15); }

    :deep(i) { 
      svg { fill: rgba(53, 131, 187, 1); }
    }
    :deep(span) {
      text-decoration-color: rgba(53, 131, 187, 0.5);
    }
  }
  // prettier-ignore
  &--green {
    color: #325428 !important;

    .link-with-icon__icon-wrap::before { background: rgba(50, 84, 40, 0.15); }

    :deep(i) { 
      svg { fill: #325428; }
    }
    :deep(span) {
      text-decoration-color: rgba(50, 84, 40, 0.5);
    }
  }
  // prettier-ignore
  &--red {
    color: #D65050 !important;
    
    .link-with-icon__icon-wrap::before { background: rgba(214, 80, 80, 0.15); }

    :deep(i) { 
      svg { fill: #D65050; }
    }
    :deep(span) {
      text-decoration-color: rgba(214, 80, 80, 0.5);
    }
  }
  // prettier-ignore
  &--orange {
    color: #EC573B !important;
    
    .link-with-icon__icon-wrap::before { background: rgba(236, 87, 59, 0.15); }

    :deep(i) { 
      svg { fill: #EC573B; }
    }
    :deep(span) {
      text-decoration-color: rgba(236, 87, 59, 0.5);
    }
  }

  &:hover .link-with-icon__icon-wrap::before {
    @include hoverCircleHovered;
  }

  &__file-input-label {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: 2;
    cursor: pointer;
  }

  &__file-input {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0 auto auto 0;
    opacity: 0;
    pointer-events: none;
  }
}
</style>
