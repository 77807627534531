<template>
  <div class="steps-guide">
    <div
      :class="{
        'steps-guide__list-wrap-1': true,
        'steps-guide__list-wrap-1--with-left-curtain': screenSize === 'mobile' && isLeftCurtainVisible,
        'steps-guide__list-wrap-1--with-right-curtain': screenSize === 'mobile' && isRightCurtainVisible,
      }"
    >
      <ul class="steps-guide__list-wrap-2" @scroll="$event => toggleCurtains(null, $event)" ref="scrollableContainer">
        <!-- Step snippet -->
        <li class="step-snippet steps-guide__step-snippet" v-for="(step, index) in statuses" :key="index">
          <div
            :class="{
              'step-snippet__icon-wrap': true,
              'step-snippet__icon-wrap--gray': !checkIsComplete(step.completedStatus) && !checkIsComplete(statuses[index - 1].completedStatus),
              'step-snippet__icon-wrap--rotate': isCurrentStep(step.completedStatus),
            }"
          >
            <IconEmbedded
              :class="{
                'step-snippet__icon': true,
                'step-snippet__icon--gray': !checkIsComplete(step.completedStatus) && !checkIsComplete(statuses[index - 1].completedStatus),
              }"
              :name="checkIsComplete(step.completedStatus) ? 'check_4' : 'hourglass_3-5'"
              color="rgba(255, 255, 255, 1)"
            />
          </div>
          <div
            v-if="index !== 0"
            :class="{
              'step-snippet__line': true,
              'step-snippet__line--left': true,
              'step-snippet__line--gray': !checkIsComplete(step.completedStatus) && !checkIsComplete(statuses[index - 1].completedStatus),
              // 'step-snippet__line--yellow': isCurrentStep(step.completedStatus),
            }"
          ></div>
          <div
            v-if="index !== statuses.length - 1"
            :class="{
              'step-snippet__line': true,
              'step-snippet__line--right': true,
              'step-snippet__line--gray': !checkIsComplete(step.completedStatus),
              // 'step-snippet__line--yellow': isCurrentStep(statuses[index + 1]?.completedStatus),
            }"
          ></div>
          <div
            :class="{
              'step-snippet__name': true,
              'step-snippet__name--light-gray': !checkIsComplete(step.completedStatus) && !checkIsComplete(statuses[index - 1].completedStatus),
            }"
          >
            {{ getLabel(index) }}
          </div>
        </li>
        <!-- / Step snippet -->
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Ref, inject, nextTick, onMounted, onBeforeUnmount, ref, watch } from "vue";
import CollabStatuses from "@logic/CollabStatuses";

// Types
import { ScreenSize } from "@contracts/screenSize";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export interface Props {
  status: string;
  isCreatorView?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  status: "",
  isCreatorView: false,
});

const collabCompleteStatus = CollabStatuses.CompleteStatusId;
// NOTE: Using completedStatus since it varies our status terms vary in tense (future vs. past)
const statuses = [
  { completedStatus: CollabStatuses.CreatorsChosenStatusId, label: "Find Creator", creatorLabel: "Collab Inputs", description: "Finding the right creator to visit", statusBarPerc: 5 },
  { completedStatus: "60", label: "Book Lodging", description: "todo", statusBarPerc: 22 },
  { completedStatus: "70", label: "Coordinate Activities", description: "todo", statusBarPerc: 41 },
  { completedStatus: "90", label: "Ready for Visit!", description: "todo", statusBarPerc: 62 },
  {
    completedStatus: "109", // Note: This is a little different since we don't show completed as an "active" step
    label: "Review Adventures",
    description: "todo",
    statusBarPerc: 80,
  },
  { completedStatus: CollabStatuses.CompleteStatusId, label: "Complete", description: "todo", statusBarPerc: 100 },
];

onMounted(() => {
  console.info(`StatusBar=${props.status}, ${getStatusBarPercentage()}%`);
});

function checkIsComplete(statusToCheck: string) {
  return +statusToCheck <= +props.status;
}

function getStatusBarPercentage(): number {
  if (props.status === CollabStatuses.CompleteStatusId) return 100;

  // Return the first one that isn't complete (since that's active)
  for (let i = 0; i < statuses.length; i++) {
    let current = statuses[i];
    if (!checkIsComplete(current.completedStatus)) return current.statusBarPerc;
  }
}

function isCurrentStep(statusToCheck: string) {
  var lastCompleted = ""; // 20
  var nextStatus = "1000";
  // Find the last complete and the next status
  for (let i = 0; i < statuses.length; i++) {
    let current = statuses[i];
    if (checkIsComplete(current.completedStatus)) {
      lastCompleted = current.completedStatus;
    } else {
      if (i + 1 < statuses.length) nextStatus = statuses[i + 1].completedStatus;
      break;
    }
  }
  // Active is > lastComplete and less than the nextStatus
  const isActive = +statusToCheck > +lastCompleted && +statusToCheck < +nextStatus;
  console.debug(`statusToCheck=${statusToCheck}, lastCompleted=${lastCompleted}, nextStatus=${nextStatus}, isActive=${isActive}, `);
  return isActive;
}

function getLabel(index: number) {
  const status = statuses[index];
  if (props.isCreatorView && status.creatorLabel) return status.creatorLabel;

  return status.label;
}

// ============================================================================
const screenSize = inject("screenSize") as Ref<ScreenSize>;

// Show/hide scroll curtains ==================================================
const isLeftCurtainVisible = ref(false);
const isRightCurtainVisible = ref(false);

const scrollableContainer = ref<HTMLDivElement>(null);

onMounted(async () => {
  window.addEventListener("resize", toggleCurtains);

  await nextTick();
  toggleCurtains();
});
onBeforeUnmount(() => {
  window.removeEventListener("resize", toggleCurtains);
});

function toggleCurtains(_?: any, event?: Event): void {
  if (screenSize.value !== "mobile") {
    isLeftCurtainVisible.value = false;
    isRightCurtainVisible.value = false;
    return;
  }

  const target = (event?.target as Element) || scrollableContainer.value;

  isLeftCurtainVisible.value = target.scrollLeft > 5;
  isRightCurtainVisible.value = target.scrollLeft < target.scrollWidth - target.getBoundingClientRect().width - 5;
}

// Scroll to the last active element if necessary =============================
function scrollToLastActiveStep() {
  const lastActiveStepIndex = statuses.reduce((acc, item, idx) => (checkIsComplete(item.completedStatus) ? idx : acc), 0);

  const targetIndex = lastActiveStepIndex + 1 > statuses.length ? statuses.length : lastActiveStepIndex + 1;
  if (targetIndex <= 1) return;

  const containerWidth = scrollableContainer.value.getBoundingClientRect().width;

  scrollableContainer.value.scrollLeft = Math.round((containerWidth / statuses.length) * targetIndex) - 15;
}

onMounted(async () => {
  await nextTick();
  scrollToLastActiveStep();
});

watch(
  () => statuses,
  async () => {
    await nextTick();
    scrollToLastActiveStep();
  },
  { deep: true }
);
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Step snippet ===============================================================
.step-snippet {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 0;

  &__icon-wrap {
    width: 28px;
    height: 28px;
    margin-bottom: 6px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(0deg);
    color: #fff;
    font-size: 16px;
    line-height: 16px;
    background: #118689;

    &--gray {
      background: #d5d5d5;
    }

    @keyframes spinningClockAnimation {
      0% {
        transform: rotate(0deg);
      }
      50% {
        transform: rotate(180deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    &--rotate {
      animation: spinningClockAnimation 1.5s ease-in-out infinite;
    }
  }

  &__icon {
    width: 18px;
    height: 18px;

    &--gray {
      width: 14px;
      height: 14px;

      :deep(svg) {
        fill: #fff;
      }
    }
  }

  &__line {
    width: calc(50% + 27px);
    height: 4px;
    position: absolute;
    top: 12px;
    z-index: -1;
    font-size: 0;
    line-height: 0;
    background: #7db8b9;

    &--left {
      left: -27px;
    }
    &--right {
      right: -27px;
    }
    &--gray {
      background: #eaeaea;
    }
    &--yellow {
      background: #f0e7b8;
    }
  }

  &__name {
    height: 15px;
    font:
      700 14px/16px "Quicksand",
      sans-serif;
    text-align: center;

    &--light-gray {
      color: rgba(0, 0, 0, 0.4);
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .step-snippet {
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .step-snippet {
    &__name {
      height: auto;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .step-snippet {
    &__name {
      height: auto;
    }
  }
}

// Steps guide ================================================================
.steps-guide {
  width: 100%;
  padding-bottom: 31px;
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;

  &__list-wrap-1 {
    position: relative;
    z-index: 0;

    &::before,
    &::after {
      width: 40px;
      height: calc(100% - 10px);
      position: absolute;
      inset: 0 auto auto 0;
      z-index: 2;
      background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
      pointer-events: none;
    }

    &::after {
      inset: 0 0 auto auto;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    }

    &--with-left-curtain {
      &::before {
        content: "";
      }
    }
    &--with-right-curtain {
      &::after {
        content: "";
      }
    }
  }

  &__list-wrap-2 {
    padding: 0 0 10px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    list-style: none;
    position: relative;
    scroll-behavior: smooth;
  }

  &__step-snippet {
    width: 189px;
    margin-right: 27px;

    &:last-child {
      margin-right: 0;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .steps-guide {
    &__step-snippet {
      width: 164px;
      margin-right: 24px;
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .steps-guide {
    &__step-snippet {
      width: 109px;
      margin-right: 22px;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .steps-guide {
    &__list-wrap-2 {
    }

    &__step-snippet {
      width: 69px;
      margin-right: 18px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .steps-guide {
    padding-bottom: 21px;

    &__list-wrap-2 {
      justify-content: flex-start;
      overflow-x: scroll;
      overflow-y: hidden;
    }

    &__step-snippet {
      width: 69px;
      min-width: 69px;
      margin-right: 18px;
    }
  }
}
</style>
