<template>
  <!--Note: Hide/show is owned by the parent since the buttons firing this live there-->
  <SrpModal :isVisible="visible" @close="close" :isClosable="false" className="new-dates-modal-message">
    <template #header><h2 class="global-h2">Proposed Visit Date Change</h2></template>
    <template #content>
      <div>The {{ isCreatorFlow ? "community" : "creator" }} has proposed a date change for this collaboration. Are you ok with these changes?</div>
      <h3 class="ui header">Current Dates</h3>
      <span>{{ currentVisitDateString }}</span>
      <h3 class="ui primary header">New Proposed Dates</h3>
      <span
        ><b>{{ dateChangeProposalString }}</b></span
      >
      <div v-if="collabInput.proposedDateChange">"{{ collabInput.proposedDateChange.changeReason }}"</div>
      <div style="margin-top: 2rem">
        <label class="approve-card" style="max-width: 250px; display: flex; align-items: center">
          <input type="radio" name="approve/decline" :value="acceptConst" v-model="acceptChangeToggle" style="width: 13px; margin-right: 10px" />
          Approve New Visit Dates
        </label>
        <label class="decline-card" style="max-width: 250px; display: flex; align-items: center">
          <input type="radio" name="approve/decline" :value="declineConst" v-model="acceptChangeToggle" style="width: 13px; margin-right: 10px" />
          Decline New Visit Dates
        </label>
        <template v-if="acceptChangeToggle === declineConst">
          <h5 class="ui header">Please explain why this doesn't work</h5>
          <div class="ui input fluid" style="margin-top: 20px">
            <textarea v-model="declineReason" type="text" placeholder="" style="width: 800px"></textarea>
          </div>
        </template>
      </div>
    </template>
    <template #footer>
      <div>
        <h3 v-if="error" style="display: inline; margin-right: 20px; color: red">{{ error }}</h3>
        <h3 v-if="saving" style="display: inline; margin-right: 8px">Saving...</h3>
        <SrpButton @click="close" color="gray" fill="outlined" style="margin-right: 10px">Cancel</SrpButton>
        <SrpButton :isDisabled="acceptChangeToggle === null || saving" @click="save">{{ saveButtonText }}</SrpButton>
      </div>
    </template>
  </SrpModal>
</template>

<script lang="ts">
import axios from "axios";
import { defineComponent } from "vue";
import { CollabCreatorInput, CollabDateChangeResponsePayload, CollabInput } from "@contracts/collab";
import SrpModal from "@components/ui/SrpModal.vue";
import SrpButton from "@components/ui/SrpButton.vue";

export default defineComponent({
  name: "CollabDateChangeResponse",

  components: {
    SrpButton,
    SrpModal,
  },

  props: {
    visible: { type: Boolean, required: true },
    isCreatorFlow: { type: Boolean, required: true },
    currentVisitDateString: { type: [String, null], required: false },
    dateChangeProposalString: { type: [String, null], required: false },
    collabInput: { type: Object as () => CollabInput | null, required: false },
    creatorInput: { type: Object as () => CollabCreatorInput | null, required: false },
  },

  emits: ["close", "update:visible"],

  data() {
    return {
      saving: false,
      error: null,
      acceptConst: "approve",
      declineConst: "decline",
      acceptChangeToggle: null, // This is annoyingly a string and not a boolean
      declineReason: "" as string,
    };
  },

  computed: {
    saveButtonText(): string {
      if (!this.acceptChangeToggle) return "Please Accept or Decline";

      return this.acceptChangeToggle === this.acceptConst ? "Accept New Date" : "Decline New Date";
    },
  },

  methods: {
    async save() {
      this.error = null;
      if (this.acceptChangeToggle !== this.acceptConst && this.declineReason.length < 30) {
        this.error = "More decline reason detail required";
        return;
      }

      this.saving = true;
      const config = { headers: { "Content-Type": "application/json" } };
      let uri = `${import.meta.env.VITE_API_URL}/collab-date-change/${this.collabInput.customerId}/inputs/${this.collabInput.id}?isCreatorResponding=${this.isCreatorFlow}&acceptedChange=${
        this.acceptChangeToggle === this.acceptConst
      }`;
      const response = await axios.put(uri, JSON.stringify(this.declineReason), config);
      let data = response.data as CollabDateChangeResponsePayload;
      this.collabInput.proposedDateChange = data.proposedDateChange;
      // NOTE: Also need to repeat a bit of the state setting from the ClientInput
      this.creatorInput.proposedVisitDateTime = data.creatorInput.proposedVisitDateTime;
      this.creatorInput.numberOfDaysStaying = data.creatorInput.numberOfDaysStaying;
      this.collabInput.collaboration.actualTiming = data.creatorInput.proposedVisitDateTime;
      this.saving = false;

      this.close();
    },
    close() {
      this.declineReason = "";
      this.acceptChangeToggle = null;
      this.$emit("close");
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";

.new-dates-modal-message {
  .action-buttons {
    margin-top: 3rem;
  }

  /* Some pages apply a broad header margin, which we don't want here (ex. featured-expeditions) */
  .header-margin-override {
    margin-top: 0;
  }

  .ui.small.info.message h3 {
    margin-bottom: 0.5rem !important;
  }
}
.approve-card {
  padding: 16px;
  margin-bottom: 8px !important;
  border-radius: 8px;
  font-weight: 600;
  background-color: $off-white;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.06) !important;
  }

  &.selected {
    background-color: $brand-color-lighter;
  }

  &.selected:hover {
    background-color: rgba(0, 0, 0, 0.07) !important;
  }
}
.decline-card {
  padding: 16px;
  border-radius: 8px;
  font-weight: 600;
  background-color: $off-white;
  cursor: pointer;

  &.selected {
    background-color: $orange-opaque;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.06) !important;
  }

  &.selected {
    background-color: $brand-color-lighter;
  }

  &.selected:hover {
    background-color: rgba(0, 0, 0, 0.07) !important;
  }
}
</style>
