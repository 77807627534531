<template>
  <div class="referrals-code-n-link">
    <div class="referrals-code-n-link__gray-bg-section">
      <div class="referrals-code-n-link__header">
        <h1 class="global-h1 referrals-code-n-link__title">Share Shrpa, Get Paid!</h1>
        <div class="referrals-code-n-link__subtitle">
          Know other creators who want to work with destinations?<br />
          <b>Receive ${{ referralBountyUSD }} for each new creator once they apply to their first collab!</b>
        </div>
      </div>

      <!-- OR section -->
      <div class="or-section referrals-code-n-link__or-section">
        <div class="or-section__left-side">
          <div class="or-section__title">Share this referral code with other creators!</div>
          <!-- Referral code snippet -->
          <div v-if="userProfileStore.getViewingUserProfile.referralCode" class="ref-code-snippet or-section__ref-code-snippet">
            <div class="ref-code-snippet__code">{{ userProfileStore.getViewingUserProfile.referralCode }}</div>

            <CopyLink class="ref-code-snippet__copy-btn" :text="userProfileStore.getViewingUserProfile.referralCode" />
          </div>
          <!-- / Referral code snippet -->

          <!-- Referral code snippet -->
          <div v-else class="ref-code-snippet or-section__ref-code-snippet">
            <div class="ref-code-snippet__set-code-form">
              <input class="ref-code-snippet__input global-textarea" type="text" placeholder="your code" maxlength="25" v-model="_referralCode" @input="validationMsg = ''" />
              <SrpButton class="ref-code-snippet__set-btn" @click="setReferralCode" :isDisabled="!_referralCode.trim().length">Set</SrpButton>
            </div>
            <div v-if="userProfileStore.isRefferalCodeAlreadyUsed || validationMsg" class="ref-code-snippet__error">
              {{ userProfileStore.isRefferalCodeAlreadyUsed ? "This code is already in use" : "" }}
              {{ validationMsg ? validationMsg : "" }}
            </div>
          </div>
          <!-- / Referral code snippet -->

          <div v-if="userProfileStore.getViewingUserProfile.referralCode" class="or-section__subscript">creators can enter this during sign-up</div>
        </div>

        <div class="or-section__or-circle">or</div>

        <div
          :class="{
            'or-section__right-side': true,
            'or-section__right-side--disabled': !userProfileStore.getViewingUserProfile.referralCode,
          }"
        >
          <div class="or-section__title">Send creators this link</div>
          <!-- Referral link snippet -->
          <div class="ref-link-snippet or-section__ref-link-snippet">
            <div class="ref-link-snippet__link">
              {{ `shrpa.com/learn?ref=${userProfileStore.getViewingUserProfile.referralCode ? userProfileStore.getViewingUserProfile.referralCode : "&lt;YOUR_CODE&gt;"}` }}
            </div>

            <CopyLink
              v-if="userProfileStore.getViewingUserProfile.referralCode"
              class="ref-link-snippet__copy-btn"
              :text="`shrpa.com/learn?ref=${userProfileStore.getViewingUserProfile.referralCode}`"
            />
          </div>
          <!-- Referral link snippet -->
        </div>
      </div>
      <!-- / OR section -->
    </div>

    <ul class="referrals-code-n-link__terms-checklist">
      <!-- Term snippet -->
      <li class="term-snippet referrals-code-n-link__term-snippet">
        <IconEmbedded class="term-snippet__icon" name="check_4" :size="20" />
        <div class="term-snippet__text">Creators must be US-based</div>
      </li>
      <!-- / Term snippet -->

      <!-- Term snippet -->
      <li class="term-snippet referrals-code-n-link__term-snippet">
        <IconEmbedded class="term-snippet__icon" name="check_4" :size="20" />
        <div class="term-snippet__text">Creators must be real people who can complete a Shrpa collaboration</div>
      </li>
      <!-- / Term snippet -->

      <!-- Term snippet -->
      <li class="term-snippet referrals-code-n-link__term-snippet">
        <IconEmbedded class="term-snippet__icon" name="check_4" :size="20" />
        <div class="term-snippet__text">Payment made in ${{ paymentSegmentUSD }} segments</div>
      </li>
      <!-- / Term snippet -->
    </ul>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
const route = useRoute();

// Components
import CopyLink from "@components/ui/CopyLink.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import SrpButton from "@components/ui/SrpButton.vue";

// Stores
import { useUserProfileStore } from "@stores/userProfileStore";
const userProfileStore = useUserProfileStore();

// Referral bounty constant (in USD) ==========================================
const referralBountyUSD = 15;

// Payment segment constant (in USD) ==========================================
const paymentSegmentUSD = 30;

// ============================================================================
const _referralCode = ref<string>("");

onMounted(async () => {
  await userProfileStore.impersonateProfile(route.params.creatorId as string);

  _referralCode.value = userProfileStore.getViewingUserProfile.instagramHandle || "";
});

// Set referral code ==========================================================
const validationMsg = ref<string>("");

function setReferralCode() {
  if (_referralCode.value.trim().length < 5) {
    validationMsg.value = "Code must be at least 5 characters";
    return;
  }
  userProfileStore.setReferralCode(_referralCode.value);
}
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Referral code snippet ======================================================
.ref-code-snippet {
  padding: 8px 45px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -1;
    background: rgba(240, 231, 184, 1);
    /* prettier-ignore */
    clip-path: polygon(0 0, 100% 0, 100% 0, 98% 10%, 100% 20%, 98% 30%, 100% 40%, 98% 50%, 100% 60%, 98% 70%, 100% 80%, 98% 90%, 100% 100%, 0 100%, 2% 90%, 0 80%, 2% 70%, 0 60%, 2% 50%, 0 40%, 2% 30%, 0 20%, 2% 10%, 0 0);
  }

  &__code {
    text-align: center;
    height: 26px;
    font:
      600 35px/35px "Courier New",
      monospace;
    color: rgba(69, 59, 6, 1);
    user-select: all;
  }

  &__copy-btn {
  }

  &__set-code-form {
    display: flex;
    align-items: center;
  }

  &__input {
    margin-right: 7px;
  }

  &__set-btn {
  }

  &__error {
    color: rgba(255, 50, 50, 1);
    font-family: sans-serif;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .ref-code-snippet {
    padding: 8px 25px 10px;
  }
}

// Referral link snippet ======================================================
.ref-link-snippet {
  min-height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__link {
    margin-bottom: -3px;
    color: rgba(17, 134, 137, 1) !important;
    text-decoration: underline;
    text-align: center;
    font: 18px/23px sans-serif;
    user-select: all;
  }

  &__copy-btn {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .ref-link-snippet {
    min-height: 0;
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .ref-link-snippet {
    min-height: 0;
  }
}

// OR section =================================================================
.or-section {
  padding: 40px 0;
  border-top: 1px rgba(0, 0, 0, 0.1) solid;
  display: flex;
  align-items: center;
  justify-content: center;

  &__left-side {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__right-side {
    display: flex;
    flex-direction: column;
    align-items: center;

    &--disabled {
      opacity: 0.2;
      pointer-events: none;
      user-select: none;
    }
  }

  &__or-circle {
    width: 50px;
    min-width: 50px;
    height: 50px;
    padding-bottom: 4px;
    margin: 0 35px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
    font:
      600 26px/26px "Quicksand",
      sans-serif;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border: 1px rgba(214, 214, 214, 1) solid;
      border-radius: 100px;
      position: absolute;
      inset: 0 auto auto 0;
      z-index: -1;
      background: rgba(255, 255, 255, 1);
    }

    &::after {
      content: "";
      width: 0;
      height: 250%;
      border: 1px rgba(0, 0, 0, 0.1) solid;
      border-width: 0 0 0 1px;
      position: absolute;
      inset: 50% auto auto 50%;
      z-index: -2;
      transform: translateY(-50%);
    }
  }

  &__title {
    margin-bottom: 7px;
    color: rgba(91, 91, 91, 1);
    font-weight: bold;
    font-family: sans-serif;
  }

  &__ref-code-snippet {
    margin-bottom: 7px;
  }

  &__ref-link-snippet {
  }

  &__subscript {
    color: rgba(91, 91, 91, 1);
    font-family: sans-serif;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .or-section {
    padding: 40px 0;
    flex-direction: column;

    &__or-circle {
      margin: 0 0 20px;

      &::after {
        width: 50vw;
        height: 0;
        border-width: 1px 0 0 0 !important;
        inset: 50% auto auto calc(50% - 25vw);
      }
    }

    &__left-side {
      margin-bottom: 20px;
    }

    &__right-side {
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .or-section {
    padding: 40px 0;
    flex-direction: column;

    &__or-circle {
      margin: 0 0 20px;

      &::after {
        width: 50vw;
        height: 0;
        border-width: 1px 0 0 0 !important;
        inset: 50% auto auto calc(50% - 25vw);
      }
    }

    &__left-side {
      margin-bottom: 20px;
    }

    &__right-side {
    }
  }
}

// Term snippet ===============================================================
.term-snippet {
  display: flex;
  align-items: flex-start;

  &__icon {
    margin-right: 5px;
  }

  &__text {
    color: rgba(91, 91, 91, 1);
    font: 14px/18px sans-serif;
  }
}

// Referrals code & link ======================================================
.referrals-code-n-link {
  &__gray-bg-section {
    padding: 43px 0 12px;
    margin-bottom: 25px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(243, 243, 243, 1);
  }

  &__header {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    margin-bottom: 8px;
    text-align: center;
    font-weight: 500;
  }

  &__subtitle {
    color: rgba(91, 91, 91, 1);
    text-align: center;
    font: 14px/18px sans-serif;
  }

  &__or-section {
    width: calc(100% - 50px);
    max-width: 890px;
  }

  &__terms-checklist {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    list-style: none;
  }

  &__term-snippet {
    max-width: 28%;
    margin: 0 20px;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .referrals-code-n-link {
    &__header {
      padding: 0 25px;
    }

    &__gray-bg-section {
      padding-bottom: 0;
    }

    &__terms-checklist {
      flex-wrap: wrap;
    }

    &__term-snippet {
      max-width: 40%;
      margin: 0 10px 14px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .referrals-code-n-link {
    &__header {
      padding: 0 25px;
    }

    &__gray-bg-section {
      padding-bottom: 0;
    }

    &__terms-checklist {
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    &__term-snippet {
      max-width: 100%;
      margin: 0 0 14px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
