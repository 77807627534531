<template>
  <div
    :class="{
      'reorder-snippet': true,
      'reorder-snippet--invisible': !isSnippetVisible,
    }"
    @touchstart.stop.prevent="() => {}"
  >
    <div class="reorder-snippet__title">Reorder</div>
    <div class="reorder-snippet__counter-n-buttons">
      <div
        :class="{
          'reorder-snippet__arrow-btn': true,
          'reorder-snippet__arrow-btn--left': true,
          'reorder-snippet__arrow-btn--invisible': !props.isLeftArrowVisible,
        }"
        @click="emit('clickLeftArrow')"
        ref="domRefArrowBtnLeft"
      >
        <IconEmbedded name="caret-left_2-5" :size="30" />
      </div>
      <div class="reorder-snippet__counter">
        {{ _index + 1 }}
      </div>
      <div
        :class="{
          'reorder-snippet__arrow-btn': true,
          'reorder-snippet__arrow-btn--right': true,
          'reorder-snippet__arrow-btn--invisible': !props.isRightArrowVisible,
        }"
        @click="emit('clickRightArrow')"
        ref="domRefArrowBtnRight"
      >
        <IconEmbedded name="caret-right_2-5" :size="30" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, nextTick, onMounted, watch } from "vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

const props = withDefaults(
  defineProps<{
    index: number;
    isRightArrowVisible: boolean;
    isLeftArrowVisible: boolean;
  }>(),
  {
    index: 0,
    isRightArrowVisible: true,
    isLeftArrowVisible: true,
  }
);

const emit = defineEmits<{
  (e: "clickRightArrow"): void;
  (e: "clickLeftArrow"): void;
}>();

// Fix index number blinking ==================================================
const _index = ref<number>(0);
const isSnippetVisible = ref<boolean>(true);
let turnOnTimeout: ReturnType<typeof setTimeout> | null = null;

onMounted(() => {
  _index.value = props.index;
});

watch(
  () => props.index,
  async () => {
    isSnippetVisible.value = false;
    clearTimeout(turnOnTimeout);

    turnOnTimeout = setTimeout(async () => {
      _index.value = props.index;
      await nextTick();
      isSnippetVisible.value = true;
    }, 100);
  }
);

// ============================================================================
// Add touch listeners to arrows to override image drag event.preventDefault() on touch devices
const domRefArrowBtnRight = ref<HTMLElement | null>(null);
const domRefArrowBtnLeft = ref<HTMLElement | null>(null);

onMounted(async () => {
  await nextTick();

  domRefArrowBtnRight.value.addEventListener("touchstart", event => event.stopPropagation());
  domRefArrowBtnLeft.value.addEventListener("touchstart", event => event.stopPropagation());
});
</script>

<style scoped lang="scss">
// Reorder snippet ============================================================
.reorder-snippet {
  padding: 11px 16px 16px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.45);
  backdrop-filter: blur(10px);
  cursor: default;
  opacity: 1;
  transition: opacity 0.1s ease-in-out;

  &--invisible {
    opacity: 0;
    transition: none;
  }

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    font-size: 18px;
    line-height: 18px;
  }

  &__counter-n-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__arrow-btn {
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;

    :deep(svg) {
      fill: rgba(255, 255, 255, 1);
    }

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 200px;
      position: absolute;
      inset: 0 auto auto 0;
      transform: scale(1);
      background: rgba(255, 255, 255, 0.2);
      transition: transform 0.05s ease-in-out;
    }

    &:hover::after {
      transform: scale(1.14);
    }

    &--left {
    }
    &--right {
      padding-left: 3px;
    }

    &--invisible {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__counter {
    width: 30px;
    margin: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    line-height: 40px;
    text-align: center;
  }
}
</style>
