<template>
  <div class="social-button-wrap">
    <!-- Social button -->
    <div
      :class="{
        'social-button': true,
        'social-button--twitter': props.twitterHandle,
        'social-button--facebook': props.facebookUrl,
        'social-button--tiktok': props.tiktokHandle,
        'social-button--instagram': props.instagramHandle,
        'social-button--youtube': props.youtubeUrl,
        'social-button--disabled': isCopiedMsgVisible,

        'social-button--no-bg': props.isOnlyIcon,

        'social-button-wrap__button-itself': true,
      }"
    >
      <div v-if="isCopiedMsgVisible" class="social-button__copied-msg">
        <!--<IconEmbedded class="social-button__check-icon" name="check_3 />-->
        Copied
      </div>

      <div
        :class="{
          'social-button__icon-n-text': true,
          'social-button__icon-n-text--invisible': isCopiedMsgVisible,
          'social-button__icon-n-text--no-bg': props.isOnlyIcon,
        }"
      >
        <component
          :class="{
            'social-button__a': true,
            'social-button__a--twitter': props.twitterHandle,
            'social-button__a--facebook': props.facebookUrl,
            'social-button__a--tiktok': props.tiktokHandle,
            'social-button__a--instagram': props.instagramHandle,
            'social-button__a--youtube': props.youtubeUrl,

            'social-button__a--no-paddings': props.isOnlyIcon,
            'social-button__a--height-auto': props.isOnlyIcon,
          }"
          :is="props.isOnlyCopy ? 'span' : 'a'"
          :href="href"
          target="_blank"
          @click="copyURLOrHandle"
        >
          <template v-if="props.instagramHandle">
            <IconEmbedded
              :class="{
                'social-button__icon': true,
                'social-button__icon--instagram': true,
                'social-button__icon--width-auto': props.isOnlyIcon,
                'social-button__icon--no-right-margin': props.isOnlyIcon,
                'social-button__icon--with-hover': props.isOnlyIcon,
              }"
              :size="props.iconSize"
              name="instagram"
              :color="props.isOnlyIcon ? props.iconColor : 'rgba(255, 255, 255, 1)'"
            />
            <span v-if="props.isWithText && !props.isOnlyIcon" class="social-button__text">Instagram</span>
          </template>

          <template v-if="props.tiktokHandle">
            <IconEmbedded
              :class="{
                'social-button__icon': true,
                'social-button__icon--tiktok': true,
                'social-button__icon--width-auto': props.isOnlyIcon,
                'social-button__icon--no-right-margin': props.isOnlyIcon,
                'social-button__icon--with-hover': props.isOnlyIcon,
              }"
              :size="props.iconSize"
              name="tiktok"
              :color="props.isOnlyIcon ? props.iconColor : 'rgba(255, 255, 255, 1)'"
            />
            <span v-if="props.isWithText && !props.isOnlyIcon" class="social-button__text">TikTok</span>
          </template>

          <template v-if="props.facebookUrl">
            <IconEmbedded
              :class="{
                'social-button__icon': true,
                'social-button__icon--facebook': true,
                'social-button__icon--width-auto': props.isOnlyIcon,
                'social-button__icon--no-right-margin': props.isOnlyIcon,
                'social-button__icon--with-hover': props.isOnlyIcon,
              }"
              :size="props.iconSize"
              name="facebook"
              :color="props.isOnlyIcon ? props.iconColor : 'rgba(255, 255, 255, 1)'"
            />
            <span v-if="props.isWithText && !props.isOnlyIcon" class="social-button__text">Facebook</span>
          </template>

          <template v-if="props.youtubeUrl">
            <IconEmbedded
              :class="{
                'social-button__icon': true,
                'social-button__icon--youtube': true,
                'social-button__icon--width-auto': props.isOnlyIcon,
                'social-button__icon--no-right-margin': props.isOnlyIcon,
                'social-button__icon--with-hover': props.isOnlyIcon,
              }"
              :size="props.iconSize"
              name="youtube"
              :color="props.isOnlyIcon ? props.iconColor : 'rgba(255, 255, 255, 1)'"
            />
            <span v-if="props.isWithText && !props.isOnlyIcon" class="social-button__text">YouTube</span>
          </template>

          <template v-if="props.twitterHandle">
            <IconEmbedded
              :class="{
                'social-button__icon': true,
                'social-button__icon--xwitter': true,
                'social-button__icon--width-auto': props.isOnlyIcon,
                'social-button__icon--no-right-margin': props.isOnlyIcon,
                'social-button__icon--with-hover': props.isOnlyIcon,
              }"
              :size="props.iconSize"
              name="xwitter"
              :color="props.isOnlyIcon ? props.iconColor : 'rgba(255, 255, 255, 1)'"
            />
            <span v-if="props.isWithText && !props.isOnlyIcon" class="social-button__text">Twitter</span>
          </template>

          <IconEmbedded v-if="props.isOnlyCopy && !props.isOnlyIcon" class="social-button__copy-icon" name="copy_2" />
        </component>
      </div>
    </div>
    <!-- / Social button -->

    <CopyText v-if="props.isCopyIconVisible" class="social-button-wrap__copy-button" :text="href" :showText="false" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import CopyText from "@components/CopyText.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

const props = withDefaults(
  defineProps<{
    twitterHandle?: string;
    facebookUrl?: string;
    tiktokHandle?: string;
    instagramHandle?: string;
    youtubeUrl?: string;
    isOnlyCopy?: boolean; // don't redirect anywhere, replace tag "a" with "span" and only copy the url or handle
    isCopyIconVisible?: boolean;
    isWithText?: boolean;
    isOnlyIcon?: boolean; // shows only icon without the background, text and the copy button
    iconSize?: number;
    iconColor?: string;
  }>(),
  {
    twitterHandle: "",
    facebookUrl: "",
    tiktokHandle: "",
    instagramHandle: "",
    youtubeUrl: "",
    isOnlyCopy: false,
    isCopyIconVisible: false,
    isWithText: true,
    isOnlyIcon: false,
    iconSize: 19,
    iconColor: "rgba(140, 140, 140, 1)",
  }
);

// Generate href ==============================================================
const href = computed(() => {
  if (props.twitterHandle) return `https://www.twitter.com/${props.twitterHandle}`;
  if (props.facebookUrl) return props.facebookUrl;
  if (props.tiktokHandle) return `https://www.tiktok.com/@${props.tiktokHandle}`;
  if (props.instagramHandle) return `https://www.instagram.com/${buildInstagramHandle(props.instagramHandle)}`;
  if (props.youtubeUrl) return props.youtubeUrl;
  return "";
});

// ============================================================================
function buildInstagramHandle(handle: string) {
  if (!handle) return "";
  handle = handle.trim();
  // As of Dec 2021, Instagram has a bug where occasionally a link without a trailing slash will fail
  if (!handle.endsWith("/")) handle += "/";

  return handle;
}

// Copy URL or handle =========================================================
const isCopiedMsgVisible = ref(false);
function copyURLOrHandle() {
  if (!props.isOnlyCopy) return;

  let textToCopy = "";
  if (props.twitterHandle) textToCopy = `@${props.twitterHandle}`;
  if (props.facebookUrl) textToCopy = props.facebookUrl;
  if (props.tiktokHandle) textToCopy = `@${props.tiktokHandle}`;
  if (props.instagramHandle) textToCopy = `@${props.instagramHandle}`;
  if (props.youtubeUrl) textToCopy = props.youtubeUrl;

  navigator.clipboard.writeText(textToCopy).then(
    () => {
      console.info("Copied to clipboard: " + textToCopy);
    },
    function (err) {
      console.error("Failed to copy to clipboard: ", err);
    }
  );
  isCopiedMsgVisible.value = true;

  setTimeout(() => (isCopiedMsgVisible.value = false), 3000);
}
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";

// Social button ==============================================================
.social-button {
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  position: relative;
  z-index: 0;
  color: #fff;
  background: black;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 4px;
    outline: 15px transparent solid;
    outline-offset: -15px;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -2;
    transition:
      width 0.07s ease-in-out,
      outline 0.07s ease-in-out,
      left 0.07s ease-in-out;
  }

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 4px;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -1;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition:
      width 0.07s ease-in-out,
      left 0.07s ease-in-out,
      opacity 0.07s ease-in-out;
  }

  &--disabled {
    pointer-events: none;
  }

  &:hover::before {
    width: calc(100% + 4px);
    left: -2px;
  }

  &:hover::after {
    width: calc(100% + 4px);
    left: -2px;
    opacity: 1;
  }

  &--no-bg {
    background: transparent;

    &::before,
    &::after {
      display: none;
    }
  }

  &--twitter {
    &::before {
      background: #1c9cea;
    }
  }
  &--facebook {
    &::before {
      background: #3b5998;
    }
  }
  &--tiktok {
    &::before {
      background: #000;
    }
  }
  &--instagram {
    &::before {
      background: #49769c;
    }
  }
  &--youtube {
    &::before {
      background: #f70000;
    }
  }

  &__copied-msg {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 0 auto auto 0;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    pointer-events: none;
  }

  &__check-icon {
    width: 18px;
    height: 18px;
    position: relative;
    top: -1px;
    font-size: 18px;
    line-height: 18px;
  }

  &__icon-n-text {
    width: 100%;
    height: 100%;
    display: flex;

    &--invisible {
      opacity: 0;
    }
  }

  &__a {
    width: 100%;
    height: 30px;
    padding: 0 12px;
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
    color: #fff !important;
    font-weight: 600;
    user-select: none;
    cursor: pointer;

    &--no-paddings {
      padding: 0;
    }

    &--height-auto {
      height: auto;
    }
  }

  &__icon {
    box-sizing: border-box;
    margin-right: 5px;
    display: inline-flex;
    position: relative;
    top: -1px;
    font-size: 19px;
    line-height: 19px;

    &--tiktok {
      padding: 1px 0 0 1px;
      font-size: 17px;
      line-height: 17px;

      &::before {
        filter: drop-shadow(1px 0px 0px #fd3e3e) drop-shadow(-1px -1px 0px #4de8f4);
      }
    }

    &--width-auto {
      width: auto;
    }

    &--no-right-margin {
      margin-right: 0;
    }

    &--xwitter.social-button__icon--with-hover:hover {
      :deep(svg) {
        fill: $color-social-xwitter;
      }
    }
    &--facebook.social-button__icon--with-hover:hover {
      :deep(svg) {
        fill: $color-social-facebook;
      }
    }
    &--tiktok.social-button__icon--with-hover:hover {
      :deep(svg) {
        fill: $color-social-tiktok;
      }
    }
    &--instagram.social-button__icon--with-hover:hover {
      :deep(svg) {
        fill: $color-social-instagram;
      }
    }
    &--youtube.social-button__icon--with-hover:hover {
      :deep(svg) {
        fill: $color-social-youtube;
      }
    }
  }

  &__text {
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__copy-icon {
    width: 16px;
    height: 16px;
    margin-left: -2px;
    font-size: 16px;
    line-height: 16px;
  }
}

// Social button wrap =========================================================
.social-button-wrap {
  display: flex;
  align-items: center;

  &__button-itself {
    width: 100%;
    outline-style: none;
  }

  &__copy-button {
    outline-style: none;
  }
}
</style>
