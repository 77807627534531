<template>
  <NoteWithIcon class="login-error--header" color="yellow">
    <template #text><h2>Google login does not work in Instagram</h2></template>
  </NoteWithIcon>
  <div v-if="internalState === 'display-issue'" class="login-error--instructions">
    <p><strong>What now?</strong></p>
    <ol>
      <li>Open <img class="browser-icon" :src="safariLogo" /> Safari or <img class="browser-icon" :src="chromeLogo" /> Chrome directly</li>
      <li>Go to <strong>shrpa.com</strong></li>
      <li>Log in with Google</li>
    </ol>
    <p><strong>or</strong></p>
    <div class="login-error--actions">
      <SrpButton :is-disabled="isLoading" class="login-error--action" color="teal" tag="button" @click="sendEmail">
        <template #icon><IconEmbedded name="envelope_2" /></template>
        Email me a link
      </SrpButton>
      <br />
      <SrpButton class="login-error--action" tag="button" @click="internalState = 'collect-sms-number'">
        <template #icon><IconEmbedded name="chat_2-5" /></template>
        Text me a link
      </SrpButton>
    </div>
  </div>
  <p v-else-if="internalState === 'email-sent'">
    An email was sent to <strong>{{ email }}</strong> with a link to help you get logged in!
  </p>
  <template v-else-if="internalState === 'sms-sent'">
    <p>
      A text was sent to <strong><MaskedPhoneNumber :phone-number="phoneNumber" /></strong> with a link to help you get logged in!
    </p>
  </template>
  <template v-else-if="internalState === 'collect-sms-number'">
    <form class="sms-submit-form" @submit.prevent="validatePhoneNumberAndSendSms">
      <div class="phone-input">
        <SrpPhoneNumberInput v-model="phoneNumber" label="Enter your phone number" />
      </div>

      <FormErrorsList v-if="!phoneNumberIsValid" class="sms-errors-list" :errors="['Please enter a valid phone number']" />

      <SrpButton :is-disabled="isLoading" tag="button" type="submit">Text me</SrpButton>
    </form>
  </template>
  <SrpButton class="login-error--return-button" color="gray" fill="text" @click="onClickBack">
    <template #icon><IconEmbedded name="arrow-left_2-5" /></template>
    log in a different way
  </SrpButton>
</template>

<script setup lang="ts">
import FormErrorsList from "@components/FormErrorsList.vue";
import MaskedPhoneNumber from "@components/MaskedPhoneNumber.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpPhoneNumberInput from "@components/ui/SrpPhoneNumberInput.vue";
import PhoneValidation from "@logic/PhoneValidation";
import { shrpaApi } from "@repos/axios";
import { computed, ref } from "vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export type ErrorState = "google-unsupported-in-instagram";

const props = defineProps<{ errorState: ErrorState; email: string }>();

const emit = defineEmits<{
  (e: "click:back"): void;
}>();

type InternalState = "display-issue" | "email-sent" | "collect-sms-number" | "sms-sent";
const internalState = ref<InternalState>("display-issue");

const isLoading = ref(false);

const safariLogo = computed(() => globalThis.Bootstrap.Config.contentCdnBaseUri + "/images/icons/safari-logo.svg");
const chromeLogo = computed(() => globalThis.Bootstrap.Config.contentCdnBaseUri + "/images/icons/chrome-logo.svg");

const phoneNumber = ref("");
const phoneNumberIsValid = ref(true);
async function validatePhoneNumberAndSendSms() {
  // validate the phone number
  phoneNumberIsValid.value = PhoneValidation.isPhoneNumberValid(phoneNumber.value);
  if (!phoneNumberIsValid.value) {
    return;
  }
  isLoading.value = true;
  // send the sms
  await shrpaApi.post("/user-registration/send-user-intro-sms", {
    phoneNumber: phoneNumber.value,
  });
  isLoading.value = false;
  internalState.value = "sms-sent";
}

async function sendEmail() {
  isLoading.value = true;
  await shrpaApi.post("/user-registration/send-user-intro-email", {
    email: props.email,
  });
  isLoading.value = false;
  internalState.value = "email-sent";
}

function onClickBack() {
  internalState.value = "display-issue";
  emit("click:back");
}
</script>

<style scoped lang="scss">
.login-error {
  &--header {
    margin-top: 1rem;
    margin-bottom: 2rem;
    h2 {
      font-weight: unset;
    }
  }

  &--action {
    margin-bottom: 1rem;
    margin-inline-start: auto;
    margin-inline-end: auto;
  }

  &--return-button {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  &--instructions {
    margin-inline-start: 3rem;
    margin-inline-end: 3rem;
    ol {
      text-align: start;
      padding-inline-start: 0;
      list-style-position: inside;
    }
  }
}
.sms-submit-form {
  .phone-input {
    text-align: start;
    margin-bottom: 1rem;
  }

  .sms-errors-list {
    text-align: start;
    margin-bottom: 1rem;
  }
}

.browser-icon {
  width: 1.25rem;
  height: 1.25rem;
  vertical-align: middle;
}
</style>
