import { defineStore } from "pinia";
import { ref, Ref } from "vue";

// Types
import { DomNodeClientRect } from "@helpers/CheckIfBlockIntersect";

export const useMiscellaneousStore = defineStore("miscellaneous", () => {
  const helpButtonSizeAndPosition = ref<DomNodeClientRect>({ width: 0, height: 0, top: 0, left: 0 });
  const helpButtonZindex = 200;

  return {
    helpButtonSizeAndPosition,
    helpButtonZindex,
  };
});
