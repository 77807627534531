<template>
  <Teleport to="body" :disabled="!isFullScreenOn">
    <div
      class="full-screen-sim-wrap"
      :style="{
        width: isFullScreenOn ? '100dvw' : 'auto',
        height: isFullScreenOn ? '100dvh' : 'auto',
        position: isFullScreenOn ? 'fixed' : 'relative',
        inset: isFullScreenOn ? '0 auto auto 0' : 'unset',
        zIndex: String(props.zIndex),
      }"
    >
      <div v-if="props.isIosOnly ? isUsersDeviceIOS : true" class="full-screen-sim-wrap__button" @click="isFullScreenOn = !isFullScreenOn">
        <IconEmbedded :name="isFullScreenOn ? 'exit-fullscreen_3' : 'fullscreen_3'" :size="20" color="rgba(0, 0, 0, 0.55)" />
      </div>

      <div class="full-screen-sim-wrap__map">
        <slot :isFullScreenOn="isFullScreenOn" />
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";

const props = withDefaults(
  defineProps<{
    zIndex?: number;
    isIosOnly?: boolean;
  }>(),
  {
    zIndex: 82,
    isIosOnly: false,
  }
);

// Make map fullscreen ========================================================
const isFullScreenOn = ref<boolean>(false);

// Detect is iOS ==============================================================
const isUsersDeviceIOS = ref<boolean>(false);

onMounted(() => {
  isUsersDeviceIOS.value = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
});
</script>

<style scoped lang="scss">
// Full screen simulation wrapper =============================================
.full-screen-sim-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;

  &__button {
    width: 40px;
    height: 40px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 10px 10px auto auto;
    z-index: 2;
    background: rgba(255, 255, 255, 1);
    cursor: pointer;
    user-select: none;
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);
    //opacity: 0;
  }

  &__map {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;

    & > * {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
