// Persists UploadedImages to localStorage so a refresh doesn't lose everything you've uploaded.

import { Itinerary } from "@contracts/itinerary";
import { UploadedImage } from "@contracts/uploadedImage";

export default {
  // Note: Persist is done inline now, not here
  loadUploadedImages(itinerary: Itinerary): Array<UploadedImage> {
    const imageIds = [];
    // First the ones used in the adventure (in order)
    itinerary.steps.forEach(s =>
      (s.images ?? []).forEach(i => {
        // Only add it once in case they put the same image on multiple stops
        if (imageIds.indexOf(i) === -1) imageIds.push(i);
      })
    );
    // Then the unused ones
    (itinerary.unusedPhotos ?? []).forEach(i => {
      // Don't expect this to happen but just in case things got out of sync
      if (imageIds.indexOf(i) === -1) imageIds.push(i);
    });
    return imageIds.map(serverId => ({ serverId })) as Array<UploadedImage>;
  },
};
