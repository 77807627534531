<template>
  <div class="ui form">
    <!--NOTE! Any fields in this panel need to be defaulted in the newCollab variable.  ALSO ensure the server sets the fields-->
    <div class="field">
      <div class="global-h4">Preferred Visit Window</div>
      <p class="cms" style="color: rgba(91, 91, 91, 1); font-family: sans-serif">
        - Start 4+ weeks from today to allow time for coordination<br />
        - We recommend a window at least 4 weeks long
      </p>
      <div style="margin-bottom: 11px; display: flex; align-items: center">
        <div
          :class="{
            'form-error-highlight': true,
            'form-error-highlight--bg-visible': errors.includes('Preferred Visit Window'),
          }"
          style="width: 100%; max-width: 350px; margin-right: 15px"
        >
          <CollabDatePicker
            :collabInput="collabInput"
            :resetCounter="collabDateResetCounter"
            :shouldDefaultDates="true"
            @dateChanged="collabDateChanged"
            style="width: 100%; max-width: 350px; display: inline-block"
          />
        </div>
      </div>

      <NoteWithIcon v-if="showComingSoonWarning" size="tiny" style="margin-bottom: 10px">
        <template #icon><IconEmbedded name="bulb_2-5" /></template>
        <template #text>This is coming soon! We recommend a window 4-6 weeks out to maximize your pool of applicants.</template>
      </NoteWithIcon>

      <NoteWithIcon size="tiny" v-if="!hasDateSelected || showShortWindowWarning" :color="showShortWindowWarning ? null : 'blue'" style="margin-bottom: 22px">
        <template #icon><IconEmbedded name="bulb_2-5" /></template>
        <template #text>Creator Visits with a wide timing window receive more applicants because it's easier for creators to fit into their schedule.</template>
      </NoteWithIcon>

      <p class="cms">(Optional) Additional clarification about preferred days to visit?</p>
      <textarea type="text" maxlength="500" v-model="collabInput.preferredTiming" placeholder="Ex. Prefer Weekends" style="height: 80px; min-height: 30px"></textarea>
    </div>
    <div class="field">
      <!--Future Collab Message-->
      <div v-if="(isDateRangeFarOut && !collabInput.postedToCreators) || collabInput.reminderForFarOutRequested === true" class="ui info message">
        <h3>Your Preferred Visit Window is a ways out.</h3>
        <div v-if="!collabInput.reminderForFarOutRequested">
          <div>You can send to creators now or we can remind you do so later.</div>
          <button class="ui tiny primary button" @click="futureCollabReminder()">Remind me to send to creators later</button>
          <div>Or just proceed if you prefer.</div>
        </div>
        <div v-else>
          <h4>
            We'll remind you ~{{ farOutReminderInWeeks }} weeks before <span v-if="dateRange && dateRange">{{ moment(dateRange[0]).format("MMM Do") }}</span>
          </h4>
        </div>
      </div>
    </div>

    <div class="field" v-if="isSuperOrSalesUser" style="background-color: #2c058730; border-radius: 5px; padding: 12px">
      <div style="margin-top: 5px">
        <SrpCheckbox>
          <template #input><input type="checkbox" v-model="collabInput.isConcierge" name="isDownloadCompressed" /></template>
          <template #text>Is Concierge Collab</template>
        </SrpCheckbox>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
/* eslint vue/no-mutating-props: 0 */
import axios from "axios";
import { defineComponent } from "vue";
import DateUtils from "@logic/DateUtils";
import moment from "moment";
import { mapState } from "pinia";

// Types
import { CollabInput } from "@contracts/collab";

// Stores
import { useUserProfileStore } from "@stores/userProfileStore";

// Components
import CollabDatePicker from "@views/PaidCollab/CollabDatePicker.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import TooltipWrapper from "@components/ui/TooltipWrapper.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import SrpCheckbox from "@components/ui/SrpCheckbox.vue";

export default defineComponent({
  name: "StepTiming",

  components: { IconEmbedded, CollabDatePicker, LinkWithIcon, TooltipWrapper, NoteWithIcon, SrpCheckbox },

  props: {
    getCurrentCollabInput: { type: Function, required: true },
    communityId: { type: String, required: true },
    collabInput: { type: Object as () => CollabInput | null, required: true },
    collabDateResetCounter: { type: Number, required: true },
    errors: { type: Array<string>, required: true },
  },

  data() {
    return {
      // If the collab is far enough out we'll remind them later to send to creators
      farOutCollabMinimumInDays: 150, // 5 months (pushed this out further with the new collab applications flow)
      farOutReminderInWeeks: 8, // mastered on the server in the reminders code, just showing the user here.
      // Still setting dateRange from the CollabDatePicker (could probably remove if we wanted to rework more code)
      // REMINDER: need to reset fields here on index change (in initialize())
      dateRange: null as Array<Date> | null,
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["isSuperOrSalesUser"]),
    isDateRangeFarOut(): boolean {
      if (this.dateRange?.length > 0 && this.dateRange[0]) {
        const startDate = this.dateRange[0];
        const daysOut = DateUtils.daysBetween(new Date(), startDate);
        if (daysOut > this.farOutCollabMinimumInDays) return true;
      }
      // No date set or not far enough out
      return false;
    },
    hasDateSelected(): boolean {
      if (this.dateRange?.length >= 2 && this.dateRange[0] && this.dateRange[1]) return true;
      return false;
    },
    showShortWindowWarning(): boolean {
      // Show the info message before they select a date,
      // once they select a date only show it if the window is too small
      const showWarningIfLessThanDays = 25;
      if (this.dateRange?.length === 2 && this.dateRange[0] && this.dateRange[1]) {
        var selectedRangeLength = Math.abs(DateUtils.daysBetween(this.dateRange[0], this.dateRange[1]));
        if (selectedRangeLength < showWarningIfLessThanDays) return true;
      }
      return false;
    },
    showComingSoonWarning(): boolean {
      const showWarningIfLessThanDays = 28;
      if (this.dateRange?.length === 2 && this.dateRange[0]) {
        // Decided to use end date per discussions with Hannah
        const endDate = this.dateRange[1];
        const daysOut = DateUtils.daysBetween(new Date(), endDate);
        if (daysOut < showWarningIfLessThanDays) return true;
      }
      return false;
    },
  },

  mounted() {
    this.$nextTick(() => {
      (this.$refs.collabNameInput as HTMLInputElement)?.focus();
    });
  },

  methods: {
    moment,
    collabDateChanged(dateRange: Array<Date>) {
      this.dateRange = dateRange;
    },
    futureCollabReminder() {
      this.getCurrentCollabInput().reminderForFarOutRequested = true;
      // Note: Not saving immediately anymore now that this is right in the input field flow
    },
  },
});
</script>

<style scoped lang="scss">
@import "./form-error-highlight.scss";
</style>
