import { Ref } from "vue";

type UploadFunction = (image: File) => Promise<void>;
import Logger from "@helpers/Logger";
import { getGlobalRemoteLogger } from "@helpers/RemoteLogger";
export const MAX_CONCURRENT_UPLOADS_DEFAULT = 5;

// Uploads images and videos in parallel, with a maximum number of concurrent uploads
export class ParallelAssetUploader {
  async uploadImagesAndVideos(images: File[], uploadFunc: UploadFunction, maxConcurrentUploads = MAX_CONCURRENT_UPLOADS_DEFAULT, progressTracker: Function): Promise<void> {
    let activeUploads = 0;
    let currentIndex = 0;

    const whichImagesIndexesUploaded = images.map(() => false);

    progressTracker(true, 0);

    Logger.info(`Uploading ${images.length} assets.  MaxConcurrent: ${maxConcurrentUploads}`);
    return new Promise((resolve, reject) => {
      const attemptUpload = async () => {
        if (currentIndex >= images.length && activeUploads === 0) {
          // All images have been processed (note: it's possible they failed to upload)
          Logger.info(`All ${currentIndex} assets ran.`);
          resolve();
          progressTracker(false, 100);
          return;
        }

        function setUploadProgress(currentUploadIndex) {
          whichImagesIndexesUploaded[currentUploadIndex] = true;
          progressTracker(true, (whichImagesIndexesUploaded.filter(i => i).length / images.length) * 100);

          if (whichImagesIndexesUploaded.filter(i => i).length === images.length) {
            progressTracker(false, 100);
          }
        }

        while (activeUploads < maxConcurrentUploads && currentIndex < images.length) {
          const currentUploadIndex = currentIndex;
          const imageName = images[currentUploadIndex]?.name;
          const assetSize = convertToKBorMB(images[currentUploadIndex]?.size);
          Logger.info(`Starting upload for asset '${imageName}' ${assetSize} (${currentUploadIndex + 1} of ${images.length})`);
          activeUploads++;
          uploadFunc(images[currentUploadIndex])
            .then(() => {
              Logger.info(`ParallelUpload ran for asset '${imageName}' ${assetSize} (${currentUploadIndex + 1} of ${images.length})`);
              activeUploads--;
              attemptUpload();
              setUploadProgress(currentUploadIndex);
            })
            .catch(error => {
              const remoteLogger = getGlobalRemoteLogger();
              remoteLogger.error(`Asset UploadError: ${error.message} assetName='${imageName}' ${assetSize} ${currentUploadIndex + 1} of ${images.length}`);
              reject(error);
              whichImagesIndexesUploaded[currentUploadIndex] = true;
              setUploadProgress(currentUploadIndex);
            });

          currentIndex++;
        }
      };

      attemptUpload();
    });
  }
}

function convertToKBorMB(input: number | null | undefined): string | null {
  if (input === null || input === undefined) {
    return null;
  }

  const bytesInKB = 1024;
  const bytesInMB = 1024 * bytesInKB;

  if (input < bytesInKB) {
    return Math.round(input) + "bytes";
  } else if (input < bytesInMB) {
    return Math.round(input / bytesInKB) + "KB";
  } else {
    return Math.round(input / bytesInMB) + "MB";
  }
}
