<template>
  <div class="video-container">
    <div class="video-container__video-viewport" :style="{ width: props.size + 'px' }">
      <div class="video-container__color-filter"></div>
      <video class="video-container__video-itself" :width="props.size" playsinline muted autoplay loop ref="domRefVideoPlayer">
        <source src="https://cdn.shrpa.com/videos/misc/ai_loader.mp4" type="video/mp4" />
      </video>
    </div>

    <div class="video-container__subtitle">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, nextTick } from "vue";

const props = withDefaults(
  defineProps<{
    size: number;
  }>(),
  {
    size: 100,
  }
);

// Set playback speed =========================================================
const domRefVideoPlayer = ref<HTMLVideoElement | null>(null);

onMounted(async () => {
  await nextTick();

  if (domRefVideoPlayer.value) {
    domRefVideoPlayer.value.playbackRate = 1.5;
  }
});
</script>

<style scoped lang="scss">
// Video container ============================================================
.video-container {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__video-viewport {
    aspect-ratio: 1/1;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
    overflow: hidden;
  }

  &__video-itself {
    min-width: calc(100% * 1.28);
    filter: hue-rotate(320deg);
  }

  &__subtitle {
    color: rgba(91, 91, 91, 1);
    text-align: center;
    font: 14px/19px sans-serif;
  }
}
</style>
