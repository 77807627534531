<template>
  <li class="admin-snippet">
    <div
      :class="{
        'admin-snippet__photo': true,
        'admin-snippet__photo--skeleton': isSkeleton,
      }"
    >
      <img v-if="!isSkeleton && isAdminPhotoLoadedSuccessfully" class="admin-snippet__photo-img" :src="data.profileImage" :alt="data.adminFirstName" @load="isAdminPhotoLoadedSuccessfully = true" />
      <IconEmbedded v-else name="user-solid" style="position: absolute; inset: calc(50% - 17px) auto auto calc(50% - 15px)" :size="30" />
    </div>
    <div class="admin-snippet__text">
      <div
        :class="{
          'global-h4': true,
          'admin-snippet__name': true,
          'admin-snippet__name--skeleton': isSkeleton,
        }"
      >
        {{ data.adminFirstName }}
        <span class="admin-snippet__name-you-mark">
          {{ getActingUserProfile?.authNameId === data.adminAuthNameId ? " (You)" : "" }}
        </span>
      </div>
      <div
        :class="{
          'admin-snippet__email': true,
          'admin-snippet__email--skeleton': isSkeleton,
        }"
      >
        {{ data.adminEmail }}
      </div>
      <div
        v-if="data && data.invite && data.invite.acceptedOn"
        :class="{
          'admin-snippet__date-added': true,
          'admin-snippet__date-added--skeleton': isSkeleton,
        }"
      >
        added on {{ moment(data.invite.acceptedOn).format("MMM Do YYYY") }}
      </div>
    </div>
    <CloseDeleteButton v-if="!(isSkeleton || getActingUserProfile?.authNameId === data.adminAuthNameId)" class="admin-snippet__remove-btn" @click="$emit('remove', data.adminAuthNameId)" />
  </li>
</template>

<script lang="ts">
import moment from "moment";
import { defineComponent } from "vue";
import { mapState } from "pinia";
import { useUserProfileStore } from "@stores/userProfileStore";

// Types
import { UserAdmin } from "@contracts/userAdmins";

// Components
import CloseDeleteButton from "@components/ui/CloseDeleteButton.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export default defineComponent({
  name: "AdminSnippet",

  components: {
    IconEmbedded,
    CloseDeleteButton,
  },

  props: {
    isSkeleton: { type: Boolean, default: false },
    data: { type: Object as () => UserAdmin, required: true },
  },

  emits: ["remove"],

  data() {
    return {
      isAdminPhotoLoadedSuccessfully: true,
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["getActingUserProfile", "isSuperOrSalesUser"]),
  },

  methods: {
    moment,
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/mixins/animations/loading-bg.scss";
@import "@/scss/screen-size-ranges.scss";

// Admin snippet ==============================================================
.admin-snippet {
  padding: 19px 0 25px 25px;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  position: relative;
  background: #f3f3f3;

  &__photo {
    width: 61px;
    min-width: 61px;
    height: 61px;
    min-height: 61px;
    margin-right: 20px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
    overflow: hidden;
    color: #aeb4b7;
    font-size: 62px;
    line-height: 62px;
    background: #e4e6e7;

    &::before {
      position: absolute;
      inset: 10% auto auto 50%;
      z-index: -1;
      transform: translateX(-50%);
    }

    &--skeleton {
      @include loadingBgAnimation;
      border-radius: 100px !important;
      color: transparent;
    }
  }

  &__photo-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__text {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 14px;
  }

  &__name {
    margin-bottom: 3px;

    &--skeleton {
      @include loadingBgAnimation;
      color: transparent;
    }
  }

  &__name-you-mark {
    color: #5b5b5b;
    font-weight: 400;
  }

  &__email {
    max-width: calc(100% - 10px);
    margin-bottom: 8px;
    font-weight: bold;
    user-select: all;
    word-break: break-word;

    &--skeleton {
      @include loadingBgAnimation;
      color: transparent;
    }
  }

  &__date-added {
    font-style: italic;
    opacity: 0.5;

    &--skeleton {
      @include loadingBgAnimation;
      color: transparent;
    }
  }

  &__remove-btn {
    position: absolute;
    inset: -6px -6px auto auto;
    z-index: 0;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .admin-snippet {
    flex-direction: column;

    &__photo {
      margin: 0 0 14px 0;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .admin-snippet {
    padding: 14px 17px 17px;

    &__photo {
      width: 41px;
      min-width: 41px;
      height: 41px;
      min-height: 41px;
      margin-right: 15px;
      font-size: 41px;
      line-height: 41px;
    }
  }
}
</style>
