<template>
  <div class="ui form">
    <div class="field">
      <div class="global-h4">
        <span v-if="!collabInput.themeType">Visit Focus</span>
        <span v-else
          >Visit Focus: {{ getThemeTypeName(collabInput.themeType) }}
          <img class="snippet__icon" :src="getThemeIcon(collabInput.themeType)" :alt="collabInput.themeType" />
        </span>
      </div>
      <p class="cms">Tell the creator what's most important in this visit.</p>
      <div
        :class="{
          'form-error-highlight': true,
          'form-error-highlight--bg-visible': errors.includes('Visit Focus'),
        }"
        style="width: 100%"
      >
        <textarea
          ref="themesInput"
          type="text"
          maxlength="1000"
          v-model="collabInput['themes']"
          placeholder="Ex. Foodie tour details, bike trail specifics, etc..."
          autocapitalize="sentences"
          style="height: 140px"
        ></textarea>
      </div>
    </div>
    <div class="field">
      <div class="global-h4">Content Focus</div>
      <p class="cms">
        (Optional) What content do you need for your marketing channels? <br />
        Ex: Lifestyle photos, scenery and landscape shots, seasonal or event focused.
      </p>
      <input type="text" v-model="collabInput.contentFocus" style="max-width: 600px" placeholder="Lifestyle photos" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { getThemeTypeName, getThemeIcon } from "@helpers/ThemeTypeHelper";

// Types
import { CollabInput } from "@contracts/collab";

export default defineComponent({
  name: "StepThemes",

  props: {
    collabInput: { type: Object as () => CollabInput | null, required: true },
    errors: { type: Array<string>, required: true },
  },

  mounted() {
    this.$nextTick(() => {
      (this.$refs.themesInput as HTMLInputElement)?.focus();
    });
  },
  methods: {
    getThemeTypeName,
    getThemeIcon,
  },
});
</script>

<style scoped lang="scss">
@import "./form-error-highlight.scss";

.snippet {
  &__icon {
    width: 40px;
    min-width: 40px;
    height: 40px;
    margin-bottom: -10px;
    color: rgba(0, 0, 0, 0.3);
  }
}
</style>
