<template>
  <div class="share-content-wizard">
    <!-- Header -->
    <div class="header share-content-wizard__header">
      <div class="global-h1 header__title">Share your content on Social Media!</div>
      <div class="header__description-wrap">
        <div class="header__description">
          <template v-if="shareStopSummaryStore.activeStep === 1">Select an option and we'll make it easy for you to set up a post</template>
          <template v-else>Easily build a Social Media Post with your Shrpa content!</template>
        </div>

        <div class="header__links-wrap">
          <LinkWithIcon v-if="shareStopSummaryStore.activeStep !== 1 || shareStopSummaryStore.editedPost.id" class="header__create-new-post-link" color="blue" @click="resetStateAndCreateNewPost">
            <template #icon><IconEmbedded name="plus_2-5" :size="24" /></template>
            <span>Create new post</span>
          </LinkWithIcon>

          <LinkWithIcon
            class="header__all-posts-link"
            :to="{ name: 'SavedPosts', params: { pageId: $route.params.pageId } }"
            tag="RouterLink"
            :isDisabled="!shareStopSummaryStore.allPosts.length || shareStopSummaryStore.isLoadingAllPosts"
            iconInset="1px auto auto -1px"
          >
            <template #icon><IconEmbedded name="checklist_2" :size="24" /></template>
            <span>Saved posts</span
            ><span style="color: #888; text-decoration: none !important"> &nbsp;{{ shareStopSummaryStore.allPosts.length ? `(${shareStopSummaryStore.allPosts.length})` : "" }}</span>
          </LinkWithIcon>
        </div>
      </div>
    </div>
    <!-- / Header -->
    <StepWizard
      :class="{
        'share-content-wizard__step-wizard': true,
        'share-content-wizard__step-wizard--disabled': !shareStopSummaryStore.isPostsLoaded || shareStopSummaryStore.isLoadingAllPosts || shareStopSummaryStore.isLoadingShareableSummary,
      }"
      v-model:activeSlide="shareStopSummaryStore.activeStep"
      isWithScrollReset
      :titles="[
        { title: 'What to share' },
        { title: `Select ${shareStopSummaryStore.postTypeTitle || 'Creator Visit / Adventure / Business or Location'}` },
        { title: 'Select photos & videos' },
        { title: 'Build your caption' },
        { title: 'You\'re ready to Share!', subtitle: 'Use the caption and images below to post to your preferred social media platforms or scheduling tool.' },
      ]"
    >
      <template #[0]="{ goToNextSlide, updateSlotsList }">
        <WhatToShare @goToNextSlide="goToNextSlide" @update="updateSlotsList" />
      </template>
      <template #[1]="{ goToNextSlide }">
        <SelectEntities @goToNextSlide="goToNextSlide" />
      </template>
      <template #[2]="{ goToNextSlide }">
        <SelectPhotosAndVideos @goToNextSlide="goToNextSlide" />
      </template>
      <template #[3]="{ goToNextSlide }">
        <BuildYourCaption @goToNextSlide="goToNextSlide" />
      </template>
      <template #[4]="{ goToNextSlide }">
        <DownloadTheAssets @goToNextSlide="goToNextSlide" />
      </template>
    </StepWizard>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import axios from "axios";
import OrgContext from "@logic/OrgContext";
import { mapStores } from "pinia";
import lodashCloneDeep from "lodash-es/cloneDeep";

// Types
import { PartnerNavInfo } from "@contracts/partnerNavInfo";

// Components
import LinkWithIcon from "@components/LinkWithIcon.vue";
import SlideIntro from "@components/Onboarding/CustomerOnboarding/SlideIntro.vue";
import StepWizard from "@components/ui/StepWizard.vue";
// slides
import WhatToShare from "./slides/WhatToShare.vue";
import SelectEntities from "./slides/SelectEntities/index.vue";
import SelectPhotosAndVideos from "./slides/SelectPhotosAndVideos.vue";
import BuildYourCaption from "./slides/BuildYourCaption/index.vue";
import DownloadTheAssets from "./slides/DownloadTheAssets.vue";

// Stores
import { useShareStopSummaryStore } from "@stores/shareStopSummary";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import { useHead } from "@unhead/vue";
import { MetricSender } from "@/helpers/MetricSender";

export default defineComponent({
  name: "ShareStopSummaryV2",

  components: {
    IconEmbedded,
    LinkWithIcon,
    SlideIntro,
    StepWizard,
    // slides
    WhatToShare,
    SelectEntities,
    SelectPhotosAndVideos,
    BuildYourCaption,
    DownloadTheAssets,
  },

  data() {
    return {
      globalLog: inject("globalLog") as any,

      title: "Social Snapshots",
      pageId: "",
      orgInContext: null as PartnerNavInfo,
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
    };
  },

  computed: {
    ...mapStores(useShareStopSummaryStore),
  },

  async mounted() {
    useHead({ title: () => this.title ?? "" });

    await this.$nextTick();
    // @ts-ignore
    this.pageId = this.$route.params.pageId;
    this.orgInContext = OrgContext.getOrgInContext(this);
    this.title += " - " + this.orgInContext?.name;

    await this.shareStopSummaryStore.loadAllPosts(String(this.pageId));

    if (this.$route.query.postId) {
      const post = this.shareStopSummaryStore.allPosts.find(p => p.id === this.$route.query.postId);
      this.shareStopSummaryStore.editedPost = lodashCloneDeep(post);
      await this.shareStopSummaryStore.loadShareableSummaryDetails(post.customerId, post.contextType, post.contextId);
      this.shareStopSummaryStore.activeStep = 4;
    }

    MetricSender.sendMetric("Cust-ViewShareOnSocial", null);
  },

  beforeUnmount() {
    this.shareStopSummaryStore.resetEditedPost();
    this.shareStopSummaryStore.activeStep = 1;
  },

  methods: {
    async resetStateAndCreateNewPost() {
      this.shareStopSummaryStore.resetEditedPost();
      this.shareStopSummaryStore.activeStep = 1;

      await this.$router.push({
        name: "ShareStopSummaryV2",
        params: { pageId: this.$route.params.pageId },
      });
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Header =====================================================================
.header {
  padding-bottom: 24px;
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;

  &__title {
    margin-bottom: 14px;
  }

  &__description-wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    color: #5b5b5b;
    font-family: sans-serif;
  }

  &__description {
  }

  &__links-wrap {
    display: flex;
    align-items: center;
  }

  &__create-new-post-link {
    margin-right: 35px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__all-posts-link {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .header {
    padding-bottom: 32px;

    &__description-wrap {
      flex-direction: column;
    }

    &__description {
      margin-bottom: 12px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .header {
    padding-bottom: 32px;

    &__description-wrap {
      flex-direction: column;
    }

    &__description {
      margin-bottom: 12px;
    }
  }
}

// Share stop wizard ==========================================================
.share-content-wizard {
  width: 945px;
  max-width: 945px;
  margin: 0 auto;

  &__header {
  }

  &__step-wizard {
    opacity: 1;
    transition: opacity 0.12s ease-in-out;

    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .share-content-wizard {
    width: 822px;
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .share-content-wizard {
    width: 762px;
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .share-content-wizard {
    width: 502px;
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .share-content-wizard {
    width: 100%;
  }
}
</style>
