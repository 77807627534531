<template>
  <div class="content-wrapper">
    <h1>Infringement Policy</h1>

    <p>Last Updated on May 11, 2019</p>
    <p>
      We do not take kindly to those who abuse the intellectual property rights of others. If you believe that your copyright or trademark is being infringed on the Site, please send us a written
      notice with the following information:
    </p>
    <ol>
      <li><p>Identification of the copyrighted or trademarked work that you claim has been infringed;</p></li>
      <li>
        <p>
          Identification of the allegedly infringing content, and information reasonably sufficient to permit Shrpa to locate it on the Site (e.g., the URL for the web page on which the content
          appears);
        </p>
      </li>
      <li>
        <p>
          A statement by you that you have a good faith belief that the use of the content identified in your notice in the manner complained of is not authorized by the copyright/trademark owner, its
          agent, or the law;
        </p>
      </li>
      <li>
        <p>
          A statement by you that you attest, under penalty of perjury, that the information in your notice is accurate and that you are the copyright/trademark owner or authorized to act on the
          owner's behalf; and
        </p>
      </li>
      <li><p>Your physical or electronic signature, together with your contact information (address, telephone number and, if available, email address).</p></li>
    </ol>

    <p>
      At the same time, we do not take kindly to those who abuse the scope of their own intellectual property rights. If you believe that your content should not have been removed for alleged
      copyright or trademark infringement, you may send us a written counter-notice with the following information:
    </p>
    <ol>
      <li>
        <p>Identification of the copyrighted or trademarked work that was removed, and the location on the Site where it would have been found prior to its removal;</p>
      </li>
      <li>
        <p>
          A statement, under penalty of perjury, that you have a good faith belief that the content was removed as a result of a mistake or misidentification. For trademark disputes only: information
          reasonably sufficient to explain why you believe you are not infringing the trademarked work;
        </p>
      </li>
      <li>
        <p>
          A statement that you consent either to the jurisdiction of (a) the Federal District Court for the judicial district in which your address is located if you live in the United States, or (b)
          any judicial district in which Shrpa is located if you live outside the United States. Please also include a statement that you will accept service of process from the person who sent the
          original infringement notice to Shrpa, or an agent of such person;
        </p>
      </li>
      <li>
        <p>Your physical or electronic signature, together with your contact information (address, telephone number and, if available, email address).</p>
      </li>
    </ol>
    <p>
      We will respond to all such notices and comply with applicable law. We reserve the right to remove content alleged to be infringing without prior notice and at our sole discretion. We also
      reserve the right to terminate a user's account if the user is determined to be a repeat infringer.
    </p>

    <p>You can send us your copyright or trademark notices by email at hello@shrpa.com.</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useHead } from "@unhead/vue";

export default defineComponent({
  name: "Infringement",

  mounted() {
    useHead({ title: "Terms - Infringement Policy" });
  },
});
</script>
