import { getLogger } from "@helpers/Logger";
import { RemoteLogger } from "@helpers/RemoteLogger";
import { getCurrentInstance } from "vue";

// NOTE: Manually adjust in the console with: localStorage.loglevel = "DEBUG"

// Create a logger on each Vue component for easy access
const logPlugin = {
  install(app) {
    app.provide("globalLog", getLogger(getCurrentInstance()?.type.__name ?? "Unknown"));
  },
};

export default logPlugin;

// // Make the main instance use it also
// declare module "vue" {
//   interface Vue {
//     globalLog: ReturnType<typeof getLogger>;
//     // Also add the remoteLogger here
//     remoteLogger: RemoteLogger;
//   }
// }
