<template>
  <!-- Post -->
  <li class="post">
    <CloseDeleteButton class="post__remove-btn" @click="emit('deletePost', props.post)" />

    <!-- Post title -->
    <div class="post-title post__title">
      <h3 class="post-title__invisible-overlay" ref="domRefTitleInvisibleOverlay">
        {{ _postTitle || "No title" }}
      </h3>
      <textarea
        class="post-title__textarea"
        v-model.trim="_postTitle"
        placeholder="No title"
        @blur="updatePost"
        :style="{ height: textareaHeight === 'auto' ? textareaHeight : `${textareaHeight}px` }"
      ></textarea>
    </div>
    <!-- / Post title -->

    <div v-if="post.caption" class="post__description">
      {{ props.post.caption.slice(0, 150) + (props.post.caption.length > 150 ? "..." : "") }}
    </div>
    <ul class="post__images-list">
      <!-- Image container -->
      <li class="image-container post__image-container" v-for="(mediaUrl, index) in props.post.assetIds.slice(0, howManyImagesToShow)" :key="mediaUrl">
        <div v-if="index === howManyImagesToShow - 1 && props.post.assetIds.length > howManyImagesToShow" class="image-container__counter-overlay">
          +{{ post.assetIds.length - howManyImagesToShow }}
        </div>

        <MediaThumbnail class="image-container__image" :media-url="mediaUrl" />
      </li>
      <!-- / Image container -->
    </ul>
    <div class="post__buttons-section">
      <CopyCaptionButton v-if="props.post.caption" class="post__button" :text="props.post.caption" size="small" />

      <SrpButton class="post__button" size="small" @click="handleDownloadRequest" :is-disabled="isRequestingDownloadId">
        {{ screenSize === "mobile" ? "Download" : "Download Media" }}
        <template #iconRight>
          <IconEmbedded v-if="isRequestingDownloadId" name="loader_3" color="rgba(255, 255, 255, 0.5)" :size="19" />
          <IconEmbedded v-else name="download_2" color="rgba(255, 255, 255, 0.5)" :size="19" />
        </template>
      </SrpButton>

      <SrpButton class="post__button" size="small" fill="outlined" @click="emit('editPost', props.post.id)">
        Edit
        <template #iconRight>
          <IconEmbedded name="edit-pencil_2" :size="19" style="top: -1px" />
        </template>
      </SrpButton>
    </div>
  </li>
  <!-- / Post -->
</template>

<script setup lang="ts">
import { Ref, computed, inject, ref, watch, onMounted } from "vue";
import FileDownload from "@logic/FileDownload";

// Components
import CloseDeleteButton from "@components/ui/CloseDeleteButton.vue";
import CopyCaptionButton from "@views/Community/ShareStopSummaryV2/CopyCaptionButton.vue";
import MediaThumbnail from "@components/MediaThumbnail.vue";
import SrpButton from "@components/ui/SrpButton.vue";

// Types
import { SocialMediaPostData } from "@contracts/shareStop";
import { ScreenSize } from "@contracts/screenSize";

const screenSize = inject("screenSize") as Ref<ScreenSize>;
const screenSizeUpdateKey = inject("screenSizeUpdateKey") as Ref<symbol>;

const props = withDefaults(
  defineProps<{
    post: SocialMediaPostData;
  }>(),
  {
    post: null,
  }
);

const emit = defineEmits<{
  (e: "deletePost", post: SocialMediaPostData): void;
  (e: "editPost", postId: string): void;
}>();

// Stores
import { useShareStopSummaryStore } from "@stores/shareStopSummary";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
const shareStopSummaryStore = useShareStopSummaryStore();

// Download the media =========================================================
const contentBaseUri = globalThis.Bootstrap.Config.contentBlobBaseUri;
const isRequestingDownloadId = ref(false);

async function handleDownloadRequest() {
  let downloadId = "";

  isRequestingDownloadId.value = true;
  if (props.post.zippedDownloadId) {
    downloadId = props.post.zippedDownloadId;
  } else {
    downloadId = await shareStopSummaryStore.requestDownloadId(props.post.customerId, props.post.id);
  }
  isRequestingDownloadId.value = false;

  FileDownload.downloadFileByURI(`${contentBaseUri}/${downloadId}`);
}

// How many images to show ====================================================
const howManyImagesToShow = computed<number>(() => {
  return ["tablet", "tablet-large", "laptop", "desktop"].includes(screenSize.value) ? 4 : 3;
});

// Edit title =================================================================
const _postTitle = ref("");
watch(
  // This sanitization is needed because title text node transforms all of the plural spaces into single space and textarea doesn't
  // This matters because we do some magic to set the height of the textarea based on the height of the title text node
  // so we need it to treat all characters (including spaces) the same or the height calc won't work.
  () => _postTitle.value,
  () => {
    _postTitle.value = (_postTitle.value || "").replace(/\s{2,}/g, " ").trim();
  }
);

onMounted(() => {
  _postTitle.value = props.post.contextTitle;
});

async function updatePost() {
  if (_postTitle.value.trim() === props.post.contextTitle) {
    return;
  }
  await shareStopSummaryStore.updatePost({ ...props.post, contextTitle: _postTitle.value.trim() });
  _postTitle.value = props.post.contextTitle;
}

// Update the textarea height =================================================
const textareaHeight = ref<"auto" | number>("auto");
const domRefTitleInvisibleOverlay = ref(null);

watch([() => _postTitle.value, () => screenSizeUpdateKey.value], () => {
  setTimeout(setTextareaHeight, 50);
});
onMounted(() => {
  setTimeout(setTextareaHeight, 50);
});

function setTextareaHeight() {
  textareaHeight.value = domRefTitleInvisibleOverlay.value?.getBoundingClientRect().height || "auto";
}
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";
@import "@/scss/mixins/animations/gradient-spin-white.scss";

// Post title =================================================================
.post-title {
  position: relative;

  &__invisible-overlay {
    width: 100%;
    position: absolute;
    inset: 0 auto auto 0;
    color: #000;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    font-family: "Quicksand", sans-serif;
    word-break: break-word;
    word-wrap: break-word;
    white-space: normal;
    pointer-events: none;
    opacity: 0;
    //color: rgba(255, 0, 0, 0.5);
  }

  &__textarea {
    width: 100%;
    height: 0;
    min-height: 0;
    padding: 0;
    margin: 0;
    border: none;
    color: #000;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    font-family: "Quicksand", sans-serif;
    word-break: break-word;
    word-wrap: break-word;
    white-space: normal;
    background: transparent;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
    box-shadow: none;
    transition: none;
    resize: none;

    &::placeholder {
      color: #999;
    }

    &:focus {
      border-radius: 1px;
      outline: 1px rgba(0, 0, 0, 0.25) solid;
      outline-offset: 4px;
    }
  }
}

// Image container ============================================================
.image-container {
  aspect-ratio: 1/1;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  overflow: hidden;

  &__counter-overlay {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: 1;
    color: #fff;
    font: 18px/18px sans-serif;
    background: rgba(0, 0, 0, 0.4);
  }

  &__image {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    :deep(.media-thumbnail-checkbox__video-wrap) {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    :deep(.media-thumbnail-checkbox__video) {
      display: flex;
      justify-content: center;
      align-items: center;

      a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    :deep(.icon.play.circle.outline) {
      font-size: 29px;
      line-height: 29px;
    }
  }
}

// Post =======================================================================
.post {
  padding: 15px 24px 23px;
  box-sizing: border-box;
  border-radius: 6px;
  position: relative;
  z-index: 0;
  background: rgba(0, 0, 0, 0.05);

  &__remove-btn {
    position: absolute;
    inset: -6px -6px auto auto;
    z-index: 3;
  }

  &__title {
    margin-bottom: 7px;
  }

  &__description {
    margin-bottom: 12px;
    color: #5b5b5b;
    font-family: sans-serif;
  }

  &__images-list {
    padding: 0;
    margin: 0 0 15px 0;
    display: flex;
    list-style: none;
  }

  &__image-container {
    width: calc(25% - 5px);
    margin-right: 6px;

    &:nth-child(4n) {
      margin-right: 0;
    }
  }

  &__buttons-section {
  }

  &__button {
    margin: 0 10px 10px 0;

    &:last-child {
      margin: 0;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .post {
    padding: 12px 17px 14px;

    &__image-container {
      width: calc(33.3% - 5px);
      margin-right: 6px;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
</style>
