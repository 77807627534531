import { defineStore } from "pinia";
import { ref } from "vue";
import axios, { AxiosResponse } from "axios";
import { useRoute } from "vue-router";
import { checkIsStoreFresh } from "@helpers/CheckStoreFreshness";

// Types
import { CreatorCollabSummary } from "@contracts/collab";

export const useCollabSummariesStore = defineStore("collabSummaries", () => {
  const isLoading = ref(false);
  let lastLoadTimestamp: number | null = null;

  const allCollabSummaries = ref<Array<CreatorCollabSummary>>([]);

  async function loadAllCollabSummaries(pageId: string, isOnlyCompleted = true, includeSharedWith = false, isForceLoad = false): Promise<void> {
    if (checkIsStoreFresh(lastLoadTimestamp) && !isForceLoad) return;

    const query = `?onlyCompleted=${isOnlyCompleted}&includeSharedWith=${includeSharedWith}`;
    const uri = `${import.meta.env.VITE_API_URL}/collabs/${pageId}/summary${query}`;

    isLoading.value = true;
    const response: AxiosResponse<any> = await axios.get(uri);
    lastLoadTimestamp = Date.now();
    allCollabSummaries.value = response.data;
    isLoading.value = false;
  }

  return { isLoading, allCollabSummaries, loadAllCollabSummaries };
});
