<template>
  <li class="creator-snippet">
    <!-- Message cloud -->
    <div v-if="creator.collabCreatorInput?.notesForCommunity" class="message-cloud creator-snippet__message-cloud">
      <!--Creator Comments-->
      <div class="event">
        <div class="content">
          <div class="summary" style="white-space: pre-wrap; word-break: break-word">
            <ClampedText :visibleLines="6">
              <span v-html="linkifyStr(creator.collabCreatorInput?.notesForCommunity, globalRoot.linkifyOptions)"></span>
            </ClampedText>
          </div>
        </div>
      </div>
    </div>
    <!-- / Message cloud -->

    <div class="creator-snippet__avatar-n-main-info">
      <div class="creator-snippet__col-left">
        <!-- Avatar container -->
        <div class="avatar-container creator-snippet__avatar-container">
          <div v-if="showRanking && creator.collabCreatorInput?.rank" class="avatar-container__rank-badge">
            {{ creator.collabCreatorInput?.rank }}
          </div>

          <AvatarWithFallback
            :class="{
              'avatar-container__avatar': true,
              'avatar-container__avatar--with-highlight-outline': showRanking && creator.collabCreatorInput?.rank,
            }"
            :src="creator.profileImageUri"
          />
        </div>
        <!-- / Avatar container -->

        <SocialMediaLinksBar
          v-if="creator.twitterHandle || creator.instagramHandle || creator.facebookURL || creator.youtubeURL || creator.tikTokHandle"
          class="creator-snippet__gray-social-icons-list"
          :socialFields="creator"
          :isFullSizeIcons="false"
          :iconsSize="23"
          :iconsGap="3"
        />
      </div>

      <!-- Main info -->
      <div class="main-info creator-snippet__main-info">
        <div class="global-h4 main-info__name-section">
          <a class="main-info__name" target="_blank" :href="'/creator/' + creator.creatorId"
            >{{ creator.name }}&nbsp;<IconEmbedded class="main-info__name-external-link-icon" name="external-link_2-5" :size="15" color="rgba(0, 0, 0, 0.35)" />
          </a>

          <router-link
            v-if="isSuperOrSalesUser && isCTAButtonVisible"
            :to="{ name: 'CollabOpportunity', params: { creatorId: creator.creatorId, communityId: customerId, collabInputId: collabInputId } }"
            style="display: inline; margin-left: 8px"
          >
            <i v-if="true" class="chart line grey icon"></i>
          </router-link>
        </div>
        <ul class="main-info__params-list">
          <li class="main-info__param-wrap">
            <IconEmbedded name="calendar_2" class="main-info__param-icon" />
            <div class="main-info__param-text">
              <span class="main-info__dates-string">
                {{ creator.collabCreatorInput.proposedVisitDateTime ? getFormattedVisitTime(creator.collabCreatorInput) : "" }}
              </span>
            </div>
          </li>

          <!-- Only show this if the community can arrange lodging -->
          <li v-if="showLodgingSection" class="main-info__param-wrap">
            <IconEmbedded name="home_2" class="main-info__param-icon" />
            <div class="main-info__param-text">{{ getHotelStaySummary(creator.collabCreatorInput?.nightsRequired) }}</div>
          </li>

          <li v-if="creator.collabCreatorInput?.travelingWith" class="main-info__param-wrap">
            <IconEmbedded name="users_2" class="main-info__param-icon" />
            <div class="main-info__param-text">Companions: {{ creator.collabCreatorInput?.travelingWith }}</div>
          </li>

          <li v-if="creator.creatorPreferences?.locationName" class="main-info__param-wrap">
            <IconEmbedded name="map-pin_2" class="main-info__param-icon" />
            <div class="main-info__param-text">{{ creator.creatorPreferences?.locationName }}</div>
          </li>
        </ul>

        <div class="main-info__buttons-wrap">
          <SrpButton v-if="isCTAButtonVisible" class="main-info__choose-btn" size="small" color="orange" tag="label"> <slot />&nbsp;Choose </SrpButton>

          <SrpButton class="main-info__view-profile-btn" size="small" fill="outlined" :href="'/creator/' + creator.creatorId" target="_blank">View Profile</SrpButton>
        </div>
      </div>
      <!-- / Main info -->
    </div>

    <template v-if="creator.featuredMedia?.length">
      <!-- Featured media -->
      <div
        :class="{
          'featured-media': true,
          'featured-media--with-bottom-curtain': !isFeaturedMediaSectionExpanded,
          'creator-snippet__featured-media': true,
        }"
      >
        <TransitionedHeightAuto class="featured-media__masonry-wrap" :isOpened="isFeaturedMediaSectionExpanded" :minHeight="260" isKeepAlive contentVerticalAttachment="top" :transitionDuration="230">
          <SrpMasonry class="featured-media__masonry" :items="creator.featuredMedia" :columns="screenSize === 'mobile' ? 3 : 4" :columnGap="7">
            <template #default="{ item, index, rebuildMasonry }">
              <SrpFileThumbnail :fileName="item" style="margin-bottom: 7px" :isFixedAspectRatio="false" @imageLoad="rebuildMasonry" :galleryPostfix="creator.creatorId" isEntireAreaClickable />
            </template>
          </SrpMasonry>
        </TransitionedHeightAuto>

        <LinkWithIcon class="featured-media__show-more-btn" isDottedUnderline @click="isFeaturedMediaSectionExpanded = !isFeaturedMediaSectionExpanded" iconInset="1px auto auto 2px">
          <template #icon><IconEmbedded :name="isFeaturedMediaSectionExpanded ? 'caret-top_4' : 'caret-bottom_4'" :size="13" /></template>
          <span v-if="isFeaturedMediaSectionExpanded">Show less</span>
          <span v-else>Show more</span>
        </LinkWithIcon>
        <!--<HighlightsSection class="creator-snippet__featured-media" size="sm" :highlight-asset-ids="creator.featuredMedia" />-->
      </div>
      <!-- / Featured media -->

      <div class="creator-snippet__divider" style="order: 1"></div>
    </template>

    <template v-else-if="creator.recentAdventures.length">
      <!-- Adventures carousel -->
      <div class="adventures-carousel creator-snippet__adventures-carousel">
        <div class="adventures-carousel__carousel">
          <div v-if="scrollPosition !== 0 && creator.recentAdventures.length > 2" class="adventures-carousel__arrow adventures-carousel__arrow--left" @click="scrollCarousel('left')">
            <IconEmbedded name="caret-left_4" :size="17" color="rgba(0, 0, 0, 0.5)" />
          </div>
          <div
            v-if="scrollPosition !== creator.recentAdventures.length - scrollStep && creator.recentAdventures.length > 2"
            class="adventures-carousel__arrow adventures-carousel__arrow--right"
            @click="scrollCarousel('right')"
          >
            <IconEmbedded name="caret-right_4" :size="17" color="rgba(0, 0, 0, 0.5)" />
          </div>

          <div class="adventures-carousel__snippets-list-wrap">
            <ul class="adventures-carousel__snippets-list" :style="{ transform: `translate3d(-${scrollPosition}00%, 0, 0)` }">
              <li class="adventures-carousel__snippet-wrap" v-for="itinerary in creator.recentAdventures" :key="itinerary.id">
                <ItineraryTile class="adventures-carousel__snippet" :itinerary="itinerary" :allowShortImage="false" :showIsNew="false" :openNewWindow="true" :source="null" />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- / Adventures carousel -->

      <div class="creator-snippet__divider" style="order: 1"></div>
    </template>
  </li>
</template>

<script lang="ts">
import DateUtils from "@logic/DateUtils";
import { defineComponent, inject } from "vue";
import moment from "moment";
import linkifyStr from "linkify-string";

// Types
import { CollabCreatorInput, CreatorCollabMatch } from "@contracts/collab";
import { ScreenSize } from "@contracts/screenSize";

// Components
import ItineraryTile from "@components/ItineraryTile.vue";
import AvatarWithFallback from "@components/AvatarWithFallback.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import { mapState } from "pinia";
import { useUserProfileStore } from "@stores/userProfileStore";
import HighlightsSection from "./HighlightsSection.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import SocialMediaLinksBar from "@components/SocialMediaLinksBar.vue";
import ClampedText from "@components/ui/ClampedText.vue";
import SrpMasonry from "@components/ui/SrpMasonry.vue";
import SrpFileThumbnail from "@components/ui/SrpFileThumbnail.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import TransitionedHeightAuto from "@components/ui/TransitionedHeightAuto.vue";

export default defineComponent({
  name: "PaidCreatorSummary",

  components: {
    TransitionedHeightAuto,
    LinkWithIcon,
    SrpFileThumbnail,
    SrpMasonry,
    ClampedText,
    SocialMediaLinksBar,
    SrpButton,
    AvatarWithFallback,
    IconEmbedded,
    ItineraryTile,
    HighlightsSection,
  },

  props: {
    creator: { type: Object as () => CreatorCollabMatch, required: true },
    // Note: The below is just used for a link
    customerId: { type: String, required: true },
    collabInputId: { type: String, required: true },
    communityCanCoverLodging: { type: Boolean, required: true },
    isCTAButtonVisible: { type: Boolean, default: true, required: false },
    showRanking: { type: Boolean, default: true, required: false },
  },

  data() {
    return {
      screenSize: inject("screenSize") as ScreenSize,
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri as string,

      scrollPosition: 0,

      isFeaturedMediaSectionExpanded: false,
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["isSuperOrSalesUser"]),
    scrollStep(): number {
      return { mobile: 2, tablet: 3, "tablet-large": 2, laptop: 2, desktop: 3, "desktop-wide": 3 }[this.screenSize];
    },
    showLodgingSection(): boolean {
      if (this.isCTAButtonVisible) return this.communityCanCoverLodging;
      else {
        return this.communityCanCoverLodging && this.creator?.collabCreatorInput?.nightsRequired > 0;
      }
    },
  },

  watch: {
    screenSize() {
      this.scrollPosition = 0;
    },
  },

  methods: {
    moment,
    linkifyStr,
    scrollCarousel(direction: "left" | "right"): void {
      if (direction === "left" && this.scrollPosition === 0) return;
      else if (direction === "right" && this.scrollPosition === this.creator.recentAdventures.length - this.scrollStep) return;

      let newScrollPosition = this.scrollPosition;

      if (direction === "left") newScrollPosition -= this.scrollStep;
      else if (direction === "right") newScrollPosition += this.scrollStep;

      if (newScrollPosition < 0) newScrollPosition = 0;
      else if (newScrollPosition > this.creator.recentAdventures.length - this.scrollStep) newScrollPosition = this.creator.recentAdventures.length - this.scrollStep;

      this.scrollPosition = newScrollPosition;
    },
    getFormattedVisitTime(creatorInput: CollabCreatorInput): string {
      return DateUtils.formatDateRange(creatorInput.proposedVisitDateTime, creatorInput.numberOfDaysStaying);
    },
    getHotelStaySummary(nightsRequired: number): string {
      if (nightsRequired === 0) return "No lodging needed";
      if (nightsRequired === 1) return "Staying 1 night";
      else return "Staying 2 nights";
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";
@import "@/scss/screen-size-ranges.scss";

// Message cloud ==============================================================
.message-cloud {
  padding: 23px 17px 12px;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    width: 100%;
    height: calc(100% - 11px);
    border-radius: 3px;
    position: absolute;
    inset: 11px auto auto 0;
    z-index: -1;
    background: white;
  }

  &::after {
    content: "";
    width: 15px;
    height: 15px;
    position: absolute;
    inset: 3px auto auto 55px;
    transform: rotate(45deg);
    background: rgba(255, 255, 255, 1);
  }

  :deep(a) {
    text-decoration: underline !important;
  }
  :deep(a:hover) {
    text-decoration: none !important;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .message-cloud {
    &::after {
      left: 39px;
    }
  }
}

// Main info ==================================================================
.main-info {
  &__name-section {
    margin-bottom: 4px;
    display: inline-block;
    word-break: break-word;
    cursor: pointer;
    user-select: none;
  }

  &__name {
    color: #000;
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.15);
    text-underline-offset: 3px;
  }

  &__name-section:hover &__name {
    text-decoration: none;
    opacity: 0.7;
  }

  &__name-external-link-icon {
  }

  &__params-list {
    padding: 0;
    margin: 0 0 13px;
    list-style: none;
  }

  &__param-wrap {
    margin: 0 0 -1px -5px;
    display: flex;
    align-items: flex-start;
  }

  &__param-icon {
    width: 22px;
    height: 22px;
    margin-right: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 5px;

    :deep(svg) {
      fill: rgba(0, 0, 0, 0.3);
    }
  }

  &__param-text {
    padding-top: 7px;
  }

  &__dates-string {
    color: black;
  }

  &__buttons-wrap {
    margin: -4px;
    display: flex;
    flex-wrap: wrap;
  }

  &__choose-btn {
    width: auto !important;
    margin: 4px !important;
  }

  &__view-profile-btn {
    margin: 4px;
  }
}

// Additional info snippet ====================================================
.additional-info-snippet {
  display: flex;
  align-items: flex-start;

  &__icon {
    width: 26px;
    height: 26px;
    margin-right: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.3);
  }

  &__title-n-text {
  }

  &__title {
    margin-bottom: 1px;
  }

  &__text {
  }
}

// Adventures carousel =================================================
.adventures-carousel {
  &__title {
    margin-bottom: 1px;
  }

  &__carousel {
    position: relative;
  }

  &__arrow {
    width: 34px;
    height: 34px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 72px auto auto auto;
    z-index: 6;
    color: rgba(0, 0, 0, 0.5);
    font-size: 26px;
    line-height: 26px;
    cursor: pointer;
    user-select: none;

    &::before {
      width: calc(100% + 30px);
      min-width: calc(100% + 30px);
      height: calc(100% + 30px);
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 100px;
      position: absolute;
      inset: 0 auto auto 0;
      z-index: -1;
      background: white;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
      transition:
        width 0.07s ease-in-out,
        height 0.07s ease-in-out,
        inset 0.07s ease-in-out;
    }

    &:hover {
      &::after {
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        inset: -2px auto auto -2px;
      }
    }

    &--left {
      padding-right: 2px;
      left: -17px;
    }
    &--right {
      padding-left: 2px;
      right: -17px;
    }
  }

  &__snippets-list-wrap {
    width: calc(100% + 20px);
    padding: 0 0 10px 10px;
    margin-left: -10px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;

    &::before,
    &::after {
      content: "";
      width: 10px;
      height: 100%;
      position: absolute;
      inset: 0 auto auto 0;
      z-index: 5;
      background: linear-gradient(-90deg, rgba(240, 240, 240, 0) 0%, rgba(240, 240, 240, 1) 100%);
      pointer-events: none;
    }

    &::after {
      inset: 0 0 auto auto;
      background: linear-gradient(90deg, rgba(240, 240, 240, 0) 0%, rgba(240, 240, 240, 1) 100%);
    }
  }

  &__snippets-list {
    width: 174px;
    padding: 0;
    margin: 0;
    display: flex;
    list-style: none;
    transition: transform 0.16s ease-in-out;
  }

  &__snippet-wrap {
    min-width: 100%;
    padding-right: 19px;
    display: flex;
  }

  &__snippet {
    width: 100%;
    min-width: 100%;
    height: 100%;
    margin: 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);

    &:hover {
      box-shadow: 0 4px 15px -5px rgba(0, 0, 0, 0.25) !important;
    }

    :deep(.content) {
      padding: 8px 12px;
    }

    :deep(.global-h5) {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 16px;
    }

    :deep(.meta) {
      display: flex;
      flex-direction: column;
    }

    :deep(.city) {
      margin-bottom: 5px !important;
      font-size: 14px;
      line-height: 16px;
    }
    :deep(.timeframe) {
      margin: 0 !important;
      font-size: 14px;
      line-height: 16px;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .adventures-carousel {
    &__arrow {
      top: 57px;
    }

    &__snippets-list {
      width: 144px;
    }

    &__snippet-wrap {
      padding-right: 15px;
    }
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .adventures-carousel {
    &__arrow {
      top: 79px;
    }

    &__snippets-list {
      width: 188px;
    }

    &__snippet-wrap {
      padding-right: 18px;
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .adventures-carousel {
    &__arrow {
      top: 79px;
    }

    &__snippets-list {
      width: 171px;
    }

    &__snippet-wrap {
      padding-right: 18px;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .adventures-carousel {
    &__arrow {
      top: 65px;
    }

    &__snippets-list {
      width: 159px;
    }

    &__snippet-wrap {
      padding-right: 18px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .adventures-carousel {
    &__arrow {
      top: 65px;
    }

    &__snippets-list-wrap {
      width: calc(100% + 32px);
      padding: 10px 0 10px 16px;
      margin-left: -16px;
    }

    &__snippets-list {
      width: calc($mobile-grid-col-width * 3 + $mobile-grid-gap-width * 2);
    }

    &__snippet-wrap {
      padding-right: 18px;
    }
  }
}

// Avatar container ===========================================================
.avatar-container {
  position: relative;
  z-index: 0;

  &__rank-badge {
    width: 40px;
    height: 27px;
    padding-right: 1px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: auto auto -14px calc(50% - 20px);
    z-index: 2;
    text-align: center;
    color: rgba(0, 0, 0, 1);
    font:
      900 19px/19px "Quicksand",
      sans-serif;
    background: 0 0/100% 100% no-repeat url(/images/rank-badge-bg.svg);
  }

  &__avatar {
    width: 100%;
    border-radius: 1000px;

    &--with-highlight-outline {
      outline: 4px $brand-color solid;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .avatar-container {
    height: auto;
  }
}

// Featured media =============================================================
.featured-media {
  order: 0;
  width: calc(100% + 7px);
  margin-bottom: 25px;
  position: relative;
  z-index: 0;
  overflow: hidden;

  &::after {
    content: "";
    width: 100%;
    height: 65px;
    position: absolute;
    inset: auto 0 0 auto;
    background: linear-gradient(0deg, rgba(240, 240, 240, 1) 60%, transparent 100%);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.23s ease-in-out;
  }

  &--with-bottom-curtain {
    &::after {
      opacity: 1;
    }
  }

  &__masonry-wrap {
    padding-bottom: 35px;
  }

  &__masonry {
  }

  &__show-more-btn {
    position: absolute;
    inset: auto auto 10px calc(50% - 60px);
    z-index: 2;
  }
}

// Creator snippet ============================================================
.creator-snippet {
  padding: 21px 21px 11px;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  background: rgba(240, 240, 240, 1);

  &__featured-media {
    margin-bottom: 10px;
  }

  &__divider {
    margin-bottom: 25px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__message-cloud {
    order: 3;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__avatar-n-main-info {
    order: 2;
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }
  }

  &__col-left {
    width: 125px;
    min-width: 125px;
    margin-right: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__avatar-container {
    width: 100%;
    margin: 0 0 14px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__gray-social-icons-list {
    height: 26px;
    position: relative;
    overflow: hidden;
  }

  &__main-info {
  }

  &__additional-info-list {
    padding: 0 0 17px;
    margin: 0 0 20px;
    list-style: none;

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }
  }

  &__additional-info-snippet {
    margin: 0 0 12px -3px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__adventures-carousel {
    margin-bottom: 20px;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .creator-snippet {
    padding: 17px 17px 12px;

    &__col-left {
      width: 95px;
      min-width: 95px;
    }
  }
}
</style>
