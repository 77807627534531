<template>
  <div>
    <div class="ui message" style="margin-bottom: 2rem">Easily share this stop in any of your marketing channels by copying and downloading the data to craft the perfect post!</div>
    <div class="title-and-copy">
      <h2 class="primary"><CopyText :text="currentStop.title" /></h2>
    </div>
    <div class="ui tiny action input fluid"><b>Adventure:</b>&nbsp; <CopyText :text="getAdventureUrl(false)" /></div>
    <div class="ui tiny action input fluid"><b>Stop:</b>&nbsp; <CopyText :text="getAdventureUrl(true)" /></div>
    <div class="ui stackable grid" style="margin-top: 0.25rem">
      <div class="ui seven wide column">
        <div class="ui segment">
          <div class="title-and-copy">
            <h3>Description</h3>
            <CopyText :text="currentStop.details" :showText="false" />
          </div>
          <span style="white-space: pre-line">
            {{ currentStop.details }}
          </span>
          <div class="pro-tip-copy-section" v-if="currentStop.proTip">
            <div style="display: flex; margin-bottom: 0.5rem">
              <div class="pro-tip-icon">
                <img :src="`${contentBaseUri}/images/shrpa-mark-inverted.png`" />
              </div>
              <h3 style="margin-top: 0 !important">Pro Tip</h3>
              <CopyText :text="currentStop.proTip" :showText="false" />
            </div>
            <div>
              {{ currentStop.proTip }}
            </div>
          </div>
        </div>
      </div>
      <div class="ui nine wide column">
        <div class="ui segment" v-if="currentStop.images && currentStop.images.length > 0">
          <h3>Images</h3>
          <div class="ui images">
            <div class="ui image" v-for="(image, index) in currentStop.images" :key="`image-${index}`">
              <div class="image-wrap">
                <button class="ui icon mini button"><i class="icon cloud download" @click="downloadImage(image)"></i></button>
                <template v-if="isVideo(image)">
                  <VideoRender :videoId="image" :showControls="false" :showViewerOnClick="false" :cropThumbnail="true" :customClasses="'ui centered image share-stop-img'" />
                </template>
                <template v-else>
                  <img class="ui image share-stop-img" :src="`${contentBaseUri}/cms/images/expeditions/thumbnails/${image}`" />
                </template>
              </div>
            </div>
          </div>
          <!--<div style="margin-top:0.5rem;">
            <button class="ui small basic button">
                <i class="cloud download icon"></i>
                Download All Images
            </button>
          </div>-->
        </div>
      </div>
      <div class="ui ten wide column">
        <div v-if="creator" class="ui segment">
          <div style="display: flex; align-items: center">
            <div style="margin-right: 1rem">
              <img class="ui tiny circular image" :src="creator.profileImageUri" />
            </div>
            <div>
              <div style="margin-bottom: 0.5rem">
                <div class="ui header primary" style="margin-bottom: 0.5em">{{ creator.firstName }}</div>
              </div>
              <div>
                <router-link class="ui basic primary tiny button" :to="{ name: 'CreatorProfilePublic', params: { creatorId: creator.uriKey } }" target="_blank">View Creator</router-link>
              </div>
            </div>
          </div>
          <div class="creator-social-btns">
            <SocialMediaLinksBar :socialFields="creator" :showCopyIcon="true" />
          </div>
        </div>
      </div>
      <div class="ui six wide column" v-if="currentStop.website || currentStop.customLinkText">
        <div class="ui segment">
          <h3>Links</h3>
          <div class="ad-info-item" v-if="currentStop.website">{{ currentStop.title }} Website <CopyText :text="currentStop.website" :showText="false" /></div>
          <div class="ad-info-item" v-if="currentStop.customLinkText">{{ currentStop.customLinkText }} <CopyText :text="currentStop.customLinkUri" :showText="false" /></div>
        </div>
      </div>
      <!--<div class="ui sixteen wide column">
      <div class="ui segment">
        <h3>Hashtags</h3>
        <div class="ui stackable grid">
          <div class="ui sixteen wide column">
            <div><CopyText :text="hashTags" /></div>
          </div>
        </div>
      </div>
    </div>-->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { PropType } from "vue";
import { Itinerary, ItineraryStep } from "@contracts/itinerary";
import { CreatorPublicProfileFields } from "@contracts/creatorPublicProfileFields";
import CopyText from "@components/CopyText.vue";
import SocialMediaLinksBar from "@components/SocialMediaLinksBar.vue";
import FileUtils from "@logic/FileUtils";
import VideoRender from "@components/VideoRender.vue";
import FileDownload from "@logic/FileDownload";
import OrgContext from "@logic/OrgContext";

export default defineComponent({
  name: "ShareStop",

  components: {
    CopyText,
    SocialMediaLinksBar,
    VideoRender,
  },

  props: {
    itinerary: { type: Object as PropType<Itinerary>, required: true },
    currentStop: { type: Object as PropType<ItineraryStep>, required: true },
    currentStopIndex: { type: Object as PropType<number>, required: true }, // Zero-based stop index
    creator: { type: Object as PropType<CreatorPublicProfileFields>, required: true },
  },

  data() {
    return {
      globalLog: inject("globalLog") as any,

      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      hashTags: "#explorelocal @tryshrpa",
      // @ts-ignore
      videoContentBaseUri: globalThis.Bootstrap.Config.contentBlobBaseUri, // Not using the CDN, see comments below
    };
  },

  computed: {},

  methods: {
    getAdventureUrl(directToStop = false): string {
      if (this.itinerary) {
        if (directToStop) return `${window.location.origin}/stops/${this.itinerary.uniqueName}#stop=${this.currentStopIndex + 1}`;
        else return `${window.location.origin}/itinerary/${this.itinerary.uniqueName}`;
      }

      return window.location.href;
    },
    async downloadImage(imageName) {
      let customerIdInContext = null;
      try {
        let orgInContext = OrgContext.getOrgInContext(this);
        customerIdInContext = orgInContext?.customerId;
        if (!customerIdInContext) this.globalLog.warn("downloadImage NoOrgInContext");
      } catch (err) {
        this.globalLog.error("downloadImage.orgInContext Error:" + err.message, err);
      }
      let downloadUncompressed = false;
      await FileDownload.downloadFile(imageName, downloadUncompressed, customerIdInContext, "share-stop");
    },
    isVideo(assetName) {
      return FileUtils.isVideoFileType(assetName, null);
    },
  },
});
</script>

<style scoped lang="scss">
.pro-tip-copy-section {
  border: 2px solid #fae7e3;
  border-radius: 8px;
  padding: 12px;
  margin: 1rem 0;
}

.title-and-copy {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  button {
    margin-left: 1rem;
  }
  h3 {
    margin: 0;
  }
  h2 {
    margin: 0;
  }
}

.creator-social-btns {
  margin-top: 1.5rem;
  display: flex;
}

.pro-tip-icon {
  // position: absolute;
  // top: -1.2rem;
  // left: -1rem;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  padding: 4px;
  background: #dc6047;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin: calc(2rem - 0.1428571428571429em) 0 1rem;
  margin-right: 0.5rem;
  @media screen and (max-width: 420px) {
    // position: absolute;
    // top: -.8rem;
    // left: -1rem;
    width: 2rem;
    height: 2rem;
  }
  img {
    height: 85%;
    transform: rotate(30deg);
  }
}

.image-wrap {
  position: relative;

  button.ui.icon.button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 99;
  }
}
</style>
