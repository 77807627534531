<template>
  <div
    :class="{
      'stats-section': true,
      'stats-section--small': props.size === 'small',
      'stats-section--large': props.size === 'large',
    }"
  >
    <template v-for="(key, index) in Object.keys(props.fields)" :key="key">
      <!-- Stats cell -->
      <div
        v-if="props.fields[key] > 0"
        :class="{
          'stats-cell': true,
          'stats-section__stats-cell': true,
          'stats-section__stats-cell--small-margins': props.size === 'small',
          'stats-section__stats-cell--large-margins': props.size === 'large',
        }"
      >
        <div
          :class="{
            'stats-cell__number': true,
            'stats-cell__number--small': props.size === 'small',
            'stats-cell__number--large': props.size === 'large',
          }"
        >
          {{ props.fields[key] }}
        </div>

        <div
          :class="{
            'stats-cell__text': true,
            'stats-cell__text--small': props.size === 'small',
            'stats-cell__text--large': props.size === 'large',
          }"
        >
          {{ key }}
        </div>
      </div>
      <!-- / Stats cell -->

      <div
        v-if="props.fields[key] > 0 && index !== Object.keys(props.fields).length - 1"
        :class="{
          'stats-section__divider': true,
          'stats-section__divider--small': props.size === 'small',
          'stats-section__divider--large': props.size === 'large',
        }"
      ></div>
    </template>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    fields: { [key: string]: number };
    size?: "small" | "normal" | "large";
  }>(),
  {
    fields: () => ({}),
    size: "normal",
  }
);
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Stats cell =================================================================
.stats-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &__number {
    margin-right: 16px;
    color: rgba(17, 134, 137, 1);
    font:
      500 55px/55px "Quicksand",
      sans-serif;

    &--small {
      margin-right: 11px;
      font-size: 35px;
      line-height: 35px;
    }
    &--large {
      margin-right: 18px;
      font-size: 65px;
      line-height: 65px;
    }
  }

  &__text {
    color: rgba(0, 0, 0, 1);
    font:
      300 25px/29px "Quicksand",
      sans-serif;

    &--small {
      font-size: 20px;
      line-height: 24px;
    }
    &--large {
      font-size: 30px;
      line-height: 35px;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .stats-cell {
    &__number {
      margin-right: 12px;
      font-size: 45px;
      line-height: 45px;

      &--small {
        margin-right: 7px;
        font-size: 30px;
        line-height: 30px;
      }
      &--large {
        margin-right: 14px;
        font-size: 55px;
        line-height: 55px;
      }
    }

    &__text {
      font-size: 22px;
      line-height: 26px;

      &--small {
        font-size: 17px;
        line-height: 21px;
      }
      &--large {
        font-size: 27px;
        line-height: 32px;
      }
    }
  }
}

// Stats section ==============================================================
.stats-section {
  padding: 20px 0 23px;
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
  background: rgba(243, 243, 243, 1);

  &--small {
    padding: 17px 0 20px;
  }
  &--large {
    padding: 27px 0 30px;
  }

  &__stats-cell {
    margin: 0 45px;

    &--small-margins {
      margin: 0 33px;
    }
    &--large-margins {
      margin: 0 52px;
    }
  }

  &__divider {
    width: 0;
    height: 50px;
    border-right: 1px rgba(0, 0, 0, 0.1) solid;

    &--small {
      height: 33px;
    }
    &--large {
      height: 65px;
    }

    &:first-child,
    &:last-child {
      display: none;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .stats-section {
    &__stats-cell {
      margin: 0 30px;

      &--small-margins {
        margin: 0 20px;
      }
      &--large-margins {
        margin: 0 42px;
      }
    }
  }
}
</style>
