<template>
  <div
    :class="{
      'message-bubble': true,
      'message-bubble--no-left-padding': isMsgYours,
    }"
  >
    <div v-if="!isMsgYours && (!isPrevMsgYours || !isPrevMsgExist || !checkIsMsgDatesAreSame(message, prevMessage))" class="message-bubble__avatar-wrap">
      <div v-if="isSkeleton" class="message-bubble__avatar-skeleton"></div>
      <img v-else class="message-bubble__avatar" alt="Avatar" :src="avatarSrc" />
    </div>
    <div
      :class="{
        'message-bubble__text-container': true,
        'message-bubble__text-container--green': isMsgYours,
        'message-bubble__text-container--right-aligned': isMsgYours,
        'message-bubble__text-container--rounded-left-top': isMsgYours,
        'message-bubble__text-container--rounded-left-bottom': (!isMsgYours && (!isNextMsgYours || !isNextMsgExist)) || !checkIsMsgDatesAreSame(message, nextMessage),
        'message-bubble__text-container--rounded-right-bottom': isMsgYours && (!isNextMsgYours || !isNextMsgExist),
        'message-bubble__text-container--invisible': isSkeleton,
        'message-bubble__text-container--no-tail': participantIndex === participantIndexOfNextMsg && checkIsMsgDatesAreSame(message, prevMessage),
      }"
    >
      <div v-if="isSkeleton" class="message-bubble__skeleton-overlay"></div>
      <div v-if="(!isMsgYours && !isPrevMsgYours) || !checkIsMsgDatesAreSame(message, prevMessage)" class="global-h6 message-bubble__name">
        {{ message.senderType === "Shrpa" ? "Shrpa" : msgAuthorName }}
      </div>

      <!-- prettier-ignore -->
      <div
        v-if="message.text"
        :class="{
          'message-bubble__text': true,
          'message-bubble__text--invisible': isSkeleton,
        }"
        v-html="linkifyStr(message.text, globalRoot.linkifyOptions)"
      >
      </div>

      <ul
        v-if="message.attachments && message.attachments.length"
        :class="{
          'message-bubble__files-list': true,
          'message-bubble__files-list--invisible': isSkeleton,
        }"
      >
        <!-- Attached file -->
        <li class="attached-file message-bubble__file" v-for="(file, index) in message.attachments" :key="index">
          <a class="attached-file__link-overlay" @click="downloadFileByURI(`${contentBaseUri}/message-attachments/${file.fileId}`)" download></a>
          <IconEmbedded class="attached-file__clip-icon" name="paperclip_2" />
          <div class="attached-file__name">
            {{ file.fileName }}
          </div>
        </li>
        <!-- / Attached file -->
      </ul>
      <div
        :class="{
          'message-bubble__time': true,
          'message-bubble__time--right-aligned-on-small-screen': isMsgYours,
          'message-bubble__time--left-aligned': isMsgYours,
        }"
      >
        <div v-if="isSkeleton" class="message-bubble__time-skeleton-overlay"></div>
        {{ messageTime }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import checkIsMsgDatesAreSame from "./checkIsMsgDatesAreSame";
import { defineComponent } from "vue";
import FileDownload from "@logic/FileDownload";
import linkifyStr from "linkify-string";

// Types
import { CollabThreadMessage, SenderDetails } from "./index.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export default defineComponent({
  name: "MessageBubble",
  components: { IconEmbedded },

  props: {
    msgIndex: { type: Number, required: true },
    messagesList: { type: Array as () => Array<CollabThreadMessage>, required: true },
    senderDetails: { type: Array as () => Array<SenderDetails>, required: true },
    isSkeleton: { type: Boolean, default: false },
    messageSenderType: { type: String, required: true, validator: (v: string) => ["CustomerId", "CreatorId"].includes(v) },
  },

  data() {
    return {
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri as string,
    };
  },

  computed: {
    prevMessage(): CollabThreadMessage {
      return this.messagesList[this.msgIndex - 1] || null;
    },
    nextMessage(): CollabThreadMessage {
      return this.messagesList[this.msgIndex + 1] || null;
    },
    message(): CollabThreadMessage {
      return this.messagesList[this.msgIndex];
    },
    avatarSrc(): string {
      const senderId = this.message.senderId;
      const shrpaImg = `${this.contentBaseUri}/images/misc/avatar_shrpa.jpg`;
      return this.message.senderType === "Shrpa" ? shrpaImg : this.senderDetails?.find(s => s.senderId === senderId)?.image;
    },
    msgAuthorName(): string {
      const senderId = this.message.senderId;
      return senderId.toLowerCase() === "shrpa" ? "Shrpa" : this.senderDetails?.find(s => s.senderId === senderId)?.name;
    },
    isMsgYours(): boolean {
      return this.message.senderType === this.messageSenderType;
    },
    isNextMsgYours(): boolean {
      return this.messagesList[this.msgIndex + 1]?.senderType === this.message.senderType;
    },
    isPrevMsgYours(): boolean {
      return this.messagesList[this.msgIndex - 1]?.senderType === this.message.senderType;
    },
    isNextMsgExist(): boolean {
      return Boolean(this.messagesList[this.msgIndex + 1]);
    },
    isPrevMsgExist(): boolean {
      return Boolean(this.messagesList[this.msgIndex - 1]);
    },
    messageTime(): string {
      const date = new Date(this.message.sentOn);
      return date.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true });
    },
    participantIndex(): number {
      return this.chatParticipants.findIndex(i => i === this.message.senderType);
    },
    participantIndexOfNextMsg(): number {
      return this.chatParticipants.findIndex(i => i === this.messagesList[this.msgIndex - 1]?.senderType);
    },
    // participantIndex is needed for CSS to add bigger indents between messages from different authors
    chatParticipants(): Array<string> {
      return Array.from(new Set(this.messagesList.map(i => i.senderType)));
    },
  },

  methods: {
    linkifyStr,
    checkIsMsgDatesAreSame,
    downloadFileByURI: FileDownload.downloadFileByURI,
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";
@import "@/scss/screen-size-ranges.scss";
@import "@/scss/mixins/animations/skeleton-loading.scss";

// Attached file ==============================================================
.attached-file {
  cursor: pointer;
  user-select: none;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
  }

  &__link-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: 1;
  }

  &__clip-icon {
    width: 20px;
    height: 20px;
    margin-right: 3px;
    display: inline-flex;
    position: relative;
    top: 5px;

    :deep(svg) {
      fill: rgba(0, 0, 0, 0.5);
    }
  }

  &__name {
    display: inline;
    padding-bottom: 1px;
    border-bottom: 1px rgba(#5b5b5b, 0.7) dashed;
    color: #5b5b5b;
    font:
      bold 14px/14px "Helvetica Neue",
      sans-serif;
  }

  &:hover &__name {
    border-color: transparent;
  }
}

// Message bubble =============================================================
.message-bubble {
  padding-left: 41px;
  display: flex;
  align-items: flex-start;
  position: relative;
  z-index: 0;

  &--no-left-padding {
    padding-left: 0;
  }

  &__date-divider {
    width: 100%;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: -15px auto auto 0;
    outline: 1px rgba(255, 0, 0, 0.3) solid !important;
  }

  &__avatar-wrap {
    width: 32px;
    min-width: 32px;
    height: 32px;
    min-height: 32px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: 2;
  }

  &__avatar-skeleton {
    @include skeletonLoading;
    width: calc(100% + 2px);
    min-width: calc(100% + 2px);
    height: calc(100% + 2px);
    min-height: calc(100% + 2px);
    border-radius: 4px;
  }

  &__avatar {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: contain;
  }

  &__text-container {
    width: 100%;
    padding: 9px 15px 11px;
    box-sizing: border-box;
    border: 1px #ccd3d7 solid;
    border-radius: 0 6px 6px 0;
    display: flex;
    flex-direction: column;
    position: relative;
    background: #fff;

    &--green {
      border-color: #d9eed3;
      background: #d9eed3;
    }

    &--invisible {
      border-color: transparent;
      background: transparent;
      pointer-events: none;
    }

    &::before {
      content: "";
      width: 11px;
      height: 13px;
      position: absolute;
      inset: -1px auto auto -11px;
      background: url(@/assets/images/msgBoxTails/left_white_bg_gray_border.gif);
    }

    &--invisible {
      &::before {
        opacity: 0;
      }
    }

    &--right-aligned {
      border-radius: 6px 0 0 6px;

      &::before {
        inset: -1px -11px auto auto;
        background: url(@/assets/images/msgBoxTails/right_green.png);
      }
    }

    &--rounded-left-top {
      border-top-left-radius: 6px !important;
    }

    &--rounded-left-bottom {
      border-radius: 0 6px 6px 6px;
    }

    &--rounded-right-bottom {
      border-radius: 6px 0 6px 6px;
    }

    &--no-tail {
      &::before {
        display: none;
      }
    }
  }

  &__skeleton-overlay {
    @include skeletonLoading;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border-radius: 6px;
    position: absolute;
    inset: -1px auto auto -1px;
    background: #e7e7e7 !important;
  }

  &__name {
    margin-bottom: 2px;
  }

  &__time {
    position: absolute;
    inset: 0 auto auto calc(100% + 6px);
    color: rgba(#5b5b5b, 0.5);
    font:
      12px/12px "Helvetica Neue",
      sans-serif;
    white-space: nowrap;

    &--left-aligned {
      inset: 0 calc(100% + 6px) auto auto;
    }
  }

  &__time-skeleton-overlay {
    @include skeletonLoading;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: 2;
    background: #e7e7e7 !important;
  }

  &__text {
    color: #5b5b5b;
    font:
      14px/18px "Helvetica Neue",
      sans-serif;
    word-break: break-word;
    white-space: pre-wrap;

    &--invisible {
      opacity: 0;
    }

    :deep(a) {
      color: #2165a6;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__files-list {
    padding: 0;
    margin: 4px 0 0;
    list-style: none;

    &--invisible {
      opacity: 0;
    }

    &:first-child {
      margin-top: 0;
      margin-bottom: 3px;
    }
  }

  &__file {
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .message-bubble {
    &__time {
      margin-top: 4px;
      position: relative;
      inset: auto;
      font-size: 10px;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .message-bubble {
    &__time {
      margin: 3px 0 -6px;
      position: relative;
      inset: auto;
      font-size: 10px;

      &--right-aligned-on-small-screen {
        align-self: flex-end;
      }
    }
  }
}
</style>
