<template>
  <div
    :class="{
      'spoiler-with-icon': true,
      'spoiler-with-icon--green': color === 'green',
      'spoiler-with-icon--red': color === 'red',
      'spoiler-with-icon--blue': color === 'blue',
    }"
  >
    <!-- Header -->
    <div
      :class="{
        header: true,
        'header--opened': isOpened,
        'header--bg-green': color === 'green',
        'header--bg-red': color === 'red',
        'header--bg-blue': color === 'blue',
        'spoiler-with-icon__header': true,
      }"
      @click="isOpened = !isOpened"
    >
      <div class="header__text-n-icon">
        <slot name="header" />
      </div>
      <IconEmbedded class="header__caret-icon" :name="isOpened ? 'caret-top_2-5' : 'caret-bottom_2-5'" />
    </div>
    <!-- / Header -->

    <div
      :class="{
        'spoiler-with-icon__content-wrap': true,
        'spoiler-with-icon__content-wrap--bg-green': color === 'green',
        'spoiler-with-icon__content-wrap--bg-red': color === 'red',
        'spoiler-with-icon__content-wrap--bg-blue': color === 'blue',
        'spoiler-with-icon__content-wrap--closed': !isOpened,
      }"
    >
      <div class="spoiler-with-icon__content">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export default defineComponent({
  name: "SpoilerWithIcon",
  components: { IconEmbedded },

  props: {
    color: { type: String, default: "yellow", validator: (v: string) => ["yellow", "green", "red", "blue"].includes(v) },
  },

  data() {
    return {
      isOpened: false,
    };
  },
});
</script>

<style scoped lang="scss">
// Header =====================================================================
.header {
  height: 26px;
  padding: 0 10px 0;
  border-radius: 13px;
  display: flex;
  align-items: center;
  position: relative;
  line-height: 26px;
  background: #f0e7b8;
  cursor: pointer;
  user-select: none;
  transition-delay: 0.1s;

  &::before {
    content: "";
    width: calc(100% + 18px);
    height: calc(100% + 18px);
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
  }

  &--opened {
    border-radius: 13px 13px 0 0;
    transition-delay: 0s;
  }

  &--bg-green {
    background: #d5ecce;
  }
  &--bg-red {
    background: #f8d5cf;
  }
  &--bg-blue {
    background: #daf0f0;
  }

  &__text-n-icon {
    padding-bottom: 2px;
    margin-right: 5px;

    :deep(i) {
      margin-right: 5px;
      position: relative;
      top: 2px;
    }
  }

  &__caret-icon {
    width: 18px;
    height: 18px;

    :deep(svg) {
      width: 18px;
      fill: rgba(0, 0, 0, 0.5);
    }
  }
}

// Spoiler with icon ==========================================================
.spoiler-with-icon {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  z-index: 0;
  color: #7c6e23;
  font:
    400 14px/18px "Helvetica Neue",
    sans-serif;

  &--green {
    color: #416138;
  }
  &--red {
    color: #945347;
  }
  &--blue {
    color: #2a4141;
  }

  &__header {
    position: relative;
    z-index: 1;
  }

  &__content-wrap {
    max-height: 500px;
    box-sizing: border-box;
    border-radius: 0 13px 13px 13px;
    position: relative;
    overflow: hidden;
    background: #f0e7b8;
    transition: max-height 0.12s ease-in-out;

    &--closed {
      max-height: 0;
    }

    &--bg-green {
      background: #d5ecce;
    }
    &--bg-red {
      background: #f8d5cf;
    }
    &--bg-blue {
      background: #daf0f0;
    }
  }

  &__content {
    padding: 12px 19px 14px 15px;
    position: relative;
    z-index: 0;
    word-break: break-word;
  }
}
</style>
