import { nextTick, onMounted, onBeforeUnmount, Ref, ref, computed, inject, watch } from "vue";

import { ScreenSize } from "@contracts/screenSize";

export default function useScrollCurtains(
  targetContainer: Ref<HTMLElement>,
  screenSizes: Array<ScreenSize> = ["mobile", "tablet", "tablet-large", "laptop", "desktop", "desktop-wide"],
  visibilityThreshold = 10
) {
  const isLeftCurtainVisible = ref<boolean>(false);
  const isRightCurtainVisible = ref<boolean>(false);

  const screenSize = inject("screenSize") as Ref<ScreenSize>;

  onMounted(async () => {
    targetContainer.value?.addEventListener("scroll", setCurtainsVisibility);
    window.addEventListener("resize", setCurtainsVisibility);
    await nextTick();
    setTimeout(setCurtainsVisibility, 0);
  });

  onBeforeUnmount(async () => {
    targetContainer.value?.removeEventListener("scroll", setCurtainsVisibility);
    window.removeEventListener("resize", setCurtainsVisibility);
    await nextTick();
    setTimeout(setCurtainsVisibility, 0);
  });

  watch(
    () => screenSize.value,
    async () => {
      await nextTick();
      setTimeout(setCurtainsVisibility, 0);
    }
  );

  function setCurtainsVisibility() {
    if (!screenSizes.includes(screenSize.value) || !targetContainer?.value || targetContainer?.value.getBoundingClientRect().width === targetContainer?.value.scrollWidth) {
      isLeftCurtainVisible.value = false;
      isRightCurtainVisible.value = false;
    } else {
      isLeftCurtainVisible.value = targetContainer?.value?.scrollLeft >= visibilityThreshold;
      isRightCurtainVisible.value = targetContainer?.value?.scrollLeft <= targetContainer.value.scrollWidth - targetContainer.value.clientWidth - visibilityThreshold;
    }
  }

  return {
    isRight: isRightCurtainVisible,
    isLeft: isLeftCurtainVisible,
    setCurtainsVisibility,
  };
}
