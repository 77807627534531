import { PartnerNavInfo } from "@contracts/partnerNavInfo";

// Read checks for orgInContext, see AdminContext also (for the write side)
export default {
  // Returns the OrgInContext (or null if not set)
  getOrgInContext(component?: any, globalRoot?: any): PartnerNavInfo | null {
    return (component?.globalRoot || globalRoot).orgInContext as PartnerNavInfo | null;
  },
  // If the passed org is a hotel
  isHotel(orgInContext: PartnerNavInfo | null): boolean {
    return orgInContext && orgInContext.type === "Hotel";
  },
  // If the OrgInContext is of type Hotel
  isOrgInContextHotel(component?: any, globalRoot?: any): boolean {
    const orgInContext = this.getOrgInContext(component, globalRoot);
    return this.isHotel(orgInContext);
  },
};
