<template>
  <SrpModal :isVisible="isVisible" :isClosable="false" :isCloseButtonVisible="false" @update:isVisible="emit('update:isVisible', $event)" isWithScroll>
    <template #header>
      <h2 class="global-h2">Link this adventure to an active collab?</h2>
    </template>
    <template #content>
      <div>
        <p>
          Would you like to link this adventure to one of your active collabs?
          <br />
          Once you create an adventure, the collab it is linked to can't be changed.
        </p>
      </div>
      <div class="collabs-list" v-for="collab in collabs" :key="collab.collabInputId">
        <div class="collabs-list-item">
          <p>
            <strong>{{ collab.communityName }} - {{ collab.collabName }}</strong>
          </p>
          <SrpButton @click="emit('click:collab', collab)">Link</SrpButton>
        </div>
      </div>
    </template>
    <template #footer>
      <SrpButton size="small" fill="outlined" @click="emit('update:isVisible', false)">Don't link this adventure to a collab</SrpButton>
    </template>
  </SrpModal>
</template>

<script setup lang="ts">
import SrpButton from "@components/ui/SrpButton.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import { CreatorCollabSummary } from "@contracts/collab";

defineProps<{ isVisible: boolean; collabs: CreatorCollabSummary[] }>();
const emit = defineEmits<{ (e: "update:isVisible", value: boolean): void; (e: "click:collab", value: CreatorCollabSummary): void }>();
</script>

<style scoped lang="scss">
.collabs-list {
  border-top: 1px solid #e0e0e0;
  padding: 15px 30px;
  margin-top: 15px;
  .collabs-list-item {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
}
</style>
