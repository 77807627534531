<template>
  <!-- Note: remove SrpModal wrapper -->
  <SrpModal
    :isVisible="props.isVisible"
    @update:isVisible="removeListenersAndCloseModal"
    :isContentPaddings="false"
    isWithScroll
    :zIndex="1000"
    :isWithBottomGap="false"
    ref="domRefModal"
    :isClosable="userCanShortcut"
    :isCloseButtonVisible="false"
    maxHeight="calc(100dvh - 270px)"
  >
    <template #insideWindow>
      <div
        v-if="activeSlideIndex !== 0"
        class="nav-arrow-btn nav-arrow-btn--gray"
        :style="{
          position: 'absolute',
          inset: ['mobile', 'tablet'].includes(screenSize) ? 'calc(50% - 22px) auto auto -18px' : 'calc(50% - 22px) auto auto -22px',
          zIndex: 4,
        }"
        @click="activeSlideIndex -= 1"
      >
        <IconEmbedded name="arrow-left_3" color="rgba(255, 255, 255, 1)" :size="26" />
      </div>
      <div
        v-if="activeSlideIndex !== 2"
        class="nav-arrow-btn"
        :style="{
          position: 'absolute',
          inset: ['mobile', 'tablet'].includes(screenSize) ? 'calc(50% - 22px) -18px auto auto' : 'calc(50% - 22px) -22px auto auto',
          zIndex: 4,
        }"
        @click="activeSlideIndex += 1"
      >
        <IconEmbedded name="arrow-right_3" color="rgba(255, 255, 255, 1)" :size="26" />
      </div>
    </template>

    <template #header>
      <h1 class="global-h1" style="text-align: center">Creating Adventures</h1>
      <div style="margin: 20px 0 0; border-bottom: 1px rgba(0, 0, 0, 0.1) solid"></div>
    </template>

    <template #content>
      <div class="slides-carousel" :style="{ margin: screenSize === 'mobile' ? '10px 0 15px' : '30px 0 30px' }">
        <div class="slides-carousel__slides-list-wrap1">
          <div class="slides-carousel__slides-list-wrap2">
            <ul
              class="slides-carousel__slides-list"
              :style="{
                height: activeSlideHeight === 'auto' ? 'auto' : `${activeSlideHeight}px`,
                marginLeft: `calc(-${100 * activeSlideIndex}% - ${17 * activeSlideIndex}px)`,
              }"
            >
              <li
                :class="{
                  'slides-carousel__slide-wrap': true,
                  'slides-carousel__slide-wrap--inactive': activeSlideIndex !== index,
                }"
                v-for="(component, index) in slideComponentsList"
                :key="index"
                ref="domRefsSlideContainers"
              >
                <component
                  :class="{
                    'slides-carousel__slide': true,
                    'slides-carousel__slide--hidden': activeSlideIndex !== index,
                  }"
                  :is="component"
                  @imageLoad="setSlideHeight(index)"
                  @masonryRebuilt="setSlideHeight(index)"
                  @close="emit('close')"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <!-- Actions wrapper -->
      <div class="actions-wrapper" style="width: 100%; margin-bottom: 4px">
        <div class="global-h1 actions-wrapper__counter">
          {{ `${activeSlideIndex + 1} of 3` }}
        </div>
        <SrpButton v-if="activeSlideIndex === 2" class="actions-wrapper__letsgo-btn" color="orange" @click="removeListenersAndCloseModal">Let's go!</SrpButton>
      </div>
      <!-- / Actions wrapper -->
    </template>
  </SrpModal>
</template>

<!-- prettier-ignore -->
<script lang="ts">export default { name: "AdventureGuide" };</script>

<script setup lang="ts">
import lodashDebounce from "lodash-es/debounce";
import { ref, nextTick, onMounted, watch, inject, Ref, onBeforeUnmount } from "vue";
import useSwipes from "@composables/useSwipes";

// Types
import { ScreenSize } from "@contracts/screenSize";

// Components
import SrpModal from "@components/ui/SrpModal.vue";
import SrpButton from "@components/ui/SrpButton.vue";
// slides
import Slide1Locations from "./Slide1Locations.vue";
import Slide2Photos from "./Slide2Photos.vue";
import Slide3Portfolio from "./Slide3Portfolio.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

// Global variables
const screenSize = inject("screenSize") as Ref<ScreenSize>;

const props = withDefaults(
  defineProps<{
    isVisible: boolean;
    userCanShortcut: boolean;
  }>(),
  {
    isVisible: true,
  }
);

const emit = defineEmits<{
  (e: "update:isVisible", value: boolean): void;
  (e: "close"): void;
}>();

// Slides list ================================================================
const slideComponentsList = [Slide1Locations, Slide2Photos, Slide3Portfolio];
const activeSlideIndex = ref<number>(0);

// Handle swipes ==============================================================
function switchSlide(to: -1 | 1): void {
  const i = activeSlideIndex.value;
  const listLength = slideComponentsList.length;
  if (to === -1) activeSlideIndex.value = i - 1 < 0 ? 0 : i - 1;
  else if (to === 1) activeSlideIndex.value = i + 1 > listLength - 1 ? listLength - 1 : i + 1;
}

// Activate swipes ============================================================
const domRefModal = ref(null);

let _addTouchListeners, _removeTouchListeners;

onMounted(async () => {
  const { addTouchListeners, removeTouchListeners } = useSwipes(
    domRefModal.value?.domRefModalItself,
    () => switchSlide(-1),
    () => switchSlide(+1),
    false
  );

  await nextTick();

  _addTouchListeners = addTouchListeners;
  _removeTouchListeners = removeTouchListeners;
});

watch(
  () => props.isVisible,
  async () => {
    if (props.isVisible) {
      await nextTick();
      _addTouchListeners(domRefModal.value.domRefModalItself as HTMLElement);
    }
  }
);

// Recalculate height =========================================================
const activeSlideHeight = ref<number | "auto">(0);
const domRefsSlideContainers = ref<Array<HTMLElement | null>>(null);

watch(
  () => props.isVisible,
  async () => {
    if (props.isVisible) {
      await nextTick();
      await setSlideHeight();
    }
  }
);

watch(
  () => activeSlideIndex.value,
  async () => {
    await setSlideHeight();
  }
);

async function setSlideHeight(slideIndex?: number): Promise<void> {
  const slide = domRefsSlideContainers.value?.[activeSlideIndex.value] as HTMLElement;
  if (!slide || !props.isVisible || (typeof slideIndex === "number" && slideIndex !== activeSlideIndex.value)) {
    return;
  }

  activeSlideHeight.value = "auto";
  await new Promise(resolve => setTimeout(resolve, 0));
  activeSlideHeight.value = Math.round(slide?.getBoundingClientRect().height) || 0;
}

const setSlideHeightDebounced = lodashDebounce(setSlideHeight, 100);

// Remove listeners and close modal ===========================================
async function removeListenersAndCloseModal() {
  _removeTouchListeners(domRefModal.value.domRefModalItself as HTMLElement);
  await nextTick();
  emit("update:isVisible", false);
}

// Reset slide height on screen size change ===================================
onMounted(() => globalThis.addEventListener("resize", setSlideHeightDebounced));
onBeforeUnmount(() => globalThis.removeEventListener("resize", setSlideHeightDebounced));
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Nav arrow btn ==============================================================
.nav-arrow-btn {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  color: rgba(255, 255, 255, 1);
  font-size: 26px;
  cursor: pointer;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 200px;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -1;
    background: rgba(5, 133, 135, 1);
    transition: transform 0.07s ease-in-out;
  }

  &:hover::after {
    transform: scale(1.12);
  }

  &--gray {
    &::after {
      background: rgba(183, 183, 183, 1);
    }
  }
}

// Actions wrapper ============================================================
.actions-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__counter {
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.3);
    font-weight: 500;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__letsgo-btn {
  }
}

// Slides carousel ============================================================
.slides-carousel {
  position: relative;
  overflow: hidden;

  &__slides-list-wrap1 {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
  }

  &__slides-list-wrap2 {
    width: calc(100% - 90px);
    min-width: calc(100% - 90px);
    transition: height 0.12s ease-in-out;
  }

  &__slides-list {
    width: 100%;
    min-width: 100%;
    padding: 0;
    margin: 0 0 12px;
    display: flex;
    align-items: center;
    list-style: none;
    transition: margin-left 0.12s ease-in-out;
  }

  &__slide-wrap {
    min-width: 100%;
    height: auto;
    padding: 45px 40px 55px;
    margin-right: 17px;
    box-sizing: border-box;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.03);

    &--inactive {
      height: calc(100% - 100px);
      max-height: calc(100% - 100px);
    }
  }

  &__slide {
    opacity: 1;
    transition: opacity 0.12s ease-in-out;

    &--hidden {
      opacity: 0;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .slides-carousel {
    &__slides-list-wrap2 {
      width: calc(100% - 20px);
      min-width: calc(100% - 20px);
    }

    &__slide-wrap {
      padding: 30px 15px 35px;
      margin-right: 17px;
    }
  }
}
</style>
