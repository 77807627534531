<template>
  <div>
    <div id="expeditions" class="hero">
      <div class="ui basic center aligned segment no-border none mission-theme">
        <h1 class="page-subheader wow fadeInDown" style="visibility: visible; animation-name: fadeInDown">Self-guided adventures, created by locals, experienced by you!</h1>
      </div>

      <div class="ui container featured-expeditions shrpas-lead" style="margin-top: 25px">
        <div class="fourteen wide column">
          <div class="ui action fluid input">
            <input type="text" id="searchBox" v-model="searchText" @keyup.enter="filterResults" />
            <button class="ui orange button" @click="filterResults">Search</button>
          </div>

          <div class="small-spacer"></div>
        </div>

        <div class="medium-spacer"></div>

        <br />
        <br />

        <div class="ui four doubling raised link cards adventure-cards">
          <Loader v-if="isLoading" style="position: absolute"></Loader>
          <ItineraryTile v-for="itinerary in itineraries" :itinerary="itinerary" :allowShortImage="false" :source="'search'" :key="itinerary.id" />
        </div>
      </div>
      <div class="medium-spacer"></div>
      <div v-if="totalSearchHits > returnCount && returnCount < 100" class="ui stackable center aligned grid container">
        <a @click="loadMore" class="ui primary button">Load More</a>
      </div>
      <h3 v-if="totalSearchHits === 0" style="text-align: center">No Results</h3>

      <div class="large-spacer">&nbsp;</div>
      <div class="large-spacer">&nbsp;</div>
    </div>
    <div class="ui alternate stripe vertical segment shrpas-lead none">
      <div class="ui stackable center aligned grid container">
        <img class="ui shrpa-mark-theme image" src="https://cdn.shrpa.com/images/shrpa-mark-inverted.png" alt="shrpa" />
        <div class="fourteen wide column"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { RouteHelper } from "@helpers/RouteHelper";
import { inject } from "vue";

// Components
import ItineraryTile from "@components/ItineraryTile.vue";
import Loader from "@components/Loader/Loader.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import { useHead } from "@unhead/vue";

export default {
  name: "SearchComponent",

  components: {
    SrpButton,
    ItineraryTile,
    Loader,
  },

  data() {
    return {
      globalLog: inject("globalLog"),

      isLoading: true,
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      itineraries: [],
      returnCount: 0,
      totalSearchHits: 0,
      requestedMaxReturnCount: null,
      // Query params
      searchText: "",
    };
  },

  async mounted() {
    useHead({ title: "Search" });

    this.getFiltersFromHash();
    // Fire an initial search
    await this.queryServerForItineraries();

    this.isLoading = false;
  },

  methods: {
    async filterResults(keepCurrentPosition) {
      let currentTop = window.scrollY;
      this.updateHash();
      // Now that we don't update history we need to explicitly fire the server query
      await this.queryServerForItineraries();
      // If keepCurrentPosition is true we try to snap the user to where they were before the re-bind (ex. load more)
      if (keepCurrentPosition) {
        this.globalLog.info(`Scrolling to ${currentTop}`);
        window.scrollTo(0, currentTop);
      }
    },
    queryServerForItineraries: async function () {
      var query = "";
      if (this.searchText && this.searchText !== "") query += "searchTerm=" + this.searchText;

      let lat = this.globalRoot.locationPreference.selectedPlaceLat;
      let long = this.globalRoot.locationPreference.selectedPlaceLon;
      if (lat && long) {
        query += "&latitude=" + lat + "&longitude=" + long + "&withinMiles=" + this.globalRoot.locationPreference.selectedProximity;
      }
      if (this.requestedMaxReturnCount) query += "&maxResults=" + this.requestedMaxReturnCount;

      console.info(`Searching with params: ${query}`);
      this.isLoading = true;
      const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/itineraries?${query}`);
      this.itineraries = data.matches;
      this.returnCount = data.returnCount;
      this.totalSearchHits = data.totalSearchHits;
      this.isLoading = false;
    },
    updateHash() {
      let query = "#";
      if (this.searchText && this.searchText !== "") query += "&searchText=" + this.searchText;
      if (this.requestedMaxReturnCount && this.requestedMaxReturnCount > 0) query += "&maxResults=" + this.requestedMaxReturnCount;

      // Don't save history
      history.replaceState(undefined, undefined, query);
    },
    getFiltersFromHash: function () {
      // Filters can be passed in as a param or on the querystring/hash
      this.searchText = this.$route.params.searchText;
      if (!this.searchText) {
        this.searchText = RouteHelper.getQueryStringOrHashParam("searchText");
      }
      let maxResultsParam = RouteHelper.getQueryStringOrHashParam("maxResults");
      if (maxResultsParam && maxResultsParam.length > 0) {
        this.requestedMaxReturnCount = parseInt(maxResultsParam);
      }
    },
    loadMore() {
      // Add 40 more and reload
      this.requestedMaxReturnCount = this.returnCount + 40;
      this.filterResults(true);
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen and (max-width: 700px) {
  #expeditions {
    .filter-container {
      width: 50% !important;
    }
  }
}

// Filters column =============================================================
.filters-column {
  &__title {
    margin-bottom: 7px;
  }

  &__list {
    padding: 0 0 0 3px;
    margin: 0;
    list-style: none;
  }

  &__li {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
  }

  &__checkbox {
    width: 13px;
    margin-right: 7px;
    position: relative;
    top: -1px;
  }
}

// Filters spoiler ============================================================
.filters-spoiler {
  width: 90px;
  padding: 3px 16px 3px;
  border-radius: 4px;
  background: white;

  &[open] {
    width: auto;
  }

  &__summary {
    padding: 10px 0;
    position: relative;
    z-index: 3;
    font-weight: bold;
    cursor: pointer;
    user-select: none;

    &::before {
      content: "";
      width: calc(100% + 30px);
      height: calc(100% + 10px);
      position: absolute;
      inset: 50% auto auto 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__content {
    padding: 14px 0 15px;
    border-top: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__columns-section {
    padding: 0 0 18px;
    margin: 0 0 14px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
    display: flex;
    list-style: none;
  }

  &__column {
    width: 250px;
    max-width: 100%;
    box-sizing: border-box;
    margin-right: 25px;
    border-right: 1px rgba(0, 0, 0, 0.1) solid;

    &:last-child {
      border: none;
    }
  }

  &__apply-btn {
  }
}
</style>
