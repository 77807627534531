<template>
  <div class="location-snippet">
    <!-- Header -->
    <div class="header location-snippet__header">
      <IconEmbedded class="header__map-marker-icon" name="map-pin-solid" :size="42" color="brandColor" />
      <h3 class="global-h3 header__title">
        {{ props.location?.title }}
      </h3>
    </div>
    <!-- / Header -->

    <TinyThumbnailsCarousel v-if="location.mediaIds?.length" class="location-snippet__gallery-carousel" :thumbnailsList="location.mediaIds" :fancyboxPrefix="location.id" />

    <div class="location-snippet__buttons-section">
      <SrpButton class="location-snippet__button" color="gray" fill="outlined" :size="screenSize === 'mobile' ? 'tiny' : 'small'">
        <template #icon><IconEmbedded name="file-text_2" :size="17" /></template>
        Create Social Post
      </SrpButton>
      <SrpButton class="location-snippet__button" color="gray" fill="outlined" :size="screenSize === 'mobile' ? 'tiny' : 'small'">
        <template #icon><IconEmbedded name="grid_2" :size="15" /></template>
        Create Photo Wall
      </SrpButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { inject, Ref } from "vue";

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import TinyThumbnailsCarousel from "@components/TinyThumbnailsCarousel.vue";

// Types
import { CollabLocation } from "@contracts/collabLocations";
import { ScreenSize } from "@contracts/screenSize";

// Injections =================================================================
const screenSize = inject("screenSize") as Ref<ScreenSize>;

const props = withDefaults(
  defineProps<{
    location: CollabLocation;
  }>(),
  {
    location: null,
  }
);
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Header =====================================================================
.header {
  display: flex;
  align-items: flex-start;

  &__map-marker-icon {
    margin-left: -6px;
  }

  &__title {
    padding-top: 5px;
    font-weight: 400;
  }
}

// Location snippet ===========================================================
.location-snippet {
  padding: 15px 20px 24px;
  border-radius: 6px;
  background: rgba(243, 243, 243, 1);

  &__header {
    margin-bottom: 10px;
  }

  &__gallery-carousel {
    margin-bottom: 20px;
  }

  &__buttons-section {
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
  }

  &__button {
    &::before,
    &:hover::before {
      background: rgba(255, 255, 255, 1);
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .location-snippet {
    padding: 11px 14px 17px;
  }
}
</style>
