<template>
  <div class="onboarding-adventure-page">
    <Loader v-if="isLoadingItinerary" />

    <StepWizard v-else class="onboarding-adventure-page__step-wizard" :isStickNumbersToLeftEdge="true" isWithScrollReset :titles="stepTitlesAndSubtitles" ref="domRefStepWizard">
      <template #[index]="{ goToNextSlide }" v-for="(step, index) in itinerary.steps" :key="index">
        <!-- Slide container -->
        <div class="slide-container">
          <PlaceForm
            class="slide-container__place-details-form"
            :step="step"
            @update:step="
              () => {
                throttledSaveItinerary();
              }
            "
            ref="domRefActivePlaceForm"
          />

          <div class="slide-container__divider"></div>

          <div class="slide-container__whats-left-counter">
            {{ genXPlacesToGoText(index) }}
          </div>

          <!--<div class="slide-container__whats-left-counter">-->
          <!--  <LinkWithIcon isDottedUnderline iconInset="auto auto auto 3px">-->
          <!--    <template #icon><IconEmbedded name="plus_2-5" :size="18" /></template>-->
          <!--    <span>Add another Place</span>-->
          <!--  </LinkWithIcon>-->
          <!--</div>-->

          <SrpButton
            class="slide-container__next-button"
            @click="
              () => {
                // Always save on next (just in case)
                throttledSaveItinerary();
                if (domRefActivePlaceForm.validate()) {
                  goToNextSlide();
                }
              }
            "
            color="orange"
            :isDisabled="isSavingItinerary"
          >
            <template #icon>
              <LoadingIcon v-if="isSavingItinerary" :size="18" />
              <IconEmbedded v-else name="arrow-bottom_3-5" :size="18" />
            </template>
            Next
          </SrpButton>
        </div>
        <!-- / Slide container -->
      </template>

      <template #[itinerary.steps.length]="{ goToPrevSlide }">
        <!-- Slide container -->
        <div class="slide-container">
          <SummaryForm class="slide-container__place-details-form" :itinerary="itinerary" @update:itinerary="throttledSaveItinerary" ref="domRefSummaryForm" />

          <div class="slide-container__divider"></div>

          <SrpButton
            class="slide-container__next-button"
            @click="
              async () => {
                if (domRefSummaryForm.validate()) {
                  isSavingItinerary = true;
                  setMapFieldsOnAdventure(itinerary, () => {
                    // Save in the callback to ensure the map calls have completed
                    saveItinerary(true);
                  });
                }
              }
            "
            color="orange"
            :isDisabled="isSavingItinerary"
          >
            <template #icon>
              <LoadingIcon v-if="isSavingItinerary" :size="18" />
              <IconEmbedded v-else name="check_3-5" :size="18" />
            </template>
            Done
          </SrpButton>
        </div>
        <!-- / Slide container -->
      </template>
    </StepWizard>
  </div>
</template>

<script setup lang="ts">
import axios from "axios";
import { computed, ref, watch, onMounted, onUnmounted } from "vue";
import ItineraryRepo from "@repos/ItineraryRepo";
import lodashThrottle from "lodash-es/throttle";
import setMapFieldsOnAdventure from "@logic/CmsAdventureHelper";
import { useRoute } from "vue-router";
import router from "@/router";
import { getGlobalRemoteLogger } from "@/helpers/RemoteLogger";
import { MetricSender } from "@/helpers/MetricSender";
import { getOrdinalSuffix } from "@logic/NumberFormatting";

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import Loader from "@components/Loader/Loader.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import PlaceForm from "./PlaceForm.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import StepWizard from "@components/ui/StepWizard.vue";
import SummaryForm from "./SummaryForm.vue";

// Types
import { Itinerary } from "@contracts/itinerary";
import { StepWizardTitleAndSubtitle } from "@components/ui/StepWizard.vue";
import LoadingIcon from "@components/ui/LoadingIcon.vue";

// Stores
import { useUserProfileStore } from "@/stores/userProfileStore";

const userProfileStore = useUserProfileStore();

const route = useRoute();

// Global variables & injections
const apiBaseUri = import.meta.env.VITE_API_URL;

// DOM ref for StepWizard =====================================================
const domRefStepWizard = ref<any>(null);

// Step titles ================================================================
const stepTitlesAndSubtitles = computed<Array<StepWizardTitleAndSubtitle>>(() => {
  const firstSlideText = domRefStepWizard.value?.activeSlide === 0 ? "Highlight your work!" : "1st Location";

  const allSlideTitles = [
    {
      title: firstSlideText,
      subtitle: "Show destinations your personality and creativity",
    },
    { title: "Last Step!" },
  ];

  itinerary.value.steps.forEach((step, index) => {
    if (index === 0) {
      return;
    } else {
      let title = `${index + 1}${getOrdinalSuffix(index + 1)} Location`;
      allSlideTitles.splice(allSlideTitles.length - 1, 0, { title });
    }
  });

  return allSlideTitles;
});

// Get the Itinerary ==========================================================
const itinerary = ref<Itinerary | null>(null);
const isLoadingItinerary = ref<boolean>(true);

onMounted(async () => {
  await getTheItinerary();

  await userProfileStore.impersonateProfile(itinerary.value?.sherpaId);
});
async function getTheItinerary() {
  const uri = `${apiBaseUri}/cms/${route.params.creatorId}/onboarding-itinerary`;

  isLoadingItinerary.value = true;

  const { data } = await axios.get<Itinerary>(uri);
  // Needed for the cover photo "default if not selected" to work. Should probably rework this.
  ItineraryRepo.prepItineraryAfterLoad(data);
  itinerary.value = data;

  isLoadingItinerary.value = false;
}

// Save the Itinerary =========================================================
const isSavingItinerary = ref<boolean>(false);
async function saveItinerary(shouldPublish = false) {
  isSavingItinerary.value = true;

  getGlobalRemoteLogger().info(`Onboarding-saveItinerary: ${itinerary.value?.id} publish=${shouldPublish}, creatorId=${userProfileStore.getViewingUserProfile?.sherpaId}`);
  const saveResult = await ItineraryRepo.saveItinerary(itinerary.value, shouldPublish);
  // Note: Manually setting the eTag so we don't get a ton of concurrency warnings.
  itinerary.value._etag = saveResult.itinerary._etag;

  MetricSender.setPropertyOnSession("OnboardingState", "AdventureSaved");

  if (shouldPublish) {
    if (saveResult.justBecamePaidEligible) {
      userProfileStore.getViewingUserProfile.collabPreferences.isCollabEligible = true;
    }
    router.push({
      name: "CreatorDashboard",
      params: { creatorId: userProfileStore.getViewingUserProfile.sherpaId },
      query: { justBecamePaidEligible: saveResult.justBecamePaidEligible.toString() },
    });
  }

  isSavingItinerary.value = false;
}

const throttledSaveItinerary = lodashThrottle(saveItinerary, 3000, { leading: false });
// when the component is unmounted, flush any remaining calls
onUnmounted(() => throttledSaveItinerary.flush());

// Generate "X places to go" text =============================================
function genXPlacesToGoText(index: number): string {
  const currentStepNumber = index + 1;
  const numberOfSteps = itinerary.value.steps.length;

  if (currentStepNumber === numberOfSteps) return "Amost done";
  if (currentStepNumber === numberOfSteps - 1) return "1 location to go";
  if (currentStepNumber < numberOfSteps - 1) return `${numberOfSteps - currentStepNumber} locations to go`;
}

// DOM ref form the active placeForm and the Summary ==========================
const domRefActivePlaceForm = ref<any>(null);
const domRefSummaryForm = ref<any>(null);
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Slide container ============================================================
.slide-container {
  color: rgba(91, 91, 91, 1);
  font: 14px/18px sans-serif;

  &__place-details-form {
    max-width: 700px;
    margin-bottom: 25px;
  }

  &__divider {
    max-width: 700px;
    margin-bottom: 20px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__whats-left-counter {
    width: 100%;
    max-width: 700px;
    margin-bottom: 20px;
    text-align: right;
  }

  &__next-button {
  }
}

// Onboarding adventure page ==================================================
.onboarding-adventure-page {
  border-top: 1px rgba(220, 220, 220, 1) solid;

  &__step-wizard {
    max-width: 700px;
    margin: -1px auto 0;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .onboarding-adventure-page {
    width: calc(100dvw);
    padding-right: 10px;
    margin: 0 -1rem;
    box-sizing: border-box;
  }
}
</style>
