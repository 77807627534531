<template>
  <li class="new-destination-section">
    <EventTypeTag class="new-destination-section__type-tag" color="blue">New Destination</EventTypeTag>

    <RouterLink class="new-destination-section__link-overlay" :to="{ name: 'Pages', params: { pageId: feedItem.id } }" target="_blank" />

    <div class="new-destination-section__content">
      <div class="new-destination-section__logo-wrap">
        <img class="new-destination-section__logo" :src="`${contentBaseUri}/cms/images/orgs/${feedItem.logo}`" :alt="feedItem.name" />
      </div>

      <div class="new-destination-section__name-n-location">
        <h5 class="global-h5 new-destination-section__name">
          {{ feedItem.name }}
        </h5>

        <!-- Location snippet -->
        <div class="location-snippet new-destination-section__location-snippet">
          <IconEmbedded class="location-snippet__icon" name="map-pin_2" />
          <div class="location-snippet__text">
            {{ getStateNameFromAbbrev(feedItem.state) }}
          </div>
        </div>
        <!-- / Location snippet -->
      </div>
    </div>
  </li>
</template>

<script setup lang="ts">
import getStateNameFromAbbrev from "@helpers/GetStateName";

// Types
import { NewDestinationFeedItem } from "@components/ActivityFeed/types";
import EventTypeTag from "@components/ActivityFeed/EventTypeTag.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

// Global variables
const contentBaseUri = globalThis.Bootstrap.Config.contentCdnBaseUri;

const props = withDefaults(
  defineProps<{
    feedItem: NewDestinationFeedItem;
  }>(),
  {
    feedItem: () => ({}) as NewDestinationFeedItem,
  }
);
</script>

<style scoped lang="scss">
@import "./commonStyle.scss";

// New destination section ====================================================
.new-destination-section {
  @include eventContainer;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 0;

  &__link-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: 3;
  }

  &__content {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  &__logo-wrap {
    width: 72px;
    min-width: 72px;
    height: 72px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-content: center;

    &--rounded {
      border-radius: 200px;
      position: relative;
      overflow: hidden;
    }
  }

  &__logo {
    width: 100%;
    height: 100%;
    object-fit: contain;

    &--object-fit-cover {
      object-fit: cover;
    }
  }

  &__name-n-location {
    flex-grow: 1;
  }

  &__name {
    max-width: calc(100% - 110px);
    margin-bottom: 4px;
    text-decoration: none;
  }

  &:hover &__name {
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.2);
    text-underline-offset: 2px;
    text-decoration-thickness: 1px;
    text-decoration-style: solid;
  }

  &__location-snippet {
    margin-left: -4px;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .new-destination-section {
    &__name {
      max-width: 100%;
    }
  }
}
</style>
