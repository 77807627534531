<template>
  <div class="place-details-form">
    <!-- Form element snippet -->
    <div class="form-element-snippet place-details-form__form-element-snippet">
      <div class="form-element-snippet__header">
        <h5 class="global-h5 form-element-snippet__title">Give it a Title!</h5>
      </div>

      <TextInputWithAISuggestions
        ref="aiTitlesInput"
        class="form-element-snippet__form-element-itself"
        :suggestionsEndpointUri="`${apiBaseUri}/cms/${props.itinerary.sherpaId}/adventures/${props.itinerary.id}/generate-title`"
        v-model:value="props.itinerary.title"
        @update:value="emit('update:itinerary')"
        style="max-width: 400px"
        :isError="Boolean(formErrors.title)"
      />

      <div v-if="formErrors.title" class="global-error-string form-element-snippet__form-element-error">
        {{ formErrors.title }}
      </div>
    </div>
    <!-- / Form element snippet -->

    <div class="place-details-form__divider"></div>

    <!-- Form element snippet -->
    <div class="form-element-snippet place-details-form__form-element-snippet">
      <!-- Upload Photo Form container -->
      <div
        class="upload-photo-form-container form-element-snippet__upload-photo-form-container"
        :style="{
          marginBottom: props.itinerary.tileImageLocation ? '50px' : '30px',
        }"
      >
        <div v-if="!props.itinerary.tileImageLocation" class="upload-photo-form-container__overlay upload-photo-form-container__overlay--full-height" style="margin-top: 10px">
          <IconEmbedded name="image_2" :size="26" color="rgba(0, 0, 0, 0.4)" style="margin-bottom: 5px" />
          <b style="margin-bottom: 5px">Cover Photo</b>
          <SrpButton color="gray" fill="outlined" size="small">Choose a Photo</SrpButton>
        </div>

        <CoverPhotoForm
          class="upload-photo-form-container__form-itself"
          :style="{ maxHeight: props.itinerary.tileImageLocation ? '' : '200px' }"
          :tileImageFileName="props.itinerary.tileImageLocation"
          :isError="Boolean(formErrors.tileImageLocation)"
          :images="allItineraryImages"
          :collabImagesUsed="[]"
          :collabImagesUnused="[]"
          :isCollab="false"
          @croppedCoverPhotoSelected="
            (croppedImage, uncroppedTileImageLocation) => {
              onCroppedCoverPhotoSelected(croppedImage, uncroppedTileImageLocation);
              emit('update:itinerary');
            }
          "
          @imageUploadedFromCoverPhotoForm="
            uploadedImage => {
              props.itinerary.unusedPhotos.push(uploadedImage.serverId);
              emit('update:itinerary');
            }
          "
          @removeMediaFile="event => {}"
          @fixTheFieldError="args => {}"
        />
      </div>
      <!-- / Upload Photo Form container -->

      <div v-if="formErrors.tileImageLocation" class="global-error-string form-element-snippet__form-element-error">
        {{ formErrors.tileImageLocation }}
      </div>
    </div>
    <!-- / Form element snippet -->

    <div class="place-details-form__divider"></div>

    <!-- Form element snippet -->
    <div class="form-element-snippet place-details-form__form-element-snippet">
      <div class="form-element-snippet__header">
        <h5 class="global-h5 form-element-snippet__title">Summarize the experience</h5>
      </div>

      <textarea
        :class="{
          'global-textarea': true,
          'global-textarea--error': formErrors.isDescriptionInvalid,
          'form-element-snippet__form-element-itself': true,
        }"
        style="height: 95px; min-height: 95px"
        v-model="props.itinerary.whatToExpectText"
        @input="emit('update:itinerary')"
      ></textarea>

      <div
        :class="{
          'global-error-string': formErrors.isDescriptionInvalid,
          'form-element-snippet__form-element-error': formErrors.isDescriptionInvalid,
        }"
        style="text-align: right"
      >
        {{ getDetailsLengthMessage(props.itinerary.whatToExpectText) }}
      </div>
    </div>
    <!-- / Form element snippet -->
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from "vue";
import { getDetailsLengthMessage, isDescriptionInvalid } from "./OnboardingValidation";

// Components
import TextInputWithAISuggestions from "@components/TextInputWithAISuggestions.vue";
import CoverPhotoForm from "@views/CMS/CoverPhotoForm.vue";

// Types
import { Itinerary } from "@contracts/itinerary";
import { UploadedImage } from "@contracts/uploadedImage";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import SrpButton from "@components/ui/SrpButton.vue";

// Global variables & injections
const apiBaseUri = import.meta.env.VITE_API_URL;

const props = withDefaults(
  defineProps<{
    itinerary: Itinerary | null;
  }>(),
  {
    itinerary: null,
  }
);

const emit = defineEmits<{
  (e: "update:itinerary"): void;
}>();

// Get the AI titles input ref ==============================================
const aiTitlesInput = ref();
onMounted(async () => {
  // Auto-open the AI titles dropdown if the title is empty.
  if (!props.itinerary?.title) {
    await aiTitlesInput.value?.autoGenerateAiTitlesClick();
  }
});

// All the Itinerary images ===================================================
const allItineraryImages = computed<Array<UploadedImage>>(() => {
  return props.itinerary?.steps
    ?.map(s => s.images)
    .flat()
    .map(i => ({ serverId: i }));
});

function onCroppedCoverPhotoSelected(croppedImage: UploadedImage, uncroppedTileImageLocation: string) {
  // when a image is selected and cropped to be used as a cover photo.
  // this images are NOT added to the unusedPhotos list
  props.itinerary.tileImageLocation = croppedImage.serverId;
  props.itinerary.uncroppedTileImageLocation = uncroppedTileImageLocation;

  emit("update:itinerary");
}

// Validation =================================================================
const formErrors = ref<{ title: string; tileImageLocation: string; isDescriptionInvalid: boolean }>({
  title: "",
  tileImageLocation: "",
  isDescriptionInvalid: false,
});

const MinDescriptionLength = 100; // Also in PlaceForm
function validate(): boolean {
  formErrors.value.title = props.itinerary.title ? "" : "Please add a Title";
  formErrors.value.tileImageLocation = props.itinerary.tileImageLocation ? "" : "Please select a Cover Photo";
  formErrors.value.isDescriptionInvalid = isDescriptionInvalid(props.itinerary.whatToExpectText);

  return Object.values(formErrors.value).filter(v => Boolean(v)).length === 0;
}

watch(
  () => props.itinerary.title,
  () => (formErrors.value.title = "")
);
watch(
  () => props.itinerary.tileImageLocation,
  () => (formErrors.value.tileImageLocation = "")
);
watch(
  () => props.itinerary.whatToExpectText,
  () => (formErrors.value.isDescriptionInvalid = false)
);

// Define expose ==============================================================
defineExpose({
  validate,
});
</script>

<style scoped lang="scss">
@import "./PlaceAndSummaryForms.scss";
</style>
