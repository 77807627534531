<template>
  <div class="ui form" style="width: 100%; margin: 0 !important">
    <div class="fields" style="margin: 0 !important; justify-content: flex-end; flex-wrap: wrap">
      <!--Stops buttons-->
      <div class="flex" style="justify-content: center; float: right; align-self: flex-end; flex-wrap: wrap" v-if="!isSummary">
        <!--Last Stop (note: We show them a modal on Step 2 Next click to ask if they want another stop or to go to the Summary)-->
        <template v-if="isEndStop && (index > 1 || hasClosedShowNextOrSummaryModal)">
          <SrpButton @click="save" color="gray" style="margin: 12px 6px 0 6px">Save Draft</SrpButton>
          <SrpButton v-if="index < maxStopCount - 1" @click="addStop" style="margin: 12px 6px 0 6px">Add Stop</SrpButton>
          <SrpButton @click="goToSummary" style="margin-top: 12px">Go to Summary</SrpButton>
        </template>
        <!--Previous stops-->
        <template v-else>
          <SrpButton @click="save" color="gray" style="margin: 0 6px 0 6px">Save Draft</SrpButton>
          <SrpButton @click="goToTab">Next</SrpButton>
        </template>
      </div>

      <!--Summary Buttons-->
      <div class="flex" style="float: right; align-self: flex-end; flex-wrap: wrap" v-else>
        <SrpButton @click="preview" color="gray" style="margin: 0 6px 0 6px">Preview</SrpButton>
        <SrpButton @click="save" color="gray" style="margin: 0 6px 0 6px">Save Draft</SrpButton>
        <SrpButton @click="publish">Publish</SrpButton>
      </div>
    </div>

    <SrpModal v-model:isVisible="showNextOrSummaryModal" className="checkModal">
      <template #header>
        <h2 class="global-h2">Have more stops to add?</h2>
      </template>
      <template #footer>
        <SrpButton color="gray" fill="outlined" style="margin: 0 6px 0 6px" @click="addStop">Add Another Stop</SrpButton>
        <SrpButton @click="goToSummary">That's it, take me to the Summary</SrpButton>
      </template>
    </SrpModal>
  </div>
</template>

<script>
import SrpModal from "@components/ui/SrpModal.vue";
import SrpButton from "@components/ui/SrpButton.vue";

export default {
  name: "ButtonGroup",
  components: { SrpButton, SrpModal },

  props: ["index", "isEndStop", "isSummary", "maxStopCount"],

  emits: ["goToTab", "goToSummary", "addStop", "save", "preview"],

  data() {
    return {
      showNextOrSummaryModal: false,
      // Used to only bother them with the modal once per itinerary session
      hasClosedShowNextOrSummaryModal: false,
    };
  },

  methods: {
    goToTab() {
      if (this.isEndStop && !this.hasClosedShowNextOrSummaryModal) {
        this.showNextOrSummaryModal = true;
      } else {
        this.$emit("goToTab", this.index + 1);
      }
    },
    goToSummary() {
      this.showNextOrSummaryModal = false;
      this.hasClosedShowNextOrSummaryModal = true;
      this.$emit("goToSummary");
    },
    addStop() {
      this.showNextOrSummaryModal = false;
      this.hasClosedShowNextOrSummaryModal = true;
      this.$emit("addStop");
    },
    save() {
      this.$emit("save", false);
    },
    publish() {
      this.$emit("save", true);
    },
    preview() {
      this.$emit("preview");
    },
  },
};
</script>

<style>
.checkModal .modal-header.header {
  background: #058587;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em !important;
  color: white;
}

.checkModal .ui.modal.active {
  max-width: 520px !important;
}

.marginLeft {
  margin-left: 1.5em !important;
}

.summaryBtn {
  background: transparent;
  color: #2c5566;
  font-weight: bold;
  cursor: pointer;
  margin: 1em 0 0.75em 0 !important;
}
</style>
