<template>
  <div v-if="isVisible" class="spoiler-section">
    <SrpDetailsSummary class="spoiler-section__details-summary" v-model:is-opened="isOpened" :transition-duration="150">
      <template #heading="{ isOpened }">
        <div
          :class="{
            'global-h3': true,
            'spoiler-section__summary': true,
            'spoiler-section__summary--with-bottom-padding': !isOpened,
          }"
        >
          <span><span>Creator Visit Feedback</span></span>
        </div>
      </template>
      <template #details>
        <div class="spoiler-section__content">
          <div class="feedback">
            <div v-if="showRequired" style="margin-bottom: 30px; color: red; font-weight: bold">Ratings Required</div>

            <label class="global-h4" style="margin-bottom: 7px !important">How were the photos?</label>
            <div class="ui huge star rating rating-section" style="margin: 0 0 22px 0">
              <i v-for="index in 5" :key="index" @click="feedback.photoRating = index" :class="`star icon ${index <= feedback.photoRating ? 'active' : ''}`"></i>
            </div>

            <label class="global-h4" style="margin-bottom: 7px !important">How were the stop descriptions?</label>
            <div class="ui huge star rating rating-section" style="margin: 0 0 22px 0">
              <i v-for="index in 5" :key="index" @click="feedback.writingRating = index" :class="`star icon ${index <= feedback.writingRating ? 'active' : ''}`"></i>
            </div>

            <label class="global-h4" style="margin-bottom: 7px !important">How was the creator to work with?</label>
            <div style="margin: 0 0 22px 0; display: flex; align-items: center">
              <div class="ui huge star rating rating-section" style="margin: 0">
                <i v-for="index in 5" :key="index" @click="feedback.creatorRating = index" :class="`star icon ${index <= feedback.creatorRating ? 'active' : ''}`"></i>
              </div>
              <div style="display: inline; margin-left: 10px">(1 = hard, 5 = great)</div>
            </div>

            <label class="global-h4" style="margin-bottom: 7px !important">Overall, how were the adventures?</label>
            <div class="ui huge star rating rating-section" style="margin: 0 0 22px 0">
              <i v-for="index in 5" :key="index" @click="feedback.overallRating = index" :class="`star icon ${index <= feedback.overallRating ? 'active' : ''}`"></i>
            </div>

            <label class="global-h4" style="margin-bottom: 7px !important">Do you have any feedback for the creator? <span style="font-weight: normal">(we'll share it with them)</span></label>
            <div class="ui form field" style="padding: 0; box-shadow: none">
              <textarea maxlength="2000" v-model="feedback.feedbackForCreator" class="feedback-text" placeholder="" style="margin-bottom: 20px"></textarea>
            </div>

            <label class="global-h4" style="margin-bottom: 7px !important">Do you have any feedback for Shrpa?</label>
            <div class="ui form field" style="padding: 0; box-shadow: none">
              <textarea maxlength="2000" v-model="feedback.feedbackForShrpa" class="feedback-text" placeholder="" style="margin-bottom: 20px"></textarea>
            </div>

            <template v-if="!feedback.submittedDate">
              <h4 v-if="isSaving" style="display: inline">&nbsp;Saving...</h4>
            </template>

            <template v-else>
              <div style="margin: 20px 0; border-bottom: 1px rgba(0, 0, 0, 0.1) solid"></div>
              <NoteWithIcon color="green">
                <template #icon><IconEmbedded name="info-simple_4" /></template>
                <template #text>
                  <h4 class="global-h4">Feedback Sent!</h4>
                </template>
              </NoteWithIcon>
            </template>
          </div>

          <!-- Manage buttons panel -->
          <div
            v-if="!feedback.submittedDate"
            :class="{
              'manage-buttons-panel': true,
              'manage-buttons-panel--stuck': isButtonsPanelStuck,
              'spoiler-section__manage-buttons-panel': true,
              'spoiler-section__manage-buttons-panel--stuck': isButtonsPanelStuck,
            }"
          >
            <div class="manage-buttons-panel__left-side"></div>

            <div class="manage-buttons-panel__right-side">
              <SrpButton :size="screenSize === 'mobile' ? 'small' : 'normal'" class="manage-buttons-panel__button" is-bigger-side-paddings @click="save" :is-disabled="isSaving"
                >Send Feedback</SrpButton
              >
            </div>
          </div>
          <!-- / Manage buttons panel -->

          <div ref="intersectionDetectionTarget"></div>
        </div>
      </template>
    </SrpDetailsSummary>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import axios from "axios";

// Types
import { CollabFeedback } from "@contracts/collab";
import { ScreenSize } from "@contracts/screenSize";

// Components
import SrpButton from "@components/ui/SrpButton.vue";
import SrpDetailsSummary from "@components/ui/SrpDetailsSummary.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export default defineComponent({
  name: "CollabFeedback",

  components: { IconEmbedded, SrpButton, SrpDetailsSummary, NoteWithIcon },

  props: {
    communityId: { type: String, required: true },
    collabInputId: { type: String, required: true },
    feedback: { type: Object as () => CollabFeedback, required: true },
    collabIndex: { type: Number, required: true },
  },

  emits: ["updated"],

  data() {
    return {
      globalLog: inject("globalLog") as any,
      screenSize: inject("screenSize") as ScreenSize,

      isSaving: false,
      showRequired: false,

      isOpened: true,

      intersectionObserver: null,
      isButtonsPanelStuck: false,
    };
  },

  computed: {
    isVisible(): boolean {
      if (this.feedback) return true;
      else return false;
    },
  },

  watch: {
    // Resets the state since they changed collabs
    collabIndex: function (newVal, oldVal) {
      this.setDefaultIsOpened();
      this.intersectionObserver.disconnect();
      this.$nextTick(this.setIntersectionObservers);
    },
    isVisible() {
      this.intersectionObserver.disconnect();
      this.$nextTick(this.setIntersectionObservers);
    },
    feedback: {
      handler(newVal, oldVal) {
        if (oldVal != null) {
          // this.globalLog.debug("Emitting update for Collab feedback");
          this.$emit("updated", this.feedback, this.collabIndex);
        }
      },
      deep: true,
    },
  },

  async mounted() {
    this.setDefaultIsOpened();
    await this.setIntersectionObservers();
  },

  beforeUnmount() {
    this.intersectionObserver.disconnect();
  },

  methods: {
    async setIntersectionObservers(): Promise<void> {
      // Detect whether button panels are stuck (is "sticky" position applied) to move it a bit higher to the top to avoid overlapping with mobile bottom navigation panel
      this.intersectionObserver = new IntersectionObserver(
        ([e]) => (this.isButtonsPanelStuck = e.intersectionRatio === 0 && (this.$refs.intersectionDetectionTarget as HTMLDivElement)?.getBoundingClientRect().top > 0),
        { threshold: 0.1 }
      );
      await this.$nextTick();
      if (this.$refs.intersectionDetectionTarget) {
        this.intersectionObserver.observe(this.$refs.intersectionDetectionTarget as Element);
      }
    },
    async save() {
      if (!this.validate()) {
        return;
      }
      this.isSaving = true;
      const config = { headers: { "Content-Type": "application/json" } };
      let uri = `${import.meta.env.VITE_API_URL}/collabs/${this.communityId}/inputs/${this.collabInputId}/feedback`;
      const response = await axios.put(uri, JSON.stringify(this.feedback), config);
      this.feedback.submittedDate = response.data;
      this.isSaving = false;
    },
    setDefaultIsOpened() {
      this.isOpened = !(this.feedback?.submittedDate && this.feedback?.submittedDate.length > 0);
    },
    validate(): boolean {
      if (this.feedback.photoRating === 0 || this.feedback.writingRating === 0 || this.feedback.creatorRating === 0 || this.feedback.overallRating === 0) {
        this.showRequired = true;
        return false;
      }
      // All good
      this.showRequired = false;
      return true;
    },
  },
});
</script>

<style scoped lang="scss">
@import "./AllCollabsV2.scss";

.ui.form {
  padding: 20px;
  box-shadow: 0 0 5px 0 #ccc;

  .feedback {
    margin-top: 25px;

    .cms-section-title {
      margin: 0px 0 5px 0 !important;
    }
    .rating-section {
      margin: 5px 0 15px 0 !important;
    }
    .feedback-text {
      height: 80px;
      min-height: 50px;
      margin-bottom: 10px;
    }
  }
}
@media screen and (max-width: 420px) {
  .ui.form {
    padding: 15px;
    h1 {
      font-size: 18px !important;
    }
    .field > label.cms-section-title {
      font-size: 16px !important;
      margin: 0 !important;
    }
  }
}

.large-accordion-dropdown {
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
  position: relative !important;
  z-index: 5;
  cursor: pointer;
  user-select: none;

  &:hover {
    opacity: 0.8;
  }

  &::before {
    content: "";
    width: calc(100% + 17px);
    height: calc(100% + 17px);
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
  }

  h2 {
    height: 30px;
    margin: 0;
    border-bottom: 1px transparent solid;
    display: inline-block;
    transition:
      border-color 0.12s ease-in-out,
      opacity 0.12s ease-in-out;
  }

  &:hover h2 {
    border-color: rgba(0, 0, 0, 0.2);
  }
}
</style>
