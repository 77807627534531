<template>
  <SrpModal :isVisible="props.isVisible" @close="emit('update:isVisible', false)">
    <template #content>
      <!-- Spread the word modal -->
      <div class="spread-the-word-modal">
        <!-- Header -->
        <div class="header spread-the-word-modal__header">
          <h1 class="global-h1 header__title">Spread the Word!<!--<IconEmbedded name="ai_2" color="darkslategrey" :size="26" />--></h1>
          <div class="header__subtitle">Here's a starting point for sharing upload links with locals.</div>
        </div>
        <!-- / Header -->

        <div class="spread-the-word-modal__divider"></div>

        <ButtonLikeTabs
          class="spread-the-word-modal__tabs"
          :tabNames="tabNames"
          @switchTab="
            async tabName => {
              if (tabName === 'QR Code') {
                await loadQRCode();
              }
            }
          "
        >
          <template #1TabIcon>
            <img src="/images/otherIcons/like.svg" style="width: 19px; height: 19px" alt="like" />
          </template>
          <template #2TabIcon>
            <img src="/images/otherIcons/email.svg" style="width: 19px; height: 19px" alt="email" />
          </template>
          <template #3TabIcon>
            <img src="/images/otherIcons/globe.svg" style="width: 19px; height: 19px" alt="globe" />
          </template>
          <template #4TabIcon>
            <img src="/images/otherIcons/qrcode.svg" style="width: 19px; height: 19px" alt="qrcode" />
          </template>

          <!-- ================================================================= -->
          <!-- ================================================================= -->
          <!-- ================================================================= -->
          <template #1TabContent>
            <!-- Example text snippet -->
            <div class="example-text-snippet">
              <div class="example-text-snippet__text-wrapper">
                <!--<h5 class="global-h5 example-text-snippet__title">Suggested Post</h5>-->
                <div class="example-text-snippet__example-text">
                  {{ campaignTemplate.socialMediaPostText.replace(linkReplacementPlaceholder, uploadLink) }}
                </div>
              </div>

              <CopyToClipboardButton class="example-text-snippet__copy-btn" :textToCopy="campaignTemplate.socialMediaPostText.replace(linkReplacementPlaceholder, uploadLink)" color="orange" />
            </div>
            <!-- / Example text snippet -->
          </template>

          <!-- ================================================================= -->
          <!-- ================================================================= -->
          <!-- ================================================================= -->
          <template #2TabContent>
            <!-- Example text snippet -->
            <div class="example-text-snippet" style="margin-bottom: 20px">
              <div class="example-text-snippet__text-wrapper">
                <!--<h5 class="global-h5 example-text-snippet__title">Suggested Email</h5>-->
                <div class="example-text-snippet__example-text example-text-snippet__example-text--big-font">
                  {{ campaignTemplate.emailSubject.replace(linkReplacementPlaceholder, uploadLink) }}
                </div>
              </div>

              <CopyToClipboardButton
                class="example-text-snippet__copy-btn"
                style="margin-top: 0"
                :textToCopy="campaignTemplate.emailSubject.replace(linkReplacementPlaceholder, uploadLink)"
                color="orange"
              />
            </div>
            <!-- / Example text snippet -->

            <div class="spread-the-word-modal__divider spread-the-word-modal__divider--dashed" style="margin-bottom: 15px"></div>

            <!-- Example text snippet -->
            <div class="example-text-snippet">
              <div class="example-text-snippet__text-wrapper">
                <!--<h5 class="global-h5 example-text-snippet__title">Email text</h5>-->
                <div class="example-text-snippet__example-text">
                  {{ campaignTemplate.emailText.replace(linkReplacementPlaceholder, uploadLink) }}
                </div>
              </div>

              <CopyToClipboardButton class="example-text-snippet__copy-btn" :textToCopy="campaignTemplate.emailText.replace(linkReplacementPlaceholder, uploadLink)" color="orange" />
            </div>
            <!-- / Example text snippet -->
          </template>

          <!-- ================================================================= -->
          <!-- ================================================================= -->
          <!-- ================================================================= -->
          <template #3TabContent>
            <!-- Example text snippet -->
            <div class="example-text-snippet">
              <div class="example-text-snippet__text-wrapper">
                <!--<h5 class="global-h5 example-text-snippet__title">Suggested Text</h5>-->
                <div class="example-text-snippet__example-text">
                  {{ campaignTemplate.websiteText.replace(linkReplacementPlaceholder, uploadLink) }}
                </div>
              </div>

              <CopyToClipboardButton class="example-text-snippet__copy-btn" :textToCopy="campaignTemplate.websiteText.replace(linkReplacementPlaceholder, uploadLink)" color="orange" />
            </div>
            <!-- / Example text snippet -->
          </template>

          <!-- ================================================================= -->
          <!-- ================================================================= -->
          <!-- ================================================================= -->
          <template #4TabContent>
            <div>
              <Loader v-if="isLoadingQRCode" />

              <div v-else>
                <img style="max-width: 100px; margin-bottom: 8px; border: 1px #bbb solid; border-radius: 6px" :src="`data:image/png;base64, ${qrCodeImageData}`" alt="qr code" />
                <br />
                <a style="margin-top: 17px" class="ui primary mini button" :download="`${pageId}-${campaign?.uriKey}-qrcode.png`" :href="qrCodeBinaryData"> <i class="download icon"></i>Download </a>
              </div>
            </div>
          </template>
        </ButtonLikeTabs>
      </div>
      <!-- / Spread the word modal -->
    </template>

    <template #footer>
      <SrpButton @click="emit('update:isVisible', false)" fill="outlined">Finish</SrpButton>
    </template>
  </SrpModal>
</template>

<script setup lang="ts">
import axios from "axios";
import { computed, ref } from "vue";
import uploadCampaignTemplatesRaw from "@views/Community/UserUploadCampaigns/uploadCampaignTemplates.json";
import { Base64ToBinary } from "@logic/FileConversions";

// Components
import CopyToClipboardButton from "@components/CopyToClipboardButton.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import ButtonLikeTabs from "@components/ui/ButtonLikeTabs.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

// Types
import { UploadCampaignTemplateTheme, UploadCampaignTemplate } from "@contracts/uploadCampaignTemplates";
import { UserContentUploadCampaignData } from "@contracts/userContentUploadCampaignData";
import Loader from "@components/Loader/Loader.vue";

const props = withDefaults(
  defineProps<{
    isVisible: boolean;
    pageId: string | null;
    campaign: UserContentUploadCampaignData | null;
  }>(),
  {
    isVisible: false,
    pageId: null,
    campaign: null,
  }
);

const emit = defineEmits<{
  (e: "update:isVisible", value: boolean): void;
}>();

// Set type for the uploadCampaignTemplates ===================================
const uploadCampaignTemplates: Record<UploadCampaignTemplateTheme, UploadCampaignTemplate> = uploadCampaignTemplatesRaw;

// Campaign Template ==========================================================
const campaignTemplate = computed<UploadCampaignTemplate>(() => {
  return props.campaign?.themeId ? uploadCampaignTemplates[props.campaign?.themeId] : uploadCampaignTemplates.general;
});

// Tab names ==================================================================
const tabNames = ["Social Media", "Email", "Website", "QR Code"];

// Link the replacement placeholder ===========================================
const linkReplacementPlaceholder = "<link>";

// Generate an upload link ====================================================
const uploadLink = computed<string>(() => {
  return `${window.location.protocol}//${window.location.host}/upload/${props.pageId}/${props.campaign?.uriKey || props.campaign?.campaignId}`;
});

// Load the QR Code ===========================================================
const isLoadingQRCode = ref<boolean>(false);
const qrCodeImageData = ref<string>("");
const qrCodeBinaryData = ref<string | null>(null);

async function loadQRCode() {
  let uri = `${import.meta.env.VITE_API_URL}/qrcodes/${props.pageId}/community-upload`;
  const urlSearchParams = new URLSearchParams({ uri: uploadLink.value });
  uri = uri + "?" + urlSearchParams.toString();

  isLoadingQRCode.value = true;
  const { data } = await axios.get(uri);
  isLoadingQRCode.value = false;

  qrCodeImageData.value = data;

  // Mac needs a binary png format for downloads
  const binaryData = Base64ToBinary(qrCodeImageData.value);
  const blob = new Blob([binaryData], { type: "image/png" });
  qrCodeBinaryData.value = URL.createObjectURL(blob);
}
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";

// Header =====================================================================
.header {
  &__title {
    margin-bottom: 4px;
  }

  &__subtitle {
  }
}

// Example text snippet =======================================================
.example-text-snippet {
  display: flex;
  white-space: pre-wrap;

  &__text-wrapper {
    flex-grow: 1;
    margin-right: 30px;
  }

  &__title {
    margin-bottom: 8px;
    color: rgba(91, 91, 91, 1);
    font-weight: 500;
  }

  &__example-text {
    margin-top: 7px;
    padding-left: 20px;
    color: rgba(0, 0, 0, 1);
    font: 17px/23px sans-serif;
    word-break: break-word;

    &--big-font {
      font:
        700 26px/30px "Quicksand",
        sans-serif;
    }
  }

  &__copy-btn {
    margin-top: 15px;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .example-text-snippet {
    flex-direction: column;
    align-items: flex-start;
  }
}

// Spread the word modal ======================================================
.spread-the-word-modal {
  padding-bottom: 17px;
  color: rgba(91, 91, 91, 1);
  font: 14px/18px sans-serif;
  min-height: 400px;

  &__header {
    margin-bottom: 25px;
  }

  &__divider {
    margin-bottom: 28px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;

    &--dashed {
      border-bottom-color: rgba(0, 0, 0, 0.15);
      border-bottom-style: dashed;
    }
  }

  &__tabs {
  }
}
</style>
