<template>
  <div v-if="currentProfile">
    <div class="field">
      <!--<label class="cms-section-title" style="margin-top: 0 !important">Your Home Town</label>-->
      <p class="cms">What town do you live in (or near)?</p>

      <div class="global-text-input-wrap">
        <CreatorLocationAutocomplete v-model:paidCreatorFields="currentProfile.collabPreferences" />
      </div>
    </div>

    <div class="field" style="margin-top: 1rem" id="travel-boundary">
      <!--<label class="cms-section-title">Travel Boundary</label>-->
      <p class="cms">How far can you travel for a collaboration?</p>

      <!--NOTE: Similar UI in PaidCreatorFields-->
      <!-- Tabs with or -->
      <div class="tabs-with-or">
        <div
          :class="{
            'global-h5': true,
            'tabs-with-or__tab': true,
            'tabs-with-or__tab--active': currentProfile.collabPreferences.boundarySelectMode === 'radius',
          }"
          @click="onSelectBoundaryMode('radius')"
        >
          <IconEmbedded
            class="tabs-with-or__tab-icon"
            :size="26"
            name="radius_2"
            :color="currentProfile.collabPreferences.boundarySelectMode === 'radius' ? 'rgba(255,255,255,0.3)' : 'rgba(5,133,135,0.3)'"
          />
          Set a Travel Radius
        </div>
        <div class="tabs-with-or__or-wrap">
          <div class="tabs-with-or__or">or</div>
        </div>
        <div
          :class="{
            'global-h5': true,
            'tabs-with-or__tab': true,
            'tabs-with-or__tab--active': currentProfile.collabPreferences.boundarySelectMode === 'draw',
          }"
          @click="onSelectBoundaryMode('draw')"
        >
          <IconEmbedded
            class="tabs-with-or__tab-icon"
            name="area-boundaries_2"
            :size="26"
            :color="currentProfile.collabPreferences.boundarySelectMode === 'draw' ? 'rgba(255,255,255,0.3)' : 'rgba(5,133,135,0.3)'"
          />
          Draw a Boundary
        </div>
      </div>
      <!-- / Tabs with or -->

      <div style="padding: 20px 11px 11px; margin-top: -5px; border: 1px rgba(0, 0, 0, 0.2) solid; border-radius: 0 0 6px 6px">
        <div class="map-top">
          <template v-if="currentProfile.collabPreferences.boundarySelectMode">
            <div>
              <div v-if="currentProfile.collabPreferences.boundarySelectMode === 'radius'">
                <input type="number" min="0" step="50" v-model="currentProfile.collabPreferences.travelRangeInMiles" style="width: 75px; height: 25px; display: inline; font-weight: 500" />
                mile radius.
              </div>
              <h4 v-else-if="currentProfile.collabPreferences.boundarySelectMode === 'draw'">Click the map to adjust</h4>
            </div>
            <div style="display: flex; align-items: center">
              <img style="height: 20px; width: 20px" src="https://cdn.shrpa.com/images/shrpa-logo-border-white-150.png" />
              destinations
            </div>
          </template>
        </div>

        <div>
          <GMapFullScreenSimulationWrapper isIosOnly>
            <DrawingMap
              style="width: 100%; margin: 0; display: block"
              :lat="currentProfile.collabPreferences.latitude"
              :lng="currentProfile.collabPreferences.longitude"
              :inputGeoJson="currentProfile.collabPreferences.travelBoundaryGeoJson"
              :circleRadiusInMiles="+currentProfile.collabPreferences.travelRangeInMiles"
              :boundarySelectMode="currentProfile.collabPreferences.boundarySelectMode"
              :onlyAllowDrawingMode="true"
              :defaultZoomLevel="9"
              :isCreatorFlow="true"
              mapAspectRatio="10/7"
              @boundaryChanged="boundaryChanged"
              @markerManuallyMoved="markerManuallyMoved"
              @mapReady="onMapReady"
            />
          </GMapFullScreenSimulationWrapper>
          <div v-if="showUserBoundaryArea" style="margin-top: 5px" :class="{ boundaryTooLarge: currentProfile.collabPreferences.travelBoundaryAreaInMiles > maximumTravelBoundaryInSquareMiles }">
            <span style="display: none">{{ Math.trunc(currentProfile.collabPreferences.travelBoundaryAreaInMiles).toLocaleString() }} sq miles</span>
            <span v-if="currentProfile.collabPreferences.travelBoundaryAreaInMiles > maximumTravelBoundaryInSquareMiles"> (max is {{ maximumTravelBoundaryInSquareMilesFormatted }})</span>
          </div>
          <div class="ui yellow message" v-if="currentProfile.collabPreferences.travelBoundaryAreaInMiles > travelBoundaryShowWarningInSquareMiles">
            <div class="header" style="margin-top: 0">You've selected a large area!</div>
            <p>
              Large travel boundaries work for some creators but not all.<br />
              Reminder that the same compensation and collab requirements apply whether it's 50 or 500 miles away.<br />
              Happy Travels!
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
/* eslint vue/no-mutating-props: 0 */
import { defineComponent, inject } from "vue";
import { UserProfile } from "@contracts/userProfile";
import ProfileRepo from "@logic/ProfileRepo";
import DrawingMap from "@views/Community/DrawingMap.vue";
import SrpButton from "./ui/SrpButton.vue";
import MaskedPhoneNumber from "./MaskedPhoneNumber.vue";
import CreatorLocationAutocomplete from "./CreatorLocationAutocomplete.vue";
import { buildSquareAroundCenterAsGeoJson } from "@helpers/GeoSpatialHelper";
import FeatureFlags from "@logic/FeatureFlags";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import GMapFullScreenSimulationWrapper from "@components/GMapFullScreenSimulationWrapper.vue";

// We set the auto-complete to only allow certain geography types
// see https://developers.google.com/maps/documentation/places/web-service/autocomplete#types

const travelRadiusDefaultInMiles = 250;

export default defineComponent({
  name: "PaidCreatorFields",

  components: {
    GMapFullScreenSimulationWrapper,
    IconEmbedded,
    DrawingMap,
    SrpButton,
    MaskedPhoneNumber,
    CreatorLocationAutocomplete,
  },

  props: {
    currentProfile: {
      type: Object as () => UserProfile,
      required: true,
    },
  },

  data() {
    return {
      globalLog: inject("globalLog") as any,
      globalRemoteLogger: inject("globalRemoteLogger") as any,

      maximumTravelBoundaryInSquareMiles: ProfileRepo.MaximumTravelBoundaryInSquareMiles,
      maximumTravelBoundaryInSquareMilesFormatted: ProfileRepo.MaximumTravelBoundaryInSquareMiles.toLocaleString(),
      travelBoundaryShowWarningInSquareMiles: ProfileRepo.TravelBoundaryShowWarningInSquareMiles,
    };
  },

  computed: {
    showUserBoundaryArea(): boolean {
      return this.currentProfile?.collabPreferences?.travelBoundaryGeoJson?.length > 0;
    },
  },

  methods: {
    onMapReady() {
      // Default to radius if not set
      if (!this.currentProfile?.collabPreferences?.boundarySelectMode) {
        this.currentProfile.collabPreferences.travelRangeInMiles = travelRadiusDefaultInMiles;
        this.currentProfile.collabPreferences.boundarySelectMode = "radius";
      }
    },
    boundaryChanged(geoJsonBoundaryString: string, areaInMiles: number) {
      // this.globalLog.info("boundary changed " + geoJsonBoundaryString);
      this.currentProfile.collabPreferences.travelBoundaryGeoJson = geoJsonBoundaryString;
      this.currentProfile.collabPreferences.travelBoundaryAreaInMiles = areaInMiles;
    },
    markerManuallyMoved(lat: number, lng: number) {
      this.currentProfile.collabPreferences.latitude = lat;
      this.currentProfile.collabPreferences.longitude = lng;
    },
    onSelectBoundaryMode(mode: "draw" | "radius") {
      // Both radius and manual boundary are defaulted now
      this.currentProfile.collabPreferences.boundarySelectMode = mode;
      if (mode === "radius" && !this.currentProfile.collabPreferences.travelRangeInMiles) {
        // default travel range to X miles if it hasn't been set
        this.currentProfile.collabPreferences.travelRangeInMiles = travelRadiusDefaultInMiles;
        this.globalRemoteLogger.info(`Creator: Defaulting travel radius to ${travelRadiusDefaultInMiles} miles`);
      }
      if (mode === "draw" && !this.currentProfile.collabPreferences.travelBoundaryGeoJson) {
        if (this.currentProfile.collabPreferences.latitude && this.currentProfile.collabPreferences.longitude) {
          var geoJson = buildSquareAroundCenterAsGeoJson(this.currentProfile.collabPreferences.latitude, this.currentProfile.collabPreferences.longitude, travelRadiusDefaultInMiles);
          this.currentProfile.collabPreferences.travelBoundaryGeoJson = geoJson;
          this.globalRemoteLogger.info(
            `Creator: Defaulting square boundary with centerLat=${this.currentProfile.collabPreferences.latitude}, centerLng=${this.currentProfile.collabPreferences.longitude}: ${geoJson}`
          );
        }
      }
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";

// Tabs with or ===============================================================
.tabs-with-or {
  max-width: 750px;
  display: flex;
  align-items: stretch;
  align-content: stretch;
  position: relative;
  z-index: 0;

  &__tab {
    flex-grow: 1;
    padding: 7px 15px 9px;
    border: 1px rgba(5, 133, 135, 1) solid;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(5, 133, 135, 1);
    background: rgba(255, 255, 255, 1);
    cursor: pointer;
    user-select: none;

    &:hover {
      background: linear-gradient(rgba(5, 133, 135, 0.15), rgba(5, 133, 135, 0.15)), linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
    }

    &:first-child {
      border-radius: 6px 0 0 6px;
    }
    &:last-child {
      border-radius: 0 6px 6px 0;
    }

    &--active,
    &--active:hover {
      color: rgba(255, 255, 255, 1);
      background: rgba(5, 133, 135, 1);
      cursor: default;
    }
  }

  &__tab-icon {
    margin-right: 5px;

    &--dark {
    }

    &--light {
    }
  }

  &__or-wrap {
    margin: 0 -15px;
    display: flex;
    align-items: center;
  }

  &__or {
    width: 30px;
    min-width: 30px;
    height: 30px;
    padding-bottom: 3px;
    box-sizing: border-box;
    border: 1px rgba(255, 255, 255, 1) solid;
    border-radius: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    color: rgba(255, 255, 255, 1);
    font:
      700 17px/17px "Quicksand",
      sans-serif;
    background: rgba(5, 133, 135, 1);
  }
}

.boundaryTooLarge {
  color: crimson;
  font-weight: bold;
}
.map-top {
  min-height: 25px;
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
}
.travel-boundary-selections {
  margin-top: 1rem;
}
.checkbox-card {
  width: auto !important;
  padding: 12px 16px;
  margin-right: 1rem !important;
  border: 1px solid rgba(255, 255, 255, 0.15) !important;
  border-radius: 4px;
  display: inline-flex !important;
  background-color: white !important;
  cursor: pointer;

  label {
    cursor: pointer;
  }

  &.active {
    background-color: $brand-color-lighter !important;
    border-color: $brand-color-light !important;
    label {
      color: $brand-color !important;
    }
  }
}
</style>
