<template>
  <div v-if="getViewingUserProfile?.sherpaId" class="vertical-navigation">
    <div
      :class="{
        'vertical-navigation__foldable-section': true,
        'vertical-navigation__foldable-section--folded': isMenuFolded,
      }"
    >
      <!-- Foldable title -->
      <div class="foldable-title vertical-navigation__foldable-title" @click="isMenuFolded = !isMenuFolded">
        <div class="foldable-title__title-word">Menu</div>

        <div class="foldable-title__caret-icon">
          <IconEmbedded :name="isMenuFolded ? 'caret-bottom_2-5' : 'caret-top_2-5'" />
        </div>
      </div>
      <!-- / Foldable title -->

      <ul class="vertical-navigation__links-list" @click="isMenuFolded = true">
        <MenuLinksList v-if="showSettingsNav" :linksList="navLinksSettings" />

        <MenuLinksList v-else :linksList="navLinksAdmin" />
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapState } from "pinia";

// Types
import { NavigationMenuItem } from "@contracts/navigationMenuItem";

// Stores
import { useUserProfileStore } from "@stores/userProfileStore";

// Components
import SrpButton from "@components/ui/SrpButton.vue";
import MenuLinksList from "@components/Navigation/MenuLinksList.vue";
import FeatureFlags, { ReferralFlag } from "@logic/FeatureFlags";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export default defineComponent({
  name: "CreatorLeftNav",

  components: {
    IconEmbedded,
    SrpButton,
    MenuLinksList,
  },

  data() {
    return {
      isMenuFolded: true,

      currentRouteName: "",
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["getViewingUserProfile", "getActingUserProfile", "isImpersonating"]),
    showSettingsNav(): boolean {
      // note: Always false for the creator for now
      return this.$route.path.startsWith("/settings");
    },
    navLinksSettings(): Array<NavigationMenuItem> {
      return [
        {
          text: "Back to Dashboard",
          isSubItem: false,
          titleIcon: { name: "caret-left_2-5" },
          to: { name: "CreatorDashboard", params: { creatorId: this.getViewingUserProfile.sherpaId } },
        },
      ];
    },
    navLinksAdmin(): Array<NavigationMenuItem> {
      let links = [] as Array<NavigationMenuItem>;

      links.push({
        text: "Dashboard",
        isSubItem: false,
        titleIcon: { name: "dashboard_2" },
        to: { name: "CreatorDashboard", params: { creatorId: this.getViewingUserProfile.sherpaId } },
      });

      if (this.getViewingUserProfile) {
        links.push({
          text: "My Profile",
          isSubItem: false,
          titleIcon: { name: "user_2", size: 26 },
          to: { name: "CreatorProfilePublic", params: { creatorId: this.getViewingUserProfile.sherpaId } },
        });

        // Hide this if they're becoming eligible since the new flow pushes them to a simplified adventure creation flow
        const hideMyAdventures = !this.getViewingUserProfile.collabPreferences?.isCollabEligible && this.getViewingUserProfile.collabPreferences?.becomingCollabEligible;
        if (!hideMyAdventures) {
          links.push({
            text: "My Adventures",
            isSubItem: false,
            titleIcon: { name: "compass_2" },
            to: { name: "MyItineraries", query: { creatorId: this.getViewingUserProfile.sherpaId } },
          });
        }

        // if (this.getViewingUserProfile?.enabledFeatures?.includes(ReferralFlag)) {
        links.push({
          text: "My Referrals",
          isSubItem: false,
          titleIcon: { name: "collab_2", size: 22 },
          to: { name: "CreatorReferrals", params: { creatorId: this.getViewingUserProfile.sherpaId } },
        });
        // }

        // Showing notifications for all users if (this.getViewingUserProfile?.collabPreferences?.isCollabEligible) {
        links.push({
          text: "My Settings",
          isSubItem: false,
          titleIcon: { name: "gear_2" },
          to: { name: "CreatorSettings", params: { creatorId: this.getViewingUserProfile.sherpaId } },
        });
      }

      return links;
    },
  },

  watch: {
    $route(to, from) {
      this.setRouteName();
      console.info(this.currentRouteName);
    },
  },

  async mounted() {
    this.setRouteName();
  },

  methods: {
    setRouteName() {
      this.currentRouteName = this.$router.currentRoute.value.name?.toString();
    },
    isActive(name, aliases = []): boolean {
      return name === this.currentRouteName || aliases.includes(this.currentRouteName);
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
}

// Foldable title =============================================================
.foldable-title {
  display: flex;
  position: relative;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
  user-select: none;

  &::before {
    content: "";
    width: calc(100% + 45px);
    height: calc(100% + 45px);
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
  }

  &__title-word {
    margin-right: 5px;
  }

  &__navigation-word {
    font-weight: 300;
  }

  &__caret-icon {
    width: 30px;
    height: 30px;
    position: absolute;
    inset: 0 -10px auto auto;
    font-size: 25px;
  }
}

// Vertical navigation ========================================================
.vertical-navigation {
  display: flex;
  flex-direction: column;
  background: #eee; //Changed to match the existing creator background
  //background: #e7edf0;

  &__foldable-section {
    max-height: 1000px;
    padding: 23px 26px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    transition: max-height 0.12s ease-in-out;

    &--folded {
      max-height: 66px;
    }
  }

  &__foldable-title {
    margin-bottom: 30px;
  }

  &__title-wrap {
    margin-bottom: 25px;
  }

  &__title {
    font:
      bold 22px/22px "Quicksand",
      sans-serif;
  }

  &__links-list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__li {
    margin-bottom: 9px;
    display: block;

    &:last-child {
    }

    & + &--subtitle {
      margin-top: 24px;
    }

    &--subtitle {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  &__a {
  }
}
@media screen and (min-width: 1240px) {
  .vertical-navigation {
    &__foldable-section {
      max-height: 1000px !important;
      overflow: visible;
    }

    &__foldable-title {
      display: none;
    }
  }
}
@media screen and (max-width: 1239px) {
  .vertical-navigation {
    //&__title {
    //  display: none;
    //}
  }
}
</style>
