<template>
  <div class="itinerary-details">
    <NoteWithIcon v-if="props.isEditingAllowed && showEditGuidanceInfo" class="itinerary-details__this-is-for-adjustments-note" color="yellow">
      <template #text>
        <div>
          <h4 class="global-h4" style="margin-bottom: 2px">Please use inline editing for small changes.</h4>
          <span
            >We recommend making simple text or grammar adjustments.<br />
            For larger changes, request corrections below.</span
          >
        </div>
      </template>
    </NoteWithIcon>

    <template v-if="itineraryNotFound">Itinerary not found</template>

    <template v-else>
      <!-- Gallery section -->
      <div class="gallery-section itinerary-details__gallery-section">
        <RouterLink class="gallery-section__main-image-wrap" :to="adventurePreviewLinkTo" :target="props.target">
          <Loader v-if="!isImageLoaded" style="margin: 0" />
          <img
            :class="{
              'gallery-section__main-image': true,
              'gallery-section__main-image--blurred': !isImageLoaded,
            }"
            :src="FileUtils.getAdventureImagePath(selectedImageId, true)"
            alt="Photo"
            @load="() => (isImageLoaded = true)"
          />
        </RouterLink>

        <TinyThumbnailsCarousel
          class="gallery-section__thumbnails-carousel"
          :thumbnailsList="itineraryDetails?.allImages || []"
          @selectImage="
            imageId => {
              isImageLoaded = false;
              selectedImageId = imageId;
            }
          "
        />
      </div>
      <!-- / Gallery section -->

      <div class="itinerary-details__text-content">
        <div v-if="itineraryDetails" class="itinerary-details__title-n-description">
          <component
            :is="isMainTitleAndDescriptionEditMode ? 'div' : 'RouterLink'"
            :class="{
              'global-h3': true,
              'itinerary-details__title': true,
              'itinerary-details__title--no-underline': isMainTitleAndDescriptionEditMode,
            }"
            :to="adventurePreviewLinkTo"
            :target="props.target"
          >
            <TextareaSubtle
              v-model:value="itineraryDetails.title"
              :isWithEditButton="false"
              :isBorderAlwaysVisible="isMainTitleAndDescriptionEditMode"
              :isDisabled="!isMainTitleAndDescriptionEditMode || isSaving"
              :fontSize="22"
              :fontWeight="500"
              :lineHeightCoeff="1.4"
              :fakeBgSize="{
                width: 'calc(100% - 0px)',
                minWidth: 'calc(100% - 0px)',
                height: 'calc(100% - 8px)',
              }"
              :style="{ pointerEvents: isMainTitleAndDescriptionEditMode ? 'all' : 'none' }"
            />
            <IconEmbedded v-if="!isMainTitleAndDescriptionEditMode && props.target === '_blank'" name="external-link_2" />
          </component>

          <div
            :class="{
              'itinerary-details__description': true,
              'itinerary-details__description--darker-text': isMainTitleAndDescriptionEditMode,
            }"
          >
            <TextareaSubtle
              v-model:value="itineraryDetails.whatToExpectText"
              :isWithEditButton="false"
              :isBorderAlwaysVisible="isMainTitleAndDescriptionEditMode"
              :isDisabled="!isMainTitleAndDescriptionEditMode || isSaving"
              :fontSize="14"
              :fontWeight="400"
              fontFamily="sans-serif"
              :lineHeightCoeff="1.4"
              :fakeBgSize="{
                width: 'calc(100%)',
                minWidth: 'calc(100%)',
                height: 'calc(100% - 4px)',
              }"
              :lineClamp="isMainTitleAndDescriptionEditMode ? mainDescriptionLineClampLimit : isMainDescriptionExpanded ? 0 : mainDescriptionLineClampLimit"
            />
          </div>
          <LinkWithIcon
            v-if="itineraryDetails.whatToExpectText?.length > mainDescriptionMaxSymbolsVisibleBeforeClamping && !isMainTitleAndDescriptionEditMode"
            @click="isMainDescriptionExpanded = !isMainDescriptionExpanded"
            color="teal"
            iconInset="1px -2px auto auto"
            isDottedUnderline
            style="margin: 0 0 10px -6px"
          >
            <template #icon>
              <IconEmbedded v-if="isMainDescriptionExpanded" name="caret-top_4" :size="13" />
              <IconEmbedded v-else name="caret-bottom_4" :size="13" />
            </template>
            <span> {{ isMainDescriptionExpanded ? "collapse" : "expand" }}</span>
          </LinkWithIcon>

          <div v-if="props.isEditingAllowed" class="itinerary-details__buttons-panel">
            <SrpButton
              v-if="!isMainTitleAndDescriptionEditMode"
              class="itinerary-details__button"
              size="small"
              @click="
                () => {
                  isMainTitleAndDescriptionEditMode = true;
                  showEditGuidanceInfo = true;
                  isStopsListExpanded = true;

                  titleCache = itineraryDetails.title;
                  descriptionCache = itineraryDetails.whatToExpectText;
                }
              "
              :isDisabled="isMainTitleAndDescriptionEditMode || stepIndexInEditMode !== -1"
            >
              <template #icon><IconEmbedded name="pencil_2-5" :size="18" /></template>
              Edit
            </SrpButton>

            <template v-else>
              <SrpButton
                class="itinerary-details__button"
                size="small"
                @click="saveTitleAndDescriptionAndProTip(itineraryDetails.title, itineraryDetails.whatToExpectText, itineraryDetails.proTip, true)"
                color="orange"
                :isDisabled="isSaving"
              >
                <template #icon><IconEmbedded name="save_2-5" :size="18" /></template>
                Save
              </SrpButton>
              <SrpButton
                class="itinerary-details__button"
                size="small"
                @click="
                  () => {
                    isMainTitleAndDescriptionEditMode = false;

                    itineraryDetails.title = titleCache;
                    itineraryDetails.whatToExpectText = descriptionCache;
                  }
                "
                fill="outlined"
                color="gray"
              >
                Cancel
              </SrpButton>
            </template>
          </div>
        </div>

        <!-- Roadmap section -->
        <div class="roadmap-section itinerary-details__roadmap-section">
          <div class="global-h4 roadmap-section__title">Destinations</div>

          <div
            :class="{
              'roadmap-section__roadmap-scheme-wrap-1': true,
              'roadmap-section__roadmap-scheme-wrap-1--with-left-curtain': roadmapSchemeCurtains.isLeft.value,
              'roadmap-section__roadmap-scheme-wrap-1--with-right-curtain': roadmapSchemeCurtains.isRight.value,
            }"
          >
            <div class="roadmap-section__roadmap-scheme-wrap-2" ref="domRefRoadmapSchemeContainer">
              <ul class="roadmap-section__roadmap-scheme">
                <li class="global-h6 roadmap-section__stop" v-for="stopTitle in itineraryDetails?.stepNames" :key="stopTitle">{{ stopTitle }}</li>
              </ul>
            </div>
          </div>
        </div>
        <!-- / Roadmap section -->
      </div>

      <div v-if="enableStopDetailsSection" class="itinerary-details__divider"></div>

      <!-- Stops accordion -->
      <div v-if="enableStopDetailsSection" class="stops-accordion itinerary-details__stops-accordion">
        <!-- Stops header -->
        <div
          :class="{
            'stops-header': true,
            'stops-accordion__stops-header': true,
            'stops-accordion__stops-header--with-bottom-margin': isStopsListExpanded,
          }"
          @click="isStopsListExpanded = !isStopsListExpanded"
        >
          <LinkWithIcon class="stops-header__link-with-icon" isDottedUnderline iconInset="4px auto auto 2px">
            <template #icon>
              <IconEmbedded :name="isStopsListExpanded ? 'caret-top_4-5' : 'caret-bottom_4-5'" :size="13" />
            </template>
            <span>
              <span class="global-h4" style="font-weight: 600">
                {{ isStopsListExpanded ? "Hide stops" : "Show stops" }}
              </span>
            </span>
          </LinkWithIcon>

          <!--<div v-if="itineraryDetails?.stepNames?.length" class="stops-header__counter-circle">
            {{ itineraryDetails?.stepNames?.length }}
          </div>-->
        </div>
        <!-- / Stops header -->

        <div v-if="isLoadingItinerarySteps" style="display: flex; justify-content: center; align-items: center">
          <Loader style="margin: 0" />
        </div>

        <TransitionedHeightAuto v-else-if="itinerarySteps.length" :isOpened="isStopsListExpanded">
          <ul class="stops-accordion__stops-list">
            <!-- Stop snippet -->
            <li class="stop-snippet stops-accordion__stop" v-for="(step, index) in itinerarySteps" :key="`${itineraryDetails.id}-step-${index}`">
              <div class="stop-snippet__number-and-line">
                <div class="stop-snippet__number">{{ index + 1 }}</div>
                <div v-if="index < itinerarySteps.length - 1" class="stop-snippet__line"></div>
              </div>

              <div class="stop-snippet__title-and-description">
                <div class="stop-snippet__title">
                  <TextareaSubtle
                    v-model:value="step.title"
                    :isWithEditButton="false"
                    :isBorderAlwaysVisible="stepIndexInEditMode === index"
                    :isDisabled="stepIndexInEditMode !== index || isSaving"
                    :fontSize="19"
                    :fontWeight="500"
                    :lineHeightCoeff="1.4"
                    :fakeBgSize="{
                      width: 'calc(100% - 0px)',
                      minWidth: 'calc(100% - 0px)',
                      height: 'calc(100% - 8px)',
                    }"
                  />
                </div>
                <div
                  :class="{
                    'stop-snippet__description': true,
                    'stop-snippet__description--darker-text': stepIndexInEditMode === index,
                  }"
                >
                  <TextareaSubtle
                    v-model:value="step.details"
                    :isWithEditButton="false"
                    :isBorderAlwaysVisible="stepIndexInEditMode === index"
                    :isDisabled="stepIndexInEditMode !== index || isSaving"
                    :fontSize="14"
                    :fontWeight="400"
                    fontFamily="sans-serif"
                    :lineHeightCoeff="1.4"
                    :fakeBgSize="{
                      width: 'calc(100% - 0px)',
                      minWidth: 'calc(100% - 0px)',
                      height: 'calc(100% - 4px)',
                    }"
                  />
                </div>

                <template v-if="stepIndexInEditMode === index || step.proTip">
                  <ProTipForm class="stop-snippet__pro-tip-form" v-model:value="step.proTip" :isEditable="stepIndexInEditMode === index" />
                </template>

                <div v-if="props.isEditingAllowed" class="stop-snippet__buttons-panel">
                  <SrpButton
                    v-if="stepIndexInEditMode !== index"
                    class="stop-snippet__button"
                    size="small"
                    @click="
                      () => {
                        if (stepIndexInEditMode === index) {
                          stepIndexInEditMode = -1;
                        } else {
                          stepIndexInEditMode = index;
                        }
                        showEditGuidanceInfo = true;

                        titleCache = step.title;
                        descriptionCache = step.details;
                        proTipCache = step.proTip;
                      }
                    "
                    :isDisabled="isMainTitleAndDescriptionEditMode || stepIndexInEditMode !== -1"
                  >
                    <template #icon><IconEmbedded name="pencil_2-5" :size="18" /></template>
                    Edit
                  </SrpButton>

                  <template v-else>
                    <SrpButton
                      class="stop-snippet__button"
                      size="small"
                      @click="saveTitleAndDescriptionAndProTip(itinerarySteps[index].title, itinerarySteps[index].details, itinerarySteps[index].proTip, false, index)"
                      color="orange"
                      :isDisabled="isSaving"
                    >
                      <template #icon><IconEmbedded name="save_2-5" :size="18" /></template>
                      Save
                    </SrpButton>
                    <SrpButton
                      class="stop-snippet__button"
                      size="small"
                      @click="
                        () => {
                          stepIndexInEditMode = -1;

                          itinerarySteps[index].title = titleCache;
                          itinerarySteps[index].details = descriptionCache;
                          itinerarySteps[index].proTip = proTipCache;
                        }
                      "
                      fill="outlined"
                      color="gray"
                    >
                      Cancel
                    </SrpButton>
                  </template>
                </div>
              </div>
            </li>
            <!-- / Stop snippet -->
          </ul>
        </TransitionedHeightAuto>
      </div>
      <!-- / Stops accordion -->
    </template>
  </div>
</template>

<script setup lang="ts">
import axios from "axios";
import { onMounted, computed, ref, nextTick, watch } from "vue";
import { RouteLocationRaw } from "vue-router";
import FileUtils from "@logic/FileUtils";

// Types
import { Itinerary, ItineraryStepClientSummary, ItinerarySummaryWithStepDetails, ItinerarySummary } from "@contracts/itinerary";

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import Loader from "@components/Loader/Loader.vue";
import TinyThumbnailsCarousel from "@components/TinyThumbnailsCarousel.vue";
import TransitionedHeightAuto from "@components/ui/TransitionedHeightAuto.vue";

// Composables
import useScrollCurtains from "@composables/useScrollCurtains";
import ItineraryRepo from "@repos/ItineraryRepo";
import TextareaSubtle from "@components/ui/TextareaSubtle.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import ProTipForm from "@components/ProTipForm.vue";

const props = withDefaults(
  // Note! Originally this used ItinerarySummary and called both preview and stops apis to get needed data.
  // Endpoints are being updated to remove the need to call these apis.
  defineProps<{
    itinerary: ItinerarySummary | ItinerarySummaryWithStepDetails;
    target?: "_self" | "_blank";
    isEditingAllowed?: boolean;
    enableStopDetailsSection?: boolean;
    defaultStopsOpen?: boolean;
  }>(),
  {
    itinerary: () => ({}) as ItinerarySummary,
    target: "_self",
    isEditingAllowed: false,
    enableStopDetailsSection: false,
    defaultStopsOpen: false,
  }
);

// Is image loaded ============================================================
const isImageLoaded = ref<boolean>(false);

// Selected image id ==========================================================
const selectedImageId = ref<string>(null);

// Load itinerary details =====================================================
const itineraryDetails = ref<ItinerarySummaryWithStepDetails>(null);
const itineraryNotFound = ref<boolean>(false);
const isLoading = ref<boolean>(false);

onMounted(async () => {
  isLoading.value = true;
  await loadItineraryDetails();
  isLoading.value = false;

  selectedImageId.value = itineraryDetails.value?.tileImageLocation || itineraryDetails.value?.allImages?.[0];

  if (props.defaultStopsOpen) {
    isStopsListExpanded.value = true;
  }
});

async function loadItineraryDetails() {
  // We don't need to call this if the passing code already set the stepNames
  if ("stepNames" in props.itinerary) {
    itineraryDetails.value = props.itinerary as ItinerarySummaryWithStepDetails;
    return;
  }

  let uri = `${import.meta.env.VITE_API_URL}/itineraries/${props.itinerary.id}/preview?status=${props.itinerary.status}`;
  const response = await axios.get<Itinerary>(uri);

  if (response.status == 204) {
    itineraryNotFound.value = true;
  } else {
    itineraryDetails.value = response.data as ItinerarySummaryWithStepDetails;
  }
}

// Info section for Edit Guidance =============================================
const showEditGuidanceInfo = ref<boolean>(false);

// Link building ==============================================================
const adventurePreviewLinkTo = computed((): RouteLocationRaw => {
  const isLive = props.itinerary.status === "Live";
  const link = {
    name: "ItineraryPreview",
    params: { itineraryId: isLive ? props.itinerary.uniqueName : props.itinerary.id },
    query: {},
  };
  if (!isLive) link.query = { status: props.itinerary.status };
  return link;
});

// Set roadmap scheme container curtains ======================================
const domRefRoadmapSchemeContainer = ref<HTMLElement>(null);

const roadmapSchemeCurtains = useScrollCurtains(domRefRoadmapSchemeContainer);

onMounted(async () => {
  await nextTick();
  roadmapSchemeCurtains.setCurtainsVisibility();
});

watch(itineraryDetails, async () => {
  await nextTick();
  roadmapSchemeCurtains.setCurtainsVisibility();
});

// Toggle stops list ==========================================================
const isStopsListExpanded = ref<boolean>(false);

// Load steps data ============================================================
const isLoadingItinerarySteps = ref<boolean>(false);
const itinerarySteps = ref<Array<ItineraryStepClientSummary>>([]);

watch(isStopsListExpanded, async () => {
  // We don't need to call this if the passing code already set the steps
  if ("steps" in props.itinerary) {
    itinerarySteps.value = (props.itinerary as ItinerarySummaryWithStepDetails).steps;
    return;
  }

  if (isStopsListExpanded.value && !itinerarySteps.value.length && !isLoadingItinerarySteps.value) {
    isLoadingItinerarySteps.value = true;
    const result = await ItineraryRepo.getItineraryWithSteps(props.itinerary.id, props.itinerary.status);
    isLoadingItinerarySteps.value = false;

    itinerarySteps.value = result?.itinerary?.steps || [];
  }
});

// Edit steps texts ===========================================================
const isMainTitleAndDescriptionEditMode = ref<boolean>(false);
const stepIndexInEditMode = ref<number>(-1);

// Temporarily save the Title and the Description values in case the user cancels the changes
const titleCache = ref<string>("");
const descriptionCache = ref<string>("");
const proTipCache = ref<string>("");

// Save title & description & Pro Tip =========================================
const isSaving = ref<boolean>(false);
async function saveTitleAndDescriptionAndProTip(newTitle: string, newDescription: string, newProTip: string | null, isTopLevel = false, stopIndex = -1): Promise<void> {
  const uri = `${import.meta.env.VITE_API_URL}/cms/itineraries/${props.itinerary.id}/details`;

  const payload: any = { newTitle, newDescription, newProTip, isTopLevel };
  if (!isTopLevel) {
    payload.stopIndex = stopIndex;
  }

  isSaving.value = true;
  await axios.put(uri, JSON.stringify(payload), { headers: { "Content-Type": "application/json" } });
  isSaving.value = false;

  if (isTopLevel) {
    isMainTitleAndDescriptionEditMode.value = false;
  } else {
    stepIndexInEditMode.value = -1;
  }
}

// Main description clamping options ==========================================
const mainDescriptionLineClampLimit = 8;
const mainDescriptionMaxSymbolsVisibleBeforeClamping = 600;

// Expand/collapse main description ===========================================
const isMainDescriptionExpanded = ref<boolean>(false);
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Gallery section ============================================================
.gallery-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  z-index: 0;

  &__main-image-wrap {
    width: 100%;
    min-width: 100%;
    aspect-ratio: 100/65;
    margin-bottom: 10px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  &__main-image {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0 auto auto 0;
    object-fit: cover;
    background: rgba(200, 207, 210, 1);

    &--blurred {
      filter: blur(10px);
      transform: translate3d(1px, 1px, 1px);
    }
  }

  &__thumbnails-carousel {
    width: 100%;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .gallery-section {
    &__main-image-wrap {
      aspect-ratio: unset;
      height: 160px;
    }

    &__main-image {
      object-fit: contain;
    }
  }
}

// Roadmap section ============================================================
.roadmap-section {
  &__title {
    margin-bottom: 8px;
    font-weight: 500;
  }

  &__roadmap-scheme-wrap-1 {
    position: relative;
    overflow: hidden;

    &::before,
    &::after {
      content: "";
      width: 60px;
      height: 100%;
      position: absolute;
      inset: 0 auto auto auto;
      z-index: 1;
      background: linear-gradient(90deg, rgba(243, 243, 243, 1) 0%, rgba(243, 243, 243, 0) 100%);
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.1s ease-in-out;
    }

    &::before {
      left: 0;
    }
    &::after {
      right: 0;
      background: linear-gradient(90deg, rgba(243, 243, 243, 0) 0%, rgba(243, 243, 243, 1) 100%);
    }

    &--with-left-curtain {
      &::before {
        opacity: 1;
      }
    }
    &--with-right-curtain {
      &::after {
        opacity: 1;
      }
    }
  }

  &__roadmap-scheme-wrap-2 {
    width: 100%;
    position: relative;
    z-index: 0;
    overflow-y: hidden;
    overflow-x: scroll;
    scrollbar-width: thin; /* Firefox */
    scrollbar-gutter: stable;
    scrollbar-color: rgba(0, 0, 0, 0.25) transparent;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      width: 7px !important;
      height: 7px !important;
    }

    &::-webkit-scrollbar-track {
      background: transparent !important;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      background-color: rgba(0, 0, 0, 0.25);
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(0, 0, 0, 0.35);
    }
  }

  &__roadmap-scheme {
    padding: 0;
    margin: 0;
    display: flex;
    list-style: none;
    position: relative;
    z-index: 0;
  }

  &__stop {
    width: 95px;
    min-width: 135px;
    padding: 20px 30px 0 0;
    box-sizing: border-box;
    position: relative;
    font-weight: 600;

    &::before {
      content: "";
      width: 13px;
      height: 13px;
      border-radius: 100px;
      position: absolute;
      inset: 0 auto auto 0;
      z-index: 1;
      background: rgba(17, 134, 137, 1);
    }

    &::after {
      content: "";
      width: 100%;
      height: 0;
      border-bottom: 2px rgba(0, 0, 0, 0.3) dashed;
      position: absolute;
      inset: 6px auto auto 0;
      z-index: 0;
    }

    &:last-child::after {
      display: none;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
}

// Stops header ===============================================================
.stops-header {
  display: flex;
  align-items: center;

  &__link-with-icon {
    margin-right: 4px;
  }

  &__counter-circle {
    width: 24px;
    height: 24px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 4px;
    color: rgba(0, 0, 0, 1);
    font:
      700 14px/14px "Quicksand",
      sans-serif;
    background: rgba(255, 255, 255, 1);
  }
}

// Stop snippet ===============================================================
.stop-snippet {
  display: flex;
  align-items: stretch;

  &__number-and-line {
    width: 24px;
    min-width: 24px;
    height: calc(100% + 28px);
    margin-right: 8px;
    position: relative;
    z-index: 0;
  }

  &__number {
    width: 24px;
    height: 24px;
    box-sizing: border-box;
    border: 2px rgba(17, 134, 137, 1) solid;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 1);
    font:
      700 14px/14px "Quicksand",
      sans-serif;
    text-align: center;
    background: rgba(255, 255, 255, 1);
  }

  &__line {
    width: 0;
    height: 100%;
    border-right: 2px rgba(0, 0, 0, 0.2) dashed;
    position: absolute;
    inset: 0 auto auto calc(50% - 1px);
    z-index: -1;
  }

  &__title-and-description {
    flex-grow: 1;
  }

  &__title {
    margin: -6px 0 -3px;

    :deep(.textarea-subtle textarea) {
    }
  }

  &__description {
    margin-bottom: 12px;

    :deep(.textarea-subtle textarea) {
      color: rgba(0, 0, 0, 0.7) !important;
    }

    &--darker-text {
      :deep(.textarea-subtle textarea) {
        color: rgba(0, 0, 0, 1) !important;
      }
    }
  }

  &__divider {
    margin-bottom: 15px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__pro-tip-form {
    margin-bottom: 15px;
  }

  &__buttons-panel {
    gap: 10px;
    display: flex;
    align-items: center;
  }

  &__button {
  }
}

// Stops accordion ============================================================
.stops-accordion {
  &__stops-header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: margin 0.02s ease-in-out;

    &--with-bottom-margin {
      margin-bottom: 25px;
    }
  }

  &__stops-list {
    gap: 28px;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    list-style: none;
  }

  &__stop {
  }
}

// Itinerary details ==========================================================
.itinerary-details {
  max-width: 91dvw;
  padding: 25px 20px;
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  background: rgba(243, 243, 243, 1);

  &__this-is-for-adjustments-note {
    width: 100%;
    margin-bottom: 20px;
  }

  &__gallery-section {
    width: calc(var(--grid-col-width) * 3 + var(--grid-col-gap-width) * 2 - 20px);
    min-width: calc(var(--grid-col-width) * 3 + var(--grid-col-gap-width) * 2 - 20px);
    margin-right: var(--grid-col-gap-width);
    margin-bottom: 23px;
  }

  &__text-content {
    width: calc(var(--grid-col-width) * 6 + var(--grid-col-gap-width) * 5 - 20px);
    min-width: calc(var(--grid-col-width) * 6 + var(--grid-col-gap-width) * 5 - 20px);
  }

  &__title-n-description {
    margin-bottom: 23px;
  }

  &__title {
    margin: -8px 0 -3px 0;
    display: inline-flex;
    font-weight: 600;

    &:last-child {
      margin-bottom: 0;
    }

    :deep(.textarea-subtle textarea) {
      text-decoration: underline;
      text-decoration-color: rgba(0, 0, 0, 0.3);
      text-underline-offset: 3px;
      text-decoration-thickness: 1px;
    }

    &:hover,
    &--no-underline {
      :deep(.textarea-subtle textarea) {
        text-decoration: none;
      }
    }
  }

  &__description {
    margin-bottom: 5px;
    color: rgba(91, 91, 91, 1);
    font-family: sans-serif;

    :deep(.textarea-subtle textarea) {
      color: rgba(91, 91, 91, 1) !important;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &--darker-text {
      :deep(.textarea-subtle textarea) {
        color: rgba(0, 0, 0, 1) !important;
      }
    }
  }

  &__buttons-panel {
    gap: 10px;
    display: flex;
    align-items: center;
  }

  &__button {
  }

  &__roadmap-section {
  }

  &__divider {
    width: 100%;
    height: 0;
    margin-bottom: 17px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__stops-accordion {
    width: 100%;
    margin-bottom: 5px;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .itinerary-details {
    &__gallery-section {
      width: calc(var(--grid-col-width) * 3 + var(--grid-col-gap-width) * 2 - 20px);
      min-width: calc(var(--grid-col-width) * 3 + var(--grid-col-gap-width) * 2 - 20px);
    }

    &__text-content {
      width: calc(var(--grid-col-width) * 5 + var(--grid-col-gap-width) * 4 - 20px);
      min-width: calc(var(--grid-col-width) * 5 + var(--grid-col-gap-width) * 4 - 20px);
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .itinerary-details {
    flex-direction: column;

    &__gallery-section {
      width: calc(var(--grid-col-width) * 5 + var(--grid-col-gap-width) * 4 - 20px);
      min-width: calc(var(--grid-col-width) * 5 + var(--grid-col-gap-width) * 4 - 20px);
      margin: 0 0 20px 0;
    }

    &__text-content {
      width: calc(var(--grid-col-width) * 8 + var(--grid-col-gap-width) * 7 - 40px);
      min-width: calc(var(--grid-col-width) * 8 + var(--grid-col-gap-width) * 7 - 40px);
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .itinerary-details {
    flex-direction: column;

    &__gallery-section {
      width: 100%;
      min-width: 100%;
      margin: 0 0 20px 0;
    }

    &__text-content {
      width: calc(var(--grid-col-width) * 6 + var(--grid-col-gap-width) * 5 - 40px);
      min-width: calc(var(--grid-col-width) * 6 + var(--grid-col-gap-width) * 5 - 40px);
    }
  }
}
</style>
