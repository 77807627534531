<template>
  <div class="srp-progress-bar">
    <div v-if="label" class="global-h5 srp-progress-bar__label">{{ label }}</div>
    <div class="srp-progress-bar__scale-wrap">
      <div class="srp-progress-bar__scale" :style="{ width: progress + '%' }">
        <div v-if="progress > 5" class="srp-progress-bar__progress-number">{{ progress }}%</div>
      </div>
    </div>
  </div>
</template>

<!-- prettier-ignore -->
<script lang="ts">export default { name: "SrpProgressBar" };</script>

<script setup lang="ts">
import { ref, watch, onMounted } from "vue";
export interface Props {
  progress: number;
  label?: string;
}
const props = withDefaults(defineProps<Props>(), {
  progress: 0,
  label: "",
});
</script>

<style scoped lang="scss">
// Shrpa Progress Bar =========================================================
.srp-progress-bar {
  &__label {
    margin-bottom: 5px;
  }

  &__scale-wrap {
    height: 25px;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    background: #e5e5e5;
  }

  &__scale {
    height: 100%;
    padding: 0 9px 1px;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    inset: 0 auto auto 0;
    white-space: nowrap;
    background: #058587;
    transition: width 0.12s ease-in-out;
  }

  &__progress-number {
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    color: #fff;
  }
}
</style>
