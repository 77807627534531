import posthog from "posthog-js";

export default {
  install(app) {
    const postHogId = globalThis.Bootstrap.Config.postHogId;
    if (postHogId) {
      app.config.globalProperties.$posthog = posthog.init(postHogId, {
        api_host: "https://us.i.posthog.com",
        session_recording: {
          maskAllInputs: false,
          maskInputOptions: {
            password: true,
            email: true,
            tel: true,
            text: false,
            url: false,
            number: false,
            textarea: false,
          },
        },
      });
    } else {
      console.info("Posthog not setup, no Id found.");
    }
  },
};
