<template>
  <div>
    <h4>How can I be eligible for paid collaborations?</h4>
    <div>You need to create build your portfolio on Shrpa and create 1 adventure. Destinations will be able to see the style of content you create and the activities you are interested in.</div>
    <h4>What should I expect?</h4>
    <div>Destinations will be able to invite you as a creator they want to work with. We'll email you any time you get an invite!</div>
    <br />
    <div>We're constantly adding more destinations and collaboration opportunities! We look forward to collaborating with you. Happy exploring!</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PaidCreatorFieldsEnteredMessage",
  data() {
    return {};
  },
  methods: {},
});
</script>

<style scoped lang="scss"></style>
