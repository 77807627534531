<template>
  <div class="spotlight-dashboard">
    <Loader v-if="isLoading" />

    <template v-else>
      <template v-if="!latestCampaign">
        <!-- Main header -->
        <div class="main-header spotlight-dashboard__main-header">
          <h2 class="global-h2 main-header__title">Start with a Community Upload Link</h2>
          <div class="main-header__subtitle">Invite locals to share authentic photos and videos!</div>
        </div>
        <!-- / Main header -->

        <!-- Upload campaigns -->
        <div class="upload-campaigns spotlight-dashboard__upload-campaigns">
          <ul class="upload-campaigns__template-snippets-list">
            <UploadCampaignTemplateSnippet
              class="upload-campaigns__template-snippet"
              v-for="templateTheme in Object.keys(uploadCampaignTemplates)"
              :key="templateTheme"
              :template="uploadCampaignTemplates[templateTheme]"
              :templateTheme="templateTheme as UploadCampaignTemplateTheme"
              @select="templateTheme => router.push({ name: 'UserUploadCampaigns', params: { pageId: props.pageId }, query: { openCreateCampaignModalWithTemplate: templateTheme } })"
            />
          </ul>
        </div>
        <!-- / Upload campaigns -->
      </template>

      <template v-else>
        <RouterLink :to="{ name: 'UserUploadCampaigns', params: { pageId: props.pageId }, query: { openCreateCampaignModal: 'true' } }">
          <LinkWithIconAndCTA
            :text="campaignData?.campaigns?.length ? 'Create another Upload Link' : 'Create Upload Link'"
            iconSrc="/images/uploadCampaignIcons/generalUploads.svg"
            :iconSize="50"
            style="margin-bottom: 20px"
            @click="() => {}"
          />
        </RouterLink>

        <!-- Upload campaigns -->
        <div class="upload-campaigns spotlight-dashboard__upload-campaigns" style="margin-bottom: 20px">
          <ul class="upload-campaigns__upload-campaign-snippets-list">
            <UploadCampaignSnippet
              v-if="latestCampaign"
              class="upload-campaigns__upload-campaign-snippet"
              :uploadCampaign="latestCampaign"
              :pageId="props.pageId"
              :showRemoveButton="false"
              @clickOnTitle="router.push({ name: 'UserUploadCampaigns', params: { pageId: props.pageId }, query: { campaignToEditId: latestCampaign.campaign.campaignId } })"
              @archive="router.push({ name: 'UserUploadCampaigns', params: { pageId: props.pageId }, query: { campaignToArchiveId: latestCampaign.campaign.campaignId } })"
              @share="
                () => {
                  campaignToShare = latestCampaign.campaign;
                  isSpreadTheWordModalVisible = true;
                }
              "
            />
          </ul>
        </div>
        <!-- / Upload campaigns -->

        <SpreadTheWordModal v-if="campaignToShare" v-model:isVisible="isSpreadTheWordModalVisible" :pageId="props.pageId" :campaign="campaignToShare" />

        <LinkWithIcon
          v-if="campaignData?.campaigns?.length > 1"
          style="margin-bottom: 30px"
          iconInset="auto -5px auto auto"
          color="blue"
          tag="RouterLink"
          :to="{ name: 'UserUploadCampaigns', params: { pageId: props.pageId } }"
        >
          <span>All upload campaigns</span>
          <template #iconRight><IconEmbedded name="arrow-right_2" :size="20" /></template>
        </LinkWithIcon>
      </template>

      <div class="spotlight-dashboard__divider"></div>

      <!-- Photo walls section -->
      <div class="photo-walls-section spotlight-dashboard__photo-walls">
        <h2 class="global-h2 photo-walls-section__title">Enhance your Site with Photo Walls</h2>

        <img class="photo-walls-section__example-img" src="https://cdn.shrpa.com/images/misc/integrations/photowall-example.jpg" alt="example" />

        <!-- Create photowall snippet -->
        <RouterLink :to="{ name: 'WidgetDirections', params: { pageId: props.pageId } }">
          <div class="create-photowall-snippet photo-walls-section__create-photowall-snippet">
            <div class="create-photowall-snippet__icon-and-text-wrap">
              <div class="create-photowall-snippet__icon-wrap">
                <img class="create-photowall-snippet__icon" style="width: 60px" src="/images/uploadCampaignIcons/photoContest.svg" />
              </div>

              <div class="global-h3 create-photowall-snippet__title">Setup a Photowall</div>
            </div>

            <SrpButton class="create-photowall-snippet__cta-button" fill="outlined">Get Started</SrpButton>
          </div>
        </RouterLink>
        <!-- / Create photowall snippet -->
      </div>
      <!-- / Photo walls section -->
    </template>
  </div>
</template>

<script setup lang="ts">
import axios from "axios";
import { ref, onMounted, computed, watch } from "vue";
import uploadCampaignTemplatesRaw from "@views/Community/UserUploadCampaigns/uploadCampaignTemplates.json";
import { useRouter } from "vue-router";

// Components
import SpreadTheWordModal from "@views/Community/UserUploadCampaigns/SpreadTheWordModal.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import UploadCampaignSnippet from "@views/Community/UserUploadCampaigns/UploadCampaignSnippet.vue";
import UploadCampaignTemplateSnippet from "@views/Community/UserUploadCampaigns/UploadCampaignTemplateSnippet.vue";
import LinkWithIconAndCTA from "@views/Community/UserUploadCampaigns/LinkWithIconAndCTA.vue";
import Loader from "@components/Loader/Loader.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

// Types
import { UserContentUploadCampaignData, UserContentUploadCampaignDataV2, UserUploadCampaignsV2 } from "@contracts/userContentUploadCampaignData";
import { UploadCampaignTemplateTheme, UploadCampaignTemplate } from "@contracts/uploadCampaignTemplates";

const props = withDefaults(
  defineProps<{
    pageId: string | null;
  }>(),
  {
    pageId: null,
  }
);

// Init router ================================================================
const router = useRouter();

// Set type for the uploadCampaignTemplates ===================================
const uploadCampaignTemplates: Record<UploadCampaignTemplateTheme, UploadCampaignTemplate> = uploadCampaignTemplatesRaw;

// Load data ==================================================================
const isLoading = ref<boolean>(false);
const campaignData = ref<UserUploadCampaignsV2 | null>(null);

watch(() => props.pageId, loadData);

async function loadData(): Promise<void> {
  isLoading.value = true;
  const { data } = await axios.get<UserUploadCampaignsV2>(`${import.meta.env.VITE_API_URL}/useruploadcampaigns/admin/${props.pageId}/v2`);
  campaignData.value = data;

  isLoading.value = false;
}

onMounted(async () => {
  await loadData();
});

// Retrieve the latest campaign ===============================================
const latestCampaign = computed<UserContentUploadCampaignDataV2 | null>(() => {
  const activeCampaigns = campaignData.value?.campaigns?.filter(c => !c.campaign.expiryDateTime);
  if (activeCampaigns?.length) {
    return activeCampaigns?.reduce((a, b) => {
      return new Date(b.campaign.createdDate) > new Date(a.campaign.createdDate) ? b : a;
    });
  } else {
    return null;
  }
});

// Toggle the "Spread The Word" modal =========================================
const campaignToShare = ref<UserContentUploadCampaignData | null>(null);
const isSpreadTheWordModalVisible = ref<boolean>(false);
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";
@import "@/scss/variables.scss";

// Main header ================================================================
.main-header {
  &__title {
    margin-bottom: 6px;
  }

  &__subtitle {
  }
}

// Upload campaigns ===========================================================
.upload-campaigns {
  &__template-snippets-list {
    padding: 0;
    margin: 0;
    gap: $desktop-wide-grid-gap-width;
    display: flex;
    list-style: none;
  }

  &__template-snippet {
  }

  &__create-link {
  }

  &__upload-campaign-snippets-list {
    width: 100%;
    max-width: 100%;
    gap: 27px;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
  }

  &__upload-campaign-snippet {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .upload-campaigns {
    &__template-snippets-list {
      gap: $tablet-large-grid-gap-width;
    }

    &__template-snippet {
      width: calc(50% - #{$tablet-large-grid-gap-width} / 2);
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .upload-campaigns {
    &__template-snippets-list {
      gap: $tablet-grid-gap-width;
      flex-wrap: wrap;
    }

    &__template-snippet {
      width: calc(50% - #{$tablet-grid-gap-width} / 2);
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .upload-campaigns {
    &__template-snippets-list {
      gap: $mobile-grid-gap-width;
      flex-wrap: wrap;
    }

    &__template-snippet {
      width: calc(50% - #{$mobile-grid-gap-width} / 2);
    }
  }
}

// Create photowall snippet ===================================================
.create-photowall-snippet {
  min-height: 65px;
  padding: 0 20px;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: $faint-blue;
  user-select: none;
  cursor: pointer;

  &__icon-and-text-wrap {
    margin-right: 45px;
    display: flex;
    align-items: center;

    &:last-child {
      margin-right: 10px;
    }
  }

  &__icon-wrap {
    width: 40px;
    height: 40px;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
  }

  &__title {
    font-weight: 500;
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.3);
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;

    &--dashed-underline {
      text-decoration-style: dashed;
    }
  }
  &:hover &__title {
    text-decoration: none;
  }

  &__cta-button {
    &::before {
      background: rgba(255, 255, 255, 1) !important;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .create-photowall-snippet {
    padding: 10px 23px 7px 20px;
    flex-direction: column;
    align-items: flex-start;

    &__icon-and-text-wrap {
      margin: 0 0 10px 0;

      &:last-child {
        margin: 0;
      }
    }

    &__cta-button {
      margin-bottom: 10px;
    }
  }
}

// Photo walls section ========================================================
.photo-walls-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__title {
    margin-bottom: 20px;
  }

  &__example-img {
    width: 400px;
    max-width: calc(100dvw - 30px);
    margin-bottom: 23px;
  }

  &__create-photowall-snippet {
  }
}

// Spotlight dashboard ========================================================
.spotlight-dashboard {
  &__main-header {
    margin-bottom: 30px;
  }

  &__upload-campaigns {
    width: 100%;
    margin-bottom: 48px;
  }

  &__divider {
    margin-bottom: 35px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__photo-walls {
  }
}
</style>
