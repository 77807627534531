<template>
  <div class="slide-about-examples">
    <ExampleSection
      class="slide-about-examples__example-section"
      v-for="sectionName in Object.keys(collabSummarySections)"
      :key="sectionName"
      :title="sectionName"
      :collabSummary="collabSummarySections[sectionName]"
    />

    <SrpButton class="slide-about-examples__complete-button" size="big" color="orange" isBiggerSidePaddings @click="emit('complete')" :isDisabled="props.isLoading">Got it, I'm ready!</SrpButton>
  </div>
</template>

<!-- prettier-ignore -->
<script lang="ts">export default { name: "SlideAboutExamples" };</script>

<script setup lang="ts">
import axios from "axios";
import { onMounted, ref } from "vue";

// Types
import { ItinerarySummary } from "@contracts/itinerary";
import { CreatorPublicProfileFields } from "@contracts/creatorPublicProfileFields";
import { CreatorCollab } from "@contracts/collab";
import { Page } from "@contracts/pages";

export interface CollabSummary {
  page: Page;
  itineraries: Array<ItinerarySummary>;
  creator: CreatorPublicProfileFields;
  collabDetails: CreatorCollab;
}

export type SummarySectionName = "Event Focused" | "Seasonal" | "Family" | "Couples";

// Components
import SrpButton from "@components/ui/SrpButton.vue";
import ExampleSection from "./ExampleSection.vue";

export interface Props {
  isLoading: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  isLoading: false,
});

const emit = defineEmits<{
  (e: "complete"): void;
}>();

// Load collab summaries ======================================================
const collabSummarySections = ref<{
  [key: SummarySectionName]: CollabSummary | null;
}>({});

async function loadCollabSummary(sectionName: SummarySectionName, communityId: string, collabInputId: string): Promise<void> {
  // NOTE! Using the prod api here since we're pulling example collabs (this endpoint is anonymous)
  let uri = `${import.meta.env.VITE_PROD_API_URL}/collabsummary/${communityId}/collabs/${collabInputId}`;
  const response = await axios.get(uri);

  collabSummarySections.value[sectionName] = {
    page: response.data.page,
    itineraries: response.data.itineraries,
    creator: response.data.creator,
    collabDetails: response.data.collabDetails,
  };
}

onMounted(async () => {
  // Event focused
  await Promise.all([
    // Event focused
    loadCollabSummary("Event Focused", "shakopeemn", "1512e59c-2387-4ab7-a4f3-fe46bc2a0f77"),
    // Seasonal
    loadCollabSummary("Seasonal", "lakeofthewoodsmn", "8ddf2a54-5f46-4e38-95fe-dcae4786563a"),
    // Family
    loadCollabSummary("Family", "alexandriamn", "80afeacb-a3c3-4b23-b579-0e08f68929f2"),
    // Couples
    loadCollabSummary("Couples", "crescenthotelar", "e007c8c9-0f9c-4058-bd99-43de226843b1"),
  ]);
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Slide about examples =======================================================
.slide-about-examples {
  &__example-section {
    margin-bottom: 25px;
  }

  &__complete-button {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
}
</style>
