<template>
  <div
    :class="{
      'note-with-icon': true,
      'note-with-icon--gray': props.color === 'gray',
      'note-with-icon--green': props.color === 'green',
      'note-with-icon--red': props.color === 'red',
      'note-with-icon--blue': props.color === 'blue',

      'note-with-icon--small': props.size === 'small',
      'note-with-icon--tiny': props.size === 'tiny',

      'note-with-icon--transparent-bg': props.isTransparentBg,
    }"
    ref="domRefRoot"
  >
    <div
      v-if="slots.icon || props.icon"
      :class="{
        'note-with-icon__icon': true,
        'note-with-icon__icon--gray': props.color === 'gray',
        'note-with-icon__icon--green': props.color === 'green',
        'note-with-icon__icon--red': props.color === 'red',
        'note-with-icon__icon--blue': props.color === 'blue',

        'note-with-icon__icon--small': props.size === 'small',
        'note-with-icon__icon--tiny': props.size === 'tiny',
      }"
    >
      <slot name="icon">
        <IconEmbedded :name="props.icon" :size="props.iconSize || { tiny: 16, small: 18, normal: 26 }[props.size]" />
      </slot>
    </div>

    <div class="note-with-icon__text">
      <slot name="text" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, useSlots } from "vue";

// Types
import { IconName } from "@contracts/IconName";

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";

const props = withDefaults(
  defineProps<{
    color?: "gray" | "yellow" | "green" | "red" | "blue";
    size?: "tiny" | "small" | "normal";
    icon?: IconName;
    iconSize?: number;
    isTransparentBg?: boolean;
  }>(),
  {
    color: "yellow",
    size: "normal",
    icon: "info-simple_4",
    iconSize: null,
    isTransparentBg: false,
  }
);

const slots = useSlots();

// Expose the root node =======================================================
const domRefRoot = ref<HTMLElement | null>(null);

defineExpose({
  domRefRoot,
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Note with icon =============================================================
.note-with-icon {
  max-width: 100%;
  box-sizing: border-box;
  padding: 14px 23px;
  border-radius: 6px;
  display: flex;
  align-items: flex-start;
  position: relative;
  z-index: 0;
  color: #413806;
  font:
    400 14px/18px "Helvetica Neue",
    sans-serif;
  background: #f0e7b8;

  :deep(.embedded-icon svg) {
    fill: #413806;
  }

  :deep(a:hover) {
    text-decoration: none;
  }

  // prettier-ignore
  :deep(h1),:deep(h2),:deep(h3),:deep(h4),:deep(h5),:deep(h6),:deep(.global-h1),:deep(.global-h2),:deep(.global-h3),:deep(.global-h4),:deep(.global-h5),:deep(.global-h6) { color: #413806; }

  // color ====================================================================
  &--gray {
    color: #5b5b5b;
    background: #f0f0f0;
    :deep(.embedded-icon svg) {
      fill: #5b5b5b;
    }
    // prettier-ignore
    :deep(h1),:deep(h2),:deep(h3),:deep(h4),:deep(h5),:deep(h6),:deep(.global-h1),:deep(.global-h2),:deep(.global-h3),:deep(.global-h4),:deep(.global-h5),:deep(.global-h6) { color: #5b5b5b; }

    :deep(a) {
      color: #5b5b5b !important;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  &--green {
    color: #22341d;
    background: #d5ecce;
    :deep(.embedded-icon svg) {
      fill: #22341d;
    }
    // prettier-ignore
    :deep(h1),:deep(h2),:deep(h3),:deep(h4),:deep(h5),:deep(h6),:deep(.global-h1),:deep(.global-h2),:deep(.global-h3),:deep(.global-h4),:deep(.global-h5),:deep(.global-h6) { color: #22341d; }

    :deep(a) {
      color: #22341d !important;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  &--red {
    color: #945347;
    background: #f8d5cf;
    :deep(.embedded-icon svg) {
      fill: #945347;
    }
    // prettier-ignore
    :deep(h1),:deep(h2),:deep(h3),:deep(h4),:deep(h5),:deep(h6),:deep(.global-h1),:deep(.global-h2),:deep(.global-h3),:deep(.global-h4),:deep(.global-h5),:deep(.global-h6) { color: #945347; }

    :deep(a) {
      color: #945347 !important;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  &--blue {
    color: #2a4141;
    background: #daf0f0;
    :deep(.embedded-icon svg) {
      fill: #2a4141;
    }
    // prettier-ignore
    :deep(h1),:deep(h2),:deep(h3),:deep(h4),:deep(h5),:deep(h6),:deep(.global-h1),:deep(.global-h2),:deep(.global-h3),:deep(.global-h4),:deep(.global-h5),:deep(.global-h6) { color: #2a4141; }

    :deep(a) {
      color: #2a4141 !important;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  // size =====================================================================
  &--tiny {
    padding: 9px 15px 9px 11px;
  }
  &--small {
    padding: 13px 15px;
  }

  &--transparent-bg {
    padding: 0;
    background: transparent;
  }

  &__icon {
    width: 40px;
    min-width: 40px;
    height: 40px;
    margin-right: 16px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #d9ce95;

    :deep(.embedded-icon svg) {
      width: 23px;
      height: 23px;
    }

    &--gray {
      background: #d0d0d0;
    }
    &--green {
      background: #add1a1;
    }
    &--red {
      background: #e5b0a6;
    }
    &--blue {
      background: #afd6d6;
    }

    &--tiny {
      width: 26px;
      min-width: 26px;
      height: 26px;
      margin-right: 8px;
    }
    &--small {
      width: 33px;
      min-width: 33px;
      height: 33px;
      margin-right: 8px;
    }
  }

  &__text {
    flex-grow: 1;
    padding-bottom: 3px;
    align-self: stretch;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    word-break: break-word;
    text-wrap: balance;

    :deep(*) {
      text-wrap: balance;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .note-with-icon {
    //padding: 16px 23px;
    padding: 16px 17px;

    &--tiny {
      padding: 9px 11px;
    }
    &--small {
      padding: 13px 15px;
    }

    &__icon {
      margin-right: 13px;
    }
  }
}
</style>
