<template>
  <span class="ellipsis-animated">
    <span :class="['ellipsis-animated__dot', `ellipsis-animated__dot--${index + 1}`]" v-for="(_, index) in [0, 1, 2]" :key="index" :style="{ animationDuration: props.duration + 's' }">.</span>
  </span>
</template>

<script setup lang="ts">
export interface Props {
  duration?: number;
}
const props = withDefaults(defineProps<Props>(), {
  duration: 0.8,
});
</script>

<style scoped lang="scss">
// prettier-ignore
@keyframes dot1Blinking {
  0% {opacity: 0;}
  25% {opacity: 0;}
  50% {opacity: 1;}
  75% {opacity: 1;}
  100% {opacity: 1;}
}
// prettier-ignore
@keyframes dot2Blinking {
  0% {opacity: 0;}
  25% {opacity: 0;}
  50% {opacity: 0;}
  75% {opacity: 1;}
  100% {opacity: 1;}
}
// prettier-ignore
@keyframes dot3Blinking {
  0% {opacity: 0;}
  25% {opacity: 0;}
  50% {opacity: 0;}
  75% {opacity: 0;}
  100% {opacity: 1;}
}
// Ellipsis animated ==========================================================
.ellipsis-animated {
  &__dot {
    &--1 {
      animation: dot1Blinking 0.8s step-start infinite;
    }
    &--2 {
      animation: dot2Blinking 0.8s step-start infinite;
    }
    &--3 {
      animation: dot3Blinking 0.8s step-start infinite;
    }
  }
}
</style>
