import logger, { LogLevelDesc, Logger } from "loglevel";
import { getCurrentInstance } from "vue";

// NOTE: Manually adjust in the console with: localStorage.loglevel = "DEBUG"

// @ts-ignore
const logLevel = globalThis.Bootstrap?.Config?.isDev ? logger.levels.INFO : logger.levels.WARN;
console.log("Setting LogLevel=" + logLevel);
logger.setDefaultLevel(logLevel);

export default logger;

export function getLogger(name: string, defaultLogLevel: LogLevelDesc | null = null): Logger {
  const localLogger = logger.getLogger(name);
  if (defaultLogLevel != null) localLogger.setDefaultLevel(defaultLogLevel);

  return localLogger;
}

export function useLogger() {
  return getLogger(getCurrentInstance()?.type.__name ?? "Unknown");
}
