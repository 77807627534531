<template>
  <li class="collab-posted-section">
    <EventTypeTag class="collab-posted-section__type-tag" color="green">Collab Posted</EventTypeTag>

    <div class="collab-posted-section__content">
      <div class="collab-posted-section__logo-wrap">
        <img class="collab-posted-section__logo" :src="`${contentBaseUri}/cms/images/orgs/${feedItem.destinationLogo}`" :alt="props.feedItem.destinationName" />
      </div>

      <div class="collab-posted-section__right-side">
        <div class="collab-posted-section__community-name">
          {{ feedItem.destinationName }}
        </div>
        <h5 class="global-h5 collab-posted-section__collab-name">
          {{ feedItem.collabName }}
        </h5>
        <div class="collab-posted-section__date-n-collab-type">
          <!-- Date snippet -->
          <div class="date-snippet collab-posted-section__date">
            <IconEmbedded class="date-snippet__icon" name="calendar_2" />
            <div class="date-snippet__text">
              {{
                DateUtils.formatDateRange(feedItem.preferredDateRangeStart, 0, false) +
                (feedItem.preferredDateRangeEnd ? ` - ${DateUtils.formatDateRange(feedItem.preferredDateRangeEnd, 0, false)}` : "")
              }}
            </div>
          </div>
          <!-- / Date snippet -->

          <!-- Collab type snippet -->
          <div v-if="feedItem.collabVisitFocus" class="collab-type-snippet collab-posted-section__collab-type">
            <img class="collab-type-snippet__icon" :src="getThemeIcon(feedItem.collabVisitFocus)" :alt="getThemeTypeName(feedItem.collabVisitFocus)" />
            <div class="collab-type-snippet__name">{{ getThemeTypeName(feedItem.collabVisitFocus) }}</div>
          </div>
          <!-- / Collab type snippet -->
        </div>

        <div v-if="showApplyButton" class="collab-posted-section__apply-container">
          <SrpButton :is-disabled="!!userCantApplyMessage" @click="onClickApply" class="collab-posted-section__apply-btn" size="small" color="gray">Apply</SrpButton>
          <p v-if="userCantApplyMessage">{{ userCantApplyMessage }}</p>
        </div>
      </div>
    </div>
  </li>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import { computed, ref } from "vue";
import { getThemeTypeName, getThemeIcon } from "@helpers/ThemeTypeHelper";
import DateUtils from "@logic/DateUtils";

// Components
import EventTypeTag from "@components/ActivityFeed/EventTypeTag.vue";
import SrpButton from "@components/ui/SrpButton.vue";

// Types
import { CollabPostedFeedItem } from "@components/ActivityFeed/types";

// Global variables
const contentBaseUri = globalThis.Bootstrap.Config.contentCdnBaseUri;

// Stores
import { useUserProfileStore } from "@stores/userProfileStore";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
const userProfileStore = useUserProfileStore();

const props = withDefaults(
  defineProps<{
    hideApplyButton?: boolean;
    feedItem: CollabPostedFeedItem;
  }>(),
  {
    feedItem: null,
  }
);

const emit = defineEmits<{
  (e: "clickApply"): void;
}>();

const router = useRouter();

const userCantApplyMessage = ref("");

const showApplyButton = computed(() => {
  return userProfileStore.getViewingUserProfile?.sherpaId && !props.hideApplyButton && userProfileStore.getViewingUserProfile?.collabPreferences?.collabEligibleDisabled !== true;
});

function onClickApply() {
  if (!userProfileStore.getViewingUserProfile.collabPreferences.isCollabEligible) {
    userCantApplyMessage.value = "You can apply to collabs as soon as you're paid eligible!";
    return;
  }
  if (!props.feedItem.isOpenToApply) {
    userCantApplyMessage.value = "A creator has already been selected for this collab.";
    return;
  }
  router.push({
    name: "CollabOpportunity",
    params: { communityId: props.feedItem.destinationId, collabInputId: props.feedItem.collabId, creatorId: userProfileStore.getViewingUserProfile?.sherpaId },
  });
  emit("clickApply");
}
</script>

<style scoped lang="scss">
@import "./commonStyle.scss";
@import "@/scss/variables.scss";

// Date snippet ===============================================================
.date-snippet {
  display: flex;
  align-items: center;

  &__icon {
    width: 22px;
    margin: -2px 3px 0 0;

    :deep(svg) {
      fill: rgba(0, 0, 0, 0.5);
    }
  }

  &__text {
    color: #5b5b5b;
    font: 14px/14px sans-serif;
    white-space: nowrap;
  }
}

// Collab type snippet ========================================================
.collab-type-snippet {
  display: flex;
  align-items: center;

  &__icon {
    width: 30px;
    margin-right: 2px;
    color: rgba(0, 0, 0, 0.5);
    font-size: 22px;
    line-height: 22px;
  }

  &__name {
    color: #5b5b5b;
    font: 14px/14px sans-serif;
  }
}

// Collab posted section ======================================================
.collab-posted-section {
  @include eventContainer;
  display: flex;
  justify-content: space-between;
  position: relative;

  &__content {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  &__logo-wrap {
    width: 72px;
    min-width: 72px;
    height: 72px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-content: center;
  }

  &__logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__right-side {
    flex-grow: 1;
  }

  &__community-name {
    margin-bottom: 1px;
    font-weight: 500;
  }

  &__collab-name {
    max-width: calc(100% - 100px);
    margin-bottom: 2px;
  }

  &__date-n-collab-type {
    margin-bottom: 9px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__date {
    margin: 0 30px 0 -2px;
  }

  &__collab-type {
  }

  &__apply-container {
    display: flex;
    align-items: center;
    p {
      color: $orange-dark;
      margin-left: 4px;
      font-size: 1rem;
    }
  }

  &__apply-btn {
    margin-right: 4px;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .collab-posted-section {
    &__collab-name {
      max-width: 100%;
    }
  }
}
</style>
