export function Base64ToBinary(base64String: string): Uint8Array {
  // Remove data URL prefix if present
  const base64WithoutPrefix = base64String.replace(/^data:[^,]+,/, "");
  
  // Decode base64 to binary string
  const binaryString = atob(base64WithoutPrefix);
  
  // Convert binary string to Uint8Array
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  
  return bytes;
}
