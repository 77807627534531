<template>
  <div class="locations-list">
    <div class="map-section__map-floating-zone">
      <div class="locations-list__map-section-wrap">
        <!-- Map legend -->
        <div class="map-legend locations-list__map-legend">
          <!-- Legend snippet -->
          <div class="legend-snippet map-legend__legend-snippet">
            <label class="legend-snippet__icon-wrap">
              <div
                :style="{
                  width: '20px',
                  height: '20px',
                  border: '2px rgba(45, 149, 148, 1) solid',
                  borderRadius: ['radius', 'circle'].includes(boundarySelectMode) ? '100px' : '0',
                  background: 'rgba(45, 149, 148, 0.3)',
                }"
              ></div>
            </label>
            <div class="legend-snippet__name">destination boundary</div>
          </div>
          <!-- / Legend snippet -->
        </div>
        <!-- / Map legend -->

        <MapSection
          class="locations-list__map-section"
          v-model:isExpanded="isMapExpanded"
          v-model:isHighlighted="isMapHighlighted"
          v-click-outside="() => (isMapExpanded = false)"
          :locationsList="props.locationsList"
          ref="domRefMapSection"
          @clickOnMap="location => (mapClickLocation = location)"
          :activeSnippetIndex="activeSnippetIndex"
          @markerDragEnd="location => (draggedMarkerNewLocation = location)"
          :boundaryGeoJson="props.boundaryGeoJson"
          :boundarySelectMode="props.boundarySelectMode"
          :withinMiles="props.withinMiles"
          :mapCenter="props.mapCenter"
        />
      </div>

      <ul class="locations-list__list-itself" ref="domRefLocationsListItself">
        <LocationInputSnippet
          class="locations-list__location-input-snippet"
          v-for="(collabLocation, index) in props.locationsList"
          :key="collabLocation?.id"
          :index="index"
          :isDraggedOver="[elementIndexDraggedOver, elementIndexTouchDraggedOver].includes(index) && ![elementIndexDragged, elementIndexTouchDragged].includes(index)"
          :dragOverAreaQuarter="elementAreaQuarterDraggedOver || elementAreaQuarterTouchDraggedOver"
          :isCollabLocationUsedInCollab="collabLocationsStore.usedLocationsInAdventures.includes(collabLocation.id)"
          :showValidation="showValidation"
          ref="domRefLocationInputSnippets"
          @focusOnAnyField="isMapExpanded = false"
          @showSetThePinNote="isSetThePinNoteVisible => (isMapHighlighted = isSetThePinNoteVisible)"
          :collabLocation="collabLocation"
          @update:collabLocation="
            newObj => {
              const _locationsList = [...props.locationsList];
              _locationsList[index] = newObj;
              emit('update:locationsList', _locationsList);
            }
          "
          @googlePlaceSelected="
            place => {
              // fit the map boundaries to the locations
              domRefMapSection?.fitMapToLocations();
            }
          "
          @editModeToggle="value => (activeSnippetIndex = value ? index : -1)"
          @deleteLocation="deleteLocation"
        />
      </ul>
      <p v-if="!props.locationsList.length">No locations have been added for this collab</p>

      <SrpButton class="locations-list__add-location-btn" fill="outlined" size="small" @click="addNewLocationField">
        <template #icon><IconEmbedded name="plus_3-5" :size="22" /></template>
        Add another location
      </SrpButton>
    </div>

    <textarea
      v-if="props.isVisitPlanNotesVisible"
      class="global-textarea locations-list__additional-notes-textarea"
      placeholder="Additional Notes (optional)"
      :value="props.visitPlanNotes"
      @change="$event => emit('update:visitPlanNotes', ($event.target as HTMLTextAreaElement).value)"
    ></textarea>
    <!-- if any locations are used in adventures, show the warning message  -->
    <p class="locations-list__changes-not-reflected-in-adventures" v-if="collabLocationsStore.usedLocationsInAdventures.length">
      * Changes to locations will not be reflected in existing adventure stops.
    </p>
  </div>
</template>

<script setup lang="ts">
import lodashCloneDeep from "lodash-es/cloneDeep";
import { ref, toRef, nextTick, inject, Ref, provide, onMounted, watch } from "vue";
import { v4 as uuidv4 } from "uuid";

// Types
import { CollabLocation, CollabLocationEmpty } from "@contracts/collabLocations";
import { ScreenSize } from "@contracts/screenSize";
import { Location } from "@contracts/location";
import { BoundarySelectMode } from "@contracts/pages";

// Composables
import { AreaQuarter, useMouseDragNDrop } from "@composables/useMouseDragNDrop";
import { useTouchDragNDrop } from "@composables/useTouchDragNDrop";

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import LocationInputSnippet from "./LocationInputSnippet.vue";
import MapSection from "./MapSection.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import { useCollabLocationsStore } from "@stores/collabLocations";

// Global variables
const screenSize = inject("screenSize") as Ref<ScreenSize>;

const props = withDefaults(
  defineProps<{
    boundaryGeoJson?: string | null;
    locationsList: Array<CollabLocation>;
    visitPlanNotes?: string;
    isVisitPlanNotesVisible?: boolean;
    boundarySelectMode?: BoundarySelectMode | null;
    withinMiles?: number | null;
    mapCenter?: Location | null;
    showValidation?: boolean;
  }>(),
  {
    boundaryGeoJson: null,
    locationsList: () => [],
    visitPlanNotes: "",
    isVisitPlanNotesVisible: true,
    boundarySelectMode: null,
    withinMiles: null,
    mapCenter: null,
  }
);

const emit = defineEmits<{
  (e: "update:locationsList", value: Array<CollabLocation>): void;
  (e: "update:visitPlanNotes", value: string): void;
}>();

const collabLocationsStore = useCollabLocationsStore();

// Provide map click location =================================================
const mapClickLocation = ref<Location | null>(null);
provide("mapClickLocation", mapClickLocation);

// Provide dragged marker new location ========================================
const draggedMarkerNewLocation = ref<Location | null>(null);
provide("draggedMarkerNewLocation", draggedMarkerNewLocation);

// Map events
const domRefMapSection = ref<InstanceType<typeof MapSection>>();

// Initialize drag&drop composables ===========================================
const domRefLocationsListItself = ref<HTMLElement | null>(null);

function moveElementToOtherIndex(oldIndex: number, newIndex: number, _1: any, _2: any, areaQuarter: AreaQuarter): void {
  if (oldIndex === newIndex) {
    return;
  }

  const movedElement = lodashCloneDeep(props.locationsList[oldIndex]);
  const movedOverElement = lodashCloneDeep(props.locationsList[newIndex]);

  let newArray: Array<any> = lodashCloneDeep(props.locationsList);

  // Turn every element into array to make it easier to insert both of the elements into needed position
  newArray = newArray.map(el => [el]);

  // Empty both of the indexes
  newArray[oldIndex] = [];
  newArray[newIndex] = [];

  // Insert both of the elements into new positions
  newArray[newIndex] = [movedOverElement];
  // insert the new element before or after the old one depending on the AreaQuarter
  if ([1, 2].includes(areaQuarter)) newArray[newIndex].unshift(movedElement);
  else newArray[newIndex].push(movedElement);

  // Flatten the array
  newArray = newArray.flat();

  emit("update:locationsList", newArray);
}

const { elementIndexDragged, elementIndexDraggedOver, elementAreaQuarterDraggedOver } = useMouseDragNDrop(
  toRef(true),
  toRef(props.locationsList),
  domRefLocationsListItself,
  ".locations-list__location-input-snippet",
  ".location-input-snippet__drag-n-drop-handle",
  moveElementToOtherIndex
);

const { elementIndexTouchDragged, elementIndexTouchDraggedOver, elementAreaQuarterTouchDraggedOver } = useTouchDragNDrop(
  toRef(true),
  toRef(props.locationsList),
  domRefLocationsListItself,
  ".locations-list__location-input-snippet",
  ".location-input-snippet__drag-n-drop-handle",
  moveElementToOtherIndex
);

// Add a new location field ===================================================
const domRefLocationInputSnippets = ref<Array<any>>([]);

async function addNewLocationField() {
  const emptyCollabLocation = new CollabLocationEmpty(uuidv4());
  emit("update:locationsList", lodashCloneDeep([...props.locationsList, emptyCollabLocation]));
  await nextTick();
  domRefLocationInputSnippets.value[domRefLocationInputSnippets.value.length - 1]?.setFocusOnLocationInput();
}

// Map section params =========================================================
const isMapExpanded = ref<boolean>(false);
const isMapHighlighted = ref<boolean>(false);

// Set the index of the snippet that currently in edit mode ===================
// it's needed to highlight a corresponding marker on the map
const activeSnippetIndex = ref<number>(-1);

// Delete location ============================================================
function deleteLocation(index: number): void {
  emit("update:locationsList", [...props.locationsList.slice(0, index), ...props.locationsList.slice(index + 1)]);
}
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";
@import "@/scss/variables.scss";
@import "@/scss/map-legend.scss";

// Locations list =============================================================
.locations-list {
  position: relative;

  &__map-floating-zone {
    position: relative;
  }

  &__map-section-wrap {
    width: 100%;
    margin-bottom: 25px;
    position: sticky;
    inset: 0 auto auto 0;
    z-index: 2;
  }

  &__map-legend {
    position: absolute;
    inset: 12px auto auto 12px;
    z-index: 1;
    transform: scale(0.75);
    transform-origin: top left;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(15px);
  }

  &__map-section {
    position: relative;
    z-index: 0;
  }

  &__list-itself {
    padding: 0;
    margin: 0 0 12px;
    list-style: none;
    position: relative;
    z-index: 1;
  }

  &__location-input-snippet {
  }

  &__add-location-btn {
    margin: 0 0 25px 56px;
  }

  &__additional-notes-textarea {
    height: 70px;
    min-height: 70px;
  }

  &__changes-not-reflected-in-adventures {
    font-size: 1rem;
  }
}

// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .locations-list {
    &__map-section {
      margin-bottom: calc(25px + 43px);
    }
  }
}
</style>
