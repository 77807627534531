<template>
  <NotificationsSettingsModal :isVisible="props.isVisible" @update:isVisible="emit('update:isVisible', false)" @phoneVerificationFormToggle="isVisible => (isPhoneVerificationFormVisible = isVisible)">
    <div
      v-if="!isPhoneVerificationFormVisible"
      style="padding: 20px; margin-bottom: 20px; border-radius: 6px; display: flex; flex-direction: column; align-items: center; background: rgba(213, 236, 206, 1)"
    >
      <img src="https://cdn.shrpa.com/images/misc/high-five.png" style="width: 50px; height: 50px; margin-bottom: 5px" alt="Hands" />

      <h1 class="global-h1" style="margin-bottom: 17px; text-align: center">You're now Eligible<br />for Paid Collabs!</h1>

      <ul class="global-ul" style="margin-bottom: 20px; display: flex; flex-direction: column; align-items: center">
        <li>You can now view Available and Upcoming collabs on your dashboard.</li>
        <li><b>Apply to as many as you're interested in!</b></li>
        <li>Shrpa will notify you when new opportunities come up.</li>
      </ul>

      <div style="width: 100%; margin-bottom: 15px; border-bottom: 1px rgba(0, 0, 0, 0.1) solid"></div>

      <p class="global-p" style="margin-bottom: 2px; text-align: center">Questions? Reach out anytime to <CopyText text="creators@shrpa.com" /></p>
    </div>
  </NotificationsSettingsModal>
</template>

<script setup lang="ts">
import { ref } from "vue";

// Components
import CopyText from "@components/CopyText.vue";
import NotificationsSettingsModal from "@components/NotificationsSettingsModal.vue";

const props = withDefaults(
  defineProps<{
    isVisible: boolean;
  }>(),
  {
    isVisible: false,
  }
);

const emit = defineEmits<{
  (e: "update:isVisible", value: boolean): void;
}>();

// Track phone verification form visibility ===================================
const isPhoneVerificationFormVisible = ref<boolean>(false);
</script>

<style scoped lang="scss"></style>
