<template>
  <TooltipWrapper class="lodging-checkbox-n-info__tooltip-wrap" :width="props.width">
    <template #hoverableElement="{ isTooltipVisible }">
      <!-- Info icon -->
      <span
        :class="{
          'info-icon': true,
          'info-icon--expanded': isTooltipVisible,
          'lodging-checkbox-n-info__info-icon': true,
        }"
      >
        <IconEmbedded class="info-icon__symbol" :size="props.iconSize" :name="props.icon" />
      </span>
      <!-- / Info icon -->
    </template>
    <template #tooltip><slot /> </template>
  </TooltipWrapper>
</template>

<script setup lang="ts">
import { ref } from "vue";

// Types
import { IconName } from "@contracts/IconName";

// Components
import TooltipWrapper from "@components/ui/TooltipWrapper.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

const props = withDefaults(
  defineProps<{
    icon?: IconName;
    width?: number;
    iconSize?: number;
  }>(),
  {
    icon: "info-simple_4",
    width: 200,
    iconSize: 15,
  }
);
</script>

<style scoped lang="scss">
// Info icon ==================================================================
.info-icon {
  min-width: 23px;
  min-height: 23px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 100px;
    position: absolute;
    inset: 0 auto auto 0;
    background: rgba(0, 0, 0, 0.1);
    transition:
      width 0.05s ease-in-out,
      height 0.05s ease-in-out,
      top 0.05s ease-in-out,
      left 0.05s ease-in-out;
  }

  &::after {
    content: "";
    width: calc(100% + 22px);
    height: calc(100% + 22px);
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
  }

  &--expanded {
    &::before {
      width: calc(100% + 6px);
      height: calc(100% + 6px);
      inset: -3px auto auto -3px;
    }
  }

  &__symbol {
    :deep(svg) {
      fill: rgba(0, 0, 0, 0.5);
    }
  }
}
</style>
