<template>
  <div>
    <div class="finished-section">
      <!--PendingCreator is the state up until they submit the collab for review-->
      <NoteWithIcon v-if="showCollabRequirementsNote" class="collab-guidance-notes" color="green" size="small">
        <template #icon>
          <IconEmbedded name="info-simple_4" />
        </template>
        <template #text>
          <div class="collab-guidance-notes__content">
            <h2 class="global-h3">Collab Requirements</h2>
            <ul>
              <li>{{ collab.collabInput.requestedLocationCount }} locations</li>
              <li>{{ requestedAdventureCount }} adventures</li>
              <li>Average ~125 total photos and videos ({{ MinimumPhotosAndVideos }} minimum)</li>
            </ul>
          </div>
        </template>
      </NoteWithIcon>
      <div v-if="collab.collabInput.correctionsStatus === 'PendingCreator'">
        <!--Customer requested corrections-->
        <div v-if="collab.collabInput.correctionsForCreator">
          <h2 class="global-h2">{{ collab.communityName }} Requested Changes</h2>
          <div style="white-space: pre-wrap">"{{ collab.collabInput.correctionsForCreator }}"</div>
          <button @click="showCorrectionsCompleteModal = true" class="ui small primary button" style="margin: 10px 0px">Updates Complete!</button>
        </div>
        <!--Initial Submission-->
        <div v-else-if="showCompleteCollabHeader">
          <h2 class="global-h2">Finished Creating?</h2>
          <p>Once you've selected highlights and created your adventures send them to the destination for final review!</p>
          <SrpButton @click="onClickSubmitCollab" size="small">I'm finished</SrpButton>
          <p class="error-message" v-if="collabCompleteRequirementsNotMetMessage">{{ collabCompleteRequirementsNotMetMessage }}</p>
          <SrpButton v-if="collabCompleteRequirementsNotMetMessage && userProfileStore.isSuperOrSalesUser === true" @click="onClickSubmitCollab(true)" fill="outlined" color="gray" size="tiny"
            >super override</SrpButton
          >
        </div>
        <div v-else>
          <h2 class="global-h2">Summarize your visit!</h2>
        </div>
      </div>
      <div v-else>
        <template v-if="collabIsComplete">
          <h2 class="global-h2">This collab is complete!</h2>
          Nice job, we look forward to more collabs in the future!<br />
          <router-link
            :to="{
              name: 'CollabSummaryPublic',
              params: { communityId: collab.communityId, collabInputId: collab.collabInput.id },
            }"
            target="_blank"
          >
            <SrpButton size="small" style="margin-top: 8px">View the Collab Summary</SrpButton>
          </router-link>

          <div v-if="collab.collabInput?.feedback?.feedbackForCreator?.trim().length > 5" style="margin-top: 25px">
            <h4 class="global-h4">{{ collab.communityName }} provided feedback:</h4>
            <div style="white-space: pre-wrap; margin-top: 5px">"{{ collab.collabInput.feedback.feedbackForCreator.trim() }}"</div>
          </div>
        </template>
        <template v-else>
          <!--Creator has submitted the collab for review-->
          <div v-if="collab.collabInput.collaboration.shrpaReviewStatus === 'RequestedChanges'">
            <!--Shrpa Requested Changes-->
            <h2 class="global-h2">Shrpa Requested Changes</h2>
            See your email for details. Reach out to collabs@shrpa.com with any questions.<br />
            <button @click="showCorrectionsCompleteModal = true" class="ui small primary button" style="margin: 10px 0px">Updates Complete!</button>
          </div>
          <div v-else-if="collab.collabInput.collaboration.shrpaReviewStatus === 'Pending'">
            <!--Shrpa Review-->
            <h2 class="global-h2">Collab submitted!</h2>
            Shrpa is reviewing and will pass the collab on to the destination or contact you if updates are needed.<br />
            Once Shrpa approves the collab, the destination will have 10 days to review before the content is published and payment sent.
          </div>
          <div v-else>
            <!--Destination Review-->
            <h2 v-if="collab.collabInput.correctionsForCreator" class="global-h2">Updates sent!</h2>
            <h2 v-else class="global-h2">Destination Reviewing!</h2>
            {{ collab.communityName }} will review and let you know of any feedback before the adventures are published!
            <div v-if="daysLeftUntilAutoComplete !== null">
              <div v-if="daysLeftUntilAutoComplete > 0">- There are {{ daysLeftUntilAutoComplete }} days remaining to review before the collab will be marked complete.</div>
              <div v-else>- The collab will be automatically be marked complete shortly.</div>
            </div>
          </div>
        </template>
      </div>

      <!-- Feedback/Corrections modal -->
      <SrpModal v-model:isVisible="showCorrectionsCompleteModal">
        <template #header>
          <h2 class="global-h2" v-if="collab.collabInput?.correctionsForCreator || collab.collabInput?.collaboration?.shrpaReviewStatus === 'RequestedChanges'">
            Finished with the requested changes?
          </h2>
          <h2 class="global-h2" v-else>Finished with this collab for {{ collab.communityName || "the destination" }}?</h2>
        </template>
        <template #footer>
          <div class="ui basic button" @click="showCorrectionsCompleteModal = false">Not yet</div>
          <div class="ui primary button" @click="confirmComplete()">Yep!</div>
        </template>
      </SrpModal>
    </div>

    <hr class="divider" />

    <div class="adventures-section">
      <h2 class="global-h2">Adventures</h2>
      <p>Craft {{ requestedAdventureCount }} adventures from your visit</p>
      <div class="adventures-container">
        <ItineraryTile
          v-for="adventure in collabAdventures"
          :key="adventure.id"
          :itinerary="adventure"
          allowShortImage
          hideShareButton
          isCms
          :style="{ width: { mobile: `calc(50% - 20px)`, tablet: `calc(33.3% - 20px)` }[screenSize] || `calc(25% - 20px)` }"
        />
        <template v-if="collabIsComplete === false">
          <template v-if="collabAdventures.length < requestedAdventureCount">
            <AdventureTileCreateLink v-for="i in requestedAdventureCount - collabAdventures.length" :key="i" :customerId="collab.communityId" :collabInputId="collab.collabInput.id" />
          </template>

          <div v-if="collabAdventures.length >= requestedAdventureCount" style="width: 100%; flex-grow: 1">
            <LinkWithIcon
              color="teal"
              isDottedUnderline
              :to="{
                name: 'CmsEditorV2',
                query: {
                  customerId: collab.communityId,
                  collabInputId: collab.collabInput.id,
                },
              }"
              tag="RouterLink"
            >
              <template #icon><IconEmbedded name="plus_2-5" :size="20" /></template>
              <span>Add Adventure</span>
            </LinkWithIcon>
          </div>
        </template>
      </div>
    </div>
    <hr class="divider" />
    <div class="highlights-section">
      <h2 class="global-h2">Highlights</h2>
      <p>Choose your best photos &amp; videos that summarize the collaboration</p>
      <HighlightsSection :padPlaceholders="15" :highlightAssetIds="highlightsState.state === 'success' && highlightsState.data.length ? highlightsState.data : []" />
      <div class="actions"><SrpButton fill="outlined" @click="showHighlightsSelectModal = true">Select Highlights</SrpButton></div>

      <HighlightsSelectModal
        v-model:isVisible="showHighlightsSelectModal"
        :collabInputId="collab.collabInput.id"
        :communityId="collab.communityId"
        :highlightedAssetIds="highlightsState.state === 'success' ? highlightsState.data : []"
        :creatorId="userProfileStore.getViewingUserProfile.sherpaId"
        @complete="onHighlightsSelected"
      ></HighlightsSelectModal>

      <hr class="divider" />
      <div style="margin-bottom: 2em">
        <h2 class="global-h2">Social Post</h2>

        <div style="margin-bottom: 7px">Create one social post and tag the destination within ~10 days of completing your visit.</div>

        <!-- Social handle copy buttons -->
        <ul v-if="Object.keys(allNotEmptySocialFields).length" class="social-handle-copy-buttons" style="margin-bottom: 15px">
          <li class="social-handle-copy-buttons__li" v-for="socialFieldKey in Object.keys(allNotEmptySocialFields)" :key="socialFieldKey">
            <SrpButton size="tiny" fill="outlined" color="gray" @click="copySocialHandleToClipboard(socialFieldKey as keyof SocialMediaFields, allNotEmptySocialFields[socialFieldKey])">
              <template #icon>
                <IconEmbedded
                  :name="
                    {
                      twitterHandle: 'xwitter',
                      instagramHandle: 'instagram',
                      facebookURL: 'facebook',
                      youtubeURL: 'youtube',
                      tikTokHandle: 'tiktok',
                    }[socialFieldKey]
                  "
                />
              </template>
              {{ allNotEmptySocialFields[socialFieldKey].split("/").reverse()[0] }}

              <template #iconRight>
                <IconEmbedded name="copy_2" :size="18" />
              </template>
            </SrpButton>

            <span v-if="whichHandleWasJustCopied === socialFieldKey">Copied!</span>
          </li>
        </ul>
        <!-- / Social handle copy buttons -->

        <ul class="global-ul" style="margin-bottom: 35px">
          <li>Tag the destination and @tryshrpa in your social post.</li>
          <li>If supported, tag the destination as a collaborator.</li>
          <li>If you are creating a reel, use royalty-free music.</li>

          <div style="display: inline">
            Questions?
            <LinkWithIcon isDottedUnderline @click="openSocialPostTips">
              <!--<template #icon>
                <IconEmbedded name="external-link_2" :size="13" />
              </template>-->
              <span>Tips here</span> </LinkWithIcon
            >, or reach out to Shrpa or the destination anytime!
          </div>
        </ul>

        <!-- Give post link form -->
        <div class="give-post-link-form">
          <h5 class="global-h5 give-post-link-form__title">Add a link to your post</h5>

          <div class="give-post-link-form__form">
            <input
              class="global-text-input give-post-link-form__input"
              type="text"
              placeholder="https://instgram.com/example/1234"
              v-model="collab.creatorInput.socialPostLink"
              :disabled="isSavingPostLink || isSavedMsgVisible"
            />

            <span v-if="isSavedMsgVisible" class="global-h5">Saved!</span>
            <SrpButton v-else class="give-post-link-form__btn" @click="saveSocialPostLink" fill="outlined" :isDisabled="isSavingPostLink">
              <template v-if="isSavingPostLink" #icon><LoadingIcon :size="18" /></template>
              Save
            </SrpButton>
          </div>
        </div>
        <!-- / Give post link form -->

        <div v-if="!Object.keys(allNotEmptySocialFields).length" style="margin-top: 12px">
          <b>Note:</b> This destination does not have any social handles set.<br />
          Please message them to confirm if they would like an account tagged in your post.
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getGlobalLink } from "@logic/ExternalLinks";
import { computed, onMounted, ref, inject } from "vue";
import HighlightsSection from "@components/HighlightsSection.vue";
import HighlightsSelectModal from "@components/HighlightsSelectModal.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import { CreatorCollab } from "@contracts/collab";
import { useUserProfileStore } from "@stores/userProfileStore";
import { ItinerarySummaryCms } from "@contracts/itinerary";
import ItineraryTile from "@components/ItineraryTile.vue";
import CollabsRepo from "@repos/CollabsRepo";
import { useLogger } from "@helpers/Logger";
import axios from "axios";
import SrpModal from "@components/ui/SrpModal.vue";
import { getGlobalRemoteLogger } from "@helpers/RemoteLogger";
import { ScreenSize } from "@contracts/screenSize";
import AdventureTileCreateLink from "../../AdventureTileCreateLink.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import CollabStatuses from "@logic/CollabStatuses";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import { SocialMediaFields } from "@contracts/creatorPublicProfileFields";
import LoadingIcon from "@components/ui/LoadingIcon.vue";

// Stores
import { useCollabLocationsStore } from "@stores/collabLocations";
const collabLocationsStore = useCollabLocationsStore();

const logger = useLogger();
const remoteLogger = getGlobalRemoteLogger();
const screenSize = inject("screenSize") as ScreenSize;

const MinimumPhotosAndVideos = 80;
// Need 15-20 to fill two rows if the photos are all portrait.
const MinimumHighlights = 17;

type DataLoadState<T> = { state: "loading" } | { state: "success"; data: T } | { state: "error"; error: unknown };

const props = defineProps<{
  collab: CreatorCollab;
  collabAdventures: ItinerarySummaryCms[];
}>();

const userProfileStore = useUserProfileStore();

const collabIsComplete = computed(() => !!props.collab.collabInput.collaboration.completedDate);
const showCollabRequirementsNote = computed(() => {
  return props.collab.collabInput.correctionsStatus === "PendingCreator" && +props.collab.collabInput.collaboration.status < +CollabStatuses.SentToCommunity;
});

const requestedAdventureCount = computed(() => props.collab.collabInput.requestedAdventureCount);

// collab highlights loading
const showHighlightsSelectModal = ref(false);
const highlightsState = ref<DataLoadState<string[]>>({ state: "loading" });
async function loadCollabHighlights() {
  const highlights = await CollabsRepo.loadHighlights(props.collab.communityId, props.collab.collabInput.id, userProfileStore.getViewingUserProfile.sherpaId);
  highlightsState.value = { state: "success", data: highlights };
}
function onHighlightsSelected() {
  showHighlightsSelectModal.value = false;
  loadCollabHighlights();
}
onMounted(loadCollabHighlights);

// completion and corrections
const showCompleteCollabHeader = computed(() => {
  // "Ready to finish" header if they have at least one adventure
  return props.collabAdventures.length > 0;
});
const uploadedAssetCount = computed(() => {
  const assetCount =
    collabLocationsStore.locations?.reduce((acc, item) => {
      return (item.mediaIds?.length || 0) + acc;
    }, 0) ?? 0;
  return assetCount;
});
const showCorrectionsCompleteModal = ref(false);

// validation and submit
const collabCompleteRequirementsNotMetMessage = ref<string>();
const publishedAdventureCount = computed(() => props.collabAdventures.filter(adv => adv.secondaryStatus === "CollabReview").length);

function onClickSubmitCollab(superOverride = false) {
  let logMessage = "";
  if (superOverride === true) {
    showCorrectionsCompleteModal.value = true;
    logMessage = "Superoverride";
  } else {
    collabCompleteRequirementsNotMetMessage.value = "";
    if (publishedAdventureCount.value < requestedAdventureCount.value) {
      logMessage += `Adv ${publishedAdventureCount.value} of ${requestedAdventureCount.value}, `;
      collabCompleteRequirementsNotMetMessage.value += `Minimum ${requestedAdventureCount.value} adventures are required. (You've published ${publishedAdventureCount.value})\n`;
    }
    if (uploadedAssetCount.value < MinimumPhotosAndVideos) {
      logMessage += `Images ${uploadedAssetCount.value} of ${MinimumPhotosAndVideos}, `;
      collabCompleteRequirementsNotMetMessage.value += `Minimum ${MinimumPhotosAndVideos} photos and videos are required. (You've added ${uploadedAssetCount.value})\n`;
    }

    if (highlightsState.value.state === "success" && highlightsState.value.data.length < MinimumHighlights) {
      logMessage += `Highlights ${highlightsState.value.data.length} of ${MinimumHighlights}, `;
      collabCompleteRequirementsNotMetMessage.value += `Minimum ${MinimumHighlights} highlights are required. (You've selected ${highlightsState.value.data.length})`;
    }
    // Show the modal if there are no errors
    if (!collabCompleteRequirementsNotMetMessage.value) {
      logMessage += "Validation passed";
      showCorrectionsCompleteModal.value = true;
    }
  }
  remoteLogger.info(`Collab Submit: ${logMessage}`, false, false, {
    communityId: props.collab.communityId,
    collabInputId: props.collab.collabInput.id,
    creatorId: userProfileStore.getViewingUserProfile.sherpaId,
    validatePassed: showCorrectionsCompleteModal.value ? "true" : "false",
  });
}

const savingCollab = ref(false);
async function confirmComplete() {
  let isAnUpdate = false;
  if (props.collab.collabInput.correctionsForCreator) isAnUpdate = true;
  logger.info(`confirmComplete for ${props.collab.communityId} isAnUpdate=${isAnUpdate}`);
  savingCollab.value = true;
  // Call the new endpoint and then update the state so we give them a nice message
  await axios.put(`${import.meta.env.VITE_API_URL}/collabs/${props.collab.communityId}/inputs/${props.collab.collabInput.id}/complete?isAnUpdate=${isAnUpdate}`);
  savingCollab.value = false;
  // Note: Ideally we should just use an object returned from the server instead of mutating the state in a similar way to the server.
  // If in the destination review flow, need this status to flip
  props.collab.collabInput.correctionsStatus = "ShrpaReview";
  // If in the Shrpa review flow, need this status to flip
  if (props.collab.collabInput.collaboration.shrpaReviewStatus === "RequestedChanges") props.collab.collabInput.collaboration.shrpaReviewStatus = "Pending";
  showCorrectionsCompleteModal.value = false;
}

// Computed that checks how many days are left of the 10 day deadline since FirstApproveNotification
const daysLeftUntilAutoComplete = computed(() => {
  if (!props.collab?.collabInput?.collaboration?.notifications?.firstApproveNotification) return null;
  let now = Date.now();
  // now += 24 * 60 * 60 * 1000 * 12; // testing adding days
  const timePassed = now - new Date(props.collab.collabInput.collaboration.notifications.firstApproveNotification).getTime();
  const daysPassed = Math.floor(timePassed / (1000 * 60 * 60 * 24));
  return 10 - daysPassed;
});

// All not empty social fields ================================================
const allNotEmptySocialFields = computed<Partial<SocialMediaFields>>(() => {
  if (!props.collab.customerSocialFields) {
    return {};
  }
  const allFields = props.collab.customerSocialFields;
  const notEmptyFields: Partial<SocialMediaFields> = {};

  if (allFields.twitterHandle) notEmptyFields.twitterHandle = allFields.twitterHandle;
  if (allFields.instagramHandle) notEmptyFields.instagramHandle = allFields.instagramHandle;
  if (allFields.facebookURL) notEmptyFields.facebookURL = allFields.facebookURL;
  if (allFields.youtubeURL) notEmptyFields.youtubeURL = allFields.youtubeURL;
  if (allFields.tikTokHandle) notEmptyFields.tikTokHandle = allFields.tikTokHandle;

  return notEmptyFields;
});

// Copy social handle to clipboard ============================================
const whichHandleWasJustCopied = ref<keyof SocialMediaFields | "">("");
let copiedHandleStringTimeout = null;

async function copySocialHandleToClipboard(socialKey: keyof SocialMediaFields, socialHandle: string): Promise<void> {
  clearTimeout(copiedHandleStringTimeout);

  await navigator.clipboard.writeText(socialHandle).then(
    () => console.info("Copied to clipboard: " + socialHandle),
    err => console.error("Failed to copy to clipboard: ", err)
  );

  whichHandleWasJustCopied.value = socialKey;
  copiedHandleStringTimeout = setTimeout(() => (whichHandleWasJustCopied.value = ""), 2000);
}

function openSocialPostTips() {
  window.open(getGlobalLink("CollabSocialPostTips"), "_blank");
}

// Save post link =============================================================
const isSavingPostLink = ref<boolean>(false);
const isSavedMsgVisible = ref<boolean>(false);

async function saveSocialPostLink(): Promise<void> {
  isSavingPostLink.value = true;

  const uri = `${import.meta.env.VITE_API_URL}/collabs/${props.collab.communityId}/inputs/${props.collab.collabInput.id}/creator/${userProfileStore.getViewingUserProfile.sherpaId}/post-link`;
  const config = { headers: { "Content-Type": "application/json" } };

  await axios.put(uri, props.collab.creatorInput.socialPostLink, config);

  isSavedMsgVisible.value = true;
  setTimeout(() => (isSavedMsgVisible.value = false), 2000);

  isSavingPostLink.value = false;
}
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";
@import "@/scss/variables.scss";

// Give post link form ========================================================
.give-post-link-form {
  color: rgba(91, 91, 91, 1);
  font: 14px/18px sans-serif;

  &__title {
    margin-bottom: 3px;
    font-weight: 600;
  }

  &__example-text {
    margin-bottom: 7px;
  }

  &__form {
    gap: 12px;
    display: flex;
    align-items: center;
  }

  &__input {
    max-width: 500px;
  }

  &__btn {
  }
}

// Social handle copy buttons =================================================
.social-handle-copy-buttons {
  gap: 11px;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;

  &__li {
    gap: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

// ============================================================================

.finished-section {
  h2 {
    margin-bottom: 0.5rem;
  }
}
.highlights-section {
  .actions {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
  }
}
.divider {
  margin: 2.5rem 0;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
}
.adventures-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  a {
    margin: 0;
  }
  .empty-adventure-tile {
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}
.collab-guidance-notes {
  margin-bottom: 1rem;
  &__content {
    * {
      // to override NoteWithIcon styling. It looks strange.
      text-wrap: initial !important;
    }
  }
}

.error-message {
  color: $orange-dark;
  font-size: 1.1em;
}

// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
}
</style>
