<template>
  <SrpModal :isVisible="props.isVisible" @close="emit('update:isVisible', false)" size="large" isWithScroll :isClosable="false" maxHeight="calc(100dvh - 200px)">
    <template #content>
      <!-- Rank creators section -->
      <div class="rank-creators-section" style="margin-bottom: 15px">
        <h1 class="global-h1 rank-creators-section__main-title">Rank creators</h1>

        <div class="rank-creators-section__columns-container" ref="domRefColumnsContainer">
          <div class="rank-creators-section__column rank-creators-section__column--ranked">
            <h3 class="global-h3 rank-creators-section__column-title">Ranked</h3>

            <ul class="rank-creators-section__creators-list">
              <template v-for="(creator, index) in rankedCreators" :key="creator.creatorId">
                <li v-if="index > 0" class="rank-creators-section__divider"></li>

                <!-- Creator snippet -->
                <li
                  :class="{
                    'creator-snippet': true,
                    'rank-creators-section__creator-snippet': true,
                  }"
                  :data-drag-id="`${index}-ranked`"
                  :data-index="index"
                  data-is-ranked="true"
                  data-is-available-for-drop="true"
                >
                  <div class="creator-snippet__drag-n-drop-handle-wrap" :data-index="index" data-is-ranked="true">
                    <IconEmbedded class="creator-snippet__drag-n-drop-handle" name="drag-n-drop-handle-3-lines" :size="24" />
                  </div>
                  <AvatarWithFallback class="creator-snippet__avatar" :src="creator.profileImageUri" />
                  <div class="creator-snippet__rank-badge">
                    {{ index + 1 }}
                  </div>
                  <div class="global-h4 creator-snippet__name">{{ creator.name }}</div>
                </li>
                <!-- / Creator snippet -->
              </template>

              <!-- Creator snippet -->
              <li
                :class="{
                  'creator-snippet': true,
                  'creator-snippet--placeholder': true,
                  'rank-creators-section__creator-snippet': true,
                  'rank-creators-section__creator-snippet--placeholder': true,
                }"
                :data-drag-id="`${rankedCreators.length}-ranked-placeholder`"
                :data-index="rankedCreators.length"
                data-is-ranked="true"
                data-is-placeholder="true"
                data-is-available-for-drop="true"
              ></li>
              <!-- / Creator snippet -->
            </ul>
          </div>

          <div class="rank-creators-section__column rank-creators-section__column--unranked">
            <h3 class="global-h3 rank-creators-section__column-title">Unranked</h3>

            <ul class="rank-creators-section__creators-list">
              <template v-for="(creator, index) in unRankedCreators" :key="creator.creatorId">
                <li v-if="index > 0" class="rank-creators-section__divider"></li>

                <!-- Creator snippet -->
                <li
                  :class="{
                    'creator-snippet': true,
                    'rank-creators-section__creator-snippet': true,
                  }"
                  :data-drag-id="`${index}-unranked`"
                  :data-index="index"
                  data-is-ranked="false"
                  :data-is-available-for-drop="[elementDatasetDragged?.isRanked, elementDatasetTouchDragged?.isRanked].includes('true')"
                >
                  <div class="creator-snippet__drag-n-drop-handle-wrap" :data-index="index" data-is-ranked="false">
                    <IconEmbedded class="creator-snippet__drag-n-drop-handle" name="drag-n-drop-handle-3-lines" :size="24" />
                  </div>
                  <AvatarWithFallback class="creator-snippet__avatar" :src="creator.profileImageUri" />
                  <!--<div class="creator-snippet__rank-badge"></div>-->
                  <div class="global-h4 creator-snippet__name">{{ creator.name }}</div>
                </li>
                <!-- / Creator snippet -->
              </template>

              <!-- Creator snippet -->
              <li
                :class="{
                  'creator-snippet': true,
                  'creator-snippet--placeholder': true,
                  'rank-creators-section__creator-snippet': true,
                  'rank-creators-section__creator-snippet--placeholder': true,
                }"
                :data-drag-id="`${unRankedCreators.length}-unranked-placeholder`"
                :data-index="unRankedCreators.length"
                data-is-ranked="false"
                data-is-placeholder="true"
                :data-is-available-for-drop="[elementDatasetDragged?.isRanked, elementDatasetTouchDragged?.isRanked].includes('true')"
              ></li>
              <!-- / Creator snippet -->
            </ul>
          </div>
        </div>
      </div>
      <!-- / Rank creators section -->
    </template>

    <template #footer>
      <SrpButton @click="emit('update:isVisible', false)" fill="outlined" color="gray" style="margin-right: 12px" :isDisabled="isSaving">Cancel</SrpButton>

      <SrpButton @click="saveNewRankings" :isDisabled="isSaving">
        <template #icon><IconEmbedded name="save_2" :size="22" /></template>
        Save
      </SrpButton>
    </template>
  </SrpModal>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, toRef, nextTick, computed, Ref } from "vue";
import lodashCloneDeep from "lodash-es/cloneDeep";

// Components
import SrpModal from "@components/ui/SrpModal.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

// Types
import { CreatorCollabMatch } from "@contracts/collab";
import AvatarWithFallback from "@components/AvatarWithFallback.vue";

// Composables
import { useMouseDragNDrop } from "@composables/useMouseDragNDrop";
import { useTouchDragNDrop } from "@composables/useTouchDragNDrop";
import axios from "axios";

const props = withDefaults(
  defineProps<{
    isVisible: boolean;
    allCreators: Array<CreatorCollabMatch> | null;
    customerId: string;
    collabInputId: string;
  }>(),
  {
    isVisible: false,
    allCreators: () => [],
    customerId: "",
    collabInputId: "",
  }
);

const emit = defineEmits<{
  (e: "update:isVisible", event: boolean): void;
  (e: "save"): void;
}>();

// Ranked and unranked lists ==================================================
const rankedCreators = ref<Array<CreatorCollabMatch>>([]);
const unRankedCreators = ref<Array<CreatorCollabMatch>>([]);

onMounted(resetCreatorLists);

watch(() => props.allCreators, resetCreatorLists, { deep: true });
watch(() => props.isVisible, resetCreatorLists);

function resetCreatorLists() {
  if (!props.allCreators?.length) {
    rankedCreators.value = [];
    unRankedCreators.value = [];
  } else {
    rankedCreators.value = props.allCreators.filter(c => Boolean(c.collabCreatorInput.rank));
    unRankedCreators.value = props.allCreators.filter(c => Boolean(!c.collabCreatorInput.rank));
  }
}

// Save new rankings ==========================================================
const isSaving = ref<boolean>(false);

async function saveNewRankings(): Promise<void> {
  isSaving.value = true;

  const config = { headers: { "Content-Type": "application/json" } };
  let uri = `${import.meta.env.VITE_API_URL}/collabs/${props.customerId}/inputs/${props.collabInputId}/invites/rank`;
  await axios.put(uri, JSON.stringify({ rankedCreatorIds: rankedCreators.value.map(c => c.creatorId) }), config);
  emit("save");

  isSaving.value = false;
  emit("update:isVisible", false);
}

// Handle drag & drop =========================================================
const domRefColumnsContainer = ref<HTMLElement | null>(null);

const { elementDatasetDragged } = useMouseDragNDrop(
  toRef(true),
  computed(() => [rankedCreators.value?.length, unRankedCreators.value?.length, props?.isVisible ? 1 : 0]),
  domRefColumnsContainer,
  ".creator-snippet.rank-creators-section__creator-snippet",
  ".creator-snippet .creator-snippet__drag-n-drop-handle-wrap",
  moveCreatorToOtherColumn
);
const { elementDatasetTouchDragged } = useTouchDragNDrop(
  toRef(true),
  computed(() => [rankedCreators.value?.length, unRankedCreators.value?.length, props?.isVisible ? 1 : 0]),
  domRefColumnsContainer,
  ".creator-snippet.rank-creators-section__creator-snippet",
  ".creator-snippet .creator-snippet__drag-n-drop-handle-wrap",
  moveCreatorToOtherColumn
);

function moveCreatorToOtherColumn(oldIndex, newIndex, elemDatasetDragged, elemDatasetDraggedOver, quarter) {
  if (elemDatasetDragged?.isRanked === "false" && elemDatasetDraggedOver?.isRanked === "false") {
    return; // don't do anything if element is dragged within one UnRanked column
  }

  if (quarter > 2) {
    newIndex += 1;
  }

  const columnFrom: Ref<Array<CreatorCollabMatch>> = elemDatasetDragged?.isRanked === "true" ? rankedCreators : unRankedCreators;
  let columnTo: Ref<Array<CreatorCollabMatch>> = elemDatasetDraggedOver?.isRanked === "true" ? rankedCreators : unRankedCreators;

  // handle special case when user want's to reorder RANKED creators
  if (columnFrom === columnTo) {
    const movedCreatorObj = lodashCloneDeep(rankedCreators.value[oldIndex]);
    let newRankedCreators = [...rankedCreators.value];
    newRankedCreators[oldIndex] = null;
    newRankedCreators = [...newRankedCreators.slice(0, newIndex), movedCreatorObj, ...newRankedCreators.slice(newIndex)];
    rankedCreators.value = newRankedCreators.filter(c => c !== null);
    return;
  }

  const columnToFirstHalf = columnTo.value.slice(0, newIndex);
  const columnToSecondHalf = columnTo.value.slice(newIndex);

  const movedCreatorObj = lodashCloneDeep(elemDatasetDragged?.isRanked === "true" ? rankedCreators.value[oldIndex] : unRankedCreators.value[oldIndex]);

  columnFrom.value = columnFrom.value.filter(c => c.creatorId !== movedCreatorObj.creatorId);
  columnTo.value = [...columnToFirstHalf, movedCreatorObj, ...columnToSecondHalf];
}
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";

// Creator snippet ============================================================
.creator-snippet {
  padding: 11px 0;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;
  user-select: none;

  &::before {
    content: "";
    width: calc(100% + 20px);
    height: 100%;
    border-radius: 5px;
    position: absolute;
    inset: 0 auto auto -10px;
    z-index: -1;
  }

  &:hover::before {
    background: rgba(0, 0, 0, 0.05);
  }

  &--placeholder {
    &::before {
      display: none;
    }
  }

  &[data-is-dragged-over="true"][data-dragged-over-q="1"][data-is-available-for-drop="true"],
  &[data-is-dragged-over="true"][data-dragged-over-q="2"][data-is-available-for-drop="true"],
  &[data-is-dragged-over="true"][data-dragged-over-q="3"][data-is-available-for-drop="true"],
  &[data-is-dragged-over="true"][data-dragged-over-q="4"][data-is-available-for-drop="true"],
  &[data-is-touch-dragged-over="true"][data-touch-dragged-over-q="1"][data-is-available-for-drop="true"],
  &[data-is-touch-dragged-over="true"][data-touch-dragged-over-q="2"][data-is-available-for-drop="true"],
  &[data-is-touch-dragged-over="true"][data-touch-dragged-over-q="3"][data-is-available-for-drop="true"],
  &[data-is-touch-dragged-over="true"][data-touch-dragged-over-q="4"][data-is-available-for-drop="true"] {
    &::after {
      content: "";
      width: 100%;
      height: 3px;
      border-radius: 100px;
      position: absolute;
      inset: -2px auto auto 0;
      background: $brand-color;
    }
  }
  &[data-is-dragged-over="true"][data-dragged-over-q="3"][data-is-available-for-drop="true"],
  &[data-is-dragged-over="true"][data-dragged-over-q="4"][data-is-available-for-drop="true"],
  &[data-is-touch-dragged-over="true"][data-touch-dragged-over-q="3"][data-is-available-for-drop="true"],
  &[data-is-touch-dragged-over="true"][data-touch-dragged-over-q="4"][data-is-available-for-drop="true"] {
    &::after {
      top: auto;
      bottom: -2px;
    }
  }

  &[data-cloned-element-for-photos-section-touch-drag-event] {
    &::before {
      background: rgba(0, 0, 0, 0.05);
    }
  }

  &__drag-n-drop-handle-wrap {
    margin-right: 8px;
    position: relative;

    &:active {
      cursor: none;
    }

    &::before {
      content: "";
      width: calc(100% + 20px);
      height: calc(100% + 20px);
      position: absolute;
      inset: 50% auto auto 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__drag-n-drop-handle {
    pointer-events: none;

    :deep(svg) {
      fill: rgba(0, 0, 0, 0.3);
      transition: fill 0.1s ease-in-out;
    }
  }

  &:hover &__drag-n-drop-handle {
    :deep(svg) {
      fill: rgba(0, 0, 0, 0.6);
    }
  }

  &__avatar {
    width: 40px;
    min-width: 40px;
    margin-right: 9px;
  }

  &__rank-badge {
    width: 23px;
    height: 23px;
    padding-right: 1px;
    margin-right: 7px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgba(0, 0, 0, 1);
    font:
      900 13px/13px "Quicksand",
      sans-serif;
    background: 0 0/100% 100% no-repeat url(/images/rank-badge-bg2.svg);
  }

  &__name {
    font-weight: 500;
  }
}

// Rank creators section ======================================================
.rank-creators-section {
  &__main-title {
    padding-bottom: 15px;
    margin-bottom: 22px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__columns-container {
    display: flex;
    position: relative;
    z-index: 0;
  }

  &__column {
    width: 50%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    &:first-child {
      border-right: 1px rgba(0, 0, 0, 0.1) solid;
    }
  }

  &__column + &__column {
    align-items: flex-end;
  }

  &__column-title {
    width: calc(100% - 21px);
    padding-bottom: 15px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__creators-list {
    width: calc(100% - 21px);
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
  }

  &__creator-snippet {
    width: 100%;
    max-width: 100%;

    &:last-child {
      margin-bottom: 0;
    }

    &--placeholder {
      flex-grow: 1;
      padding: 0;
    }
  }

  &__divider {
    width: 100%;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }
}
</style>
