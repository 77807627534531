<template>
  <div>
    <GMapAutocomplete
      v-if="!hideFormFields && isMapReady"
      :address="stop.title"
      @place_changed="googlePlaceChosen"
      @input="e => placeTextChanged(e.target.value)"
      :value="stop.title"
      :options="{
        fields: ['name', 'place_id', 'address_components', 'formatted_address', 'geometry', 'website'],
      }"
      style="margin-bottom: 0.5em !important"
      :class="{ 'black-text': stop.title }"
    />

    <template v-if="!hideFormFields && showAddress && isMapReady">
      <label class="subsection-title">Address</label>
      <input type="text" v-model="stop.address" style="margin-bottom: 2em" />
    </template>
    <div v-else style="display: flex">
      <div v-if="!hideFormFields" @click="showAddress = true">
        <a class="ui flex location-actions">Show the address</a>
      </div>
      <div style="margin-left: auto">
        <span v-if="markerPin" style="float: right; margin-bottom: 1em">Click to drag the marker</span>
        <span v-else @click="addPin" class="location-actions" style="float: right; margin-right: 5px">Add a marker</span>
      </div>
    </div>

    <GMapMap style="width: 100%; height: 223px" ref="mapRef" :center="center" :options="gMapOptions" :zoom="zoomLevel" @rightclick="mapRightClicked">
      <GMapMarker v-if="markerPin" :position="markerPin.position" :draggable="true" @dragend="markerMoved" />
    </GMapMap>
  </div>
</template>

<script lang="ts">
/* eslint vue/no-mutating-props: 0 */
import { defineComponent, inject } from "vue";
import { ItineraryStep } from "@contracts/itinerary";
import GooglePlaceConverter from "@logic/GooglePlaceConverter";
import { Location } from "@contracts/location";

export default defineComponent({
  name: "GMap",

  props: {
    stop: { type: Object as () => ItineraryStep | null, default: null },
    index: { type: Number, default: 0 },
    locationDefault: { type: Object as () => Location | null, default: null },
    hideFormFields: Boolean,
  },

  emits: ["placeChanged", "updated", "markerManuallyMoved", "mapReady"],

  data() {
    return {
      globalLog: inject("globalLog") as any,
      globalRemoteLogger: inject("globalRemoteLogger") as any,

      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      showAddress: false,

      isMapReady: false,

      gMapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false,
      },
      defaultCenter: { lat: 39.687664, lng: -95.253992 } as globalThis.google.maps.LatLngLiteral, // NE Kansas
      /* Note: Just using the default marker, formerly :icon="markerIcon"
    markerIcon: {
        url: "https://cdn.shrpa.com/images/shrpa-logo-pointy-150.png",
        scaledSize: new google.maps.Size(35, 61),//(30, 53),//(40, 70),
    } as google.maps.Icon,*/
    };
  },

  computed: {
    center(): globalThis.google.maps.LatLngLiteral {
      if (this.stop && this.stop.latitude && this.stop.latitude > 0) {
        // A location is already set
        return {
          lat: this.stop.latitude,
          lng: this.stop.longitude,
        };
      } else if (this.locationDefault) {
        return {
          lat: this.locationDefault.latitude,
          lng: this.locationDefault.longitude,
        };
      } else {
        // Default values in the data are zoomed out to show most of the US
        return this.defaultCenter;
      }
    },
    markerPin(): globalThis.google.maps.Marker {
      if (!this.isMapReady) {
        return null;
      }
      if (this.stop && this.stop.latitude && this.stop.latitude > 0) {
        return new globalThis.google.maps.Marker({
          position: {
            lat: this.stop.latitude,
            lng: this.stop.longitude,
          },
        });
      }
      return null;
    },
    zoomLevel(): number {
      if (this.stop && this.stop.latitude && this.stop.latitude > 0) {
        return 15; // Zoomed in to street level
      } else if (this.locationDefault) {
        return 14;
      }
      return 3; // Zoomed way out
    },
  },

  watch: {
    stop: {
      handler(newVal, oldVal) {
        // console.info("updated! '" + oldVal + "' '" + newVal);
        this.$emit("updated", this.stop, this.index);
      },
      deep: true,
    },
  },

  mounted() {
    (this.$refs.mapRef as any)?.$mapPromise.then(() => {
      this.isMapReady = true;
      this.$emit("mapReady");
    });
  },

  methods: {
    mapRightClicked(location) {
      if (!this.stop.latitude) {
        this.setMarker(location.latLng.lat(), location.latLng.lng());
      }
    },
    addPin() {
      // Default to near the previous stop
      if (this.locationDefault) {
        // Shift this new stop slight to the west of the previous one
        var longitude = this.locationDefault.longitude - 0.002;
        this.setMarker(this.locationDefault.latitude, longitude);
      } else {
        // Fallback to central US
        this.setMarker(this.defaultCenter.lat, this.defaultCenter.lng);
      }
    },
    setMarker(latitude: number, longitude: number) {
      this.globalLog.info(`Setting marker at lat=${latitude} lng=${longitude}`);
      this.stop.latitude = latitude;
      this.stop.longitude = longitude;
      // Emit an event so we can recalculate the maps before saving
      this.$emit("markerManuallyMoved");
    },
    markerMoved(location) {
      this.setMarker(location.latLng.lat(), location.latLng.lng());
    },
    // NOTE! This doesn't catch text changes, just when they pick a result from the auto-complete
    googlePlaceChosen(place: globalThis.google.maps.places.PlaceResult) {
      this.globalLog.info(`googlePlaceChosen: ${place?.name} Id=${place?.place_id}`);
      if (!place?.place_id) {
        this.globalLog.info(`googlePlaceChosen: No place_id found for ${place?.name}`);
        return;
      }
      // Set the fields
      try {
        GooglePlaceConverter.setItineraryFieldsFromPlace(place, this.stop);
        // Need to set the fields first since the code that fires from placeChanged uses that
        this.$emit("placeChanged", this.stop, this.index);
      } catch (ex) {
        this.globalRemoteLogger.error(`Place Autocomplete failure: Input=${this.stop?.title} Name=${place?.name} PlaceId=${place?.place_id} ${ex.message}`);
      }
    },
    // This method handles text changes to the stop title
    placeTextChanged(newTitle) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.globalLog.info("PlaceTextChanged: " + newTitle);
      this.stop.title = newTitle;
    },
  },
});
</script>

<style scoped>
.black-text {
  &::placeholder {
    color: black;
  }

  &:focus::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
}
.location-actions {
  color: #058587;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 1em !important;
}
</style>
