import { FloatingProblemBadgesExpose, ProblemBadge } from "@components/FloatingProblemBadges.vue";
import { inject, Ref, onBeforeUnmount, ref } from "vue";

export function useFloatingProblemBadges() {
  const badgeIdsToRemoveBeforeUnmount = ref<Array<symbol>>([]);

  // Global variables & injections
  const domRefFloatingProblemBadges = inject("domRefFloatingProblemBadges") as Ref<FloatingProblemBadgesExpose>;

  function upsertBadge(problemBadge: ProblemBadge) {
    if (!badgeIdsToRemoveBeforeUnmount.value.includes(problemBadge.id)) {
      badgeIdsToRemoveBeforeUnmount.value.push(problemBadge.id);
    }
    domRefFloatingProblemBadges.value.upsertBadge(problemBadge);
  }

  onBeforeUnmount(() => {
    badgeIdsToRemoveBeforeUnmount.value.forEach(badgeId => domRefFloatingProblemBadges.value.removeBadge(badgeId));
    badgeIdsToRemoveBeforeUnmount.value = [];
  });

  return {
    upsertBadge,
    checkIfBadgeExists: domRefFloatingProblemBadges.value.checkIfBadgeExists,
    removeBadge: domRefFloatingProblemBadges.value.removeBadge,
    removeAllBadges: domRefFloatingProblemBadges.value.removeAllBadges,
  };
}
