<template>
  <div class="video-intro-snippet" @click="isVisibleIntroVideoModal = true">
    <div class="video-intro-snippet__preview">
      <div class="video-intro-snippet__play-button">
        <IconEmbedded name="triangle-right-solid" color="rgba(255, 255, 255, 1)" :size="18" />
      </div>
      <div class="video-intro-snippet__thumbnail-wrap">
        <img class="video-intro-snippet__thumbnail" :src="props.thumbnail" alt="intro video preview" />
      </div>
    </div>
    <div class="video-intro-snippet__text-label">Watch short intro</div>

    <SrpModal v-model:isVisible="isVisibleIntroVideoModal" size="large">
      <template #content>
        <video class="video-player" controls autoplay controlsList="nodownload">
          <source :src="props.videoUrl" type="video/mp4" />
          Your browser does not support HTML video.
        </video>
      </template>
    </SrpModal>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

// Components
import SrpModal from "@components/ui/SrpModal.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export interface Props {
  thumbnail: string;
  videoUrl: string;
}
const props = withDefaults(defineProps<Props>(), {
  thumbnail: "",
  videoUrl: "",
});

// Toggle modal ===============================================================
const isVisibleIntroVideoModal = ref(false);
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Video player ===============================================================
.video-player {
  width: calc(100% + 30px);
  margin: -5px 0 -11px -16px;
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .video-player {
    width: calc(100% + 16px);
    margin: -5px 0 -9px -13px;
  }
}

// Video intro snippet ========================================================
.video-intro-snippet {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  user-select: none;

  &__preview {
    width: 110px;
    height: 75px;
    margin-bottom: 7px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
    box-shadow: 0 3px 9px -1px rgba(0, 0, 0, 0.25);
  }

  &__thumbnail-wrap {
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  &__thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__play-button {
    width: 34px;
    height: 34px;
    padding-left: 2px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: calc(50% - 17px) auto auto calc(50% - 17px);
    z-index: 1;
    color: #fff;
    font-size: 18px;
    line-height: 18px;

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 100px;
      position: absolute;
      inset: 0 auto auto 0;
      z-index: -1;
      background: #118689;
      transition:
        transform 0.07s ease-in-out,
        background 0.07s ease-in-out;
    }
  }

  &:hover &__play-button::after {
    transform: scale(1.15);
    background: #102f41;
  }

  &__text-label {
    color: rgba(#5b5b5b, 0.7);
    font:
      italic 12px/12px "Helvetica Neue",
      sans-serif;
    text-decoration: underline;
  }

  &:hover &__text-label {
    text-decoration: none;
  }
}
</style>
