<template>
  <div class="examples-section">
    <h3 class="global-h3 examples-section__title">
      {{ props.title }}
    </h3>

    <!-- Community & Creator logos -->
    <div v-if="props.collabSummary" class="community-n-creator-logos examples-section__logos">
      <RouterLink class="community-n-creator-logos__logo-wrap community-n-creator-logos__logo-wrap--top" :to="{ name: 'Pages', params: { pageId: props.collabSummary?.page?.id } }" target="_blank">
        <div class="community-n-creator-logos__logo-title community-n-creator-logos__logo-title--bottom-aligned">{{ props.collabSummary?.page?.title }}</div>
        <div class="community-n-creator-logos__logo-image-wrap">
          <img
            class="community-n-creator-logos__logo-image community-n-creator-logos__logo-image--community-logo"
            :src="`${contentBaseUri}/cms/images/orgs/${props.collabSummary?.page?.icon}`"
            alt="Community"
          />
        </div>
      </RouterLink>
      <IconEmbedded class="community-n-creator-logos__plus" name="plus_3" />
      <RouterLink
        :class="{
          'community-n-creator-logos__logo-wrap': true,
          'community-n-creator-logos__logo-wrap--reverse': !['mobile', 'tablet', 'tablet-large'].includes(screenSize),
        }"
        :to="{ name: 'CreatorProfilePublic', params: { creatorId: props.collabSummary?.creator.sherpaId } }"
        target="_blank"
      >
        <div class="community-n-creator-logos__logo-title community-n-creator-logos__logo-title--top-aligned">
          {{ props.collabSummary?.creator.firstName || "&nbsp;" }}
        </div>
        <div class="community-n-creator-logos__logo-image-wrap">
          <img class="community-n-creator-logos__logo-image" :src="props.collabSummary?.creator.profileImageUri" alt="Creator" />
        </div>
      </RouterLink>
    </div>
    <!-- / Community & Creator logos -->

    <ul v-if="props.collabSummary" class="examples-section__itineraries-list">
      <ItineraryTile
        class="examples-section__itinerary"
        v-for="itinerary in props.collabSummary?.itineraries.slice(0, 3)"
        :key="itinerary.id"
        :itinerary="itinerary"
        :allowShortImage="false"
        :openNewWindow="true"
      />
    </ul>
  </div>
</template>

<script setup lang="ts">
import { inject, Ref, onMounted, watch } from "vue";

// Components
import ItineraryTile from "@components/ItineraryTile.vue";

// Types
import { ScreenSize } from "@contracts/screenSize";
import { CollabSummary, SummarySectionName } from "./index.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

// Global variables
const contentBaseUri = globalThis.Bootstrap.Config.contentCdnBaseUri;
const screenSize = inject("screenSize") as Ref<ScreenSize>;

const props = withDefaults(
  defineProps<{
    title: SummarySectionName | null;
    collabSummary: CollabSummary | null;
  }>(),
  {
    title: null,
    collabSummary: null,
  }
);
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Community & Creator logos ==================================================
.community-n-creator-logos {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;

  &__logo-wrap {
    margin: 0 auto -50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:last-child {
      margin: 0 0 0 auto;
      z-index: -1;
    }

    &--reverse {
      flex-direction: column-reverse;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__logo-title {
    width: 142px;
    height: 16px;
    margin: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    line-height: 15px;
    font-weight: 600;
    text-align: center;

    &:last-child {
      margin-bottom: 0;
    }

    &--top-aligned {
      align-items: flex-start;
    }

    &--bottom-aligned {
      align-items: flex-end;
    }
  }

  &__logo-image-wrap {
    width: 90px;
    height: 90px;
    margin: 5px 0;
    box-sizing: border-box;
    border: 1px #c5c5c5 solid;
    border-radius: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    background: #fff;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__logo-image {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &--community-logo {
      width: 70%;
      height: 70%;
      object-fit: contain;
    }
  }

  &__plus {
    width: 30px;
    min-width: 30px;
    height: 30px;
    border-radius: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: calc(50% - 5px) auto auto calc(50% - 5px);
    z-index: 2;
    color: #fff;
    font-size: 22px;
    line-height: 22px;
    background: #118689;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .community-n-creator-logos {
    display: flex;
    flex-direction: row;
    justify-content: center;

    &__logo-wrap {
      margin: 0 !important;
    }

    &__logo-image-wrap {
      width: 100px;
      height: 100px;
    }

    &__plus {
      margin-top: 65px;
      position: static;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .community-n-creator-logos {
    display: flex;
    flex-direction: row;
    justify-content: center;

    &__logo-wrap {
      margin: 0 !important;
    }

    &__logo-image-wrap {
      width: 100px;
      height: 100px;
    }

    &__plus {
      margin-top: 65px;
      position: static;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .community-n-creator-logos {
    display: flex;
    flex-direction: row;
    justify-content: center;

    &__logo-wrap {
      margin: 0 !important;
    }

    &__logo-title {
      width: 110px;
      white-space: normal;
    }

    &__logo-image-wrap {
      width: 100px;
      height: 100px;
    }

    &__plus {
      margin-top: 65px;
      position: static;
    }
  }
}

// Examples section ===========================================================
.examples-section {
  padding-bottom: 40px;
  border-bottom: 1px rgba(0, 0, 0, 0.15) solid;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &--no-bottom-border {
    padding-bottom: 0 !important;
    border-bottom: none;
  }

  &__title {
    width: 100%;
    margin-bottom: 25px;
  }

  &__logos {
    width: 180px;
    margin-right: 10px;
  }

  &__itineraries-list {
    width: calc(100% - 190px);
    padding: 0;
    margin: 0;
    display: flex;
    list-style: none;
  }

  &__itinerary {
    margin: 0 15px 0 0 !important;
    min-width: calc(100% / 3 - 15px) !important;

    &:last-child {
      margin-right: 0 !important;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .examples-section {
    flex-direction: column;

    &__title {
      text-align: center;
    }

    &__logos {
      margin-bottom: 25px;
    }

    &__itineraries-list {
      width: 100%;
    }

    &__itinerary {
      width: calc(33.3% - 15px);
      margin: 0 8px 15px;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .examples-section {
    padding-bottom: 25px;
    flex-direction: column;

    &__title {
      text-align: center;
    }

    &__logos {
      margin-bottom: 25px;
    }

    &__itineraries-list {
      width: 100%;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__itinerary {
      width: calc(33.3% - 16px) !important;
      min-width: calc(33.3% - 16px) !important;
      margin: 0 8px 15px !important;

      &:last-child {
        margin: 0 8px 15px !important;
      }
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .examples-section {
    padding-bottom: 20px;
    flex-direction: column;

    &__title {
      text-align: center;
    }

    &__logos {
      margin-bottom: 25px;
    }

    &__itineraries-list {
      width: calc(100% + 16px) !important;
      margin-left: -8px;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__itinerary {
      width: calc(50% - 16px);
      min-width: calc(50% - 16px);
      margin: 0 8px 15px !important;

      &:last-child {
        margin: 0 8px 15px !important;
      }
    }
  }
}
</style>
