<template>
  <div class="feedback-slide">
    <h1>You have selected the following creator{{ selectedCreatorIds.length === 1 ? "" : "s" }}:</h1>

    <!-- Selected creators list -->
    <div class="selected-creators-list feedback-slide__selected-creators-list">
      <ul class="selected-creators-list__list-itself">
        <!-- Selected creator -->
        <li class="selected-creator selected-creators-list__selected-creator" v-for="creator in selectedCreators" :key="creator.creatorId">
          <div class="selected-creator__avatar-wrap">
            <img class="selected-creator__avatar-img" :src="creator.profileImageUri" alt="" />
          </div>

          <div class="global-h3 selected-creator__name">
            {{ creator.name }}
          </div>
        </li>
        <!-- / Selected creator -->
      </ul>
    </div>
    <!-- / Selected creators list -->

    <NoteWithIcon v-if="isMoreCreatorsChosenThanCollabRemaining" color="red" style="margin-bottom: 35px">
      <template #icon><IconEmbedded name="info-simple_4" /></template>
      <template #text>
        <h4 class="global-h4">Need more Creator Visits?</h4>
        <div style="margin-top: 5px">
          You've chosen more creators than visits remaining in your subscription.<br />
          <b>Contact <CopyText :text="'collabs@shrpa.com'" /> and we'll help get you the content you need!</b>
        </div>
      </template>
    </NoteWithIcon>

    <NoteWithIcon v-else-if="selectedCreatorIds.length > 1" color="green" style="margin-bottom: 35px">
      <template #icon><IconEmbedded name="info-simple_4" /></template>
      <template #text>
        <h4 class="global-h4">What selecting multiple creators means:</h4>
        <div>
          You will use {{ selectedCreatorIds.length }} out of {{ props.collabsRemaining }} remaining creator visits.<br />
          A separate creator visit will automatically be created for each creator chosen.<br />
          Each visit can then be coordinated separately with the creator.<br />
        </div>
      </template>
    </NoteWithIcon>

    <div style="width: 100%; height: 1px; margin-bottom: 24px; background: rgba(0, 0, 0, 0.1)"></div>

    <!-- Feedback form -->
    <div class="feedback-form" style="margin-bottom: 18px">
      <div class="feedback-form__header">
        <h2 class="global-h2 feedback-form__title" style="margin-bottom: 3px">Help us improve our creator network</h2>
        <div class="feedback-form__subtitle">Any feedback you provide helps us understand where we can improve!</div>
      </div>

      <!-- Form section -->
      <div class="form-section feedback-form__form-section">
        <h4 class="global-h4 form-section__title">Satisfied with the number of creators to choose from?</h4>

        <StarRatingScale
          class="form-section__form-element"
          radioInputsName="numberOfCreators"
          :value="props.creatorSelectionFeedback.numberOfCreators"
          @update:value="
            newValue => {
              isWarningVisible = false;
              emit('update:creatorSelectionFeedback', { ...props.creatorSelectionFeedback, numberOfCreators: newValue });
            }
          "
        />
      </div>
      <!-- / Form section -->

      <!-- Form section -->
      <div class="form-section feedback-form__form-section">
        <h4 class="global-h4 form-section__title">How about the quality of those creators?</h4>

        <StarRatingScale
          class="form-section__form-element"
          radioInputsName="qualityOfCreators"
          :value="props.creatorSelectionFeedback.qualityOfCreators"
          @update:value="
            newValue => {
              isWarningVisible = false;
              emit('update:creatorSelectionFeedback', { ...props.creatorSelectionFeedback, qualityOfCreators: newValue });
            }
          "
        />
      </div>
      <!-- / Form section -->

      <!-- Form section -->
      <div class="form-section feedback-form__form-section">
        <h4 class="global-h4 form-section__title form-section__title--bigger-bottom-margin">Any additional detail? <span style="color: #a5a5a5">(optional)</span></h4>
        <textarea
          class="global-textarea form-section__form-element"
          style="height: 90px"
          :value="props.creatorSelectionFeedback.additionalDetails"
          @change="$event => emit('update:creatorSelectionFeedback', { ...props.creatorSelectionFeedback, additionalDetails: ($event.target as HTMLTextAreaElement).value })"
          @focus="isWarningVisible = false"
        ></textarea>
      </div>
      <!-- / Form section -->

      <!-- Errors list -->
      <div v-if="isWarningVisible" class="errors-list feedback-form__errors-list">
        <h4 class="global-h4 errors-list__title">Please specify some feedback. Thank you!</h4>
      </div>
      <!-- / Errors list -->
    </div>
    <!-- / Feedback form -->
  </div>
</template>

<script setup lang="ts">
import { computed, toRef, ref, onMounted } from "vue";

// Components
import CopyText from "@components/CopyText.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import StarRatingScale from "@components/ui/StarRatingScale.vue";

// Types
import { CreatorCollabMatch, CollabCreatorSelectionFeedback } from "@contracts/collab";

// Stores
import { useInvitedCreatorsStore } from "@stores/invitedCreators";
const invitedCreatorsStore = useInvitedCreatorsStore();

const props = withDefaults(
  defineProps<{
    selectedCreatorIds: Array<string>;
    collabsRemaining: number;
    chooseCreatorIsSaving: boolean;
    communityId: string;
    collabInputId: string;
    creatorSelectionFeedback: CollabCreatorSelectionFeedback;
  }>(),
  {
    selectedCreatorIds: () => [],
    collabsRemaining: 0,
    chooseCreatorIsSaving: false,
    communityId: "",
    collabInputId: "",
    creatorSelectionFeedback: null,
  }
);

const emit = defineEmits<{
  (e: "onMounted"): void;
  (e: "update:creatorSelectionFeedback", value: CollabCreatorSelectionFeedback): void;
}>();

// Selected creators ==========================================================
const selectedCreators = computed<Array<CreatorCollabMatch>>(() => {
  const invitedCreators = invitedCreatorsStore.getCreatorsList(props.communityId, props.collabInputId)?.filter(c => c.creatorId);
  return invitedCreators?.filter(c => props.selectedCreatorIds.includes(c.creatorId));
});

// Toggle warning if needed ===================================================
const isWarningVisible = ref<boolean>(false);

const isFormValid = computed<boolean>(() => {
  const number = props.creatorSelectionFeedback.numberOfCreators;
  const quality = props.creatorSelectionFeedback.qualityOfCreators;
  const haveEnoughCreatorVisits = !isMoreCreatorsChosenThanCollabRemaining.value;

  return Boolean(number && quality) && haveEnoughCreatorVisits;
});

// Is more creators chosen than collabs remaining =============================
const isMoreCreatorsChosenThanCollabRemaining = computed<boolean>(() => {
  // Note: 1 needs to be substracted since the collab currently showing is already reduced from collabsRemaining
  // because a new collab will be created for each selected creator
  return props.selectedCreatorIds.length - 1 > props.collabsRemaining;
});

// Inform the parent component that this component is shown ===================
onMounted(() => emit("onMounted"));

// Define expose ==============================================================
defineExpose({
  isFormValid,
  showWarning: () => (isWarningVisible.value = true),
});
</script>

<style scoped lang="scss">
// Errors list ================================================================
.errors-list {
  color: firebrick;

  &__title {
    margin-bottom: 4px;
    color: firebrick;
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__li {
    margin-bottom: 5px;
    font: 14px/18px sans-serif;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// Form section ===============================================================
.form-section {
  &__title {
    margin-bottom: 5px;
    font-weight: 500;

    &--bigger-bottom-margin {
      margin-bottom: 10px;
    }
  }

  &__form-element {
  }
}

// Feedback form ==============================================================
.feedback-form {
  &__header {
    margin-bottom: 21px;
  }

  &__title {
  }

  &__subtitle {
    color: #5b5b5b;
    font-family: sans-serif;
  }

  &__form-section {
    margin-bottom: 19px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__errors-list {
    margin-bottom: 20px;
  }
}

// Selected creator ===========================================================
.selected-creator {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;

  &__avatar-wrap {
    width: 44px;
    min-width: 44px;
    height: 44px;
    margin-right: 12px;
    border-radius: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    background: rgba(221, 221, 221, 1);
  }

  &__avatar-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__name {
    font-weight: 600;
    line-height: 100%;
  }
}

// Selected creators list =====================================================
.selected-creators-list {
  position: relative;
  overflow: hidden;

  &__list-itself {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  &__selected-creator {
    margin: 0 22px 14px 0;
  }
}

// Feedback slide =============================================================
.feedback-slide {
  &__h1 {
    margin-bottom: 10px;
    position: absolute;
    inset: 0 0 auto auto;
    transform: translate(-10px, -10px);
  }

  &__selected-creators-list {
  }

  &__feedback-form {
  }
}
</style>
