<template>
  <div class="referrals-table">
    <table class="referrals-table__table-itself">
      <thead>
        <tr>
          <td class="referrals-table__thead-td">Name</td>
          <!--<td class="referrals-table__thead-td">Email</td>-->
          <td class="referrals-table__thead-td">Status</td>
        </tr>
      </thead>

      <tbody>
        <tr v-for="referral in props.referralsList" :key="referral.email">
          <td class="referrals-table__tbody-td">
            <!-- Avatar & name snippet -->
            <RouterLink class="avatar-n-name-snippet referrals-table__avatar-n-name-snippet" :to="{ name: 'CreatorProfilePublic', params: { creatorId: referral.creatorId } }" target="_blank">
              <AvatarWithFallback class="avatar-n-name-snippet__avatar" :src="`${contentBaseUri}/cms/images/profiles/${referral.profilePhoto}`" />
              <div class="global-h4 avatar-n-name-snippet__name">
                {{ referral.name }}
              </div>
            </RouterLink>
            <!-- / Avatar & name snippet -->
          </td>
          <!--<td class="referrals-table__tbody-td referrals-table__tbody-td--email">
            {{ referral.email }}
          </td>-->
          <td class="referrals-table__tbody-td">
            <!-- Status snippet -->
            <div class="status-snippet referrals-table__status-snippet">
              <!--<IconEmbedded v-if="referral.status === 'BecomingPaid'" class="status-snippet__icon" name="flag_3" :size="16" color="rgba(0, 0, 0, 0.5)" />-->
              <!--<IconEmbedded v-if="referral.status === 'NotApplied'" class="status-snippet__icon" name="hourglass_3" :size="14" color="rgba(173, 158, 19, 1)" style="margin-right: 3px" />-->
              <!--<IconEmbedded v-if="referral.status === 'Applied'" class="status-snippet__icon" name="check_4" :size="17" color="rgba(78, 153, 90, 1)" />-->
              <div
                :class="{
                  'status-snippet__text': true,
                  // 'status-snippet__text--orange': referral.status === 'NotApplied',
                  // 'status-snippet__text--green': referral.status === 'Applied',
                }"
              >
                {{ Statuses[referral.status] }}
              </div>
            </div>
            <!-- / Status snippet -->
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
// Components
import AvatarWithFallback from "@components/AvatarWithFallback.vue";

// Types
import { ReferralUsers } from "@contracts/referrals";

const contentBaseUri = globalThis.Bootstrap.Config.contentCdnBaseUri;

const props = withDefaults(
  defineProps<{
    referralsList: Array<ReferralUsers>;
  }>(),
  {
    referralsList: () => [],
  }
);

enum Statuses {
  BecomingPaid = "Creating Profile",
  NotApplied = "Hasn't Applied to Collabs",
  Applied = "Applied to Collabs!",
  Paid = "Referral Paid",
}
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Avatar & name snippet ======================================================
.avatar-n-name-snippet {
  display: flex;
  align-items: center;

  &__avatar {
    width: 42px;
    min-width: 42px;
    height: 42px;
    margin-right: 17px;
  }

  &__name {
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.2);
    text-underline-offset: 2px;
    text-decoration-thickness: 1px;
    text-decoration-style: solid;

    &:hover {
      text-decoration: none;
    }
  }
}

// Status snippet =============================================================
.status-snippet {
  display: flex;
  align-items: center;

  &__icon {
    margin-right: 2px;
  }

  &__text {
    color: rgba(91, 91, 91, 1);
    font-weight: bold;
    font-family: sans-serif;

    &--orange {
      color: rgba(173, 158, 19, 1);
    }

    &--green {
      color: rgba(78, 153, 90, 1);
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .status-snippet {
    &__text {
      white-space: nowrap;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .status-snippet {
    &__text {
      white-space: nowrap;
    }
  }
}

// Referrals table ============================================================
.referrals-table {
  border: 1px rgba(0, 0, 0, 0.1) solid;
  border-radius: 6px;
  position: relative;
  overflow: hidden;

  &__table-itself {
    width: calc(100% + 2px);
    margin: -1px;
    border-spacing: 0;
    border-collapse: collapse;
  }

  &__thead-td {
    padding: 13px 20px 7px;
    border: 1px rgba(0, 0, 0, 0.1) solid;
    font-weight: 800;
  }

  &__tbody-td {
    padding: 12px 20px;
    border: 1px rgba(0, 0, 0, 0.1) solid;

    &--email {
      font:
        400 19px/21px "Quicksand",
        sans-serif;
    }
  }

  &__avatar-n-name-snippet {
  }

  &__status-snippet {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .referrals-table {
    overflow-x: scroll;
    overflow-y: hidden;
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .referrals-table {
    overflow-x: scroll;
    overflow-y: hidden;
  }
}
</style>
