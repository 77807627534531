<template>
  <div style="overflow-x: auto; width: 100%">
    <div v-if="isLoading"><Loader /></div>
    <div v-else>
      <h2>Adventure Interaction Summary</h2>
      <VueGoodTable
        :columns="getColumns()"
        :rows="metricsData"
        compactMode
        :sort-options="{
          enabled: true,
          initialSortBy: { field: 'firstPublished', type: 'desc' },
        }"
      >
        <template #table-row="props">
          <span v-if="props.column.field == 'itinerary.id'">
            <router-link
              lang="en"
              hreflang="en"
              :to="{ name: 'ItineraryPreview', params: { itineraryId: props.row.itinerary.uniqueName ?? props.row.itinerary.id } }"
              target="_blank"
              style="text-decoration: underline"
            >
              {{ props.row.itinerary.title }}
            </router-link>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </VueGoodTable>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import axios from "axios";
import { VueGoodTable } from "vue-good-table-next";
import Loader from "@components/Loader/Loader.vue";
import { UserProfile } from "@contracts/userProfile";
import { RouteHelper } from "@helpers/RouteHelper";
import { mapState } from "pinia";
import { useUserProfileStore } from "@stores/userProfileStore";
import { useHead } from "@unhead/vue";

// Show total interaction and metrics count?
// Show ratios??
// Eventually we want navigation for both "see the itinerary" and "see time-based metrics"

export default defineComponent({
  name: "CommunityAdventureMetrics",

  components: {
    Loader,
    VueGoodTable,
  },

  data() {
    return {
      globalLog: inject("globalLog") as any,

      title: "Community Metrics",
      isLoading: true,
      metricsData: null,
      columns: [
        { label: "Adventure", field: "itinerary.id" },
        {
          label: "Impressions",
          field: "totalImpressionCount",
          type: "number",
          // @ts-ignore
          formatFn: this.formatNumber,
        },
        {
          label: "Interactions",
          field: "totalInteractionCount",
          type: "number",
          // @ts-ignore
          formatFn: this.formatNumber,
        },
        {
          label: "Days on Site",
          field: "daysSincePublished",
          type: "number",
          // @ts-ignore
          formatFn: this.formatNumber,
        },
        {
          label: "Published",
          field: "firstPublished",
          hidden: true,
          // @ts-ignore
          formatFn: this.showDatePart,
        },
        {
          label: "Metrics start",
          field: "metricStartDate",
          hidden: true,
          // @ts-ignore
          formatFn: this.showDatePart,
        },
        {
          label: "Impression latest non-zero",
          field: "lastNonZeroImpressionMetricDate",
          hidden: true,
          // @ts-ignore
          formatFn: this.showDatePart,
        },
        {
          label: "Interaction latest non-zero",
          field: "lastNonZeroInteractionMetricDate",
          hidden: true,
          // @ts-ignore
          formatFn: this.showDatePart,
        },
      ],
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["isSuperOrSalesUser"]),
  },
  async mounted() {
    useHead({ title: () => this.title ?? "" });

    var customerId = this.$route.params.pageId;
    // @ts-ignore
    await this.getMetrics(customerId);
  },

  methods: {
    async getMetrics(customerId: string) {
      const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/metrics/customers/${customerId}`);
      this.metricsData = data;
      this.isLoading = false;
    },
    getColumns() {
      // Flip the fields to not hidden if you're a superuser and have a query param
      if (RouteHelper.getQueryStringParam("debug")) {
        if (this.isSuperOrSalesUser) {
          this.globalLog.info("Showing all columns");
          this.columns.forEach(column => {
            column.hidden = false;
          });
        }
      }
      return this.columns;
    },
    showDatePart(input) {
      // Just remove the time portion
      if (input && input.length > 0) {
        return input.split("T")[0];
      }
      return null;
    },
    formatNumber(input) {
      if (input) {
        return input.toLocaleString();
      }
      return null;
    },
  },
});
</script>
