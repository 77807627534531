import { appInsights } from "@logic/AppInsights";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useIntervalFn } from "@vueuse/core";

export interface BaseToast {
  id: number;
  type: string;
  message: string;
  details?: string;
  expiresAtEpochMs: number | null;
  link?: string;
}
export interface ErrorToast extends BaseToast {
  type: "error";
  stack?: string;
  traceId?: string;
  uri?: string;
}
export interface SuccessToast extends BaseToast {
  type: "success";
}
export interface WarningToast extends BaseToast {
  type: "warning";
}

// Certain error scenarios can really spam the user with toasts, so we limit how many we show at once
const MaxToastsToShowAtOnce = 15;

export type Toast = ErrorToast | SuccessToast | WarningToast;

export const useToastsStore = defineStore("toastsList", () => {
  const _nextId = ref(0);
  const toasts = ref<Toast[]>([]);

  function error(body: { message: string; details?: string; stack?: string; expiresInMs?: number; link?: string }) {
    const traceId = appInsights.getTraceCtx()?.getTraceId();
    const uri = window.location.href;
    const expiresAt = body.expiresInMs == null ? null : Date.now() + (body.expiresInMs ?? 5000);
    addRow({
      id: _nextId.value,
      type: "error",
      message: body.message,
      details: body.details,
      uri: uri,
      traceId: traceId ?? undefined,
      expiresAtEpochMs: expiresAt,
      stack: body.stack,
      link: body.link,
    });
  }

  function warn(body: { message: string; details?: string; expiresInMs?: number; link?: string }) {
    const expiresAt = body.expiresInMs == null ? null : Date.now() + (body.expiresInMs ?? 5000);
    addRow({ id: _nextId.value, type: "warning", message: body.message, details: body.details, expiresAtEpochMs: expiresAt, link: body.link });
  }

  function success(body: { message: string; details?: string; expiresInMs?: number; neverExpires?: boolean; link?: string }) {
    const expiresAt = body.neverExpires ? null : Date.now() + (body.expiresInMs ?? 5000);
    addRow({ id: _nextId.value, type: "success", message: body.message, details: body.details, expiresAtEpochMs: expiresAt, link: body.link });
  }

  function addRow(toast: Toast) {
    // Limit how many we show
    if (toasts.value.length >= MaxToastsToShowAtOnce) {
      console.warn(`Too many toasts, supressing new: ${toast.message}`);
      return;
    }
    // add to the beginning of the array so that the newest toast is at the top
    toasts.value.unshift(toast);
    _nextId.value += 1;
  }

  // remove expired toasts
  useIntervalFn(removeExpiredToasts, 700);
  function removeExpiredToasts() {
    // This runs quite a bit so make sure it's efficient (and shortcut when possible)
    if (!toasts?.value || toasts.value.length === 0) return;
    const now = Date.now();
    toasts.value = toasts.value.filter(toast => toast.expiresAtEpochMs == null || toast.expiresAtEpochMs > now);
  }

  function remove(toastId: number) {
    const index = toasts.value.findIndex(toast => toast.id === toastId);
    if (index === -1) return;
    toasts.value.splice(index, 1);
  }

  function removeAll() {
    toasts.value = [];
  }

  return { toasts, error, warn, success, remove, removeAll };
});
