import axios from "axios";
import { ref } from "vue";
import { defineStore } from "pinia";

// Types
import { CollabSuggestion, CollabGenerateInputPayload } from "@contracts/collabSuggestions";

export const useCollabSuggestionsStore = defineStore("collabSuggestions", () => {
  const isLoading = ref<boolean>(false);

  // Get a collab suggestion ==================================================
  async function getCollabSuggestion(customerId: string, collabSuggestionId: string): Promise<CollabSuggestion> {
    const uri = `${import.meta.env.VITE_API_URL}/collab-suggestions/${customerId}/suggestions/${collabSuggestionId}`;

    isLoading.value = true;
    const { data } = await axios.get<CollabSuggestion>(uri);
    isLoading.value = false;

    return data;
  }

  const isGenerating = ref<boolean>(false);
  // Generate collab suggestions =============================================
  async function generateCollabSuggestions(pageId: string, payload: CollabGenerateInputPayload, countToGenerate: number): Promise<Array<CollabSuggestion>> {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append("resultCount", String(countToGenerate));

    const config = { headers: { "Content-Type": "application/json" } };
    const uri = `${import.meta.env.VITE_API_URL}/collab-suggestions/${pageId}/generate?${urlSearchParams.toString()}`;

    isGenerating.value = true;
    const { data } = await axios.post<Array<CollabSuggestion>>(uri, JSON.stringify(payload), config);
    isGenerating.value = false;

    return data;
  }

  return {
    isLoading,
    getCollabSuggestion,
    isGenerating,
    generateCollabSuggestions,
  };
});
