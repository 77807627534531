<template>
  <div
    class="srp-toast"
    :class="{
      'srp-toast__error': toast.type === 'error',
      'srp-toast__success': toast.type === 'success',
      'srp-toast__warning': toast.type === 'warning',
    }"
  >
    <div class="toast-body">
      <h2 class="message">{{ toast.message }}</h2>
      <p v-if="toast.details" class="details">{{ toast.details }}</p>
      <a v-if="toast.link" :href="toast.link" target="_blank" rel="noopener noreferrer" class="toast-link">{{ toast.link }}</a>
      <template v-if="toast.type === 'error'">
        <p v-if="toast.stack" class="stack">{{ toast.stack }}</p>
        <p v-if="toast.traceId" class="trace">
          Issue ID: <CopyTextNew :showText="false" :text="`IssueId: ${toast.traceId} at ${toast.uri}`" color="white"></CopyTextNew>
          <br />
          {{ toast.traceId }}
        </p>
      </template>
    </div>

    <SrpButton class="dismiss-btn" @click="emit('click:delete')" :color="toast.type === 'warning' ? 'gray' : 'white'" size="tiny" fill="text" shape="rounded">
      <template #icon><IconEmbedded name="remove_3" /></template>
    </SrpButton>
  </div>
</template>

<script setup lang="ts">
import CopyTextNew from "@components/CopyTextNew.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import type { Toast } from "@stores/toasts";
defineProps<{
  toast: Toast;
}>();
const emit = defineEmits<{
  (e: "click:delete"): void;
}>();
</script>

<style scoped lang="scss">
.srp-toast {
  padding: var(--spacing-4);
  border-radius: var(--border-radius);
  position: relative;
  &__error {
    background-color: var(--color-error);
    color: var(--color-error-content);
  }
  &__success {
    background-color: var(--color-success);
    color: var(--color-success-content);
  }
  &__warning {
    background-color: var(--color-warning);
    color: var(--color-warning-content);
  }
  .toast-body {
    margin-right: var(--spacing-8);
    white-space: pre-wrap;
    .message {
      font-size: var(--font-size-lg);
      font-weight: var(--font-weight-bold);
    }
    .details {
      white-space: pre-line;
      margin-top: var(--spacing-2);
    }
    .trace {
      font-size: var(--font-size-sm);
    }
    .stack {
      font-size: var(--font-size-sm);
      overflow-x: auto;
      font-family: monospace;
    }
    .toast-link {
      display: inline-block;
      color: inherit;
      text-decoration: underline;
      font-weight: var(--font-weight-bold);

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .dismiss-btn {
    position: absolute;
    top: var(--spacing-4);
    right: 5px;
  }
}
</style>
