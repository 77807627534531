<template>
  <div class="photos-padding-selector">
    <div class="photos-padding-selector__icons-row">
      <IconEmbedded class="photos-padding-selector__icon" name="image_1" :size="56" color="rgba(0, 0, 0, 0.5)" :style="{ marginRight: `${(props.value / 10) * 3 - 15}px` }" />
      <IconEmbedded class="photos-padding-selector__icon" name="image_1" :size="56" color="rgba(0, 0, 0, 0.5)" :style="{ marginRight: `${(props.value / 10) * 3 - 15}px` }" />
      <IconEmbedded class="photos-padding-selector__icon" name="image_1" :size="56" color="rgba(0, 0, 0, 0.5)" />
    </div>

    <div class="slider-container photos-padding-selector__slider">
      <VueSlider
        class="slider-container__slider"
        v-model="_value"
        tooltip="always"
        :data="[0, 10, 20, 30, 40]"
        data-value="id"
        data-label="name"
        :tooltip-style="{ display: 'none' }"
        adsorb
        :contained="true"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

const props = withDefaults(
  defineProps<{
    value: number;
  }>(),
  {
    value: 0,
  }
);

const emit = defineEmits<{
  (e: "update:value", value: number): void;
}>();

// Local value ================================================================
const _value = computed({
  get() {
    return props.value;
  },
  set(newValue) {
    emit("update:value", newValue);
  },
});
</script>

<style scoped lang="scss">
// Photos padding selector ====================================================
.photos-padding-selector {
  &__icons-row {
    margin: 0 0 -25px -7px;
    display: flex;
    align-items: center;
  }

  &__icon {
  }

  &__slider {
    width: 150px;
    max-width: 150px;

    :deep(.vue-slider-mark-label) {
      display: none;
    }
  }
}

// Slider container ===========================================================
.slider-container {
  width: 250px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &__slider {
    width: 250px;

    :deep(.vue-slider-dot-handle-focus) {
      outline: 4px #fff solid;
      outline-offset: -1px;
      box-shadow: none;
      box-shadow: 0 1px 3px 5px rgba(0, 0, 0, 0.2);
    }
    :deep(.vue-slider-process) {
      height: 6px;
      background: transparent;
    }
    :deep(.vue-slider-process::before) {
      content: "";
      width: calc(100% + 14px);
      height: 100%;
      border-radius: 100px;
      position: absolute;
      inset: 0 auto auto -7px;
      background: rgba(21, 118, 124, 1);
    }
    :deep(.vue-slider-rail) {
    }
    :deep(.vue-slider-dot-tooltip-inner-top) {
      font-weight: bold;
      font-family: sans-serif;
      background: black;
    }
    :deep(.vue-slider-dot-tooltip-inner-top:after) {
      border-top-color: black;
    }
    :deep(.vue-slider-mark-step) {
      background: transparent;
    }
    :deep(.vue-slider-mark-label) {
      top: -38px;
      color: rgba(0, 0, 0, 0.4);
      font-family: sans-serif;
    }
    :deep(.vue-slider-mark) {
      opacity: 0;
    }
    :deep(.vue-slider-mark:first-child) {
      opacity: 1;
    }
    :deep(.vue-slider-mark:last-child) {
      opacity: 1;
    }

    :deep(.vue-slider-rail) {
      height: 6px;
      background: transparent;
    }
    :deep(.vue-slider-rail::before) {
      content: "";
      width: calc(100% + 14px);
      height: 100%;
      border-radius: 100px;
      position: absolute;
      inset: 0 auto auto -7px;
      background: rgba(217, 217, 217, 1);
    }
  }
}
</style>
