<template>
  <SrpModal :isVisible="isVisible" @close="close">
    <template #content>
      <div id="intro">
        <img class="shrpa-logo" src="https://cdn.shrpa.com/images/shrpa-full-color.png" alt="Shrpa logo" />
        <h1 class="global-h1" style="margin-bottom: 15px">
          Share Shrpa<br />
          Get Paid!
        </h1>
        <div style="margin-bottom: 14px; text-align: center">
          Know an adventure buddy who might be interested in creating adventures on Shrpa?<br />
          Invite them to Shrpa and receive $10 after they apply to their first collab!
        </div>
        <input type="text" v-model="email" placeholder="friend@somewhere.com" style="margin-bottom: 15px" />
        <div v-if="status && status.length > 0" style="margin: 1em">
          <b>{{ status }}</b>
        </div>

        <div @click="send" class="ui large primary button">Send an Invite</div>

        <br /><br /><br />
        <div style="margin-bottom: 8px">Rather just manually send the invite?</div>
        <div @click="copy" class="ui small button">Copy the Invite Link</div>
        <div v-if="justCopied">Copied!</div>

        <!--<br/><br/>
      <h3>Invite 3 creators and get<br/>a State Park Pass!</h3>
      <div><a style="text-decoration:underline" target="_blank" :href="globalGetLink('CreatorReferralProgram')">Full Rules Here</a></div>-->
      </div>
    </template>
  </SrpModal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import { inject } from "vue";
import SrpModal from "@components/ui/SrpModal.vue";
import { mapState } from "pinia";
import { useUserProfileStore } from "@stores/userProfileStore";

export default defineComponent({
  name: "CreatorReferralInvite",
  components: { SrpModal },

  props: {
    isVisible: { type: Boolean, required: true },
  },

  emits: ["close"],

  data() {
    return {
      globalGetLink: inject("globalGetLink") as any,

      email: "",
      status: "",
      justCopied: false,
    };
  },

  watch: {
    email: function (newVal, oldVal) {
      this.status = "";
    },
  },

  computed: {
    ...mapState(useUserProfileStore, ["getActingUserProfile", "isSuperOrSalesUser"]),
  },

  methods: {
    async copy() {
      const inviteLink = `${window.location.origin}/redirect?referredByUserId=${encodeURIComponent(this.getActingUserProfile?.authNameId)}`;
      await navigator.clipboard.writeText(inviteLink);
      this.justCopied = true;
      setTimeout(() => {
        this.justCopied = false;
      }, 3000);
    },
    async send() {
      if (!this.email || this.email.length === 0 || !this.email.includes("@")) {
        this.status = "Please enter a valid email";
        return;
      }

      this.status = "Sending...";
      const { data } = await axios.put(`${import.meta.env.VITE_API_URL}/profile/sendReferral?email=${this.email.trim()}`);
      this.status = "Email sent, thanks!";
    },
    close() {
      this.status = "";
      this.$emit("close");
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

/*Note: Some of this may not be needed, it was stolen from the Intro component */
#intro {
  padding: 8px 15px 25px;
  display: block;
  justify-content: space-around;
  position: relative;
  text-align: center;
  font-family: "Quicksand", "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  /*background-image: url("https://cdn.shrpa.com/images/backgrounds/streets-fade3.jpg");*/
  background-size: cover;
}

.shrpa-logo {
  height: 65px;
  margin-bottom: 18px;
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .shrpa-logo {
    height: 48px;
    margin-bottom: 10px;
  }
}
</style>
