import FileUtils from "./FileUtils";
import { getInstance } from "@auth/authWrapper";
import Logger from "@helpers/Logger";
import axios from "axios";

export default {
  // Downloads a image or video (via a simulated link click) given the parameters.
  // Decided to encapsulate this since it's used in a few places now
  async downloadFile(fileName: string, downloadUncompressed: boolean, communityId: string, logSource: string | null) {
    // @ts-ignore
    const contentBaseUri = globalThis.Bootstrap.Config.contentCdnBaseUri;
    let fileUri = `${contentBaseUri}/cms/images/${downloadUncompressed ? "uncompressed" : "expeditions"}/${fileName}`;
    if (logSource && communityId) {
      // note: purposely firing this and not awaitg it
      this.recordLog(communityId, fileName, logSource);
    }
    if (FileUtils.isVideoFileType(fileName, null)) {
      // Proxying this through the server so the browser doesn't take over and auto-play the video.
      const quality = downloadUncompressed ? "high" : "mid";
      const currentUser = await getInstance()?.getUser();
      const authId = currentUser?.sub ?? "unknown";
      fileUri = `${import.meta.env.VITE_API_URL}/videodownload/${fileName}?quality=${quality}&requestor=${encodeURIComponent(authId)}`;
    }
    Logger.info(`Downloading file: ${fileUri}`);
    const link = document.createElement("a");
    link.href = fileUri;
    link.target = "_blank";
    // link.download = 'Download.jpg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },

  downloadCSV(csvContent, filename = "download.csv") {
    Logger.info(`Downloading file: ${filename}`);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.target = "_blank";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },

  downloadFileByURI(fileUri: string) {
    Logger.info(`Downloading file: ${fileUri}`);
    const link = document.createElement("a");
    link.href = fileUri;
    link.target = "_blank";
    // link.download = 'Download.jpg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },

  async recordLog(communityId: string, assetId: string, source: string) {
    try {
      await axios.post(`${import.meta.env.VITE_API_URL}/customer-success/${communityId}/download-asset/${assetId}?source=${source}`);
    } catch (e) {
      Logger.error(`Error recordLog for: ${assetId}`, e);
    }
  },
};
