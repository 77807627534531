<template>
  <div class="customer-onboarding">
    <div class="customer-onboarding__steps-wizard-wrap" ref="wizardContainer">
      <StepWizard
        class="customer-onboarding__steps-wizard"
        :isStickNumbersToLeftEdge="true"
        @slideChange="resetScrollPosition"
        :titles="[
          { title: 'Welcome to Shrpa!', subtitle: '~30 seconds' },
          { title: 'First Collab', subtitle: '~1.5 min' },
          { title: 'Photo Wall', subtitle: '~1.5 min' },
          { title: 'What to do next', subtitle: '~1 minute' },
        ]"
      >
        <template #[0]="{ goToNextSlide }">
          <SlideIntro @goToNextStep="goToNextSlide" />
        </template>
        <template #[1]="{ goToPrevSlide, goToNextSlide }">
          <SlideCollab :onboardingParams="onboardingParams" :communityIdInContext="communityIdInContext" @goToPrevStep="goToPrevSlide" @goToNextStep="goToNextSlide" />
        </template>
        <template #[2]="{ goToPrevSlide, goToNextSlide }">
          <SlidePhotoWall :onboardingParams="onboardingParams" :communityIdInContext="communityIdInContext" @goToPrevStep="goToPrevSlide" @goToNextStep="goToNextSlide" @close="close" />
        </template>
        <template #[3]="{ goToPrevSlide }">
          <SlideNextStep :onboardingParams="onboardingParams" :communityIdInContext="communityIdInContext" @goToPrevStep="goToPrevSlide" />
        </template>
      </StepWizard>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// Types
import { OnboardingCustomer } from "@contracts/onboarding";

// Components
import SlideIntro from "./SlideIntro.vue";
import SlideCollab from "./SlideCollab.vue";
import SlidePhotoWall from "./SlidePhotoWall.vue";
import SlideNextStep from "./SlideNextStep.vue";
import StepWizard from "@components/ui/StepWizard.vue";

export default defineComponent({
  name: "OnboardingCustomer",

  components: {
    StepWizard,
    SlideIntro,
    SlideCollab,
    SlidePhotoWall,
    SlideNextStep,
  },

  props: {
    onboardingParams: { type: Object as () => OnboardingCustomer, required: true },
    communityIdInContext: { type: String, required: true },
  },

  emits: ["onHide"],

  data() {
    return {
      activeSlide: 0,
    };
  },

  methods: {
    close() {
      this.$emit("onHide", false);
    },
    resetScrollPosition() {
      (this.$refs.wizardContainer as HTMLDivElement).scrollTop = 0;
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Customer onboarding ========================================================
.customer-onboarding {
  &__steps-wizard-wrap {
    height: 100%;
    padding: 0 75px 0 33px;
    position: relative;
    overflow-y: scroll;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: white;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(black, 0.25);
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(black, 0.35);
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .customer-onboarding {
    &__steps-wizard-wrap {
      padding: 0 30px 0 0;
    }
  }
}
</style>
