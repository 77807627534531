import { SocialMediaFields } from "@contracts/creatorPublicProfileFields";

export default {
  validateSocialFields(fields: SocialMediaFields): [boolean, Array<string> | null] {
    // Validate
    var error = new Array<string>();
    error.push(...this.validateTwitterHandle(fields.twitterHandle));
    error.push(...this.validateInstagramHandle(fields.instagramHandle));
    error.push(...this.validateFacebookURL(fields.facebookURL));
    error.push(...this.validateYoutubeURL(fields.youtubeURL));
    error.push(...this.validateTikTokHanle(fields.tikTokHandle));

    if (error?.length > 0) return [false, error];
    else return [true, null];
  },

  fixSocialFields(fields: SocialMediaFields) {
    fields.facebookURL = this.fixurl(fields.facebookURL);
    fields.youtubeURL = this.fixurl(fields.youtubeURL);
    fields.instagramHandle = this.parseInstagramHandle(fields.instagramHandle);
  },

  // Adds http to the front of urls
  fixurl(url) {
    if (!url || url === "") {
      return null;
    }
    url = url.trim();
    if (/^(:\/\/)/.test(url)) {
      return `https${url}`;
    }
    if (!/^(f|ht)tps?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  },

  parseInstagramHandle(input) {
    if (!input) return null;

    var result = input.trim();

    if (result.startsWith("/")) {
      result = result.substring(1);
    }
    if (result.startsWith("@")) {
      result = result.substring(1);
    }

    const domain = "instagram.com/";
    if (result.includes(domain)) {
      const startIdx = result.indexOf(domain) + domain.length;
      if (startIdx >= 0) result = result.slice(startIdx);
    }
    // Remove an optional trailing slash
    if (result.endsWith("/")) {
      result = result.slice(0, -1);
    }

    return result;
  },

  validateTwitterHandle(value): Array<string> {
    var errors = new Array<string>();
    if (value) {
      // Remove trailing whitespaces
      value = value.trim();
      if (value.length > 16) {
        errors.push("Twitter handles must be fewer than 15 characters.");
      }
      if (value.substring(1).match(/^[A-Za-z0-9_]*$/) ? false : true) {
        errors.push("Twitter handles must consist of only letters, numbers, and underscores.");
      }
    }
    return errors;
  },
  validateTikTokHanle(value): Array<string> {
    var errors = new Array<string>();
    if (value) {
      // Remove trailing whitespaces
      value = value.trim();
      if (value.length > 30) {
        errors.push("TikTok handles must be fewer than 30 characters.");
      }
      if (value.substring(1).match(/^[A-Za-z0-9_.]*$/) ? false : true) {
        errors.push("TikTok handles must consist of only letters, numbers, underscores, and periods.");
      }
    }
    return errors;
  },
  validateInstagramHandle(value): Array<string> {
    var errors = new Array<string>();
    if (value) {
      // Remove trailing whitespaces
      value = value.trim();
      if (value.length > 30) {
        errors.push("Instagram usernames must be fewer than 15 characters.");
      }
      if (value.match(/^[^a-zA-Z0-9._]+$/) ? true : false) {
        errors.push("Instagram handles must consist of only letters, numbers, periods, and underscores.");
      }
    }
    return errors;
  },
  validateFacebookURL(value): Array<string> {
    var errors = new Array<string>();
    if (value) {
      // Remove trailing whitespaces
      value = value.trim();
      if (!/^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/i.test(value)) {
        errors.push("Not a valid facebook url.");
      }
    }
    return errors;
  },
  validateYoutubeURL(value): Array<string> {
    var errors = new Array<string>();
    if (value) {
      // Remove trailing whitespaces
      value = value.replace(/^\s+|\s+$/g, "");
      if (!value.includes("youtube.com")) {
        errors.push("Not a valid YouTube url.");
      }
    }
    return errors;
  },
};
