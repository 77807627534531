<template>
  <div class="slide-content">
    <img class="slide-content__illustration" alt="slide1" src="/images/onboarding/rearrangement.svg" style="width: 280px" />

    <ul class="slide-content__list-of-next-steps">
      <!-- Next step link -->
      <RouterLink v-for="step in nextStepsList" :key="step.text" class="next-step-link slide-content__next-step-link" :to="step.to" @click="$emit('clickOnLink')">
        <div class="next-step-link__text">{{ step.text }}</div>
        <IconEmbedded class="next-step-link__arrow" name="arrow-right_2-5" />
      </RouterLink>
      <!-- / Next step link -->
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// Types
import { OnboardingCustomer } from "@contracts/onboarding";

// Components
import CopyTextNew from "@components/CopyTextNew.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export default defineComponent({
  name: "SlideNextStep",

  components: {
    IconEmbedded,
    CopyTextNew,
    LinkWithIcon,
  },

  props: {
    onboardingParams: { type: Object as () => OnboardingCustomer, required: true },
    communityIdInContext: { type: String, required: true },
  },

  data() {
    return {
      nextStepsList: [
        {
          text: "Setup your first collab",
          to: { name: "PaidCollabCommunityAdmin", params: { communityId: this.communityIdInContext } },
        },
        {
          text: "Review your photo wall",
          to: { name: "WidgetDirections", params: { pageId: this.communityIdInContext } },
        },
        {
          text: "Get Content with Community Uploads",
          to: { name: "UserUploadCampaigns", params: { pageId: this.communityIdInContext } },
        },
      ],
    };
  },
});
</script>

<style scoped lang="scss">
@import "./slide-styles.scss";
</style>
