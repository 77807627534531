<template>
  <div class="grid-dev-overlay">
    <div class="grid-dev-overlay__first-fold-line1"></div>
    <div class="grid-dev-overlay__first-fold-line2"></div>

    <div v-if="props.isColumnsMode" class="grid-dev-overlay__grid-with-left-col">
      <ul class="grid-dev-overlay__left-col">
        <li v-for="(_, i) in Array(3)" :key="i" class="grid-dev-overlay__column"></li>
      </ul>
      <ul class="grid-dev-overlay__right-col" :style="{ transform: `translateX(${props.rightColShift}px)` }">
        <li v-for="(_, i) in Array(9)" :key="i" class="grid-dev-overlay__column"></li>
      </ul>
    </div>

    <ul v-else class="grid-dev-overlay__plain-grid">
      <li v-for="(_, i) in Array(12)" :key="i" class="grid-dev-overlay__column"></li>
    </ul>
  </div>
</template>

<script setup lang="ts">
export interface Props {
  isColumnsMode?: boolean;
  rightColShift?: number;
}
const props = withDefaults(defineProps<Props>(), {
  isColumnsMode: false,
  rightColShift: 0,
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Grid overlay ===============================================================
.grid-dev-overlay {
  width: 100%;
  height: 100%;
  display: flex;
  pointer-events: none;

  &::before {
    content: "desktop wide";
    padding: 3px 9px 3px;
    border-radius: 5px;
    position: absolute;
    inset: 20px auto auto 20px;
    color: #fff;
    font-family: sans-serif;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
  }

  &__first-fold-line1 {
    width: 100%;
    height: 0;
    border-bottom: 1px rgba(255, 0, 0, 0.1) solid;
    position: absolute;
    inset: 730px auto auto 0;
  }

  &__first-fold-line2 {
    width: 100%;
    height: 0;
    border-bottom: 1px rgba(255, 0, 0, 0.1) solid;
    position: absolute;
    inset: 550px auto auto 0;
  }

  &__plain-grid {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    list-style: none;
  }

  &__grid-with-left-col {
    width: 100%;
    height: 100%;
    display: flex;
  }

  &__left-col {
    width: 354px;
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    position: absolute;
    inset: 0 auto auto 0;
    list-style: none;
  }

  &__right-col {
    width: 1116px;
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    position: absolute;
    inset: 0 auto auto calc(354px + (100% - 354px) / 2 - 1116px / 2);
    list-style: none;
  }

  &__column {
    width: 100px;
    margin-right: 27px;
    background: rgba(255, 0, 0, 0.03);

    &:last-child {
      margin-right: 0;
    }
  }
}

// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .grid-dev-overlay {
    &:before {
      content: "desktop";
    }

    &__left-col {
      width: 297px;
    }

    &__right-col {
      width: 945px;
      inset: 0 auto auto calc(297px + (100% - 297px) / 2 - 945px / 2);
    }

    &__column {
      width: 81px;
    }
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .grid-dev-overlay {
    &:before {
      content: "laptop";
    }

    &__first-fold-line {
      top: 870px;
    }

    &__left-col {
      width: 258px;
    }

    &__right-col {
      width: 822px;
      inset: 0 auto auto calc(258px + (100% - 258px) / 2 - 822px / 2);
    }

    &__column {
      width: 70px;
      margin-right: 24px;
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .grid-dev-overlay {
    &:before {
      content: "tablet-large";
    }

    &__first-fold-line {
      top: 740px;
    }

    &__left-col {
      width: 76px;

      .grid-dev-overlay__column:nth-child(1),
      .grid-dev-overlay__column:nth-child(2) {
        display: none;
      }
    }

    &__right-col {
      width: 664px;
      inset: 0 auto auto calc(76px + (100% - 76px) / 2 - 664px / 2);

      .grid-dev-overlay__column:nth-child(1),
      .grid-dev-overlay__column:nth-child(2) {
        display: none;
      }
    }

    &__column {
      width: 76px;
      margin-right: 22px;
    }

    &__plain-grid {
      .grid-dev-overlay__column:nth-child(8) {
        margin-right: 0;
      }

      .grid-dev-overlay__column:nth-child(n + 9) {
        display: none;
      }
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .grid-dev-overlay {
    &:before {
      content: "tablet";
    }

    &__first-fold-line {
      top: 450px;
    }

    &__left-col {
      width: 47px;

      .grid-dev-overlay__column:nth-child(1),
      .grid-dev-overlay__column:nth-child(2) {
        display: none;
      }
    }

    &__right-col {
      width: 437px;
      inset: 0 auto auto calc(47px + (100% - 47px) / 2 - 437px / 2);

      .grid-dev-overlay__column:nth-child(1),
      .grid-dev-overlay__column:nth-child(2) {
        display: none;
      }
    }

    &__column {
      width: 47px;
      margin-right: 18px;
    }

    &__plain-grid {
      .grid-dev-overlay__column:nth-child(8) {
        margin-right: 0;
      }

      .grid-dev-overlay__column:nth-child(n + 9) {
        display: none;
      }
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .grid-dev-overlay {
    width: 100%;
    max-width: calc(100% - 17px * 2);
    margin-left: 17px;

    &:before {
      content: "mobile";
      left: 3px;
    }

    &__first-fold-line {
      top: 450px;
    }

    &__left-col {
      display: none;
    }

    &__right-col {
      width: 100%;
      left: 0;
      justify-content: center;

      .grid-dev-overlay__column {
        flex-grow: 1;
      }

      .grid-dev-overlay__column:nth-child(1),
      .grid-dev-overlay__column:nth-child(2),
      .grid-dev-overlay__column:nth-child(3) {
        display: none;
      }
    }

    &__column {
      width: 55px;
      margin-right: 17px;
    }

    &__plain-grid {
      .grid-dev-overlay__column {
        flex-grow: 1;
      }

      .grid-dev-overlay__column:nth-child(6) {
        margin-right: 0;
      }

      .grid-dev-overlay__column:nth-child(n + 7) {
        display: none;
      }
    }
  }
}
</style>
