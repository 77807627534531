<template>
  <div class="industry-insights-page">
    <!-- Header -->
    <div class="header industry-insights-page__header">
      <h1 class="global-h1 header__title">Industry Insights</h1>
      <div class="header__subtitle">
        Gain valuable perspectives through Creator Visit summaries.<br />
        Discover trends, explore success stories, and receive unique insights from the field.
      </div>
    </div>
    <!-- / Header -->

    <div class="industry-insights-page__divider"></div>

    <Loader v-if="isLoading" class="industry-insights-page__loader" />

    <ul v-else class="industry-insights-page__visit-summaries-list">
      <CollabSummarySection
        class="industry-insights-page__summary-section"
        v-for="summarySection in summarySections"
        :key="summarySection.itineraries"
        :summarySection="summarySection"
        isVisitDateVisible
      />
    </ul>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import { defineComponent, inject } from "vue";
import { getInstance } from "@auth/authWrapper";
import { useHead } from "@unhead/vue";
import { RouteHelper } from "@helpers/RouteHelper";

// Components
import CollabSummarySection from "@components/CollabSummarySection.vue";
import Loader from "@components/Loader/Loader.vue";
import { MetricSender } from "@/helpers/MetricSender";

export default defineComponent({
  name: "RecentCreatorVisits",

  components: { CollabSummarySection, Loader },

  data() {
    return {
      globalLog: inject("globalLog") as any,

      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      isLoading: true,
      title: "Industry Insights - Shrpa",
      pageDetailsNotFound: false,

      summarySections: [],
    };
  },

  async mounted() {
    useHead({ title: () => this.title ?? "", meta: [{ name: "description", content: "Industry Insights" }] });
    await this.loadPageDetails();

    MetricSender.sendMetric("Cust-ViewIndustryInsights", null);
  },

  methods: {
    loadPageDetails: async function (): Promise<void> {
      let queryParam = "?homepage=false&returnCount=10";
      const uri = `${import.meta.env.VITE_API_URL}/collabsummary/recent` + queryParam;

      const response = await axios.get(uri);
      this.summarySections = response.data;

      this.isLoading = false;
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Header =====================================================================
.header {
  &__title {
    margin-bottom: 8px;
  }

  &__subtitle {
  }
}

// Industry insights page =====================================================
.industry-insights-page {
  width: $desktop-wide-right-col-width;
  margin: 0 auto;
  color: rgba(91, 91, 91, 1);
  font: 14px/18px sans-serif;

  &__header {
    margin-bottom: 35px;
  }

  &__divider {
    margin-bottom: 45px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__loader {
    margin-bottom: 150px;
  }

  &__visit-summaries-list {
    gap: 50px;
    padding: 0;
    margin: 0 auto 150px;
    display: flex;
    flex-direction: column;
    list-style: none;
  }

  &__summary-section {
    padding-bottom: 60px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;

    &:last-child {
      border-bottom: none;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .industry-insights-page {
    width: $laptop-right-col-width;
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .industry-insights-page {
    width: $tablet-large-right-col-width;
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .industry-insights-page {
    width: $tablet-content-width;
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .industry-insights-page {
    width: $mobile-content-width;
  }
}
</style>
