import { Itinerary, ItinerarySteps, SaveItineraryResponse } from "@contracts/itinerary";
import axios from "axios";

export default {
  async getItinerary(id: string | null, creatorIdOverride: string | null): Promise<Itinerary> {
    let uri = `${import.meta.env.VITE_API_URL}/cms/itineraries/`;
    uri += id ? id : "empty"; // empty is passed as the Id when creating a new adventure
    const params: GetItineraryQueryParams = { creatorIdOverride };
    const { data } = await axios.get(uri, { params });
    const itinerary = data as Itinerary;
    this.prepItineraryAfterLoad(itinerary);

    return itinerary;
  },

  async getItineraryWithSteps(id: string, status: string | null): Promise<ItinerarySteps> {
    let uri = `${import.meta.env.VITE_API_URL}/itineraries/${id}/steps/v2`;
    if (status) uri += `?status=${status}`;
    const { data } = await axios.get(uri);
    const result = data as ItinerarySteps;
    this.prepItineraryAfterLoad(result.itinerary);

    return result;
  },

  async saveItinerary(itinerary: Itinerary, shouldPublish: boolean): Promise<SaveItineraryResponse> {
    const { data } = await axios.put(`${import.meta.env.VITE_API_URL}/cms/itineraries/?publish=${shouldPublish}`, itinerary);
    return data;
  },

  // Updating CreatedFor has a one-off flow since it's an admin field we want set across all versions
  async updateCreatedFor(itinerary: Itinerary) {
    const payload = {
      createdFor: itinerary.createdFor,
      collabInputId: itinerary.collabInputId,
      sharesWith: itinerary.sharesWith,
    };
    const config = { headers: { "Content-Type": "application/json" } };
    await axios.put(`${import.meta.env.VITE_API_URL}/cms/creator/${itinerary.sherpaId}/itineraries/${itinerary.id}/createdfor`, payload, config);
  },

  async deleteItinerary(id: string, markRemoved: boolean) {
    let uri = `${import.meta.env.VITE_API_URL}/cms/itineraries/${id}`;
    if (markRemoved) uri += "?remove=true";
    const { data } = await axios.delete(uri);
  },

  async prepItineraryAfterLoad(itinerary: Itinerary) {
    // Sets a few fields so they display better in the cms
    // Note: The initial empty step(s) are added server-side

    // Temporary, once the new CMS is live we should remove the default server-side and then this code can go away
    if (itinerary?.tileImageLocation === "placeholder-image.jpg") {
      itinerary.tileImageLocation = null;
    }

    if (itinerary && !itinerary.unusedPhotos) itinerary.unusedPhotos = [];

    // Nothing to do now since WhatToBring is a comma separated list
  },
};

interface GetItineraryQueryParams {
  creatorIdOverride?: string | null;
}
