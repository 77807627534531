<template>
  <SrpDropdown :width="screenSize === 'mobile' ? 240 : 180">
    <template #clickableElement="{ isDropdownVisible }">
      <!-- Select imitation -->
      <div class="select-imitation collab-theme-picker__select-imitation">
        <div class="select-imitation__theme-icon-wrap">
          <img v-if="modelValue" class="select-imitation__theme-icon" :src="getThemeIcon(modelValue)" :alt="getThemeTypeName(modelValue)" />
        </div>

        <IconEmbedded v-if="isDropdownVisible" class="select-imitation__caret-icon" name="caret-top_3-5" :size="16" color="rgba(0, 0, 0, 0.5)" />
        <IconEmbedded v-else class="select-imitation__caret-icon" name="caret-bottom_3-5" :size="16" color="rgba(0, 0, 0, 0.5)" />

        <div
          :class="{
            'global-select': true,
            'global-select--focus': isDropdownVisible,
            'select-imitation__input': true,
          }"
        >
          {{ getThemeTypeName(modelValue) }}
        </div>
      </div>
      <!-- / Select imitation -->
    </template>

    <template #dropdown="{ close }">
      <div class="collab-theme-picker__dropdown">
        <ul class="collab-theme-picker__theme-types-ul">
          <!-- Theme type li -->
          <li
            :class="{
              'theme-type-li': true,
              'theme-type-li--highlighted': type === modelValue,
              'theme-types-list__theme-type-li': true,
            }"
            v-for="type in themeTypes"
            :key="type"
            @click="
              () => {
                emit('update:modelValue', type);
                close();
              }
            "
          >
            <img class="theme-type-li__icon" :src="getThemeIcon(type)" :alt="getThemeTypeName(type)" />
            <div class="theme-type-li__text">
              {{ getThemeTypeName(type) }}
            </div>
          </li>
          <!-- / Theme type li -->
        </ul>
      </div>
    </template>
  </SrpDropdown>
</template>

<script setup lang="ts">
import { Ref, inject } from "vue";
import { getThemeTypeName, getThemeIcon } from "@helpers/ThemeTypeHelper";

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import SrpDropdown from "@components/ui/SrpDropdown.vue";

// Types
import { type ScreenSize } from "@contracts/screenSize";
import { type ThemeType, themeTypes } from "@contracts/collab";

defineProps<{ modelValue: ThemeType }>();

const emit = defineEmits<{
  (e: "update:modelValue", value: ThemeType): void;
}>();

// Global variables
const screenSize = inject("screenSize") as Ref<ScreenSize>;
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Tabs =======================================================================
.tabs {
  height: 37px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    width: 100%;
    height: 0;
    border-bottom: 1px rgba(0, 0, 0, 0.2) solid;
    position: absolute;
    inset: auto auto 0 0;
    z-index: -1;
  }

  &__tab {
    height: 100%;
    padding: 0 23px 2px;
    margin-right: 5px;
    box-sizing: border-box;
    border: 1px rgba(223, 223, 223, 1) solid;
    border-radius: 4px 4px 0 0;
    border-bottom-color: rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(223, 223, 223, 1);
    cursor: pointer;
    user-select: none;

    &--active {
      border-color: rgba(198, 198, 198, 1);
      border-bottom-color: rgba(255, 255, 255, 1);
      background-color: rgba(255, 255, 255, 1);
    }
  }
}

// Theme type li ==============================================================
.theme-type-li {
  height: 33px;
  padding: 0 6px;
  box-sizing: border-box;
  border-bottom: 1px rgba(220, 220, 220, 1) solid;
  display: flex;
  align-items: center;
  font: 14px/14px sans-serif;
  user-select: none;
  cursor: pointer;

  &--highlighted {
    background: rgba(0, 0, 0, 0.04);
    cursor: default;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }

  &__icon {
    width: 29px;
    height: 29px;
    margin-right: 4px;
  }

  &__text {
    color: rgba(91, 91, 91, 1);
  }

  &--highlighted &__text {
    font-weight: bold;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .theme-type-li {
    height: 44px;
    padding: 0 15px;

    &__icon {
      width: 36px;
      height: 36px;
      margin-right: 8px;
    }
  }
}

// Select imitation ===========================================================
.select-imitation {
  width: 210px;
  position: relative;
  cursor: pointer;
  user-select: none;

  &__theme-icon-wrap {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 5px auto auto 8px;
    z-index: 2;
    pointer-events: none;
  }

  &__theme-icon {
    width: 100%;
    height: 100%;
  }

  &__caret-icon {
    position: absolute;
    inset: 12px 9px auto auto;
    z-index: 2;
    pointer-events: none;
  }

  &__input {
    padding-left: 40px;
    display: flex;
    align-items: center;
    font-weight: bold;
    white-space: nowrap;
  }
}

// Collab theme picker ========================================================
.collab-theme-picker {
  height: 33px;
  position: relative;
  z-index: 0;
  color: rgba(91, 91, 91, 1);
  font: 14px/18px sans-serif;
  cursor: pointer;

  &__select-imitation {
  }

  &__dropdown {
    width: 180px;
    box-sizing: border-box;
    border: 1px rgba(200, 200, 200, 1) solid;
    border-radius: 6px;
    background: rgba(255, 255, 255, 1);

    :deep(.dp__outer_menu_wrap),
    :deep(.dp__menu) {
      border: none !important;
      box-shadow: none !important;
    }
  }

  &__theme-types-ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__theme-type-li {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .collab-theme-picker {
    &__dropdown {
      width: 240px;
    }
  }
}
</style>
