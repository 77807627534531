<template>
  <div class="content-wrapper map-container" style="width: 100%; margin-top: 0px" id="adventure-map">
    <div class="close-map-button">
      <button class="ui icon button close-btn" @click="navigateClose">
        <i class="angle left icon"></i>
        Close
      </button>
    </div>

    <GMapMap ref="mapRef" :center="center" :options="gMapOptions" :zoom="zoomLevel">
      <GMapInfoWindow
        :ref="'info' + i"
        v-for="(community, i) in communities"
        :key="'info' + community.uniqueName"
        :options="{
          pixelOffset: {
            width: 0,
            height: 0,
          },
        }"
        :position="{ lat: community.latitude, lng: community.longitude }"
      >
        <div @click="infoWindowClick(community.uniqueName)">
          <h4 style="max-width: 200px; margin-bottom: 3px">{{ community.title }}</h4>
          <div>
            <b>{{ community.totalCollabs - community.completedCollabs }} Collabs left</b> of {{ community.totalCollabs }}
          </div>
          <div>
            <b>{{ community.creatorsInRange }} Eligible Creators</b> in range
          </div>
          <div>{{ community.creatorsNear }} of those are within {{ milesToConsiderNear }} miles</div>
          <!--<div>{{ community.becomingEligibleInRange }} interested in range ({{ community.becomingEligibleNear }} near)</div>-->
          <!--<img style="max-height: 50px; max-width: 50px; object-fit: cover" :src="`${contentBaseUri}/cms/images/orgs/${community.icon}`" alt="Community Icon" />-->
        </div>
      </GMapInfoWindow>
    </GMapMap>

    <!--
    <button v-if="showStartButton" class="ui icon huge orange button go-map-btn" @click="startClick">
      Let's Go!
    </button>
    -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import Loader from "@components/Loader/Loader.vue";
import { PageSummary } from "@contracts/pages";
import { RouteHelper } from "@helpers/RouteHelper";
import { useHead } from "@unhead/vue";

// This is probably better than InfoWindow?
// https://developers.google.com/maps/documentation/javascript/examples/overlay-popup

export default defineComponent({
  name: "CommunitiesMap",

  components: {
    Loader,
  },

  data() {
    return {
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      communities: [] as Array<PageSummary>,
      milesToConsiderNear: 250,
      center: { lat: 44.1473, lng: -92.3814 } as globalThis.google.maps.LatLngLiteral,
      zoomLevel: 8,

      gMapOptions: {
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: false,
        disableDefaultUi: false,
      },

      map: null as globalThis.google.maps.Map,
    };
  },

  async mounted() {
    useHead({ title: "Communities Map" });
    (this.$refs.mapRef as any).$mapPromise.then(map => {
      this.map = map;
      this.getCommunitiesFromServer();
    });
  },

  methods: {
    async getCommunitiesFromServer() {
      const nearParam = RouteHelper.getQueryStringOrHashParam("near");
      if (nearParam) this.milesToConsiderNear = parseInt(nearParam, 10);
      const apiUrl = `${import.meta.env.VITE_API_URL}/pages/superuser-map?milesToConsiderNear=${this.milesToConsiderNear}`;
      const { data } = await axios.get(apiUrl);
      this.communities = data;

      // Similar to what's in GoogleMapBoundsLogic
      this.fitMapToLocations(this.map, this.communities);
    },
    fitMapToLocations(map: globalThis.google.maps.Map, communities: Array<PageSummary>) {
      if (communities.length === 0) return;

      var bounds = new globalThis.google.maps.LatLngBounds();
      communities.forEach(community => {
        bounds.extend(new globalThis.google.maps.LatLng(community.latitude, community.longitude));
      });
      if (map?.fitBounds) map.fitBounds(bounds);
      else (map as any).map?.fitBounds?.(bounds);
    },
    infoWindowClick(uniqueName: string) {
      this.navigateToPage(uniqueName);
    },
    navigateToPage(uniqueName: string) {
      window.open(this.$router.resolve({ name: "Pages", params: { pageId: uniqueName } }).href, "_blank");
    },
    navigateClose() {
      // this.$router.push({name: 'Home'});
      history.back();
    },
  },
});
</script>

<style scoped>
:deep(.vue-map-container) {
  width: 100%;
  height: 100vh;
}

.close-map-button {
  display: flex;
  position: absolute;
  z-index: 101;
  top: 10px;
  right: 100px;
  color: black;
}

@media screen and (max-width: 420px) {
  :deep(.vue-map-container) {
    width: 100%;
    height: 85vh;
    overflow-y: hidden;
  }

  .close-map-button {
    right: 5px;
  }
}
</style>
