<template>
  <div class="map-section">
    <GMapMap
      :class="{
        'map-section__map-component': true,
        'map-section__map-component--highlighted': props.isHighlighted,
        'map-section__map-component--collapsed': !props.isExpanded && screenSize === 'mobile',
      }"
      ref="domRefMap"
      :center="{ lat: props.mapCenter?.latitude || centerOfUS.lat, lng: props.mapCenter?.longitude || centerOfUS.lng }"
      :options="gmapOptions"
      :zoom="defaultZoom"
      @rightclick="`mapRightClicked`"
      @click="
        value => {
          emit('update:isHighlighted', false);
          emit('clickOnMap', { latitude: value.latLng.lat(), longitude: value.latLng.lng() });
        }
      "
    >
      <!--<GMapMarker-->
      <!--  :position="mapCenter"-->
      <!--  :draggable="true"-->
      <!--  @dragend="() => {}"-->
      <!--  :icon="{-->
      <!--    url: 'https://cdn.shrpa.com/images/misc/mapicons/red-circle-small.png',-->
      <!--    scaledSize: getMarkerScaledSize(),-->
      <!--  }"-->
      <!--  :label="{ text: '1', color: 'white', fontSize: '15px', fontWeight: 'bold', fontFamily: 'quicksand' }"-->
      <!--/>-->
      <template v-for="(collabLocation, index) in locationsList" :key="collabLocation?.id">
        <template v-if="collabLocation?.latitude !== null && collabLocation?.longitude !== null">
          <GMapMarker
            :position="{ lat: collabLocation?.latitude, lng: collabLocation?.longitude }"
            :draggable="props.activeSnippetIndex === index"
            @dragend="handleMarkerDragEnd"
            :icon="{
              url: `https://cdn.shrpa.com/images/icons/${props.activeSnippetIndex === index ? 'marker-icon-teal-with-move-indicator.png' : 'marker-icon-gray.png'}`,
              scaledSize: { width: props.activeSnippetIndex === index ? 62 : 30, height: props.activeSnippetIndex === index ? 62 : 30 },
            }"
            :label="{ text: `${index + 1}`, color: 'white', fontSize: props.activeSnippetIndex === index ? '24px' : '13px', fontWeight: 'bold', fontFamily: 'quicksand' }"
          />
        </template>
      </template>

      <GMapPolygon
        v-if="props.boundarySelectMode === 'draw' && boundaryCoordinates.length"
        :paths="boundaryCoordinates"
        :editable="false"
        :options="{
          strokeColor: 'rgba(17, 134, 137, 1)',
          strokeOpacity: 0.8,
          strokeWeight: 3,
          fillColor: 'rgba(17, 134, 137, 0.6)',
          fillOpacity: 0.35,
          clickable: false,
        }"
      />

      <GMapCircle
        v-if="['circle', 'radius'].includes(props.boundarySelectMode) && circleRadiusInMeters"
        :options="{
          strokeColor: 'rgba(17, 134, 137, 1)',
          strokeOpacity: 0.8,
          strokeWeight: 3,
          fillColor: 'rgba(17, 134, 137, 0.6)',
          fillOpacity: 0.35,
          clickable: false,
        }"
        :center="{ lat: props.mapCenter?.latitude || centerOfUS.lat, lng: props.mapCenter?.longitude || centerOfUS.lng }"
        :radius="circleRadiusInMeters"
      />
    </GMapMap>

    <!-- Toggle label btn -->
    <div
      :class="{
        'toggle-label-btn': true,
        'toggle-label-btn--hidden': screenSize !== 'mobile',
        'map-section__toggle-label-btn': true,
      }"
      @click="emit('update:isExpanded', !props.isExpanded)"
    >
      <IconEmbedded class="toggle-label-btn__caret-icon" :name="props.isExpanded ? 'caret-top_4-5' : 'caret-bottom_4-5'" :size="12" />
      {{ `${props.isExpanded ? "Close the Map" : "Open the Map"}` }}
    </div>
    <!-- / Toggle label btn -->
  </div>
</template>

<script setup lang="ts">
import { inject, Ref, computed, ref, watch, onMounted, nextTick } from "vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import BoundsLogic from "@logic/GoogleMapBoundsLogic";

// Types
import { ScreenSize } from "@contracts/screenSize";
import { CollabLocation } from "@contracts/collabLocations";
import { Location } from "@contracts/location";
import { BoundarySelectMode } from "@contracts/pages";

// Global variables
const screenSize = inject("screenSize") as Ref<ScreenSize>;

const props = withDefaults(
  defineProps<{
    isExpanded: boolean;
    isHighlighted: boolean;
    locationsList: Array<CollabLocation>;
    activeSnippetIndex: number;
    boundaryGeoJson?: string | null;
    boundarySelectMode?: BoundarySelectMode | null;
    withinMiles?: number | null;
    mapCenter?: Location | null;
  }>(),
  {
    isExpanded: true,
    isHighlighted: false,
    locationsList: () => [],
    activeSnippetIndex: -1,
    boundaryGeoJson: null,
    boundarySelectMode: null,
    withinMiles: null,
    mapCenter: null, // Needed when they don't have a location set. Roughly center of the US lat: 39.687664, lng: -95.253992
  }
);

const emit = defineEmits<{
  (e: "update:isExpanded", value: boolean): void;
  (e: "update:isHighlighted", value: false): void;
  (e: "clickOnMap", value: Location): void;
  (e: "markerDragEnd", value: Location): void;
}>();

defineExpose({
  fitMapToLocations,
});

// Gmap params ================================================================
const gmapOptions = {
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: true,
  disableDefaultUi: false,
};

const defaultZoom = 12;
// Needed when they don't have a location set. Roughly center of the US lat: 39.687664, lng: -95.253992
const centerOfUS: globalThis.google.maps.LatLngLiteral = { lat: 39.687664, lng: -95.253992 };

// Wait for map to load =======================================================
const domRefMap = ref<any>(null);
const isMapReady = ref<boolean>(false);

onMounted(async () => {
  (domRefMap.value as any)?.$mapPromise.then(() => {
    // On first load the zoom wouldn't work correctly, this delay seemed to fix it.
    // Maybe we should fire this on a different event?
    setTimeout(() => {
      isMapReady.value = true;
    }, 1000);
  });
});

// Filter out locations without coordinates ===================================
const locationsWithCoordinates = computed(() => props.locationsList.filter(({ latitude, longitude }) => latitude !== null && longitude !== null));

function fitMapToLocations() {
  // Don't do this on mobile since the map is collapsed
  if (screenSize.value === "mobile") return;
  if (isMapReady.value) {
    const coordsList = [...locationsWithCoordinates.value, ...boundaryCoordinates.value.map(({ lat, lng }) => ({ latitude: lat, longitude: lng }))];
    // Only run this  if there are 2 or more locations
    if (coordsList.length < 2) return;

    if (props.mapCenter && props.mapCenter.latitude !== null && props.mapCenter.longitude !== null) {
      coordsList.push({ latitude: props.mapCenter.latitude, longitude: props.mapCenter.longitude });
    }

    BoundsLogic.fitMapToLocations(domRefMap.value.$mapObject, coordsList);
    const currentZoom = domRefMap.value.$mapObject.getZoom(); // The native gmap fitBounds function sets the zoom level a bit too small, so the map needs to be zoomed in slightly after "fitBounds" is executed
    let newZoom = currentZoom + 0.5;

    // console.info(`fitMapToLocations: zoom=${newZoom}`);
    if (newZoom > 14) newZoom = 14;
    domRefMap.value.$mapObject.setZoom(newZoom);
  }
}

watch(isMapReady, fitMapToLocations);

// Emit moved marker coordinates ==============================================
function handleMarkerDragEnd(event) {
  const location = { latitude: event.latLng.lat(), longitude: event.latLng.lng() };
  emit("markerDragEnd", location);
}

// Extract boundary coordinates from the geoJson ==============================
const boundaryCoordinates = ref<Array<{ lat: number; lng: number }>>([]);

watch(
  () => props.boundaryGeoJson,
  () => {
    if (props.boundaryGeoJson) {
      extractBoundaryFromGeoJson();
    }
  }
);
onMounted(() => {
  if (props.boundaryGeoJson) {
    extractBoundaryFromGeoJson();
  }
});

function extractBoundaryFromGeoJson() {
  const parsedJson = JSON.parse(props.boundaryGeoJson);
  boundaryCoordinates.value = parsedJson?.features?.[0]?.geometry?.coordinates?.[0].map(([lng, lat]) => ({ lat, lng }));

  fitMapToLocations();
}

// Convert radius miles to meters =============================================
const circleRadiusInMeters = computed<number>(() => {
  return props.withinMiles * 1609.34;
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Toggle label button ========================================================
.toggle-label-btn {
  height: 49px;
  padding: 1px 14px 0;
  box-sizing: border-box;
  border-radius: 0 0 6px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-decoration: underline;
  text-decoration-color: rgba(91, 91, 91, 0.7);
  text-underline-offset: 2px;
  text-decoration-thickness: 1px;
  text-decoration-style: dashed;
  background: rgba(240, 231, 184, 1);
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.15);
  user-select: none;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &--hidden {
    opacity: 0;
    pointer-events: none;
  }

  &__caret-icon {
    margin-right: 5px;

    :deep(svg) {
      fill: rgba(0, 0, 0, 0.5);
    }
  }
}

// Map section ================================================================
.map-section {
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 6px;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -1;
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.15);
  }

  &__map-component {
    width: 100%;
    height: 220px;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    z-index: 2;
    transition: height 0.08s ease-in-out;

    &--highlighted {
      &::before {
        content: "";
        width: 100%;
        height: 100%;
        outline: 6px rgba(240, 231, 184, 1) solid;
        outline-offset: -6px;
        position: absolute;
        inset: 0 auto auto 0;
        z-index: 4;
        pointer-events: none;
      }
    }

    &--collapsed {
      height: 30px;
    }
  }

  &__toggle-label-btn {
    position: absolute;
    inset: auto auto -43px 0;
    z-index: 1;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .map-section {
    &__map-component {
      height: 350px;

      &--collapsed {
        height: 60px;
      }
    }
  }
}
</style>
