<template>
  <!--This high level drag/drop.prevent keeps a dropped image from opening in the browser-->
  <div class="cms-view" v-if="initialLoadComplete" @dragover.prevent @drop.prevent>
    <div class="ui container">
      <div v-if="collabForItinerary">
        <CmsAdventureCollabNotification :collab="collabForItinerary" @click:viewCollab="saveAdventureAndNavigateToCollabOpportunity" />
      </div>

      <div>
        <!-- 
          Initial Photo Upload
          Note:
            There are two ways this section is hidden:
              1. renderPhotoUploadSection is set to true when the adventure already exists
              2. showPhotoUploadScreen is set to false when the user clicks Next after doing the initial photo upload
            
            showPhotoUploadScreen uses CSS to hide the components rather than v-if so
              the photo uploads can continue even if the user presses Next before they're done
            
            renderPhotoUploadSection was added to reduce initial image data load
              time for existing adventures without this, the images would be 'rendered'
              in the DOM, but never displayed. This causes unnecessary image loading.
              Adventure images are still all loaded because the SrpTabs that show the StopsForm
              are not lazily loaded, but this prevent additional collab images from loading.
        -->
        <div
          v-if="renderPhotoUploadSection"
          :style="{ display: showPhotoUploadScreen ? 'block' : 'none', opacity: isLoadingCollabImages ? 0.5 : 1, pointerEvents: isLoadingCollabImages ? 'none' : 'all' }"
        >
          <div
            :style="{
              marginBottom: '20px',
              display: 'flex',
              flexDirection: screenSize === 'mobile' ? 'column' : 'row',
              justifyContent: 'space-between',
              alignItems: screenSize === 'mobile' ? 'flex-start' : 'center',
            }"
          >
            <h1 class="global-h1" style="margin: 4px 0; color: #058587">Create a new Adventure!</h1>

            <LinkWithIcon
              isDottedUnderline
              color="teal"
              @click="
                isFirstAdventureGuidance = false;
                isAdventureGuideModalVisible = true;
              "
              style="margin: 4px 0"
            >
              <template #icon><IconEmbedded name="bulb_2" :size="22" /></template>
              <span>Adventure Tips</span>
            </LinkWithIcon>
          </div>

          <AdventureGuideModal v-model:isVisible="isAdventureGuideModalVisible" :userCanShortcut="!isFirstAdventureGuidance" />
          <div class="ui raised segment">
            <h3 class="cms-section-title" style="margin: 0 0 16px 0 !important">Start by adding photos and videos of the places you visited!</h3>

            <UploadPhotoForm
              callerName="AdvStart"
              :img="images"
              :isCollab="isCollab"
              :showClose="false"
              :showRemove="true"
              :autoSelect="false"
              @imageUploadedToServer="onImageUploadedToServer"
              @removeMediaFile="removeMediaFile"
              style="width: 100%"
              :class="{ imagesUploadAreaTall: !images.length }"
            >
              <template #thumbnailsList="{ items, removeItem }">
                <!-- Uploaded files list -->
                <ul class="uploaded-files-list">
                  <SrpFileThumbnail
                    class="uploaded-files-list__thumbnail"
                    v-for="item in items"
                    :key="item.serverId"
                    :fileName="item.serverId"
                    :thumbSize="'thumb-tiny'"
                    :isDeleteButton="isInitialScreenDeleteMode"
                    galleryPostfix="initial"
                    @delete="
                      removeItem(
                        items.findIndex(i => i.serverId === item.serverId),
                        true
                      )
                    "
                  />
                </ul>
                <!-- / Uploaded files list -->
              </template>

              <template v-if="collabImagesUnused.length" #additionalThumbnails>
                <h4 class="global-h4">Images & videos from the Collab</h4>
                <!-- Uploaded files list -->
                <ul class="uploaded-files-list">
                  <SrpFileThumbnail class="uploaded-files-list__thumbnail" v-for="item in collabImagesUnused" :key="item" :fileName="item" galleryPostfix="collabAssets" />
                  <SrpFileThumbnail class="uploaded-files-list__thumbnail" v-for="item in collabImagesUsed" :key="item" :fileName="item" galleryPostfix="collabAssets" />
                </ul>
                <!-- / Uploaded files list -->
              </template>
            </UploadPhotoForm>
            <label v-if="images.length === 0" class="subsection-title" style="margin-top: 5px !important">If you're not ready to upload, don't worry, you can add them later.</label>

            <LinkWithIcon v-if="images.length" isDottedUnderline @click="isInitialScreenDeleteMode = !isInitialScreenDeleteMode" style="margin-top: 15px">
              <template #icon><IconEmbedded name="trashcan-alt_2" :size="20" /></template>
              <span>{{ isInitialScreenDeleteMode ? "Cancel remove mode" : "Select files to remove" }}</span>
            </LinkWithIcon>
          </div>
          <div class="text-right">
            <button class="ui primary button tiny" @click="showPhotoUploadScreen = false">Next</button>
          </div>
        </div>

        <!-- Steps and Summary Tabs-->
        <div v-if="!showPhotoUploadScreen" style="margin-top: 23px">
          <div class="ui yellow message" v-if="itinerary.sherpaId != null && getViewingUserProfile?.sherpaId !== itinerary.sherpaId">
            <div class="header">You're editing someone else's Adventure!</div>
            <p>
              Please use discretion for your edits. This is generally intended for smaller corrections rather than sweeping changes.<br />
              If you have any questions contact support@shrpa.com
            </p>
          </div>

          <div v-if="activeTabIndex >= itinerary.steps.length" style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 1em">
            <h1 class="ui orange header" style="margin-bottom: 0">Share your experience!</h1>
            <!--<button class="ui small button" style="display: flex" @click="cancelEdit">-->
            <!--  <div name="close" />-->
            <!--  Close-->
            <!--</button>-->
          </div>
          <div v-else style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 1em">
            <h1 class="ui orange header" style="margin-bottom: 0">Share your experience!</h1>
            <!--<button class="ui small button" style="display: flex" @click="cancelEdit">-->
            <!--  <div name="close" />-->
            <!--  Close-->
            <!--</button>-->
          </div>

          <NoteWithIcon v-if="fieldsWithErrors?.length > 0" color="red" style="margin-bottom: 25px">
            <template #icon><IconEmbedded name="info-simple_4" /></template>
            <template #text>
              <div>
                <h5 class="global-h5" style="margin-bottom: 4px; color: inherit">Almost There</h5>
                <div>Fill out the remaining details to make your adventure top notch!</div>
              </div>
            </template>
          </NoteWithIcon>

          <div class="tabs-error-indicator-wrapper">
            <div :class="[stepsWithErrors.map(i => `red-dot-over-${i - 1}`).join(' '), isSummaryHasErrors ? 'red-dot-over-summary' : '']">
              <SrpTabs
                :activeTab="activeTab"
                @update:activeTab="
                  tabName => {
                    activeTab = tabName;
                    tabClicked(transformTabNameToId(tabName));
                  }
                "
                :key="redrawCounter"
                style="margin-bottom: 20px"
                :style="{ opacity: isDeletingCollabImage ? 0.5 : 1, pointerEvents: isDeletingCollabImage ? 'none' : 'all' }"
                :class="{
                  'hide-plus-tab': !(itinerary.steps.length < maxStopCount),
                  'cms-tabs': true,
                  'cms-tabs--with-step-word': itinerary.steps.length <= 4 && !['tablet', 'mobile'].includes(screenSize),
                }"
                isKeepTabsAlive
              >
                <!--<VueTinyTabs id="stopTabs" :anchor="false" :closable="false" :hideTitle="true" @on-before="tabClicked" :key="redrawCounter">-->
                <template #[i+1] v-for="(stop, i) in itinerary.steps" :key="`StopTab-${i}`">
                  <!--Stops-->
                  <div class="section" :id="`${tabStopIdPrefix}${i}`">
                    <!--Tab Content-->
                    <StopsForm
                      :data="stop"
                      :useCollabLocationsLogic="useCollabLocationsLogic"
                      :allStops="itinerary.steps"
                      :stopTabIndex="i"
                      :images="images"
                      :collabImagesUsed="collabImagesUsed"
                      :collabImagesUnused="collabImagesUnused"
                      :firstStop="firstStop"
                      :hasRemove="itinerary.steps.length > 2"
                      :isFirst="i === 0"
                      :isEnd="i === itinerary.steps.length - 1"
                      :isCollab="isCollab"
                      :minStopDetailsChars="minStopDetailsChars"
                      :collabLocations="collabLocations"
                      @remove="askToConfirmDeleteStep"
                      @updated="updatedStop"
                      @placeChanged="(step, index, callback) => placeChanged(step, index, callback)"
                      @markerManuallyMoved="setRecalculateMaps"
                      @shiftStep="shiftStep"
                      @imageUploadedToServer="(image, autoSelect) => onImageUploadedToServer(image, autoSelect, stop)"
                      @toggleImageSelection="onToggleImageSelection"
                      @removeMediaFile="removeMediaFile"
                      :fieldsWithErrors="fieldsWithErrors?.find(item => item?.step === i + 1)?.fields || []"
                      @fixTheFieldError="removeFieldFromErrors"
                      :isTabActive="activeTab === `${i + 1}`"
                      :collabId="itinerary?.collabInputId"
                      :communityId="itinerary?.createdFor"
                      :itineraryId="itinerary?.id"
                      :ref="`stopsForm${i}`"
                    />
                  </div>
                </template>

                <template #[`+`]>
                  <!--Add Stop-->
                  <div class="section" id="addTabSection">
                    <h3>...</h3>
                  </div>
                </template>

                <template #[`Summary`]>
                  <!--Summary-->
                  <div class="section" id="summaryTabSection">
                    <SummaryForm
                      ref="summaryForm"
                      :itinerary="itinerary"
                      :distanceOptions="distanceOptions"
                      :images="mediaForSummary"
                      :collabImagesUsed="collabImagesUsed"
                      :collabImagesUnused="collabImagesUnused"
                      :minStopDetailsChars="minStopDetailsChars"
                      :isCollab="isCollab"
                      @removeItinerary="askToConfirmRemove"
                      @deleteDraftItinerary="askToConfirmDelete"
                      @useWalkingDirectionsChanged="setRecalculateMaps"
                      @updateCreatedFor="updateCreatedFor"
                      @imageUploadedFromCoverPhotoForm="onImageUploadedFromCoverPhotoForm"
                      @signatureVideoUploaded="onSignatureVideoUploaded"
                      @croppedCoverPhotoSelected="onCroppedCoverPhotoSelected"
                      @toggleImageSelection="onToggleImageSelection"
                      @removeMediaFile="removeMediaFile"
                      :fieldsWithErrors="fieldsWithErrors?.find(item => item?.step === 'summary')?.fields || []"
                      @fixTheFieldError="removeFieldFromErrors"
                      :isTabActive="activeTab === 'Summary'"
                      @requestSave="saveButtonClick"
                    />
                  </div>
                </template>

                <!--</VueTinyTabs>-->
              </SrpTabs>
            </div>
          </div>

          <div class="ui two column stackable grid">
            <div class="column">
              <span v-if="errorMessage?.length > 0">
                <!--<div class="error-message-heading">{{ errorMessageHeading }}</div>
                  <div class="error-message">{{ errorMessage }}</div>
                  <div style="margin-top:8px; font-size: 1.05em;">
                    <a target="_blank" :href="globalGetLink('AdventureCreationTips')">Adventure Creation Tips <span style="text-decoration: underline;">here</span></a>
                  </div>-->
                <SrpButton v-if="isSuperOrSalesUser" @click="superPublishOverride" color="gray" fill="outlined" style="margin-top: 25px" size="small">(super) Override Publish</SrpButton>
                <br /><br />
              </span>
            </div>
            <div class="column">
              <div v-if="savedMessage?.length > 0">
                <div class="saved-message">
                  {{ savedMessage }}
                </div>
                <br /><br />
              </div>
              <ButtonGroup
                :index="activeTabIndex"
                :isEndStop="activeTabIndex === itinerary.steps.length - 1"
                :isSummary="activeTabIndex > itinerary.steps.length - 1"
                :maxStopCount="maxStopCount"
                @addStop="addStop"
                @goToTab="goToTab"
                @goToSummary="goToSummary"
                @save="saveButtonClick"
                @preview="previewItinerary"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--<FeedbackReviewRibbon v-if="feedbackFeatureEnabled" />-->

    <!--Publish Modal-->
    <SrpModal v-model:isVisible="showPublishModal" size="small">
      <template #header>
        <h2 class="global-h2">Are you ready to publish this adventure for others to see?!</h2>
      </template>
      <template #footer>
        <SrpButton @click="showPublishModal = false" color="gray" fill="outlined" style="margin-right: 10px">No</SrpButton>
        <SrpButton @click="publishConfirmed">Yep, I'm Finished!</SrpButton>
      </template>
    </SrpModal>
    <!--Unsaved Changes Modal-->
    <SrpModal v-model:isVisible="showUnsavedChangesModal">
      <template #header>
        <h2 class="global-h2">There are unsaved changes, are you sure you want to close?</h2>
      </template>
      <template #footer>
        <SrpButton @click="showUnsavedChangesModal = false" style="margin-right: 10px">No</SrpButton>
        <SrpButton @click="cancelEditConfirmed" color="gray" fill="outlined">Yep, I've saved what I need</SrpButton>
      </template>
    </SrpModal>
    <!--Delete Itinerary Modal-->
    <SrpModal v-model:isVisible="showDeleteModal" size="small">
      <template #header><h2 class="global-h2">Are you sure you want to delete this adventure?</h2></template>
      <template #footer>
        <SrpButton @click="cancelDelete" color="gray" fill="outlined" style="margin-right: 10px">Cancel</SrpButton>
        <SrpButton @click="deleteItinerary" color="orange">Yep, delete it</SrpButton>
      </template>
    </SrpModal>
    <!--Remove Itinerary Modal-->
    <SrpModal v-model:isVisible="showRemoveModal" size="small">
      <template #header><h2 class="global-h2">Are you sure you want to remove this adventure?</h2></template>
      <template #footer>
        <SrpButton @click="cancelRemove" color="gray" fill="outlined" style="margin-right: 10px">Cancel</SrpButton>
        <SrpButton @click="removeItinerary" color="orange">Yep, remove it</SrpButton>
      </template>
    </SrpModal>
    <!--Delete Step Modal-->
    <SrpModal v-model:isVisible="showDeleteStepModal" size="small">
      <template #header>
        <h2 class="global-h2">Are you sure you want to remove this stop?</h2>
      </template>
      <template #footer>
        <SrpButton @click="cancelDeleteStep" color="gray" fill="outlined" style="margin-right: 10px">Cancel</SrpButton>
        <SrpButton @click="deleteStepInContext" color="orange">Yep, remove it</SrpButton>
      </template>
    </SrpModal>

    <CmsEditorCollabLinkModal v-if="creatorsActiveCollabs" v-model:isVisible="showCollabSelectionModal" :collabs="creatorsActiveCollabs" @click:collab="linkAdventureToCollab" />

    <div class="ui dimmer" :class="{ active: isLoading }">
      <div class="ui text loader">Loading</div>
    </div>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import { defineComponent, inject, computed, toRaw, ref } from "vue";
import ItineraryRepo from "@repos/ItineraryRepo";
import { RouteHelper } from "@helpers/RouteHelper";
import UploadedImageRepo from "@repos/UploadedImageRepo";
import { Sapling } from "@saplingai/sapling-js/observer";
import logger from "@helpers/Logger";
import lodashUniqBy from "lodash-es/uniqBy";
import FeatureFlags from "@logic/FeatureFlags";
import setMapFieldsOnAdventure from "@logic/CmsAdventureHelper";

// Types
import { Itinerary, ItineraryStep, StepFieldsWithError } from "@contracts/itinerary";
import { ScreenSize } from "@contracts/screenSize";
import { UploadedImage } from "@contracts/uploadedImage";

// Components
import AdventureGuideModal from "@components/AdventureGuideModal/index.vue";
import ButtonGroup from "./ButtonGroup.vue";
import CmsAdventureCollabNotification from "./CmsAdventureCollabNotification.vue";
import CmsEditorCollabLinkModal from "./CmsEditorCollabLinkModal.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpFileThumbnail from "@components/ui/SrpFileThumbnail.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import SrpTabs from "@components/ui/SrpTabs.vue";
import StopsForm from "./StopsForm/index.vue";
import SummaryForm from "./SummaryForm.vue";
import UploadPhotoForm from "./UploadPhotoForm.vue";
import { useCollabLocationsStore } from "@stores/collabLocations";
import { setLogLevel } from "@azure/logger";

// Stores
import { mapState, mapActions } from "pinia";
import { useUserProfileStore } from "@stores/userProfileStore";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import { RemoteLogger } from "@helpers/RemoteLogger";
import { CreatorCollabSummary } from "@contracts/collab";
import { useHead } from "@unhead/vue";
import FileUtils from "@logic/FileUtils";
import { useToastsStore } from "@stores/toasts";

export default defineComponent({
  name: "CmsEditor",

  components: {
    IconEmbedded,
    AdventureGuideModal,
    ButtonGroup,
    CmsAdventureCollabNotification,
    CmsEditorCollabLinkModal,
    LinkWithIcon,
    NoteWithIcon,
    SrpButton,
    SrpFileThumbnail,
    SrpModal,
    SrpTabs,
    StopsForm,
    SummaryForm,
    UploadPhotoForm,
  },

  provide() {
    return {
      itinerary: computed(() => this.itinerary),
    };
  },

  data() {
    return {
      collabLocationsStore: useCollabLocationsStore(),
      globalLog: inject("globalLog") as any,
      globalRemoteLogger: inject("globalRemoteLogger") as RemoteLogger,
      globalGetLink: inject("globalGetLink") as any,

      screenSize: inject("screenSize") as ScreenSize,

      activeTab: "1",

      maxStopCount: 7,
      itinerary: null as Itinerary | null,
      initialLoadComplete: false,
      isLoading: true,
      errorMessage: null as string | null,
      errorMessageHeading: null as string | null,
      savedMessage: null as string | null,
      lastSaveDateTime: null as Date | null,
      lastAssetUploaded: null as Date | null,
      uploadImageSaveTimer: null as NodeJS.Timeout | null,
      // If something has changed on the itinerary but has not been saved
      dirty: false,
      showPhotoUploadScreen: true,
      collabImagesUsed: [],
      collabImagesUnused: [],
      // Tabs
      tabStopIdPrefix: "StopTabSection-",
      // Deprecated: Hack needed to make tabs reactive: https://github.com/mevinoth/vue-tiny-tabs/issues/2
      redrawCounter: 1,
      redrawingTabs: false,
      activeTabIndex: 0,

      reCalculateMaps: false,

      showPublishModal: false,
      showUnsavedChangesModal: false,
      showDeleteModal: false,
      showRemoveModal: false,
      showDeleteStepModal: false,
      // Currently used for delete step confirm
      stepInContextIndex: -1,

      distanceOptions: [],

      // Validation constants (most of these are in the validate method)
      minCollabStopDetailsChars: 300, // characters, also used for What to Expect
      minNonCollabStopDetailsChars: 150, // characters, also used for What to Expect

      fieldsWithErrors: [] as Array<StepFieldsWithError>,

      isSaplingEnabled: true,

      isInitialScreenDeleteMode: false,

      isLoadingCollabImages: false,
      isDeletingCollabImage: false,

      isAdventureGuideModalVisible: false,
      // Drives if we force them to go through the wizard or let them close it.
      isFirstAdventureGuidance: false,

      showCollabSelectionModal: false,
      // Used by the collab selection modal (in the rare case that is used, normally they come in with a collabId from that flow)
      creatorsActiveCollabs: null as CreatorCollabSummary[] | null,
      // The collab tied to this adventure (if it has a collabInputId)
      collabForItinerary: null as CreatorCollabSummary | null,
      renderPhotoUploadSection: true,

      toastsStore: useToastsStore(),
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["getViewingUserProfile", "isSuperOrSalesUser"]),
    collabLocations() {
      return this.collabLocationsStore.locations;
    },
    images(): UploadedImage[] {
      if (this.useCollabLocationsLogic) {
        return this.mediaFromCollabLocations;
      }
      return this.mediaFromItineraryAndSteps;
    },
    mediaFromCollabLocations(): UploadedImage[] {
      return this.collabLocations?.reduce<UploadedImage[]>((acc, location) => {
        const imagesFromLocation = location.mediaIds.map<UploadedImage>(serverId => ({ serverId }) as UploadedImage);
        acc.push(...imagesFromLocation);
        return acc;
      }, []);
    },
    mediaFromItineraryAndSteps(): UploadedImage[] {
      return UploadedImageRepo.loadUploadedImages(this.itinerary);
    },
    /**
     * Media for summary includes all media from itinerary and steps as well as
     * media from collab locations is appropriate.
     *
     * This is case because "unusedImages" is used as a bucket for "additional" media
     * for things cover photos and signature videos.
     */
    mediaForSummary(): UploadedImage[] {
      const mediaIds = [...this.mediaFromItineraryAndSteps];
      if (this.useCollabLocationsLogic) {
        mediaIds.push(...this.mediaFromCollabLocations);
      }

      return lodashUniqBy(mediaIds, "serverId");
    },
    stepsWithErrors(): Array<number> {
      return this.fieldsWithErrors.filter(i => Number.isInteger(i.step) && i.fields.length).map(i => Number(i.step));
    },
    isSummaryHasErrors(): boolean {
      return Boolean(this.errorMessage?.includes("Overall Adventure:"));
    },
    showShortTabName(): boolean {
      return this.itinerary.steps.length >= 3;
    },
    firstStop(): ItineraryStep | null {
      return this.itinerary?.steps?.length > 0 ? this.itinerary.steps[0] : null;
    },
    minStopDetailsChars(): number {
      if (this.itinerary?.collabInputId?.trim().length > 0) return this.minCollabStopDetailsChars;
      else return this.minNonCollabStopDetailsChars;
    },
    isCollab(): boolean {
      return this.itinerary?.collabInputId?.trim().length > 0;
    },
    useCollabLocationsLogic(): boolean {
      if (!this.collabForItinerary) return false;
      return this.collabForItinerary.useLocationsInAdventures;
    },
  },

  watch: {
    itinerary: {
      async handler(newVal, oldVal) {
        if (oldVal != null) {
          this.globalLog.info("isDirty=true");
          this.dirty = true;
        }

        if (this.itinerary.steps.length === 0) {
          this.addStop();
        }

        // Update: This caused a ridiculous amount of calls to the cms-images endpoint
        /* const { collabInputId, createdFor, id } = this.itinerary;
        await this.getCollabImages(collabInputId, createdFor, id);*/
      },
      deep: true,
    },
    // Ideally we'd remove this also,
    // but it seems to be needed for the photos selected on other stops to show up correctly in the unused section
    async activeTab() {
      const { collabInputId, createdFor, id } = this.itinerary;
      await this.getCollabImages(collabInputId, createdFor, id);
    },
  },

  async mounted() {
    useHead({ title: "Adventure Editor" });

    // Debugging the Azure SDK calls (in particular BlockBlobClient uploadData)
    // setLogLevel("info"); // Options: "info", "warning", "error", "verbose"

    // Note: This needs to happen early (before the mount calls on the child components)
    this.initializeSapling(this.isSaplingEnabled);

    // Creator filter for Collab Selection
    const creatorIdOverride = RouteHelper.getQueryStringParam("creatorId");
    if (creatorIdOverride?.length > 0) {
      await this.impersonateProfile(creatorIdOverride);
    }

    await this.loadData();
    const distanceResult = await axios.get(`${import.meta.env.VITE_API_URL}/config/distanceOptions`);
    this.distanceOptions = distanceResult.data;

    this.checkForShowFirstAdventureGuidance(this.getViewingUserProfile.sherpaId);
    this.checkForExistingCollabsToLink();
  },

  methods: {
    ...mapActions(useUserProfileStore, { impersonateProfile: "impersonateProfile" }),

    async saveAdventureAndNavigateToCollabOpportunity() {
      if (this.dirty) {
        await this.saveItinerary(false, null, false);
      }

      this.$router.push({
        name: "CollabOpportunity",
        params: { communityId: this.collabForItinerary.communityId, collabInputId: this.collabForItinerary.collabInputId, creatorId: this.getViewingUserProfile.sherpaId },
      });
    },
    async checkForExistingCollabsToLink() {
      // Note: Run these checks first since they're MUCH cheaper than the collabs api call
      const collabInputId = this.getCollabInputIdFromQueryString();
      const customerId = this.getCustomerIdFromQueryString();
      const id = this.getItineraryIdFromQueryString();
      // don't show the collab linking modal
      if (
        // an existing collab is already being edited
        id ||
        // or the CMS was entered with collab adventure data for automatic linking
        (collabInputId && customerId)
      )
        return;

      // Update: Call the my/active endpoint to get collabs (so the creator can link to a collab)
      let uri = `${import.meta.env.VITE_API_URL}/collabs/my/active`;
      if (this.isSuperOrSalesUser === true) {
        uri += `?creatorIdOverride=${this.getViewingUserProfile?.sherpaId}`;
      }
      const { data } = await axios.get<CreatorCollabSummary[]>(uri);
      this.creatorsActiveCollabs = data;
      const hasCollabs = this.creatorsActiveCollabs?.length > 0;
      if (!hasCollabs) return;

      this.showCollabSelectionModal = true;
    },
    async loadCollabForAdventure() {
      // Note: Updated to just get the collab this adventure is for
      if (this.itinerary.collabInputId && this.itinerary.createdFor) {
        this.globalLog.info("Loading collab: " + this.itinerary.collabInputId);
        let uri = `${import.meta.env.VITE_API_URL}/collabs/${this.itinerary.createdFor}/inputs/${this.itinerary.collabInputId}/creator-summary`;
        if (this.isSuperOrSalesUser === true) {
          uri += `?creatorIdOverride=${this.getViewingUserProfile?.sherpaId}`;
        }
        const { data } = await axios.get<CreatorCollabSummary>(uri);
        this.collabForItinerary = data;
      }
    },
    async getCollabImages(collabId, communityId, itineraryId = "") {
      if (!collabId || !communityId) {
        return;
      }
      let uri = `${import.meta.env.VITE_API_URL}/cms-images/collab/${collabId}/customer/${communityId}`;
      if (itineraryId) uri += `?adventureIdToExclude=${itineraryId}`;

      this.isLoadingCollabImages = true;
      const { data } = await axios.get(uri);
      if (data.usedAssets?.length) this.collabImagesUsed = data.usedAssets;
      if (data.unusedAssets?.length) this.collabImagesUnused = data.unusedAssets;
      this.isLoadingCollabImages = false;
    },
    // Set isAdventureGuideVisible based on the call to /api/cms/itineraries/show-guidance
    async checkForShowFirstAdventureGuidance(creatorIdOverride: string | null) {
      // Only show this if they're creating a new adventure (not editing one)
      if (this.getItineraryIdFromQueryString()) return;

      let uri = `${import.meta.env.VITE_API_URL}/cms/itineraries/show-guidance`;
      if (creatorIdOverride) {
        uri += `?creatorIdOverride=${creatorIdOverride}`;
      }
      const result = await axios.get(uri);
      // if isAdventureGuideModalVisible === true it means that the user opened it manually before this function finished its execution which means that we shouldn't open or close it anymore
      if (!this.isAdventureGuideModalVisible) {
        this.isAdventureGuideModalVisible = result.data;
      }
      this.isFirstAdventureGuidance = result.data;
    },
    transformTabIdToName(tabId: string): string {
      if (Number.isInteger(+tabId.replace(this.tabStopIdPrefix, ""))) {
        return String(+tabId.replace(this.tabStopIdPrefix, "") + 1);
      } else {
        return { addTabSection: "+", summaryTabSection: "Summary" }[tabId];
      }
    },
    transformTabNameToId(tabName: string): string {
      if (Number.isInteger(+tabName)) {
        return `${this.tabStopIdPrefix}${+tabName - 1}`;
      } else {
        return { "+": "addTabSection", Summary: "summaryTabSection" }[tabName];
      }
    },
    removeFieldFromErrors(step, fieldName) {
      let fieldsList = this.fieldsWithErrors?.find(item => item.step === step)?.["fields"];
      if (!fieldsList) return;

      this.fieldsWithErrors.find(item => item?.step === step)["fields"] = fieldsList.filter(item => item.field !== fieldName);
      if (fieldName === "images") {
        fieldsList = this.fieldsWithErrors?.find(item => item.step === "summary")?.["fields"];
        if (fieldsList) {
          this.fieldsWithErrors.find(item => item?.step === "summary")["fields"] = fieldsList.filter(item => item.field !== "uniqueImages");
        }
      }
      this.fieldsWithErrors = [...this.fieldsWithErrors];
    },

    addStop() {
      if (this.itinerary.steps.length > 6) return;
      const newStep: ItineraryStep = {
        collabLocationId: null,
        address: null,
        city: null,
        country: null,
        customLinkText: null,
        customLinkUri: null,
        details: null,
        externalId: null,
        images: [],
        latitude: null,
        longitude: null,
        proTip: null,
        state: null,
        title: null,
        website: null,
        distanceToNextStopInMeters: null,
        timeToNextStopInSeconds: null,
      };
      this.itinerary.steps.push(newStep);
      this.activeTabIndex = this.itinerary.steps.length - 1;

      this.redrawTabs();
      // The redraw above has to take effect first
      this.$nextTick(() => {
        this.goToTab(this.activeTabIndex);
        this.redrawingTabs = false;
      });
    },
    redrawTabs() {
      // See the comment in tabClicked()
      this.redrawingTabs = true;
      this.redrawCounter++;
    },
    goToTab(index: number) {
      // activateTab manages activeTab, activeTabIndex, and saving
      this.activateTab(`${this.tabStopIdPrefix}${index}`);
    },
    goToSummary() {
      // activateTab manages activeTab, activeTabIndex, and saving
      this.activateTab("summaryTabSection");
    },
    activateTab(tabId: string): void {
      // The tab library doesnt' have a "select this tab event" so this does effectively that
      // let tab = document.querySelector(`[data-id="${tabId}"]`);
      // @ts-ignore
      // tab.click();
      this.activeTab = this.transformTabIdToName(tabId);
      this.tabClicked(tabId);
    },
    tabClicked(id, tab?) {
      // NOTE: This gets called when we change redrawCounter and 0 is passed,
      // which can corrupt activeTabIndex, hence the extra check there
      if (id === "addTabSection") {
        this.addStop();
      } else if (id === "summaryTabSection") {
        this.activeTabIndex = this.itinerary.steps.length;
      } else if (id.startsWith(this.tabStopIdPrefix) && !this.redrawingTabs) {
        this.activeTabIndex = +id.substring(this.tabStopIdPrefix.length);
      }

      // Save! NOTE: We now save on next/back/tab change (reduces the likelihood of creators loosing content)
      if (this.dirty) {
        this.saveItinerary(false, null, false);
      }
    },
    updatedStop(updatedStep: ItineraryStep, index: number) {
      this.globalLog.info("Stop " + index + " Update.  value=" + updatedStep.title + " Tip=" + updatedStep.proTip + " Addr=" + updatedStep.address);
      // Need to use this or Vue won't see the change
      this.itinerary.steps[index] = updatedStep;
    },
    getCollabInputIdFromQueryString(): string | null {
      const id = RouteHelper.getQueryStringOrHashParam("collabInputId");
      return id || null;
    },
    getItineraryIdFromQueryString(): string | null {
      const id = RouteHelper.getQueryStringOrHashParam("id");
      return id || null;
    },
    getCustomerIdFromQueryString(): string | null {
      return RouteHelper.getQueryStringOrHashParam("customerId") || null;
    },
    async loadData() {
      this.isLoading = true;

      // NOTE! Load the adventure first so we can set impersonation (some of the downstream calls require it)
      const id = this.getItineraryIdFromQueryString();
      this.itinerary = await ItineraryRepo.getItinerary(id, this.getViewingUserProfile.sherpaId);
      if (this.isSuperOrSalesUser === true) {
        await this.impersonateProfile(this.itinerary.sherpaId);
      }

      // only default collabInput and createdFor if it's a new adventure
      if (!id) {
        this.itinerary.collabInputId = this.getCollabInputIdFromQueryString();
        this.itinerary.createdFor = this.getCustomerIdFromQueryString();
      }
      await this.loadCollabForAdventure();
      await this.loadCollabLocationsData();
      // this.images = UploadedImageRepo.loadUploadedImages(this.itinerary);
      this.setShowPhotoUploadScreen();
      if (this.showPhotoUploadScreen === false) {
        // after initial data load, if the photo screen is not shown
        // set this to false to prevent the photo screen from being inserted into the DOM
        // for more information see comment at the variable's usage
        this.renderPhotoUploadSection = false;
      }

      this.isLoading = false;

      const { collabInputId, createdFor, id: itineraryId } = this.itinerary;
      await this.getCollabImages(collabInputId, createdFor, itineraryId);

      this.markNotDirty();
      this.initialLoadComplete = true;
    },
    async loadCollabLocationsData() {
      if (this.itinerary.collabInputId && this.itinerary.createdFor) {
        // if it's a collab adventure, load collab locations data
        await this.collabLocationsStore.loadCollabLocationsData(this.itinerary.collabInputId, this.getViewingUserProfile.sherpaId, this.itinerary.createdFor);
      } else {
        // otherwise, just reset the data
        this.collabLocationsStore.reset();
      }
    },
    async saveCollabLocationsData() {
      // if it's a collab adventure, save collab locations data
      if (this.itinerary.collabInputId && this.itinerary.createdFor) {
        await this.collabLocationsStore.saveCollabLocationsData(this.itinerary.createdFor, this.itinerary.collabInputId, this.getViewingUserProfile.sherpaId);
      }
    },
    async linkAdventureToCollab(collab: CreatorCollabSummary) {
      this.itinerary.collabInputId = collab.collabInputId;
      this.itinerary.createdFor = collab.communityId;
      this.collabForItinerary = collab;
      // now that a collab has been selected, fetch the collab's location data
      await this.loadCollabLocationsData();
      // and the other collab images
      const { collabInputId, createdFor, id: itineraryId } = this.itinerary;
      await this.getCollabImages(collabInputId, createdFor, itineraryId);
      this.showCollabSelectionModal = false;
      // bypass initial photo upload, that should have already been done when the
      // collab locations were set up
      this.showPhotoUploadScreen = false;
    },
    setShowPhotoUploadScreen() {
      // collabs that use "collab locations" should never display the photo upload screen
      if (this.collabForItinerary?.useLocationsInAdventures === true) {
        this.showPhotoUploadScreen = false;
        return;
      }
      // Now that itinerary.Id is set immediately, we'll use the id query param to see if this is a brand new adventure or existing.
      // We only show the photo upload screen if this is a brand new adventure
      const existingId = RouteHelper.getQueryStringOrHashParam("id");
      if (existingId) {
        this.showPhotoUploadScreen = false;
      } else {
        this.showPhotoUploadScreen = true;
      }
    },
    // Called anytime a place is added, updated, or removed
    placeChanged(step: ItineraryStep | null, index: number, callback: () => void) {
      // NOTE! The watch that syncs the step update to the itinerary property
      // is typically delayed so we update the itinerary directly here (or we could wait for the watch)
      if (index >= 0) {
        this.itinerary.steps[index] = step;
      }

      setMapFieldsOnAdventure(this.itinerary, null);
    },

    async saveButtonClick(shouldPublish: boolean) {
      if (shouldPublish) {
        // Verify modal
        this.showPublishModal = true;
      } else {
        this.saveItinerary(shouldPublish, null, false);
      }
    },
    async superPublishOverride() {
      // Allows superusers to bypass some of the validation
      this.saveItinerary(true, null, true);
    },
    async publishConfirmed() {
      this.saveItinerary(true, null, false);
    },
    async saveItinerary(shouldPublish: boolean, callback: () => void, superValidationOverride: boolean) {
      // The annoying google callback makes us split up the save method
      if (this.reCalculateMaps) {
        this.globalLog.info("Recalculating the map and directions");
        // Use the placeChanged logic to re-calc the map and directions
        setMapFieldsOnAdventure(this.itinerary, () => {
          this.reCalculateMaps = false;
          // Save and let that fire the callback
          this.saveItineraryWithoutMapRecalcCheck(shouldPublish, callback, superValidationOverride);
        });
      } else {
        this.saveItineraryWithoutMapRecalcCheck(shouldPublish, callback, superValidationOverride);
      }

      const { collabInputId, createdFor, id } = this.itinerary;
      await this.getCollabImages(collabInputId, createdFor, id);
    },
    async saveItineraryWithoutMapRecalcCheck(shouldPublish: boolean, callback: () => void, superValidationOverride: boolean, skipValidation = false) {
      try {
        const isFirstSave = this.itinerary.id === null;
        this.savedMessage = null;
        this.globalLog.info(`Saving! ${this.itinerary.title}, StopCount=${this.itinerary.steps.length}, shouldPublish=${shouldPublish}, isFirstSave=${isFirstSave}`);
        // Check if they have a selected but "un-chosen" cover photo
        await this.checkForSelectedButNotChosenImage();

        // Ensure they can publish/save
        if (shouldPublish && !superValidationOverride && !this.validatePublish()) {
          this.showPublishModal = false;
          return;
        }
        this.cancelDelete();
        if (!skipValidation) this.isLoading = true;
        this.savedMessage = "Saving...";
        // for testing: await new Promise(resolve => setTimeout(resolve, 5000));

        this.lastSaveDateTime = new Date();
        let [saveResult] = await Promise.all([ItineraryRepo.saveItinerary(this.itinerary, shouldPublish), this.saveCollabLocationsData()]);
        this.itinerary = saveResult.itinerary;
        this.globalLog.info("Saved itinerary " + this.itinerary.id);
        // Check if they just became eligble
        if (saveResult.justBecamePaidEligible) {
          this.globalLog.info(`Creator just became paid eligible! creator=${this.itinerary.sherpaId}, itinerary=${this.itinerary.id}`);
          this.getViewingUserProfile.collabPreferences.isCollabEligible = true;
        }

        // If this is the first save put the id on the hash (so if they refresh they get their saved adventure back)
        if (!RouteHelper.getQueryStringOrHashParam("id")) {
          window.location.hash = "id=" + this.itinerary.id;
        }
        this.markNotDirty();
        this.isLoading = false;
        this.savedMessage = "Saved!";
        setTimeout(() => {
          // Hide the saved message after a few seconds
          this.savedMessage = null;
        }, 5000);
        if (callback) {
          callback();
        }
        if (shouldPublish) {
          const queryParams = {};
          // If they just became paid eligible, send them back to the dashboard with a special modal
          if (saveResult.justBecamePaidEligible) {
            // @ts-ignore
            queryParams.justBecamePaidEligible = "true";

            this.$router.push({
              name: "CreatorDashboard",
              params: { creatorId: this.getViewingUserProfile.sherpaId },
              query: queryParams,
            });
            return;
          }

          // new logic for handling publish
          // if the adventure is for a collab, go to the collab opportunity page. If not, go to my itineraries
          if (this.isSuperOrSalesUser === true) {
            // @ts-ignore
            queryParams.creatorId = this.getViewingUserProfile.sherpaId;
          }
          if (this.itinerary.collabInputId && this.itinerary.createdFor) {
            this.$router.push({
              name: "CollabOpportunity",
              params: { communityId: this.itinerary.createdFor, collabInputId: this.itinerary.collabInputId, creatorId: this.getViewingUserProfile.sherpaId },
            });
          } else {
            this.$router.push({ name: "MyItineraries", query: queryParams });
          }
        }
      } catch (error) {
        this.globalRemoteLogger.error(`Failed to save itinerary adv=${this.itinerary?.id} by ${this.itinerary?.sherpaId}: ${error.message}`, true);
        this.isLoading = false;
        this.savedMessage = null;
        throw error;
      }
    },
    async updateCreatedFor() {
      await ItineraryRepo.updateCreatedFor(this.itinerary);
      this.toastsStore.success({ message: "Updated CreatedFor and SharesWith", expiresInMs: 7000 });
    },
    previewItinerary: function () {
      // Only save if something has changed...
      if (this.dirty) {
        this.globalLog.info("Saving before preview");
        this.saveItinerary(
          false,
          () => {
            window.open("/itinerary/" + this.itinerary.id + "?Status=Draft", "_blank");
          },
          false
        );
      } else {
        window.open("/itinerary/" + this.itinerary.id + "?Status=Draft", "_blank");
      }
    },
    async checkForSelectedButNotChosenImage() {
      const summaryForm = this.$refs.summaryForm;
      // If the user hasn't navigated to the summary this is null
      if (!summaryForm) return;

      // @ts-ignore
      const uploadedCoverPhoto = await summaryForm.checkForSelectedButNotChosenImage();
      if (uploadedCoverPhoto != null) this.itinerary.tileImageLocation = uploadedCoverPhoto;
    },
    // NOTE: Also fires a remote log
    validatePublish() {
      // NOTE: Now that we've added inline validation we can retire the errorMessage, stepErrors, and adventureErrors fields
      // Note: This has gotten pretty big now, should probably be it's own object
      const isCollab = this.itinerary.collabInputId?.trim().length > 0;
      let navToSummary = false;
      let navigateToStop = null;
      const minCollabPhotosPerStop = 2;
      const minCollabTotalPhotos = 10;
      const minDescriptionChars = isCollab ? this.minCollabStopDetailsChars : this.minNonCollabStopDetailsChars;
      this.errorMessage = "";
      let remoteLogDetails = ""; // Abbreviated version of the error message for remote logging

      // Validate the steps
      for (let i = 0; i < this.itinerary.steps.length; i++) {
        const step = this.itinerary.steps[i];
        let stepErrors = "";
        this.fieldsWithErrors[i] = { step: i + 1, fields: [] };

        if (step.details == null || step.details.trim().length < minDescriptionChars) {
          const errorMsg = "Add more details to help guide travelers!";
          stepErrors += ` - ${errorMsg}\n`;
          this.fieldsWithErrors[i]["fields"].push({ field: "details", errorMsg });
        }
        if (step.collabLocationId == null && this.useCollabLocationsLogic) {
          const errorMsg = "You must select a location for this stop!";
          stepErrors += ` - ${errorMsg}\n`;
          this.fieldsWithErrors[i]["fields"].push({ field: "collabLocationId", errorMsg });
        } else if (step.latitude == null || step.longitude == null) {
          const errorMsg = this.useCollabLocationsLogic ? "Location is required: manually add a map marker" : "Location is required: use the autocomplete or manually add a map marker";
          stepErrors += ` - ${errorMsg}\n`;
          this.fieldsWithErrors[i]["fields"].push({ field: "latitude", errorMsg });
        } else if (step.title == null || step.title.trim().length === 0) {
          // Only show this if they've chosen a location
          const errorMsg = "Add a Title";
          stepErrors += ` - ${errorMsg}\n`;
          this.fieldsWithErrors[i]["fields"].push({ field: "title", errorMsg });
        }

        // Even non-collabs require a photo per stop now
        if (isCollab && (step.images == null || step.images.length < minCollabPhotosPerStop)) {
          const errorMsg = `Include at least ${minCollabPhotosPerStop} photos or videos per stop!`;
          stepErrors += ` - ${errorMsg}\n`;
          this.fieldsWithErrors[i]["fields"].push({ field: "images", errorMsg });
        } else if (step.images == null || step.images.length === 0) {
          const errorMsg = "Select some photos or videos!";
          stepErrors += ` - ${errorMsg}\n`;
          this.fieldsWithErrors[i]["fields"].push({ field: "images", errorMsg });
        }
        if (stepErrors?.length > 0) {
          const stopTitle = step.title?.trim().length > 0 ? "Stop: " + step.title : "Stop " + (i + 1);
          this.errorMessage += `${stopTitle}\n${stepErrors}`;
          if (navigateToStop == null)
            // Jump to this tab/stop
            navigateToStop = i;
        }
        remoteLogDetails += ` |Stop${i + 1}: t=${step.title?.trim().length ?? 0},d=${step.details?.trim().length ?? 0},i=${step.images?.length},p=${step.proTip?.length ?? 0}`;
      }

      let adventureErrors = "";
      // Must have 3 photos (collabs need 10)
      const minPhotos = isCollab ? minCollabTotalPhotos : 3;
      // Total image count must be unique (no re-use of the same photo), so using a Set
      const uniqueImages = new Set();

      this.itinerary.steps.forEach(step => {
        step.images.forEach(image => {
          uniqueImages.add(image);
        });
      });

      this.fieldsWithErrors[this.itinerary.steps.length] = { step: "summary", fields: [] };

      // All adventures need 3 stops now (upped the minimum to be the same as collabs)
      const minSteps = isCollab ? 3 : 3;

      if (this.itinerary.steps.length < minSteps) {
        const errorMsg = `Add at least ${minSteps} stops to help travelers explore!`;
        adventureErrors += ` - ${errorMsg}\n`;
        this.fieldsWithErrors[this.itinerary.steps.length]["fields"].push({ field: "uniqueImages", errorMsg });
      } else if (uniqueImages.size < minPhotos) {
        // Using this space for the stops validation if not met
        const errorMsg = `Add at least ${minPhotos} photos or videos total per adventure`;
        adventureErrors += ` - ${errorMsg}\n`;
        this.fieldsWithErrors[this.itinerary.steps.length]["fields"].push({ field: "uniqueImages", errorMsg });
      }
      // Title must be over 5 characters
      if (!this.itinerary.title || this.itinerary.title.length <= 5) {
        const errorMsg = this.itinerary.title?.trim()?.length > 0 ? "Title must be at least 5 characters" : "Add a Title";
        adventureErrors += ` - ${errorMsg}\n`;
        navToSummary = true;
        this.fieldsWithErrors[this.itinerary.steps.length]["fields"].push({ field: "title", errorMsg });
      }
      if (this.itinerary.whatToExpectText == null || this.itinerary.whatToExpectText.trim().length < minDescriptionChars) {
        const errorMsg = "Add more details to help guide travelers!";
        adventureErrors += ` - ${errorMsg}\n`;
        navToSummary = true;
        this.fieldsWithErrors[this.itinerary.steps.length]["fields"].push({ field: "whatToExpectText", errorMsg });
      }
      // Must have a cover photo
      if (!this.itinerary.tileImageLocation || this.itinerary.tileImageLocation.length === 0) {
        const errorMsg = "Add a cover image";
        adventureErrors += ` - ${errorMsg}.\n`;
        navToSummary = true;
        this.fieldsWithErrors[this.itinerary.steps.length]["fields"].push({ field: "tileImageLocation", errorMsg });
      }
      if (adventureErrors?.length > 0) {
        this.errorMessage += `Overall Adventure:\n${adventureErrors}`;
      }

      // Remove all of the steps with no errors
      this.fieldsWithErrors = this.fieldsWithErrors.filter(item => item.fields.length);

      this.errorMessageHeading = isCollab ? "Collaboration Adventure Requirements" : "Please complete the following before publishing:";

      const isValid = this.fieldsWithErrors.length === 0;

      // Log the publish
      try {
        remoteLogDetails =
          `PublishAdventure: creator=${this.itinerary.sherpaId} adv=${this.itinerary.id} isValid=${isValid} ${this.itinerary.uniqueName} Summary: ${
            this.itinerary.steps.length
          } steps, collab=${isCollab}, t=${this.itinerary.title?.trim().length ?? 0},d=${this.itinerary.whatToExpectText?.trim().length ?? 0},ci=${this.itinerary.tileImageLocation?.substring(0, 5)}` +
          remoteLogDetails;
        this.globalRemoteLogger.info(remoteLogDetails, true); // Immedately send the log
      } catch (e) {
        this.globalLog.error("Error sending RemoteLog", e);
      }

      // NOTE! This can cause the save to fire if isDirty=true (ex. we had to remove the errorMessage reset in that method because of this)
      if (navigateToStop != null) this.goToTab(navigateToStop);
      else if (navToSummary) this.goToSummary();

      return isValid;
    },
    // NOTE! We added these basically to keep track of the unusedPhotos collection.
    // At the same tried we wanted to remove some of the ugly watch/eventing logic around the images collection,
    // but then we scoped down this change to just set unusedPhotos.  Should revisit the eventing at some point.
    onImageUploadedToServer(image: UploadedImage, autoSelect: boolean, stop: ItineraryStep) {
      if (this.useCollabLocationsLogic) {
        // expecting to use collab locations logic,but the stop doesn't have the collab location set.
        if (!stop.collabLocationId) {
          this.globalRemoteLogger.error(`--ALERT--Collab Location not set for stop. CollabId=${this.itinerary.collabInputId}, ItineraryId=${this.itinerary.id}`);
          return;
        }
        const collabLocation = this.collabLocations?.find(loc => loc.id === stop.collabLocationId);
        if (!collabLocation) {
          this.globalRemoteLogger.error(`Collab Location not found for stop. CollabId=${this.itinerary.collabInputId}, CollabLocationId=${stop.collabLocationId}, ItineraryId=${this.itinerary.id}`);
          return;
        }
        collabLocation.mediaIds.push(image.serverId);
      } else {
        // NOTE: not adding/removing from the main images collection since that's handled by the watch logic
        if (!autoSelect && this.itinerary.unusedPhotos.indexOf(image.serverId) === -1) {
          this.itinerary.unusedPhotos.push(image.serverId);
        }
      }
      // Added auto-save periodically while uploading.
      // This helps if the user is uploading a lot of images and the browser reloads (ex. if the phone goes to sleep)
      // But skip it for the cover photo selection since that save often overwrites the title as the user is typing it
      let isImageCoverPhoto = image.serverId === this.itinerary.tileImageLocation || image.serverId === this.itinerary.uncroppedTileImageLocation;
      if (!isImageCoverPhoto) {
        this.lastAssetUploaded = new Date();
        this.setupTimerForAutosaveUploadedImages();
      } else this.globalLog.info("Skipping AssetUpload AutoSave for Cover image");

      // fixes the reactivity loss which prevents the images list update
      // this.images.push(image);
    },
    setupTimerForAutosaveUploadedImages(): void {
      // Shortcut if it's alraedy running
      if (this.uploadImageSaveTimer) return;

      const HowOftenToSaveDuringAssetUploadInMs = 7 * 1000;
      this.uploadImageSaveTimer = setInterval(() => {
        // Check if we've never saved or if the last asset as uploaded since we last saved
        if (!this.lastSaveDateTime || this.lastAssetUploaded > this.lastSaveDateTime) {
          this.globalLog.info("AssetUpload AutoSave");
          // autosave fork logic
          this.saveItineraryWithoutMapRecalcCheck(false, null, false, true);
        } else {
          // If nothing uploaded since we last saved, terminate the timer
          if (this.uploadImageSaveTimer) {
            this.globalLog.info("AssetUpload Clear AutoSave Timer");
            clearInterval(this.uploadImageSaveTimer);
            this.uploadImageSaveTimer = null;
          }
        }
      }, HowOftenToSaveDuringAssetUploadInMs);
    },
    onToggleImageSelection(image: UploadedImage, includeInStop: boolean) {
      if (includeInStop) {
        // Remove from unused
        this.itinerary.unusedPhotos = this.itinerary.unusedPhotos.filter(x => x !== image.serverId);
      } else {
        // Add to unused
        if (this.itinerary.unusedPhotos.indexOf(image.serverId) === -1) {
          this.itinerary.unusedPhotos.push(image.serverId);
        }
      }
    },
    onImageUploadedFromCoverPhotoForm(image: UploadedImage) {
      // when the image is uploaded from the cover photo form, it should be added to the unusedPhotos list
      // this is not the same as selecting and cropping an image. This is simply an upload
      this.itinerary.unusedPhotos.push(image.serverId);
      this.saveItineraryWithoutMapRecalcCheck(false, null, false, true);
    },
    onSignatureVideoUploaded(video: UploadedImage) {
      if (FileUtils.isVideoFileType(video.serverId)) this.itinerary.signatureVideo = video.serverId;
      else this.globalRemoteLogger.info(`SignatureVideo Upload - NOT a video: ${video.serverId}`);

      this.itinerary.unusedPhotos.push(video.serverId);
      this.saveItineraryWithoutMapRecalcCheck(false, null, false, true);
    },
    onCroppedCoverPhotoSelected(croppedImage: UploadedImage, uncroppedTileImageLocation: string) {
      // when a image is selected and cropped to be used as a cover photo.
      // this images are NOT added to the unusedPhotos list
      this.itinerary.tileImageLocation = croppedImage.serverId;
      this.itinerary.uncroppedTileImageLocation = uncroppedTileImageLocation;
      this.saveItineraryWithoutMapRecalcCheck(false, null, false, true);
    },
    async removeMediaFile(imageId: string, collabLocationId: string | null) {
      // Original logic: Remove from unused array at the adventure level
      if (this.itinerary.unusedPhotos.indexOf(imageId) > -1) {
        this.itinerary.unusedPhotos = this.itinerary.unusedPhotos.filter(x => x !== imageId);
        // this.images = this.images.filter(x => x.serverId !== imageId);
      }

      // Collabs store the unused images elsewhere now
      if (this.collabForItinerary) {
        // New Location-based logic stores at the location level
        if (this.collabForItinerary.useLocationsInAdventures) {
          const collabLocation = this.collabLocations?.find(loc => loc.id === collabLocationId);
          if (!collabLocation || !collabLocationId) {
            this.globalRemoteLogger.error(`--Alert--Missing CollabLocation removeMediaFile. ItineraryId=${this.itinerary.id}, collabLocationId=${collabLocationId}, imageId=${imageId}`);
            return;
          } else {
            // Remove it and save
            collabLocation.mediaIds = collabLocation.mediaIds.filter((id, _) => id !== imageId);
            this.lastAssetUploaded = new Date();
            this.setupTimerForAutosaveUploadedImages();
          }
        } else {
          // The old endpoint (before location-based logic)
          await this.removeMediaFileFromCollab(this.collabForItinerary.collabInputId, this.collabForItinerary.communityId, imageId);
        }
      }
    },
    async removeMediaFileFromCollab(collabId: string, communityId: string, imageId: string): Promise<void> {
      let uri = `${import.meta.env.VITE_API_URL}/cms-images/collab/${collabId}/customer/${communityId}?imageIdsToDelete=${imageId}`;

      this.isDeletingCollabImage = true;
      this.collabImagesUsed = this.collabImagesUsed.filter(i => i !== imageId);
      this.collabImagesUnused = this.collabImagesUnused.filter(i => i !== imageId);

      await axios.delete(uri);

      // const { collabInputId, createdFor, id } = this.itinerary;
      // await this.getCollabImages(collabInputId, createdFor, id);

      this.isDeletingCollabImage = false;
    },
    setRecalculateMaps() {
      this.globalLog.info("setRecalculateMaps: marking maps to be recalculated");
      this.reCalculateMaps = true;
    },

    markNotDirty() {
      // There seems to be a delay for when the watch executes after we assign the object after get/save
      // So wait a bit, ugly but works.
      setTimeout(() => {
        this.globalLog.info("Marking NOT dirty");
        this.dirty = false;
      }, 300);
    },

    askToConfirmDelete() {
      this.showDeleteModal = true;
    },
    askToConfirmRemove() {
      this.showRemoveModal = true;
    },
    cancelEdit() {
      // If isDirty they have unsaved changes so prompt them
      if (this.dirty) {
        this.showUnsavedChangesModal = true;
      } else {
        this.cancelEditConfirmed();
      }
    },
    cancelEditConfirmed() {
      let queryParams = {};
      if (this.isSuperOrSalesUser === true) {
        // @ts-ignore
        queryParams.creatorId = this.getViewingUserProfile.sherpaId;
      }
      this.$router.push({ name: "MyItineraries", query: queryParams });
    },
    cancelDelete() {
      this.showDeleteModal = false;
    },
    cancelRemove() {
      this.showRemoveModal = false;
    },
    askToConfirmDeleteStep(stepIndex: number) {
      // Only use Confirm dialog if there's a certain amount of data in the step
      let step = this.itinerary.steps[stepIndex];
      if (step?.title && step?.details?.length > 10) {
        // prompt the user
        this.stepInContextIndex = stepIndex;
        this.showDeleteStepModal = true;
      } else {
        // otherwise just remove it
        this.removeStep(stepIndex);
      }
    },
    deleteStepInContext() {
      this.removeStep(this.stepInContextIndex);
    },
    removeStep(index: number) {
      this.itinerary.steps.splice(index, 1);
      this.showDeleteStepModal = false;
      // Re-calculate the route and map
      setMapFieldsOnAdventure(this.itinerary, null);

      this.activeTabIndex = index === 0 ? 0 : index - 1;

      this.redrawTabs();

      setTimeout(() => {
        this.redrawingTabs = false;
        this.goToTab(this.activeTabIndex);
      }, 200);
    },
    cancelDeleteStep() {
      this.stepInContextIndex = -1;
      this.showDeleteStepModal = false;
    },
    async deleteItinerary() {
      this.isLoading = true;
      await ItineraryRepo.deleteItinerary(this.itinerary.id, false);
      this.$router.push({ name: "MyItineraries" });
    },
    async removeItinerary() {
      this.isLoading = true;
      await ItineraryRepo.deleteItinerary(this.itinerary.id, true);
      this.$router.push({ name: "MyItineraries" });
    },
    shiftStep(currentIndex, moveBy) {
      this.globalRemoteLogger.info(`ShiftStep for ${this.itinerary.id}: ${currentIndex} by ${moveBy}, creatorId=${this.itinerary.sherpaId}`);
      // Note: VueJS can't handle direct array mutations so we have to use a method
      const newIndex = currentIndex + moveBy;
      const steps = [...this.itinerary.steps];
      this.itinerary.steps = null;
      const swap = steps[currentIndex];
      steps.splice(currentIndex, 1, steps[newIndex]);
      steps.splice(newIndex, 1, swap);
      this.itinerary.steps = [...steps];
      this.activeTabIndex = newIndex;
      this.redrawTabs();
      setTimeout(() => {
        this.redrawingTabs = false;
        this.goToTab(this.activeTabIndex);
      }, 200);

      this.setRecalculateMaps();
    },

    initializeSapling(enabled: boolean) {
      if (!enabled) return;

      try {
        // For non-test we need to proxy the Sapling calls throug the backend
        let endpointHost = `${import.meta.env.VITE_API_URL}`;
        // Note: only remove from the end of the string (per the $ in the regex), since prod has https://api.shrpa.com/api in two places
        endpointHost = endpointHost.replace(/\/api$/, "");
        // Note: If we're running on localhost, we hardcode endpointHost (just the way things are currently wired up)
        if (endpointHost?.length === 0 && window.location.hostname.includes("localhost")) {
          logger.warn("Running localhost, overriding Sapling endpoint");
          endpointHost = "https://dev-api.shrpa.com";
        }
        logger.info("Initializing Sapling " + endpointHost);

        /* For testing w/out the proxy: Sapling.init({ key: "<key>", mode: "dev", }); */

        Sapling.init({
          endpointHostname: endpointHost,
          // @ts-ignore
          saplingPathPrefix: "/sapling",
        });
      } catch (e) {
        logger.error("Error setting up Sapling: " + e);
      }
    },
  },
});
</script>

<style lang="scss">
@import "@/scss/screen-size-ranges.scss";

.imagesUploadAreaTall {
  .uploadBtn.center {
    min-height: 170px !important;
  }
}

.uploaded-files-list {
  width: 100%;
  box-sizing: border-box;
  padding: 7px 0 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  position: relative;
  overflow: hidden;

  &__list-itself {
    width: calc(100% + 15px);
    box-sizing: border-box;
  }

  &__thumbnail {
    width: 124px;
    margin: 0 10px 10px 0;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .uploaded-files-list {
    &__thumbnail {
      width: calc(100% / 5 - 8px);

      &:nth-child(5n) {
        margin-right: 0;
      }
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .uploaded-files-list {
    &__thumbnail {
      width: calc(100% / 3 - 7px);

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}

// Dots in tabs with errors ===================================================
@mixin redDot {
  position: relative;

  &::after {
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 100px;
    position: absolute;
    inset: auto 3px 28px auto;
    background: #df513a;
  }
}

// prettier-ignore
.tabs-error-indicator-wrapper {
  .red-dot-over-0 { [data-tabname*="1"] { @include redDot} }
  .red-dot-over-1 { [data-tabname*="2"] { @include redDot} }
  .red-dot-over-2 { [data-tabname*="3"] { @include redDot} }
  .red-dot-over-3 { [data-tabname*="4"] { @include redDot} }
  .red-dot-over-4 { [data-tabname*="5"] { @include redDot} }
  .red-dot-over-5 { [data-tabname*="6"] { @include redDot} }
  .red-dot-over-6 { [data-tabname*="7"] { @include redDot} }
  .red-dot-over-summary { [data-tabname*="Summary"] { @include redDot} }
}

// Back link section ==========================================================
.back-link-section {
  padding-bottom: 12px;
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;

  &__link {
  }
}

//TODO: Style these to match what we currently have
.tinytabs .tabs {
  display: flex;
  flex-flow: row wrap;
}
.tinytabs .tabs .tab .close {
  padding-left: 5px;
}
.tinytabs .tabs .tab {
  margin: 0 3px 0 0;
  background: #e1e1e1;
  display: block;
  padding: 6px 15px;
  text-decoration: none;
  color: #666;
  font-weight: bold;
  border-radius: 3px 3px 0 0;
}
.tinytabs .section {
  background-color: #ffffff;
  padding: 15px;
  clear: both;
  border-radius: 0.28571429rem;
  margin-bottom: 1rem;
}
.tinytabs .tab.sel {
  background-color: #ffffff;
  color: #333;
  text-shadow: none;
}
//End of TinyTabs default styles

.stopTabs {
  .tab.segment {
    margin: 0;
  }

  .ui.tabular.menu {
    border-bottom: 0;

    .item {
      padding: 1em;

      span {
        font-size: 1.5em;
        font-weight: 500;
        color: #2c5566;
      }
    }

    .active.item {
      box-shadow:
        0 2px 4px 0 rgba(34, 36, 38, 0.12),
        0 2px 10px 0 rgba(34, 36, 38, 0.15);
      z-index: 10;

      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 15px;
        position: absolute;
        margin-top: 44px;
        left: 0;
        z-index: 5;
        background: white;
      }

      span {
        color: black;
      }
    }
  }
}

.error-message-heading {
  color: red;
  //text-decoration: underline;
  font-weight: bold;
  font-size: 1.2em;
}
.error-message {
  color: red;
  font-size: 1.1em;
  font-weight: 500;
  white-space: pre-wrap;
}
.saved-message {
  float: right;
  font-weight: bold;
  font-size: 1.2em;
}
.content-checkbox > label {
  font-size: 1rem !important;
  font-weight: 600 !important;
}

.cms-tabs {
  &--with-step-word {
    .srp-tabs__head[data-tabname="1"]::before,
    .srp-tabs__head[data-tabname="2"]::before,
    .srp-tabs__head[data-tabname="3"]::before,
    .srp-tabs__head[data-tabname="4"]::before,
    .srp-tabs__head[data-tabname="5"]::before,
    .srp-tabs__head[data-tabname="6"]::before,
    .srp-tabs__head[data-tabname="7"]::before {
      content: "Stop ";
      margin-right: 4px;
    }
  }
}

.hide-plus-tab {
  [data-tabname="+"] {
    display: none;
  }
}

// Step tabs ==================================================================
.step-tabs {
}
</style>
