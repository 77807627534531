<template>
  <SrpModal :isVisible="props.isVisible" @close="emit('close')" size="large" isWithScroll :isClosable="false" maxHeight="calc(100dvh - 200px)">
    <template #content>
      <!-- Modal content -->
      <div class="modal-content">
        <FeedbackSlide
          v-if="activeSlideNumber === 1"
          class="modal-content__slide-content"
          ref="domRefFeedbackSlide"
          :selectedCreatorIds="props.selectedCreatorIds"
          :collabsRemaining="props.collabsRemaining"
          :chooseCreatorIsSaving="props.chooseCreatorIsSaving"
          :communityId="props.communityId"
          :collabInputId="props.collabInput?.id"
          v-model:creatorSelectionFeedback="chooseCreatorInputPayload.creatorSelectionFeedback"
          @onMounted="activeSlideName = SlideNames.FeedbackSlide"
        />

        <VisitFocusSlide
          v-if="activeSlideNumber === 2"
          class="modal-content__slide-content"
          ref="domRefVisitFocusSlide"
          v-model:themes="chooseCreatorInputPayload.themes"
          v-model:contentFocus="chooseCreatorInputPayload.contentFocus"
          v-model:contentUsage="chooseCreatorInputPayload.contentUsage"
          @onMounted="activeSlideName = SlideNames.VisitFocusSlide"
        />

        <LocationsSlide
          v-if="activeSlideNumber === 3 && !isAdvCountNLocationCountSet"
          class="modal-content__slide-content"
          ref="domRefLocationsSlide"
          v-model:requestedLocationCount="chooseCreatorInputPayload.requestedLocationCount"
          v-model:requestedAdventureCount="chooseCreatorInputPayload.requestedAdventureCount"
          @onMounted="activeSlideName = SlideNames.LocationsSlide"
        />

        <ContactsSlide
          v-if="isAdvCountNLocationCountSet ? activeSlideNumber === 3 : activeSlideNumber === 4"
          class="modal-content__slide-content"
          ref="domRefContactsSlide"
          v-model:destinationPhoneNumber="chooseCreatorInputPayload.destinationPhoneNumber"
          v-model:destinationContactName="chooseCreatorInputPayload.destinationContactName"
          @onMounted="activeSlideName = SlideNames.ContactsSlide"
        />

        <!--<WhatsNextSlide-->
        <!--  v-if="activeSlideNumber === 4"-->
        <!--  class="modal-content__slide-content"-->
        <!--  ref="domRefWhatsNextSlide"-->
        <!--  :selectedCreatorIds="props.selectedCreatorIds"-->
        <!--  :communityId="props.communityId"-->
        <!--  :collabInputId="props.collabInput?.id"-->
        <!--  @onMounted="activeSlideName = SlideNames.WhatsNextSlide"-->
        <!--/>-->

        <div v-if="activeSlideNumber <= 3" class="modal-content__progressbar-wrap">
          <StepsProgressBar class="modal-content__progressbar" :currentStep="activeSlideNumber" :numberOfSteps="totalStepsCount" />
        </div>
      </div>
      <!-- / Modal content -->
    </template>

    <template #footer>
      <SrpButton v-if="activeSlideNumber === 1" @click="emit('cancel')" style="margin-right: 12px" fill="outlined" color="gray">I'm not sure yet</SrpButton>

      <SrpButton v-if="activeSlideNumber > 1" @click="activeSlideNumber -= 1" :isDisabled="props.chooseCreatorIsSaving" style="margin-right: 12px" fill="outlined" color="gray">Back </SrpButton>

      <SrpButton v-if="activeSlideNumber < totalStepsCount" @click="goToNextSlide">
        Next
        <template #iconRight>
          <IconEmbedded name="arrow-right_3" :size="23" />
        </template>
      </SrpButton>

      <SrpButton v-else @click="saveForm" :isDisabled="isSaving"> Finalize my choice </SrpButton>
    </template>
  </SrpModal>
</template>

<!-- prettier-ignore -->
<script lang="ts">export default { name: "ChooseCreatorModal" };</script>

<script setup lang="ts">
import { ref, Ref, onMounted, computed, watch } from "vue";
import axios from "axios";

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import StepsProgressBar from "@components/StepsProgressBar.vue";
// slides
import FeedbackSlide from "./FeedbackSlide.vue";
import VisitFocusSlide from "./VisitFocusSlide.vue";
import LocationsSlide from "./LocationsSlide.vue";
import ContactsSlide from "./ContactsSlide.vue";
import WhatsNextSlide from "./WhatsNextSlide.vue";

// Types
import { CollabCreatorSelectionFeedback, ChooseCreatorInputPayload, CollabInput } from "@contracts/collab";

const props = withDefaults(
  defineProps<{
    isVisible: boolean;
    selectedCreatorIds: Array<string>;
    collabsRemaining: number;
    chooseCreatorIsSaving: boolean;
    communityId: string;
    collabInput: CollabInput | null;
  }>(),
  {
    isVisible: false,
    selectedCreatorIds: () => [],
    collabsRemaining: 0,
    chooseCreatorIsSaving: false,
    communityId: "",
    collabInput: null,
  }
);

const emit = defineEmits<{
  (e: "close"): void;
  (e: "confirm", chooseCreatorInputs: ChooseCreatorInputPayload): void;
  (e: "cancel"): void;
}>();

// Is AdventureCount and LocationCount set ====================================
const isAdvCountNLocationCountSet = computed<boolean>(() => props.collabInput.requestedAdventureCount > 0 && props.collabInput.requestedLocationCount > 0);

// Total steps count ==========================================================
const totalStepsCount = computed<number>(() => (isAdvCountNLocationCountSet.value ? 3 : 4));

// "Choose creator input" payload =============================================
const chooseCreatorInputPayload = ref<ChooseCreatorInputPayload>({
  creatorSelectionFeedback: {
    numberOfCreators: 0,
    qualityOfCreators: 0,
    additionalDetails: "",
  },

  themes: "",
  contentFocus: "",
  contentUsage: "",

  requestedLocationCount: null,
  requestedAdventureCount: null,

  destinationContactName: null,
  destinationPhoneNumber: null,
});

function prefillPayload() {
  chooseCreatorInputPayload.value.themes = props.collabInput.themes || "";
  chooseCreatorInputPayload.value.contentFocus = props.collabInput.contentFocus || "";
  chooseCreatorInputPayload.value.contentUsage = props.collabInput.contentUsage || "";

  chooseCreatorInputPayload.value.requestedLocationCount = props.collabInput.requestedLocationCount;
  chooseCreatorInputPayload.value.requestedAdventureCount = props.collabInput.requestedAdventureCount;
}

onMounted(prefillPayload);

// Slide component dom refs ===================================================
type SlideComponentExposedData = { isFormValid: Ref<boolean>; showWarning?: Function };

const domRefFeedbackSlide = ref<SlideComponentExposedData>(null);
const domRefVisitFocusSlide = ref<SlideComponentExposedData>(null);
const domRefLocationsSlide = ref<SlideComponentExposedData>(null);
const domRefContactsSlide = ref<SlideComponentExposedData>(null);
const domRefWhatsNextSlide = ref<SlideComponentExposedData>(null);

// Switch slides ==============================================================
const activeSlideNumber = ref<number>(1);

enum SlideNames {
  FeedbackSlide = "FeedbackSlide",
  VisitFocusSlide = "VisitFocusSlide",
  LocationsSlide = "LocationsSlide",
  ContactsSlide = "ContactsSlide",
  WhatsNextSlide = "WhatsNextSlide",
}

const activeSlideName = ref<SlideNames>(null);

const activeSlideDomRef = computed<SlideComponentExposedData>(() => {
  if (activeSlideName.value === SlideNames.FeedbackSlide) return domRefFeedbackSlide.value;
  if (activeSlideName.value === SlideNames.VisitFocusSlide) return domRefVisitFocusSlide.value;
  if (activeSlideName.value === SlideNames.LocationsSlide) return domRefLocationsSlide.value;
  if (activeSlideName.value === SlideNames.ContactsSlide) return domRefContactsSlide.value;
  if (activeSlideName.value === SlideNames.WhatsNextSlide) return domRefWhatsNextSlide.value;
});

// Validate active slide ======================================================
function validateActiveSlide(): boolean {
  if ("isFormValid" in activeSlideDomRef.value && !activeSlideDomRef.value.isFormValid) {
    if ("showWarning" in activeSlideDomRef.value) {
      activeSlideDomRef.value.showWarning();
      return false;
    }
  } else {
    return true;
  }
}

// Go to the next slide =======================================================
function goToNextSlide() {
  if (validateActiveSlide()) {
    activeSlideNumber.value += 1;
  }
}

// Save form ==================================================================
const isSaving = ref<boolean>(false);

async function saveForm() {
  if (!validateActiveSlide()) {
    return;
  }
  isSaving.value = true;

  // Emit confirm with the chooseCreatorInputPayload
  emit("confirm", chooseCreatorInputPayload.value);
}
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Modal content ==============================================================
.modal-content {
  position: relative;

  &__slide-content {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__progressbar-wrap {
    padding-bottom: 5px;
    position: sticky;
    inset: auto auto 0 0;
    z-index: 2;

    &::before {
      content: "";
      width: calc(100% + 20px);
      height: calc(100% + 25px + 20px);
      position: absolute;
      inset: -25px auto auto -10px;
      z-index: -1;
      background: linear-gradient(0deg, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 0) 100%);
    }
  }

  &__progressbar {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .modal-content {
    &__progressbar-wrap {
      &::before {
        height: calc(100% + 25px + 10px);
      }
    }
  }
}
</style>
