import { shrpaApi } from "./axios";

export default {
  async loadHighlights(communityId: string, collabInputId: string, creatorId: string): Promise<string[]> {
    if (!creatorId) return [];

    const uri = `/collabs/${communityId}/inputs/${collabInputId}/creator/${creatorId}/highlights`;

    const response = await shrpaApi.get<string[]>(uri);
    return response.data;
  },
};
