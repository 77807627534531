<template>Redirecting...</template>
<script setup lang="ts">
import { useUserProfileStore } from "@stores/userProfileStore";
import { watchEffect } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const userProfileStore = useUserProfileStore();

// Watch for getViewingUserProfile to be loaded, then redirect to the appropriate page
watchEffect(async () => {
  if (userProfileStore.getViewingUserProfile) {
    // Try to impersonate (note the params.authNameId can be authNameId or creatorId)
    var idInContext = router.currentRoute.value.params.authNameId ?? userProfileStore.getViewingUserProfile.sherpaId;
    await userProfileStore.impersonateProfile(idInContext as string);
    // Admins have a new simplified profile page also
    if (userProfileStore.getViewingUserProfile.adminOf?.length > 0) {
      router.replace({ name: "CustomerAdminProfile", params: { creatorId: userProfileStore.getViewingUserProfile.sherpaId } });
    } else {
      router.replace({ name: "CreatorProfilePublic", params: { creatorId: userProfileStore.getViewingUserProfile.sherpaId } });
    }
  }
});
</script>
