<template>
  <div class="srp-tabs">
    <ul class="srp-tabs__heads-list">
      <li
        v-for="slotName in contentSlots"
        :key="slotName"
        :class="{
          'srp-tabs__head': true,
          'srp-tabs__head--active': slotName === props.activeTab,
          'srp-tabs__head--grow': props.tabsFillWidth,
        }"
        @click="emit('update:activeTab', slotName)"
        :data-tabName="slotName"
      >
        <slot :name="`${slotName}-header`" v-bind="{ active: slotName === props.activeTab }">
          {{ slotName }}
        </slot>
      </li>
    </ul>
    <!-- Tab container -->
    <ul class="srp-tabs__contents-list">
      <template v-for="slotName in contentSlots" :key="slotName">
        <li
          v-if="props.isKeepTabsAlive || slotName === props.activeTab"
          :class="{
            'srp-tabs__content': true,
            'srp-tabs__content--inactive': slotName !== props.activeTab,
          }"
        >
          <slot :name="slotName" />
        </li>
      </template>
    </ul>
    <!-- / Tab container -->
  </div>
</template>

<!-- prettier-ignore -->
<script lang="ts">export default { name: "SrpTabs" };</script>

<script setup lang="ts">
import { computed, useSlots } from "vue";

export interface Props {
  activeTab?: string;
  isKeepTabsAlive?: boolean;
  tabsFillWidth?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  activeTab: "",
  isKeepTabsAlive: false,
  tabsFillWidth: false,
});

const emit = defineEmits<{
  (e: "update:activeTab", event: string): void;
}>();

const slots = useSlots();
const contentSlots = computed(() => Object.keys(slots).filter(slotName => !slotName.endsWith("-header")));
</script>

<style scoped lang="scss">
$border-color: rgba(141, 141, 141, 0.6);
$border-width: 1px;
$inactive-background-color: #E8E8E8;
// Sherpa Tabs ================================================================
.srp-tabs {
  &__heads-list {
    border-bottom: $border-width solid $border-color;
    min-height: 38px;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 5px;
    list-style: none;
  }

  &__head {
    padding: 0 17px;
    border-radius: 5px 5px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font:
      600 15px/15px "Quicksand",
      sans-serif;
    background: $inactive-background-color;
    border: $border-width solid $inactive-background-color;
    border-bottom: none;
    cursor: pointer;
    user-select: none;
    transition:
      height 0.03s ease-in-out,
      padding-top 0.03s ease-in-out,
      background-color 0.03s ease-in-out;

    &:hover {
      background: $border-color;
      border-color: $border-color;
    }

    &--grow {
      flex-grow: 1;
    }

    &--active {
      margin-bottom: -$border-width;
      border-color: $border-color;
      border-bottom: $border-width solid #fff !important;
      padding-top: 0 !important;
      background: #fff !important;
      pointer-events: none;
    }
  }

  &__contents-list {
    padding: 0;
    margin: 0;
    position: relative;
    list-style: none;
  }

  &__content {
    padding: 18px;
    border-radius: 0 0 5px 5px;
    border: $border-width solid $border-color;
    border-top: none;
    background: #fff;

    &--inactive {
      display: none;
    }
  }
}
</style>
