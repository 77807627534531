import { defineStore } from "pinia";
import { ref, computed } from "vue";
import axios, { AxiosResponse } from "axios";
import { SocialPostCreatePayload, AdventureCreatorSummary, AdventureTextSummary, SocialMediaPostData, SocialPostType, SocialPostTypeId, ShareableSummaryDetails } from "@contracts/shareStop";

export const useShareStopSummaryStore = defineStore("shareStopSummary", () => {
  const activeStep = ref<number>(1);

  const postTypesList: Array<SocialPostType> = [
    {
      id: "collabInput",
      title: "Creator Visit",
      description: "Share photos and videos from a specific Creator Visit!",
    },
    {
      id: "adventure",
      title: "Adventure",
      description: "Build a post from your adventures.",
    },
    {
      id: "location",
      title: "Business or Location",
      description: "Give a specific local business or attraction in your destination a shout out.",
    },
  ];

  // Sharable summary details =================================================
  const isLoadingShareableSummary = ref(false);

  const shareableSummaryDetails = ref<ShareableSummaryDetails>({
    adventureCreatorSummaries: [],
    adventureTextSummaries: [],
    assets: [],
    title: "",
  });

  const adventureCreatorSummariesWithSocials = computed<Array<AdventureCreatorSummary>>(() => {
    return shareableSummaryDetails.value.adventureCreatorSummaries.filter(c => {
      return Object.values(c.socialFields).filter(v => v).length;
    });
  });

  async function loadShareableSummaryDetails(pageId: string, postType: string, contextId: string): Promise<void> {
    const query = `?${postType}Id=${contextId}`;
    const uri = `${import.meta.env.VITE_API_URL}/social/v2/${pageId}/summary-details${query}`;

    isLoadingShareableSummary.value = true;
    const { data } = await axios.get(uri);
    shareableSummaryDetails.value = data;
    isLoadingShareableSummary.value = false;
  }

  // Post =====================================================================
  const editedPost = ref<SocialMediaPostData>({
    assetIds: [],
    caption: "",
    contextId: "",
    contextTitle: null,
    contextType: null,
    createdBy: "",
    createdDate: "",
    customerId: "",
    deletedDate: null,
    id: "",
    pkey: "",
    updatedBy: "",
    updatedDate: "",
    zippedDownloadId: null,
  });

  function resetEditedPost() {
    editedPost.value = {
      assetIds: [],
      caption: "",
      contextId: "",
      contextTitle: null,
      contextType: null,
      createdBy: "",
      createdDate: "",
      customerId: "",
      deletedDate: null,
      id: "",
      pkey: "",
      updatedBy: "",
      updatedDate: "",
      zippedDownloadId: null,
    };
  }

  const postTypeTitle = computed<string>(() => postTypesList.find(({ id }) => id === String(editedPost.value.contextType))?.title || "");

  function removeMediaFromList(id: string) {
    editedPost.value.assetIds = editedPost.value.assetIds.filter(_id => _id !== id);
  }

  // Load posts ===============================================================
  const allPosts = ref<Array<SocialMediaPostData>>([]);
  const isPostsLoaded = ref(false);
  const isLoadingAllPosts = ref(false);

  async function loadAllPosts(pageId: string): Promise<void> {
    const uri = `${import.meta.env.VITE_API_URL}/social/v2/${pageId}/posts`;

    isLoadingAllPosts.value = true;
    const { data }: AxiosResponse<any> = await axios.get(uri);
    allPosts.value = data;
    isLoadingAllPosts.value = false;
    isPostsLoaded.value = true;
  }

  // Save/update post =========================================================
  const isSavingPost = ref(false);
  async function savePost(pageId: string, payload: SocialPostCreatePayload): Promise<void> {
    const uri = `${import.meta.env.VITE_API_URL}/social/v2/${pageId}/posts`;

    isSavingPost.value = true;
    const { data } = await axios.post(uri, payload);
    if (data.caption === null) data.caption = "";
    editedPost.value = data;

    allPosts.value = [data, ...allPosts.value];
    isSavingPost.value = false;
  }

  const isUpdatingPost = ref(false);
  async function updatePost(postData: SocialMediaPostData): Promise<void> {
    const uri = `${import.meta.env.VITE_API_URL}/social/v2/${postData.customerId}/posts/${postData.id}`;

    isUpdatingPost.value = true;
    const { data } = await axios.put(uri, postData);
    if (data.caption === null) data.caption = "";
    editedPost.value = data;

    allPosts.value = [data, ...allPosts.value.filter(({ id }) => id !== data.id)];
    isUpdatingPost.value = false;
  }

  // Delete post ==============================================================
  const isDeletingPost = ref(false);
  async function deletePost(pageId: string, postId: string): Promise<void> {
    const uri = `${import.meta.env.VITE_API_URL}/social/v2/${pageId}/posts/${postId}`;

    isDeletingPost.value = true;
    await axios.delete(uri);
    allPosts.value = allPosts.value.filter(({ id }) => id !== postId);

    isDeletingPost.value = false;
  }

  // Request download =========================================================
  const isRequestingDownloadId = ref(false);
  async function requestDownloadId(customerId: string, postId: string): Promise<string> {
    const uri = `${import.meta.env.VITE_API_URL}/social/v2/${customerId}/posts/${postId}/download`;
    let downloadId = "";

    isRequestingDownloadId.value = true;
    const { data } = await axios.get(uri);
    downloadId = data;
    isRequestingDownloadId.value = false;

    return downloadId;
  }

  return {
    activeStep,
    postTypesList,
    postTypeTitle,
    //
    shareableSummaryDetails,
    adventureCreatorSummariesWithSocials,
    isLoadingShareableSummary,
    loadShareableSummaryDetails,
    removeMediaFromList,
    //
    editedPost,
    resetEditedPost,
    isSavingPost,
    savePost,
    isUpdatingPost,
    updatePost,
    isDeletingPost,
    deletePost,
    // Posts
    allPosts,
    isPostsLoaded,
    isLoadingAllPosts,
    loadAllPosts,
    // DownloadId
    isRequestingDownloadId,
    requestDownloadId,
  };
});
