<template>
  <div class="toasts-list-container">
    <div v-if="toastsStore.toasts.length > 3" class="actions">
      <SrpButton size="tiny" @click="toastsStore.removeAll">Clear All</SrpButton>
    </div>
    <div class="toasts-list">
      <TransitionGroup name="toast-list">
        <ToastsListItem class="toasts-list__toast-list-item" v-for="toast in toastsStore.toasts" :key="toast.id" :toast="toast" @click:delete="toastsStore.remove(toast.id)" />
      </TransitionGroup>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useToastsStore } from "@stores/toasts";
import ToastsListItem from "./ToastsListItem.vue";
import SrpButton from "@components/ui/SrpButton.vue";

const toastsStore = useToastsStore();
</script>

<style scoped lang="scss">
.toasts-list-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  right: var(--spacing-6);
  margin-left: var(--spacing-6);
  padding-top: var(--spacing-6);
  padding-bottom: var(--spacing-6);
  z-index: 1000;
  height: 100vh;
  pointer-events: none;

  .toasts-list {
    flex-grow: 1;
    max-height: 100%;
    overflow-y: auto;
    // width: var(--spacing-96);

    &__toast-list-item {
      pointer-events: auto;
      margin-top: var(--spacing-3);
      &:first-child {
        margin-top: 0;
      }
    }
  }

  .actions {
    pointer-events: auto;
    flex: 0 1;
    padding: 0 var(--spacing-2);
    margin-bottom: var(--spacing-2);
    display: flex;
    justify-content: end;
  }
}

.toast-list-enter-active {
  transition: all 0.3s ease;
}
.toast-list-leave-active {
  transition: all 0.2s ease;
}
.toast-list-enter-from,
.toast-list-leave-to {
  opacity: 0;
}
.toast-list-leave-to {
  opacity: 0;
}
</style>
