// Note: Some of these are constants in CollabAdminController.cs in the backend code
const CommunityInputStatusId = "10";
const CreatorsBeingSelectedStatusId = "20";
const CreatorsInvitedStatusId = "30";
const CreatorsAcceptedStatusId = "40";
const CreatorsChosenStatusId = "50";
const AdventuresCreated = "90";
const SentToCommunity = "100";
const CreatorCorrectionsMade = "105";
const CompleteStatusId = "110";
const statuses = [
  {
    id: CommunityInputStatusId,
    desc: "1 - Community Inputs", // Community is still filling out their request
  },
  {
    id: CreatorsBeingSelectedStatusId,
    desc: "2 - Creators Being Selected", // Community pressed "next" and is in thep rocess of inviting creators
  },
  {
    id: CreatorsInvitedStatusId,
    desc: "3 - Creators Invited", // Community has sent at least one invite
  },
  {
    id: CreatorsAcceptedStatusId,
    desc: "4 - Creators Applied", // At least one creator has applied/accepted (no input change allowed now)
  },
  {
    id: CreatorsChosenStatusId,
    desc: "5 - Creator Chosen", // Community has selected a creator
  },
  {
    id: "60",
    desc: "6 - Hotel Booked",
  },
  {
    id: "70",
    desc: "7 - Big Ticket Items Booked",
  },
  {
    id: AdventuresCreated,
    desc: "9 - Adventures Created",
  },
  {
    id: SentToCommunity,
    desc: "10 - Sent community finalized adventures",
  },
  {
    id: "105",
    desc: "10.5 - Creator Corrections made", // Note: there is a separate status field (CorrectionsStatus) for the Community/Creator back and forth here
  },
  {
    id: CompleteStatusId,
    desc: "11 - Completed",
  },
];

// From CollabCorrectionStatus on the server
const CorrectionStatusShrpaReview = "ShrpaReview";
const CorrectionStatusCommunityReview = "CommunityReview";
const CorrectionStatusPendingCreator = "PendingCreator";

export default {
  // Collab Main Statuses
  CommunityInputStatusId,
  CreatorsBeingSelectedStatusId,
  CreatorsInvitedStatusId,
  CreatorsAcceptedStatusId,
  CreatorsChosenStatusId,
  AdventuresCreated,
  CreatorCorrectionsMade,
  CompleteStatusId,
  SentToCommunity,

  // Correct Statuses
  CorrectionStatusShrpaReview,
  CorrectionStatusCommunityReview,
  CorrectionStatusPendingCreator,

  statuses,

  getStatus(statusId: string): string {
    if (statusId == null || statusId.length === 0) return null;
    var result = statuses.filter(s => s.id === statusId);
    if (result.length > 0) return result[0].desc;
    else return statusId;
  },
};
