import { CollabInput } from "@contracts/collab";
import CollabStatuses from "./CollabStatuses";

// Simple State Checks for the Collabs
export default {
  canShowCorrectionsUI(collabInput: CollabInput) {
    if (collabInput.correctionsForCreator && collabInput.correctionsForCreator.length > 0) {
      // They've alraedy provided feedback so show it
      return true;
    } else if (collabInput.correctionsStatus === CollabStatuses.CorrectionStatusCommunityReview) {
      // It's in a state where we need their feedback
      return true;
    } else if (collabInput.collaboration.completedDate) {
      return true;
    } else {
      // Either the creator isn't done yet or Shrpa is reviewing it
      return false;
    }
  },
};
