<template>
  <ul class="vertical-navigation">
    <!-- Navigation item -->
    <template v-for="link in props.linksList" :key="link.text">
      <RouterLink
        :class="{
          'nav-item': true,
          'nav-item--active': checkIsActive(link.to.name, link.aliases) && !link.isHighlightingOff,
          'nav-item--subtitle': !link.isSubItem,
          'nav-item--with-top-divider': link.isWithTopDivider,
          'vertical-navigation__li': true,
          'vertical-navigation__li--subtitle': !link.isSubItem,
          'vertical-navigation__li--sub-subitem': link.isSubSubItem,
          'nav-item--disabled': link.isDisabled,
        }"
        v-if="link.isHidden !== true"
        :to="link.to"
        lang="en"
        hreflang="en"
      >
        <div class="nav-item__title-icon-wrap">
          <IconEmbedded
            v-if="!link.isSubItem && link.titleIcon"
            :class="{
              'nav-item__title-icon': true,
              'nav-item__title-icon--highlighted': checkIsActive(link.to.name, link.aliases) && !link.isHighlightingOff,
            }"
            :name="link.titleIcon?.name"
            :size="link.titleIcon?.size || 24"
            :style="{ minWidth: `${link.titleIcon?.size || 24}px` }"
          />
          <IconEmbedded v-if="checkIsActive(link.to.name, link.aliases) && link.isSubItem && !link.isHighlightingOff" class="nav-item__activity-indicator" name="triangle-right-solid" :size="13" />
        </div>
        <span
          :class="{
            'nav-item__link-dash': true,
            'nav-item__link-dash--invisible': checkIsActive(link.to.name, link.aliases) && !link.isHighlightingOff,
          }"
          >{{ link.isSubItem ? "- " : "" }}</span
        >
        <span
          :class="{
            'nav-item__link-text': true,
            'nav-item__link-text--highlighted': checkIsActive(link.to.name, link.aliases) && !link.isHighlightingOff,
          }"
        >
          {{ link.text }}
          <span v-if="link.isWithBetaBadge" class="nav-item__link-beta-badge">beta</span>
          <span v-if="link.isWithComingSoonBadge" class="nav-item__link-coming-soon-badge">coming soon</span>
        </span>
      </RouterLink>
    </template>
    <!-- / Navigation item -->
  </ul>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import { computed } from "vue";

// Types
import { NavigationMenuItem } from "@contracts/navigationMenuItem";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

const router = useRouter();

export interface Props {
  linksList: Array<NavigationMenuItem>;
}
const props = withDefaults(defineProps<Props>(), {
  linksList: () => [],
});

// ============================================================================
const currentRouteName = computed(() => router.currentRoute.value.name?.toString());
function checkIsActive(name: string, aliases = []): boolean {
  return name === currentRouteName.value || aliases.includes(currentRouteName.value);
}
</script>

<style scoped lang="scss">
// Navigation item ============================================================
.nav-item {
  position: relative;
  z-index: 0;
  color: #5b5b5b;
  font: 500 14px/18px sans-serif;
  white-space: nowrap;
  user-select: none;

  &::before {
    content: "";
    width: 100%;
    height: calc(100% + 8px);
    max-height: 30px;
    position: absolute;
    inset: auto auto -4px 50%;
    z-index: 2;
    transform: translateX(-50%);
    cursor: pointer;
  }

  &--active {
    color: #000;
  }

  &--subtitle {
    padding: 16px 0 0 30px;
    color: #000;
    font:
      bold 15px/18px "Quicksand",
      sans-serif;
    pointer-events: all;

    &::after {
      content: "";
      width: 100%;
      height: 1px;
      position: absolute;
      inset: 0 auto auto 0;
      font-size: 0;
      line-height: 0;
      background: rgba(0, 0, 0, 0.1);
      pointer-events: none;
    }

    &:first-child {
      padding-top: 0;

      &::after {
        display: none;
      }
    }
  }

  &--disabled {
    pointer-events: none;
    color: rgba(0, 0, 0, 0.3);

    :deep(svg) {
      fill: rgba(0, 0, 0, 0.3) !important;
    }
  }

  &--with-top-divider {
    margin-top: 20px;

    &::after {
      content: "";
      width: 100%;
      height: 1px;
      position: absolute;
      inset: -16px auto auto 0;
      font-size: 0;
      line-height: 0;
      background: rgba(0, 0, 0, 0.1);
      pointer-events: none;
    }
  }

  &__title-icon-wrap {
    width: 23px;
    min-width: 23px;
    max-width: 23px;
    height: 23px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 13px auto auto 0;
  }

  &__title-icon {
    width: 23px;
    font-size: 23px;
    transition: color 0.07s ease-in-out;

    :deep(svg) {
      fill: rgba(69, 71, 72, 1);
    }
  }

  &:first-child &__title-icon-wrap {
    top: -3px;
  }

  &__title-icon--highlighted,
  &:hover &__title-icon {
    :deep(svg) {
      fill: #058587;
    }
  }

  &__activity-indicator {
    width: 12px;
    height: 12px;
    position: absolute;
    inset: -10px auto auto -6px !important;

    :deep(svg) {
      fill: rgba(17, 134, 137, 1);
    }
  }

  &__link-dash {
    &--invisible {
      opacity: 0;
    }
  }

  &__link-text {
    display: inline-block;
    position: relative;
    text-decoration: underline;
    text-decoration-color: rgba(5, 133, 135, 0);
    text-underline-offset: 2px;
    transition: color 0.07s ease-in-out;
  }

  &__link-beta-badge,
  &__link-coming-soon-badge {
    height: 13px;
    padding: 1px 4px 0;
    box-sizing: border-box;
    border: 1px rgba(0, 0, 0, 0.3) solid;
    border-radius: 4px;
    display: block;
    position: absolute;
    inset: 3px -33px auto auto;
    color: rgba(0, 0, 0, 0.5);
    font: 9px/9px sans-serif;
    user-select: none;
  }

  &__link-coming-soon-badge {
    right: -70px;
  }

  &__link-text--highlighted,
  &:hover &__link-text {
    color: #058587;
  }

  &:hover &__link-text {
    text-decoration-color: rgba(5, 133, 135, 0.2);
  }
}

// Vertical navigation ========================================================
.vertical-navigation {
  padding: 0;
  margin: 0;
  list-style: none;

  &__li {
    margin-bottom: 9px;
    display: block;

    &:last-child {
    }

    & + &--subtitle {
      margin-top: 7px;
    }

    &--subtitle {
      width: 100%;
      margin-bottom: 12px;
    }

    &--subtitle + &--subtitle {
      margin-top: 4px;
    }

    &--sub-subitem {
      margin-left: 20px;
    }
  }

  &__a {
  }
}
</style>
