import { defineStore } from "pinia";
import ProfileRepo from "@logic/ProfileRepo";

// Types
import { UserProfile } from "@contracts/userProfile";
import logger from "@helpers/Logger";
import axios from "axios";

export interface SetReferralCodePayload {
  isAlreadySet: boolean;
  codeAlreadyUsed: boolean;
  profile: UserProfile;
}

export const useUserProfileStore = defineStore("userProfile", {
  state: () => ({
    loggedInUser: null as UserProfile | null,
    impersonatingUser: null as UserProfile | null,

    isRefferalCodeAlreadySet: false,
    isRefferalCodeAlreadyUsed: false,
  }),
  getters: {
    isSuperOrSalesUser(state): boolean {
      return state.loggedInUser?.isSuperuser === true || state.loggedInUser?.isSalesUser === true;
    },
    isImpersonating(state): boolean {
      return state.impersonatingUser !== null;
    },
    // User profile being viewed (may be impersonated)
    getViewingUserProfile(state): UserProfile | null {
      return state.impersonatingUser ?? state.loggedInUser;
    },
    // The Acting User's profile (use this for auth)
    getActingUserProfile(state): UserProfile | null {
      // Always return the logged in user
      return state.loggedInUser;
    },
  },
  actions: {
    setLocalProfile(profileToUse: UserProfile): void {
      if (profileToUse.sherpaId === this.loggedInUser?.sherpaId) {
        this.loggedInUser = profileToUse;
      } else if (profileToUse.sherpaId === this.impersonatingUser?.sherpaId) {
        this.impersonatingUser = profileToUse;
      }
    },
    async saveProfile(profile: UserProfile, referredByCode: string = null, setLocalFromServerState = true): Promise<UserProfile> {
      const newProfile: UserProfile = await ProfileRepo.saveProfile(profile, referredByCode);
      // Most places should set the local from the server state,
      // But this can cause issues if we auto-save while the user is editing (the server state can overwrite changes they're in the middle of)
      if (setLocalFromServerState) {
        this.setLocalProfile(newProfile);
      }

      return newProfile;
    },

    async setReferralCode(referralCode: string): Promise<void> {
      this.isRefferalCodeAlreadySet = false;
      this.isRefferalCodeAlreadyUsed = false;

      const uri = `${import.meta.env.VITE_API_URL}/referrals/${this.getViewingUserProfile.sherpaId}/setReferralCode?referralCode=${referralCode}`;
      const { data } = await axios.post<SetReferralCodePayload>(uri);

      this.setLocalProfile(data.profile);

      this.isRefferalCodeAlreadySet = data.isAlreadySet;
      this.isRefferalCodeAlreadyUsed = data.codeAlreadyUsed;
    },
    /**
     * Reload the profile for the logged in user and the impersonated user (if applicable)
     */
    async refreshLoadedProfiles() {
      logger.info("Profile.refreshLoadedProfiles called");
      const promises: Promise<unknown>[] = [this.setProfileForCurrentUser()];
      if (this.impersonatingUser) {
        promises.push(this.impersonateProfile(this.impersonatingUser.authNameId));
      }
      await Promise.all(promises);
    },
    // ---------------------Public Methods---------------------
    // Just loads th current user
    async setProfileForCurrentUser() {
      logger.info("Profile.setProfileForCurrentUser called");
      this.loggedInUser = await ProfileRepo.loadProfile(null);
    },

    // Allows superusers to impersonate users, or shortcuts if not applicable
    // Note: We expect pages to call this even if they're not sure an impersonation scenarios is in play.
    async impersonateProfile(authIdOrCreatorIdToImpersonate: string) {
      logger.info(`Profile.impersonateProfile called with ${authIdOrCreatorIdToImpersonate}`);
      if (!authIdOrCreatorIdToImpersonate) return;

      // Only superusers can impersonate, shortcut if not
      if (this.loggedInUser?.isSuperuser !== true && this.loggedInUser?.isSalesUser !== true) {
        // logger.info(`Profile.impersonateProfile not superuser, returning`);
        return;
      }

      // If the user requested is just the logged in user, then shortcut (since you can't impersonate yourself)
      if (
        authIdOrCreatorIdToImpersonate.toLowerCase().trim() === this.loggedInUser?.authNameId?.toLowerCase() ||
        authIdOrCreatorIdToImpersonate.toLowerCase().trim() === this.loggedInUser?.sherpaId?.toLowerCase()
      ) {
        // logger.info(`Profile.impersonateProfile requested user is the logged in user, returning`);
        return;
      }
      // If the user requested is already being impersonated, then shortcut
      if (
        authIdOrCreatorIdToImpersonate.toLowerCase().trim() === this.impersonatingUser?.authNameId?.toLowerCase() ||
        authIdOrCreatorIdToImpersonate.toLowerCase().trim() === this.impersonatingUser?.sherpaId?.toLowerCase()
      ) {
        // logger.info(`Profile.impersonateProfile requested user is being impersonated, returning`);
        return;
      }

      // Load the user
      logger.info(`Profile.impersonateProfile calling loadProfile with ${authIdOrCreatorIdToImpersonate}`);
      const userLoaded = await ProfileRepo.loadProfile(authIdOrCreatorIdToImpersonate);
      // Double check it's not just the current user (techincally the above check should cover this, but this doesn't hurt)
      if (userLoaded?.authNameId !== this.loggedInUser?.authNameId) {
        this.impersonatingUser = userLoaded;
      }
    },

    // Stops impersonation (note: calling pages may need to reload their state)
    stopImpersonation() {
      logger.info("Profile.stopImpersonation");
      this.impersonatingUser = null;
    },
  },
});
