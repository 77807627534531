import type { NavigationGuard } from "vue-router";
import router from "../router";
import { useUserProfileStore } from "@stores/userProfileStore";

// Allows super or sales users to access the route
// NOTE: This assumes the authGuard has already run
export const authGuardSuper: NavigationGuard = (to, from, next) => {
  const userProfileStore = useUserProfileStore();
  if (userProfileStore.isSuperOrSalesUser) {
    return next();
  } else {
    // Just send them back to the homepage
    console.warn("User is not a super, redirecting to home.");
    router.replace({ path: "/" });
  }
};
