<template>
  <div id="section-to-print">
    <br />
    <br />

    <Loader v-if="isLoading" />
    <div v-else class="ui stackable two column grid community-stats-summary">
      <div>
        <div v-if="statsData">
          <div class="stats-header">
            <div class="business-name">
              <img class="ui small image" src="https://cdn.shrpa.com/images/shrpa-full-color.png" alt="shrpa - Explore Local" />
            </div>
            <div style="display: flex; align-items: center">
              <i class="plus icon" style="font-size: 20px; color: #058587; margin: 0 40px"></i>
              <img v-if="globalRoot.orgInContext" :src="`${contentBaseUri}/cms/images/orgs/${globalRoot.orgInContext.logoUri}`" class="partner-img" alt="partner logo" />
            </div>
          </div>

          <button class="ui small primary right floated button print-button" @click="print()">Print</button>
          <h1>{{ statsData.customerName }} Stats</h1>
          <div class="top-summary">Below is a summary of your community's content stats on Shrpa. "Print" to access a printer or PDF friendly version.</div>
          <div style="margin-top: 10px" class="ui stackable grid">
            <!--Adventures-->
            <div class="ui sixteen wide column adventure-section">
              <div class="ui segment">
                <div class="ui stackable grid">
                  <div class="five wide column">
                    <div>
                      <div class="ui huge header size-override">
                        <div class="content">
                          {{ statsData.stats.paidAdventureCount + statsData.stats.communityAdventureCount }} Adventures
                          <div v-if="statsData.stats.totalCollaborationsCount > 0" class="sub header">
                            {{ statsData.stats.totalCollaborationsCount }} {{ statsData.stats.totalCollaborationsCount === 1 ? "Collaboration" : "Collaborations" }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="text-block grey margin-top">
                      Adventures provide complete itineraries that both inspire and inform. Enabling travelers to discover all of the ways they can explore your community!
                    </div>
                    <!--<div class="ui huge header size-override" style="margin-top: 0">
                    <img :src="`${contentBaseUri}/images/admin/adventure.png`" />
                  </div>-->
                  </div>
                  <div class="eleven wide column">
                    <div class="ui four doubling raised link cards adventure-cards">
                      <ItineraryTile
                        v-for="itinerary in statsData.adventures"
                        :itinerary="itinerary"
                        :allowShortImage="false"
                        :showIsNew="false"
                        :openNewWindow="true"
                        :source="null"
                        :key="'adventures-' + itinerary.id"
                        :hideDetails="false"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--Photos-->
            <div class="ui sixteen wide column photos-section">
              <div class="ui segment">
                <div class="ui stackable grid">
                  <div class="sixteen wide column">
                    <div class="ui eight column grid hide-on-mobile">
                      <div class="six wide column">
                        <div class="ui huge header size-override">
                          <div class="content">{{ formatNumber(statsData.stats.photoCount) }} Photos & Videos</div>
                          <!--<img :src="`${contentBaseUri}/images/admin/camera-square.png`" />-->
                        </div>
                        <div class="text-block grey margin-top">
                          Your subscription allows you to use these photos in your marketing channels! Authentic images that were taken by travelers exploring your community.
                        </div>
                      </div>
                      <div class="column stats-photo" v-for="photo in statsData.photos" :key="photo">
                        <img class="ui rounded image photos" :src="`${contentBaseUri}/cms/images/expeditions/thumbnails/${photo}`" />
                      </div>
                    </div>
                    <div class="ui grid hide-on-desktop">
                      <div class="sixteen wide column">
                        <div class="ui huge header">
                          <div class="content">{{ formatNumber(statsData.stats.photoCount) }} Photos</div>
                          <br />
                          <img :src="`${contentBaseUri}/images/admin/camera-square.png`" />
                        </div>
                      </div>
                      <div class="eight wide column" v-for="photo in statsData.photos" :key="photo">
                        <img class="ui rounded image photos" :src="`${contentBaseUri}/cms/images/expeditions/thumbnails/${photo}`" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--Locations-->
            <div class="ui sixteen wide column locations-section">
              <div class="ui segment">
                <div class="ui stackable grid">
                  <div class="five wide column">
                    <div class="ui huge header size-override">
                      <div class="content">{{ statsData.stats.locationCount }} Locations</div>
                      <!--<br/>
                      <img :src="`${contentBaseUri}/images/admin/location.png`" />-->
                    </div>
                    <div class="text-block grey margin-top">
                      Locations highlighted with both photos, descriptions, and even some pro tips! Shrpa's adventures always highlight what the travelers love about these area businesses and
                      attractions.
                    </div>
                  </div>
                  <div class="eleven wide column">
                    <div class="ui two column grid hide-on-mobile locations">
                      <div class="ui column" v-for="location in statsData.locationNames" :key="location">
                        <div class="ui huge relaxed list">
                          <div class="item">
                            <i class="map pin icon"></i>
                            <div class="content">
                              {{ location }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="ui grid hide-on-desktop locations">
                      <div class="ui eight wide column" v-for="location in statsData.locationNames" :key="location">
                        <div class="ui relaxed list">
                          <div class="item">
                            <i class="map pin icon"></i>
                            <div class="content">
                              {{ location }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--Creators-->
            <div class="ui sixteen wide column creators-section">
              <div class="ui segment">
                <div class="ui stackable grid">
                  <div class="six wide column">
                    <div>
                      <div class="ui huge header size-override">
                        <div class="content">{{ statsData.stats.paidCreatorCount + statsData.stats.communityCreatorCount }} Creators</div>
                        <!--<br/>
                      <img :src="`${contentBaseUri}/images/admin/creator.png`" />-->
                      </div>
                    </div>
                    <div class="text-block grey margin-top">Creators are the travel bloggers, writers, and influencers who create adventures on Shrpa.</div>
                  </div>
                  <div class="ten wide column hide-on-mobile">
                    <div class="ui six column stackable grid">
                      <div class="column" v-for="creator in statsData.creators" :key="creator.sherpaId">
                        <div>
                          <div>
                            <img class="ui small circular centered image" :src="creator.profileImageUri" />
                          </div>
                          <h5 class="ui header image-header">
                            {{ creator.firstName }}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="twelve wide column hide-on-desktop">
                    <div class="ui grid">
                      <div class="eight wide column" v-for="creator in statsData.creators" :key="creator.sherpaId">
                        <div>
                          <div>
                            <img class="ui small circular image" :src="creator.profileImageUri" />
                          </div>
                          <div class="ui small header image-header">
                            {{ creator.firstName }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--Deprecated
            <div class="ui eight wide column impression-section">
              <div class="ui segment">
                <div class="ui huge header icon-override">
                  <i class="eye icon"></i>
                  <div class="content">
                    {{ abbreviatedFormat(statsData.stats.impressionCount) }} Impressions
                    <div class="sub header">How many times your adventures have been displayed through either a site integration or shrpa.com.</div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="ui eight wide column interaction-section">
              <div class="ui segment">
                <div class="ui huge header icon-override">
                  <i class="hand point up outline large icon"></i>
                  <div class="content">
                    {{ abbreviatedFormat(statsData.stats.previewCount + statsData.stats.exploreCount) }} Interactions
                    <div class="sub header">How many times people have opened your adventures to explore them further.</div>
                  </div>
                </div>
              </div>
            </div>
            -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import Loader from "@components/Loader/Loader.vue";
import CopyText from "@components/CopyText.vue";
import ItineraryTile from "@components/ItineraryTile.vue";
import NumberFormatting from "@logic/NumberFormatting";
import { useHead } from "@unhead/vue";

const adventuresToReturn = 4;
const photosToReturn = 21;
const locationsToReturn = 8;
const creatorsToReturn = 6;

export default defineComponent({
  name: "CommunityStatsSummary",

  components: {
    Loader,
    CopyText,
    ItineraryTile,
  },

  data() {
    return {
      title: "Community Stats",
      customerId: null as string | null,
      isLoading: true,
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      statsData: null,
    };
  },

  async mounted() {
    useHead({ title: () => this.title ?? "" });

    // @ts-ignore
    this.customerId = this.$route.params.pageId;
    await this.getData();
  },

  methods: {
    async getData() {
      const { data } = await axios.get(
        `${import.meta.env.VITE_API_URL}/stats/${this.customerId}/top-level` +
          `?adventuresToReturn=${adventuresToReturn}&photosToReturn=${photosToReturn}&locationsToReturn=${locationsToReturn}&creatorsToReturn=${creatorsToReturn}`
      );
      this.statsData = data;
      this.isLoading = false;
      this.title += ` - ${this.statsData.customerName}`;
    },
    formatNumber(value: number): string {
      return NumberFormatting.formatNumber(value);
    },
    abbreviatedFormat(value: number): string {
      return NumberFormatting.abbreviatedFormat(value);
    },
    async print() {
      window.print();
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/community-stats-print";

.photos {
  width: 10em;
  height: 10em;
  object-fit: cover;
}
.text-block.margin-top {
  margin-top: 1em;
}
.image-header {
  margin-top: 0.2em;
  text-align: center;
}
.locations {
  padding: 0.5em;
}
.ui.huge.header.size-override {
  font-size: 2.6em !important;
}
.ui.huge.header.icon-override {
  font-size: 2.6em !important;
  i.icon {
    font-size: 1.4em !important;
  }
}

.stats-header {
  //Hidden normally and shows on print
  display: none;
}
</style>
