<template>
  <div class="ui container">
    <h2>Redirecting<EllipsisAnimated /></h2>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import EllipsisAnimated from "@components/ui/EllipsisAnimated.vue";
import { useHead } from "@unhead/vue";

export default defineComponent({
  name: "RedirectingElsewhere",

  components: {
    EllipsisAnimated,
  },

  data() {
    return {
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
    };
  },

  mounted() {
    useHead({ title: "Redirecting" });
  },
});
</script>

<style scoped></style>
