<template>
  <div class="ui container">
    <Loader v-if="!hasAuthenticationInitialized" style="position: absolute"></Loader>

    <div class="main-wrapper" v-else>
      <!--Not Authenticated yet-->
      <div v-if="!isAuthenticated">
        <h3 style="margin-top: 50px">Welcome! Please Authenticate before proceeding.</h3>
        <div>
          <button class="ui primary button" @click="isShowLoginModal = true">Continue</button>
        </div>

        <p style="margin-top: 100px; text-align: center">If you are having trouble, email us anytime at <CopyText /></p>
      </div>

      <!--Authenticated-->
      <div v-else>
        <!--Redirecting-->
        <div>
          <h2>Redirecting...</h2>
          <p>You'll be redirected momentarily. If you are having trouble, email us at <CopyText /></p>
        </div>
      </div>
    </div>

    <!--Modals-->
    <LoginModal :isVisible="isShowLoginModal" @close="isShowLoginModal = false" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Loader from "@components/Loader/Loader.vue";
import CopyText from "@components/CopyText.vue";
import LoginModal from "@components/Modals/LoginModal.vue";
import { getInstance } from "@auth/authWrapper";
import { inject } from "vue";
import { useHead } from "@unhead/vue";

// This is the generic page a user gets redirected to if they try to go straight to an authenticated page without being authenticated.
// Note that we have both customer and creator dashboards with more targeted flows, which is where they normally should come into the site.
// But this gives them a better experience if they have an authed page bookmarked.
// In the future we might want to consolidate this and the dashboard logic.

export default defineComponent({
  name: "AuthLanding",

  components: {
    Loader,
    CopyText,
    LoginModal,
  },

  data() {
    return {
      globalLog: inject("globalLog") as any,

      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      hasAuthenticationInitialized: false,
      isAuthenticated: false,
      isShowLoginModal: false,
    };
  },

  async mounted() {
    useHead({ title: "Shrpa Auth" });

    this.globalLog.info(`AuthLanding Mounted`);
    // Note: See notes in Dashboard.vue for more detail
    let authService = getInstance();
    if (authService.loading === false) {
      this.authLoaded();
    }
    authService.$watch("loading", loading => {
      if (loading === false) {
        this.authLoaded();
      }
    });
  },

  methods: {
    authLoaded() {
      this.hasAuthenticationInitialized = true;
      let authService = getInstance();
      this.isAuthenticated = authService.isAuthenticated;
      this.globalLog.info(`AuthLoaded: IsAuthenticated=${this.isAuthenticated}`);

      // If they're coming back from authentication redirect them
      if (this.isAuthenticated) {
        this.redirectUser();
      } else {
        this.isShowLoginModal = true;
      }
    },
    redirectUser() {
      // Check for a redirect location (that's what we normally expect when hitting this page)
      const redirectPath = sessionStorage.getItem("redirectPath"); // This is just the path, not the full uri
      if (redirectPath?.length > 0) {
        this.globalLog.info(`Redirecting to ${redirectPath}`);
        sessionStorage.removeItem("redirectPath");
        // @ts-ignore
        window.location.href = redirectPath;
      } else {
        // If for some reason they don't have that, send them to the homepage
        this.globalLog.info(`Redirecting to Home`);
        this.$router.replace({ name: "Home" });
      }
    },
  },
});
</script>

<style scoped></style>
