<template>
  <div ref="domRefRoot">
    <SrpMasonry
      :items="images"
      :columns="
        props.columnsNumber
          ? props.columnsNumber
          : {
              mobile: 2,
              tablet: 3,
              'tablet-large': 4,
              laptop: 5,
              desktop: 5,
              'desktop-wide': 5,
            }[screenSize]
      "
      :columnGap="screenSize === 'mobile' ? 9 : 11"
      customKey="serverId"
      :class="{ transparentForClicks: props.isDragOverActive }"
    >
      <template #default="{ item, index, rebuildMasonry }">
        <div
          v-if="!props.singlePickMode || !FileUtils.isVideoFileType(item.serverId, null)"
          :key="index"
          :data-index="index"
          :class="{
            photoNode: true,
            'photoNode--active': item.serverId === props.cachedActiveServerId,
            'global-dnd-ghost-placeholder': (isDragInProgress || isTouchDragInProgress) && (elementIndexDragged === index || elementIndexTouchDragged === index),
            'global-dnd-dragged-over': (isDragInProgress || isTouchDragInProgress) && (elementIndexDraggedOver === index || elementIndexTouchDraggedOver === index),
          }"
          style="margin-bottom: 10px"
        >
          <DragAndDropHandle v-if="props.isWithDragAndDrop" style="position: absolute; inset: 6px auto auto 6px" size="small" :data-index="index" />

          <CloseDeleteButton
            v-if="props.showRemove"
            class="remove-button"
            size="tiny"
            @click="
              emit(
                'removeMediaFile',
                props.images.findIndex(i => i.serverId === item.serverId)
              )
            "
            icon="trashcan"
          />
          <LoaderComponent v-if="item.finishedUploading === false && !props.singlePickMode" active style="color: #fff" />
          <div @click="emit('imageClicked', item)" class="image-container" :class="{ active: item.active }">
            <!--Video-->
            <template v-if="FileUtils.isVideoFileType(item.serverId, null)">
              <SrpFileThumbnail class="cms-video" :fileName="item.serverId" @imageLoad="rebuildMasonry" />
            </template>
            <!--Image-->
            <template v-else>
              <img v-if="item.finishedUploading !== false" :src="`${contentBaseUri}/cms/images/expeditions/thumbmed/${item.serverId}`" class="rounded" @load="rebuildMasonry" alt="thumbnail" />
            </template>
          </div>
        </div>
      </template>
    </SrpMasonry>
  </div>
</template>

<script setup lang="ts">
import { inject, Ref, ref, computed, onMounted } from "vue";
import FileUtils from "@logic/FileUtils";

// Components
import CloseDeleteButton from "@components/ui/CloseDeleteButton.vue";
import DragAndDropHandle from "@components/ui/DragAndDropHandle.vue";
import LoaderComponent from "@components/Loader/Loader.vue";
import SrpFileThumbnail from "@components/ui/SrpFileThumbnail.vue";
import SrpMasonry from "@components/ui/SrpMasonry.vue";

// Types
import { ScreenSize } from "@contracts/screenSize";

// Global variables
const contentBaseUri = globalThis.Bootstrap.Config.contentCdnBaseUri;
const screenSize = inject("screenSize") as Ref<ScreenSize>;

// Composables
import { moveItemToNewIndex, useMouseDragNDrop } from "@composables/useMouseDragNDrop";
import { useTouchDragNDrop } from "@composables/useTouchDragNDrop";

const props = withDefaults(
  defineProps<{
    showRemove?: boolean;
    singlePickMode?: boolean;
    isDragOverActive?: boolean;
    cachedActiveServerId?: string;
    columnsNumber?: number;
    images: Array<any>;
    isWithDragAndDrop?: boolean;
  }>(),
  {
    showRemove: false,
    singlePickMode: false,
    isDragOverActive: false,
    cachedActiveServerId: "",
    columnsNumber: 0,
    images: () => [],
    isWithDragAndDrop: false,
  }
);

const emit = defineEmits<{
  (e: "removeMediaFile", index: number): void;
  (e: "imageClicked", item: any): void;
  (e: "reorderImages", oldIndex: number, newIndex: number): void;
}>();

// DOM ref root ===============================================================
const domRefRoot = ref<HTMLElement | null>(null);

// Handle Drag & Drop =========================================================
const { isDragInProgress, elementIndexDragged, elementIndexDraggedOver } = useMouseDragNDrop(
  computed(() => props.isWithDragAndDrop),
  // Changes to either of these props will cause the event listeners to be reset.
  // Optimized this so we only do the images.map if drag and drop is active
  computed(() => props.isWithDragAndDrop + String(props.isWithDragAndDrop ? props.images.map(i => i.serverId) : [])),
  domRefRoot,
  ".photoNode",
  ".photoNode .drag-n-drop-handle",
  (oldIndex: number, newIndex: number) => emit("reorderImages", oldIndex, newIndex)
);

const { isTouchDragInProgress, elementIndexTouchDragged, elementIndexTouchDraggedOver } = useTouchDragNDrop(
  computed(() => props.isWithDragAndDrop),
  // Changes to either of these props will cause the event listeners to be reset.
  computed(() => props.isWithDragAndDrop + String(props.images.map(i => i.serverId))),
  domRefRoot,
  ".photoNode",
  ".photoNode .drag-n-drop-handle",
  (oldIndex: number, newIndex: number) => emit("reorderImages", oldIndex, newIndex)
);
</script>

<style scoped lang="scss">
.transparentForClicks {
  pointer-events: none;
}

// Remove button ==============================================================
.remove-button {
  position: absolute;
  inset: 3px 3px auto auto;
  z-index: 3;
}

.modal .image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  //cursor: pointer; //Only applies to a very small set of scenarios (singlePickMode)
  border: 3px solid transparent;
  transition: 0.3s;

  img {
    width: 100%;
  }
  video {
    width: 100%;
  }

  &.active {
  }
}

.photoNode {
  width: 100%;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  text-align: center;

  .ajax-loader {
    margin: 0;
    position: absolute;
    inset: 50% auto auto 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
  }

  .image-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      border-radius: 4px;
      width: 100%;
    }
  }

  &--active {
    outline: 3px #058587 solid !important;
  }
}

@media only screen and (max-width: 767px) {
  .modal .image-container {
    min-height: 85px;
  }
}

.global-dnd-dragged-over::before {
  left: -5px;
}
.global-dnd-ghost-placeholder::before {
  display: none;
}
.global-dnd-ghost-placeholder::after {
  border-radius: 4px;
}
</style>
