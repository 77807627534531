<template>
  <div class="upload-campaign-template">
    <div class="upload-campaign-template__header">
      <img class="upload-campaign-template__icon" alt="icon" :src="`/images/uploadCampaignIcons/${props.template.iconName}.svg`" />
      <SrpButton
        class="upload-campaign-template__cta-button"
        @click="emit('select', props.templateTheme)"
        :fill="props.solidButtons ? 'solid' : 'outlined'"
        :size="screenSize === 'mobile' || screenSize === 'tablet' ? 'small' : 'normal'"
        >Get Started</SrpButton
      >
    </div>
    <div class="upload-campaign-template__text-wrap">
      <h3 class="global-h3 upload-campaign-template__title">
        {{ props.template.templateTitle }}
      </h3>
      <div class="upload-campaign-template__description">
        {{ props.template.templateDescription }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Ref, inject } from "vue";

// Components
import SrpButton from "@components/ui/SrpButton.vue";

// Types
import { ScreenSize } from "@contracts/screenSize";
import { UploadCampaignTemplateTheme, UploadCampaignTemplate } from "@contracts/uploadCampaignTemplates";

// Global variables
const screenSize = inject("screenSize") as Ref<ScreenSize>;

const props = withDefaults(
  defineProps<{
    templateTheme: UploadCampaignTemplateTheme | null;
    template: UploadCampaignTemplate | null;
    solidButtons?: boolean;
  }>(),
  {
    templateType: null,
    template: null,
    solidButtons: true,
  }
);

const emit = defineEmits<{
  (e: "select", templateTheme: UploadCampaignTemplateTheme): void;
}>();
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Upload campaign template ===================================================
.upload-campaign-template {
  padding: 14px 20px 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: rgba(91, 91, 91, 0.8);
  font: 14px/18px sans-serif;
  background: rgba(243, 243, 243, 1);

  &__header {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 7px;
  }

  &__icon {
    width: 60px;
    aspect-ratio: 1/1;
    margin: 0 0 0 -10px;
  }

  &__text-wrap {
    flex-grow: 1;
  }

  &__title {
    margin-bottom: 8px;
  }

  &__description {
  }

  &__cta-button {
    margin-left: 10px;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
  .upload-campaign-template {
    &__title {
      white-space: nowrap;
    }
  }
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .upload-campaign-template {
    &__title {
      white-space: nowrap;
    }
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .upload-campaign-template {
    &__title {
      white-space: nowrap;
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .upload-campaign-template {
    padding: 14px 20px 23px;

    &__icon {
      width: 60px;
      margin: 0 0 0 -10px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .upload-campaign-template {
    padding: 10px 15px 17px;

    &__header {
      flex-wrap: wrap;
      gap: 15px;
    }

    &__icon {
      width: 60px;
      margin: 0 0 0 -5px;
    }

    &__cta-button {
      margin-left: 0;
      width: 100%;
    }
  }
}
</style>
