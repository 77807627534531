<template>
  <div v-if="communityIdInContext" class="vertical-navigation">
    <SideFlyout v-model:isVisible="onboardingCustomerParams['showOnboardingFlyout']" width="700" title="Onboarding">
      <CustomerOnboarding :onboardingParams="onboardingCustomerParams" :communityIdInContext="communityIdInContext" @onHide="onboardingCustomerParams['showOnboardingFlyout'] = false" />
    </SideFlyout>

    <Teleport v-if="onboardingCustomerParams.showOnboardingButton" to="body">
      <SrpButton class="onboarding-floating-button" @click="onboardingCustomerParams['showOnboardingFlyout'] = true" shape="rounded">
        <template #icon><IconEmbedded name="user-check_2-5" color="rgba(255, 255, 255, 0.8)" /></template>
        Onboarding
      </SrpButton>
    </Teleport>

    <div
      :class="{
        'vertical-navigation__foldable-section': true,
        'vertical-navigation__foldable-section--folded': isMenuFolded,
      }"
    >
      <!-- Foldable title -->
      <div class="foldable-title vertical-navigation__foldable-title" @click="isMenuFolded = !isMenuFolded">
        <div class="foldable-title__title-word">
          Menu
          <!--{{ showSettingsNav ? 'Settings' : 'Dashboard' }} navigation-->
        </div>
        <!--<div class="foldable-title__navigation-word">navigation</div>-->

        <div class="foldable-title__caret-icon">
          <IconEmbedded :name="isMenuFolded ? 'caret-bottom_2-5' : 'caret-top_2-5'" />
        </div>
      </div>
      <!-- / Foldable title -->

      <SettingsMenuLinksList v-if="showSettingsNav" class="vertical-navigation__links-list" @click="isMenuFolded = true" />

      <AdminMenuLinksList v-else class="vertical-navigation__links-list" @click="isMenuFolded = true" />
    </div>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import { defineComponent } from "vue";
import OrgContext from "@logic/OrgContext";

// Types
import { OnboardingCustomer } from "@contracts/onboarding";

// Components
import AdminMenuLinksList from "@components/Navigation/AdminMenuLinksList.vue";
import CustomerOnboarding from "@components/Onboarding/CustomerOnboarding/index.vue";
import SettingsMenuLinksList from "@components/Navigation/SettingsMenuLinksList.vue";
import SideFlyout from "@components/ui/SideFlyout.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

// NOTE: Currently using the dashboard redirect parameter.
// An alternative would be to get the community in context here (but we'd have to react to dropdown changes on the dashboard anyway...)

export default defineComponent({
  name: "CustomerAdminLeftNav",

  components: {
    IconEmbedded,
    AdminMenuLinksList,
    CustomerOnboarding,
    SettingsMenuLinksList,
    SideFlyout,
    SrpButton,
  },

  data() {
    return {
      isMenuFolded: true, // folded | unfolded

      currentRouteName: "",
      communityIdInContext: null as string,
      hotelInContext: false,

      onboardingCustomerParams: {
        collabCount: 0,
        collabTheme: null,
        initialCollabDate: null,
        onboardingCollabInputId: null,
        onboardingPhotoWallId: null,
        showOnboardingButton: false,
        showOnboardingFlyout: false,
      } as OnboardingCustomer | null,
    };
  },

  computed: {
    showSettingsNav(): boolean {
      // Update: Feb 2024, moved the settings gear from the top nav to the left nav.
      // That means SettingsMenuLinksList is dead unless we want to re-use it for something else.
      return false;
      // return this.$route.path.startsWith("/settings");
    },
    orgInContext() {
      return this.globalRoot.orgInContext;
    },
  },

  watch: {
    $route(to, from) {
      this.setRouteName();
      console.info(this.currentRouteName);
    },
    // This would allow us to avoid the redirect through dashboard routes
    "globalRoot.orgInContext": {
      handler: async function (orgInContext) {
        if (orgInContext && orgInContext?.customerId != null) {
          this.setupOrgInContext();
        }
        await this.getOnboardingCustomerParams();
      },
    },
  },

  created() {
    this.globalRoot.customerAdminLeftNavComponent = this;
  },

  async mounted() {
    this.setRouteName();
    this.setupOrgInContext();
  },

  methods: {
    async getOnboardingCustomerParams(): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!this.globalAuth.isAuthenticated) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/customer-onboard/${this.globalRoot?.orgInContext?.customerId}`);

      // Fix a b-u-g where flyout panel hides after data fetching
      const _onboardingCustomerParams = { ...data, showOnboardingFlyout: this.onboardingCustomerParams.showOnboardingFlyout ? true : data.showOnboardingFlyout };

      this.onboardingCustomerParams = _onboardingCustomerParams as OnboardingCustomer;
    },
    setRouteName() {
      this.currentRouteName = this.$router.currentRoute.value.name?.toString();
    },
    setupOrgInContext() {
      const orgInContext = OrgContext.getOrgInContext(this);
      if (orgInContext) {
        this.communityIdInContext = orgInContext?.customerId;
        this.hotelInContext = OrgContext.isOrgInContextHotel(this);
      }
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Onboarding floating button =================================================
.onboarding-floating-button {
  position: fixed !important;
  inset: auto auto calc(25px + 1px) 25px;
  z-index: 100;

  &::before {
    box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.25);
  }
}
@media print {
  .onboarding-floating-button {
    display: none;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .onboarding-floating-button {
    inset: auto auto calc(80px + 1px) 15px;
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .onboarding-floating-button {
    inset: auto auto calc(80px + 1px) 15px;
  }
}

// Foldable title =============================================================
.foldable-title {
  display: flex;
  position: relative;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
  user-select: none;

  &::before {
    content: "";
    width: calc(100% + 45px);
    height: calc(100% + 45px);
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
  }

  &__title-word {
    margin-right: 5px;
  }

  &__navigation-word {
    font-weight: 300;
  }

  &__caret-icon {
    width: 30px;
    height: 30px;
    position: absolute;
    inset: 0 -10px auto auto;
    font-size: 25px;
  }
}

// Vertical navigation ========================================================
.vertical-navigation {
  display: flex;
  flex-direction: column;
  background: #e7edf0;

  &__foldable-section {
    max-height: 1000px;
    padding: 23px 26px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    transition: max-height 0.12s ease-in-out;

    &--folded {
      max-height: 66px;
    }
  }

  &__foldable-title {
    margin-bottom: 30px;
  }

  &__title-wrap {
    margin-bottom: 25px;
  }

  &__title {
    font:
      bold 22px/22px "Quicksand",
      sans-serif;
  }

  &__links-list {
  }
}
@media screen and (min-width: 1240px) {
  .vertical-navigation {
    &__foldable-section {
      max-height: 1000px !important;
      overflow: visible;
    }

    &__foldable-title {
      display: none;
    }
  }
}
@media screen and (max-width: 1239px) {
  .vertical-navigation {
    //&__title {
    //  display: none;
    //}
  }
}
</style>
