<template>
  <div class="visit-focus-slide">
    <!-- Header -->
    <div class="header visit-focus-slide__header">
      <h1 class="global-h1 header__h1">Visit Focus</h1>
      <div class="header__subtitle">Would you like to make any adjustments?</div>
    </div>
    <!-- / Header -->

    <h4 class="global-h4 visit-focus-slide__h4">
      Theme
      <LinkWithIcon v-if="!isThemesTextareaVisible" class="text-with-edit-btn-form__edit-btn" color="teal" isDottedUnderline @click="isThemesTextareaVisible = true" iconInset="0 0 0 3px">
        <template #icon><IconEmbedded name="pencil_2" :size="16" /></template>
        <span>adjust</span>
      </LinkWithIcon>
    </h4>

    <!-- Text with edit button form -->
    <div class="text-with-edit-btn-form visit-focus-slide__text-with-edit-btn-form">
      <textarea
        v-if="isThemesTextareaVisible"
        class="global-textarea text-with-edit-btn-form__textarea"
        :value="props.themes"
        @change="$event => emit('update:themes', ($event.target as HTMLTextAreaElement).value)"
      ></textarea>
      <span v-else class="text-with-edit-btn-form__text">
        {{ props.themes }}
      </span>
    </div>
    <!-- / Text with edit button form -->

    <!--<div class="visit-focus-slide__divider"></div>-->

    <h4 class="global-h4 visit-focus-slide__h4">Content Focus</h4>

    <!-- Text with edit button form -->
    <div class="text-with-edit-btn-form visit-focus-slide__text-with-edit-btn-form">
      <textarea
        v-if="isContentFocusTextareaVisible"
        class="global-textarea content-usage-form__textarea"
        :value="props.contentFocus"
        @change="$event => emit('update:contentFocus', ($event.target as HTMLTextAreaElement).value)"
        placeholder="ex. lifestyle photos, scenery and landscape shots"
      ></textarea>
      <span v-else class="text-with-edit-btn-form__text">
        {{ props.contentFocus }}
      </span>

      <LinkWithIcon v-if="!isContentFocusTextareaVisible" class="text-with-edit-btn-form__edit-btn" color="teal" isDottedUnderline @click="isContentFocusTextareaVisible = true" iconInset="0 0 0 3px">
        <template #icon><IconEmbedded name="pencil_2" :size="16" /></template>
        <span v-if="props.contentFocus">adjust</span>
        <span v-else>add</span>
      </LinkWithIcon>
    </div>
    <!-- / Text with edit button form -->

    <div class="visit-focus-slide__divider"></div>

    <h4 class="global-h4 visit-focus-slide__h4">How will you use this content?</h4>

    <!-- Content usage form -->
    <div class="content-usage-form visit-focus-slide__content-usage-form">
      <textarea class="global-textarea content-usage-form__textarea" :value="props.contentUsage"></textarea>

      <div v-if="suggestionsList.length" class="content-usage-form__suggestions-list">
        Add:&nbsp;&nbsp;
        <span
          class="content-usage-form__suggestion"
          v-for="suggestionText in suggestionsList"
          :key="suggestionText"
          @click="
            () => {
              let updatedText = props.contentUsage;
              if (updatedText) updatedText += ', ';
              updatedText += suggestionText;
              emit('update:contentUsage', updatedText);
              suggestionsList = suggestionsList.filter(i => i !== suggestionText);
            }
          "
        >
          {{ suggestionText }}
        </span>
      </div>
    </div>
    <!-- / Content usage form -->
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";

// Types
import { CollabInput } from "@contracts/collab";

const props = withDefaults(
  defineProps<{
    themes: string;
    contentFocus: string;
    contentUsage: string;
  }>(),
  {
    themes: "",
    contentFocus: "",
    contentUsage: "",
  }
);

const emit = defineEmits<{
  (e: "onMounted"): void;
  (e: "update:themes", value: string): void;
  (e: "update:contentFocus", value: string): void;
  (e: "update:contentUsage", value: string): void;
}>();

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";

// Suggestions list ===========================================================
const suggestionsList = ref<Array<string>>(["Social media", "Website", "Visitor guides", "Brochures/magazines"]);

// Inform the parent component that this component is shown ===================
onMounted(() => emit("onMounted"));

// Toggle textareas ===========================================================
const isThemesTextareaVisible = ref<boolean>(false);
const isContentFocusTextareaVisible = ref<boolean>(false);
</script>

<style scoped lang="scss">
// Header =====================================================================
.header {
  &__h1 {
    margin-bottom: 3px;
  }

  &__subtitle {
    color: rgba(91, 91, 91, 1);
    font-family: sans-serif;
  }
}

// Text with edit button form =================================================
.text-with-edit-btn-form {
  vertical-align: center;

  &__textarea {
    height: 80px;
  }

  &__text {
    color: rgba(91, 91, 91, 1);
    font-family: sans-serif;
    white-space: pre-wrap;
  }

  &__edit-btn {
    margin-left: 5px;
    position: relative;
    top: 3px;
  }
}

// Content usage form =========================================================
.content-usage-form {
  &__textarea {
    height: 40px;
    min-height: 40px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__suggestions-list {
    color: rgba(91, 91, 91, 1);
    font-weight: bold;
    font-family: sans-serif;
  }

  &__suggestion {
    margin-right: 12px;
    font-weight: normal;
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.4);
    text-underline-offset: 2px;
    text-decoration-thickness: 1px;
    text-decoration-style: dashed;
    cursor: pointer;
    user-select: none;

    &:hover {
      text-decoration: none;
    }
  }
}

// Visit focus slide ==========================================================
.visit-focus-slide {
  &__header {
    margin-bottom: 20px;
  }

  &__h4 {
    margin-bottom: 5px;
  }

  &__divider {
    height: 0;
    margin-bottom: 20px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__text-with-edit-btn-form {
    margin-bottom: 20px;
  }

  &__content-usage-form {
  }
}
</style>
