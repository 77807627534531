<template>
  <SrpButton
    :class="{
      'copy-caption-button': true,
      'copy-caption-button--unclickable': isCopiedMsgVisible,
    }"
    :size="props.size"
    @click="copyText"
  >
    {{ isCopiedMsgVisible ? "Copied" : "Copy text" }}
    <template #iconRight>
      <IconEmbedded v-if="isCopiedMsgVisible" name="check_3" :size="props.size === 'small' ? 19 : 23" color="rgba(255, 255, 255, 0.5)" />
      <IconEmbedded v-else name="copy_2" :size="props.size === 'small' ? 19 : 23" color="rgba(255, 255, 255, 0.5)" />
    </template>
  </SrpButton>
</template>

<script setup lang="ts">
import { ref } from "vue";

// Components
import SrpButton from "@components/ui/SrpButton.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export interface Props {
  size?: "small" | "normal";
  text?: string;
}
const props = withDefaults(defineProps<Props>(), {
  size: "normal",
  text: "",
});

// Copy text ==================================================================
const isCopiedMsgVisible = ref(false);

function copyText() {
  if (!props.text) return;

  navigator.clipboard.writeText(props.text).then(
    () => {
      console.info("Copied to clipboard: " + props.text);
    },
    function (err) {
      console.error("Failed to copy to clipboard: ", err);
    }
  );
  isCopiedMsgVisible.value = true;

  setTimeout(() => (isCopiedMsgVisible.value = false), 3000);
}
</script>

<style scoped lang="scss">
// Copy caption button ========================================================
.copy-caption-button {
  &--unclickable {
    pointer-events: none;
  }
}
</style>
