import { Location } from "@contracts/location";

export default {
  getStepsWithLatLong<T extends Location>(locations: Array<T> | null): Array<T> {
    if (locations == null || locations.length === 0) return [];
    var stepsWithLocation = locations.filter(s => s.latitude && s.longitude);
    return stepsWithLocation;
  },

  fitMapToLocationsFromLatLng(map: globalThis.google.maps.Map, locations: Array<globalThis.google.maps.LatLng>) {
    // Note: Should invert this since we're converting twice...
    var converted = locations.map(x => {
      return { latitude: x.lat(), longitude: x.lng() } as Location;
    });
    this.fitMapToLocations(map, converted);
  },

  fitMapToLocations(map: globalThis.google.maps.Map, locations: Array<Location>) {
    var steps = this.getStepsWithLatLong(locations);
    if (steps.length === 0) return;

    var bounds = new globalThis.google.maps.LatLngBounds();
    steps.forEach(step => {
      if (step.latitude) {
        bounds.extend(new globalThis.google.maps.LatLng(step.latitude, step.longitude));
      }
    });
    // console.info(`fitMapToLocations for ${steps.length} steps`, JSON.stringify(bounds));
    if (map?.fitBounds) map.fitBounds(bounds);
    else (map as any).map?.fitBounds?.(bounds);
  },
};
