<template>
  <div class="phone-number-verification-form">
    <h2>Verify your Phone Number</h2>
    <template v-if="step === 'enterPhoneNumber'">
      <form @submit.prevent="requestVerification">
        <SrpPhoneNumberInput
          v-model="unmaskedPhoneNumber"
          :label="userProfileStore.getViewingUserProfile.phoneNumber ? 'Verify your phone number is correct' : 'Enter your phone number to receive texts'"
        />
        <FormErrorsList :errors="errors" />
        <!-- / Errors list -->
        <div class="phone-number-verification-form__actions">
          <SrpButton class="cancel" @click="onCancel" color="gray" fill="outlined">Cancel</SrpButton>
          <SrpButton tag="button" type="submit" :isDisabled="isLoading">
            Next

            <template #iconRight>
              <LoadingIcon v-if="isLoading" :size="26" />
              <IconEmbedded v-else name="arrow-right_2-5" :size="26" />
            </template>
          </SrpButton>
        </div>
      </form>
    </template>
    <template v-else>
      <form @submit.prevent="onVerifyClicked">
        <label>
          Enter the verification code sent to your phone
          <input type="text" v-model="verificationCode" />
        </label>

        <!-- Errors list -->
        <div v-if="errors.length" class="errors-list buttons-sticky-panel__errors-list">
          <ul class="errors-list__list-itself">
            <li class="errors-list__li" v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </div>
        <div class="phone-number-verification-form__actions">
          <SrpButton class="cancel" @click="step = 'enterPhoneNumber'" color="gray" fill="outlined">
            <template #icon>
              <IconEmbedded name="arrow-left_2-5" :size="26" />
            </template>
            Go back
          </SrpButton>
          <SrpButton tag="button" type="submit" :isDisabled="isLoading">Verify</SrpButton>
        </div>
      </form>
    </template>
  </div>
</template>

<script setup lang="ts">
import axios from "axios";
import { computed, onMounted, ref } from "vue";
import PhoneValidation from "@logic/PhoneValidation";

// Components
import FormErrorsList from "@components/FormErrorsList.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpPhoneNumberInput from "@components/ui/SrpPhoneNumberInput.vue";

// Stores
import { useUserProfileStore } from "@stores/userProfileStore";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import LoadingIcon from "@components/ui/LoadingIcon.vue";
const userProfileStore = useUserProfileStore();

const emit = defineEmits<{
  (e: "verificationCanceled"): void;
  (e: "phoneVerified"): void;
}>();

type ModalStep = "enterPhoneNumber" | "verifyPhoneNumber";
const step = ref<ModalStep>("enterPhoneNumber");

const verificationCode = ref("");
const unmaskedPhoneNumber = ref("");

onMounted(resetForm);

const errors = ref<string[]>([]);
function resetForm() {
  step.value = "enterPhoneNumber";
  if (userProfileStore.getViewingUserProfile.phoneNumber) {
    unmaskedPhoneNumber.value = userProfileStore.getViewingUserProfile.phoneNumber;
  } else {
    unmaskedPhoneNumber.value = "";
  }
  verificationCode.value = "";
  errors.value = [];
}

const isLoading = ref(false);
async function requestVerification() {
  errors.value = [];
  if (!PhoneValidation.isPhoneNumberValid(unmaskedPhoneNumber.value)) {
    errors.value.push("Please enter a valid phone number");
    return;
  }
  try {
    isLoading.value = true;
    await axios.post(`${import.meta.env.VITE_API_URL}/sms/user/${userProfileStore.getViewingUserProfile.authNameId}/request-verification`, { phoneNumber: unmaskedPhoneNumber.value });
  } catch (e) {
    errors.value.push("An unexpected error occurred.  Please retry or email support@shrpa.com");
    return;
  } finally {
    isLoading.value = false;
  }

  step.value = "verifyPhoneNumber";
}

async function onVerifyClicked() {
  errors.value = [];
  if (!verificationCode.value) {
    errors.value.push("Please enter a valid verification code");
    return;
  }

  const uri = `${import.meta.env.VITE_API_URL}/sms/user/${userProfileStore.getViewingUserProfile.authNameId}/confirm-verification`;
  try {
    isLoading.value = true;
    const { data } = await axios.post<{ phoneNumberIsVerified: boolean }>(uri, {
      phoneNumber: unmaskedPhoneNumber.value,
      otp: verificationCode.value,
    });

    if (data.phoneNumberIsVerified) {
      // manually update the phone number and verification status of the profile
      // Instead of fetching the profile again which would result in obliterating changes
      // on the edit profile page
      userProfileStore.getViewingUserProfile.phoneNumber = unmaskedPhoneNumber.value;
      userProfileStore.getViewingUserProfile.phoneNumberIsVerified = true;
      emit("phoneVerified");
      return;
    } else {
      errors.value.push("Invalid verification code");
    }
  } catch (e) {
    errors.value.push("An unexpected error occurred.  Please retry or email support@shrpa.com");
    return;
  } finally {
    isLoading.value = false;
  }
}

function onCancel() {
  emit("verificationCanceled");
}
</script>

<style scoped lang="scss">
.phone-number-verification-form {
  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  .cancel {
    margin-right: 10px;
  }
}

.errors-list {
  margin-top: 1rem;
  color: firebrick;

  &__title {
    margin-bottom: 8px;
    color: firebrick;
  }

  &__list-itself {
    padding: 0;
    margin: 0 0 20px;
    list-style: none;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__li {
    font: 14px/18px sans-serif;
  }
}
</style>
