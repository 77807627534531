<template>
  <div class="what-next-form">
    <ul class="what-next-form__guide-steps-list">
      <!-- Guide step -->
      <li class="guide-step what-next-form__guide-step">
        <div class="guide-step__number-n-line">
          <div class="guide-step__number">1</div>
          <div class="guide-step__line"></div>
        </div>
        <div class="guide-step__text-wrap">
          <h3 class="global-h3 guide-step__title">Copy the code</h3>
          <!-- Code snippet -->
          <CodeSnippetWithSyntaxHighlighting style="width: 100%">
            <template #line1>
              <i t>&lt;</i><i r>script&nbsp;</i><i p>id</i>{{ `="shrpa-advsum__${route.params.pageId}--${props.selectedAdventureId}"` }}
              &nbsp;&nbsp;
            </template>
            <template #line2>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i p>src</i>="https://shrpa.com/widget/advsum.js"&nbsp;<i p>defer</i><i t>&gt;</i> <i t>&lt;/</i><i r>script</i><i t>&gt;</i>&nbsp;
            </template>
          </CodeSnippetWithSyntaxHighlighting>
          <!-- / Code snippet -->
        </div>
      </li>
      <!-- / Guide step -->

      <!-- Guide step -->
      <li class="guide-step what-next-form__guide-step">
        <div class="guide-step__number-n-line">
          <div class="guide-step__number">2</div>
          <!--<div class="guide-step__line"></div>-->
        </div>
        <div class="guide-step__text-wrap">
          <h3 class="global-h3 guide-step__title">Paste that code into your website</h3>
          <div class="guide-step__description">
            <p class="guide-step__p">Depending on your website host, paste the copied code into the section of your website where you want the adventure to display.</p>
            <p class="guide-step__p"><b>For Example:</b> using WordPress, it is just a matter of adding a “Shortcode” section and pasting the code you just copied.</p>

            <div style="max-width: 400px; margin-bottom: 15px; border-bottom: 1px rgba(0, 0, 0, 0.1) solid"></div>
            <slot name="colorPicker" />
          </div>
        </div>
      </li>
      <!-- / Guide step -->
    </ul>

    <!-- Help note -->
    <div class="help-note what-next-form__help-note">
      <h3 class="global-h3 help-note__h3">Need help?</h3>
      <div class="help-note__links-row">
        <div class="help-note__link" style="padding-top: 2px"><b>Email: </b><CopyTextNew>support@shrpa.com</CopyTextNew></div>
        <LinkWithIcon class="help-note__link" color="teal" href="https://explore.shrpa.com/integrations/how-to-integrate-adventures-on-your-site/" tag="a" target="_blank">
          <template #icon><IconEmbedded name="info_2-5" :size="19" /></template>
          <span>Detailed install guide</span>
        </LinkWithIcon>
      </div>
    </div>
    <!-- / Help note -->

    <!--<div class="what-next-form__divider-line"></div>
    Examples section
    <div class="examples-section what-next-form__examples-section">
      <h3 class="global-h3 examples-section__h3">Examples</h3>
      <div class="examples-section__snippets-list">
        <a class="example-snippet examples-section__example-snippet" v-for="example in exampleSnippets" :key="example.name" :href="example.href" target="_blank">
          <span class="example-snippet__title">
            <span class="example-snippet__title-link">{{ example.name }}</span>
            <IconEmbedded name="external-link_2-5" class="example-snippet__external-icon" />
          </span>
          <span class="example-snippet__thumbnail-wrap">
            <img class="example-snippet__thumbnail" :src="example.imgSrc" alt="Example photo" />
          </span>
        </a>
      </div>
    </div>
    / Examples section -->
  </div>
</template>

<script setup lang="ts">
import { ref, nextTick } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();

// Components
import CopyTextNew from "@components/CopyTextNew.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import CodeSnippetWithSyntaxHighlighting from "@components/CodeSnippetWithSyntaxHighlighting.vue";

export interface Props {
  selectedAdventureId: string;
}
const props = withDefaults(defineProps<Props>(), {
  selectedAdventureId: "",
});

// Example snippets list ======================================================
const exampleSnippets = [
  {
    name: "Otter Tail Lakes Country",
    href: "https://ottertaillakescountry.com/adventures",
    imgSrc: "https://i0.wp.com/explore.shrpa.com/wp-content/uploads/2021/09/Otter-Tail.png?resize=768%2C741&ssl=1",
  },
  {
    name: "Arkansas",
    href: "https://www.arkansas.com/regions/central/custom-itineraries",
    imgSrc: "https://i0.wp.com/explore.shrpa.com/wp-content/uploads/2023/08/Untitled-design-47.jpg?resize=768%2C722&ssl=1",
  },
  {
    name: "Explore La Crosse",
    href: "https://explorelacrosse.com/adventures/",
    imgSrc: "https://i0.wp.com/explore.shrpa.com/wp-content/uploads/2023/08/La-Crosse-Adventure-Wall.jpg?resize=768%2C994&ssl=1",
  },
];
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Guide step =================================================================
.guide-step {
  display: flex;

  &__number-n-line {
    margin-right: 13px;
    position: relative;
  }

  &__number {
    width: 28px;
    height: 28px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 2;
    align-items: center;
    text-align: center;
    color: #fff;
    font:
      500 15px/15px "Quicksand",
      sans-serif;
    background: #3d8528;
  }

  &__line {
    width: 2px;
    height: calc(100% + 45px);
    position: absolute;
    inset: 0 auto auto calc(50% - 1px);
    background: #d5d5d5;
  }

  &__text-wrap {
    width: calc(100% - 40px);
    flex-grow: 1;
  }

  &__title {
    margin-bottom: 12px;
  }

  &__code-snippet {
    width: 760px;
    max-width: calc(100% - 50px);
    margin-bottom: 20px;
  }

  &__copy-btn {
  }

  &__description {
  }

  &__p {
    font-size: 14px;
    line-height: 18px;
    text-wrap: balance;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .guide-step {
    &__code-snippet {
      max-width: calc(100vw - 120px);
    }
  }
}

// Help note ==================================================================
.help-note {
  padding: 21px 30px 30px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.04);

  &__h3 {
    margin-bottom: 9px;
  }

  &__links-row {
    display: flex;
  }

  &__link {
    margin-right: 28px;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .help-note {
    padding: 17px 23px 20px;

    &__links-row {
      flex-direction: column;
    }

    &__link {
      margin: 0 0 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// Example snippet ============================================================
.example-snippet {
  box-sizing: border-box;

  &__title {
    margin-bottom: 14px;
    display: block;
  }

  &__title-link {
    margin-right: 4px;
    color: #118689;
    text-decoration: underline;
    text-decoration-color: rgba(#118689, 0.5);
    text-underline-offset: 2px;
    text-decoration-thickness: 1px;
  }

  &:hover &__title-link {
    text-decoration: none;
  }

  &__external-icon {
    position: relative;
    top: 2px;
    font-size: 16px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.4);
  }

  &__thumbnail-wrap {
    width: 100%;
    aspect-ratio: 3/2;
    border: 1px #dadada solid;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    overflow: hidden;
  }

  &__thumbnail {
    width: 100%;
    object-fit: cover;
  }
}

// Examples section ===========================================================
.examples-section {
  &__h3 {
    margin-bottom: 18px;
  }

  &__snippets-list {
    padding: 0;
    margin: 0 0 -30px;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  &__example-snippet {
    width: calc(100% / 3 - 26px * 2 / 3);
    margin: 0 26px 30px 0;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .examples-section {
    &__example-snippet {
      width: calc(100% / 2 - 18px * 2 / 3);
      margin: 0 18px 28px 0;

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-child(3n) {
        margin-right: 18px;
      }
    }
  }
}

// What next form =============================================================
.what-next-form {
  color: #5b5b5b;
  font: 14px/18px sans-serif;

  &__guide-steps-list {
    padding: 0;
    margin: 0 0 44px;
    list-style: none;
  }

  &__guide-step {
    margin-bottom: 45px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__help-note {
    margin-bottom: 40px;
  }

  &__divider-line {
    width: 100%;
    height: 0;
    margin-bottom: 30px;
    border-bottom: 1px rgba(0, 0, 0, 0.15) solid;
    font-size: 0;
    line-height: 0;
  }

  &__examples-section {
  }
}
</style>
