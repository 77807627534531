<template>
  <div></div>
</template>

<script lang="ts">
import logger from "@helpers/Logger";
import { useUserProfileStore } from "@stores/userProfileStore";
import { mapState } from "pinia";
import { defineComponent, inject } from "vue";

export default defineComponent({
  name: "FrillWidget",

  data() {
    return {
      globalLog: inject("globalLog") as any,

      widget: undefined, // as FrillWidget | undefined,
      config: undefined, // as FrillConfig | undefined,
      hasInitialized: false,

      // Needed for the watch below
      userProfileStore: useUserProfileStore(),
    };
  },

  /* Not needed now that it's always shown
  beforeDestroy() {
    if (this.widget) {
      this.widget.destroy();
      this.widget = undefined;
    }

    if (this.config) {
      //@ts-ignore
      window.Frill_Config = window.Frill_Config.filter((c) => c !== this.config);
    }
  }, */

  computed: {
    ...mapState(useUserProfileStore, ["getViewingUserProfile", "getActingUserProfile"]),
  },

  watch: {
    // Little hacky but works
    "userProfileStore.loggedInUser": function (profile) {
      if (profile) {
        this.profileLoaded();
      }
    },
  },

  mounted() {
    if (this.getActingUserProfile) {
      this.profileLoaded();
    }
  },

  methods: {
    profileLoaded() {
      try {
        // Only applies for authenticated users
        if (this.hasInitialized) return;

        this.setupFrillWidget();
      } catch (e) {
        console.warn("setupFrillWidget failed " + e);
      }
      this.hasInitialized = true;
    },
    setupFrillWidget() {
      let isCustomer = this.getViewingUserProfile?.adminOf?.length > 0;
      // Default to creator since this should only fire for authenticated users (and if you're not a customer admin you're considered a creator)
      let widgetKey = "91881706-1371-4010-a944-f49cda4b6e6f"; // Creator
      // If they're customer admin then show them a different set of "what's new" announcements
      if (isCustomer) widgetKey = "3e15a2ad-4ee7-425f-bbf3-8c06daa8d1dd"; // Customer
      let overrideKey = this.checkForFrillKeyOverride();
      if (overrideKey) widgetKey = overrideKey;
      logger.info("Frill widget setup: " + widgetKey);

      this.config = {
        key: widgetKey,
        callbacks: {
          onReady: frillWidget => {
            this.widget = frillWidget;
          },
        },
      };

      // @ts-ignore
      window.Frill_Config = window.Frill_Config || [];
      // @ts-ignore
      window.Frill_Config.push(this.config);

      if ("Frill" in window) {
        // @ts-ignore
        this.widget = window.Frill.widget(this.config);
      }
    },
    checkForFrillKeyOverride(): string | null {
      try {
        let urlParams = new URLSearchParams(window.location.search);
        let frillKey = urlParams.get("frillKey");
        return frillKey ?? null;
      } catch (e) {
        this.globalLog.warn("checkForFrillKeyOverride failed " + e);
        return null;
      }
    },
  },
});
</script>
