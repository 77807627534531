import posthog from "posthog-js";
import Logger from "@helpers/Logger";

export class MetricSender {
  public static navigate(toName, fromName) {
    this.sendMetric("navigate", { to: toName, from: fromName });
  }

  public static previewAdventure(itineraryId, uniqueName) {
    this.sendMetric("AdvPreview-View", { itineraryId: itineraryId, uniqueName: uniqueName });
  }

  public static exploreAdventure(itineraryId, uniqueName) {
    this.sendMetric("AdvSteps-View", { itineraryId: itineraryId, uniqueName: uniqueName });
  }

  public static viewCreatorProfile(sherpaId, name) {
    this.sendMetric("CreatorProfile-View", { creatorId: sherpaId, name: name });
  }

  public static shareAdventure(shareType, pageUri) {
    // Note: Ideally we'd actually get the parameter rather than the entire uri
    this.sendMetric("Adv-Share", { shareType: shareType, pageUri: pageUri });
    // ga('send', 'event', 'itinerary', 'share', shareType);
  }

  public static aboutToLogin(source) {
    this.sendMetric("User-AboutToLogin", { source: source });
    // ga('send', 'event', 'account', 'login', source);
  }

  public static userProvisioned(email) {
    this.sendMetric("User-Provisioned", { email: email });
    // ga('send', 'event', 'account', 'create');
  }

  public static identify(uniqueId, email, name, authId, userType) {
    // Replacing Amplitude with Posthog
    // NOTE: Arguably we should use sherpaId instead of authId (since that could change if we
    // re-wire the user with a different auth provider/credential).  If so we'd need to change
    // this to get called after the profile call.
    posthog.identify(uniqueId, { email: email, name: name, userType: userType, authId: authId });

    // NOTE: This will fire if they open a new tab (so there may be many of these events for each AboutToLogin event)
    this.sendMetric("User-LoggedIn", { email: email });

    try {
      if (userType === "super") {
        // Turn of session recording for shrpa team members
        posthog.stopSessionRecording();
      }
    } catch (ex) {
      Logger.warn(`MetricSender: posthog error ${ex.message}`);
    }

    // this.setClarityCustomField("authid", authId);
    // this.setClarityCustomField("email", email);
  }

  public static markCurrentUserAsSpotlight() {
    posthog.people.set({ isSpotlight: true });
    this.setReplayPriority(1);
    Logger.info(`MarkCurrentUserAsSpotlight=true`);
  }

  public static markUserIncomingFromCustomerIntegration(partnerId: string) {
    // Marks if this user was linked from a customer widget integration
    posthog.register({ customerIntegrationId: partnerId });
    Logger.info(`MarkUserIncomingFromCustomerIntegration: ${partnerId}`);
  }

  public static setOrgInContext(customerId) {
    if (posthog) {
      posthog.register({
        customerId: customerId,
      });
      console.info(`Metrics: Setting org in context: ${customerId}`);
    } else {
      console.warn("Posthog isn't setup yet. setOrgInContext");
    }
  }

  public static logout() {
    posthog.reset();
    // this.instance.reset();
    Logger.info("Metrics: User Logout, reset identity.");
  }

  // Gets us a way to mark which replays are more important to review
  public static setReplayPriority(priority: number) {
    this.setPropertyOnSession("replayPri", priority);
  }

  // Sets a property that will be sent with every event in this session
  public static setPropertyOnSession(key: string, value: any) {
    if (posthog) {
      posthog.register({ [key]: value });
    } else {
      console.info("Posthog isn't setup yet. setPropertyOnSession=" + key + "=" + value);
    }
  }

  // Note: Most callers should use specific methods above
  public static sendMetric(eventName, params) {
    if (posthog) {
      // Replacing Amplitude with Posthog
      posthog.capture(eventName, params);
      // this.instance.logEvent(eventName, params);
    } else {
      console.info("Posthog isn't setup yet. Event=" + eventName);
    }
  }
}
