import { useUserProfileStore } from "@stores/userProfileStore";
import AdminContext from "./AdminContext";
import { getGlobalRemoteLogger } from "@helpers/RemoteLogger";

export function setAppKey() {
  ChurnZero.push(["setAppKey", Bootstrap.Config.churnZeroAppKey]);
}

export function setChurnZeroContactAndTrackLogInForCustomerAdmin(customerId: string, customerImmutableId: string) {
  const userProfileStore = useUserProfileStore();
  const currentUser = userProfileStore.getActingUserProfile;
  const isCurrentUserAdmin = AdminContext.isAdminOf(customerId, currentUser, false, true);
  // Shortcut for supers or non-customer admins
  if (!isCurrentUserAdmin || currentUser.isSuperuser || currentUser.isSalesUser) {
    return;
  }
  getGlobalRemoteLogger().info(`ChurnZero CustomerAdmin setContact for ${customerId}, ${customerImmutableId}, ${currentUser.email}, ${currentUser.sherpaId}`);
  ChurnZero.push(["setContact", customerImmutableId, currentUser.email]);
  ChurnZero.push(["trackEvent", "SiteVisit"]);
}

export function setChurnZeroContactAndTrackLogInForCreator() {
  const userProfileStore = useUserProfileStore();
  const currentUser = userProfileStore.getActingUserProfile;
  const isCustomerAdmin = currentUser.adminOf.length > 0;
  // Shortcut for supers or customer admins (since this is the creator method)
  if (isCustomerAdmin || currentUser.isSuperuser || currentUser.isSalesUser) {
    return;
  }
  var paidInterestedOrEligible =
    (currentUser.collabPreferences?.becomingCollabEligible === true || currentUser.collabPreferences?.isCollabEligible === true) &&
    currentUser.collabPreferences?.notInterestedInPaidCollabs !== true &&
    currentUser.collabPreferences?.collabEligibleDisabled !== true;
  if (!paidInterestedOrEligible) return;

  const creatorCustomerImmutableId = "bf8c22f8-c3a0-4b3d-82b3-756491331cb3"; // Should be the same id in dev and prod
  getGlobalRemoteLogger().info(`ChurnZero Creator setContact for ${currentUser.sherpaId}, ${creatorCustomerImmutableId}, ${currentUser.email}`);
  ChurnZero.push(["setContact", creatorCustomerImmutableId, currentUser.email]);
  ChurnZero.push(["trackEvent", "SiteVisit"]);
}
