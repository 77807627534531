<template>
  <div class="social-links">
    <div
      class="social-links__in-wrap"
      :style="{
        marginRight: `-${iconsGap / 2}px`,
        marginLeft: `-${iconsGap / 2}px`,
      }"
    >
      <div v-if="socialFields.instagramHandle" class="social-links__link-wrap">
        <SocialButton
          class="social-links__link"
          :instagramHandle="socialFields.instagramHandle"
          :isOnlyCopy="isOnlyCopy"
          :isCopyIconVisible="showCopyIcon"
          :isOnlyIcon="!isFullSizeIcons"
          :iconSize="iconsSize"
          :iconColor="iconsColor"
          :style="{ margin: `${iconsGap / 2}px` }"
        />
        <LinkWithIcon v-if="props.isWithSeparateEditButtons" @click="() => emit('editField', 'instagramHandle')">
          <template #icon><IconEmbedded name="pencil_2-5" :size="17" /></template>
        </LinkWithIcon>
      </div>

      <div v-if="socialFields.tikTokHandle" class="social-links__link-wrap">
        <SocialButton
          v-if="socialFields.tikTokHandle"
          class="social-links__link"
          :tiktokHandle="socialFields.tikTokHandle"
          :isOnlyCopy="isOnlyCopy"
          :isCopyIconVisible="showCopyIcon"
          :isOnlyIcon="!isFullSizeIcons"
          :iconSize="iconsSize"
          :iconColor="iconsColor"
          :style="{ margin: `${iconsGap / 2}px` }"
        />
        <LinkWithIcon v-if="props.isWithSeparateEditButtons" @click="() => emit('editField', 'tikTokHandle')">
          <template #icon><IconEmbedded name="pencil_2-5" :size="17" /></template>
        </LinkWithIcon>
      </div>

      <div v-if="socialFields.facebookURL" class="social-links__link-wrap">
        <SocialButton
          v-if="socialFields.facebookURL"
          class="social-links__link"
          :facebookUrl="socialFields.facebookURL"
          :isOnlyCopy="isOnlyCopy"
          :isCopyIconVisible="showCopyIcon"
          :isOnlyIcon="!isFullSizeIcons"
          :iconSize="iconsSize"
          :iconColor="iconsColor"
          :style="{ margin: `${iconsGap / 2}px` }"
        />
        <LinkWithIcon v-if="props.isWithSeparateEditButtons" @click="() => emit('editField', 'facebookURL')">
          <template #icon><IconEmbedded name="pencil_2-5" :size="17" /></template>
        </LinkWithIcon>
      </div>

      <div v-if="socialFields.youtubeURL" class="social-links__link-wrap">
        <SocialButton
          v-if="socialFields.youtubeURL"
          class="social-links__link"
          :youtubeUrl="socialFields.youtubeURL"
          :isOnlyCopy="isOnlyCopy"
          :isCopyIconVisible="showCopyIcon"
          :isOnlyIcon="!isFullSizeIcons"
          :iconSize="iconsSize"
          :iconColor="iconsColor"
          :style="{ margin: `${iconsGap / 2}px` }"
        />
        <LinkWithIcon v-if="props.isWithSeparateEditButtons" @click="() => emit('editField', 'youtubeURL')">
          <template #icon><IconEmbedded name="pencil_2-5" :size="17" /></template>
        </LinkWithIcon>
      </div>

      <div v-if="socialFields.twitterHandle" class="social-links__link-wrap">
        <SocialButton
          v-if="socialFields.twitterHandle"
          class="social-links__link"
          :twitterHandle="socialFields.twitterHandle"
          :isOnlyCopy="isOnlyCopy"
          :isCopyIconVisible="showCopyIcon"
          :isOnlyIcon="!isFullSizeIcons"
          :iconSize="iconsSize"
          :iconColor="iconsColor"
          :style="{ margin: `${iconsGap / 2}px` }"
        />
        <LinkWithIcon v-if="props.isWithSeparateEditButtons" @click="() => emit('editField', 'twitterHandle')">
          <template #icon><IconEmbedded name="pencil_2-5" :size="17" /></template>
        </LinkWithIcon>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import SocialButton from "@components/ui/SocialButton.vue";

// Types
import { SocialMediaFields } from "@contracts/creatorPublicProfileFields";

const props = withDefaults(
  defineProps<{
    socialFields: SocialMediaFields | null;
    showCopyIcon?: boolean;
    isOnlyCopy?: boolean;
    isFullSizeIcons?: boolean;
    iconsSize?: number;
    iconsGap?: number;
    iconsColor?: string;
    isWithSeparateEditButtons?: boolean;
  }>(),
  {
    socialFields: null,
    showCopyIcon: false,
    isOnlyCopy: false,
    isFullSizeIcons: true,
    iconsSize: 19,
    iconsGap: 10,
    iconsColor: "rgba(140, 140, 140, 1)",
    isWithSeparateEditButtons: false,
  }
);

const emit = defineEmits<{
  (e: "editField", value: keyof SocialMediaFields): void;
}>();
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";

// Social links ===============================================================
.social-links {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  &__in-wrap {
    gap: 7px;
    margin: 0 -10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__link-wrap {
    display: flex;
    align-items: center;
  }

  &__link {
    margin: 0 !important;
  }
}
</style>
