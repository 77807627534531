<template>
  <div
    :class="{
      'color-snippet': true,
      'color-snippet--disabled': isLoading,
    }"
  >
    <h6 class="global-h6 color-snippet__title" @click="isColorPickerDropdownVisible = true">Accent Color</h6>
    <!-- Color picker link -->
    <div class="color-picker-link color-snippet__color-picker-link">
      <div class="color-picker-link__link-itself" @click="isColorPickerDropdownVisible = !isColorPickerDropdownVisible">
        <div class="color-picker-link__circle" :style="{ background: color || brandColorHex }"></div>
        <!--<div class="color-picker-link__text">{{ accentColor || "Color code" }}</div>-->
      </div>

      <div
        :class="{
          'color-picker-link__picker-dropdown-wrap': true,
          'color-picker-link__picker-dropdown-wrap--position-top': props.dropdownPosition === 'top',
          'color-picker-link__picker-dropdown-wrap--hidden': !isColorPickerDropdownVisible,
        }"
        v-click-outside="handleClickOutsidePicker"
      >
        <div class="color-picker-link__picker-dropdown">
          <ColorPicker
            class="color-picker-link__picker-form"
            :color="color || brandColorHex"
            @colorChange="
              (newColor: ColorPickerColorObj) => {
                color = newColor.cssColor;
                emit('change', newColor.cssColor === brandColorHex ? null : newColor.cssColor);
              }
            "
            :visibleFormats="['hex']"
            alphaChannel="hide"
            ref="domRefColorPicker"
          />

          <div class="color-picker-link__button-container">
            <SrpButton @click="isColorPickerDropdownVisible = false" size="tiny" fill="outlined" color="gray">Done</SrpButton>
            <SrpButton
              v-if="color !== brandColorHex"
              fill="outlined"
              color="gray"
              size="tiny"
              @click="
                () => {
                  emit('change', brandColorHex);
                  color = brandColorHex;
                  saveColor();
                  isColorPickerDropdownVisible = false;
                }
              "
            >
              Clear
            </SrpButton>
          </div>
        </div>
      </div>
    </div>
    <!-- / Color picker link -->

    <InfoIconWithTooltip class="color-snippet__info-tooltip-icon">This color will apply to all Community Uploads, Photo Walls, and Adventure Posts</InfoIconWithTooltip>
  </div>
</template>

<script setup lang="ts">
import axios from "axios";
import { brandColorHex } from "@/constants";
import { ref, watch, onMounted } from "vue";
import { useRoute } from "vue-router";

// Components
import { ColorPicker } from "vue-accessible-color-picker";
import InfoIconWithTooltip from "@components/ui/InfoIconWithTooltip.vue";
import SrpButton from "@components/ui/SrpButton.vue";

const props = withDefaults(
  defineProps<{
    dropdownPosition?: "bottom" | "top";
  }>(),
  {
    dropdownPosition: "bottom",
  }
);

const emit = defineEmits<{
  (e: "change", newColorHex: string | null): void;
  (e: "close"): void;
}>();

const route = useRoute();

interface ColorPickerColorObj {
  colors: {
    hex: string; // Example: #98ff75ff
    hsl: { h: number; s: number; l: number; a: number };
    hwb: { h: number; s: number; v: number; a: number };
    hgb: { r: number; g: number; b: number; a: number };
  };
  cssColor: string; // Example: #98ff75
}

// Toggle color picker ========================================================
const isColorPickerDropdownVisible = ref(false);
let timePassedAfterPickerOpening = 0;

watch(
  () => isColorPickerDropdownVisible.value,
  async () => {
    if (isColorPickerDropdownVisible.value) {
      timePassedAfterPickerOpening = Date.now();
    } else {
      emit("close");
      await saveColor();
    }
  }
);

function handleClickOutsidePicker() {
  if (Date.now() - timePassedAfterPickerOpening > 200) isColorPickerDropdownVisible.value = false;
}

// Get/save global accent color ===============================================
const color = ref("");
const isLoading = ref(false);

onMounted(async () => {
  await getColor();
});

async function getColor(): Promise<void> {
  let uri = `${import.meta.env.VITE_API_URL}/widget-admin/${route.params.pageId}/widgets/settings`;

  isLoading.value = true;
  const { data } = await axios.get(uri);
  color.value = data.widgetSettings.accentColor || brandColorHex;

  isLoading.value = false;
}

async function saveColor(): Promise<void> {
  const urlSearchParams = new URLSearchParams();
  if (color.value) {
    urlSearchParams.append("accentColor", color.value);
  }

  let uri = `${import.meta.env.VITE_API_URL}/widget-admin/${route.params.pageId}/widgets/settings` + "?" + urlSearchParams.toString();

  isLoading.value = true;
  await axios.put(uri);
  isLoading.value = false;
}
</script>

<style scoped lang="scss">
// Color picker link ==========================================================
.color-picker-link {
  position: relative;

  &__link-itself {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
  }

  &__circle {
    width: 25px;
    min-width: 25px;
    height: 25px;
    box-sizing: border-box;
    border: 1px rgba(187, 187, 187, 1) solid;
    border-radius: 500px;
  }

  &__text {
    width: 60px;
    text-decoration: underline;
    text-decoration-color: rgba(91, 91, 91, 0.5);
    text-underline-offset: 2px;
    text-decoration-thickness: 1px;
    text-decoration-style: dashed;
    white-space: nowrap;
  }

  &:hover &__text {
    text-decoration: none;
  }

  &__picker-dropdown-wrap {
    width: 230px;
    min-width: 230px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    inset: 30px auto auto calc(50% - 115px);
    overflow: hidden;
    transform: translateY(0);
    opacity: 1;
    box-shadow: 0 6px 20px -4px rgba(0, 0, 0, 0.3);
    transition:
      opacity 0.09s ease-in-out,
      transform 0.09s ease-in-out;

    &--hidden {
      opacity: 0;
      transform: translateY(-20px);
      pointer-events: none;
    }

    &--position-top {
      top: auto;
      bottom: 30px;
    }

    &--position-top.color-picker-link__picker-dropdown-wrap--hidden {
      transform: translateY(20px);
    }
  }

  &__picker-dropdown {
    padding: 12px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    z-index: 1;
    box-shadow: 0 6px 20px -5px rgba(0, 0, 0, 0.4);
    background: rgba(255, 255, 255, 1);
  }

  &__picker-form {
    width: 100%;
    padding: 0 0 11px;
    margin-bottom: 10px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

// Color snippet ==============================================================
.color-snippet {
  display: inline-flex;
  align-items: center;
  position: relative;

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &__title {
    margin-right: 7px;
    position: relative;
    z-index: 1;
    font-weight: bold;
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.3);
    text-underline-offset: 2px;
    text-decoration-thickness: 1px;
    text-decoration-style: dashed;
    cursor: pointer;
    user-select: none;

    &:hover {
      text-decoration: none;
    }

    &::before {
      content: "";
      width: calc(100% + 32px);
      height: calc(100% + 15px);
      position: absolute;
      inset: 50% auto auto 0;
      transform: translateY(-50%);
    }
  }

  &__color-picker-link {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__clear-link {
    margin-right: 8px;
    position: relative;
    top: -1px;
  }

  &__info-tooltip-icon {
  }
}
</style>
