<template>
  <div class="tabs">
    <h3 class="global-h3 tabs__title">Group by:</h3>
    <ul class="tabs__tabs-wrap">
      <li
        :class="{
          'global-h3': true,
          tabs__tab: true,
          'tabs__tab--active': groupingType === 'adventure',
        }"
        @click="emit('setGroupingType', 'adventure')"
      >
        {{ props.useNewCollabLogic ? "Creator Visit" : "Adventure" }}
      </li>
      <li
        :class="{
          'global-h3': true,
          tabs__tab: true,
          'tabs__tab--active': groupingType === 'creator',
        }"
        @click="emit('setGroupingType', 'creator')"
      >
        Creator
      </li>
      <li
        :class="{
          'global-h3': true,
          tabs__tab: true,
          'tabs__tab--active': groupingType === 'location',
        }"
        @click="emit('setGroupingType', 'location')"
      >
        Location
      </li>
      <li
        :class="{
          'global-h3': true,
          tabs__tab: true,
          'tabs__tab--active': groupingType === 'community-upload',
        }"
        @click="emit('setGroupingType', 'community-upload')"
      >
        Community Upload
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
// Types
export type GroupingType = "adventure" | "creator" | "location" | "community-upload";

const props = withDefaults(
  defineProps<{
    groupingType: GroupingType;
    useNewCollabLogic?: boolean;
  }>(),
  {
    groupingType: "adventure",
    useNewCollabLogic: false,
  }
);

const emit = defineEmits<{
  (e: "setGroupingType", groupingType: GroupingType): void;
}>();
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Tabs =======================================================================
.tabs {
  display: flex;
  align-items: center;
  position: relative;

  &::after {
    content: "";
    width: 100%;
    height: 0;
    border-bottom: 1px rgba(204, 204, 204, 1) solid;
    position: absolute;
    inset: auto auto 0 0;
    z-index: 1;
  }

  &__title {
    padding-bottom: 3px;
    margin-right: 23px;
    font-weight: 400;
  }

  &__tabs-wrap {
    padding: 0;
    margin: 0;
    display: flex;
    list-style: none;
  }

  &__tab {
    height: 60px;
    padding: 0 20px 3px;
    margin-right: 8px;
    box-sizing: border-box;
    border: 1px rgb(232, 232, 232, 1) solid;
    border-width: 1px 1px 0 1px;
    border-radius: 6px 6px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    line-height: 17px;
    background: rgb(232, 232, 232, 1);
    cursor: pointer;
    user-select: none;

    &:hover {
      background: rgb(218, 218, 218, 1);
    }

    &--active,
    &--active:hover {
      border-color: rgb(210, 210, 210, 1);
      position: relative;
      z-index: 2;
      background: rgba(255, 255, 255, 1);
      cursor: default;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .tabs {
    flex-direction: column;
    align-items: flex-start;

    &__title {
      margin-bottom: 10px;
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .tabs {
    flex-direction: column;
    align-items: flex-start;

    &__title {
      margin-bottom: 10px;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .tabs {
    flex-direction: column;
    align-items: flex-start;

    &__title {
      margin-bottom: 10px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .tabs {
    flex-direction: column;
    align-items: flex-start;

    &__title {
      margin: 0 20px 10px;
    }

    &__tab {
      height: 60px;
      padding: 0 10px 3px;
      margin-right: 4px;
    }
  }
}
</style>
