<template>
  <li
    :class="{
      'location-input-snippet': true,
      'location-input-snippet--error': showValidation && (_collabLocation.title || _collabLocation.externalId) && !_collabLocation.latitude,
      'location-input-snippet--line-on-top': props.isDraggedOver && [1, 2].includes(dragOverAreaQuarter),
      'location-input-snippet--line-on-bottom': props.isDraggedOver && [3, 4].includes(dragOverAreaQuarter),
    }"
    v-click-outside="clickOutsideSnippet"
    :data-index="props.index"
  >
    <div class="location-input-snippet__first-line">
      <IconEmbedded class="location-input-snippet__drag-n-drop-handle" name="drag-n-drop-handle-2-lines" :data-index="props.index" />

      <div class="global-h5 location-input-snippet__number">
        {{ props.index + 1 }}
      </div>

      <!-- Input section -->
      <div class="input-section location-input-snippet__inputs-wrap" ref="domRefAddressNNotesInputsWrap">
        <GMapAutocomplete
          v-if="isEditMode || !_collabLocation.title"
          @place_changed="googlePlaceSelected"
          @input="
            e => {
              // console.info(`CollabLocationInput: input: ${e?.target?.value}`);
              _collabLocation.title = e.target.value;
            }
          "
          @error="autoCompleteError"
          :value="_collabLocation.title"
          :options="{
            fields: ['name', 'place_id', 'address_components', 'formatted_address', 'geometry', 'website'],
          }"
          @focus="
            () => {
              // console.info('CollabLocationInput: focus');
              emit('focusOnAnyField');
              isEditMode = true;
              // If the title is already set, allow the isSetThePinNoteVisible warning to show
              if (!_collabLocation.title) userClickedAwayFromInput = false;
            }
          "
          @blur="
            () => {
              // console.info('CollabLocationInput: blur');
              userClickedAwayFromInput = true;
            }
          "
        />

        <div v-else class="input-section__location" @click="startToEdit">
          {{ `${_collabLocation.title}${_collabLocation.visitPlanNotes && !isNoteInputVisible ? ":" : ""}` }} <template v-if="isCollabLocationUsedInCollab">*</template>
        </div>

        <input
          v-if="isNoteInputVisible"
          class="global-text-input input-section__input-notes"
          placeholder="Notes"
          type="text"
          v-model.lazy="_collabLocation.visitPlanNotes"
          ref="domRefNotesInput"
          @focus="emit('focusOnAnyField')"
        />

        <LinkWithIcon
          v-else-if="(!_collabLocation.visitPlanNotes && !_collabLocation.title) || (isEditMode && !_collabLocation.visitPlanNotes)"
          class="input-section__add-notes-link"
          isDottedUnderline
          iconInset="2px 0 0 2px"
          @click="showAndFocusNotesInput"
        >
          <template #icon><IconEmbedded name="notepad_2" :size="19" /></template>
          <span>add notes</span>
        </LinkWithIcon>

        <div v-if="_collabLocation.visitPlanNotes && !isEditMode" class="input-section__notes">
          {{ _collabLocation.visitPlanNotes }}
        </div>

        <LinkWithIcon v-if="hasSomeData && !isEditMode" @click="startToEdit" color="teal">
          <template #icon><IconEmbedded name="pencil_2" :size="16" /></template>
        </LinkWithIcon>

        <template
          v-if="
            !isCollabLocationUsedInCollab &&
            isEditMode &&
            (_collabLocation.visitPlanNotes ||
              _collabLocation.title ||
              _collabLocation.address ||
              _collabLocation.latitude ||
              _collabLocation.latitude ||
              _collabLocation.country ||
              _collabLocation.mediaIds?.length)
          "
        >
          <LinkWithIcon class="input-section__delete-button" @click.stop.prevent="emit('deleteLocation', props.index)" color="red">
            <template #icon><IconEmbedded name="trashcan-alt_2-5" :size="16" /></template>
          </LinkWithIcon>
        </template>

        <LinkWithIcon v-if="isEditMode" class="input-section__delete-button" @click.stop.prevent="handleDoneEditing" color="teal" isDottedUnderline iconInset="0 0 0 2px">
          <template #icon><IconEmbedded name="check_3" :size="16" /></template>
          <span>Done editing</span>
        </LinkWithIcon>
      </div>
      <!-- / Input section -->
    </div>

    <p v-if="isEditMode && isCollabLocationUsedInCollab" class="location-input-snippet__location-in-use-warning">This location is used in an adventure and cannot be removed.</p>
    <div v-if="_collabLocation.title && isEditMode" class="location-input-snippet__second-line">
      <input v-if="isManualAddress" class="global-text-input location-input-snippet__place-input" v-model="_collabLocation.address" ref="domRefAddressInput" placeholder="Address" />
      <div v-else-if="_collabLocation.address" class="location-input-snippet__place-address">
        {{ _collabLocation.address }}
      </div>

      <SrpCheckbox v-if="isEditMode" class="location-input-snippet__enter-location-manually-checkbox" :isWithHalo="false">
        <template #input>
          <input type="checkbox" v-model="isManualAddress" />
        </template>
        <template #text>{{ _collabLocation.address ? "Adjust the address" : "Add an address" }} </template>
      </SrpCheckbox>

      <LinkWithIcon v-if="false && isEditMode && !isManualAddress" class="input-section__add-notes-link" isDottedUnderline iconInset="2px 0 0 2px" @click="isManualAddress = true">
        <template #icon><IconEmbedded name="pencil_2" :size="19" /></template>
        <span>edit address</span>
      </LinkWithIcon>
    </div>

    <div v-if="isSetThePinNoteVisible" class="location-input-snippet__third-line">
      <NoteWithIcon size="tiny">
        <template #icon><IconEmbedded name="info-simple_4" :size="17" /></template>
        <template #text><span>Click on the map to set the pin</span></template>
      </NoteWithIcon>
    </div>
  </li>
</template>

<script setup lang="ts">
import { ref, nextTick, watch, computed, inject, Ref } from "vue";
import AddressHelper from "@helpers/AddressHelper";
import GooglePlaceConverter from "@logic/GooglePlaceConverter";
import { getGlobalRemoteLogger } from "@helpers/RemoteLogger";

// Types
import { CollabLocation } from "@contracts/collabLocations";
import { Location } from "@contracts/location";
import { AreaQuarter } from "@composables/useMouseDragNDrop";

// Component
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import SrpCheckbox from "@components/ui/SrpCheckbox.vue";

const props = withDefaults(
  defineProps<{
    index: number;
    isDraggedOver?: boolean;
    collabLocation: CollabLocation | null;
    isCollabLocationUsedInCollab?: boolean;
    showValidation?: boolean;
    dragOverAreaQuarter?: AreaQuarter;
  }>(),
  {
    index: 1,
    isDraggedOver: false,
    collabLocation: null,
    isCollabLocationUsedInCollab: false,
    showValidation: false,
    dragOverAreaQuarter: null,
  }
);

const emit = defineEmits<{
  (e: "focusOnAnyField"): void;
  (e: "editModeToggle", value: boolean): void;
  (e: "showSetThePinNote", value: boolean): void;
  (e: "googlePlaceSelected", value: CollabLocation): void;
  (e: "update:collabLocation", value: CollabLocation): void;
  (e: "deleteLocation", index: number): void;
}>();

const remoteLogger = getGlobalRemoteLogger();

// Create the local copy of the CollabLocation ================================
const _collabLocation = ref<CollabLocation>({ ...props.collabLocation });

watch(
  _collabLocation,
  () => {
    emit("update:collabLocation", _collabLocation.value);
  },
  { deep: true }
);

// Google place selected ======================================================
async function googlePlaceSelected(place: google.maps.places.PlaceResult) {
  remoteLogger.info(`Collab LocationInput googlePlaceSelected: ${place?.name} ${place?.place_id}`);
  GooglePlaceConverter.setItineraryFieldsFromPlace(place, _collabLocation.value);
  emit("googlePlaceSelected", _collabLocation.value);
}

function autoCompleteError(error) {
  // Log the error
  console.error("Google Maps Autocomplete Error:", error);
  remoteLogger.error(`--ALERT-- Google Maps Autocomplete Error: ${error?.status} ${error?.message}`, error);
}

// Is manual address ==========================================================
const isManualAddress = ref<boolean>(false);

// Is edit mode ===============================================================
const isEditMode = ref<boolean>(false);

// Toggle notes input =========================================================
const isNoteInputVisible = ref<boolean>(false);
const domRefNotesInput = ref<HTMLElement | null>(null);

async function showAndFocusNotesInput() {
  isNoteInputVisible.value = true;
  await nextTick();
  domRefNotesInput.value.focus();
  isEditMode.value = true;
}

// Click edit button ==========================================================
async function startToEdit() {
  isEditMode.value = true;
  userClickedAwayFromInput.value = true; // So the warning can show up when the user clicks in to edit
  isNoteInputVisible.value = Boolean(_collabLocation.value.visitPlanNotes);

  await setFocusOnLocationInput();
}

// Check if snippet has some data =============================================
const hasSomeData = computed<boolean>(() => Boolean(_collabLocation.value.title || _collabLocation.value.visitPlanNotes));

// Set focus on location input ================================================
const domRefAddressNNotesInputsWrap = ref<HTMLElement | null>(null);
async function setFocusOnLocationInput() {
  await new Promise(resolve => setTimeout(resolve, 0));
  domRefAddressNNotesInputsWrap.value.querySelector(".input-section__input-location")?.focus();
}

// Focus the address input on "manual location" checkbox tick =================
const domRefAddressInput = ref<HTMLElement | null>(null);

watch(isManualAddress, async () => {
  await nextTick();

  if (isManualAddress.value) {
    domRefAddressInput.value.focus();
  }
});

// Click outside snippet ======================================================
async function clickOutsideSnippet(event) {
  await nextTick();
  // Note: The booleans set below created a bug on mobile, where the auto-complete selection would not fire.
  // Excluding the Autocomplete container fixed, as did delaying the boolean setting (so we've done both below).
  // Ideally we would have a more reliable Google Autocomplete query selector than ".pac-container"
  if (event?.target?.closest(".map-section.locations-list__map-section") || event?.target?.closest(".pac-container")) {
    return;
  }
  setTimeout(handleDoneEditing, 100);
}

// Done editing ===============================================================
function handleDoneEditing() {
  isEditMode.value = false;
  isNoteInputVisible.value = false;
  isManualAddress.value = false;
}

// Toggle the "Set the Pin" note ==============================================
const userClickedAwayFromInput = ref<boolean>(false);
const isSetThePinNoteVisible = computed<boolean>(() => {
  const isLatLngSet = _collabLocation.value.latitude !== null && _collabLocation.value.longitude !== null;
  const { title } = _collabLocation.value;
  // Also only show this when the user clicks away (not as they're typing in the auto-complete)
  return Boolean(isEditMode.value && (isManualAddress.value || title) && !isLatLngSet && userClickedAwayFromInput.value);
});

watch(
  () => [isSetThePinNoteVisible.value, isEditMode.value],
  () => {
    if (!isEditMode.value) {
      emit("showSetThePinNote", false);
      return;
    }
    emit("showSetThePinNote", isSetThePinNoteVisible.value);
  }
);

// Get map click location =====================================================
const mapClickLocation = inject("mapClickLocation") as Ref<Location>;

watch(mapClickLocation, () => {
  if (isSetThePinNoteVisible.value) {
    _collabLocation.value = { ..._collabLocation.value, latitude: mapClickLocation.value.latitude, longitude: mapClickLocation.value.longitude };
  }
});

// Emit "editModeTurnedOn" event edit mode turns on ===========================
watch(isEditMode, async () => {
  if (isEditMode.value) {
    setTimeout(() => emit("editModeToggle", true), 100);
  } else {
    emit("editModeToggle", false);
  }
});

// Save dragged marker location ===============================================
const draggedMarkerNewLocation = inject("draggedMarkerNewLocation") as Ref<Location>;

watch(draggedMarkerNewLocation, () => {
  if (isEditMode.value) {
    _collabLocation.value = { ..._collabLocation.value, latitude: draggedMarkerNewLocation.value.latitude, longitude: draggedMarkerNewLocation.value.longitude };
  }
});

// defineExpose ===============================================================
defineExpose({
  setFocusOnLocationInput,
  hideTheMap: () => {
    isEditMode.value = false;
    isNoteInputVisible.value = false;
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";

// Input section ==============================================================
.input-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &__input-location {
    min-width: calc(50% - 28px);
    max-width: calc(50% - 28px);
    margin: 2px 5px 2px 0;
  }

  &__location {
    margin-right: 8px;
    display: inline;
    position: relative;
    color: rgba(0, 0, 0, 1);
    font-weight: 700;
    font-family: "Quicksand", sans-serif;
    cursor: pointer;
    user-select: none;

    &::before {
      content: "";
      width: calc(100% + 16px);
      height: calc(100% + 16px);
      position: absolute;
      inset: 50% auto auto 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      text-decoration: underline;
      text-decoration-color: rgba(91, 91, 91, 0.5);
      text-underline-offset: 2px;
      text-decoration-thickness: 1px;
      text-decoration-style: dashed;
    }
  }

  &__add-notes-link {
    margin-right: 10px;
  }

  &__input-notes {
    min-width: calc(50% - 28px);
    max-width: calc(50% - 28px);
    margin: 2px 0 2px 8px;
  }

  &__notes {
    margin-right: 8px;
    display: inline;
    font-style: italic;
  }

  &__edit-button {
  }

  &__delete-button {
    margin-left: 10px;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .input-section {
    display: block;

    &__input-location {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }

    &__add-notes-link {
      margin: 0 0 12px -6px;
    }

    &__input-notes {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      margin: 2px 0 9px 0;
    }
  }
}

// Map section ================================================================
.map-section {
  width: 530px;
  height: 220px;

  &__map-itself {
  }
}

// Location input snippet =====================================================
.location-input-snippet {
  display: flex;
  flex-direction: column;
  position: relative;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  border-color: transparent;
  padding: 4px;

  &--error {
    background-color: rgba(255, 0, 0, 0.05);
    border-color: rgba(255, 0, 0, 0.4);
  }

  &--line-on-top {
    &::before {
      content: "";
      width: 100%;
      height: 3px;
      border-radius: 100px;
      position: absolute;
      inset: -3px auto auto 0;
      background: $brand-color;
    }
  }

  &--line-on-bottom {
    &::before {
      content: "";
      width: 100%;
      height: 3px;
      border-radius: 100px;
      position: absolute;
      inset: auto auto -2px 0;
      background: $brand-color;
    }
  }

  // first line -----------------------
  &__first-line {
    display: flex;
    align-items: center;
  }

  &__drag-n-drop-handle {
    width: 26px;
    min-width: 26px;
    margin-right: 9px;
    cursor: grab;

    :deep(*) {
      pointer-events: none;
    }
  }

  &__number {
    width: 25px;
    min-width: 25px;
  }

  &__inputs-wrap {
    flex-grow: 1;
  }

  &__location-in-use-warning {
    padding-left: 60px;
    margin: 0;
    font-size: 12px;
  }

  // second line ----------------------
  &__second-line {
    padding-left: 60px;
    margin: 4px 0 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__place-address {
    margin-right: 25px;
    font-weight: bold;
  }

  &__place-input {
    min-width: calc(50% - 13px);
    max-width: calc(50% - 13px);
    margin-right: 12px;
  }

  &__enter-location-manually-checkbox {
  }

  // third line -----------------------
  &__third-line {
    padding-left: 59px;
    display: flex;
    justify-content: flex-start;
  }

  &__set-the-pin-note {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .location-input-snippet {
    // first line -----------------------
    &__first-line {
      align-items: flex-start;
    }

    &__drag-n-drop-handle {
      margin: 10px 9px 0 0;
    }

    &__number {
      margin-top: 14px;
    }

    &__place-input {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      margin: 0 12px 4px 0;
    }

    &__enter-location-manually-checkbox {
      margin-top: -5px !important;
    }
  }
}
</style>
