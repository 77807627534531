<template>
  <SrpModal :isVisible="props.isVisible" @update:isVisible="emit('update:isVisible', false)">
    <template #content>
      <h1 class="global-h1" style="margin-bottom: 25px !important">Who do you want to share the content with?</h1>

      <div style="margin-bottom: 7px"><i>Note:</i> This does not cascade to other child objects (ex. collab, adventure).</div>
      <div style="margin-bottom: 25px; display: flex; align-items: center">
        <h4 class="global-h4" style="margin-right: 8px; display: inline-flex">Add customers:</h4>
        &nbsp;
        <select
          class="global-select"
          style="width: 250px"
          @change="$event => _communityIdsList.push($event.target.value)"
          :key="allCommunityIdsList.filter(i => !_communityIdsList.includes(i)).toString()"
        >
          <option selected disabled>Select commmunity</option>
          <option v-for="communityId in allCommunityIdsList.filter(i => !_communityIdsList.includes(i))" :key="communityId">
            {{ communityId }}
          </option>
        </select>
      </div>

      <div v-if="_communityIdsList?.length" style="margin-bottom: 25px; border-bottom: 1px rgba(0, 0, 0, 0.1) solid"></div>

      <TransitionScale
        group
        v-if="_communityIdsList?.length"
        :duration="100"
        easing="ease-in-out"
        tag="ul"
        style="gap: 8px; padding: 0; margin: 0 0 15px; display: flex; flex-wrap: wrap; list-style: none"
      >
        <li
          v-for="(communityId, index) in _communityIdsList"
          :key="communityId"
          style="
            padding: 0 6px 10px 10px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            color: rgba(0, 0, 0, 0.7);
            font-family: sans-serif;
            background: rgba(255, 0, 0, 0.08);
            cursor: pointer;
            user-select: none;
          "
        >
          <LinkWithIcon
            color="orange"
            isDottedUnderline
            @click="() => (_communityIdsList = _communityIdsList.filter(id => id !== communityId))"
            iconInset="0 0 0 0"
            style="margin: 0 0 0 4px; position: relative; top: 3px"
          >
            <span> {{ communityId }}</span>
            <template #iconRight>
              <IconEmbedded name="remove_2" :size="20" color="rgba(0, 0, 0, 0.8)" />
            </template>
          </LinkWithIcon>
        </li>
      </TransitionScale>
    </template>

    <template #footer>
      <SrpButton @click="emit('update:isVisible', false)" :isDisabled="isLoadingCommunityIds || isSaving" color="gray" fill="outlined" style="margin-right: 12px">Cancel</SrpButton>
      <SrpButton @click="save" :isDisabled="isLoadingCommunityIds || isSaving">
        <template #icon>
          <LoadingIcon v-if="isSaving" :size="22" />
          <IconEmbedded v-else name="save_2" :size="22" color="rgba(255, 255, 255, 0.4)" />
        </template>
        Save
      </SrpButton>
    </template>
  </SrpModal>
</template>

<script setup lang="ts">
import axios from "axios";
import { ref, watch, watchEffect } from "vue";

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import { TransitionFade, TransitionScale } from "@morev/vue-transitions";
import LoadingIcon from "@components/ui/LoadingIcon.vue";

const props = withDefaults(
  defineProps<{
    isVisible: boolean;
    communityIdsList: Array<string>;
    saveFunction: (newCommunityIdsList: Array<string>) => Promise<void> | null;
  }>(),
  {
    isVisible: false,
    communityIdsList: () => [],
    saveFunction: null,
  }
);

const emit = defineEmits<{
  (e: "update:isVisible", value: boolean): void;
}>();

// Set local _communityIdsList variable =======================================
const _communityIdsList = ref<Array<string>>([]);

watch(
  () => props.isVisible,
  () => {
    if (props.isVisible) {
      _communityIdsList.value = [...props.communityIdsList];
    }
  }
);

// Load all community ids =====================================================
const allCommunityIdsList = ref<Array<string>>([]);
const isLoadingCommunityIds = ref<boolean>(false);

watchEffect(async () => {
  if (props.isVisible && !allCommunityIdsList.value.length) {
    await getAllCommunityIds();
  }
});

async function getAllCommunityIds() {
  // Used for the superuser manual community add
  // Added back sales customers since we want Funtown and the hotel test
  isLoadingCommunityIds.value = true;

  const everyCommunityResult = await axios.get(`${import.meta.env.VITE_API_URL}/pages/ids?includeTestCustomers=true`);
  allCommunityIdsList.value = everyCommunityResult.data;

  isLoadingCommunityIds.value = false;
}

// Save =======================================================================
const isSaving = ref<boolean>(false);

async function save(): Promise<void> {
  isSaving.value = true;
  await props.saveFunction(_communityIdsList.value);
  isSaving.value = false;

  emit("update:isVisible", false);
}
</script>

<style scoped lang="scss"></style>
