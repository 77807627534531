<template>
  <div class="we-love-to-visit-snippet">
    We
    <IconEmbedded class="we-love-to-visit-snippet__heart-icon" name="heart-solid" />
    {{ destinationName }}
  </div>
</template>

<script>
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export default {
  name: "WeLoveToVisitSnippet",
  components: { IconEmbedded },

  props: {
    destinationName: { type: String, default: "" },
  },
};
</script>

<style scoped lang="scss">
// We love to visit snippet ===================================================
.we-love-to-visit-snippet {
  display: flex;
  justify-content: center;
  align-items: center;
  font:
    600 14px/18px "Quicksand",
    sans-serif;

  &__heart-icon {
    width: 20px;
    margin: 0 1px;
    display: flex;
    justify-content: center;
    align-items: center;

    :deep(svg) {
      fill: #db2828;
    }
  }
}
</style>
