import { ItineraryStep, Itinerary } from "@contracts/itinerary";
import logger from "@helpers/Logger";

export default {
  // Generates a map based on the steps selected
  generateMap(itinerary: Itinerary): string {
    if (!itinerary.steps) {
      return "";
    }
    const validSteps = getStopsWithLatLong(itinerary.steps);
    if (validSteps.length < 2) {
      return "";
    }

    // https://developers.google.com/maps/documentation/embed/get-started
    // Looks like this is free: https://developers.google.com/maps/documentation/embed/usage-and-billing
    const parameters = getFormattedDirectionsQuerystring(validSteps, itinerary.useWalkingDirections, itinerary.directionsTravelMode);
    var uri = `https://www.google.com/maps/embed/v1/directions?${parameters}`;
    logger.info(`Generated MapUrl: ${uri}`);
    return uri;
  },

  // Parses out the map uri if the user created their own and copied it in from the Embed UI from google maps
  parseMapUri(input: string): string {
    if (!input) return input;

    var startIndex = input.indexOf("https://www.google.com");
    if (startIndex < 1) return input;
    var temp = input.substr(startIndex);
    var endQuoteIndex = temp.indexOf('"');
    if (endQuoteIndex > 0) {
      var result = temp.substr(0, endQuoteIndex);
      return result;
    }
    return input;
  },

  //The code above embeds the key in the url when the adventure is created, which creates problems if we ever rotate keys.
  //This method will swap out the key if it is present in the url (manually created maps don't have a key)
  getMapUrlWithCurrentKey(mapUrlFromData: string | null): string | null {
    if (!mapUrlFromData) return null;

    const keyIndex = mapUrlFromData.indexOf("&key=");
    if (keyIndex < 0) return mapUrlFromData;

    //Note: Currently assumes key is the last item (see code above)  const keyEndIndex = mapUrlFromData.indexOf("&", keyIndex);
    const result = mapUrlFromData.substring(0, keyIndex) + "&key=" + globalThis.Bootstrap.Config.googlePlacesApiId;

    return result;
  },
};

function getStopsWithLatLong(steps: Array<ItineraryStep>) {
  return steps.filter(function (step) {
    var hasLatLong = step.latitude !== null && step.longitude !== null;
    return hasLatLong;
  });
}

function getFormattedDirectionsQuerystring(steps: Array<ItineraryStep>, useWalkingDirections: boolean, travelMode: string) {
  const latLongs = steps.map(
    step => {
      // Sending PlaceId if we have it and then falling back to Lat/Long
      if (step.externalId?.trim().length > 0) return `place_id:${step.externalId}`;
      else return `${step.latitude},${step.longitude}`;
    } // format is lat,lng
  );

  const origin = latLongs[0];
  const destination = latLongs.pop();
  latLongs.shift();
  let waypointQuery = "";
  if (latLongs.length > 0) waypointQuery = "&waypoints=" + latLongs.join("|");
  // Note: Should migrate the old walking specific field over to the more flexible one (see GoogleDirectionsService also)
  let mode = useWalkingDirections ? "walking" : "driving";
  if (travelMode && travelMode.length > 0) mode = travelMode.trim().toLowerCase();

  //NOTE: If this format ever changes review getMapUrlWithCurrentKey which swaps out the key!
  var parameters = `origin=${origin}${waypointQuery}&destination=${destination}&mode=${mode}` + `&key=${globalThis.Bootstrap.Config.googlePlacesApiId}`;
  return parameters;
}
