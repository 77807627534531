<template>
  <component
    :style="{
      aspectRatio: '4/3',
      cursor: 'pointer',
    }"
    :is="coverImageUrl ? 'div' : 'label'"
  >
    <div
      class="coverImg"
      :class="{ flex: props.tileImageFileName?.length === 0 }"
      :style="{
        backgroundImage: `url(${coverImageUrl})`,
        backgroundPosition: '50% 50%',
        boxShadow: isError ? '0 0 0 5px rgba(178, 34, 34, 0.27)' : 'none',
      }"
      @click="
        () => {
          emit('fixTheFieldError', 'summary', 'tileImageFileName');
          showPhotoUploader = true;
        }
      "
    >
      <div class="uploadBtn" style="display: block; text-align: center" v-if="coverImageUrl.length == 0">
        <label class="coverLabel">Cover Photo</label>
        <label @click="showPhotoUploader = true" for="image" style="width: auto; margin-bottom: 1em !important">
          <SrpButton color="gray" size="small" tag="button">Choose a Photo</SrpButton>
        </label>
      </div>
    </div>
    <template v-if="coverImageUrl.length > 0">
      <div class="flex" style="display: flex; justify-content: flex-end; align-self: flex-end">
        <a class="ui flex" style="cursor: pointer; margin: 0 !important" @click="showPhotoUploader = true">Change Cover Photo</a>
      </div>
    </template>

    <SrpModal v-model:isVisible="showPhotoUploader" className="custom-modal" :isWithScroll="true" maxHeight="calc(100vh - 70px)">
      <template #content>
        <UploadPhotoForm
          callerName="AdvSummary"
          :img="props.images"
          :activeServerId="props.tileImageFileName"
          :collabImagesUsed="props.collabImagesUsed"
          :collabImagesUnused="props.collabImagesUnused"
          :singlePickMode="true"
          :autoSelect="true"
          :showClose="true"
          :showRemove="false"
          :isOpen="showPhotoUploader"
          :isCollab="props.isCollab"
          :hideVideoGuidance="true"
          @close="showPhotoUploader = false"
          @imageUploadedToServer="onImageUploadedToServerForCoverPhoto"
          @removeMediaFile="emit('removeMediaFile', $event)"
        />
      </template>
    </SrpModal>
  </component>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";

// Components
import SrpButton from "@components/ui/SrpButton.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import UploadPhotoForm from "@views/CMS/UploadPhotoForm.vue";

// Types
import { UploadedImage } from "@contracts/uploadedImage";

// Global variables
const contentBaseUri = globalThis.Bootstrap.Config.contentCdnBaseUri;

const props = withDefaults(
  defineProps<{
    tileImageFileName: string | null;
    isError: boolean;
    images: Array<UploadedImage>;
    collabImagesUsed: Array<string>;
    collabImagesUnused: Array<string>;
    isCollab: boolean;
  }>(),
  {
    tileImageFileName: null,
    isError: false,
    images: () => [],
    collabImagesUsed: () => [],
    collabImagesUnused: () => [],
    isCollab: false,
  }
);

const emit = defineEmits<{
  (e: "croppedCoverPhotoSelected", image: UploadedImage, uncroppedImageServerId: string): void;
  (e: "imageUploadedFromCoverPhotoForm", image: UploadedImage): void;
  (e: "removeMediaFile", event: any): void;
  (e: "fixTheFieldError", ...args: Array<string>): void;
}>();

// Toggle the PhotoUploader modal =============================================
const showPhotoUploader = ref<boolean>(false);

// Generate the cover Image URL ===============================================
const coverImageUrl = computed<string>(() => {
  if (props.tileImageFileName) return `${contentBaseUri}/cms/images/expeditions/${props.tileImageFileName}`;
  else return "";
});

// Handle Image Upload to server ==============================================
function onImageUploadedToServerForCoverPhoto(image: UploadedImage, isSelected: boolean, uncroppedImageServerId: string | null) {
  if (uncroppedImageServerId) {
    // If uncropped image is provided, it means that the image was uploaded
    // from the cover photo cropper.
    emit("croppedCoverPhotoSelected", image, uncroppedImageServerId);
    showPhotoUploader.value = false;
  } else {
    // otherwise, it was uploaded into the photo gallery
    // and should be added to the itinerary
    emit("imageUploadedFromCoverPhotoForm", image);
  }
}
</script>

<style scoped lang="scss">
// ============================================================================
.custom-modal {
  overflow-y: auto;
  justify-content: flex-start;
  padding-top: 10vh;
}
.coverImg {
  width: 100%;
  height: 100%;
  margin: 10px 0;
  border: 1px dashed;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;

  .drag {
    box-shadow: inset 0 0 5px #058587;
  }
}

.uploadBtn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.coverLabel {
  font-size: 1.2em;
  font-weight: 500;
  margin-bottom: 1em !important;
}

@media screen and (max-width: 420px) {
  .coverImg {
    min-height: 170px;
  }
}
</style>
