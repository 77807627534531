import { ItineraryStep, Itinerary } from "@contracts/itinerary";
import Logger from "@helpers/Logger";

export default {
  // Sets the following fields on the itinerary given the DirectionsService results:
  // - DirectionsOverviewPolyline
  // - stop.DistanceToNextStopInMeters
  // - stop.TimeToNextStopInSeconds
  // - DistanceInMiles
  setDirectionsFields(itinerary: Itinerary, callback: (itineraryWasUpdated: boolean) => void) {
    if (!itinerary.steps) {
      if (callback) callback(false);
      return;
    }
    var validStops = getStopsWithLatLong(itinerary.steps);
    if (validStops.length < 2) {
      if (callback) callback(false);
      return;
    }

    generateDirections(itinerary, directions => {
      if (!directions || directions.routes.length === 0) {
        if (callback) callback(false);
        return;
      }

      const route = directions.routes[0];
      itinerary.directionsOverviewPolyline = route.overview_polyline;
      let totalDistanceInMeters = 0.0;
      // We expect 1 fewer legs than stops (since it's directions BETWEEN stops)
      for (let i = 0; i < validStops.length - 1; i++) {
        var stop = validStops[i];
        var leg = route.legs[i];
        stop.distanceToNextStopInMeters = leg.distance.value;
        totalDistanceInMeters += stop.distanceToNextStopInMeters;
        stop.timeToNextStopInSeconds = leg.duration.value;
      }
      itinerary.distanceInMiles = totalDistanceInMeters / 1609.0;

      Logger.info(`setDirectionsFields: Distance=${itinerary.distanceInMiles} miles`);

      // Let the caller know this has completed
      if (callback) callback(true);
    });
  },
};

// Calls the DirectionsService for a set of stops.
// Note: Currently assumes the itinerary has at least 2 stops with valid lat/long
function generateDirections(itinerary: Itinerary, callback: (directions: globalThis.google.maps.DirectionsResult | null) => void) {
  try {
    var validStops = getStopsWithLatLong(itinerary.steps);
    const latLongs = getDirectionsRequestLatLongs(validStops);

    var directionsService = new globalThis.google.maps.DirectionsService();
    // Note: Should migrate the old walking specific field over to the more flexible one (see GoogleMapGenerator also)
    var travelMode = itinerary.useWalkingDirections ? globalThis.google.maps.TravelMode.WALKING : globalThis.google.maps.TravelMode.DRIVING;
    if (itinerary.directionsTravelMode && itinerary.directionsTravelMode.length > 0) travelMode = getTravelMode(itinerary.directionsTravelMode);
    directionsService.route(
      {
        origin: latLongs.origin,
        destination: latLongs.destination,
        waypoints: latLongs.waypoints,
        travelMode: travelMode,
      },
      function (response, status) {
        if (status == globalThis.google.maps.DirectionsStatus.OK) {
          Logger.info(`Directions Retrieved`);
          callback(response);
        } else {
          Logger.warn("Directions request failed due to " + status);
          callback(null);
        }
      }
    );
  } catch (e) {
    Logger.warn("Directions request failed due to " + e.message);
    callback(null);
  }
}

function getTravelMode(input: string): globalThis.google.maps.TravelMode {
  var defaultMode = globalThis.google.maps.TravelMode.DRIVING;
  if (!input || input.length === 0) return defaultMode;
  switch (input.trim().toLowerCase()) {
    case "driving":
      return globalThis.google.maps.TravelMode.DRIVING;
    case "walking":
      return globalThis.google.maps.TravelMode.WALKING;
    case "bicycling":
      return globalThis.google.maps.TravelMode.BICYCLING;
    case "transit":
      return globalThis.google.maps.TravelMode.TRANSIT;
    default:
      return defaultMode;
  }
}

function getStopsWithLatLong(steps: Array<ItineraryStep>) {
  return steps.filter(function (step) {
    var hasLatLong = step.latitude !== null && step.longitude !== null;
    return hasLatLong;
  });
}

// Note: Assumes only steps with a valid lat/long are passed (see method above)
function getDirectionsRequestLatLongs(steps: Array<ItineraryStep>) {
  const latLongs = steps.map(function (step) {
    return { lat: step.latitude, lng: step.longitude };
  });

  const result = {
    origin: latLongs[0],
    destination: latLongs.pop(),
  } as any;
  latLongs.shift();
  // waypoints require a specific format https://developers.google.com/maps/documentation/javascript/directions#Waypoints
  result.waypoints = latLongs.map(point => {
    return { location: point };
  });
  return result;
}
