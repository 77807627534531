<template>
  <ul
    :class="{
      'drag-n-drop-handle': true,
      'drag-n-drop-handle--small': props.size === 'small',
    }"
    draggable="false"
  >
    <li
      :class="{
        'drag-n-drop-handle__bullet': true,
        'drag-n-drop-handle__bullet--small': props.size === 'small',
      }"
      v-for="(_, index) in 9"
      :key="index"
    >
      &nbsp;
    </li>
  </ul>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    size?: "small" | "normal" | "big";
  }>(),
  {
    size: "normal",
  }
);
</script>

<style scoped lang="scss">
// Drag & Drop handle =========================================================
.drag-n-drop-handle {
  width: 34px;
  height: 34px;
  padding: 5px 5px 5px 6px !important;
  margin: 0;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  list-style: none;
  font-size: 0;
  line-height: 0;
  background: rgba(255, 255, 255, 1);
  cursor: grab;

  &--invisible {
    opacity: 0;
    pointer-events: none;
  }

  &--small {
    width: 24px;
    height: 24px;
    padding: 4px !important;
  }

  &__bullet {
    width: 4px;
    height: 4px;
    margin: 2px 2px 1px 1px;
    border-radius: 100px;
    font-size: 0;
    line-height: 0;
    background: rgba(172, 172, 172, 1);
    pointer-events: none;

    &--small {
      width: 3px;
      height: 3px;
      margin: 1px 1px 1px 1px;
    }
  }
}
</style>
