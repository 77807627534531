<template>
  <span v-if="canShow" class="ui form">
    <!--Button to fire the modal-->
    <button @click.prevent="show()" class="ui large basic primary button"><i class="list icon"></i>Lists</button>

    <!--Lists Modal-->
    <SrpModal :isVisible="shouldShowModal" @close="close" :zIndex="400">
      <template #content>
        <!--<div class="shrpa-logo">-->
        <!--  <img src="https://cdn.shrpa.com/images/shrpa-full-color.png" width="150px" style="padding-bottom: 1rem" height="auto" alt="Shrpa logo" />-->
        <!--</div>-->
        <div class="published-content">
          <p v-if="entityName && entityName.length > 0" class="wrapper2">{{ entityName }}</p>
          <p class="wrapper">Add to a List</p>
          <!--<button @click="addToList()" class="ui primary small button" style="float:right">Add</button>-->
          <div style="overflow: hidden; margin-bottom: 5px">
            <select v-model="listIdToAddTo" @change="addToList($event)">
              <option disabled value="">{{ allLists.length > 0 ? "Please select one" : "Added to all lists" }}</option>
              <option v-for="item in allLists" :key="item.id" :value="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>
          <div>
            <b>Already included in:</b><br />
            <div v-for="item in memberOfLists" :key="item.id">{{ item.name }} <i class="grey close icon" style="margin-left: 10px" @click="removeFromList(item.id)"></i></div>
          </div>

          <p class="wrapper">Create a new List</p>
          <button @click="createList()" style="float: right" class="ui primary small button">Save</button>
          <div style="overflow: hidden">
            <input type="text" maxlength="50" placeholder="List name" v-model="newListName" />
          </div>
        </div>
      </template>
    </SrpModal>
  </span>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { PropType } from "vue";
import OrgContext from "@logic/OrgContext";
import ListRepo from "@logic/ListRepo";
import { AdventureList } from "@contracts/lists";
import { UserProfile } from "@contracts/userProfile";
import AdminContext from "@logic/AdminContext";
import SrpModal from "@components/ui/SrpModal.vue";
import { mapState } from "pinia";
import { useUserProfileStore } from "@stores/userProfileStore";

export default defineComponent({
  name: "ListsButton",
  components: { SrpModal },

  props: {
    entityName: { default: "" },
    entityId: { type: String as PropType<string | null>, required: false, default: null },
  },

  emits: ["close"],

  data() {
    return {
      globalLog: inject("globalLog") as any,

      localShowShareModal: false,

      // Note: Using the @change event for the add but this to reset the selection
      listIdToAddTo: "" as string,
      newListName: "",

      userProfileStore: useUserProfileStore(),

      customerIdInContext: null as string | null,
      memberOfLists: [] as Array<AdventureList>,
      allLists: [] as Array<AdventureList>,
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["getViewingUserProfile", "getActingUserProfile"]),
    canShow(): boolean {
      if (this.customerIdInContext) return true;
      return false;
    },
    shouldShowModal(): boolean {
      return this.localShowShareModal;
    },
    // Currently we pivot some fields based on this
    hasHotelInContextComputed(): boolean {
      // Note: This won't be properly reactive since we're calling a method, which should be fine here...
      return OrgContext.isOrgInContextHotel(this);
    },
  },

  watch: {
    // Little hacky but works
    "userProfileStore.loggedInUser": function (profile) {
      if (profile) {
        this.profileLoaded();
      }
    },
    "globalRoot.orgInContext": function () {
      this.profileLoaded();
    },
  },

  async mounted() {
    if (this.getActingUserProfile) {
      this.profileLoaded();
    }
  },

  methods: {
    async profileLoaded() {
      this.globalLog.info("Listbutton: profileLoaded()");
      await this.loadLists();
    },
    async loadLists() {
      // Load the customerId in context
      var orgInContext = OrgContext.getOrgInContext(this);
      if (orgInContext) {
        this.customerIdInContext = orgInContext.customerId;
        // Check to see if this user is an admin for that org
        const isAdmin = AdminContext.isAdminOf(this.customerIdInContext, this.getActingUserProfile, true);
        if (isAdmin) {
          this.refreshLists();
        }
      }
    },
    async refreshLists() {
      this.memberOfLists = await ListRepo.getListsAdventureIsMemberOf(this.customerIdInContext, this.entityId);
      this.allLists = await ListRepo.getAllLists(this.customerIdInContext, this.entityId);
    },
    async addToList(event) {
      // if(this.listIdToAddTo && this.listIdToAddTo.length > 0) {
      this.globalLog.info(`Adding ${this.entityId} to ${event.target.value} for ${this.customerIdInContext}`);
      // save and refresh the lists
      await ListRepo.addToList(this.customerIdInContext, event.target.value, this.entityId);
      this.refreshLists();
      this.listIdToAddTo = "";
    },
    async removeFromList(listId: string) {
      await ListRepo.removeFromList(this.customerIdInContext, listId, this.entityId);
      this.refreshLists();
    },
    async createList() {
      if (this.newListName.length > 0) {
        // save and refresh the lists
        await ListRepo.createHomepageList(this.customerIdInContext, this.newListName, this.entityId);
        this.refreshLists();
        this.newListName = "";
      }
    },
    clearFields() {
      this.newListName = "";
      this.listIdToAddTo = "";
    },
    show() {
      this.clearFields();
      this.localShowShareModal = true;
    },
    close() {
      this.localShowShareModal = false;
      this.$emit("close");
    },
  },
});
</script>

<style scoped>
/* Modal Styles */
#published-wrapper {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 101;
  background-color: rgba(0, 0, 0, 0.5);
}

#published {
  background-color: #eeeeee;
  display: block;
  justify-content: space-around;
  text-align: center;
  font-family: "Quicksand", "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  max-width: 420px !important;
  min-height: 700px;
  height: 450px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 420px) {
  #published-wrapper {
    background-color: #eeeeee;
  }

  #published {
    max-width: 100% !important;
    width: 100% !important;
    height: 100%;
    min-height: auto;
    margin-top: 0% !important;
    margin-left: 0% !important;
    margin-right: 0% !important;
    padding-top: 1.25rem !important;
  }
}

.published-header {
  position: relative;
  height: 50px;
}

.header-over {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
}

.published-content {
  margin: 8px 25px 25px 25px;
  text-align: left;
}

.close-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.wrapper {
  font-size: 22px;
  line-height: 1.5;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 20px;
}

.wrapper2 {
  font-size: 22px;
  line-height: 1.5;
  font-weight: 500;
  margin-bottom: 16px;
}
</style>
