import type { NavigationGuard } from "vue-router";
import { getInstance } from "./authWrapper";
import router from "../router";

export const authGuard: NavigationGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = () => {
    if (authService.isAuthenticated) {
      return next();
    }

    // Send them to login page to help them get logged in, and store where they wanted to go.
    // Note: fullPath is the PATH (ex. /admin/blah), not the full url
    sessionStorage.setItem("redirectPath", to.fullPath);
    router.replace({ name: "AuthLanding" });

    // OLD code that takes the user straight to Auth0
    // authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  if (!authService.loading) {
    return fn();
  }

  authService.$watch("loading", loading => {
    if (loading === false) {
      return fn();
    }
  });
};
