<template>
  <div
    :class="{
      'place-details-form': true,
      'place-details-form--disabled': props.isDisabled,
    }"
  >
    <!-- Form element snippet -->
    <div class="form-element-snippet place-details-form__form-element-snippet">
      <div class="form-element-snippet__header">
        <h5 class="global-h5 form-element-snippet__title">Tell us about somewhere you've been</h5>
        <div class="form-element-snippet__subtitle">Ex. A town, coffee shop, restaurant, or a hike</div>
      </div>

      <!--<input class="global-text-input global-text-input&#45;&#45;location-icon form-element-snippet__form-element-itself" type="text" style="max-width: 400px" />-->

      <div class="form-element-snippet__form-element-itself-wrap" style="max-width: 400px">
        <GMapAutocomplete
          :class="{
            'global-text-input': true,
            'global-text-input--error': formErrors.location,
            'global-text-input--location-icon': true,
            'form-element-snippet__form-element-itself': true,
          }"
          style="max-width: 400px"
          :address="props.step.title"
          @place_changed="
            place => {
              googlePlaceChosen(place);
              emit('update:step');
            }
          "
          @input="e => placeTextChanged(e.target.value)"
          :value="props.step.title"
          :options="{
            fields: ['name', 'place_id', 'address_components', 'formatted_address', 'geometry', 'website'],
          }"
        />
      </div>
      <div v-if="props.step.externalId" style="margin-left: 7px">{{ props.step.city }}, {{ props.step.state }}</div>

      <div v-if="formErrors.location" class="global-error-string form-element-snippet__form-element-error">
        {{ formErrors.location }}
      </div>
    </div>
    <!-- / Form element snippet -->

    <div class="place-details-form__divider"></div>

    <!-- Form element snippet -->
    <div class="form-element-snippet place-details-form__form-element-snippet">
      <!-- Upload Photo Form container -->
      <div class="upload-photo-form-container form-element-snippet__upload-photo-form-container">
        <div v-if="props.step.images?.length" style="margin-bottom: 7px; align-self: flex-end">
          <LinkWithIcon isDottedUnderline @click="isAdjustingPhotos = !isAdjustingPhotos" iconInset="auto -2px auto auto">
            <template v-if="isAdjustingPhotos" #icon><IconEmbedded name="check_3" :size="16" color="rgba(17, 134, 137, 1)" /></template>
            <template v-else #icon><IconEmbedded name="pencil_2" :size="16" color="rgba(17, 134, 137, 1)" /></template>
            <span>{{ isAdjustingPhotos ? "Finished adjusting" : "Adjust photos" }}</span>
          </LinkWithIcon>
        </div>

        <div class="upload-photo-form-container__container-itself">
          <div v-if="!props.step.images.length && !isImagesUploadingInProgress" class="upload-photo-form-container__overlay">
            <IconEmbedded name="upload_2" :size="26" color="rgba(0, 0, 0, 0.4)" style="margin-bottom: 5px" />
            <b>Add or drag in 3+ photos or videos</b>
            <span style="opacity: 0.65">JPG photos or ~5-60 sec videos</span>
          </div>

          <UploadPhotoForm
            class="upload-photo-form-container__form-itself"
            :style="{ height: props.step.images.length ? 'auto' : '150px' }"
            :img="props.step.images.map(i => ({ serverId: i }))"
            :isCollab="false"
            :showRemove="isAdjustingPhotos"
            :isWithDragAndDrop="isAdjustingPhotos"
            :autoSelect="false"
            @removeMediaFile="
              fileName => {
                props.step.images = props.step.images.filter(i => i !== fileName);
                emit('update:step');
              }
            "
            @imageUploadedToServer="
              newImage => {
                props.step.images = [...props.step.images, newImage.serverId];
                emit('update:step');
              }
            "
            @reorderImages="
              (oldIndex, newIndex) => {
                props.step.images = moveItemToNewIndex(props.step.images, oldIndex, newIndex);
                emit('update:step');
              }
            "
            @uploadProgressChange="({ isUploadInProgress }) => (isImagesUploadingInProgress = isUploadInProgress)"
            hideVideoGuidance
            :isError="Boolean(formErrors.photos)"
          />
        </div>

        <div v-if="props.step.images?.length > 15" style="margin-bottom: 7px; align-self: flex-end">
          <LinkWithIcon isDottedUnderline @click="isAdjustingPhotos = !isAdjustingPhotos" iconInset="auto -2px auto auto">
            <template v-if="isAdjustingPhotos" #icon><IconEmbedded name="check_3" :size="16" color="rgba(17, 134, 137, 1)" /></template>
            <template v-else #icon><IconEmbedded name="pencil_2" :size="16" color="rgba(17, 134, 137, 1)" /></template>
            <span>{{ isAdjustingPhotos ? "Finished adjusting" : "Adjust photos" }}</span>
          </LinkWithIcon>
        </div>
      </div>
      <!-- / Upload Photo Form container -->

      <div v-if="formErrors.photos" class="global-error-string form-element-snippet__form-element-error">
        {{ formErrors.photos }}
      </div>
    </div>
    <!-- / Form element snippet -->

    <div class="place-details-form__divider"></div>

    <!-- Form element snippet -->
    <div class="form-element-snippet place-details-form__form-element-snippet">
      <div class="form-element-snippet__header">
        <h5 class="global-h5 form-element-snippet__title">What did you see and experience?</h5>
        <div class="form-element-snippet__subtitle">Make it fun and personal!</div>
      </div>

      <textarea
        :class="{
          'global-textarea': true,
          'global-textarea--error': formErrors.isDescriptionInvalid,
          'form-element-snippet__form-element-itself': true,
        }"
        v-model="props.step.details"
        style="height: 95px; min-height: 95px"
        @input="emit('update:step')"
      ></textarea>

      <div
        :class="{
          'global-error-string': formErrors.isDescriptionInvalid,
          'form-element-snippet__form-element-error': formErrors.isDescriptionInvalid,
        }"
        style="text-align: right"
      >
        {{ getDetailsLengthMessage(props.step.details) }}
      </div>
    </div>
    <!-- / Form element snippet -->
  </div>
</template>

<script setup lang="ts">
import GooglePlaceConverter from "@logic/GooglePlaceConverter";
import { inject, ref, onMounted, watch, computed } from "vue";
import { moveItemToNewIndex } from "@composables/useMouseDragNDrop";
import { getDetailsLengthMessage, isDescriptionInvalid } from "./OnboardingValidation";

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import UploadPhotoForm from "@views/CMS/UploadPhotoForm.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";

// Types
import { ItineraryStep } from "@contracts/itinerary";

// Global variables & injections
const globalLog = inject("globalLog") as any;
const globalRemoteLogger = inject("globalRemoteLogger") as any;

const props = withDefaults(
  defineProps<{
    step: ItineraryStep | null;
    isDisabled?: boolean;
  }>(),
  {
    step: null,
    isDisabled: false,
  }
);

const emit = defineEmits<{
  (e: "update:step"): void;
  (e: "updateImagesList"): void;
}>();

// Is Images upload in progress ===============================================
const isImagesUploadingInProgress = ref<boolean>(false);

// Is adjusting photos =======================================================
const isAdjustingPhotos = ref<boolean>(false);

// Handle the Google Place choosing event =====================================
// NOTE! This doesn't catch text changes, just when they pick a result from the auto-complete
function googlePlaceChosen(place: globalThis.google.maps.places.PlaceResult) {
  globalLog.info(`googlePlaceChosen: ${place?.name} Id=${place?.place_id}`);
  if (!place?.place_id) {
    globalLog.info(`googlePlaceChosen: No place_id found for ${place?.name}`);
    return;
  }
  // Set the fields
  try {
    GooglePlaceConverter.setItineraryFieldsFromPlace(place, props.step);

    // Causes a save
    emit("update:step");
  } catch (ex) {
    globalRemoteLogger.error(`Place Autocomplete failure: Input=${props.step?.title} Name=${place?.name} PlaceId=${place?.place_id} ${ex.message}`);
  }
}

// Text changes to the stop title =============================================
function placeTextChanged(newTitle) {
  globalLog.info("PlaceTextChanged: " + newTitle);
  if (!newTitle) {
    globalLog.info(`PlacesForm ClearingPlaceFields: previously=${props.step?.title}, externalId=${props.step?.externalId}`);
    GooglePlaceConverter.clearPlaceFields(props.step);
  }
  props.step.title = newTitle;
}

// Validation =================================================================
const formErrors = ref<{ location: string; photos: string; isDescriptionInvalid: boolean }>({
  location: "",
  photos: "",
  isDescriptionInvalid: false,
});

const MinPhotosCount = 3;
function validate(): boolean {
  formErrors.value.location = props.step.externalId ? "" : "Pick a location from the autocomplete";
  formErrors.value.photos = props.step.images?.length >= MinPhotosCount ? "" : `Please add ${MinPhotosCount}+ photos or videos`;
  formErrors.value.isDescriptionInvalid = isDescriptionInvalid(props.step.details);

  return Object.values(formErrors.value).filter(v => Boolean(v)).length === 0;
}

watch(
  () => props.step.address,
  () => (formErrors.value.location = "")
);
watch(
  () => props.step.images.length,
  () => (formErrors.value.photos = "")
);
watch(
  () => props.step.details,
  () => (formErrors.value.isDescriptionInvalid = false)
);

// Define expose ==============================================================
defineExpose({
  validate,
});
</script>

<style scoped lang="scss">
@import "./PlaceAndSummaryForms.scss";
</style>
