<template>
  <li class="collab-completed-section">
    <EventTypeTag class="collab-completed-section__type-tag" color="yellow">Collab Completed</EventTypeTag>

    <RouterLink
      class="global-h5 collab-completed-section__title"
      :to="{ name: 'CollabSummaryPublic', params: { communityId: feedItem.destinationId, collabInputId: feedItem.collabId } }"
      target="_blank"
    >
      {{ feedItem.collabName }}
    </RouterLink>

    <div class="collab-completed-section__content">
      <!-- Creator plus community -->
      <div
        :class="{
          'creator-plus-community': true,
          'collab-completed-section__creator-plus-community': true,
          'collab-completed-section__creator-plus-community--no-bottom-margin': !feedItem.highlightAssetIds?.length,
        }"
      >
        <!-- Avatar n name snippet -->
        <div class="avatar-n-name-snippet creator-plus-community__creator-snippet">
          <div class="avatar-n-name-snippet__avatar-wrap">
            <img class="avatar-n-name-snippet__avatar" :src="`${contentBaseUri}/cms/images/profiles/${feedItem.creatorProfilePhoto}`" :alt="feedItem.creatorName" />
          </div>
          <div class="avatar-n-name-snippet__name">
            {{ feedItem.creatorName }}
          </div>
        </div>

        <!-- / Avatar n name snippet -->
        <div class="creator-plus-community__plus-sign">
          <IconEmbedded name="plus_3-5" :size="12" color="rgba(255, 255, 255, 1)" />
        </div>
        <!-- Avatar n name snippet -->

        <div class="avatar-n-name-snippet creator-plus-community__community-snippet">
          <div class="avatar-n-name-snippet__avatar-wrap">
            <img
              class="avatar-n-name-snippet__avatar avatar-n-name-snippet__avatar--object-fit-contain"
              :src="`${contentBaseUri}/cms/images/orgs/${feedItem.destinationLogo}`"
              :alt="feedItem.destinationName"
            />
          </div>
          <div class="avatar-n-name-snippet__name">
            {{ feedItem.destinationName }}
          </div>
        </div>
        <!-- / Avatar n name snippet -->
      </div>
      <!-- / Creator plus community -->

      <!-- Media files rows -->
      <div v-if="feedItem.highlightAssetIds?.length" class="media-files-row collab-completed-section__media-files-row">
        <div class="media-files-row__in-wrap">
          <div class="media-files-row__thumbnail-wrap" v-for="fileName in feedItem.highlightAssetIds" :key="fileName">
            <SrpFileThumbnail
              class="media-files-row__thumbnail"
              :gallery-postfix="`gallery-activity-feed-${feedItem.eventDate + feedItem.type + feedItem.destinationId + feedItem.creatorId}`"
              :fileName="fileName"
              :thumbSize="'thumb-tiny'"
              :isFixedAspectRatio="false"
              :isEntireAreaClickable="true"
            />
          </div>
        </div>
      </div>
      <!-- / Media files rows -->
    </div>
  </li>
</template>

<script setup lang="ts">
// Components
import SrpFileThumbnail from "@components/ui/SrpFileThumbnail.vue";

// Types
import { CollabCompletedFeedItem } from "@components/ActivityFeed/types";
import EventTypeTag from "@components/ActivityFeed/EventTypeTag.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

// Global variables
const contentBaseUri = globalThis.Bootstrap.Config.contentCdnBaseUri;

const props = withDefaults(
  defineProps<{
    feedItem: CollabCompletedFeedItem;
  }>(),
  {
    feedItem: null,
  }
);
</script>

<style scoped lang="scss">
@import "./commonStyle.scss";

// Avatar n name snippet ======================================================
.avatar-n-name-snippet {
  display: flex;
  align-items: center;

  &__avatar-wrap {
    width: 47px;
    height: 47px;
    margin-right: 8px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  &__avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &--object-fit-contain {
      object-fit: contain;
    }
  }

  &__name {
    max-width: 110px;
    font: 14px/17px sans-serif;
  }
}

// Creator plus community =====================================================
.creator-plus-community {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__creator-snippet {
    margin-right: 5px;
  }

  &__plus-sign {
    width: 20px;
    height: 20px;
    margin: -10px 0 -10px 14px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    background: rgba(17, 134, 137, 1);
  }

  &__community-snippet {
  }
}

// Collab completed section ===================================================
.collab-completed-section {
  @include eventContainer;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  z-index: 0;

  &__title {
    max-width: calc(100% - 115px);
    margin-bottom: 17px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      text-decoration-color: rgba(0, 0, 0, 0.2);
      text-underline-offset: 2px;
      text-decoration-thickness: 1px;
      text-decoration-style: solid;
    }
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__creator-plus-community {
    min-width: 200px;
    margin-right: 15px;
    position: relative;
    left: -5px;

    &--no-bottom-margin {
      margin-bottom: 0;
    }
  }

  &__media-files-rows {
    width: calc(100% - 180px + 7px);
    min-width: calc(100% - 180px + 7px);
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .collab-completed-section {
    &__title {
      max-width: 100%;
    }
    &__creator-plus-community {
      min-width: 150px;
      margin-right: 5px;
    }
  }
}
</style>
