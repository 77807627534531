<template>
  <SrpModal :isVisible="isVisible" @update:isVisible="isVisible => emit('update:isVisible', isVisible)">
    <template #header>
      <h2 class="global-h2">Before you message {{ communityName }}:</h2>
    </template>
    <template #content>
      <b>Contact Shrpa first</b> if your question is regarding:
      <ul style="margin-bottom: 10px">
        <li>payment</li>
        <li>wanting an activity to be comped</li>
        <li>collaboration expectations</li>
      </ul>

      <label style="display: flex; align-items: center; cursor: pointer; user-select: none">
        <input
          type="checkbox"
          :checked="skipIntermediateMessagingModal"
          @change="e => emit('update:skipIntermediateMessagingModal', (e.target as HTMLInputElement).checked)"
          style="width: 13px; margin-right: 8px"
        />
        Don't show this again for this collab
      </label>
    </template>
    <template #footer v-if="showFooter">
      <SrpButton
        @click="
          emit('update:skipIntermediateMessagingModal', false);
          emit('click:contactShrpa');
        "
        fill="outlined"
        style="margin-right: 10px"
      >
        <template #icon><IconEmbedded name="envelope_2-5" :size="20" /></template>
        Contact Shrpa
      </SrpButton>
      <SrpButton @click="emit('click:messageDestination')" fill="outlined" style="margin-right: 10px">
        <template #icon><IconEmbedded name="chat_2-5" :size="20" /></template>
        Message Destination
      </SrpButton>
      <SrpButton
        @click="
          emit('update:skipIntermediateMessagingModal', false);
          emit('click:cancel');
        "
        color="gray"
        fill="outlined"
      >
        Cancel
      </SrpButton>
    </template>
  </SrpModal>
</template>

<script setup lang="ts">
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpModal from "@components/ui/SrpModal.vue";

defineProps<{ isVisible: boolean; showFooter: boolean; skipIntermediateMessagingModal: boolean; communityName: string }>();
const emit = defineEmits<{
  (e: "update:isVisible", value: boolean): void;
  (e: "update:skipIntermediateMessagingModal", value: boolean): void;
  (e: "click:contactShrpa"): void;
  (e: "click:messageDestination"): void;
  (e: "click:cancel"): void;
}>();
</script>
