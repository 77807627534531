<template>
  <SrpButton @click="copyTextToClipboard" :color="props.color">
    <template #icon v-if="isShowCopied"><IconEmbedded name="check_3" :size="19" /></template>
    <template #icon v-else><IconEmbedded name="copy_2-5" :size="19" /></template>
    {{ isShowCopied ? "Copied" : "Copy" }}
  </SrpButton>
</template>

<script setup lang="ts">
import { ref } from "vue";

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import SrpButton from "@components/ui/SrpButton.vue";

// Types
import { SrpButtonColor } from "@components/ui/SrpButton.vue";

const props = withDefaults(
  defineProps<{
    textToCopy: string;
    color?: SrpButtonColor;
  }>(),
  {
    textToCopy: "",
    color: "teal",
  }
);

// Copy text to the clipboard =================================================
const isShowCopied = ref<boolean>(false);

async function copyTextToClipboard(): Promise<void> {
  navigator.clipboard.writeText(props.textToCopy).then(
    () => {
      console.info("Copied to clipboard: " + props.textToCopy);
    },
    function (err) {
      console.error("Failed to copy to clipboard: ", err);
    }
  );

  isShowCopied.value = true;

  setTimeout(() => {
    isShowCopied.value = false;
  }, 3000);
}
</script>

<style scoped lang="scss"></style>
