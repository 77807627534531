<template>
  <div>
    <div class="content-library-page">
      <!-- Header -->
      <div class="header content-library-page__header">
        <h1 class="global-h1 header__title">{{ communityTitle }} Content Library</h1>
      </div>
      <!-- / Header -->

      <div class="content-library-page__content">
        <AllPhotosSection isThumbnailsWithDownloadButton @dataLoaded="data => (communityTitle = data.communityTitle)" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { MetricSender } from "@helpers/MetricSender";
import { ref, onMounted } from "vue";

// Components
import AllPhotosSection from "@components/AllPhotosSection/index.vue";

// Get the Community title ====================================================
const communityTitle = ref<string>("");

// Send Metrics ===============================================================
onMounted(() => {
  MetricSender.sendMetric("Cust-ViewContentLibrary", null);
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";
@import "@/scss/variables.scss";

// Header =====================================================================
.header {
  &__title {
  }
}

// Content library page =======================================================
.content-library-page {
  width: $desktop-wide-right-col-width;
  margin: 0 auto;

  &__header {
    margin-bottom: 45px;
  }

  &__content {
  }

  &__bottom-floating-panel {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .content-library-page {
    width: $desktop-right-col-width;
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .content-library-page {
    width: $laptop-right-col-width;
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .content-library-page {
    width: $tablet-large-content-width;
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .content-library-page {
    width: $tablet-content-width;
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .content-library-page {
    width: $mobile-content-width;
  }
}

// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================

// User comment text ==========================================================
.user-comments-text {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  color: #5b5b5b;
  font: 14px/18px sans-serif;
  white-space: pre-wrap;

  &--clamped {
    line-clamp: 5;
    -webkit-line-clamp: 5;
    text-overflow: ellipsis;
  }
}

// Filters section ============================================================
.filters-section {
  padding: 14px;
  margin-bottom: 2rem;
  box-sizing: border-box;
  border: 1px #ddd solid;
  border-radius: 6px;
  display: flex;

  &__details {
    width: 100%;
  }

  &__summary {
    width: 100%;
    margin-bottom: 15px;
    outline: none;
    position: relative;
    z-index: 6;
    font-weight: 700;
    cursor: pointer;
    user-select: none;

    &::before {
      content: "";
      width: calc(100% + 20px);
      height: calc(100% + 20px);
      position: absolute;
      inset: 50% auto auto 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__column {
    width: calc(50% - 20px);

    &--row {
      display: flex;
    }
  }

  &__section {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .filters-section {
    &__content {
      flex-direction: column;
    }

    &__column {
      width: 100%;
      margin-bottom: 35px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// ============================================================================
.photos-container {
  // display: flex;
}
.filter-sort-group {
  margin-bottom: 1rem;
  .label-wrapper {
    display: flex;
    label {
      font-weight: 600;
      width: auto !important;
    }
    span {
      margin-left: 0.4rem;
    }
  }
  input {
    margin-top: 0 !important;
  }
}
.download-btn {
  font-size: 0.7rem !important;
  position: absolute;
  cursor: pointer;
  z-index: 30;
  margin-top: 0.25rem;
  margin-left: 0.25rem;
}
.other-options {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.main-label {
  font-weight: 600;
  color: gray;
  // padding: 0.3rem 0.6rem;
  // border-radius: 10px;
  // background-color: $faint-gray;
}
.photos-section {
  margin-bottom: 1rem;
  .section-header {
    margin-bottom: 0.5rem;
    .ui.label {
      color: $gray;
    }
    .divider {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      color: $gray;
    }
    .secondary-label {
      margin-right: 0.5rem;
    }
    .date-label {
      margin-left: 8px;
      font-size: 0.9rem;
    }
  }
}

.ui.button.primary {
  color: #fff !important;
  background: #118689 !important;
}
</style>
