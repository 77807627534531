export default {
  // Removes country and zipcode from the end of an address string.
  // NOTE: ONLY works for the US currently!
  removeCountryAndZipCode(input: string): string {
    // Remove USA from the end if present
    if (input?.endsWith(", USA")) {
      input = input.substring(0, input.length - ", USA".length);

      // If there's just a numeric zipcode at the end, remove it (just doing this for the US for now)
      input = input.replace(/\d+$/, "").trim();
    }
    return input;
  },
};
