<template>
  {{ maskedPhoneNumber }}
</template>

<script setup lang="ts">
import { Mask } from "maska";
import { computed } from "vue";

const props = defineProps<{
  phoneNumber: string | null;
}>();

// create mask with format for (US only) phone numbers
const mask = new Mask({ mask: "+1 (###) ###-####" });

// Note: Maska fails on null values, so fallback to empty here
const maskedPhoneNumber = computed(() => mask.masked(props.phoneNumber ?? ""));
</script>
