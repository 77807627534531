<template>
  <div
    :class="{
      'action-snippet': true,
      'action-snippet--green-bg': title.toLowerCase().includes('current'),
      'action-snippet--white-with-border': title.toLowerCase().includes('completed'),
    }"
  >
    <div class="action-snippet__header">
      <div class="action-snippet-title action-snippet__title">
        <div class="global-h3 action-snippet-title__text">
          {{ title }}
        </div>
        <IconEmbedded v-if="title.toLowerCase().includes('current')" class="action-snippet-title__icon action-snippet-title__icon--green" name="loader_3" :size="24" />
        <IconEmbedded v-else-if="title.toLowerCase().includes('completed')" class="action-snippet-title__icon action-snippet-title__icon--green" name="check-circle_2-5" :size="24" />
        <IconEmbedded v-else-if="title.toLowerCase().includes('other')" class="action-snippet-title__icon" name="zap_2" :size="24" />
      </div>
      <div v-if="subTitle" class="action-snippet__subscript">{{ subTitle }}</div>
    </div>

    <ul class="action-snippet__actions-list">
      <!-- Action line -->
      <li class="action-line action-snippet__action-line" v-for="action in actionsList" :key="action.title" @click="action.callback">
        <div class="action-line__title">{{ action.title }}</div>
        <div
          :class="{
            'action-line__cta-link': true,
            'action-line__cta-link--green': title.toLowerCase().includes('current'),
            'action-line__cta-link--gray': title.toLowerCase().includes('completed'),
          }"
        >
          {{ action.status }}
        </div>
      </li>
      <!-- / Action line -->
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export default defineComponent({
  name: "ActionSnippet",
  components: { IconEmbedded },

  props: {
    title: { type: String, required: true },
    subTitle: { type: String, default: "" },
    actionsList: { type: Array, required: true },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Action line ================================================================
.action-line {
  padding-top: 11px;
  border-top: 1px rgba(black, 0.1) solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
  user-select: none;

  &::before {
    content: "";
    width: 100%;
    height: calc(100% + 13px);
    position: absolute;
    inset: 0 auto auto 0;
  }

  &__title {
    margin-right: 11px;
    color: #000;
    font:
      400 22px/28px "Quicksand",
      sans-serif;
    opacity: 1;
    transition: opacity 0.07s ease-in-out;
  }

  &:hover &__title {
    opacity: 0.6;
  }

  &__cta-link {
    position: relative;
    top: 1px;
    color: #118689;
    text-decoration: underline;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;

    &:hover {
      text-decoration: none;
    }

    &::before {
      content: "";
      width: calc(100% + 18px);
      height: calc(100% + 12px);
      position: absolute;
      inset: 50% auto auto 50%;
      transform: translate(-50%, -50%);
    }

    &--green {
      color: #549e3f;
    }

    &--gray {
      color: #5b5b5b;
    }
  }
}

// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop =============================
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large =======================
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet =============================
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile =============================
@media (max-width: $mobile-max-width) {
  .action-line {
    padding-top: 7px;

    &__title {
      font-size: 20px;
      line-height: 25px;
    }
  }
}

// Action snippet =============================================================
.action-snippet {
  padding: 19px 25px 19px;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 6px;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -1;
    background: #f3f3f3;
  }

  &--green-bg::before {
    background: #d9eed3;
  }

  &--white-with-border::before {
    border: 1px #ccd3d7 solid;
    background: white;
  }

  &__header {
    margin-bottom: 15px;
  }

  &__title {
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .action-snippet-title {
    display: flex;
    align-items: center;

    &__text {
      margin-right: 5px;
    }

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;

      :deep(svg) {
        fill: #536065;
      }

      &--green {
        :deep(svg) {
          fill: #3d8528;
        }
      }

      &--narrow {
        width: 20px;
      }
    }
  }

  &__subscript {
  }

  &__actions-list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__action-line {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop =============================
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large =======================
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet =============================
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile =============================
@media (max-width: $mobile-max-width) {
  .action-snippet {
    padding: 17px 19px 19px;

    &__title {
      margin-bottom: 3px;
    }

    &__action-line {
      margin-bottom: 10px;
    }
  }
}
</style>
