import axios from "axios";
import { defineStore } from "pinia";
import { ref, Ref, watch, computed, nextTick } from "vue";
import { getGlobalRemoteLogger as globalRemoteLogger } from "@helpers/RemoteLogger";

// Types
import { AvailableCollabsForCreatorPayloadV2 } from "@contracts/collabCredits";

export const useAvailableCollabsV2Store = defineStore("availableCollabsV2", () => {
  const isLoading = ref<boolean>(false);
  const data = ref<AvailableCollabsForCreatorPayloadV2>(null);

  async function loadAvailableCollabs(creatorId: string): Promise<void> {
    isLoading.value = true;

    const uri = `${import.meta.env.VITE_API_URL}/creator/${creatorId}/available-collabs/v2`;
    const response = await axios.get(uri);

    isLoading.value = false;

    data.value = response.data as AvailableCollabsForCreatorPayloadV2;
  }

  return {
    isLoading,
    data,
    loadAvailableCollabs,
  };
});
