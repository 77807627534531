export default function transformPhoneToUSMask(number: string): string {
  let n: string = number.replace(/\D/g, "");
  const l = n?.length;

  if (n[0] === "1") {
    if (l === 1) {
      n = `+1`;
    } else if (l > 1 && l <= 4) {
      n = `+1 (${n.slice(1)}`;
    } else if (l > 4 && l <= 7) {
      n = `+1 (${n.slice(1, 4)}) ${n.slice(4)}`;
    } else if (l > 7) {
      n = `+1 (${n.slice(1, 4)}) ${n.slice(4, 7)}-${n.slice(7, 11)}`;
    }
  } else {
    if (l > 0 && l <= 3) {
      n = `(${n.slice(0)}`;
    } else if (l > 3 && l <= 6) {
      n = `(${n.slice(0, 3)}) ${n.slice(3)}`;
    } else if (l > 6) {
      n = `(${n.slice(0, 3)}) ${n.slice(3, 6)}-${n.slice(6, 10)}`;
    }
  }

  return n;
}
