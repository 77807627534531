<template>
  <div>
    <div class="inner-hero" style="background-image: url(https://cdn.shrpa.com/images/backgrounds/hiking.jpg)">
      <div class="header-overlay wow fadeIn">
        <h2 class="ui inverted header header-title">Careers</h2>
        <h2 class="ui inverted header header-heading">Join us!</h2>
      </div>
    </div>

    <div class="content-wrapper">
      <div class="stackable very relaxed ui grid container">
        <div class="row">
          <div class="four wide column">
            <img class="ui image" src="https://cdn.shrpa.com/images/shrpa-full-color.png" alt="shrpa" />
          </div>

          <div class="twelve wide column">
            <h2 class="ui header">So you've got skills?</h2>
            <p>
              Detail why you're awesome and how you would fit into Shrpa!<br />
              Email <CopyTextNew style="margin-top: 3px">careers@shrpa.com</CopyTextNew>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useHead } from "@unhead/vue";
import CopyTextNew from "@components/CopyTextNew.vue";

export default defineComponent({
  name: "CareersView",
  components: { CopyTextNew },

  mounted() {
    useHead({ title: "Careers at Shrpa" });
  },
});
</script>
