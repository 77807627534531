import { defineStore } from "pinia";
import { ref, Ref, watch, computed, nextTick } from "vue";
import { getGlobalRemoteLogger as globalRemoteLogger } from "@helpers/RemoteLogger";

// Types
import { CollabLocationsData, CollabLocation, CollabLocationEmpty } from "@contracts/collabLocations";
import { fetchCollabLocations, saveCollabLocations } from "@repos/CollabLocationsRepo";

export const useCollabLocationsStore = defineStore("collabLocations", () => {
  const collabLocationsData = ref<CollabLocationsData>(getDefaultCollabLocationsData());
  const usedLocationsInAdventures = ref<string[]>([]);

  const isCollabLocationsDataDirty: Ref<boolean> = ref<boolean>(false);
  const isLoaded: Ref<boolean> = ref<boolean>(false);

  const isLoading: Ref<boolean> = ref<boolean>(false);

  /**
   * Reset the state of the store to the original values.
   * For example, when entering the CMS after previously viewing
   * an adventure with locations in the CMS, the data could possibly
   * be incorrect. Resetting ensure the data is back to it's initial state
   */
  function reset() {
    collabLocationsData.value = getDefaultCollabLocationsData();
    isCollabLocationsDataDirty.value = false;
    isLoaded.value = false;
  }

  async function loadCollabLocationsData(collabInputId: string, creatorId: string, customerId: string): Promise<void> {
    isLoaded.value = false;
    const { locationData, locationsInAdventures } = await fetchCollabLocations(collabInputId, creatorId, customerId);

    collabLocationsData.value = {
      ...locationData,
      visitPlanNotes: locationData.visitPlanNotes || "",
    };
    usedLocationsInAdventures.value = locationsInAdventures;
    isLoaded.value = true;

    // awaiting nextTick to ensure the isCollabLocationsDataDirty is set to false after the initial load
    // there are some watch() calls that need to be flushed before setting the value to false
    await nextTick();
    isCollabLocationsDataDirty.value = false;
  }

  function checkIsRequestedDataTheSameAsLoaded(customerId: string, collabInputId: string, creatorId: string): boolean {
    return customerId === collabLocationsData.value.customerId && collabInputId === collabLocationsData.value.collabInputId && creatorId === collabLocationsData.value.creatorId;
  }

  async function saveCollabLocationsData(customerId: string, collabInputId: string, creatorId: string): Promise<void> {
    if (!checkIsRequestedDataTheSameAsLoaded(customerId, collabInputId, creatorId)) {
      globalRemoteLogger().warn(
        `saveCollabLocationsData Mismatch! ${customerId} vs ${collabLocationsData.value.customerId}, ${collabInputId} vs ${collabLocationsData.value.collabInputId}, ${creatorId} vs ${collabLocationsData.value.creatorId}`
      );
    } else if (!isCollabLocationsDataDirty.value) {
      console.log("The collabLocationsData wasn't changed");
    } else {
      isLoading.value = true;
      try {
        const updatedCollabLocations = await saveCollabLocations(collabLocationsData.value, customerId, collabInputId, creatorId);
        collabLocationsData.value = updatedCollabLocations;
        isCollabLocationsDataDirty.value = false;
      } catch (error) {
        globalRemoteLogger().error(`Error saving the collabLocationsData: ${error}`);
      }
      isLoading.value = false;
    }
  }

  watch(
    () => collabLocationsData.value.locations,
    () => {
      isCollabLocationsDataDirty.value = true;
    },
    { deep: true }
  );

  watch(
    () => collabLocationsData.value.visitPlanNotes,
    () => {
      isCollabLocationsDataDirty.value = true;
    }
  );

  const locations = computed<Array<CollabLocation>>({
    get: () => collabLocationsData.value.locations,
    set: newLocationsList => {
      collabLocationsData.value.locations = newLocationsList;
    },
  });

  const locationsTextRepresentation = computed<string>(() => {
    return getLocationsTextRepresentation();
  });

  /**
   * Helper composable function that returns a computed property for the
   * text representation of the locations. It supports dynamic number of
   * placeholders for padding out locations when the visit plan is not sent.
   */
  function useLocationsTextRepresentation(placeholdersCount = 0) {
    return computed<string>(() => {
      return getLocationsTextRepresentation(placeholdersCount);
    });
  }

  function getLocationsTextRepresentation(padUntil = 0): string {
    let text = "";

    const maxIndex = Math.max(collabLocationsData.value.locations.length, padUntil);
    // Show the locations, and include placeholders for empty locations
    for (let i = 0; i < maxIndex; i++) {
      const location = collabLocationsData.value.locations[i];
      if (location) {
        if (location.title) text += `<b>${i + 1}</b>. <b>${location.title}</b>`;
        if (location.visitPlanNotes) text += `: ${location.visitPlanNotes}`;
        if (location.title || location.visitPlanNotes) text += "\n";
      } else {
        text += `${i + 1}.\n`;
      }
    }

    if (collabLocationsData.value.visitPlanNotes) text += `\n${collabLocationsData.value.visitPlanNotes}`;

    return text;
  }

  const visitPlanNotes = computed<string>({
    get: () => collabLocationsData.value.visitPlanNotes,
    set: newVisitPlanNotes => (collabLocationsData.value.visitPlanNotes = newVisitPlanNotes),
  });

  return {
    loadCollabLocationsData,
    usedLocationsInAdventures,
    locations,
    locationsTextRepresentation,
    useLocationsTextRepresentation,
    visitPlanNotes,
    collabLocationsData,
    saveCollabLocationsData,
    isCollabLocationsDataDirty,
    reset,
    isLoaded,
    isLoading,
  };
});
function getDefaultCollabLocationsData(): CollabLocationsData {
  return {
    id: "",
    pkey: "",
    type: "CollabLocations",

    createdBy: "",
    createdDate: "",
    updatedBy: "",
    updatedDate: "",

    collabInputId: "",
    creatorId: "",
    customerId: "",

    locations: [],
    visitPlanNotes: "",
  };
}
