<template>
  <div class="not-auth-yet-section">
    <!-- Greeting -->
    <div class="greeting not-auth-yet-section__greeting">
      <h1 class="global-h1 greeting__h1">Welcome to Shrpa</h1>
      <h2 class="global-h2 greeting__h2">Ready to start creating?</h2>

      <SrpButton class="greeting__cta-btn" :size="screenSize !== 'mobile' ? 'big' : 'normal'" @click="emit('authenticate')" iconInset="0 auto auto auto">
        <template #icon><IconEmbedded name="user-plus_2-5" :size="screenSize === 'mobile' ? 19 : 25" /></template>
        Create Account
      </SrpButton>
    </div>
    <!-- / Greeting -->

    <ActivityFeed class="not-auth-yet-section__activity-feed" />

    <div class="not-auth-yet-section__info-center">
      <h4 class="global-h4" style="margin-bottom: 12px; display: inline; font-weight: 500">Confused how you got here or what creating on Shrpa means?</h4>
      <SrpButton style="margin-left: 10px" :href="globalGetLink('ForCreators')" size="small" fill="outlined" color="gray">
        Learn more
        <template #iconRight><IconEmbedded name="caret-right_4" :size="13" /></template>
      </SrpButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { inject, Ref } from "vue";

// Components
import ActivityFeed from "@components/ActivityFeed/index.vue";
import SrpButton from "@components/ui/SrpButton.vue";

// Types
import { ScreenSize } from "@contracts/screenSize";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

// Global variables & injections
const globalGetLink = inject("globalGetLink") as any;
const screenSize = inject("screenSize") as Ref<ScreenSize>;

const emit = defineEmits<{
  (e: "authenticate"): void;
}>();
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";
@import "@/scss/screen-size-ranges.scss";

// Greeting ===================================================================
.greeting {
  padding: 55px 30px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(231, 237, 240, 1);

  &__h1 {
    margin-bottom: 12px;
    color: $brand-color;
    font-size: 55px;
    line-height: 50px;
    text-align: center;
  }

  &__h2 {
    margin-bottom: 30px;
    font-weight: 500;
    text-align: center;
  }

  &__cta-btn {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .greeting {
    padding: 45px 20px;

    &__h1 {
      font-size: 40px;
      line-height: 40px;
    }
  }
}

// Not auth yet section =======================================================
.not-auth-yet-section {
  padding: 0;

  &__greeting {
    margin-bottom: 55px;
  }

  &__activity-feed {
    margin-bottom: 75px !important;

    :deep(h1) {
      margin-bottom: 20px !important;
      font-size: 25px;
    }
  }

  &__info-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
</style>
