<template>
  <div v-if="stop" class="ui form stopForm" style="margin: 8px">
    <div class="two fields" style="padding-bottom: 35px; margin-bottom: 35px; border-bottom: 1px rgba(0, 0, 0, 0.1) solid">
      <div class="field">
        <MapSection
          :fieldsWithErrors="fieldsWithErrors"
          :stop="stop"
          :firstStop="firstStop"
          :stopTabIndex="stopTabIndex"
          :isTabActive="isTabActive"
          :allStops="allStops"
          :useCollabLocationsLogic="useCollabLocationsLogic"
          @placeChanged="(updatedStop, stopTabIndex, callback) => $emit('placeChanged', updatedStop, stopTabIndex, callback)"
          @markerManuallyMoved="$emit('markerManuallyMoved')"
          @resetPlace="resetPlace"
          @click:setLocation="showCollabStopLocationModal = true"
          @click:clearLocation="resetCollabLocationAndShowSelectModal"
          @mapReady="
            () => {
              isMapReady = true;
              // fixes a bug with Sapling overlay positioning
              if (isTabActive) setupSapling();
            }
          "
        >
          <!-- website editing is in the "edit" section of <MapSection /> for collab locations logic -->
          <template v-if="!useCollabLocationsLogic" #under-map>
            <!-- Website -->
            <div style="margin-bottom: 20px; display: flex; flex-wrap: wrap; align-items: center">
              <label class="subsection-title" style="width: 100%"> <i class="icon linkify"></i>Website </label>

              <input v-if="isWebsiteEditMode" type="text" v-model="stop.website" style="width: calc(100% - 50px); margin-right: 10px; display: inline-flex" />
              <template v-else>
                <a v-if="stop.website" target="_blank" :href="stop.website" style="color: #5b5b5b; font: 14px/14px sans-serif; text-decoration: underline">{{ stop.website }}</a>
                <span v-else style="color: #5b5b5b; font: 14px/14px sans-serif">No website</span>
              </template>

              <LinkWithIcon v-if="!isWebsiteEditMode" @click="isWebsiteEditMode = true" style="margin-left: 4px; position: relative; top: -2px">
                <template #icon><IconEmbedded name="edit-pencil_2" :size="20" color="rgba(17, 134, 137, 1)" /></template>
              </LinkWithIcon>

              <LinkWithIcon v-if="stop.website?.length > 0" @click="toggleLink" style="margin-left: 4px; position: relative; top: -2px">
                <template #icon><IconEmbedded :name="showAdditionalLink ? 'minus_2-5' : 'plus_2-5'" :size="20" color="rgba(17, 134, 137, 1)" /></template>
              </LinkWithIcon>

              <div v-if="showAdditionalLink" style="width: 100%; margin-top: 15px">
                <label class="subsection-title">Text</label>
                <input v-model="stop.customLinkText" type="text" class="marginBottom1" />
                <label class="subsection-title">Link</label>
                <input v-model="stop.customLinkUri" type="text" class="marginBottom1" />
              </div>
            </div>
            <!-- / Website -->
          </template>
        </MapSection>
      </div>

      <div class="field" style="display: flex; flex-direction: column; justify-content: space-between; margin: 0 !important">
        <div>
          <div>
            <label class="subsection-title" style="display: inline">Your experience at {{ stop.title }}?</label>
          </div>
          <div style="margin-bottom: 15px">
            <!--<p class="cms">Offer enough information to help the traveler feel comfortable exploring it on their own.</p>-->
            <div
              :class="{
                field: true,
                error: fieldsWithErrors.map(i => i.field).includes('details'),
              }"
            >
              <textarea
                v-if="isTabActive"
                ref="detailsInput"
                v-model="stop.details"
                autocapitalize="sentences"
                class="detail_textarea"
                placeholder="What did you see, feel, and experience?
Make it fun and personal!"
                @focus="$emit('fixTheFieldError', stopTabIndex + 1, 'details')"
              ></textarea>
            </div>
            <div style="width: 100%; display: inline-block; justify-content: space-between; align-items: flex-start">
              <div v-if="fieldsWithErrors.map(i => i.field).includes('details')" style="float: left; color: red">
                {{ fieldsWithErrors.find(i => i?.field === "details").errorMsg }}
              </div>
              <div style="float: right">{{ stopDetailsLengthMessage }}</div>
            </div>
          </div>

          <label class="subsection-title"> <img class="pro-tip-review" :src="`${contentBaseUri}/images/shrpa-mark-inverted.png`" />Pro Tip: </label>
          <p class="cms">What's an insider tip to make a great experience?</p>
          <input type="text" v-model="stop.proTip" class="marginBottom2" />

          <!--<div>DistanceToNext: {{stop.distanceToNextStopInMeters}}</div>
        <div>DurationToNext: {{stop.timeToNextStopInSeconds}}</div>-->
        </div>
      </div>
    </div>

    <div v-if="fieldsWithErrors.map(i => i.field).includes('images')" style="margin-bottom: 20px; color: red">
      {{ fieldsWithErrors.find(i => i?.field === "images").errorMsg }}
    </div>

    <!-- if NOT collab location logic, display always, otherwise wait until a collab location is selected -->
    <MediaFilesSelectForm
      v-if="!useCollabLocationsLogic || data.collabLocationId"
      :useCollabLocationsLogic="useCollabLocationsLogic"
      :allItineraryFiles="images"
      :collabImagesUsed="collabImagesUsed"
      :collabImagesUnused="collabImagesUnused"
      :collabLocations="collabLocations"
      :stopIndex="stopTabIndex"
      :isTabActive="isTabActive"
      @upload="uploadFilesFromMediaFilesSelectComponent"
      :allStops="allStops"
      :selectedFiles="selectedImages"
      :unselectedFiles="unselectedImages"
      @selectFile="({ file, includeInStop }) => toggleImageSelection(file, includeInStop)"
      @reorderSelectedFiles="onImagesReorder"
      @deleteFile="removeMediaFileLocal"
      :isCollab="isCollab"
      @onImageUploadedToServer="onImageUploadedToServer"
      @removeMediaFile="removeMediaFileLocal"
      :isError="fieldsWithErrors.map(f => f.field).includes('images')"
    />
    <NoteWithIcon color="blue" v-else>
      <template #text>Please choose a location before adding photos or videos</template>
    </NoteWithIcon>

    <!-- Stop edit buttons -->
    <div class="stop-edit-buttons">
      <SrpButton class="stop-edit-buttons__button" @click="$emit('shiftStep', stopTabIndex, -1)" :isDisabled="isFirst" size="tiny" fill="outlined" color="gray">
        <i class="icon arrow left"></i>&nbsp;Reorder
      </SrpButton>

      <SrpButton class="stop-edit-buttons__button" @click="$emit('shiftStep', stopTabIndex, 1)" :isDisabled="isEnd" size="tiny" fill="outlined" color="gray">
        Reorder&nbsp;<i class="icon arrow right"></i>
      </SrpButton>

      <SrpButton class="stop-edit-buttons__button" @click="removeStop" size="tiny" fill="outlined" color="gray">Remove Stop</SrpButton>
    </div>
    <!-- / Stop edit buttons -->

    <SrpModal v-model:isVisible="showPhotoUploader">
      <template #content>
        <UploadPhotoForm
          callerName="AdvStop"
          :img="images"
          :singlePickMode="false"
          :autoSelect="false"
          :showClose="true"
          :isCollab="isCollab"
          @imageUploadedToServer="onImageUploadedToServer"
          @removeMediaFile="removeMediaFileLocal"
          :showRemove="enableDeletePhotos"
          @close="showPhotoUploader = false"
          ref="domRefEditFilesModal"
          style="height: 480px"
        />
        <div v-if="images?.length > 0" class="toggle-remove-move-link" @click="enableDeletePhotos = !enableDeletePhotos" style="margin-top: -37px">
          {{ enableDeletePhotos ? "Turn off Remove" : "Remove photos" }}
        </div>
      </template>
    </SrpModal>

    <CollabStopLocationSelectorModal
      v-if="collabId"
      v-model:isVisible="showCollabStopLocationModal"
      :collabLocations="collabLocations"
      :customerId="communityId"
      :collabInputId="collabId"
      @locationSelected="onCollabLocationSelected"
    />
  </div>
</template>

<script lang="ts">
/* eslint vue/no-mutating-props: 0 */
import { defineComponent, inject } from "vue";
import FileUtils from "@logic/FileUtils";
import logger from "@helpers/Logger";
import { Sapling } from "@saplingai/sapling-js/observer";

// Types
import { FieldErrorInfo, ItineraryStep } from "@contracts/itinerary";
import { ScreenSize } from "@contracts/screenSize";
import { UploadedImage } from "@contracts/uploadedImage";
import { CollabLocation } from "@contracts/collabLocations";

// Components
import GMap from "@views/CMS/GMap.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import LoaderComponent from "@components/Loader/Loader.vue";
import MediaFilesSelectForm from "@views/CMS/StopsForm/MediaFilesSelectForm.vue";
import MapSection from "./MapSection.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import SrpSpotlightGuide from "@components/SrpSpotlightGuide.vue";
import UploadPhotoForm from "@views/CMS/UploadPhotoForm.vue";
import VideoRender from "@components/VideoRender.vue";
import axios from "axios";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import CollabStopLocationSelectorModal from "./CollabStopLocationSelectorModal.vue";
import { getGlobalRemoteLogger } from "@helpers/RemoteLogger";

export default defineComponent({
  name: "StopsForm",

  components: {
    IconEmbedded,
    GMap,
    LinkWithIcon,
    LoaderComponent,
    MediaFilesSelectForm,
    MapSection,
    NoteWithIcon,
    SrpButton,
    SrpModal,
    SrpSpotlightGuide,
    UploadPhotoForm,
    VideoRender,
    CollabStopLocationSelectorModal,
  },

  props: {
    data: { type: Object, default: () => ({}) },
    allStops: { type: Array as () => Array<ItineraryStep>, default: () => [] },
    images: { type: Array as () => Array<UploadedImage> | null, default: () => [] },
    stopTabIndex: { type: Number, default: -1 },
    hasRemove: { type: Boolean },
    isFirst: { type: Boolean },
    isEnd: { type: Boolean },
    firstStop: { type: Object, default: () => ({}) },
    isCollab: { type: Boolean },
    minStopDetailsChars: { type: Number, default: 0 },
    fieldsWithErrors: { type: Array as () => FieldErrorInfo[] | null, default: () => [] },
    isTabActive: { type: Boolean, default: false }, // it's needed to fix the Sapling buggy behavior by removing inactive textarea
    collabId: { type: String, default: "" },
    communityId: { type: String, default: "" },
    itineraryId: { type: String, default: "" },
    collabLocations: { type: Array as () => CollabLocation[], required: true },
    collabImagesUsed: { type: Array as () => Array<string>, default: () => [] },
    collabImagesUnused: { type: Array as () => Array<string>, default: () => [] },
    useCollabLocationsLogic: Boolean,
  },

  emits: {
    fixTheFieldError: (_arg0: number, _arg1: string) => true,
    remove: (_stopIndex: number) => true,
    updated: (_stop: ItineraryStep | null, _stopIndex: number) => true,
    imageUploadedToServer: (_uploadedImage: UploadedImage, _autoSelect: boolean) => true,
    removeMediaFile: (_serverId: string, _collabLocationId: string | null) => true,
    toggleImageSelection: (_image: UploadedImage, _includeInStop: boolean) => true,
    shiftStep: (_stopTabIndex: number, _direction: -1 | 1) => true,
    placeChanged: (_updatedStop: ItineraryStep, _stopTabIndex: number, _callback: (...args: any[]) => any) => true,
    markerManuallyMoved: () => true,
  },

  data() {
    return {
      globalLog: inject("globalLog") as any,
      screenSize: inject("screenSize") as ScreenSize,

      isMapReady: false,

      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      stop: null as ItineraryStep | null,
      showPhotoUploader: false,
      isShowAddress: false,
      showAdditionalLink: false,
      // Enables "remove mode" so they can delete photos from the upload form
      enableDeletePhotos: false,

      selectedImages: [] as Array<UploadedImage>,

      isWebsiteEditMode: false,

      isTooFarAwaySpotlightVisible: false,
      isSameLocationSpotlightVisible: false,

      // It's needed to avoid repeated showing of the guide in the current tab "session".
      // It should be reset on tab change
      isTooFarAwaySpotlightWasClosedByUser: false,
      isSameLocationSpotlightWasClosedByUser: false,

      showCollabStopLocationModal: false,
    };
  },

  computed: {
    selectedCollabLocation(): CollabLocation | null {
      if (!this.stop) return null;
      return this.collabLocations.find(l => l.id === this.stop.collabLocationId) ?? null;
    },
    // Note: Also in SummaryForm.vue
    stopDetailsLengthMessage(): string {
      const len = this.stop.details?.trim().length ?? 0;
      if (len === 0) return "enter at least " + this.minStopDetailsChars + " characters";
      else if (len >= this.minStopDetailsChars) return "";
      else return this.minStopDetailsChars - len + " more";
    },
    unselectedImages(): Array<UploadedImage> {
      // NOTE: If we just used itinerary.unusedPhotos the creator wouldn't be able to use a photo on mulitple stops, which we might want...
      // They could by just re-uploading so just leaving it for now
      const unselected = new Array<UploadedImage>();
      this.images.forEach(image => {
        if (this.stop.images.indexOf(image.serverId) < 0) unselected.push(image);
      });
      return unselected;
    },
  },

  watch: {
    async isTabActive() {
      await this.$nextTick();

      if (this.isTabActive) this.setupSapling();
      else this.unobserveSapling();
    },
    stop: {
      async handler(newVal, oldVal) {
        if (oldVal != null) {
          this.globalLog.debug("Emitting update for Stop " + this.stopTabIndex);
          this.$emit("updated", this.stop, this.stopTabIndex);
        }

        if (newVal.title) {
          this.$emit("fixTheFieldError", this.stopTabIndex + 1, "title");
        }
        if (newVal.latitude) {
          this.$emit("fixTheFieldError", this.stopTabIndex + 1, "latitude");
        }
      },
      deep: true,
    },
    "stop.images": {
      handler() {
        const selected = new Array<UploadedImage>();
        this.stop.images.forEach(image => {
          // Prefer the local version in case they just added it
          const localImage = this.images.find(s => s.serverId === image);
          if (localImage) selected.push(localImage);
          else selected.push({ serverId: image } as UploadedImage);
        });
        this.selectedImages = selected;
      },
      deep: true, // because it's nested stop.images property
    },
  },

  async mounted() {
    this.stop = { ...this.data } as ItineraryStep | null;

    await this.$nextTick();
    if (this.isTabActive) this.setupSapling();
  },

  methods: {
    /** inverse of onCollabLocationSelected */
    resetCollabLocationAndShowSelectModal() {
      /* eslint-disable vue/no-mutating-props */
      this.stop.title = null;
      this.stop.externalId = null;
      this.stop.latitude = null;
      this.stop.longitude = null;
      this.stop.address = null;
      this.stop.city = null;
      this.stop.state = null;
      this.stop.country = null;
      this.stop.website = null;
      this.stop.collabLocationId = null;
      this.stop.images = [];
      /* eslint-enable vue/no-mutating-props */

      // clear validation errors related to fields that just got set
      this.$emit("fixTheFieldError", this.stopTabIndex + 1, "collabLocationId");
      this.$emit("fixTheFieldError", this.stopTabIndex + 1, "images");
      this.$emit("fixTheFieldError", this.stopTabIndex + 1, "latitude");

      // show the location after clearing collab location related data
      this.showCollabStopLocationModal = true;
    },
    async onCollabLocationSelected(collabLocation: CollabLocation) {
      // Update: We now let users change the location, even after setting it
      const isOverwritingLocation = this.stop.collabLocationId != null;
      // Also considering the same (non-null) google place the same location (that allows us to keep the images below)
      const isOverwritingWithSameLocation = this.stop.collabLocationId === collabLocation.id || (this.stop.externalId && this.stop.externalId === collabLocation.externalId);
      /* eslint-disable vue/no-mutating-props */
      this.stop.title = collabLocation.title;
      this.stop.externalId = collabLocation.externalId;
      this.stop.latitude = collabLocation.latitude;
      this.stop.longitude = collabLocation.longitude;
      this.stop.address = collabLocation.address;
      this.stop.city = collabLocation.city;
      this.stop.state = collabLocation.state;
      this.stop.country = collabLocation.country;
      this.stop.website = collabLocation.website;
      this.stop.collabLocationId = collabLocation.id;

      // If they are overwriting the same location, don't clear the images
      if (isOverwritingLocation && isOverwritingWithSameLocation) {
        logger.info("onCollabLocationSelected: Overwriting the same location, not clearing images");
        if (this.stop.images.length > 0 && collabLocation.mediaIds.length === 0) {
          // Option to copy over the images from the stop (in case something was off and they got lost)
          logger.info("onCollabLocationSelected: Copying images from stop to location");
          collabLocation.mediaIds = [...this.stop.images];
        }
      } else {
        // NOTE: Setting the stop to have all of the collab location
        // images selected by default when the location is selected
        // Must be a new array to avoid mutation of the original array when locations are removed
        this.stop.images = [...collabLocation.mediaIds];
        /* eslint-enable vue/no-mutating-props */
      }

      // clear validation errors related to fields that just got set
      this.$emit("fixTheFieldError", this.stopTabIndex + 1, "collabLocationId");
      this.$emit("fixTheFieldError", this.stopTabIndex + 1, "images");
      this.$emit("fixTheFieldError", this.stopTabIndex + 1, "latitude");

      // placeChanged re-calculates the route and map (the -1 means it won't update the itinerary since we do that above)
      // We need to wait for the next tick because of how the map recalc logic works (or it will miss the stop set above)
      await this.$nextTick();
      this.$emit("placeChanged", this.stop, -1, () => {});
    },
    resetPlace() {
      this.stop.latitude = null;
      this.stop.longitude = null;
      this.stop.address = null;
      this.stop.city = null;
      this.stop.country = null;
      this.stop.externalId = null;
    },
    async uploadFilesFromMediaFilesSelectComponent(event) {
      this.showPhotoUploader = true;
      await this.$nextTick(); // wait for the modal to open
      (this.$refs.domRefEditFilesModal as any)?.onImageReadyToUploadV2(event);
    },
    removeStop() {
      this.$emit("remove", this.stopTabIndex);
    },
    showAddress() {
      this.isShowAddress = true;
    },
    editPhoto() {
      this.showPhotoUploader = true;
    },
    toggleLink() {
      this.showAdditionalLink = !this.showAdditionalLink;
      /* Future feature to add multiple links (for now it's just one)
      if (this.stop.customLinkUri) {
        this.stop.customLinkUri.push({text: null, url: null});
      } else {
        this.stop.customLinkUri = [{text: null, url: null}]
      }*/
    },
    onImageUploadedToServer(image: UploadedImage) {
      // Update: NOT auto-selecting with the new images UI
      this.$emit("imageUploadedToServer", image, false);
      // this.toggleImageSelection(image, false);
    },
    onImagesReorder(reorderedArray) {
      this.stop.images = reorderedArray.map(i => i.serverId);
    },
    toggleImageSelection(image: UploadedImage, includeInStop: boolean) {
      this.$emit("fixTheFieldError", this.stopTabIndex + 1, "images");

      if (!image?.serverId) {
        console.info(`Image ${image.name} is still uploading`);
        return;
      }
      if (!includeInStop) {
        const removeIndex = this.stop.images.indexOf(image.serverId);
        this.stop.images.splice(removeIndex, 1);

        // Push it to the uploaded collection so it doesn't just disappear
        if (!this.images.some(i => i.serverId == image.serverId) && !this.collabImagesUsed.includes(image.serverId) && !this.collabImagesUnused.includes(image.serverId)) {
          this.images.push(image);
        }
      } else {
        this.stop.images.push(image.serverId);
      }
      this.$emit("toggleImageSelection", image, includeInStop);
    },
    isVideoFileType(fileName: string) {
      return FileUtils.isVideoFileType(fileName, null);
    },
    removeMediaFileLocal(serverId: string) {
      // Add the CollabLocationId to the emit
      this.$emit("removeMediaFile", serverId, this.selectedCollabLocation?.id);
    },
    setupSapling() {
      try {
        let editor = this.$refs.detailsInput as HTMLTextAreaElement;
        logger.info("Sapling setup for " + this.stop?.title + ": " + (editor ? "found" : "not found"));
        if (editor) {
          Sapling.observe(editor);
        }
        console.log(`Sapling is observing the Stop ${this.stopTabIndex + 1} detailsInput`);
      } catch (e) {
        getGlobalRemoteLogger().error(`Error setting up Sapling for ${this.itineraryId}: ${e.message ?? e}`);
      }
    },
    unobserveSapling() {
      let editor = this.$refs.detailsInput as HTMLTextAreaElement;
      if (!editor) return;
      Sapling.unobserve(editor);

      console.log(`Sapling unobserved the Stop ${this.stopTabIndex + 1} detailsInput`);
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Stop edit buttons ==========================================================
.stop-edit-buttons {
  padding-top: 25px;
  margin-top: 30px;
  border-top: 1px rgba(0, 0, 0, 0.1) solid;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &__button {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .stop-edit-buttons {
    flex-wrap: wrap;
    justify-content: center;

    &__button {
      margin: 0 5px 10px;

      &:last-child {
        margin-bottom: 25px;
      }
    }
  }
}

// Toggle remove link =========================================================
.toggle-remove-move-link {
  margin-top: 8px;
  text-decoration: underline;
  cursor: pointer;
  user-select: none;

  &:hover {
    text-decoration: none;
  }
}

// Remove button ==============================================================
.remove-button {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: 2px 2px auto auto;
  z-index: 3;
  cursor: pointer;

  &::before {
    color: #fff;
    font-size: 16px;
  }

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 100px;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -1;
    background: #058587;
    transition:
      width 0.07s ease-in-out,
      height 0.07s ease-in-out,
      inset 0.07s ease-in-out;
  }

  &:hover::after {
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    inset: -2px auto auto -2px;
  }
}

.stopForm {
  .modal-header {
    background: #058587;
    color: white;
    padding-right: 21px !important;
  }

  // .cms-section-header {
  //   font-size: 1.5em !important;
  //   font-weight: 500 !important;
  //   color: #000000;
  // }

  .pro-tip-review {
    position: relative !important;
  }

  .photoNode {
    padding: 10px;
    position: relative;
    text-align: center;
  }

  .checkIconContainer {
    z-index: 20;
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
    //Increase the click area for mobile
    padding: 10px;
    margin: -10px;
  }
  .checkIcon {
    background: #058587 !important;
  }

  .addLink {
    color: #058587 !important;
    font-weight: bold;
    cursor: pointer;
    margin: 0.5em 0 !important;
  }

  .detail_textarea {
    margin-bottom: 0.3em;
    height: 7em;
  }

  .addPhoto {
    width: 100%;
    padding: 5px 0 10px;
    color: #058587 !important;
    font-weight: bold;
    cursor: pointer;
    margin: 0.5em 0 0 !important;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .marginBottom1 {
    margin-bottom: 1em !important;
  }

  .marginBottom2 {
    margin-bottom: 2em !important;
  }

  .cursorPoint {
    cursor: pointer;
  }
}
</style>

<style lang="scss">
.stopForm {
  .checkModal {
    .visible.active {
      max-width: 500px !important;
    }
  }
}

.text-center {
  text-align: center !important;
}
</style>
