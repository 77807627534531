<template>
  <pre>{{ userProfileStore.getViewingUserProfile }}</pre>
</template>
<script setup lang="ts">
import { useUserProfileStore } from "@stores/userProfileStore";
import { watchEffect } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const userProfileStore = useUserProfileStore();

// Watch for getViewingUserProfile to be loaded, then redirect to the AvailableCollabs page (udpate: the dashboard has collabs now)
watchEffect(() => {
  if (userProfileStore.getViewingUserProfile) {
    router.replace({ name: "CreatorDashboard", params: { creatorId: userProfileStore.getViewingUserProfile.sherpaId } });
  }
});
</script>
