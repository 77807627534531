<template>
  <LinkWithIcon
    :style="{
      pointerEvents: isShowCopiedMsg ? 'none' : 'auto',
    }"
    iconInset=" 2px auto auto 4px"
    isDottedUnderline
    @click="copy"
    :color="props.color"
    :class="{
      'no-underline': isShowCopiedMsg,
    }"
  >
    <template #icon>
      <IconEmbedded :name="isShowCopiedMsg ? 'check_3' : 'copy_2'" :size="isShowCopiedMsg ? 18 : 16" />
    </template>
    <span>{{ isShowCopiedMsg ? "copied" : "copy" }}</span>
  </LinkWithIcon>
</template>

<script setup lang="ts">
import { ref } from "vue";

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";

// Types
import { LinkWithIconColors } from "@components/LinkWithIcon.vue";

const props = withDefaults(
  defineProps<{
    text: string;
    color?: LinkWithIconColors;
  }>(),
  {
    text: "",
    color: "gray",
  }
);

const isShowCopiedMsg = ref<boolean>(false);

function copy() {
  navigator.clipboard.writeText(props.text).then(
    () => {
      console.info("Copied to clipboard: " + props.text);
    },
    function (err) {
      console.error("Failed to copy to clipboard: ", err);
    }
  );

  isShowCopiedMsg.value = true;

  setTimeout(() => {
    isShowCopiedMsg.value = false;
  }, 3000);
}
</script>

<style scoped lang="scss">
.no-underline {
  :deep(span) {
    text-decoration: none !important;
  }
}
</style>
