<template>
  <div class="creator-visits-page">
    <Loader v-if="isLoading" style="position: absolute"></Loader>

    <template v-else>
      <div class="global-h1 creator-visits-page__h1">
        {{ hasACollabSubscription ? "Creator Visits" : "Gain access to Creator Visits!" }}
      </div>

      <!-- planned collabs -->
      <PlannedCollabsList
        v-if="collabsRemaining > 0 && !plannedCollabsState.loading && collabs && customerDetails?.isDisabled !== true"
        :customerId="customerDetails.id"
        :plannedCollabs="plannedCollabsState.plannedCollabs"
        :expectedPlannedCollabsCount="collabsRemaining"
        :createdCollabCount="collabs.length"
        @click:getStarted="startCollabFromPlannedCollab"
        @savePlannedCollabs="savePlannedCollabs"
      />

      <!-- Header -->
      <div class="header creator-visits-page__header">
        <div class="header__left-side">
          <!--<VideoIntroSnippet
            class="header__video-snippet"
            thumbnail="https://cdn.shrpa.com/videos/howto/thumb/collab-intro.jpg"
            video-url="https://cdn.shrpa.com/videos/howto/collab-intro.mp4"
          />-->
          <div v-if="!hasACollabSubscription" class="header__description">
            <div>
              Creator Visits are the most effective way to create high-quality, targeted UGC.<br />
              Your area has outstanding creators ready to explore your destination and deliver exceptional content.<br />
            </div>
            <button class="ui tiny primary orange button create-collab" style="margin-top: 13px" @click="askToBuyCollab">
              <!-- <i class="icon plus" /> -->
              <span>Want Creator Visits!</span>
            </button>
            <a href="https://explore.shrpa.com/collaborate-with-content-creators/" target="_blank" style="margin-left: 20px; text-decoration: underline">Learn more</a>
          </div>

          <div v-if="hasACollabSubscription && collabs" style="margin-left: -25px">
            <!-- Collab select dropdown -->
            <div v-if="collabs.length > 0" class="collab-select-dropdown creator-visits-page__collab-select-dropdown">
              <div class="global-h2 collab-select-dropdown__overlay">
                <div class="collab-select-dropdown__overlay-text">{{ getCollabNameText(collabs[selectedCollabIndex]) }}</div>
              </div>
              <select class="collab-select-dropdown__select-itself" v-model="selectedCollabIndex" @change="collabSelectionChanged">
                <option v-for="item in collabs.map((c, index) => ({ text: getCollabNameText(c), value: index }))" :key="item.value" :value="item.value">{{ item.text }}</option>
              </select>
              <IconEmbedded class="collab-select-dropdown__caret-icon" name="caret-bottom_2-5" />
            </div>
            <!-- / Collab select dropdown -->
            <div v-else>
              <h2 style="margin-bottom: 3px">Wondering where to start?</h2>
              <div style="margin-bottom: 8px">Explore ideas inspired by past successful creator visits.</div>
              <SrpButton :to="{ name: 'VisitInspiration', params: { pageId: communityId } }" class="header__setup-btn" size="small">
                <template #icon>
                  <IconEmbedded name="ai_2" :size="18" />
                </template>
                Get Visit Inspiration
              </SrpButton>
            </div>
          </div>
        </div>

        <div v-if="hasACollabSubscription && customerDetails?.isDisabled !== true && customerDetails?.isSpotlight !== true" class="header__right-side">
          <SrpButton class="header__setup-btn" @click="askToCreate" size="small" color="teal" fill="outlined">
            <template #icon>
              <IconEmbedded name="plus_4" :size="18" />
            </template>
            {{ collabs.length > 0 ? "Set up another Creator Visit" : "Start a Creator Visit" }}
          </SrpButton>

          <!-- Stats list -->
          <ul class="stats-list header__stats-list">
            <li class="stats-list__line">
              <b class="stats-list__number">{{ collabsRemaining }}</b> {{ collabsTerm }} Remaining
            </li>
            <!--<li class="stats-list__line">
                <b class="stats-list__number">{{ collabsRemaining * 4 }}</b> Adventures
              </li>-->
          </ul>
          <!-- / Stats list -->

          <!--<template v-else>
            <SrpButton v-if="collabs.length > 0" @click="askToBuyCollab" class="header__setup-btn" size="small" color="orange"> Want more Creator Visits? </SrpButton>
          </template>-->
        </div>
      </div>
      <!-- / Header -->

      <template v-if="hasACollabSubscription && collabs">
        <!--<Preview />-->
        <!--<router-link :to="{name: 'CommunityAdminDashboard'}" lang="en" hreflang="en" class="ui small basic button right floated">Back to Dashboard</router-link>-->

        <template v-if="communityId && selectedCollab">
          <!-- CollabSummary always shows now that we have the collab wizard -->
          <CollabSummary
            class="creator-visits-page__spoiler-section"
            :communityId="communityId"
            v-model:collabInput="selectedCollab"
            :collabRemainingCounts="collabRemainingCounts"
            :collabsRemaining="collabsRemaining"
            :customerIsDisabled="customerDetails?.isDisabled"
            :collabIndex="selectedCollabIndex"
            :canEditInputs="canEditCollabInputs"
            :currentDateLocal="currentDateLocal"
            :communityCreatorMatchSummary="communityCreatorMatchSummary"
            @needMoreCollabs="askToBuyCollab"
            @deleted="currentCollabDeleted"
            @scroll-to-creators="$refs?.creatorsSection?.$el?.scrollIntoView({ behavior: 'smooth' })"
          />

          <CreatorInvites
            class="creator-visits-page__spoiler-section"
            v-if="isCreatorInvitesVisible && selectedCollab.version >= 2 && !selectedCollab.shrpaIsChoosingCreators"
            :reloadTrigger="reloadCollabCreatorInputs"
            :communityId="communityId"
            :collabInput="selectedCollab"
            :collabIndex="selectedCollabIndex"
            :forceLoad="superShowCollabInvites"
            :collabsRemaining="collabsRemaining"
            @selectMultipleCreators="loadCollabs"
            ref="creatorsSection"
          />

          <CommunityCorrections
            v-if="selectedCollab?.collaboration?.creatorId"
            class="creator-visits-page__spoiler-section"
            :communityId="communityId"
            :collabInput="selectedCollab"
            :collabIndex="selectedCollabIndex"
            :creatorId="selectedCollab?.collaboration?.creatorId"
            @updated="collabUpdate"
          />

          <CollabFeedbackComponent
            v-if="selectedCollab.feedback"
            class="creator-visits-page__spoiler-section"
            :communityId="communityId"
            :collabInputId="selectedCollab.id"
            :collabIndex="selectedCollabIndex"
            :feedback="selectedCollab.feedback"
            @updated="feedbackUpdate"
          />
        </template>

        <div v-if="isSuperOrSalesUser === true && collabs.length > 0" class="creator-visits-page__admin-buttons-section">
          <SrpButton @click="askToDelete" color="gray" fill="outlined" size="tiny" style="margin-right: 12px; opacity: 0.5">Hard Delete</SrpButton>

          <router-link
            v-if="selectedCollab"
            :to="{ name: 'SuperUserCollabDetails', params: { communityId: communityId, collabInputId: selectedCollab.id } }"
            target="_blank"
            style="margin-right: 12px"
          >
            <SrpButton color="gray" fill="outlined" size="tiny" style="opacity: 0.5">Backend View</SrpButton>
          </router-link>
          <SrpButton v-if="selectedCollab?.collaboration?.creatorId" @click="superShowInvites()" color="gray" fill="outlined" size="tiny" style="opacity: 0.5; margin-right: 12px"
            >Show Applies</SrpButton
          >

          <SrpButton @click="showDateOverrideModal = true" color="gray" fill="outlined" size="tiny" style="opacity: 0.5">
            <IconEmbedded name="calendar_2" :size="17" />
          </SrpButton>
        </div>
      </template>
    </template>
  </div>

  <!--New Collab Wizard-->
  <CreateCollabModal
    v-if="communityId"
    :communityId="communityId"
    :collabSuggestionId="collabSuggestionId"
    :defaultTheme="defaultTheme"
    :collabRemainingCounts="collabRemainingCounts"
    :visible="showCreateModal"
    :plannedCollab="plannedCollabForCreateModal"
    @collabCreated="collabCreated"
    @close="cancelCreate"
  />

  <!--Delete Modal-->
  <SrpModal v-model:isVisible="showDeleteModal" size="small">
    <template #header>
      <h2 class="global-h2">You sure???<br />This is a superuser hard delete operation</h2>
    </template>
    <template #footer>
      <SrpButton @click="cancelDelete" color="gray" fill="outlined" style="margin-right: 10px">Cancel</SrpButton>
      <SrpButton @click="deleteCollab" color="orange">Yep, delete it</SrpButton>
    </template>
  </SrpModal>

  <!--Purchase Modal-->
  <SrpModal v-model:isVisible="showPurchaseModal" :isClosable="false">
    <template #header>
      <h2 v-if="customerDetails?.isDisabled" class="global-h2">Your subscription has ended</h2>
      <h2 v-else class="global-h2">You've used your paid creator visits</h2>
    </template>
    <template #content>
      <b>But we'll be happy to help!</b>
      <div style="margin-top: 7px">
        <b>Contact <CopyText :text="'collabs@shrpa.com'" /> to get the content you need.</b>
      </div>
      <!--<div>If you need multiple collaborations, just email us! <CopyText :text="'collabs@shrpa.com'"/> </div>
          <sui-checkbox style="margin-top:25px" label="I agree to these terms" v-model="userAgreesToPurchaseTerms" />
          <div style="margin-top:10px" v-if="isPurchasingNewCollab"><b>Adding...</b></div>
          <div style="color:red; margin-top:10px" v-if="showPurchaseTermsRequired"><b>Please agree to the terms before accepting.</b></div>-->
    </template>
    <template #footer>
      <!--<SrpButton color="orange" :isDisabled="isPurchasingNewCollab" @click="acceptPurchaseTerms()">Accept</sui-button>-->
      <SrpButton class="ui basic black button" :isDisabled="isPurchasingNewCollab" @click="cancelPurchase()">Ok</SrpButton>
    </template>
  </SrpModal>

  <!--Purchase Modal-->
  <SrpModal v-model:isVisible="showDateOverrideModal" :isClosable="true">
    <template #header>
      <h2 class="global-h2">Simulate a date for testing purposes</h2>
    </template>
    <template #content>
      <h6 class="global-h6" style="margin-bottom: 9px">Pick Simulated Date</h6>
      <VueDatePicker
        class="date-range-picker"
        style="max-width: 300px; margin-bottom: 8px"
        type="date"
        format="MMM d yyyy"
        v-model="currentDateLocal"
        auto-apply
        :enableTimePicker="false"
        :teleportCenter="['mobile'].includes(screenSize)"
      />
      {{ currentDateLocal }}
    </template>
    <template #footer>
      <SrpButton @click="showDateOverrideModal = false" color="gray" fill="outlined">Close</SrpButton>
    </template>
  </SrpModal>
</template>

<script lang="ts">
import AdminContext from "@logic/AdminContext";
import axios from "axios";
import CollabStatuses from "@logic/CollabStatuses";
import { defineComponent, inject } from "vue";
import { mapState } from "pinia";
import { RouteHelper } from "@helpers/RouteHelper";
import "@vuepic/vue-datepicker/dist/main.css";

// Types
import { CollabInput, CollabFeedback, CustomerCollabRemainingCounts } from "@contracts/collab";
import { ItinerarySummary } from "@contracts/itinerary";
import { Page } from "@contracts/pages";
import { ScreenSize } from "@contracts/screenSize";

// Components
import CollabFeedbackComponent from "./CollabFeedback.vue";
import CollabSummary from "./CollabSummary/index.vue";
import CommunityCorrections from "./CommunityCorrections/index.vue";
import CopyText from "@components/CopyText.vue";
import CreateCollabModal from "./CreateCollab/index.vue";
import CreatorInvites from "./CreatorInvites.vue";
import Loader from "@components/Loader/Loader.vue";
import Preview from "@components/Preview.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpDetailsSummary from "@components/ui/SrpDetailsSummary.vue";
import SrpFileThumbnail from "@components/ui/SrpFileThumbnail.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import VideoIntroSnippet from "@components/VideoIntroSnippet.vue";
import VueDatePicker from "@vuepic/vue-datepicker";

// Stores
import { useUserProfileStore } from "@stores/userProfileStore";
import PlannedCollabsRepo, { PlannedCollab } from "@repos/PlannedCollabsRepo";
import PlannedCollabsList from "./PlannedCollabs/PlannedCollabsList.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import { useHead } from "@unhead/vue";
import { MetricSender } from "@/helpers/MetricSender";

export default defineComponent({
  name: "PaidCollabCommunityAdmin",

  components: {
    IconEmbedded,
    CollabFeedbackComponent,
    CollabSummary,
    CommunityCorrections,
    CopyText,
    CreateCollabModal,
    CreatorInvites,
    Loader,
    PlannedCollabsList,
    Preview,
    SrpButton,
    SrpDetailsSummary,
    SrpFileThumbnail,
    SrpModal,
    VideoIntroSnippet,
    VueDatePicker,
  },

  data() {
    const plannedCollabForCreateModal: PlannedCollab | null = null;
    // Defaulting loading to true, otherwise the component shows for a brief moment, disappears while loading, and then reappears
    const plannedCollabsState: { loading: boolean; plannedCollabs: PlannedCollab[] } = { loading: true, plannedCollabs: [] };
    return {
      globalRemoteLogger: inject("globalRemoteLogger") as any,
      globalGetLink: inject("globalGetLink") as any,

      screenSize: inject("screenSize") as ScreenSize,

      title: "Creator Visits",
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      communityId: null,
      collabs: null as Array<CollabInput>,
      customerDetails: null as Page,
      collabRemainingCounts: null as CustomerCollabRemainingCounts | null,
      communityCreatorMatchSummary: null,
      selectedCollabIndex: 0,
      isLoading: true,
      reloadCollabCreatorInputs: 0, // A watch to force a reload of the collab creator inputs
      showCreateModal: false,
      plannedCollabForCreateModal,
      showDeleteModal: false,
      showDateOverrideModal: false,
      currentDateLocal: new Date(), // Used for testing
      showPurchaseModal: false,
      userAgreesToPurchaseTerms: false,
      showPurchaseTermsRequired: false,
      isPurchasingNewCollab: false,
      plannedCollabsState,
      superShowCollabInvites: false,

      collabSuggestionId: null as string | null,
      defaultTheme: null as string | null,
      version: 3, // Note: Just used for creating collabs, otherwise collab.version is used
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["isSuperOrSalesUser"]),
    isCreatorInvitesVisible(): boolean {
      if (this.superShowCollabInvites) return true;

      let status = this.selectedCollab?.collaboration?.status;
      return status && +status >= +CollabStatuses.CreatorsBeingSelectedStatusId && !this.selectedCollab.collaboration.creatorId;
    },
    hasACollabSubscription(): boolean {
      if (this.customerDetails === null || this.customerDetails.totalPaidCollabs === 0) {
        // No paid collabs
        return false;
      }
      return true;
    },
    selectedCollab: {
      get(): CollabInput | null {
        return this.collabs && this.collabs.length > 0 ? this.collabs[this.selectedCollabIndex] : null;
      },
      set(value: CollabInput) {
        if (!this.collabs) return;
        this.collabs[this.selectedCollabIndex] = value;
      },
    },
    canEditCollabInputs(): boolean {
      var collab = this.collabs[this.selectedCollabIndex];
      // If V1 always return true
      if (collab.version === 1) return false;
      // They can edit until a creator has accepted an invite
      return +collab.collaboration.status < +CollabStatuses.CreatorsAcceptedStatusId;
    },
    collabsRemaining(): number {
      if (!this.customerDetails) return -1;
      // Update Nov 2024: We now let the customer create as many collabs as they want but only post to creators
      // as many as they have paid for.
      const postedOrCompletedCollabs = this.collabs.filter(c => c.collaboration.completedDate || c.readyToPost).length;
      const remaining = this.customerDetails.totalPaidCollabs - postedOrCompletedCollabs;
      return remaining > 0 ? remaining : 0;
    },
    collabsTerm(): string {
      if (this.customerDetails && this.customerDetails.totalPaidCollabs - this.collabs.length === 1) return "Creator Visit";
      else return "Creator Visits";
    },
  },

  watch: {
    selectedCollabIndex: {
      handler(newVal, oldVal) {
        this.collabSelectionChanged(newVal);
      },
    },
  },

  created() {
    this.globalRoot.allCollabsComponent = this;
  },

  async mounted() {
    useHead({ title: () => this.title ?? "" });

    await this.checkForCollabSuggestionId();

    const versionQuery = RouteHelper.getQueryStringParam("version");
    if (versionQuery) {
      this.version = +versionQuery;
    }

    const id = this.$route.params.communityId as string;
    await this.loadCollabs(id);
    await this.loadCustomerDetails(id);
    this.isLoading = false; // We want both collabs and customer subscription details to load before we render
    await this.loadCommunityCreatorMatchSummary(id);
    await this.loadPlannedCollabs(id);

    MetricSender.sendMetric("Cust-ViewCollabs", null);
  },

  methods: {
    async checkForCollabSuggestionId() {
      const collabSuggestionId = RouteHelper.getHashParam("collabSuggestionId");
      const defaultTheme = RouteHelper.getHashParam("theme");

      if (collabSuggestionId) {
        this.collabSuggestionId = collabSuggestionId;
        if (defaultTheme) {
          this.defaultTheme = defaultTheme;
        }
        this.showCreateModal = true;

        await this.$nextTick();

        // removes all hash values to prevent the creation of another identical Collab on reload
        this.$router.replace({ ...this.$route, hash: "" });
      }
    },
    async loadCollabs(identifier) {
      const uri = `${import.meta.env.VITE_API_URL}/collabs/${identifier}`;
      const response = await axios.get(uri);
      this.collabs = response.data;
      // Setting the Id after collabs are set since we use it in v-if statements
      this.communityId = identifier;
      this.defaultCollab();
      this.defaultFeedback();
      this.loadCollabRemainingCounts(this.selectedCollab?.id);
    },
    defaultCollab() {
      const collabInputId = RouteHelper.getQueryStringOrHashParam("collab");
      if (collabInputId) {
        var index = this.collabs.findIndex(c => c.id === collabInputId);
        if (index >= 0) {
          this.selectedCollabIndex = index;
        }
      }
    },
    defaultFeedback() {
      // If it's completed but has not feedback, default the feedback which shows the section
      if (this.collabs[this.selectedCollabIndex] && this.collabs[this.selectedCollabIndex].collaboration.completedDate && !this.collabs[this.selectedCollabIndex].feedback) {
        // this.globalLog.info("Defaulting feedback for collab " + this.collabs[this.selectedCollabIndex].id);
        this.collabs[this.selectedCollabIndex].feedback = {
          photoRating: 0,
          writingRating: 0,
          creatorRating: 0,
          overallRating: 0,
          feedbackForCreator: null,
          feedbackForShrpa: null,
          submittedDate: null,
        } as CollabFeedback;
      }
    },
    async loadCustomerDetails(identifier) {
      // Since we're authenticated, passing isPublisFacing so we get the totalPaidCollabs field back
      const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/pages/${identifier}?isPublicFacing=false`);
      this.customerDetails = data.page;
      this.title += ` - ${this.customerDetails.title}`;

      this.setOrgInContext();
    },
    async loadCollabRemainingCounts(collabInputIdInContext: string | null) {
      let uri = `${import.meta.env.VITE_API_URL}/collabs/${this.communityId}/remaining-counts`;
      if (collabInputIdInContext) uri += `?collabInputIdInContext=${collabInputIdInContext}`;
      const response = await axios.get(uri);
      this.collabRemainingCounts = response.data;
      // this.globalLog.info(`loadCollabRemainingCounts: ${JSON.stringify(this.collabRemainingCounts)}`);
    },
    async loadCommunityCreatorMatchSummary(identifier) {
      const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/community/${identifier}/creator-match-summary?creatorExampleCount=3`);
      this.communityCreatorMatchSummary = data;
    },
    // Event from the CreateCollab wizard that a collab was created
    collabCreated(newCollab: CollabInput) {
      // Add to the front of the array
      this.collabs.unshift(newCollab);
      this.selectedCollabIndex = 0;

      this.loadCollabRemainingCounts(newCollab.id);
      this.updateSelectedPlannedCollab(newCollab.id);
    },
    collabUpdate(collab: CollabInput, index: number) {
      // Need to use this or Vue won't see the change
      this.collabs[index] = collab;
      if (collab.collaboration && collab.collaboration.completedDate) {
        // If the collab just completed we should default feedback so that section shows
        this.defaultFeedback();
      }
    },
    feedbackUpdate(feedback: CollabFeedback) {
      this.collabs[this.selectedCollabIndex].feedback = feedback;
    },
    cancelCreate() {
      this.plannedCollabForCreateModal = null;
      this.showCreateModal = false;
    },
    askToCreate() {
      this.showCreateModal = true;
    },
    askToBuyCollab() {
      this.globalRemoteLogger.info(`Customer OutOfCollabs: ${this.communityId}`, {
        customerId: this.communityId,
        collabIdInContext: this.selectedCollab?.id,
        collabNameInContext: this.selectedCollab?.name,
      });
      this.showPurchaseModal = true;
    },
    cancelPurchase() {
      this.showPurchaseModal = false;
    },
    async acceptPurchaseTerms() {
      // Retired now that we've moved to new packages
    },
    superShowInvites() {
      this.superShowCollabInvites = true;
    },
    currentCollabDeleted() {
      const indexToDelete = this.selectedCollabIndex;
      if (this.selectedCollabIndex !== 0) {
        this.selectedCollabIndex--;
      }
      this.collabs.splice(indexToDelete, 1);
    },
    getCollabNameText(collab: CollabInput): string {
      var name = this.fallbackIfNull(collab.name);
      if (collab.collaboration?.completedDate) {
        name = name + " - Completed!";
      } else if (!collab.readyToPost && !collab.postedToCreators) {
        name = name + " - Draft";
      }
      return name;
    },
    fallbackIfNull(value: string | null): string {
      if (!value || value.length === 0) return "New Creator Visit";
      return value;
    },
    collabSelectionChanged(newValue) {
      newValue = newValue?.target?.value;
      if (newValue >= 0) {
        const hashesToKeep = [];
        // Ideally this would be smart enough to just keep the rest of the hash
        if (location.hash?.includes("contactCreator=true")) hashesToKeep.push("contactCreator=true");
        if (location.hash?.includes("messages=true")) hashesToKeep.push("messages=true");

        history.replaceState(undefined, undefined, `#collab=${this.collabs[this.selectedCollabIndex].id}&${hashesToKeep.join(String.fromCharCode(38))}`);
      }
      this.currentDateLocal = new Date();
      this.loadCollabRemainingCounts(this.selectedCollab.id);
      this.defaultFeedback();
      // todo: Do an IsDirty check here in case they didn't save
    },
    // collabHasRequiredFieldsFilled(collab: CollabInput) {},
    async setOrgInContext() {
      this.globalRoot.orgInContext = AdminContext.setOrgInContextFromCustomer(this.customerDetails);
    },
    askToDelete() {
      this.showDeleteModal = true;
    },
    cancelDelete() {
      this.showDeleteModal = false;
    },
    async deleteCollab() {
      var currentCollab = this.collabs[this.selectedCollabIndex];
      const uri = `${import.meta.env.VITE_API_URL}/collabs/${this.communityId}/inputs/${currentCollab.id}`;
      await axios.delete(uri);
      this.currentCollabDeleted();
      this.showDeleteModal = false;
    },
    async loadPlannedCollabs(customerId: string) {
      this.plannedCollabsState.loading = true;
      this.plannedCollabsState.plannedCollabs = await PlannedCollabsRepo.fetchPlannedCollabs(customerId);
      this.plannedCollabsState.loading = false;
    },
    startCollabFromPlannedCollab(plannedCollab: PlannedCollab) {
      // If the name is still "Creator Visit 1/2/3", then clear it out
      if (
        plannedCollab.name?.startsWith("Creator Visit ") === true &&
        // @ts-ignore
        !isNaN(plannedCollab.name.trim().slice(-1))
      ) {
        plannedCollab.name = null;
      }
      this.plannedCollabForCreateModal = plannedCollab;
      this.showCreateModal = true;
    },
    async savePlannedCollabs(plannedCollabs: PlannedCollab[]) {
      this.plannedCollabsState.plannedCollabs = await PlannedCollabsRepo.updatePlannedCollabs(this.customerDetails.id, plannedCollabs);
    },

    async updateSelectedPlannedCollab(collabId: string) {
      if (!this.plannedCollabForCreateModal) return;
      const selectedPlannedCollab = this.plannedCollabsState.plannedCollabs.find(plannedCollab => plannedCollab.id === this.plannedCollabForCreateModal.id);
      if (!selectedPlannedCollab) return;

      selectedPlannedCollab.createdCollaborationId = collabId;
      this.savePlannedCollabs(this.plannedCollabsState.plannedCollabs);
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Choose a creator note content ==============================================
.choose-creator-note-content {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__text {
  }

  &__creator-avatars-row {
  }

  &__choose-creator-btn {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .choose-creator-note-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__text {
      margin: 0 0 10px 0;
    }

    &__creator-avatars-row {
      margin: 0 0 15px 0 !important;
    }

    &__choose-creator-btn {
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .choose-creator-note-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__text {
      margin: 0 0 10px 0;
    }

    &__creator-avatars-row {
      margin: 0 0 15px 0 !important;
    }

    &__choose-creator-btn {
    }
  }
}

// Creator avatars row ========================================================
.creator-avatars-row {
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;

  &__avatar-container {
    width: 39px;
    height: 39px;
    margin-right: 8px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    line-height: 14px;

    &--with-border {
      border: 1px rgba(0, 0, 0, 0.25) solid;
    }
  }

  &__avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__counter {
    width: 100%;
    height: 100%;
    padding-right: 2px;
    padding-bottom: 1px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// Stats list =================================================================
.stats-list {
  padding: 0;
  margin: 0;
  list-style: none;

  &__line {
  }

  &__number {
    color: black;
  }
}

// Header =====================================================================
.header {
  display: flex;
  justify-content: space-between;

  &__left-side {
    width: calc(100% - 275px);
    margin-right: $desktop-wide-grid-gap-width;
    display: flex;

    p {
      margin-bottom: 11px;
    }
  }

  &__video-snippet {
    margin-right: 21px;
  }

  &__description {
    padding-top: 3px;
  }

  &__right-side {
    width: calc($desktop-wide-grid-col-width * 3 + $desktop-wide-grid-gap-width * 2);
    min-width: calc($desktop-wide-grid-col-width * 3 + $desktop-wide-grid-gap-width * 2);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__setup-btn {
    margin-bottom: 9px;
  }

  &__stats-list {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .header {
    &__left-side {
      margin-right: $desktop-grid-gap-width;
    }

    &__right-side {
      width: calc($desktop-grid-col-width * 3 + $desktop-grid-gap-width * 2);
      min-width: calc($desktop-grid-col-width * 3 + $desktop-grid-gap-width * 2);
    }
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .header {
    &__left-side {
      margin-right: $laptop-grid-gap-width;
    }

    &__right-side {
      width: calc($laptop-grid-col-width * 3 + $laptop-grid-gap-width * 2);
      min-width: calc($laptop-grid-col-width * 3 + $laptop-grid-gap-width * 2);
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .header {
    &__left-side {
      margin-right: $tablet-large-grid-gap-width;
    }

    &__right-side {
      width: calc($tablet-large-grid-col-width * 3 + $tablet-large-grid-gap-width * 2);
      min-width: calc($tablet-large-grid-col-width * 3 + $tablet-large-grid-gap-width * 2);
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .header {
    padding-bottom: 22px;
    flex-direction: column-reverse;

    &__left-side {
      width: 100%;
      margin: 0;
    }

    &__right-side {
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__setup-btn {
      width: calc($tablet-grid-col-width * 4 + $tablet-grid-gap-width * 3);
      margin: 0 $tablet-grid-gap-width 0 0;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .header {
    padding-bottom: 18px;
    flex-direction: column-reverse;

    &__left-side {
      width: 100%;
      margin: 0;
    }

    &__right-side {
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
    }

    &__setup-btn {
      width: auto;
      margin: 0 0 10px 0;
    }
  }
}

// Collab select dropdown =====================================================
.collab-select-dropdown {
  height: 55px;
  box-sizing: border-box;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: 1;
    background: white;
    pointer-events: none;
    user-select: none;
  }

  &__overlay {
    width: 100%;
    height: 100%;
    padding: 0 16px 0;
    border: 3px #118689 solid;
    border-radius: 6px;
    box-sizing: border-box;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: 2;
    overflow: hidden;
    display: flex;
    align-items: center;
    line-height: 26px;
    font-weight: 600;
    background: white;
    pointer-events: none;
    user-select: none;
  }

  &__overlay-text {
    width: calc(100% - 20px);
    height: 100%;
    padding-top: 8px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__select-itself {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &__caret-icon {
    width: 26px;
    position: absolute;
    inset: 15px 10px auto auto;
    z-index: 2;
    pointer-events: none;
    user-select: none;

    :deep(svg) {
      fill: rgba(0, 0, 0, 1);
    }
  }

  &__select-itself:focus + &__caret-icon {
    top: 12px;
    transform: rotate(180deg);
  }
}

// Manage buttons panel =======================================================
.manage-buttons-panel {
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 0;

  &--stuck {
  }

  &::before {
    content: "";
    width: 100%;
    height: calc(100% + 30px);
    position: absolute;
    inset: auto auto 0 0;
    z-index: -1;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 0) 100%);
    pointer-events: none;
  }

  &__left-side {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  &__right-side {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__button {
    margin-right: 17px;

    &:last-child {
      margin-right: 0;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .manage-buttons-panel {
    &__button {
      margin-right: 11px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .manage-buttons-panel {
    flex-direction: column;
    align-items: flex-start;

    &--stuck {
      &::before {
        height: calc(100% + 130px);
        bottom: -100px;
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 85%, rgba(255, 255, 255, 0) 100%);
      }
    }

    &__left-side {
      margin-bottom: 11px;
    }

    &__right-side {
    }

    &__button {
      margin-right: 11px;
    }
  }
}

// Checklist/guidance/creator =================================================
.checklist-guidance-creator {
  padding-bottom: 23px;
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  display: flex;
  position: relative;

  &:last-child {
    padding-bottom: 23px;
    border-bottom: none;
  }

  &::before {
    content: "";
    width: calc($desktop-wide-grid-col-width * 3 + $desktop-wide-grid-gap-width * 3);
    height: calc(100% - 23px);
    box-sizing: border-box;
    border: 1px #e6e6e6 solid;
    border-width: 0 1px 0;
    position: absolute;
    inset: 0 auto auto calc(50% - ($desktop-wide-grid-col-width * 3 + $desktop-wide-grid-gap-width * 3) / 2);
    pointer-events: none;
    user-select: none;
  }

  &__column {
    padding-top: 4px;
    padding-bottom: 11px;
    box-sizing: border-box;
    position: relative;

    &--left {
      order: 0;
      width: calc($desktop-wide-grid-col-width * 3 + $desktop-wide-grid-gap-width * 2.5);
      padding-right: 22px;
    }
    &--center {
      order: 1;
      width: calc($desktop-wide-grid-col-width * 3 + $desktop-wide-grid-gap-width * 3);
      padding-right: 22px;
      padding-left: 22px;
    }
    &--right {
      order: 2;
      width: calc($desktop-wide-grid-col-width * 3 + $desktop-wide-grid-gap-width * 2.5);
      padding-left: 22px;
    }
  }

  &__title {
    margin-bottom: 12px;
    font-weight: 600;
  }

  &__visit-checklist {
  }

  &__visit-guidance {
  }

  &__selected-creator-section {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .checklist-guidance-creator {
    &::before {
      width: calc($desktop-grid-col-width * 3 + $desktop-grid-gap-width * 3);
      left: calc(50% - ($desktop-grid-col-width * 3 + $desktop-grid-gap-width * 3) / 2);
    }

    &__column {
      &--left {
        width: calc($desktop-grid-col-width * 3 + $desktop-grid-gap-width * 2.5);
        padding-right: 22px;
      }
      &--center {
        width: calc($desktop-grid-col-width * 3 + $desktop-grid-gap-width * 3);
        padding-right: 22px;
        padding-left: 22px;
      }
      &--right {
        width: calc($desktop-grid-col-width * 3 + $desktop-grid-gap-width * 2.5);
        padding-left: 22px;
      }
    }
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .checklist-guidance-creator {
    &::before {
      width: calc($laptop-grid-col-width * 3 + $laptop-grid-gap-width * 3);
      left: calc(50% - ($laptop-grid-col-width * 3 + $laptop-grid-gap-width * 3) / 2);
    }

    &__column {
      &--left {
        width: calc($laptop-grid-col-width * 3 + $laptop-grid-gap-width * 2.5);
        padding-right: 22px;
      }
      &--center {
        width: calc($laptop-grid-col-width * 3 + $laptop-grid-gap-width * 3);
        padding-right: 22px;
        padding-left: 22px;
      }
      &--right {
        width: calc($laptop-grid-col-width * 3 + $laptop-grid-gap-width * 2.5);
        padding-left: 22px;
      }
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .checklist-guidance-creator {
    padding-bottom: 0;
    display: grid;
    grid-template-areas:
      "steps guidance"
      "creator guidance";
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;

    &::before {
      width: 1px;
      border-width: 0 0 0 1px;
      left: 50%;
    }

    &__column {
      width: 100%;
      margin: 0 0 45px 0;
      display: table-cell;

      &--left {
        grid-template: "steps";
        grid-row-start: 1;
        grid-row-end: 2;
        padding: 0 22px 0 0;

        &::before {
          content: "";
          width: calc(100% - 22px);
          height: 0;
          border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
          position: absolute;
          inset: auto auto -20px 0;
        }
      }
      &--center {
        grid-template: "guidance";
        grid-row-start: 1;
        grid-row-end: 4;
        height: 100%;
        min-height: 100%;
        padding: 0 0 0 22px;
      }
      &--right {
        grid-template: "creator";
        grid-row-start: 2;
        grid-row-end: 3;
        padding: 0 22px 0 0;
      }
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .checklist-guidance-creator {
    padding-bottom: 0;
    display: grid;
    grid-template-areas:
      "steps guidance"
      "creator guidance";
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;

    &::before {
      width: 1px;
      border-width: 0 0 0 1px;
      left: 50%;
    }

    &__column {
      width: 100%;
      margin: 0 0 45px 0;
      display: table-cell;

      &--left {
        grid-template: "steps";
        grid-row-start: 1;
        grid-row-end: 2;
        padding: 0 22px 0 0;

        &::before {
          content: "";
          width: calc(100% - 22px);
          height: 0;
          border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
          position: absolute;
          inset: auto auto -20px 0;
        }
      }
      &--center {
        grid-template: "guidance";
        grid-row-start: 1;
        grid-row-end: 4;
        height: 100%;
        min-height: 100%;
        padding: 0 0 0 22px;
      }
      &--right {
        grid-template: "creator";
        grid-row-start: 2;
        grid-row-end: 3;
        padding: 0 22px 0 0;
      }
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .checklist-guidance-creator {
    flex-direction: column;

    &::before {
      display: none;
    }

    &__column {
      width: 100%;
      margin-bottom: 54px;

      &::before {
        content: "";
        width: 100%;
        height: 0;
        position: absolute;
        inset: auto auto -25px 0;
        border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
      }

      &--left {
        padding: 0;
      }
      &--center {
        padding: 0;
      }
      &--right {
        padding: 0;
        margin: 0;

        &::before {
          display: none;
        }
      }
    }
  }
}

// Spoiler section ============================================================
.spoiler-section {
  padding: 21px 0 11px;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px #ccd3d7 solid;
    border-radius: 6px;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: 5;
    pointer-events: none;
  }

  &__details-summary {
  }

  :deep(.srp-summary__title-wrap) {
    margin: 0 25px;
  }

  &__summary {
    padding-bottom: 15px;
    font-weight: 600;
  }

  &__content {
    padding: 20px 25px 0;
    margin-top: 7px;
    position: relative;

    &::before {
      content: "";
      width: calc(100% - 50px);
      height: 0;
      border-top: 1px rgba(0, 0, 0, 0.1) solid;
      position: absolute;
      inset: 0 auto auto 25px;
      font-size: 0;
      line-height: 0;
      pointer-events: none;
    }

    :deep(.srp-summary__title-wrap) {
      margin: 0;
    }
  }

  &__note {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__checklist-guidance-creator {
    margin-bottom: 23px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__creator-snippets-list {
    padding: 0;
    margin: 0 0 23px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
    display: flex;
    flex-wrap: wrap;
    list-style: none;

    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
  }

  &__creator-snippet {
    width: calc(50% - $desktop-wide-grid-gap-width / 2);
    margin: 0 $desktop-wide-grid-gap-width $desktop-wide-grid-gap-width 0;
    position: relative;
    z-index: 0;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  &__manage-buttons-panel {
    position: sticky;
    inset: auto auto 0 0;
    z-index: 2;
    transition: inset 0.07s ease-in-out;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .spoiler-section {
    &__creator-snippet {
      width: calc(50% - $desktop-grid-gap-width / 2);
      margin: 0 $desktop-grid-gap-width $desktop-grid-gap-width 0;
    }
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .spoiler-section {
    &__creator-snippet {
      width: calc(50% - $laptop-grid-gap-width / 2);
      margin: 0 $laptop-grid-gap-width $laptop-grid-gap-width 0;
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .spoiler-section {
    &__creator-snippet {
      width: calc(50% - $tablet-large-grid-gap-width / 2);
      margin: 0 $tablet-large-grid-gap-width $tablet-large-grid-gap-width 0;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .spoiler-section {
    &__creator-snippet {
      width: 100%;
      margin: 0 0 $tablet-grid-gap-width 0;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .spoiler-section {
    padding: 17px 0 11px;

    &__summary {
      padding-bottom: 11px;
    }

    :deep(.srp-summary__title-wrap) {
      margin: 0 15px;
    }

    &__content {
      padding: 20px 15px 0;
      margin-top: 7px;

      &::before {
        content: "";
        width: calc(100% - 29px);
        inset: 0 auto auto 13px;
      }
    }

    &__creator-snippets-list {
    }

    &__creator-snippet {
      width: 100%;
      margin: 0 0 17px 0 !important;
    }

    &__manage-buttons-panel {
      &--stuck {
        bottom: 125px;
      }
    }
  }
}

// Creator visits page ========================================================
.creator-visits-page {
  width: $desktop-wide-right-col-width;
  margin: 0 auto;
  color: #5b5b5b;
  font: 14px/19px sans-serif;

  p {
    color: #5b5b5b;
    font: 14px/19px sans-serif;
  }

  &__h1 {
    margin-bottom: 27px;
    margin-left: -25px;
  }

  &__header {
    margin-bottom: 28px;
  }

  &__collab-select-dropdown {
    width: calc($desktop-wide-grid-col-width * 6 + $desktop-wide-grid-gap-width * 5);
  }

  &__spoiler-section {
    width: calc(100% + 50px);
    margin: 0 0 24px -25px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__admin-buttons-section {
    width: calc(100% + 50px);
    padding: 0 25px;
    margin: 0 0 24px -25px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .creator-visits-page {
    width: $desktop-right-col-width;

    &__collab-select-dropdown {
      width: calc($desktop-grid-col-width * 6 + $desktop-grid-gap-width * 5);
    }
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .creator-visits-page {
    width: $laptop-right-col-width;

    &__collab-select-dropdown {
      width: calc($laptop-grid-col-width * 6 + $laptop-grid-gap-width * 5);
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .creator-visits-page {
    width: $tablet-large-content-width;

    &__collab-select-dropdown {
      width: calc($tablet-large-grid-col-width * 6 + $tablet-large-grid-gap-width * 5);
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .creator-visits-page {
    width: $tablet-content-width;

    &__h1 {
      margin-bottom: 22px;
    }

    &__header {
      margin-bottom: 23px;
    }

    &__collab-select-dropdown {
      width: $tablet-content-width;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .creator-visits-page {
    width: $mobile-content-width;

    &__h1 {
      margin-bottom: 20px;
    }

    &__header {
      margin-bottom: 20px;
    }

    &__planned-visits-spoiler {
      width: calc(100% + 30px);
      padding: 20px 15px 15px;
      margin-left: -15px;
    }

    &__collab-select-dropdown {
      width: 100%;
    }

    &__spoiler-section {
      width: calc(100% + 30px);
      margin: 0 0 22px -15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__admin-buttons-section {
      width: calc(100% + 30px);
      padding: 0 15px;
      margin: 0 0 22px -15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
