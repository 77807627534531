const MinDescriptionLength = 100;
export const MinStopDetailsChars = 100;

export function isDescriptionInvalid(userInput: string): boolean {
  const descriptionLength = userInput?.trim().length ?? 0;
  return descriptionLength < MinDescriptionLength;
}

export function getDetailsLengthMessage(details: string | null): string {
  const len = details?.trim().length ?? 0;
  if (len === 0) return "enter at least " + MinStopDetailsChars + " characters";
  else if (len >= MinStopDetailsChars) return "";
  else return MinStopDetailsChars - len + " more...";
}
