<template>
  <div class="ui form">
    <div class="field">
      <div class="global-h4">Gear Needed</div>
      <p class="cms">(Optional) What gear must the creator bring (that isn't supplied for them)?</p>
      <textarea type="text" ref="gearInput" v-model="collabInput.gearToBring" style="height:60px;"></textarea>
    </div>
    <div class="field">
      <div class="global-h4">Reference Material</div>
      <p class="cms">(Optional) Websites or reference material our creators should review before coming to town.</p>
      <textarea type="text" maxlength="1000" v-model="collabInput.referenceMaterial"></textarea>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// Types
import { CollabInput } from "@contracts/collab";

export default defineComponent({
  name: "StepGearNMaterial",

  components: {},

  props: {
    collabInput: { type: Object as () => CollabInput | null, required: true },
  },

  data() {
    return {};
  },

  mounted() {
    this.$nextTick(() => {
      (this.$refs.gearInput as HTMLInputElement)?.focus();
    });
  },
});
</script>

<style scoped lang="scss"></style>
