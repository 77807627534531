<template>
  <div class="horizontal-step-carousel" ref="domRefRoot">
    <div v-if="scrollPosition !== 0" class="horizontal-step-carousel__arrow horizontal-step-carousel__arrow--left" @click="switchToPrevStep">
      <IconEmbedded name="arrow-left_3" color="rgba(0, 0, 0, 0.4)" />
    </div>

    <div v-if="scrollPosition < maxScrollPosition" class="horizontal-step-carousel__arrow horizontal-step-carousel__arrow--right" @click="switchToNextStep">
      <IconEmbedded name="arrow-right_3" color="rgba(0, 0, 0, 0.4)" />
    </div>

    <div class="horizontal-step-carousel__slides-row-wrap1">
      <div class="horizontal-step-carousel__slides-row-wrap2" ref="domRefRowContainerWrap">
        <div
          class="horizontal-step-carousel__slides-row"
          :style="{
            transform: `translateX(-${scrollPosition}px)`,
            transitionDuration: props.transitionDuration + 'ms',
          }"
        >
          <slot :itemWithinViewportIndexes="itemWithinViewportIndexes" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, Ref, inject, watch, computed, onMounted, nextTick, onBeforeUnmount } from "vue";

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";

// Types
import { ScreenSize } from "@contracts/screenSize";

// Composables
import useSwipes from "@composables/useSwipes";

// Global variables
const screenSize = inject<Ref<ScreenSize>>("screenSize");

const props = withDefaults(
  defineProps<{
    itemWrapClassName: string; // to get item's boundingClientRect + gap between items
    itemClassName: string; // to get item's boundingClientRect and define is it fully visible within row container's viewport
    stepSize?: number; // how many items to scroll in 1 click/swipe
    transitionDuration?: number;
  }>(),
  {
    itemWrapClassName: "",
    itemClassName: "",
    stepSize: 1,
    transitionDuration: 150,
  }
);

// DOM Refs ===================================================================
const domRefRoot = ref<HTMLElement | null>(null);
const domRefRowContainerWrap = ref<HTMLElement | null>(null);

// Apply swipes ===============================================================
let _removeTouchListeners: Function = () => {};

onMounted(async () => {
  const { addTouchListeners, removeTouchListeners } = useSwipes(domRefRoot.value, switchToPrevStep, switchToNextStep, false);

  _removeTouchListeners = removeTouchListeners;

  await nextTick();
  addTouchListeners();
});

onBeforeUnmount(_removeTouchListeners);

// Track the scroll position ==================================================
const scrollPosition = ref<number>(0);

// Calc rects =================================================================
const rowContainerWrapRect = ref<any>(null);
const allItemWrapRects = ref<Array<any>>(null);
const allItemRects = ref<Array<any>>(null);

function calcRects(): void {
  rowContainerWrapRect.value = domRefRowContainerWrap.value.getBoundingClientRect();

  const allItemWraps = domRefRowContainerWrap.value.querySelectorAll(`.${props.itemWrapClassName}`);
  allItemWrapRects.value = [...allItemWraps].map(i => i.getBoundingClientRect());

  const allItems = domRefRowContainerWrap.value.querySelectorAll(`.${props.itemClassName}`);
  allItemRects.value = [...allItems].map(i => i.getBoundingClientRect());
}

// Item within viewport indexes ===============================================
const itemWithinViewportIndexes = ref<Array<number>>([]);
const lowestIndexInViewport = computed<number>(() => itemWithinViewportIndexes.value[0]);
const highestIndexInViewport = computed<number>(() => itemWithinViewportIndexes.value.slice(-1)[0]);

function calcItemWithinViewportIndexes(): void {
  const threshold = 10;

  const _itemWithinViewportIndexes = allItemRects.value.reduce((acc, itemRect, index) => {
    const isFullyVisible =
      Math.round(itemRect.left) >= Math.round(rowContainerWrapRect.value.left - threshold) && Math.round(itemRect.right) <= Math.round(rowContainerWrapRect.value.right + threshold);

    if (isFullyVisible) {
      return [...acc, index];
    } else {
      return acc;
    }
  }, []);

  itemWithinViewportIndexes.value = _itemWithinViewportIndexes;
}

// Get full widths ============================================================
const allItemsWidth = computed<number>(() => allItemWrapRects.value?.reduce((acc, itemRect, index) => acc + (itemRect?.width || 0), 0));
const containerWrapWidth = computed<number>(() => rowContainerWrapRect.value?.width);
const maxScrollPosition = computed<number>(() => Math.round((allItemsWidth.value || 0) - (containerWrapWidth.value || 0)));

// Calc items indent ==========================================================
const itemsIndent = ref<number>(0); // compensates right scrolling position

function getItemsIndent() {
  itemsIndent.value = allItemWrapRects.value?.[0]?.width - allItemRects.value?.[0]?.width;
}

// Calc rects and indexes within viewport =====================================
onMounted(async () => {
  await nextTick();

  calcRects();
  calcItemWithinViewportIndexes();
  getItemsIndent();
});

// Handle step switching ======================================================
async function switchToPrevStep() {
  calcRects();
  calcItemWithinViewportIndexes();
  getItemsIndent();

  const newLowestIndexInViewport = lowestIndexInViewport.value - props.stepSize < 0 ? 0 : lowestIndexInViewport.value - props.stepSize;

  let newScrollPosition = 0;

  if (newLowestIndexInViewport === 0) {
    newScrollPosition = 0;
  } else {
    newScrollPosition = allItemWrapRects.value.reduce((acc, itemRect, index) => {
      return index < newLowestIndexInViewport ? acc + itemRect.width : acc;
    }, 0);
  }

  scrollPosition.value = Math.round(newScrollPosition);

  await new Promise(resolve => setTimeout(resolve, props.transitionDuration));
  calcRects();
  calcItemWithinViewportIndexes();
  getItemsIndent();
}

async function switchToNextStep() {
  calcRects();
  calcItemWithinViewportIndexes();
  getItemsIndent();

  const lastIndex = allItemWrapRects.value.length - 1;
  const newHighestIndexInViewport = highestIndexInViewport.value + props.stepSize > lastIndex ? lastIndex : highestIndexInViewport.value + props.stepSize;

  const newScrollPosition =
    allItemWrapRects.value.reduce((acc, itemRect, index) => {
      return index <= newHighestIndexInViewport ? acc + itemRect.width : acc;
    }, 0) - containerWrapWidth.value;

  scrollPosition.value = Math.round(newScrollPosition > maxScrollPosition.value ? maxScrollPosition.value : newScrollPosition) - (newHighestIndexInViewport === lastIndex ? 0 : itemsIndent.value);

  await new Promise(resolve => setTimeout(resolve, props.transitionDuration));
  calcRects();
  calcItemWithinViewportIndexes();
  getItemsIndent();
}

// Reset all params on the screenSize change ==================================
watch(screenSize, async () => {
  scrollPosition.value = 0;

  await new Promise(resolve => setTimeout(resolve, props.transitionDuration));
  calcRects();
  calcItemWithinViewportIndexes();
  getItemsIndent();
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Horizontal step carousel ===================================================
.horizontal-step-carousel {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 0;

  &__arrow {
    width: 45px;
    height: 45px;
    border: 1px rgba(218, 218, 218, 1) solid;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: calc(50% - 25px) auto auto auto;
    z-index: 2;
    cursor: pointer;
    user-select: none;

    &:hover {
      background: rgba(0, 0, 0, 0.03);
    }

    &--left {
      left: -100px;
    }
    &--right {
      right: -100px;
    }
  }

  &__slides-row-wrap1 {
    width: calc(100% + $desktop-wide-grid-gap-width * 2);
    min-width: calc(100% + $desktop-wide-grid-gap-width * 2);
    mask-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 4%, rgba(0, 0, 0, 1) 96%, rgba(0, 0, 0, 0) 100%);
    //background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 3%, rgba(0, 0, 0, 1) 97%, rgba(0, 0, 0, 0) 100%);
  }

  &__slides-row-wrap2 {
    width: calc(100% - $desktop-wide-grid-gap-width * 2);
    margin: 0 auto;
  }

  &__slides-row {
    width: 100%;
    transition: transform 0.15s ease-in-out;

    :deep(> *) {
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .horizontal-step-carousel {
    &__arrow {
      &--left {
        left: -90px;
      }
      &--right {
        right: -90px;
      }
    }
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .horizontal-step-carousel {
    &__arrow {
      &--left {
        left: -75px;
      }
      &--right {
        right: -75px;
      }
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .horizontal-step-carousel {
    &__arrow {
      &--left {
        left: -75px;
      }
      &--right {
        right: -75px;
      }
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .horizontal-step-carousel {
    &__arrow {
      &--left {
        left: -60px;
      }
      &--right {
        right: -60px;
      }
    }

    &__slides-row-wrap1 {
      mask-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 7%, rgba(0, 0, 0, 1) 93%, rgba(0, 0, 0, 0) 100%);
      //background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 7%, rgba(0, 0, 0, 1) 93%, rgba(0, 0, 0, 0) 100%);
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .horizontal-step-carousel {
    &__arrow {
      border: none;
      background: rgba(150, 150, 150, 0.2);
      backdrop-filter: blur(15px);

      &--left {
        border-radius: 0 100px 100px 0;
        left: -30px;
      }
      &--right {
        border-radius: 100px 0 0 100px;
        right: -30px;
      }
    }

    &__slides-row-wrap1 {
      width: calc(100% + $mobile-grid-gap-width * 2);
      min-width: calc(100% + $mobile-grid-gap-width * 2);
      mask-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%);
      //background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%);
    }

    &__slides-row-wrap2 {
      width: calc(100% - $mobile-grid-gap-width * 2);
    }
  }
}
</style>
