<template>
  <li class="summary-section">
    <div v-if="props.summarySection.page || props.summarySection.creator" class="summary-section__community-n-creator-side">
      <!-- Title & logo snippet -->
      <RouterLink
        v-if="props.isPageAvatarVisible && props.summarySection.page"
        :to="{ name: 'Pages', params: { pageId: props.summarySection.page?.id } }"
        class="title-n-logo-snippet summary-section__community-snippet"
      >
        <!--<div class="global-h2 title-n-logo-snippet__title">&lt;!&ndash;{{ props.summarySection.page?.title }}&ndash;&gt;&nbsp;</div>-->
        <div class="title-n-logo-snippet__logo-wrap">
          <img
            class="title-n-logo-snippet__logo title-n-logo-snippet__logo--bigger"
            :src="`${contentBaseUri}/cms/images/orgs/${props.summarySection.page?.icon}`"
            :alt="props.summarySection.page?.title"
          />
        </div>
      </RouterLink>
      <!-- / Title & logo snippet -->

      <IconEmbedded v-if="props.isPageAvatarVisible && props.isCreatorAvatarVisible" class="summary-section__plus-sign" name="plus_3" :size="66" />

      <!-- Title & logo snippet -->
      <RouterLink
        v-if="props.isCreatorAvatarVisible && props.summarySection.creator"
        :to="{ name: 'CreatorProfilePublic', params: { creatorId: props.summarySection.creator?.sherpaId } }"
        class="title-n-logo-snippet summary-section__creator-snippet"
      >
        <div class="global-h2 title-n-logo-snippet__title">{{ props.summarySection.creator?.firstName }}</div>
        <div class="title-n-logo-snippet__logo-wrap">
          <CreatorAvatar class="title-n-logo-snippet__logo" :imageUri="props.summarySection.creator?.profileImageUri" :isWithPlusIcon="props.summarySection.creator?.isCollabEligible" />
        </div>
      </RouterLink>
      <!-- / Title & logo snippet -->
    </div>

    <div class="summary-section__itineraries-side">
      <div class="global-h2 summary-section__itineraries-title">
        <RouterLink
          :to="{
            name: 'CollabSummaryPublic',
            params: {
              communityId: props.summarySection.page?.id,
              collabInputId: props.summarySection.collabDetails.collabInputId,
            },
          }"
        >
          {{ props.summarySection.collabDetails.collabName }}
        </RouterLink>

        <div v-if="props.isVisitDateVisible && props.summarySection.collabDetails.visitDate" class="summary-section__itineraries-title-date">
          {{ DateUtils.formatDateRange(props.summarySection.collabDetails.visitDate, 0, true, "MMM YYYY") }}
        </div>
      </div>

      <HighlightsSection
        v-if="props.summarySection.collabDetails?.highlightAssetIds?.length"
        class="summary-section__highlights-section"
        :highlightAssetIds="props.summarySection.collabDetails?.highlightAssetIds"
      />

      <ul v-else class="summary-section__itineraries-list">
        <ItineraryTile
          class="summary-section__itinerary"
          v-for="itinerary in props.summarySection.itineraries.slice(0, 4)"
          :itinerary="itinerary"
          :key="itinerary.id"
          @shareItinerary="() => {}"
          :allowShortImage="false"
          :source="null"
        />
      </ul>

      <div v-if="props.summarySection.collabDetails?.locationCount > 0 || props.summarySection.collabDetails?.photoCount > 0" class="summary-section__stats-section">
        <!-- Stats cell -->
        <div class="stats-cell summary-section__stats-cell">
          <div class="stats-cell__number">{{ props.summarySection.collabDetails?.locationCount }}</div>
          <div class="stats-cell__text">Locations</div>
        </div>
        <!-- / Stats cell -->

        <!-- Stats cell -->
        <div class="stats-cell summary-section__stats-cell">
          <div class="stats-cell__number">{{ props.summarySection.collabDetails?.photoCount }}</div>
          <div class="stats-cell__text">Photos</div>
        </div>
        <!-- / Stats cell -->

        <!-- Stats cell -->
        <div v-if="props.summarySection.collabDetails?.videoCount > 1" class="stats-cell summary-section__stats-cell">
          <div class="stats-cell__number">{{ props.summarySection.collabDetails?.videoCount }}</div>
          <div class="stats-cell__text">Videos</div>
        </div>
        <!-- / Stats cell -->
      </div>
    </div>
  </li>
</template>

<script setup lang="ts">
// Components
import CreatorAvatar from "@components/CreatorAvatar.vue";
import HighlightsSection from "@components/HighlightsSection.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import ItineraryTile from "@components/ItineraryTile.vue";

// Types
import { CollabSummaryModel } from "@contracts/collabCredits";
import DateUtils from "@logic/DateUtils";

// Global variables
const contentBaseUri = globalThis.Bootstrap.Config.contentCdnBaseUri;

const props = withDefaults(
  defineProps<{
    summarySection: CollabSummaryModel;
    isVisitDateVisible?: boolean;
    isPageAvatarVisible?: boolean;
    isCreatorAvatarVisible?: boolean;
  }>(),
  {
    summarySection: null,
    isVisitDateVisible: true,
    isPageAvatarVisible: true,
    isCreatorAvatarVisible: true,
  }
);
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Title & logo snippet =======================================================
.title-n-logo-snippet {
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 1;
  transition: opacity 0.07s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &__title {
    margin-bottom: 10px;
    text-align: center;
    word-break: keep-all;
  }

  &__logo-wrap {
    width: 140px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  &__logo {
    width: 100%;
    height: 100%;
    object-fit: contain;

    &--bigger {
      transform: scale(1.1);
    }
  }
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .title-n-logo-snippet {
    &__logo-wrap {
      width: 100px;
      height: 100px;
    }
  }
}

// Stats cell =================================================================
.stats-cell {
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &::after {
    content: "";
    width: 1px;
    height: calc(100% - 26px);
    position: absolute;
    inset: 13px -1px auto auto;
    background: rgba(0, 0, 0, 0.1);
  }

  &__number {
    margin-right: 23px;
    color: #118689;
    font:
      500 40px/40px "Quicksand",
      sans-serif;
  }

  &__text {
    font:
      400 20px/23px "Quicksand",
      sans-serif;
  }
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .stats-cell {
    &__number {
      font-size: 40px;
      line-height: 40px;
    }
    &__text {
      font-size: 20px;
      line-height: 23px;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .stats-cell {
    &__number {
      font-size: 30px;
      line-height: 30px;
    }
    &__text {
      font-size: 20px;
      line-height: 23px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .stats-cell {
    &__number {
      margin-right: 15px;
      font-size: 30px;
      line-height: 30px;
    }
    &__text {
      font-size: 17px;
      line-height: 21px;
    }
  }
}

// Summary section ============================================================
.summary-section {
  display: flex;

  &--no-bottom-border {
    padding-bottom: 0;
    border-bottom: none;
  }

  &__community-n-creator-side {
    align-self: stretch;
    width: 297px;
    min-width: 297px;
    margin-right: 27px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__community-snippet {
    margin-bottom: 10px;
  }

  &__plus-sign {
    width: 66px;
    height: 66px;
    margin-bottom: 10px;

    :deep(svg) {
      fill: #118689;
    }
  }

  &__creator-snippet {
    outline: none;
  }

  &__itineraries-side {
    flex-grow: 1;
  }

  &__itineraries-title {
    gap: 10px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 400;
    text-align: center;

    a {
      color: rgba(5, 133, 135, 1);
      text-decoration: underline;
      text-decoration-color: rgba(5, 133, 135, 0.2);
      text-underline-offset: 3px;
      text-decoration-thickness: 1px;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__itineraries-title-date {
    color: rgba(0, 0, 0, 0.7);
    font:
      600 14px/14px "Quicksand",
      sans-serif;
  }

  &__highlights-section {
    width: calc(100% + 5px);
  }

  &__itineraries-list {
    padding: 0;
    margin: 0;
    display: flex;
    list-style: none;
    align-items: stretch;
  }

  &__itinerary {
    width: calc(25% - 20px);
    margin: 0 27px 0 0;

    &:last-child {
      margin-right: 0;
    }
  }

  &__stats-section {
    width: 100%;
    margin-top: 30px;
    padding: 5px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    z-index: 0;
    overflow: hidden;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 6px;
      position: absolute;
      inset: 0 auto auto 50%;
      z-index: -1;
      transform: translateX(-50%);
      background: #f3f3f3;
    }
  }

  &__stats-cell {
    width: 33%;

    &:last-child {
      &::after {
        display: none;
      }
    }
  }
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .summary-section {
    &__community-n-creator-side {
      width: 258px;
      min-width: 258px;
      margin-right: 24px;
    }

    &__itinerary {
      width: calc(25% - 18px);
      margin-right: 24px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .summary-section {
    flex-direction: column;
    align-items: center;

    &__community-n-creator-side {
      width: 100%;
      margin: 0 0 40px 0;
      flex-direction: row;
      justify-content: center;
    }

    &__community-snippet {
      width: 32%;
      margin-bottom: 0;
    }

    &__plus-sign {
      margin: 20px 20px 0;
    }

    &__creator-snippet {
      width: 32%;
    }

    &__itineraries-side {
      width: 100%;
    }

    &__itineraries-title {
      margin-bottom: 38px;
    }

    &__itinerary {
      width: calc(25% - 16px);
      margin-right: 22px;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .summary-section {
    flex-direction: column;
    align-items: center;

    &__community-n-creator-side {
      width: 100%;
      margin: 0 0 40px 0;
      flex-direction: row;
      justify-content: center;
    }

    &__community-snippet {
      width: 32%;
      margin-bottom: 0;
    }

    &__plus-sign {
      margin: 20px 20px 0;
    }

    &__creator-snippet {
      width: 32%;
    }

    &__itineraries-side {
      width: 100%;
    }

    &__itineraries-title {
      margin-bottom: 28px;
    }

    &__itineraries-list {
      flex-wrap: wrap;
      justify-content: center;
    }

    &__itinerary {
      width: calc(33% - 11px);
      min-width: calc(33% - 11px);
      margin: 0 18px 20px 0;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    &__stats-section {
      padding: 5px 0 8px;
    }

    &__stats-cell {
      width: 50%;
      margin-bottom: 0;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .summary-section {
    padding-bottom: 30px;
    flex-direction: column;
    align-items: center;

    &__community-n-creator-side {
      width: 100%;
      margin: 0 0 30px 0;
      flex-direction: row;
      justify-content: center;
    }

    &__community-snippet {
      width: 32%;
      margin-bottom: 0;
    }

    &__plus-sign {
      width: 46px;
      height: 46px;
      margin: 20px 20px 0;
      font-size: 46px;
      line-height: 46px;
    }

    &__creator-snippet {
      width: 32%;
    }

    &__itineraries-side {
      width: 100%;
    }

    &__itineraries-title {
      margin-bottom: 18px;
    }

    &__itineraries-list {
      flex-wrap: wrap;
      justify-content: center;
    }

    &__itinerary {
      width: calc(50% - 9px);
      min-width: calc(50% - 9px);
      margin: 0 18px 20px 0;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    &__stats-section {
      padding: 5px 0 8px;
    }

    &__stats-cell {
      width: 50%;
      margin-bottom: 0;
    }
  }
}
</style>
