<template>
  <div class="referrals-page">
    <div class="referrals-page__header">
      <h1 class="global-h1 referrals-page__h1">Referrals</h1>
    </div>

    <ReferralsCodeAndLink class="referrals-page__referrals-code-and-link" />

    <template v-if="referralUsersList?.length">
      <div class="referrals-page__divider"></div>

      <h2 class="referrals-page__h2">My Referrals</h2>

      <ReferralsTable class="referrals-page__referrals-table" :referralsList="referralUsersList" />
    </template>
  </div>
</template>

<!-- prettier-ignore -->
<script lang="ts">export default { name: "ReferralsPage" };</script>

<script setup lang="ts">
import axios from "axios";
import { getGlobalRemoteLogger } from "@helpers/RemoteLogger";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";

// Components
import ReferralsCodeAndLink from "./ReferralsCodeAndLink.vue";
import ReferralsTable from "./ReferralsTable.vue";

// Types
import { ReferralUsers } from "@contracts/referrals";

const route = useRoute();

// Get referral users list ====================================================
const referralUsersList = ref<Array<ReferralUsers>>(null);
onMounted(getReferralUsersList);

async function getReferralUsersList() {
  let uri = `${import.meta.env.VITE_API_URL}/referrals/${route.params.creatorId}`;

  const { data } = await axios.get<{ referralUsers: Array<ReferralUsers> }>(uri);
  referralUsersList.value = data.referralUsers;
}
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Referrals page =============================================================
.referrals-page {
  width: $desktop-wide-right-col-width;
  margin: 0 auto;

  &__header {
    margin-bottom: 30px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__h1 {
    margin-bottom: 20px;
  }

  &__h2 {
    margin-bottom: 15px;
  }

  &__referrals-code-and-link {
    margin-bottom: 30px;
  }

  &__divider {
    margin-bottom: 40px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__referrals-table {
    width: 100%;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .referrals-page {
    width: $desktop-right-col-width;
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .referrals-page {
    width: $laptop-right-col-width;
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .referrals-page {
    width: $tablet-large-content-width;
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .referrals-page {
    width: $tablet-content-width;
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .referrals-page {
    width: $mobile-content-width;
  }
}
</style>
