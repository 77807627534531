<template>
  <li
    :class="{
      'highlight-snippet': true,
      'highlight-snippet--clickable': data.isClickable,
    }"
    @click="
      () => {
        if (data.onClickHandler) data.onClickHandler();
      }
    "
  >
    <RouterLink v-if="data.routerLinkTo" class="highlight-snippet__router-link-overlay" :to="data.routerLinkTo" />

    <div class="highlight-snippet__icon-n-number">
      <img class="highlight-snippet__icon" :src="`/images/highlightsIllustrations/${data.iconName}.svg`" :alt="data.iconName" />
      <div class="highlight-snippet__number">{{ data.number }}</div>
    </div>
    <div
      :class="{
        'highlight-snippet__name': true,
        'highlight-snippet__name--clickable': data.isClickable,
      }"
    >
      {{ data.name }}
    </div>
  </li>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// Types
import { Highlight } from "./HighlightsList.vue";

export default defineComponent({
  name: "HighlightSnippet",

  props: {
    data: { type: Object as () => Highlight, required: true },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Highlight snippet ==========================================================
.highlight-snippet {
  padding: 18px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  color: #000;
  cursor: default;
  user-select: none;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px rgba(0, 0, 0, 0.1) solid;
    position: absolute;
    inset: 0 auto auto 0;
    pointer-events: none;
  }

  &--clickable {
    cursor: pointer;

    &::before {
      border-color: #83b2b4;
      transition:
        width 0.09s ease-in-out,
        height 0.09s ease-in-out,
        inset 0.09s ease-in-out;
    }

    &:hover::before {
      width: calc(100% + 4px);
      inset: 0 auto auto -2px;
    }
  }

  &__router-link-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: 3;
    opacity: 0;
  }

  &__icon-n-number {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    height: 40px;
    min-height: 40px;
    margin-right: 6px;
  }

  &__number {
    font:
      500 33px/33px "Quicksand",
      sans-serif;
  }

  &__name {
    font:
      600 14px/14px "Quicksand",
      sans-serif;

    &--clickable {
      color: #0c878a;
      text-decoration: underline;
    }
  }

  &:hover &__name {
    text-decoration: none;
  }
}

// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop =============================
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large =======================
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet =============================
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile =============================
@media (max-width: $mobile-max-width) {
}
</style>
