<template>
  <div class="slide-about-exp">
    <ul class="slide-about-exp__guide-checklist">
      <!-- Guide checklist item -->
      <li class="guide-checklist-item slide-about-exp__guide-checklist-item" v-for="item in guideCheckListItems" :key="item">
        <IconEmbedded class="guide-checklist-item__check-icon" name="check_4" :size="19" />
        <div class="guide-checklist-item__text">{{ item }}</div>
      </li>
      <!-- / Guide checklist item -->
    </ul>

    <!-- Adventures list -->
    <div class="adventures-list slide-about-exp__adventures-list">
      <h5 class="global-h5 adventures-list__title">The adventures you create will be shared directly on the destination's website</h5>
      <ul class="adventures-list__list-itself">
        <ItineraryTile
          class="adventures-list__adventure-tile"
          v-for="(itinerary, index) in collabsList"
          :key="index"
          :itinerary="itinerary"
          @shareItinerary="() => {}"
          :allowShortImage="false"
          :source="null"
        />
      </ul>
    </div>
    <!-- / Adventures list -->

    <NoteWithIcon class="slide-about-exp__pro-tip" color="green">
      <template #icon><IconEmbedded name="info-simple_4" /></template>
      <template #text>
        <div>
          <h5 class="global-h5" style="margin-bottom: 4px">Pro tip!</h5>
          <span>Send the destination your preplanned itinerary before your visit! Communication is key when working with destinations.</span>
        </div>
      </template>
    </NoteWithIcon>

    <SrpDetailsSummary class="slide-about-exp__hint-spoiler">
      <template #heading>
        <h4 class="global-h4 spoiler-section__summary">
          <span
            ><span>How am I supposed to visit {{ props.requestedLocationCount }} unique locations?</span></span
          >
        </h4>
      </template>
      <template #details>
        <div class="slide-about-exp__hint-spoiler-content">
          <p style="padding: 0; margin: 0 0 10px 0; font: 14px/18px sans-serif">
            If your collaboration is focused around an event, or wants to highlight a location that might take a whole day to explore - we want you to focus more on the event, rather than going to a
            bunch of locations!
          </p>
          <p style="padding: 0; margin: 0 0 10px 0; font: 14px/18px sans-serif">
            One way to meet the deliverables required is to feature specific aspects of the event. Talk about the food options, live music, arts, shopping, activities, etc, as individual stops in an
            adventure.
          </p>

          <p style="padding: 0; margin: 0; font: 14px/18px sans-serif">
            Here's a
            <RouterLink
              :to="{ name: 'CollabSummaryPublic', params: { communityId: 'beaverdamwi', collabInputId: '468491b8-f07e-4b02-ab22-b08e69f6f253' } }"
              style="text-decoration: underline"
              target="_blank"
            >
              collab that did a great job of this</RouterLink
            >
            <IconEmbedded class="snippet__icon" name="external-link_2" style="margin-left: 4px; position: relative; top: 2px" :size="15" color="rgba(0, 0, 0, 0.5)" />.
          </p>
        </div>
      </template>
    </SrpDetailsSummary>

    <SrpButton class="slide-about-exp__next-slide-button" @click="emit('goToNextSlide')" style="padding-right: 30px">
      <template #icon><IconEmbedded name="arrow-bottom_3" color="rgba(255, 255, 255, 0.5)" :size="24" style="position: relative; top: 2px" /></template>
      Next
    </SrpButton>
  </div>
</template>

<script setup lang="ts">
import axios from "axios";
import { onMounted, ref } from "vue";

// Types
import { Itinerary } from "@contracts/itinerary";

// Components
import ItineraryTile from "@components/ItineraryTile.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import SrpDetailsSummary from "@components/ui/SrpDetailsSummary.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

const props = withDefaults(
  defineProps<{
    requestedLocationCount: number;
    requestedAdventureCount: number;
  }>(),
  {
    requestedLocationCount: 10,
    requestedAdventureCount: 4,
  }
);

const emit = defineEmits<{
  (e: "goToNextSlide"): void;
}>();

// Guide checklist items ======================================================
const guideCheckListItems = [
  "Communicate with the destination about the locations you're visiting",
  `Create ${props.requestedAdventureCount} adventures with lots of photos & videos`,
  "Submit adventures within 10 days of your visit",
];

// Load collabs ===============================================================
const collabsList = ref<Array<Itinerary>>([]);
async function loadItineraries(communityId: string, collabId: string): Promise<void> {
  let uri = `${import.meta.env.VITE_API_URL}/collabs/${communityId}/inputs/${collabId}/adventures`;
  const { data } = await axios.get(uri);
  collabsList.value = data;
}

onMounted(async () => {
  await loadItineraries("putnamcountyin", "5c865e22-803f-4a20-bf24-83f370bf362a");
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Guide checklist item =======================================================
.guide-checklist-item {
  display: flex;

  &__check-icon {
    padding-top: 4px;
    margin-right: 7px;

    :deep(svg) {
      fill: #3d8528;
    }
  }

  &__text {
    color: black;
    font:
      19px/24px "Quicksand",
      sans-serif;
  }
}

// Adventures list ============================================================
.adventures-list {
  &__title {
    margin-bottom: 20px;
  }

  &__list-itself {
    padding: 0;
    margin: 0;
    display: flex;
    align-content: stretch;
    list-style: none;
  }

  &__adventure-tile {
    width: calc(25% - 10px);
    margin: 0 10px 0 0;

    &:nth-child(4) {
      margin-right: 0;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .adventures-list {
    &__list-itself {
      margin: 0 -10px;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__adventure-tile {
      width: calc(50% - 20px);
      margin: 0 7px 20px;

      &:nth-child(4) {
        margin: 0 7px 20px;
      }
    }
  }
}

// Slide about exp ============================================================
.slide-about-exp {
  &__guide-checklist {
    padding: 0 0 30px;
    margin: 0 0 35px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
    list-style: none;
  }

  &__guide-checklist-item {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__adventures-list {
    margin-bottom: 40px;
  }

  &__pro-tip {
    margin-bottom: 40px;
  }

  &__hint-spoiler {
    padding: 15px 20px 20px;
    margin-bottom: 40px;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.07);
  }

  &__hint-spoiler-content {
    padding-top: 12px;
  }

  &__next-slide-button {
  }
}
</style>
