<template>
  <Loader v-if="isLoading" />

  <div v-else-if="pageDetailsNotFound" class="global-h2" style="padding: 70px 0 20px; text-align: center">Page details not found</div>

  <div v-else class="collab-sum-page">
    <!-- Logotypes section -->
    <div class="logotypes-section collab-sum-page__logotypes-section">
      <!-- Logo cell -->
      <div class="logo-cell logotypes-section__logo-cell" @click="clickCommunity">
        <div class="global-h1 logo-cell__title">{{ pageDetails?.title }}</div>
        <div class="logo-cell__logo-wrap">
          <img class="community-logo logo-cell__logo-itself" :src="`${contentBaseUri}/cms/images/orgs/${pageDetails?.icon}`" :alt="pageDetails?.title" />
        </div>
      </div>
      <!-- / Logo cell -->

      <IconEmbedded class="logotypes-section__plus-sign" name="plus_3" />

      <!-- Logo cell -->
      <div class="logo-cell logotypes-section__logo-cell" @click="clickCreator">
        <div class="global-h1 logo-cell__title">{{ creatorInfo?.firstName }}</div>
        <div class="logo-cell__logo-wrap">
          <CreatorAvatar class="logo-cell__logo-itself" :imageUri="creatorInfo?.profileImageUri" :isWithPlusIcon="creatorInfo?.isCollabEligible" />
        </div>
      </div>
      <!-- / Logo cell -->
    </div>
    <!-- / Logotypes section -->

    <!-- Itineraries section -->
    <div class="itineraries-section collab-sum-page__itineraries-section">
      <div class="global-h2 itineraries-section__title">{{ collabDetails?.collabName }}</div>

      <HighlightsSection v-if="collabDetails?.highlightAssetIds?.length" style="margin-bottom: 60px" :highlightAssetIds="collabDetails?.highlightAssetIds" />

      <div class="itineraries-section__list">
        <ItineraryTile
          class="itineraries-section__itinerary"
          v-for="itinerary in itinerariesList"
          :itinerary="itinerary"
          :key="itinerary.id"
          @shareItinerary="() => {}"
          :allowShortImage="false"
          :source="null"
        />
      </div>
    </div>
    <!-- / Itineraries section -->

    <StatNumbersBanner
      class="collab-sum-page__stats-section"
      :fields="{
        Locations: collabDetails?.locationCount,
        Photos: collabDetails?.photoCount,
        ...(collabDetails?.videoCount > 1 ? { Videos: collabDetails?.videoCount } : {}),
      }"
      size="large"
    />
  </div>
</template>

<script lang="ts">
import axios from "axios";
import { defineComponent } from "vue";
import { useHead } from "@unhead/vue";

// Types
import { ItinerarySummary } from "@contracts/itinerary";
import { CreatorPublicProfileFields } from "@contracts/creatorPublicProfileFields";

// Components
import CreatorAvatar from "@components/CreatorAvatar.vue";
import ItineraryTile from "@components/ItineraryTile.vue";
import Loader from "@components/Loader/Loader.vue";
import HighlightsSection from "@components/HighlightsSection.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import StatNumbersBanner from "@components/StatNumbersBanner.vue";

export default defineComponent({
  components: { IconEmbedded, HighlightsSection, Loader, CreatorAvatar, ItineraryTile, StatNumbersBanner },

  data() {
    return {
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      isLoading: true,
      pageDetails: null,
      collabDetails: null,
      pageDetailsNotFound: false,
      title: "Shrpa",

      creatorInfo: null as CreatorPublicProfileFields,

      itinerariesList: [] as Array<ItinerarySummary>,
    };
  },

  async mounted() {
    useHead({ title: () => this.title ?? "", meta: [{ name: "description", content: () => this.description ?? "" }] });
    await this.loadPageDetails(this.$route.params.communityId, this.$route.params.collabInputId);
  },

  methods: {
    clickCommunity() {
      this.$router.push({ name: "Pages", params: { pageId: this.$route.params.communityId } });
    },
    clickCreator() {
      this.$router.push({ name: "CreatorProfilePublic", params: { creatorId: this.creatorInfo.sherpaId } });
    },
    loadPageDetails: async function (communityId, collabInputId) {
      try {
        let uri = `${import.meta.env.VITE_API_URL}/collabsummary/${communityId}/collabs/${collabInputId}`;
        const response = await axios.get(uri);

        this.pageDetails = response.data.page;
        this.collabDetails = response.data.collabDetails;
        this.itinerariesList = response.data.itineraries;
        this.title = this.pageDetails?.title || this.title;
        this.creatorInfo = response.data.creator;

        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        if (err.response.status == 404) {
          this.pageDetailsNotFound = true;
          return false;
        }
        return true;
      }
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Community logo =============================================================
.community-logo {
  max-width: 200px;
  max-height: 200px;

  &__title {
  }

  &__logo-itself {
  }
}

// Logo cell ==================================================================
.logo-cell {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    max-width: 100%;
    margin-bottom: 5px;
    position: relative;
    text-align: center;
  }

  &__logo-wrap {
    max-width: 70%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__logo-itself {
    max-height: 80%;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .logo-cell {
    &__title {
      width: 86vw;
      max-width: 86vw;
      margin-bottom: 15px;
    }

    &__logo-wrap {
      max-width: 100%;
      height: 100px;
    }

    &__logo-itself {
      max-height: 100%;
    }
  }
}

// Logotypes section ==========================================================
.logotypes-section {
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &::after {
    content: "";
    width: 70%;
    height: 0;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
    position: absolute;
    inset: auto auto 0 15%;
  }

  &__logo-cell {
    width: 35%;
    opacity: 1;
    cursor: pointer;
    user-select: none;
    transition: opacity 0.07s ease-in-out;

    &:hover {
      opacity: 0.65;
    }
  }

  &__plus-sign {
    width: 66px;
    height: 66px;
    margin: 20px 40px 0;
    color: #118689;
    font-size: 66px;
    line-height: 66px;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .logotypes-section {
    padding-bottom: 60px;

    &__logo-cell {
      width: 45%;
    }

    &__plus-sign {
      margin: 95px 10px 0;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .logotypes-section {
    padding-bottom: 50px;
    flex-direction: column;

    &__logo-cell {
      width: 25%;
    }

    &__plus-sign {
      margin: 25px 0 30px;
      width: 36px;
      height: 36px;
      font-size: 36px;
      line-height: 36px;
    }
  }
}

// Itineraries section ========================================================
.itineraries-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &__title {
    margin-bottom: 35px;
    text-align: center;
  }

  &__list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__itinerary {
    width: calc(25% - 15px);
    margin: 0 20px 20px 0 !important;

    &:nth-child(4n) {
      margin-right: 0 !important;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .itineraries-section {
    &__itinerary {
      width: calc(33% - 15px);
      margin: 0 20px 20px 0 !important;

      &:nth-child(4n) {
        margin: 0 20px 20px 0 !important;
      }

      &:nth-child(3n) {
        margin-right: 0 !important;
      }
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .itineraries-section {
    &__itinerary {
      width: calc(50% - 15px);
      margin: 0 20px 20px 0 !important;

      &:nth-child(4n),
      &:nth-child(3n) {
        margin: 0 20px 20px 0 !important;
      }

      &:nth-child(2n) {
        margin-right: 0 !important;
      }
    }
  }
}

// Collab Summary page ========================================================
.collab-sum-page {
  width: 100%;
  max-width: 1128px;
  padding: 60px 0 0;
  margin: 0 auto;

  &__logotypes-section {
    margin-bottom: 55px;
  }

  &__itineraries-section {
    margin-bottom: 60px;
    padding: 0 20px;
  }

  &__stats-section {
  }

  &__photogallery {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .collab-sum-page {
    padding-top: 70px;

    &__stats-section {
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .collab-sum-page {
    padding-top: 60px;

    &__logotypes-section {
      margin-bottom: 55px;
    }

    &__itineraries-section {
      margin-bottom: 50px;
    }

    &__stats-section {
    }
  }
}
</style>
