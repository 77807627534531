<template>
  <div class="upload-campaign-snippet">
    <div class="upload-campaign-snippet__left-side">
      <h3 class="global-h3 upload-campaign-snippet__title" @click="emit('clickOnTitle', props.uploadCampaign.campaign.campaignId)">
        <IconEmbedded name="edit-pencil_3" :size="19" style="margin-right: 2px; position: relative; inset: 2px auto auto -2px" color="rgba(0, 0, 0, 0.5)" />{{ props.uploadCampaign?.campaign.title }}
      </h3>
      <!-- Stats -->
      <div v-if="props.uploadCampaign.latestPhotoIds?.length" class="stats upload-campaign-snippet__stats">
        <span class="stats__number">{{ props.uploadCampaign.photoCount }}</span> photos and videos from <span class="stats__number">{{ props.uploadCampaign.usersUploaded }}</span>
        {{ props.uploadCampaign.usersUploaded === 1 ? "local" : "locals" }}
      </div>
      <!-- / Stats -->
      <div v-else class="upload-campaign-snippet__subtitle">Share this link to gather local perspectives!</div>

      <div class="upload-campaign-snippet__buttons">
        <template v-if="props.uploadCampaign.latestPhotoIds?.length">
          <SrpButton class="upload-campaign-snippet__cta-button" size="small" @click="photoWallButtonClick">
            <template #icon><IconEmbedded name="grid_2-5" :size="18" color="rgba(255, 255, 255, 0.8)" /></template>
            {{ props.uploadCampaign.campaign.photoWallId ? "View PhotoWall" : "Create PhotoWall" }}
          </SrpButton>
          <RouterLink :to="{ name: 'CommunityPhotos', params: { pageId: props.pageId }, hash: `#justCommunityUploads=true&group=community-upload&search=${props.uploadCampaign.campaign.title}` }">
            <SrpButton class="upload-campaign-snippet__cta-button" size="small">
              View Uploads
              <template #iconRight><IconEmbedded name="arrow-right_3" :size="18" color="rgba(255, 255, 255, 0.8)" /></template>
            </SrpButton>
          </RouterLink>
          <!-- Show a small share icon once they've had photos uploaded-->
          <SrpButton class="upload-campaign-snippet__cta-button" size="small" @click="emit('share', props.uploadCampaign.campaign.campaignId)" fill="outlined" color="gray">
            <template #icon><IconEmbedded name="share_2-5" :size="20" /></template>
          </SrpButton>
        </template>
        <SrpButton v-else class="upload-campaign-snippet__cta-button" size="small" @click="emit('share', props.uploadCampaign.campaign.campaignId)">
          <template #icon><IconEmbedded name="share_2-5" :size="20" color="rgba(255, 255, 255, 0.8)" /></template>
          Share
        </SrpButton>
        <SrpButton
          v-if="props.showRemoveButton"
          class="upload-campaign-snippet__cta-button"
          size="small"
          @click="emit('archive', props.uploadCampaign.campaign.campaignId)"
          fill="outlined"
          color="gray"
        >
          <template #icon><IconEmbedded name="trashcan-alt_2-5" :size="20" /></template>
        </SrpButton>
      </div>
    </div>

    <component
      class="upload-campaign-snippet__right-side"
      :is="props.uploadCampaign.latestPhotoIds?.length ? 'RouterLink' : 'div'"
      :to="{ name: 'CommunityPhotos', params: { pageId: props.pageId }, hash: `#justCommunityUploads=true&group=community-upload&search=${props.uploadCampaign.campaign.title}` }"
      @click="
        () => {
          if (!props.uploadCampaign.latestPhotoIds?.length) {
            emit('share', props.uploadCampaign.campaign.campaignId);
          }
        }
      "
    >
      <LinkWithIcon v-if="!props.uploadCampaign.latestPhotoIds?.length && screenSize !== 'mobile'" class="upload-campaign-snippet__get-the-word-out-btn" isDottedUnderline>
        <template #icon><IconEmbedded name="share_2-5" :size="20" color="rgba(255, 255, 255, 0.8)" /></template>
        <span>Get the word out</span>
      </LinkWithIcon>

      <SrpMasonry
        class="upload-campaign-snippet__photos-masonry"
        :items="props.uploadCampaign.latestPhotoIds?.length ? [...props.uploadCampaign.latestPhotoIds, ...placeholdersArray] : placeholdersArray"
        :columns="['mobile', 'tablet'].includes(screenSize) ? 3 : 6"
        :columnGap="['mobile', 'tablet'].includes(screenSize) ? 4 : 9"
      >
        <template #default="{ item, rebuildMasonry }">
          <div
            v-if="!props.uploadCampaign.latestPhotoIds || props.uploadCampaign.latestPhotoIds.length === 0 || typeof item === 'number'"
            :style="{
              marginBottom: ['mobile', 'tablet'].includes(screenSize) ? '4px' : '9px',
              borderRadius: '6px',
              position: 'relative',
              background: 'rgba(0, 0, 0, 0.15)',
            }"
            style="max-width: calc(50dvw / 3)"
          >
            <IconEmbedded name="image_2-5" :size="26" style="position: absolute; inset: calc(50% - 13px) auto auto calc(50% - 13px)" color="rgba(0, 0, 0, 0.1)" />
            <img :style="{ minHeight: item + 'px', opacity: 0 }" src="" alt="placeholder" @error="rebuildMasonry" />
          </div>

          <SrpFileThumbnail
            v-else
            :fileName="item"
            :isFixedAspectRatio="false"
            @imageLoad="rebuildMasonry"
            thumbSize="thumb-tiny"
            :style="{
              height: (!props.uploadCampaign.latestPhotoIds || props.uploadCampaign.latestPhotoIds.length === 0 ? item : 'auto') + 'px',
              marginBottom: ['mobile', 'tablet'].includes(screenSize) ? '4px' : '9px',
            }"
            style="max-width: calc(50dvw / 3)"
          />
        </template>
      </SrpMasonry>
    </component>
  </div>
</template>

<script setup lang="ts">
import { Ref, inject } from "vue";
import lodashRandom from "lodash-es/random";
import axios from "axios";
import { useRouter } from "vue-router";

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpFileThumbnail from "@components/ui/SrpFileThumbnail.vue";
import SrpMasonry from "@components/ui/SrpMasonry.vue";

// Types
import { ScreenSize } from "@contracts/screenSize";
import { UserContentUploadCampaignDataV2 } from "@contracts/userContentUploadCampaignData";
import LinkWithIcon from "@components/LinkWithIcon.vue";

// Global variables
const screenSize = inject("screenSize") as Ref<ScreenSize>;
const router = useRouter();

const props = withDefaults(
  defineProps<{
    uploadCampaign: UserContentUploadCampaignDataV2 | null;
    pageId: string | null;
    showRemoveButton: boolean;
  }>(),
  {
    uploadCampaign: null,
    pageId: null,
    showRemoveButton: true,
  }
);

const emit = defineEmits<{
  (e: "share", campaignId: string): void;
  // (e: "createPhotowall", campaignId: string): void;
  (e: "clickOnTitle", campaignId: string): void;
  (e: "archive", campaignId: string): void;
}>();

// Generate placeholders array ================================================
const placeholdersArray = [...new Array(30)].map(() => lodashRandom(40, 90));

// Create PhotoWall method ====================================================
const photoWallButtonClick = async () => {
  if (props.uploadCampaign?.campaign.photoWallId) {
    router.push({ name: "PhotoWallAdmin", params: { photoWallId: props.uploadCampaign.campaign.photoWallId }, query: { filterType: "community" } });
  } else {
    const uri = `${import.meta.env.VITE_API_URL}/photo-widget-admin/${props.pageId}/widgets/community-uploads/${props.uploadCampaign?.campaign.campaignId}`;
    const response = await axios.post(uri);
    const photoWallId = response.data.widgetId;

    // Redirect to the PhotoWall page
    router.push({ name: "PhotoWallAdmin", params: { photoWallId: photoWallId }, query: { filterType: "community" } });
  }
};
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";

// Stats ======================================================================
.stats {
  font-weight: 600;
  font-family: "Quicksand", sans-serif;

  &__number {
    color: rgba(0, 0, 0, 1);
    font-weight: 700;
  }
}

// Upload campaign snippet ====================================================
.upload-campaign-snippet {
  max-width: $desktop-wide-right-col-width;
  gap: 27px;
  border: 1px rgba(204, 211, 215, 1) solid;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
  background: $faint-gray-opaque;

  &__left-side {
    flex-grow: 1;
    padding: 13px 0 18px 26px;
  }

  &__title {
    margin-bottom: 7px;
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.4);
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
    text-decoration-style: dashed;
    cursor: pointer;
    user-select: none;

    &:hover {
      text-decoration: none;
    }
  }

  &__subtitle {
    margin-bottom: 15px;
  }

  &__stats {
    margin-bottom: 15px;
  }

  &__buttons {
    gap: 12px;
    display: flex;
    flex-wrap: wrap;
  }

  &__cta-button {
  }

  &__right-side {
    width: 540px;
    max-width: 50%;
    height: 100%;
    min-height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
    opacity: 1;
    transition: opacity 0.06s ease-in-out;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &__get-the-word-out-btn {
    padding: 10px 30px 12px;
    border-radius: 100px;
    background: rgba(242, 242, 242, 1);
    box-shadow: 0 0 7px 7px rgba(242, 242, 242, 1);
  }

  &__photos-masonry {
    width: 100%;
    position: absolute;
    inset: -20px auto auto 0;
    z-index: -1;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .upload-campaign-snippet {
    &__left-side {
      padding-left: 18px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .upload-campaign-snippet {
    &__left-side {
      width: 210px;
      min-width: 210px;
      padding-left: 18px;
    }

    &__right-side {
      width: 130px;
      min-width: 130px;
    }
  }
}
</style>
