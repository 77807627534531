<template>
  <SrpDetailsSummary class="photos-spoiler" :isOpened="true">
    <template #heading>
      <!-- Header -->
      <div class="header" style="margin-top: 2px">
        <h4 class="global-h4 header__title">{{ props.title }}</h4>

        <RouterLink v-if="props.nonLinkText && props.linkRoute" class="header__link-snippet" target="_blank" :to="props.linkRoute">
          <!--<IconEmbedded name="user_2" :size="21" style="margin-right: 3px" color="rgba(0, 0, 0, 0.5)" />-->
          <span class="header__link-snippet-link" @click.stop>
            {{ props.nonLinkText || "Go to" }}
            <IconEmbedded name="external-link_2-5" :size="15" style="margin-left: 3px" color="rgba(0, 0, 0, 0.5)" />
          </span>
        </RouterLink>

        <div v-if="props.email" class="header__email">
          <IconEmbedded name="envelope_2" :size="20" style="margin-right: 3px" color="rgba(0, 0, 0, 0.5)" />
          {{ props.email }}
        </div>

        <div v-if="props.date" class="header__date">
          <IconEmbedded name="calendar_2" :size="19" style="margin-right: 3px" color="rgba(0, 0, 0, 0.5)" />
          {{ props.date }}
        </div>
      </div>
      <!-- / Header -->
    </template>

    <template #details>
      <div style="width: 100%; height: 0; margin-bottom: 17px; border-bottom: 1px rgba(0, 0, 0, 0.1) solid"></div>

      <!-- Photos spoiler content -->
      <div class="photos-spoiler-content">
        <!-- Uploads history -->
        <div v-if="props.additionalInfo" class="uploads-history photos-spoiler-content__uploads-history">
          <ul
            :class="{
              'uploads-history__info-snippets-list': true,
              'uploads-history__info-snippets-list--half-hidden': props.additionalInfo.length > additionalInfoTruncCharLimit && !isAdditionalInfoFullyVisible,
            }"
          >
            <li class="uploads-history__info-snippet">
              {{ props.additionalInfo.trim() }}
            </li>
          </ul>

          <div v-if="props.additionalInfo.length > additionalInfoTruncCharLimit" class="uploads-history__footer">
            <LinkWithIcon isDottedUnderline iconInset="auto auto auto 2px" style="margin-left: -5px" @click="isAdditionalInfoFullyVisible = !isAdditionalInfoFullyVisible">
              <template #icon>
                <IconEmbedded :name="isAdditionalInfoFullyVisible ? 'caret-top_3' : 'caret-bottom_3'" :size="14" />
              </template>
              <span v-if="isAdditionalInfoFullyVisible">Show less</span>
              <span v-else>Show more</span>
            </LinkWithIcon>
          </div>
        </div>
        <!-- / Uploads history -->

        <!-- "Select all" and "Edit" buttons -->
        <div v-if="props.isWithSelectAllCheckbox || props.showManageUploads" class="select-all-and-edit-buttons photos-spoiler-content__select-all-and-edit-buttons">
          <div class="select-all-and-edit-buttons__left-side">
            <SrpCheckbox v-if="props.isWithSelectAllCheckbox" class="select-all-and-edit-buttons__button" size="medium">
              <template #input
                ><input type="checkbox" @change="$event => emit('checkUncheckAll', ($event.target as HTMLInputElement).checked)" :checked="props.checkedFiles?.length === props.photosList?.length"
              /></template>
              <template #text>Select all</template>
            </SrpCheckbox>
            <slot name="custom-buttons"></slot>
          </div>

          <div class="select-all-and-edit-buttons__right-side">
            <LinkWithIcon v-if="props.showManageUploads" @click="emit('manageUploads')" class="select-all-and-edit-buttons__button" isDottedUnderline color="blue">
              <template #icon><IconEmbedded name="pencil_2" :size="20" /></template>
              <span>Manage uploads</span>
            </LinkWithIcon>
          </div>
        </div>
        <!-- / "Select all" and "Edit" buttons -->

        <!-- Thumbnails grid -->
        <div class="thumbnails-grid photos-spoiler-content__thumbnails-grid">
          <ul class="thumbnails-grid__in-wrap">
            <!-- Thumbnail container -->
            <li class="thumbnail-container thumbnails-grid__thumbnail-container" v-for="fileName in props.photosList" :key="fileName">
              <ButtonIconExpandable
                v-if="props.isThumbnailsWithDownloadButton"
                class="thumbnail-container__button-icon thumbnail-container__button-icon--top-right"
                :icons="['download_2-5', 'download_2']"
                :iconSizes="[16, 16]"
                @click="emit('downloadImage', fileName)"
              />

              <SrpFileThumbnail
                class="thumbnail-container__thumbnail"
                :fileName="fileName"
                :thumbSize="'thumb-tiny'"
                :galleryPostfix="props.title"
                :isEntireAreaClickable="props.isThumbnailsWithCheckboxes ? false : props.isThumbnailsEntireAreaClickable"
                :isEntireAreaCheckable="props.isThumbnailsEntireAreaCheckable"
              >
                <template v-if="props.isThumbnailsWithCheckboxes" #default>
                  <input type="checkbox" :checked="props.checkedFiles.includes(fileName)" @change="$event => $emit('checkUncheckFile', fileName, $event.target.checked)" />
                </template>
              </SrpFileThumbnail>
            </li>
            <!-- / Thumbnail container -->

            <!-- Spacers are needed to align the last row to the left edge while keeping all tiles horizontally centered at the same time (it's not possible to achieve the same effect with CSS alone) -->
            <li class="thumbnails-grid__thumbnail-spacer" v-for="_ in 50" :key="_"></li>
          </ul>
        </div>
        <!-- / Thumbnails grid -->
      </div>
      <!-- / Photos spoiler content -->
    </template>
  </SrpDetailsSummary>
</template>

<script setup lang="ts">
import { ref } from "vue";

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import SrpDetailsSummary from "@components/ui/SrpDetailsSummary.vue";
import SrpCheckbox from "@components/ui/SrpCheckbox.vue";
import SrpFileThumbnail from "@components/ui/SrpFileThumbnail.vue";
import ButtonIconExpandable from "@components/ui/ButtonIconExpandable.vue";

const props = withDefaults(
  defineProps<{
    title: string | null;
    date?: string | null;
    email?: string | null;
    additionalInfo?: string | null;
    nonLinkText?: string | null;
    linkRoute?: any | null;
    isThumbnailsWithCheckboxes?: boolean;
    isThumbnailsWithDownloadButton?: boolean;
    isWithSelectAllCheckbox?: boolean;
    showManageUploads?: boolean;
    photosList: Array<string> | null;
    checkedFiles: Array<string> | null;
    isThumbnailsEntireAreaClickable?: boolean;
    isThumbnailsEntireAreaCheckable?: boolean;
  }>(),
  {
    title: null,
    date: null,
    email: null,
    additionalInfo: null,
    nonLinkText: null,
    linkRoute: null,
    isThumbnailsWithCheckboxes: false,
    isThumbnailsWithDownloadButton: false,
    isWithSelectAllCheckbox: false,
    showManageUploads: false,
    photosList: () => [],
    checkedFiles: () => [],
    isThumbnailsEntireAreaClickable: true,
    isThumbnailsEntireAreaCheckable: false,
  }
);

const emit = defineEmits<{
  (e: "downloadImage", value: string): void;
  (e: "checkUncheckFile", fileName: string, isChecked: boolean): void;
  (e: "checkUncheckAll", isChecked: boolean): void;
  (e: "manageUploads"): void;
  (e: "uploadMore"): void;
}>();

// Additional info params =======================================================
const isAdditionalInfoFullyVisible = ref<boolean>(false);
const additionalInfoTruncCharLimit = 130;
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Uploads history ============================================================
.uploads-history {
  color: rgba(91, 91, 91, 1);
  font: 14px/19px sans-serif;

  &__info-snippets-list {
    padding: 0;
    margin: 0;
    position: relative;
    z-index: 0;
    list-style: none;
    overflow: hidden;

    &--half-hidden {
      height: 90px;
      margin-bottom: 10px;

      &::after {
        content: "";
        width: 100%;
        height: 40px;
        position: absolute;
        inset: auto auto 0 0;
        z-index: 1;
        pointer-events: none;
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
      }
    }
  }

  &__info-snippet {
    padding-bottom: 8px;
    margin-bottom: 8px;
    position: relative;
    z-index: 0;
    white-space: pre-wrap;

    &::after {
      content: "";
      width: 100%;
      max-width: 250px;
      border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
      position: absolute;
      inset: auto auto 0 0;
    }

    &:last-child {
      padding-bottom: 0;

      &::after {
        display: none;
      }
    }
  }

  &__footer {
  }

  &__show-more-link {
  }
}

// "Select all" and "Edit" buttons ============================================
.select-all-and-edit-buttons {
  display: flex;
  justify-content: space-between;

  &__left-side {
    gap: 15px;
    display: flex;
    align-items: center;
  }

  &__right-side {
    gap: 15px;
    display: flex;
    align-items: center;
  }

  &__button {
  }
}

// Thumbnail container ========================================================
.thumbnail-container {
  position: relative;
  z-index: 0;

  &__button-icon {
    position: absolute;
    z-index: 1;

    &--top-right {
      inset: 7px 7px auto auto;
    }
  }

  &__thumbnail {
    position: relative;
    z-index: 0;
  }
}

// Thumbnails grid ============================================================
.thumbnails-grid {
  &__in-wrap {
    padding: 0;
    margin: 0 -5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
  }

  &__thumbnail-container {
    width: 130px;
    max-width: 130px;
    margin: 5px;
  }

  &__thumbnail-spacer {
    width: 130px;
    max-width: 130px;
    margin: 0 5px;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .thumbnails-grid {
    &__thumbnail-container,
    &__thumbnail-spacer {
      width: calc(13% + 2px);
    }
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .thumbnails-grid {
    &__thumbnail-container,
    &__thumbnail-spacer {
      width: calc(15% + 3px);
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .thumbnails-grid {
    &__thumbnail-container,
    &__thumbnail-spacer {
      width: calc(15% + 2px);
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .thumbnails-grid {
    &__thumbnail-container,
    &__thumbnail-spacer {
      width: calc(23%);
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .thumbnails-grid {
    &__thumbnail-container,
    &__thumbnail-spacer {
      width: calc(33.3% - 10px);
    }
  }
}

// Photos spoiler content =====================================================
.photos-spoiler-content {
  position: relative;
  z-index: 0;

  &__header {
  }

  &__uploads-history {
    width: 100%;
    margin-bottom: 20px;
  }

  &__select-all-and-edit-buttons {
    margin-bottom: 20px;
  }

  &__thumbnails-grid {
  }
}

// Header =====================================================================
.header {
  gap: 15px 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: rgba(91, 91, 91, 1);
  font: 14px/19px sans-serif;

  &__title {
    text-decoration: underline;
    text-decoration-color: rgba(91, 91, 91, 0.5);
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
    text-decoration-style: dashed;
  }
  &:hover &__title {
    text-decoration: none;
  }

  &__link-snippet,
  &__email,
  &__date {
    display: flex;
    align-items: center;
  }

  &__link-snippet-link {
    display: flex;
    align-items: center;
    text-decoration: underline;
    text-decoration-color: rgba(91, 91, 91, 0.5);
    text-underline-offset: 2px;
    text-decoration-thickness: 1px;
    text-decoration-style: dashed;

    &:hover {
      text-decoration: none;
    }
  }
}

// Photos spoiler =============================================================
.photos-spoiler {
  padding: 17px 0 24px;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    width: calc(100% + 50px);
    height: 100%;
    box-sizing: border-box;
    border: 1px rgba(0, 0, 0, 0.1) solid;
    border-radius: 6px;
    position: absolute;
    inset: 0 auto auto -25px;
  }
}
</style>
