<template>
  <div class="date-picker-field">
    <IconEmbedded class="date-picker-field__calendar-icon" name="calendar_2" />
    <SrpDropdown :width="300">
      <template #clickableElement>
        <input
          class="global-text-input global-text-input--small date-picker-field__input"
          :class="{
            'date-picker-field__input--error': isFormFocused && !dateStringRepresentation,
          }"
          type="text"
          @input="
            evt => {
              evt.preventDefault();
              (evt.target as HTMLInputElement).value = dateStringRepresentation;
            }
          "
          :value="dateStringRepresentation"
          placeholder="Pick a date"
        />
      </template>

      <template #dropdown="{ close, computeDropdownPosition }">
        <!-- Dropdown -->
        <div class="dropdown date-picker-field__dropdown">
          <div class="dropdown__header">
            <h4 class="global-h4 dropdown__title">What month do you prefer?</h4>
            <div class="dropdown__subtitle">Or you can pick specific dates</div>
          </div>

          <!-- Tabs -->
          <div class="tabs dropdown__tabs">
            <div
              :class="{
                tabs__tab: true,
                'tabs__tab--active': activeTab === 'month',
              }"
              @click="
                () => {
                  activeTab = 'month';
                  computeDropdownPosition();
                }
              "
            >
              Month
            </div>
            <div
              :class="{
                tabs__tab: true,
                'tabs__tab--active': activeTab === 'date range',
              }"
              @click="
                () => {
                  activeTab = 'date range';
                  computeDropdownPosition();
                }
              "
            >
              Date range
            </div>
          </div>
          <!-- / Tabs -->

          <VueDatePicker
            v-if="activeTab === 'month'"
            type="date"
            format="MMM d yyyy"
            :model-value="dateString"
            placeholder="Choose a date range"
            :teleport="true"
            autoApply
            :enableTimePicker="false"
            :teleportCenter="['mobile'].includes(screenSize)"
            :inline="{ input: false }"
            monthPicker
            :minDate="minimumMonthAllowed"
            @update:model-value="
              evt => {
                onUpdateMonthSelector(evt);
                close();
              }
            "
          />

          <VueDatePicker
            v-if="activeTab === 'date range'"
            type="date"
            format="MMM d yyyy"
            :model-value="dateRangeArray"
            placeholder="Choose a date range"
            :teleport="true"
            autoApply
            :enableTimePicker="false"
            :teleportCenter="['mobile'].includes(screenSize)"
            :inline="{ input: false }"
            range
            :minDate="minimumDayAllowed"
            @update:model-value="
              evt => {
                onUpdateDateRange(evt);
                close();
              }
            "
          />
        </div>
        <!-- / Dropdown -->
      </template>
    </SrpDropdown>
  </div>
</template>

<script setup lang="ts">
import { Ref, inject, ref, watch, computed, watchEffect } from "vue";

// Components
import SrpDropdown from "@components/ui/SrpDropdown.vue";
import VueDatePicker from "@vuepic/vue-datepicker";

// Types
import { ScreenSize } from "@contracts/screenSize";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export interface DateRange {
  start: string | null;
  end: string | null;
}

const props = withDefaults(
  defineProps<{
    dateRange: DateRange;
    isFormFocused: boolean;
    onlyAllowFutureDates: boolean;
  }>(),
  {
    // dateRange: null,
    isFormFocused: false,
    onlyAllowFutureDates: false,
  }
);
const emit = defineEmits<{
  (e: "update:dateRange", value: DateRange);
}>();

// Global variables
const screenSize = inject("screenSize") as Ref<ScreenSize>;

const startDate = computed<Date | null>(() => {
  if (!props.dateRange.start) return null;
  return new Date(props.dateRange.start);
});

const endDate = computed<Date | null>(() => {
  if (!props.dateRange.end) return null;
  return new Date(props.dateRange.end);
});

// Minimum Dates ==============================================================
const minimumMonthAllowed = computed<Date | null>(() => {
  if (!props.onlyAllowFutureDates) return null;
  const today = new Date();
  const minimumMonthAllowed = new Date(today.getFullYear(), today.getMonth(), 1);
  return minimumMonthAllowed;
});
const minimumDayAllowed = computed<Date | null>(() => {
  if (!props.onlyAllowFutureDates) return null;
  return new Date();
});

// ============================================================================
const dateString = computed<{ month: number; year: number } | null>(() => {
  if (endDate.value) return null;
  if (!startDate.value) return null;
  return { month: startDate.value.getMonth(), year: startDate.value.getFullYear() };
});
const dateRangeArray = computed<Array<Date> | null>(() => {
  // both required
  if (!startDate.value || !endDate.value) return null;
  return [new Date(startDate.value), new Date(endDate.value)];
});

const dateStringRepresentation = computed(() => {
  if (dateRangeArray.value) {
    const currentDate = new Date();
    const [startDate, endDate] = dateRangeArray.value;
    const currentYear = currentDate.getFullYear();

    const startDay = startDate.getDate();
    const startYear = startDate.getFullYear();
    const startMonth = startDate.toLocaleString("en-US", { month: "short" });

    const endDay = endDate.getDate();
    const endYear = endDate.getFullYear();
    const endMonth = endDate.toLocaleString("en-US", { month: "short" });

    return startYear === endYear && startYear === currentYear ? `${startMonth} ${startDay} — ${endMonth} ${endDay}` : `${startMonth} ${startDay}, ${startYear} — ${endMonth} ${endDay}, ${endYear}`;
  } else if (dateString.value) {
    const currentDate = new Date();
    currentDate.setMonth(dateString.value.month);
    currentDate.setFullYear(dateString.value.year);
    const monthName = currentDate.toLocaleString("en", { month: "long" });
    return `${monthName}, ${currentDate.getFullYear()}`;
  }
  return "";
});

function onUpdateMonthSelector(value: { month: number; year: number }) {
  const date = new Date(value.year, value.month, 1, 0, 0, 0, 0);
  emit("update:dateRange", { start: date.toISOString(), end: null });
}

function onUpdateDateRange([startDate, endDate]: [Date, Date]) {
  emit("update:dateRange", { start: startDate.toISOString(), end: endDate.toISOString() });
}

// Tabs switcher ==============================================================
const activeTab = ref<"month" | "date range">("month");
watchEffect(() => {
  if (endDate.value) activeTab.value = "date range";
  else activeTab.value = "month";
});
</script>

<style scoped lang="scss">
// Tabs =======================================================================
.tabs {
  height: 37px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    width: 100%;
    height: 0;
    border-bottom: 1px rgba(0, 0, 0, 0.2) solid;
    position: absolute;
    inset: auto auto 0 0;
    z-index: -1;
  }

  &__tab {
    height: 100%;
    padding: 0 23px 2px;
    margin-right: 5px;
    box-sizing: border-box;
    border: 1px rgba(223, 223, 223, 1) solid;
    border-radius: 4px 4px 0 0;
    border-bottom-color: rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(223, 223, 223, 1);
    cursor: pointer;
    user-select: none;

    &--active {
      border-color: rgba(198, 198, 198, 1);
      border-bottom-color: rgba(255, 255, 255, 1);
      background-color: rgba(255, 255, 255, 1);
    }
  }
}

// Dropdown ===================================================================
.dropdown {
  &__header {
    margin-bottom: 17px;
  }

  &__title {
  }

  &__subtitle {
  }

  &__tabs {
    margin-bottom: 13px;
  }

  &__content {
  }
}

// Date picker field ==========================================================
.date-picker-field {
  width: 180px;
  position: relative;
  z-index: 0;
  color: rgba(91, 91, 91, 1);
  font: 14px/18px sans-serif;

  &__input--error {
    border-color: rgba(188, 73, 73, 0.5);
    border-width: 2px;
  }

  &__input {
    width: 100%;
    padding-left: 34px;
    box-sizing: border-box;
  }

  &__calendar-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    inset: 4px auto auto 7px;
    z-index: 2;
    pointer-events: none;

    :deep(svg) {
      fill: rgba(0, 0, 0, 0.5);
    }
  }

  &__dropdown {
    width: 300px;
    padding: 18px;
    box-sizing: border-box;
    border: 1px rgba(200, 200, 200, 1) solid;
    border-radius: 6px;
    background: rgba(255, 255, 255, 1);

    :deep(.dp__outer_menu_wrap),
    :deep(.dp__menu) {
      border: none !important;
      box-shadow: none !important;
    }
  }
}
</style>
