import { CollabThreadMessage } from "./index.vue";
export default function checkIsMsgDatesAreSame(msg1: CollabThreadMessage | null, msg2: CollabThreadMessage | null): boolean {
  if (!msg1 || !msg2) return false;

  const msg1Date = new Date(msg1.sentOn);
  const msg2Date = new Date(msg2.sentOn);

  const msg1DateString = `${msg1Date.getFullYear()}-${msg1Date.getMonth()}-${msg1Date.getDate()}`;
  const msg2DateString = `${msg2Date.getFullYear()}-${msg2Date.getMonth()}-${msg2Date.getDate()}`;

  return msg1DateString === msg2DateString;
}
