<template>
  <div class="comped-activities">
    <button
      v-for="tag in tagOptions"
      :key="tag.key"
      @click="
        () => {
          collabInput.compedActivitiesTags.includes(tag.key) ? removeTag(tag.key) : addTag(tag.key);
          $emit('change');
        }
      "
      :class="{
        'ui small button': true,
        primary: collabInput.compedActivitiesTags.includes(tag.key),
      }"
    >
      <IconEmbedded
        :class="{
          icon: true,
          'icon--white': collabInput.compedActivitiesTags.includes(tag.key),
        }"
        :name="tag.icon"
      />
      {{ tag.text }}
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CollabConsts, { CollabInput } from "@contracts/collab";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export default defineComponent({
  name: "CollabCompTags",

  components: { IconEmbedded },

  props: {
    collabInput: { type: Object as () => CollabInput, required: true },
  },

  emits: {
    change: () => true,
  },

  data() {
    return {
      tagOptions: CollabConsts.CollabCompTags,
    };
  },

  methods: {
    addTag(key: string) {
      this.collabInput.compedActivitiesTags.push(key);
    },
    removeTag(key: string) {
      const index = this.collabInput.compedActivitiesTags.indexOf(key);
      if (index >= 0) this.collabInput.compedActivitiesTags.splice(index, 1);
    },
  },
});
</script>

<style scoped lang="scss">
.comped-activities {
  button {
    margin-top: 0.5rem;
    border-radius: 6px;
    display: inline-flex;
    align-items: center;

    &.primary {
      box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.25) !important;
    }
  }

  .icon {
    width: 18px;

    :deep(svg) {
      fill: rgba(0, 0, 0, 0.5);
    }

    &--white {
      :deep(svg) {
        fill: rgba(255, 255, 255, 0.7);
      }
    }
  }
}
</style>
