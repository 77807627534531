<template>
  <div class="event-type-tag">
    <div
      :class="{
        'event-type-tag__bg-svg-shape-left': true,
        'event-type-tag__bg-svg-shape-left--blue': props.color === 'blue',
        'event-type-tag__bg-svg-shape-left--green': props.color === 'green',
        'event-type-tag__bg-svg-shape-left--yellow': props.color === 'yellow',
        'event-type-tag__bg-svg-shape-left--purple': props.color === 'purple',
      }"
      v-html="svgTagShape"
    ></div>
    <div
      :class="{
        'event-type-tag__bg-svg-shape-right': true,
        'event-type-tag__bg-svg-shape-right--blue': props.color === 'blue',
        'event-type-tag__bg-svg-shape-right--green': props.color === 'green',
        'event-type-tag__bg-svg-shape-right--yellow': props.color === 'yellow',
        'event-type-tag__bg-svg-shape-right--purple': props.color === 'purple',
      }"
      v-html="svgTagShape"
    ></div>
    <slot />
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    color: "blue" | "green" | "yellow" | "purple";
  }>(),
  {
    color: "blue",
  }
);

// SVG tag shape ==============================================================
const svgTagShape = `
  <svg viewBox="0 0 246 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.3315 9.29201C-0.443261 11.0555 -0.443719 13.9461 1.32945 15.7114L9.43768 23.7671C10.2546 24.5268 11.3494 25.001 12.549 25H241.411C243.919 25.0026 245.999 22.942 245.999 20.4435L246 4.556C245.999 2.06059 243.92 -0.0013064 241.41 2.85528e-06L12.5481 7.38084e-06C11.3525 -0.00216299 10.2473 0.474473 9.43333 1.23289L1.3315 9.29201ZM11.0931 2.67711L3.01692 10.7107C2.02955 11.6918 2.02828 13.3096 3.0155 14.2933L3.01626 14.2941L11.0964 22.3218C11.5644 22.748 12.1714 23.0006 12.823 23L241.413 23C242.828 23.0015 243.999 21.824 243.999 20.4436L244 4.55719C243.999 3.17848 242.829 1.99926 241.411 2H12.8202C12.172 1.99882 11.5565 2.25455 11.0931 2.67711Z" />
  </svg>`;
</script>

<style scoped lang="scss">
// Event type tag ===================================================================
.event-type-tag {
  height: 25px;
  padding: 0 9px 1px 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;
  color: rgba(91, 91, 91, 1);
  font: 11px/11px sans-serif;
  white-space: nowrap;

  &__bg-svg-shape-left,
  &__bg-svg-shape-right {
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -1;
    overflow: hidden;

    :deep(svg) {
      height: 100%;
      position: absolute;
      inset: 0 auto auto 0;
    }

    &--blue {
      :deep(svg) {
        fill: rgba(59, 174, 190, 1);
      }
    }
    &--green {
      :deep(svg) {
        fill: rgba(135, 208, 101, 1);
      }
    }
    &--purple {
      :deep(svg) {
        fill: rgba(143, 147, 238, 1);
      }
    }
    &--yellow {
      :deep(svg) {
        fill: rgba(228, 204, 74, 1);
      }
    }
  }

  &__bg-svg-shape-right {
    inset: 0 0 auto auto;
    justify-content: flex-end;

    :deep(svg) {
      inset: 0 0 auto auto;
    }
  }
}
</style>
