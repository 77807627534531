<template>
  <Teleport to="#app">
    <div
      :class="{
        'text-container': true,
        'text-container--invisible': !isTextContainerVisible || activeStep !== index,
      }"
      v-for="(_, index) in Object.keys(slots)"
      :key="index"
      :style="textContainerPositionStyles"
      ref="domRefTextContainers"
    >
      <div
        v-if="props.isWithCloseBtn"
        :class="{
          'modal-close-button': true,
          'text-container__close-btn': true,
        }"
        @click="() => emit('update:isVisible', false)"
      >
        <IconEmbedded name="remove_3" />
      </div>

      <div
        :class="{
          'text-container__arrow': true,
          'text-container__arrow--top': textContainerPlacement?.includes('bottom'),
          'text-container__arrow--right': textContainerPlacement?.includes('left'),
          'text-container__arrow--bottom': textContainerPlacement?.includes('top'),
          'text-container__arrow--left': textContainerPlacement?.includes('right'),
        }"
        ref="domRefTextContainerArrows"
        :style="{
          left: textContainerArrowPositionStyles?.x != null ? `${textContainerArrowPositionStyles?.x}px !important` : '',
          top: textContainerArrowPositionStyles?.y != null ? `${textContainerArrowPositionStyles?.y}px !important` : '',
        }"
      ></div>

      <slot
        :name="`textBubble${index + 1}`"
        :close="() => emit('update:isVisible', false)"
        :goToPreviousSpotlight="() => switchStep(-1)"
        :goToNextSpotlight="() => switchStep(1)"
        :resetState="() => (activeStep = 0)"
      />
    </div>

    <TransitionFade :duration="transitionDuration" easing="ease-in-out">
      <!-- Light spot -->
      <div
        v-if="isLightSpotVisible"
        class="light-spot"
        :style="{
          width: `${targetNodesCoordinates.width}px`,
          height: `${targetNodesCoordinates.height}px`,
          top: `${targetNodesCoordinates.top}px`,
          left: `${targetNodesCoordinates.left}px`,
        }"
        ref="domRefLightSpot"
      ></div>
      <!-- / Light spot -->
    </TransitionFade>

    <TransitionFade :duration="transitionDuration" easing="ease-in-out">
      <!-- Light spot -->
      <div
        v-if="isLightSpotVisible"
        class="light-spot"
        :style="{
          width: `${targetNodesCoordinates.width}px`,
          height: `${targetNodesCoordinates.height}px`,
          top: `${targetNodesCoordinates.top}px`,
          left: `${targetNodesCoordinates.left}px`,
        }"
      ></div>
      <!-- / Light spot -->
    </TransitionFade>

    <TransitionFade :duration="transitionDuration" easing="ease-in-out">
      <!-- Light spot -->
      <div
        v-if="isLightSpotVisible"
        class="light-spot"
        :style="{
          width: `${targetNodesCoordinates.width}px`,
          height: `${targetNodesCoordinates.height}px`,
          top: `${targetNodesCoordinates.top}px`,
          left: `${targetNodesCoordinates.left}px`,
        }"
      ></div>
      <!-- / Light spot -->
    </TransitionFade>

    <TransitionFade :duration="transitionDuration" easing="ease-in-out">
      <!-- Light spot -->
      <div
        v-if="isLightSpotVisible"
        class="light-spot light-spot--10-opacity"
        :style="{
          width: `${targetNodesCoordinates.width}px`,
          height: `${targetNodesCoordinates.height}px`,
          top: `${targetNodesCoordinates.top}px`,
          left: `${targetNodesCoordinates.left}px`,
        }"
      ></div>
      <!-- / Light spot -->
    </TransitionFade>

    <TransitionFade :duration="transitionDuration" easing="ease-in-out">
      <div
        v-if="isBgVisible"
        class="bg-darkening-color-tile-top"
        :style="{
          width: '100%',
          height: `${targetNodesCoordinates.top}px`,
          top: 0,
          left: 0,
        }"
      ></div>
    </TransitionFade>

    <TransitionFade :duration="transitionDuration" easing="ease-in-out">
      <div
        v-if="isBgVisible"
        class="bg-darkening-color-tile-bottom"
        :style="{
          width: '100%',
          height: `calc(100% - ${targetNodesCoordinates.top + targetNodesCoordinates.height}px)`,
          top: `${targetNodesCoordinates.top + targetNodesCoordinates.height}px`,
          left: 0,
        }"
      ></div>
    </TransitionFade>

    <TransitionFade :duration="transitionDuration" easing="ease-in-out">
      <div
        v-if="isBgVisible"
        class="bg-darkening-color-tile-left"
        :style="{
          width: `${targetNodesCoordinates.left}px`,
          height: `${targetNodesCoordinates.height}px`,
          top: `${targetNodesCoordinates.top}px`,
          left: 0,
        }"
      ></div>
    </TransitionFade>

    <TransitionFade :duration="transitionDuration" easing="ease-in-out">
      <div
        v-if="isBgVisible"
        class="bg-darkening-color-tile-right"
        :style="{
          width: `calc(100% - ${targetNodesCoordinates.left + targetNodesCoordinates.width}px)`,
          height: `${targetNodesCoordinates.height}px`,
          top: `${targetNodesCoordinates.top}px`,
          left: `${targetNodesCoordinates.left + targetNodesCoordinates.width}px`,
        }"
      ></div>
    </TransitionFade>

    <TransitionFade :duration="transitionDuration" easing="ease-in-out">
      <!-- Bg darkening layer -->
      <div v-if="isBgVisible" class="bg-darkening-layer"></div>
      <!-- / Bg darkening layer -->
    </TransitionFade>
  </Teleport>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, nextTick, useSlots } from "vue";
import { useFloating, offset, flip, shift } from "@floating-ui/vue";
import { computePosition, arrow, autoPlacement, type ComputePositionConfig } from "@floating-ui/dom";

// Components
import { TransitionFade } from "@morev/vue-transitions";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

const props = withDefaults(
  defineProps<{
    targetNodes: Array<Array<HTMLElement>>;
    placement?: undefined | "top" | "right" | "bottom" | "left";
    padding?: number;
    isVisible: boolean;
    isWithCloseBtn?: boolean;
  }>(),
  {
    targetNodes: () => [],
    placement: undefined,
    padding: 12,
    isVisible: false,
    isWithCloseBtn: true,
  }
);

const emit = defineEmits<{
  (e: "update:isVisible", value: boolean): void;
}>();

const slots = useSlots();

// Transition duration ========================================================
const transitionDuration = 150;

// Set spotlight position =====================================================
interface TargetNodesCoordinates {
  width: number;
  height: number;
  top: number;
  left: number;
}

const targetNodesCoordinates = ref<TargetNodesCoordinates>({
  width: 0,
  height: 0,
  top: 0,
  left: 0,
});

function setSpotlightPosition(targetNodes: Array<HTMLElement>): void {
  const validNodes = targetNodes.filter(n => Boolean(n));

  if (validNodes.length === 0) {
    targetNodesCoordinates.value = {
      width: 0,
      height: 0,
      top: 0,
      left: 0,
    };
  }

  const boundingClientRects: Array<DOMRect> = validNodes.map(n => n?.getBoundingClientRect());

  const allWidthValues = boundingClientRects.map(({ width }) => Math.round(width));
  const allHeightValues = boundingClientRects.map(({ height }) => Math.round(height));
  const allTopValues = boundingClientRects.map(({ y }) => Math.round(y));
  const allLeftValues = boundingClientRects.map(({ x }) => Math.round(x));

  const smallestTopPoint = Math.min(...allTopValues);
  const biggestTopPoint = Math.max(...allTopValues.map((value, index) => value + allHeightValues[index]));

  const smallestLeftPoint = Math.min(...allLeftValues);
  const biggestLeftPoint = Math.max(...allLeftValues.map((value, index) => value + allWidthValues[index]));

  targetNodesCoordinates.value = {
    width: biggestLeftPoint - smallestLeftPoint + props.padding * 2,
    height: biggestTopPoint - smallestTopPoint + props.padding * 2,
    top: smallestTopPoint - props.padding + window.scrollY,
    left: smallestLeftPoint - props.padding,
  };
}

// Set text container position ================================================
const domRefLightSpot = ref<HTMLElement | null>(null);
const domRefTextContainers = ref<Array<HTMLElement | null>>(null);
const domRefTextContainerArrows = ref<Array<HTMLElement | null>>(null);

const textContainerPositionStyles = ref(null);
const textContainerPlacement = ref<string>(null);
const textContainerArrowPositionStyles = ref(null);

function setTextContainerPosition(textContainer: HTMLElement | null, arrowContainer: HTMLElement | null) {
  if (!textContainer || !arrowContainer) {
    return;
  }

  const floatingUiOptions: ComputePositionConfig = {
    middleware: [offset(25), flip(), shift(), arrow({ element: arrowContainer })],
  };
  if (props.placement) floatingUiOptions.placement = props.placement;

  computePosition(domRefLightSpot.value, textContainer, floatingUiOptions).then(position => {
    textContainerPlacement.value = position.placement;

    textContainerPositionStyles.value = {
      top: `${position.y}px`,
      left: `${position.x}px`,
    };
    textContainerArrowPositionStyles.value = {
      x: position.middlewareData?.arrow?.x,
      y: position.middlewareData?.arrow?.y,
    };
  });
}

// Switch steps ===============================================================
const activeStep = ref<number>(0);

function switchStep(direction: -1 | 1) {
  const stepsInTotal = props.targetNodes.length;

  if (direction === 1) {
    activeStep.value = activeStep.value + 1 > stepsInTotal - 1 ? stepsInTotal - 1 : activeStep.value + 1;
  } else {
    activeStep.value = activeStep.value - 1 < 0 ? 0 : activeStep.value - 1;
  }

  setSpotlightPosition(props.targetNodes[activeStep.value]);
  setTextContainerPosition(domRefTextContainers.value[activeStep.value], domRefTextContainerArrows.value[activeStep.value]);
}

// Show/hide guide ============================================================
const isBgVisible = ref<boolean>(false);
const isLightSpotVisible = ref<boolean>(false);
const isTextContainerVisible = ref<boolean>(false);

watch(
  () => props.isVisible,
  async () => {
    if (props.isVisible) {
      setSpotlightPosition(props.targetNodes[activeStep.value]);
      isBgVisible.value = props.isVisible;

      await new Promise(resolve => {
        setTimeout(() => {
          isLightSpotVisible.value = true;
          resolve(null);
        }, transitionDuration / 2);
      });

      await nextTick(() => {
        setTextContainerPosition(domRefTextContainers.value[activeStep.value], domRefTextContainerArrows.value[activeStep.value]);
        setTimeout(() => (isTextContainerVisible.value = true), 0);
      });
    } else {
      isLightSpotVisible.value = false;
      isTextContainerVisible.value = false;

      setTimeout(() => {
        isBgVisible.value = false;
      }, transitionDuration / 2);
    }
  }
);
</script>

<style scoped lang="scss">
@import "@/scss/z-indexes.scss";
@import "@/scss/modal-close-button.scss";

// Text container =============================================================
.text-container {
  padding: 18px 25px 20px;
  border-radius: 6px;
  position: absolute;
  inset: 0 auto auto 0;
  z-index: #{$z-index-spotlight-guide + 2};
  color: rgba(#413806, 0.75);
  font: 14px/18px sans-serif;
  background: #f0e7b8;
  opacity: 1;
  transition: opacity 0.12s ease-in-out;

  &--invisible {
    opacity: 0;
    pointer-events: none;
  }

  &__close-btn {
    position: absolute;
    inset: -8px -8px auto auto;
    z-index: 3;
  }

  &__arrow {
    width: 24px;
    height: 24px;
    display: block;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: #{$z-index-spotlight-guide + 2};

    &::before {
      content: "";
      min-width: 24px;
      height: 14px;
      position: absolute;
      background: #f0e7b8;
    }

    &::after {
      content: "";
      width: 10px;
      height: 10px;
      position: absolute;
      background: #f0e7b8;
    }

    &--top {
      inset: -24px auto auto calc(50% - 12px);

      &::before {
        inset: auto auto 0 0;
        clip-path: polygon(50% 0, 100% 100%, 0 100%);
      }
      &::after {
        width: 24px;
        height: 10px;
        inset: auto auto -10px 0;
      }
    }
    &--right {
      inset: calc(50% - 12px) -24px auto auto;

      &::before {
        min-width: 14px;
        height: 24px;
        inset: 0 auto auto 0;
        clip-path: polygon(0 0, 100% 50%, 0 100%);
      }
      &::after {
        width: 10px;
        height: 24px;
        inset: 0 auto auto -10px;
      }
    }
    &--bottom {
      inset: auto auto -24px calc(50% - 12px);

      &::before {
        clip-path: polygon(0 0, 100% 0, 50% 100%);
      }
      &::after {
        width: 24px;
        height: 10px;
        inset: -10px auto auto 0;
      }
    }
    &--left {
      inset: calc(50% - 12px) auto auto -24px;

      &::before {
        min-width: 14px;
        height: 24px;
        inset: 0 0 auto auto;
        clip-path: polygon(0 50%, 100% 0, 100% 100%);
      }
      &::after {
        width: 10px;
        height: 24px;
        inset: 0 -10px auto auto;
      }
    }
  }
}

// Light spot =================================================================
.light-spot {
  height: 100px;
  border-radius: 6px;
  position: absolute;
  z-index: #{$z-index-spotlight-guide + 2};
  background: white;
  pointer-events: none;
  mix-blend-mode: overlay;

  &--10-opacity {
    opacity: 0.1;
  }
}

@mixin bgDarkeningColorTile {
  position: absolute;
  inset: 0 0 0 0;
  z-index: #{$z-index-spotlight-guide + 1};
  background: #02070a;
  mix-blend-mode: color;
}
// Bg darkening color tile top ================================================
.bg-darkening-color-tile-top {
  @include bgDarkeningColorTile;
}
// Bg darkening color tile bottom =============================================
.bg-darkening-color-tile-bottom {
  @include bgDarkeningColorTile;
}
// Bg darkening color tile left ===============================================
.bg-darkening-color-tile-left {
  @include bgDarkeningColorTile;
}
// Bg darkening color tile right ==============================================
.bg-darkening-color-tile-right {
  @include bgDarkeningColorTile;
}

// Bg darkening layer =========================================================
.bg-darkening-layer {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0 auto auto 0;
  z-index: $z-index-spotlight-guide;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.9);
  pointer-events: none;
}
</style>
