<template>
  <div class="locations-slide">
    <!-- Header -->
    <div class="header locations-slide__header">
      <h4 class="global-h4 header__h1">Locations &amp; Adventures</h4>
      <div class="header__subtitle">
        <div style="margin: 0 0 10px 0">Regardless of your choice below, you will receive 80+ pieces of content.</div>
        <div style="margin-bottom: 0">
          The sliders below help direct the creator to highlight more locations,<br />
          or to spend more time at fewer locations.
        </div>
      </div>
    </div>
    <!-- / Header -->

    <div class="locations-slide__divider"></div>

    <h4 class="global-h4 locations-slide__h4">How many locations would you like highlighted?</h4>
    <SrpSlider
      v-if="mountingCompleted"
      class="locations-slide__slider"
      :min="minLocationCount"
      :max="maxLocationCount"
      v-model:value="_requestedLocationCount"
      @update:value="newValue => emit('update:requestedLocationCount', newValue)"
    />

    <div class="locations-slide__divider"></div>

    <h4 class="global-h4 locations-slide__h4">How many adventures would you like created?</h4>
    <SrpSlider
      v-if="mountingCompleted"
      class="locations-slide__slider"
      :min="minAdventureCount"
      :max="maxAdventureCount"
      v-model:value="_requestedAdventureCount"
      @update:value="newValue => emit('update:requestedAdventureCount', newValue)"
    />

    <NoteWithIcon v-if="isWarningNoteVisible" class="locations-slide__note" color="red" size="tiny">
      <template #icon><IconEmbedded name="warning_2-5" :size="18" /></template>
      <template #text>
        <span>
          For more adventures, increase the number of locations<br />
          being highlighted. (so the creator can build full itineraries)
        </span>
      </template>
    </NoteWithIcon>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import SrpSlider from "@components/ui/SrpSlider.vue";

// Types
import CollabInputValidator from "@logic/CollabInputValidator";

const props = withDefaults(
  defineProps<{
    requestedLocationCount: number | null;
    requestedAdventureCount: number | null;
  }>(),
  {
    requestedLocationCount: null,
    requestedAdventureCount: null,
  }
);

const emit = defineEmits<{
  (e: "update:requestedLocationCount", value: number): void;
  (e: "update:requestedAdventureCount", value: number): void;
}>();

// Init values ================================================================
const RequestedLocationCountDefault = 10;
const RequestedAdventuresCountDefault = 3;
const _requestedLocationCount = ref<number>(RequestedLocationCountDefault);
const _requestedAdventureCount = ref<number>(RequestedAdventuresCountDefault);

const minLocationCount = 4;
const maxLocationCount = 10;

const minAdventureCount = 2;
const maxAdventureCount = 4;

// Had an issue on mount it would temporarily snap back to defaults so turning off the sliders until mounting is complete
const mountingCompleted = ref<boolean>(false);
onMounted(() => {
  if (props.requestedLocationCount) {
    _requestedLocationCount.value = props.requestedLocationCount;
  }
  if (props.requestedAdventureCount) {
    _requestedAdventureCount.value = props.requestedAdventureCount;
  }
  mountingCompleted.value = true;
});

// Is warning note visible ====================================================
const isWarningNoteVisible = computed<boolean>(() => {
  // Added the + to ensure they're not strings
  var isValid = CollabInputValidator.validateAdventureAndLocationCount(+_requestedAdventureCount.value, +_requestedLocationCount.value);
  return !isValid;
});

const isFormValid = computed<boolean>(() => {
  return !isWarningNoteVisible.value;
});

defineExpose({
  isFormValid,
});
</script>

<style scoped lang="scss">
// Header =====================================================================
.header {
  &__h1 {
    margin-bottom: 8px;
  }

  &__subtitle {
  }
}

// Locations slide ============================================================
.locations-slide {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__header {
    margin-bottom: 20px;
  }

  &__h4 {
    margin-bottom: 5px;
    color: rgba(44, 85, 102, 1);
    font-weight: 500;
  }

  &__divider {
    width: 100%;
    height: 0;
    margin-bottom: 20px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__slider {
    margin-bottom: 23px;
  }

  &__note {
  }
}
</style>
