<template>
  <div></div>
</template>

<script>
// import VSelect from "vue-select";

export default {
  props: {
    options: { type: Array, default: () => [] },
    multiple: { type: Boolean, default: true },
  },
  // extends: VSelect,
};
</script>

<style lang="scss">
.vs__dropdown-toggle {
  background: white;
  padding: 0;
}
.vs__selected-options .vs__search {
  width: auto !important;
  border: unset !important;
}
.vs__selected-options .vs__selected {
  margin: 4px 2px;
}
</style>
