<template>
  <div class="photos-slide">
    <h2 class="global-h2 photos-slide__title">Use your best photos & videos</h2>

    <SrpMasonry class="photos-slide__example-photos" :items="photosList" :columns="screenSize === 'mobile' ? 3 : 5" :columnGap="6" :threshold="50" @masonryRebuilt="emit('masonryRebuilt')">
      <template #default="{ item, rebuildMasonry }">
        <SrpFileThumbnail
          :filePath="`${contentBaseUri}/cms/images/expeditions/thumbnocrop/${item}`"
          style="margin-bottom: 6px"
          :isFixedAspectRatio="false"
          @imageLoad="
            () => {
              rebuildMasonry();
              emit('imageLoad');
            }
          "
        />
      </template>
    </SrpMasonry>

    <h2 class="global-h2 photos-slide__description">Destinations will check out the style of your photos and videos when reviewing your portfolio, so put your best foot forward!</h2>
  </div>
</template>

<script setup lang="ts">
import { inject, Ref } from "vue";

// Components
import SrpFileThumbnail from "@components/ui/SrpFileThumbnail.vue";
import SrpMasonry from "@components/ui/SrpMasonry.vue";

// Types
import { ScreenSize } from "@contracts/screenSize";

// Global variables
const contentBaseUri = globalThis.Bootstrap.Config.contentCdnBaseUri;
const screenSize = inject("screenSize") as Ref<ScreenSize>;

const emit = defineEmits<{
  (e: "imageLoad"): void;
  (e: "masonryRebuilt"): void;
}>();

// Photos list ================================================================
const photosList = [
  "33733b66-6f93-4d79-b1ee-57cd7cf938df.jpg",
  "4c62ac71-892c-4ae9-b39e-7a2965daae8a.jpg",
  "e9137b89-4484-4de4-9fbb-b0bbff36517e.jpg",
  "e3baa2ae-57e4-411f-8292-054711b6ceed.jpg",
  "ce4c4663-485f-48e4-84ac-db2454cf9e9f.jpg",
  "be7e18cc-6edf-48f0-9579-45556ce7dfc2.jpg",
  "bdbd6cec-d71e-489a-9686-2f40223a91f0.jpg",
  "61549424-8a89-42cd-91dc-8b369a09c991.jpg",
  "50755ea0-ecaf-43bc-a445-8727a8bbed29.jpg",
  "c720b43b-43a1-47fc-b14e-c12c825b842b.jpg",
];
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Photos slide ===============================================================
.photos-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__title {
    margin-bottom: 33px;
    text-align: center;
  }

  &__example-photos {
    margin-bottom: 30px;
  }

  &__description {
    font-weight: 400;
    text-align: center;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .photos-slide {
    &__title {
      margin-bottom: 15px;
    }
  }
}
</style>
