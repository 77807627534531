<template>
  <div>
    <Loader v-if="isLoading" style="position: absolute"></Loader>
    <div v-else class="ui form">
      <!--<router-link :to="{name: 'CommunityAdminDashboard'}" lang="en" hreflang="en" class="ui small basic button right floated">Back to Dashboard</router-link>-->
      <div>
        <h1 style="display: inline; margin-right: 25px">Invite Community Members to Upload Photos and Videos!</h1>
      </div>
      <p style="margin-bottom: 20px">
        The best way to experience your community is through the eyes of those who love it most!
        <br />These allow community members to easily upload photos that you can use in your marketing.
      </p>

      <div class="ui stackable grid" style="margin-top: 2em">
        <!--<div v-if="unarchivedCampaigns?.length">
          <LinkWithIconAndCTA text="Create another Upload Link" iconSrc="/images/uploadCampaignIcons/generalUploads.svg" :iconSize="50" style="margin-bottom: 40px" @click="() => askToCreate()" />
        </div>-->

        <!-- Creation Templates (always showing now) -->
        <div class="template-tiles" style="width: 100%">
          <UploadCampaignTemplateSnippet
            class="template-tiles__tile"
            v-for="templateTheme in Object.keys(uploadCampaignTemplates)"
            :key="templateTheme"
            :template="uploadCampaignTemplates[templateTheme]"
            :templateTheme="templateTheme as UploadCampaignTemplateTheme"
            @select="templateTheme => askToCreate(templateTheme)"
            :solidButtons="unarchivedCampaigns?.length === 0"
          />
        </div>

        <div v-if="unarchivedCampaigns?.length > 0" class="upload-campaign-snippets" style="width: 100%">
          <h2 style="margin-bottom: 0">Your Upload Links</h2>
          <UploadCampaignSnippet
            class="upload-campaign-snippets__snippet"
            v-for="(uploadCampaign, index) in unarchivedCampaigns"
            :key="uploadCampaign.campaign.campaignId"
            :uploadCampaign="uploadCampaign"
            :pageId="pageId"
            :showRemoveButton="true"
            @clickOnTitle="editCampaign(uploadCampaign.campaign, index)"
            @share="
              () => {
                campaignToSave = uploadCampaign.campaign;
                showSpreadTheWordModal = true;
              }
            "
            @archive="askToArchive(uploadCampaign)"
          />
        </div>
      </div>

      <div v-if="archivedCampaigns.length > 0">
        <label class="cms-section-title" style="margin-top: 100px !important">Deactivated Links</label>
        <table class="ui very basic table">
          <thead></thead>
          <tbody>
            <tr v-for="campaign in archivedCampaigns" :key="campaign.campaign.campaignId">
              <td>{{ campaign.campaign.title }}</td>
              <td>
                <button class="ui grey basic mini button" @click="unarchive(campaign)">Activate</button>
                <button class="ui basic tiny button" @click="askToSoftDelete(campaign)"><i class="icon trash"></i>Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <SelectTemplateModal :isVisible="showSelectTemplateModal" @selectTemplate="selectTemplateAndContinueCreation" @update:isVisible="showSelectTemplateModal = false" />

    <SpreadTheWordModal v-if="campaignToSave" :isVisible="showSpreadTheWordModal" @update:isVisible="showSpreadTheWordModal = false" :campaign="campaignToSave" :pageId="pageId" />

    <!--Edit Modal-->
    <SrpModal v-if="campaignToSave" v-model:isVisible="showEditModal" :isClosable="false">
      <template #header>
        <h2 class="global-h2">{{ campaignToSave.campaignId ? "Update the Upload Link" : "Create an Upload Link" }}</h2>
      </template>
      <template #content>
        <div class="ui form">
          <div class="field">
            <label class="subsection-title">Title</label>
            <p class="cms">Use a theme or category you want to highlight!</p>
            <div class="ui input">
              <input type="text" v-model="campaignToSave.title" placeholder="Ex. Fall colors, or Holiday lights, or Summer fun" />
            </div>
          </div>
          <div class="field" style="margin-bottom: 20px">
            <label class="subsection-title">Description</label>
            <div class="ui fluid input">
              <textarea type="text" v-model="campaignToSave.description" style="height: 80px; min-height: 20px"></textarea>
            </div>
          </div>
          <div class="field">
            <label class="subsection-title"
              >Photo Inspiration (optional)
              <span v-if="campaignToSave.exampleMediaIds?.length" style="float: right; margin-right: 5px">
                <LinkWithIcon isDottedUnderline @click="isRemovingExampleMedia = !isRemovingExampleMedia">
                  <!--<template #icon><IconEmbedded name="trashcan-alt_2" :size="20" /></template>-->
                  <span>{{ isRemovingExampleMedia ? "Finished adjusting" : "Adjust photos" }}</span>
                </LinkWithIcon>
              </span>
            </label>
            <div class="ui fluid input">
              <UploadPhotoForm
                style="width: 100%; min-height: 150px; max-height: 360px; margin-bottom: 20px"
                :img="campaignToSave.exampleMediaIds?.map(i => ({ serverId: i })) || []"
                @imageUploadedToServer="
                  image => {
                    if (!campaignToSave.exampleMediaIds) {
                      campaignToSave.exampleMediaIds = [];
                    }
                    campaignToSave.exampleMediaIds.push(image.serverId);
                  }
                "
                @removeMediaFile="imgId => (campaignToSave.exampleMediaIds = campaignToSave.exampleMediaIds.filter(i => i !== imgId))"
                :columnsNumber="{ mobile: 3, tablet: 5, 'tablet-large': 6, laptop: 6, desktop: 6, 'desktop-wide': 6 }[screenSize]"
                :showRemove="isRemovingExampleMedia"
                :hideVideoGuidance="true"
                :isWithDragAndDrop="isRemovingExampleMedia"
                @reorderImages="
                  (oldIndex, newIndex) => {
                    campaignToSave.exampleMediaIds = moveItemToNewIndex(campaignToSave.exampleMediaIds, oldIndex, newIndex, false);
                  }
                "
              />
            </div>
          </div>
          <div class="field" style="padding-bottom: 15px; margin-bottom: 20px; border-bottom: 1px rgba(0, 0, 0, 0.1) solid">
            <GlobalAccentColorPicker dropdownPosition="top" />
          </div>
          <div v-if="validationError" class="validation-errors">
            <div>{{ validationError }}</div>
          </div>

          <div class="field">
            <label class="subsection-title">Upload Link to Share</label>

            <div v-if="!isEditingExistingCampaign && Boolean(campaignToSave.campaignId)" style="display: flex">
              <b style="user-select: all">{{ getUploadLink(campaignToSave.uriKey || campaignToSave.campaignId) }}</b>
              <LinkWithIcon v-if="campaignToSave.campaignId && !isEditingExistingCampaign" color="blue" isDottedUnderline @click="isEditingExistingCampaign = true" style="margin-left: 7px">
                <IconEmbedded name="edit-pencil_2" :size="21" />
                <span>Edit</span>
              </LinkWithIcon>
            </div>
            <template v-else>
              <div v-if="isNewUriKeyAlreadyTaken" style="font-weight: bold; color: firebrick">This url is already taken by another campaign</div>
              <label class="input-with-prefix">
                <span class="input-with-prefix__prefix-overlay">
                  {{
                    getUploadLink(campaignToSave.uriKey || campaignToSave.campaignId)
                      .split("/")
                      .slice(0, -1)
                      .join("/") + "/"
                  }}
                </span>
                <input class="input-with-prefix__input" type="text" v-model="campaignToSaveUriKey" style="max-width: 600px" ref="domRefUriKeyInput" />
                <div class="global-text-input input-with-prefix__input-bg"></div>
              </label>
            </template>

            <NoteWithIcon v-if="isEditingExistingCampaign" style="margin-top: 7px">
              <template #text>
                Changing this will break any previous links you've posted.<br />
                Make sure to change this before you post or update any links you've posted.
              </template>
            </NoteWithIcon>
          </div>
        </div>
      </template>
      <template #footer>
        <div style="display: flex; justify-content: space-between; align-items: center; width: 100%">
          <div>
            <SrpButton
              v-if="isNew"
              fill="outlined"
              color="black"
              @click="
                () => {
                  showSelectTemplateModal = true;
                  showEditModal = false;
                }
              "
              style="margin-right: 12px"
            >
              Back
            </SrpButton>
            <SrpButton :isDisabled="!campaignToSave.uriKey || isNewUriKeyAlreadyTaken" @click="copy(campaignToSave.uriKey)">Copy Link</SrpButton>
            <div style="font-weight: bold" v-if="justCopied">Copied to clipboard</div>
          </div>

          <div style="display: flex; gap: 7px">
            <!--<SrpButton fill="outlined" color="black" @click="cancelCreate()">Cancel</SrpButton>-->
            <SrpButton fill="outlined" color="black" :isDisabled="isSaving || !campaignToSave?.uriKey || isNewUriKeyAlreadyTaken" @click="saveCampaign(true, true, false)">Preview</SrpButton>
            <SrpButton :isDisabled="isSaving || !campaignToSave.uriKey || isNewUriKeyAlreadyTaken" @click="saveCampaign(true, false, true)">{{ isSaving ? "Saving..." : "Next" }}</SrpButton>
          </div>
        </div>
      </template>
    </SrpModal>

    <!--Archive Modal-->
    <SrpModal v-model:isVisible="showArchiveModal">
      <template #header><h2 class="global-h2">Delete or Deactivate?</h2></template>
      <template #content>
        <div style="font-size: 1.2em; line-height: 1.2">
          <strong>Delete</strong> removes this link permanently.<br />
          <strong>Deactivate</strong> stops users from uploading any more content. <br /><br />
          <span><i>Note:</i> Content uploaded via this link won't be removed.</span>
        </div>
      </template>
      <template #footer>
        <h3 v-if="isSaving" style="display: inline">Archiving...</h3>
        <button class="ui grey button" :disabled="isSaving" @click="archiveConfirmed()">Deactivate</button>
        <button class="ui red button" :disabled="isSaving" @click="deleteConfirmed()">Delete</button>
      </template>
    </SrpModal>

    <!--Delete Modal-->
    <SrpModal v-model:isVisible="showDeleteModal">
      <template #header>
        <h2 class="global-h2">Are you sure you want to delete this?</h2>
      </template>
      <template #content>
        <div>
          <b>This link will be gone for good.</b><br />
          <i>Note:</i> Content uploaded via this link won't be removed.
        </div>
      </template>
      <template #footer>
        <h3 v-if="isSaving" style="display: inline">Deleting...</h3>
        <div class="ui basic black button" @click="showDeleteModal = false">Cancel</div>
        <button class="ui red button" :disabled="isSaving" @click="deleteConfirmed()">Yep, delete it.</button>
      </template>
    </SrpModal>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import { defineComponent, inject } from "vue";
import { mapState } from "pinia";
import OrgContext from "@logic/OrgContext";
import { useHead } from "@unhead/vue";
import uploadCampaignTemplatesRaw from "./uploadCampaignTemplates.json";
import { moveItemToNewIndex } from "@composables/useMouseDragNDrop";

// Components
import CopyText from "@components/CopyText.vue";
import GlobalAccentColorPicker from "@components/GlobalAccentColorPicker.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import LinkWithIconAndCTA from "@views/Community/UserUploadCampaigns/LinkWithIconAndCTA.vue";
import Loader from "@components/Loader/Loader.vue";
import UploadCampaignSnippet from "@views/Community/UserUploadCampaigns/UploadCampaignSnippet.vue";
import UploadPhotoForm from "@views/CMS/UploadPhotoForm.vue";

import NoteWithIcon from "@components/NoteWithIcon.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import SrpButton from "@components/ui/SrpButton.vue";

// Types
import { PartnerNavInfo } from "@contracts/partnerNavInfo";
import { ScreenSize } from "@contracts/screenSize";
import { UserContentUploadCampaignData, UserContentUploadCampaignDataV2, UserUploadCampaignsV2 } from "@contracts/userContentUploadCampaignData";
import { UploadCampaignTemplateTheme, UploadCampaignTemplate } from "@contracts/uploadCampaignTemplates";

// Stores
import { useUserProfileStore } from "@stores/userProfileStore";
import SelectTemplateModal from "@views/Community/UserUploadCampaigns/SelectTemplateModal.vue";
import SpreadTheWordModal from "@views/Community/UserUploadCampaigns/SpreadTheWordModal.vue";
import UploadCampaignTemplateSnippet from "@views/Community/UserUploadCampaigns/UploadCampaignTemplateSnippet.vue";
import { MetricSender } from "@/helpers/MetricSender";

export default defineComponent({
  name: "UserUploadCampaigns",

  components: {
    UploadCampaignTemplateSnippet,
    SpreadTheWordModal,
    SelectTemplateModal,
    CopyText,
    GlobalAccentColorPicker,
    IconEmbedded,
    LinkWithIcon,
    LinkWithIconAndCTA,
    Loader,
    NoteWithIcon,
    SrpButton,
    SrpModal,
    UploadCampaignSnippet,
    UploadPhotoForm,
  },

  data() {
    return {
      screenSize: inject("screenSize") as ScreenSize,
      title: "Community Uploads",
      pageId: null,
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      orgInContext: null as PartnerNavInfo,
      // Campaign Data
      campaignData: null as UserUploadCampaignsV2,
      justCopied: false,
      isLoading: true,

      showSelectTemplateModal: false,
      showSpreadTheWordModal: false,
      showEditModal: false,
      campaignToSave: null as UserContentUploadCampaignData, // The campaign being edited/created
      campaignBeforeEdit: null, // In case they hit cancel
      campaignEditIndex: -1,
      isSaving: false,
      validationError: null,

      showDeleteModal: false,
      showArchiveModal: false,

      exampleMediaIds: [],
      isRemovingExampleMedia: false,

      isEditingExistingCampaign: false,

      uploadCampaignTemplates: uploadCampaignTemplatesRaw as Record<UploadCampaignTemplateTheme, UploadCampaignTemplate>,
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["isSuperOrSalesUser"]),
    unarchivedCampaigns(): Array<UserContentUploadCampaignDataV2> {
      return this.campaignData.campaigns.filter(c => !c.campaign.expiryDateTime).sort((a, b) => b.campaign.createdDate.localeCompare(a.campaign.createdDate));
    },
    archivedCampaigns(): Array<UserContentUploadCampaignDataV2> {
      return this.campaignData.campaigns.filter(c => c.campaign.expiryDateTime);
    },
    isNew(): boolean {
      return this.campaignToSave.campaignId == null; // Using the abstract equality check here so we catch undefined also
    },
    campaignToSaveUriKey: {
      get() {
        return this.campaignToSave.uriKey;
      },
      async set(newValue) {
        this.campaignToSave.uriKey = newValue;
        await this.$nextTick(); // waits for the text input to re-render, otherwise, the restricted symbols will appear in the text input
        this.campaignToSave.uriKey = newValue.replace(/[^a-zA-Z0-9-_]/g, "");
      },
    },
    isNewUriKeyAlreadyTaken(): boolean {
      if (!this.showEditModal) return false;

      const allUriKeysExceptCurrent = this.campaignData.campaigns.filter(c => c.campaign.uriKey !== null && c.campaign.campaignId !== this.campaignToSave.campaignId).map(c => c.campaign.uriKey);

      return allUriKeysExceptCurrent.includes(this.campaignToSaveUriKey);
    },
  },

  watch: {
    showEditModal() {
      this.isEditingExistingCampaign = false;
    },
    async isEditingExistingCampaign() {
      if (this.showEditModal) {
        await this.$nextTick();
        (this.$refs.domRefUriKeyInput as HTMLInputElement).focus();
      }
    },
  },

  async mounted() {
    useHead({ title: () => this.title ?? "" });

    this.pageId = String(this.$route.params.pageId);
    this.orgInContext = OrgContext.getOrgInContext(this);
    this.title += " - " + this.orgInContext?.name;
    await this.loadData();

    // Open "New campaign" modal with template
    if (this.$route.query.openCreateCampaignModalWithTemplate) {
      await this.$nextTick();
      this.askToCreate(this.$route.query.openCreateCampaignModalWithTemplate as UploadCampaignTemplateTheme);
    }

    // Open "New campaign" modal
    if (this.$route.query.openCreateCampaignModal === "true") {
      await this.$nextTick();
      this.askToCreate();
    }

    // Open "Edit campaign" modal
    if (this.$route.query.campaignToEditId) {
      const campaignToEdit = this.unarchivedCampaigns.find(c => c.campaign.campaignId === this.$route.query.campaignToEditId);
      if (campaignToEdit) {
        await this.$nextTick();
        const campaignToEditIndex = this.unarchivedCampaigns.findIndex(c => c.campaign.campaignId === this.$route.query.campaignToEditId);

        this.editCampaign(campaignToEdit.campaign, campaignToEditIndex);
      }
    }

    // Open "Archive campaign" modal
    if (this.$route.query.campaignToArchiveId) {
      const campaignToArchive = this.unarchivedCampaigns.find(c => c.campaign.campaignId === this.$route.query.campaignToArchiveId);
      if (campaignToArchive) {
        await this.$nextTick();

        this.askToArchive(campaignToArchive);
      }
    }

    MetricSender.sendMetric("Cust-ViewCommunityUploads", null);
  },

  methods: {
    moveItemToNewIndex,
    async copy(uriKeyOrId: string) {
      let link = this.getUploadLink(uriKeyOrId);
      await navigator.clipboard.writeText(link);

      this.justCopied = true;
      setTimeout(() => {
        this.justCopied = false;
      }, 5000);
    },
    async loadData() {
      this.isLoading = true;
      const { data } = await axios.get<UserUploadCampaignsV2>(`${import.meta.env.VITE_API_URL}/useruploadcampaigns/admin/${this.pageId}/v2`);
      this.campaignData = data;

      this.isLoading = false;
    },
    selectTemplateAndContinueCreation(templateTheme: UploadCampaignTemplateTheme) {
      const { title, description, themeId } = this.uploadCampaignTemplates[templateTheme];
      this.campaignToSave.title = title;
      this.campaignToSave.description = description;
      this.campaignToSave.themeId = themeId;
      this.campaignToSave.uriKey = this.uploadCampaignTemplates[templateTheme].uriKey;

      this.showSelectTemplateModal = false;
      this.showEditModal = true;
    },
    askToCreate(templateTheme?: UploadCampaignTemplateTheme) {
      this.campaignToSave = {
        title: null,
        description: null,
      };
      if (this.isNew) {
        this.campaignToSave.uriKey = String(this.campaignData.campaigns.length + 1);
      }
      this.campaignBeforeEdit = null;
      this.campaignEditIndex = -1;

      if (templateTheme) {
        this.selectTemplateAndContinueCreation(templateTheme);
      } else {
        this.showSelectTemplateModal = true;
        // this.showEditModal = true;
      }
    },
    cancelCreate() {
      if (this.campaignEditIndex >= 0) this.campaignData.campaigns[this.campaignEditIndex] = this.campaignBeforeEdit;
      this.showEditModal = false;
    },
    editCampaign(campaign: UserContentUploadCampaignData, index: number) {
      this.campaignToSave = campaign;
      // Shallow clone
      this.campaignBeforeEdit = { ...campaign };
      this.campaignEditIndex = index;
      this.showEditModal = true;
    },
    validate(): boolean {
      this.validationError = null;
      if (this.campaignToSave?.title == null || this.campaignToSave.title.trim().length === 0 || this.campaignToSave?.description == null || this.campaignToSave.description.trim().length === 0) {
        this.validationError = "Add a Title and Description";
        return false;
      }
      return true;
    },
    async saveCampaign(validate: boolean, preview = false, showSpreadTheWordModal = false) {
      if (validate && !this.validate()) return;

      this.campaignToSave.customerId = this.pageId;

      this.isSaving = true;
      const config = { headers: { "Content-Type": "application/json" } };
      let result = await axios.put<UserContentUploadCampaignData, { data: UserContentUploadCampaignData }>(
        `${import.meta.env.VITE_API_URL}/useruploadcampaigns/${this.pageId}/campaign`,
        JSON.stringify(this.campaignToSave),
        config
      );
      if (this.isNew)
        this.campaignData.campaigns.push({
          campaign: result.data,
          photoCount: 0,
          usersUploaded: 0,
          lastUploadDateTime: null,
          latestPhotoIds: [],
        });
      this.isSaving = false;

      if (preview) {
        // Preview leaves the modal open so we need to set the state based on what just saved
        this.editCampaign(result.data, this.campaignEditIndex);
        // Open the url in a new window
        const url = this.getUploadLink(this.campaignToSave.uriKey || this.campaignToSave.campaignId);
        window.open(url, "_blank");
      } else {
        this.showEditModal = false;
      }

      if (showSpreadTheWordModal) {
        this.showSpreadTheWordModal = true;
      }
    },
    getUploadLink(uriKeyOrId: string): string {
      return `${window.location.protocol}//${window.location.host}/upload/${this.pageId}/${uriKeyOrId}`;
    },
    askToArchive(campaign: UserContentUploadCampaignDataV2) {
      this.campaignToSave = campaign.campaign;
      this.showArchiveModal = true;
    },
    async archiveConfirmed() {
      this.isSaving = true;
      this.campaignToSave.expiryDateTime = new Date().toISOString();
      await this.saveCampaign(false);
      this.showArchiveModal = false;
      this.isSaving = false;
    },
    async unarchive(campaign: UserContentUploadCampaignDataV2) {
      this.isSaving = true;
      this.campaignToSave = campaign.campaign;
      this.campaignToSave.expiryDateTime = null;
      await this.saveCampaign(false);
      this.isSaving = false;
    },
    askToSoftDelete(campaign: UserContentUploadCampaignDataV2) {
      this.campaignToSave = campaign.campaign;
      this.showDeleteModal = true;
    },
    async deleteConfirmed() {
      this.isSaving = true;
      const indexToRemove = this.campaignData.campaigns.findIndex(campaign => campaign.campaign.campaignId === this.campaignToSave.campaignId);
      this.campaignToSave.deletedDateTime = new Date().toISOString();
      await this.saveCampaign(false);
      this.showDeleteModal = false;
      this.showArchiveModal = false;
      this.isSaving = false;
      this.campaignData.campaigns.splice(indexToRemove, 1);
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Template tiles =============================================================
.template-tiles {
  max-width: $desktop-wide-right-col-width;
  gap: 27px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  padding-right: 0;

  &__tile {
    width: calc(25% - 27px);
    max-width: 300px;
  }
}

// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .template-tiles {
    gap: $desktop-grid-gap-width;
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .template-tiles {
    gap: $laptop-grid-gap-width;
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .template-tiles {
    gap: $tablet-large-grid-gap-width;

    &__tile {
      width: calc(33% - 27px);
      max-width: 300px;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .template-tiles {
    gap: $tablet-grid-gap-width;

    &__tile {
      width: calc(50% - 17px);
      max-width: 300px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .template-tiles {
    gap: $mobile-grid-gap-width;

    &__tile {
      width: calc(50% - 17px);
      max-width: 300px;
    }
  }
}

// Upload campaign snippets ===================================================
.upload-campaign-snippets {
  gap: $desktop-wide-grid-gap-width;
  display: flex;
  flex-direction: column;

  &__snippet {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .upload-campaign-snippets {
    gap: $desktop-grid-gap-width;
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .upload-campaign-snippets {
    gap: $laptop-grid-gap-width;
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .upload-campaign-snippets {
    gap: $tablet-large-grid-gap-width;
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .upload-campaign-snippets {
    gap: $tablet-grid-gap-width;
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .upload-campaign-snippets {
    gap: $mobile-grid-gap-width;
  }
}

// ============================================================================
.validation-errors {
  color: red;
  font-weight: bold;
  margin-bottom: 10px;
}
.limit-hit-message {
  margin-top: 10px;
}

// Input with prefix ==========================================================
.input-with-prefix {
  height: 41px;
  display: flex !important;
  cursor: text;
  position: relative;
  z-index: 0;

  &__prefix-overlay {
    height: 100%;
    padding: 0 0 3px 14px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.5);
    font:
      14px/14px "Quicksand",
      sans-serif;
    user-select: none;
    pointer-events: none;
  }

  &__input {
    padding: 0 0 3px 0 !important;
    border: none !important;
    background: none !important;
  }

  &__input-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -1;
  }

  &__input:focus + &__input-bg {
    border-color: #4fa2a3;
    outline: 1px #4fa2a3 solid;
  }
}
</style>
