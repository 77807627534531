<template>
  <li
    :class="{
      'visit-step': true,
      'visit-step--with-background': props.isExpandable && _isExpanded,
    }"
  >
    <div
      v-if="!isFirst"
      :class="{
        'visit-step__guide-line': true,
        'visit-step__guide-line--top': true,
        'visit-step__guide-line--top-long': isSectionTitleVisible,
        'visit-step__guide-line--dotted': visitStep.isFuture,
      }"
    ></div>
    <div
      v-if="!isLast"
      :class="{
        'visit-step__guide-line': true,
        'visit-step__guide-line--bottom': true,
        'visit-step__guide-line--bottom-short': isSectionTitleVisible,
        'visit-step__guide-line--dotted': props.allSteps[props.index + 1]?.isFuture,
      }"
    ></div>

    <div v-if="isSectionTitleVisible" class="global-h6 visit-step__section-title">Upcoming Actions</div>

    <SrpDetailsSummary
      :class="{
        'visit-step__details-summary': true,
        'visit-step__details-summary--not-expandable': !props.isExpandable,

        'visit-step__details-summary--green': visitStep.isDone,
        'visit-step__details-summary--orange': visitStep.isUrgent,
        'visit-step__details-summary--gray': visitStep.isFuture,
        'visit-step__details-summary--gray-dark': !visitStep.isDone && !visitStep.isUrgent && !visitStep.isFuture,
      }"
      v-model:is-opened="_isExpanded"
    >
      <template #heading>
        <div
          :class="{
            'visit-step__summary': true,
            'visit-step__summary--green': visitStep.isDone,
            'visit-step__summary--orange': visitStep.isUrgent,
            'visit-step__summary--gray': visitStep.isFuture,
            'visit-step__summary--gray-dark': !visitStep.isDone && !visitStep.isUrgent && !visitStep.isFuture,
          }"
        >
          <h5
            :class="{
              'global-h5': true,
              'visit-step__title': true,
              'visit-step__title--green': visitStep.isDone,
              'visit-step__title--orange': visitStep.isUrgent,
              'visit-step__title--gray': visitStep.isFuture,
              'visit-step__title--gray-dark': !visitStep.isDone && !visitStep.isUrgent && !visitStep.isFuture,
            }"
          >
            <div
              :class="{
                'visit-step__status-icon-wrap': true,
                'visit-step__status-icon-wrap--green': visitStep.isDone,
                'visit-step__status-icon-wrap--orange': visitStep.isUrgent,
                'visit-step__status-icon-wrap--gray': visitStep.isFuture,
                'visit-step__status-icon-wrap--gray-hollow': !visitStep.isDone && !visitStep.isUrgent && !visitStep.isFuture,
              }"
            >
              <IconEmbedded
                :name="iconNameComputed"
                :class="{
                  'visit-step__status-icon': true,
                  'visit-step__status-icon--green': visitStep.isDone,
                  'visit-step__status-icon--orange': visitStep.isUrgent,
                  'visit-step__status-icon--gray': visitStep.isFuture,
                  'visit-step__status-icon--gray-hollow': !visitStep.isDone && !visitStep.isUrgent && !visitStep.isFuture,
                }"
              />
            </div>
            {{ visitStep.text }}
          </h5>

          <div v-if="visitStep.description" class="visit-step__description">
            {{ visitStep.description }}
          </div>
        </div>
      </template>
      <template v-if="props.isExpandable" #details>
        <div class="visit-step__content">
          <slot />
        </div>
      </template>
    </SrpDetailsSummary>
  </li>
</template>

<script setup lang="ts">
import { computed, onMounted } from "vue";

// Types
import { CollabChecklistRow } from "@contracts/collabChecklist";

// Components
import SrpDetailsSummary from "@components/ui/SrpDetailsSummary.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import { IconName } from "@contracts/IconName";

export interface Props {
  allSteps: Array<CollabChecklistRow | null>;
  index: number;
  isExpandable: boolean;
  isExpanded: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  allSteps: () => [],
  index: -1,
  isExpandable: false,
  isExpanded: false,
});

const emit = defineEmits<{
  (e: "toggle"): void;
}>();

// Toggle details/summary =====================================================
const _isExpanded = computed({
  get: () => props.isExpanded,
  set: newVal => {
    if (newVal !== props.isExpanded) emit("toggle");
  },
});

// ============================================================================
const visitStep = computed(() => props.allSteps[props.index]);

// Section title visibility ===================================================
const isSectionTitleVisible = computed(() => props.allSteps[props.index - 1]?.text.toLowerCase() === "upcoming actions");

// Order params ===============================================================
const isFirst = computed(() => props.index === 0);
const isLast = computed(() => props.index === props.allSteps.length - 1);

// Calc icon name =============================================================
const iconNameComputed = computed<IconName>(() => {
  const { isDone, isUrgent, isFuture } = visitStep.value;

  if (isDone) return "check_4";
  else if (isUrgent || (!isDone && !isUrgent && !isFuture)) return "info-simple_4-5";
  else if (visitStep.value.isFuture) return "hourglass_3-5";
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Visit step =================================================================
.visit-step {
  padding: 10px 0 9px;
  position: relative;
  z-index: 0;
  background-color: transparent;
  transition: background-color 0.09s ease-in-out;

  &--with-background {
    background-color: #f0f0f0;
  }

  &__guide-line {
    width: 0;
    border-right: 2px rgba(0, 0, 0, 0.17) solid;
    position: absolute;
    inset: 0 auto auto 32px;
    z-index: -1;

    &--top {
      height: 12px;
    }
    &--top-long {
      height: 62px;
    }
    &--bottom {
      height: calc(100% - 28px);
      top: 28px;
    }
    &--bottom-short {
      height: calc(100% - 76px);
      top: 76px;
    }

    &--dotted {
      border-right-style: dotted !important;
    }
  }

  &__section-title {
    padding: 5px 0 0 55px;
    margin-bottom: 8px;
  }

  &__details-summary {
    :deep(.srp-summary__caret-icon) {
      order: -1;
      width: 13px;
      height: 13px;
      position: relative;
      left: 5px;
      font-size: 17px;
      line-height: 17px;
      opacity: 0.6;
    }

    &--green {
      :deep(.srp-summary__caret-icon) {
        color: #1e4613 !important;
      }
    }
    &--orange {
      :deep(.srp-summary__caret-icon) {
        color: #853121 !important;
      }
    }
    &--gray {
      :deep(.srp-summary__caret-icon) {
        color: rgba(0, 0, 0, 0.5) !important;
      }
    }
    &--gray-dark {
      :deep(.srp-summary__caret-icon) {
        color: rgba(0, 0, 0, 0.7) !important;
      }
    }

    &--not-expandable {
      pointer-events: none;

      :deep(.srp-summary__caret-icon) {
        opacity: 0;
      }
    }

    :deep(.srp-summary__title-wrap) {
      padding-right: 22px;
      padding-left: 25px;
    }
  }

  :deep(.srp-summary__title-wrap) {
    padding: 0;
  }

  &__summary {
    flex-grow: 1;

    &--green {
      color: #1e4613 !important;
    }
    &--orange {
      color: #853121 !important;
    }
    &--gray {
      color: rgba(0, 0, 0, 0.5) !important;
    }
    &--gray-dark {
      color: rgba(0, 0, 0, 0.7) !important;
    }
  }

  &__title {
    display: flex;
    font-weight: 500;

    &--green {
      color: #1e4613 !important;
    }
    &--orange {
      color: #853121 !important;
    }
    &--gray {
      color: rgba(0, 0, 0, 0.5) !important;
    }
    &--gray-dark {
      color: rgba(0, 0, 0, 0.7) !important;
    }
  }

  &__description {
    padding: 3px 0 0 30px;
    color: #5b5b5b;
    font: 14px/17px sans-serif;
  }

  &__status-icon-wrap {
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-right: 14px;
    box-sizing: border-box;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    inset: 2px auto auto -2px;
    z-index: 2;

    &--green {
      background: #3d8528;
    }
    &--orange {
      background: #cc6855;
    }
    &--gray {
      background: #969696;
    }
    &--gray-hollow {
      background: #fff;
      border: 2px #999 solid;
    }
  }

  &__status-icon {
    width: 18px;
    min-width: 18px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    :deep(svg) {
      fill: rgba(255, 255, 255, 1);
    }

    &--green {
      width: 13px;
      min-width: 13px;
    }
    &--orange {
      width: 15px;
      min-width: 15px;

      &::before {
        transform: rotate(180deg);
      }
    }
    &--gray,
    &--gray-hollow {
      width: 12px;
      min-width: 12px;
    }
    &--gray-hollow {
      :deep(svg) {
        fill: rgba(0, 0, 0, 0.5);
      }
    }
  }

  &__content {
    padding: 15px 23px 12px 55px;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .visit-step {
    &__details-summary {
      :deep(.srp-summary__title-wrap) {
        padding: 0 14px 0 14px;
        margin: 0 !important;
      }
    }

    &__guide-line {
      left: 21px;
    }

    &__section-title {
      padding: 20px 0 0 44px;
    }

    &__content {
      padding: 12px 13px 12px 45px;
    }
  }
}
</style>
