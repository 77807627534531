<template>
  <div class="slide-about-photos">
    <div class="slide-about-photos__intro-text">
      Destinations want to use your Shrpa content to repost on their social media, website, and other marketing channels. Take at least 20+ photos per stop to have great options to choose from!
    </div>

    <!-- Usage ways list -->
    <div class="usage-ways-list slide-about-photos__usage-ways-list">
      <h5 class="global-h5 usage-ways-list__title">These are the primary ways destinations use your content:</h5>
      <ul class="usage-ways-list__snippets-list">
        <!-- Usage way snippet -->
        <li class="usage-way-snippet usage-ways-list__snippet" v-for="snippet in usageWaySnippets" :key="snippet.name">
          <img class="usage-way-snippet__img" :src="`/images/collabGuide/${snippet.image}`" :alt="snippet.name" />
          <div class="usage-way-snippet__name">{{ snippet.name }}</div>
        </li>
        <!-- / Usage way snippet -->
      </ul>
    </div>
    <!-- / Usage ways list -->

    <!-- Get creative snippet -->
    <div class="get-creative-snippet slide-about-photos__get-creative-snippet">
      <h5 class="global-h5 get-creative-snippet__title">Get creative with it!</h5>
      <div class="get-creative-snippet__description">
        Capture lots of content from creative angles, using your travel buddies as your main subject.<br />
        Edit your content using filters or free apps, like Adobe Lightroom, and other simple editing techniques.
      </div>

      <iframe
        width="300"
        height="170"
        src="https://www.youtube.com/embed/DN13bERDNJk?si=lUVA0xZ9Vo6_4Em-&amp;start=28"
        title="Photo Editing Tips"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
      <!--<video class="get-creative-snippet__video" controls controlsList="nodownload">
        <source src="https://cdn.shrpa.com/videos/howto/collab-intro.mp4" type="video/mp4" />
        Your browser does not support HTML video.
      </video>-->
    </div>
    <!-- / Get creative snippet -->

    <!-- Photo examples -->
    <div class="photo-examples slide-about-photos__photo-examples">
      <h5 class="global-h5 photo-examples__title">Examples of high quality, creative photos:</h5>
      <SrpMasonry class="photo-examples__gallery" :items="examplePhotos" :columns="screenSize === 'mobile' ? 3 : 5" :columnGap="10" style="margin-bottom: -10px">
        <template #default="{ item, rebuildMasonry }">
          <a lang="en" hreflang="en" data-fancybox="gallery" :href="`${contentBaseUri}/cms/images/expeditions/${item}`">
            <SrpFileThumbnail :filePath="`${contentBaseUri}/cms/images/expeditions/thumbnocrop/${item}`" style="margin-bottom: 10px" :isFixedAspectRatio="false" @imageLoad="rebuildMasonry" />
          </a>
        </template>
      </SrpMasonry>
    </div>
    <!-- / Photo examples -->

    <SrpButton class="slide-about-photos__next-slide-button" @click="emit('goToNextSlide')" style="padding-right: 30px">
      <template #icon>
        <IconEmbedded name="arrow-bottom_2-5" color="rgba(255, 255, 255, 0.5)" :size="24" style="position: relative; top: 2px" />
      </template>
      Next
    </SrpButton>
  </div>
</template>

<script setup lang="ts">
import { inject, Ref } from "vue";

// Components
import SrpButton from "@components/ui/SrpButton.vue";
import SrpFileThumbnail from "@components/ui/SrpFileThumbnail.vue";
import SrpMasonry from "@components/ui/SrpMasonry.vue";

// Types
import { ScreenSize } from "@contracts/screenSize";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

// Global variables
const screenSize = inject("screenSize") as Ref<ScreenSize>;
const contentBaseUri = globalThis.Bootstrap.Config.contentCdnBaseUri;

const emit = defineEmits<{
  (e: "goToNextSlide"): void;
}>();

// Usage way snippets =========================================================
const usageWaySnippets = [
  { name: "On their website", image: "website.jpg" },
  { name: "On their social media", image: "socialMedia.jpg" },
  { name: "Print marketing", image: "marketing.jpg" },
];

// Example photos =============================================================
const examplePhotos = [
  "33733b66-6f93-4d79-b1ee-57cd7cf938df.jpg",
  "4c62ac71-892c-4ae9-b39e-7a2965daae8a.jpg",
  "e9137b89-4484-4de4-9fbb-b0bbff36517e.jpg",
  "e3baa2ae-57e4-411f-8292-054711b6ceed.jpg",
  "ce4c4663-485f-48e4-84ac-db2454cf9e9f.jpg",
  "be7e18cc-6edf-48f0-9579-45556ce7dfc2.jpg",
  "bdbd6cec-d71e-489a-9686-2f40223a91f0.jpg",
  "61549424-8a89-42cd-91dc-8b369a09c991.jpg",
  "50755ea0-ecaf-43bc-a445-8727a8bbed29.jpg",
  "c720b43b-43a1-47fc-b14e-c12c825b842b.jpg",
];
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Usage way snippet ==========================================================
.usage-way-snippet {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__img {
    aspect-ratio: 3/2;
    width: 100%;
  }

  &__name {
    text-align: center;
  }
}

// Usage ways list ============================================================
.usage-ways-list {
  padding-bottom: 30px;
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;

  &__title {
    margin-bottom: 17px;
  }

  &__snippets-list {
    padding: 0;
    margin: 0;
    display: flex;
    list-style: none;
  }

  &__snippet {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .usage-ways-list {
    padding-bottom: 15px;

    &__snippets-list {
      flex-wrap: wrap;
      justify-content: center;
    }

    &__snippet {
      width: calc(50% - 20px);
      margin: 0 10px 15px;
    }
  }
}

// Get creative snippet =======================================================
.get-creative-snippet {
  padding-bottom: 30px;
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;

  &__title {
    margin-bottom: 6px;
  }

  &__description {
    margin-bottom: 17px;
  }

  &__video {
    width: 100%;
    max-width: 270px;
  }
}

// Photo examples =============================================================
.photo-examples {
  &__title {
    margin-bottom: 20px;
  }

  &__gallery {
  }
}

// Slide about photos =========================================================
.slide-about-photos {
  &__intro-text {
    margin-bottom: 30px;
  }

  &__usage-ways-list {
    margin-bottom: 28px;
  }

  &__get-creative-snippet {
    margin-bottom: 28px;
  }

  &__photo-examples {
    margin-bottom: 50px;
  }

  &__next-slide-button {
  }
}
</style>
