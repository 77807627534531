import { IconName } from "@contracts/IconName";

export interface StopSnippet {
  title: StopTitles;
  icon?: { name: IconName; size: number };
  buttonText?: string;
  editCallback?: () => void;
  time?: string;
}

export enum StopTitles {
  Boundary = "Set Travel\nBoundary",
  Portfolio = "Set up\nyour Portfolio",
  Adventure = "Last Step!",
  Destinations = "Work with\nDestinations",
}
