<template>
  <div class="fb-post-snippet">
    <!-- User info skeleton -->
    <div class="user-info-skeleton fb-post-snippet__user-info-skeleton">
      <div class="user-info-skeleton__avatar"></div>
      <div class="user-info-skeleton__text-side">
        <div class="user-info-skeleton__text-line-1"></div>
        <div class="user-info-skeleton__text-line-2"></div>
      </div>
    </div>
    <!-- / User info skeleton -->

    <div class="fb-post-snippet__caption-textarea-wrap">
      <slot name="textarea" />
    </div>

    <!-- Photos grid -->
    <ul
      :class="{
        'photos-grid': true,
        'photos-grid--1-photo-layout': props.imagesList.length === 1,
        'photos-grid--2-photos-layout': props.imagesList.length === 2,
        'photos-grid--3-photos-layout': props.imagesList.length === 3,
        'photos-grid--4-photos-layout': props.imagesList.length === 4,
        'photos-grid--5-photos-layout': props.imagesList.length >= 5,
        'fb-post-snippet__photos-grid': true,
      }"
    >
      <li class="photos-grid__photo-wrap" v-for="(mediaUrl, index) in props.imagesList.slice(0, 5)" :key="index">
        <div v-if="props.imagesList.length > 5 && index === 4" class="photos-grid__counter-overlay">+{{ props.imagesList.length - 5 }}</div>
        <MediaThumbnail class="photos-grid__photo" :media-url="mediaUrl" />
      </li>
    </ul>
    <!-- / Photos grid -->

    <!-- Footer skeleton -->
    <div class="footer-skeleton fb-post-snippet__footer-skeleton">
      <div class="footer-skeleton__top-panel">
        <div class="footer-skeleton__icon-placeholder"></div>
        <div class="footer-skeleton__text-placeholder"></div>
      </div>
      <div class="footer-skeleton__middle-panel">
        <div class="footer-skeleton__3rd-column">
          <div class="footer-skeleton__icon-placeholder footer-skeleton__icon-placeholder--big"></div>
          <div class="footer-skeleton__text-placeholder footer-skeleton__text-placeholder--big"></div>
        </div>
        <div class="footer-skeleton__3rd-column">
          <div class="footer-skeleton__icon-placeholder footer-skeleton__icon-placeholder--big"></div>
          <div class="footer-skeleton__text-placeholder footer-skeleton__text-placeholder--big"></div>
        </div>
        <div class="footer-skeleton__3rd-column">
          <div class="footer-skeleton__icon-placeholder footer-skeleton__icon-placeholder--big"></div>
          <div class="footer-skeleton__text-placeholder footer-skeleton__text-placeholder--big"></div>
        </div>
      </div>
      <div class="footer-skeleton__bottom-panel">
        <div class="footer-skeleton__icon-placeholder footer-skeleton__icon-placeholder--huge"></div>
        <div class="footer-skeleton__textarea-placehplder"></div>
      </div>
    </div>
    <!-- / Footer skeleton -->
  </div>
</template>

<script setup lang="ts">
import MediaThumbnail from "@components/MediaThumbnail.vue";

export interface Props {
  imagesList: Array<string>;
}
const props = withDefaults(defineProps<Props>(), {
  imagesList: () => [],
});
</script>

<style scoped lang="scss">
@mixin placeholderElement {
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.1);
}

// User info skeleton =========================================================
.user-info-skeleton {
  display: flex;
  align-items: center;

  &__avatar {
    @include placeholderElement;
    width: 42px;
    min-width: 42px;
    height: 42px;
    margin-right: 9px;
    border-radius: 6px;
  }

  &__text-side {
    flex-grow: 1;
  }

  &__text-line-1 {
    @include placeholderElement;
    max-width: 172px;
    height: 14px;
    margin-bottom: 7px;
  }

  &__text-line-2 {
    @include placeholderElement;
    max-width: 299px;
    height: 8px;
  }
}

// Photos grid ================================================================
.photos-grid {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;

  &--1-photo-layout {
    .photos-grid__photo-wrap {
      width: 100%;
      aspect-ratio: 100/66;
    }
  }

  &--2-photos-layout {
    .photos-grid__photo-wrap {
      width: calc(50% - 1px);
      margin-right: 2px;
      aspect-ratio: 1/1;
    }
    .photos-grid__photo-wrap:last-child {
      margin-right: 0;
    }
  }

  &--3-photos-layout {
    .photos-grid__photo-wrap:nth-child(1) {
      width: 100%;
      aspect-ratio: 2/1;
      margin-bottom: 4px;
    }
    .photos-grid__photo-wrap:nth-child(2) {
      width: calc(50% - 2px);
      margin-right: 4px;
    }
    .photos-grid__photo-wrap:nth-child(3) {
      width: calc(50% - 2px);
    }
  }

  &--4-photos-layout {
    .photos-grid__photo-wrap:nth-child(1) {
      width: 100%;
      aspect-ratio: 100/75;
      margin-bottom: 4px;
    }
    .photos-grid__photo-wrap:nth-child(2),
    .photos-grid__photo-wrap:nth-child(3) {
      aspect-ratio: 1/1;
      width: calc(33.3% - 3px);
      margin-right: 4px;
    }
    .photos-grid__photo-wrap:nth-child(4) {
      aspect-ratio: 1/1;
      width: calc(33.3% - 2px);
    }
  }

  &--5-photos-layout {
    .photos-grid__photo-wrap:nth-child(1) {
      width: calc(50% - 2px);
      aspect-ratio: 1/1;
      margin: 0 4px 4px 0;
    }

    .photos-grid__photo-wrap:nth-child(2) {
      width: calc(50% - 2px);
      aspect-ratio: 1/1;
      margin-bottom: 4px;
    }

    .photos-grid__photo-wrap:nth-child(3),
    .photos-grid__photo-wrap:nth-child(4) {
      aspect-ratio: 1/1;
      width: calc(33.3% - 3px);
      margin-right: 4px;
    }

    .photos-grid__photo-wrap:nth-child(5) {
      aspect-ratio: 1/1;
      width: calc(33.3% - 2px);
    }
  }

  &__photo-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
    overflow: hidden;
  }

  &__photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    :deep(.media-thumbnail-checkbox__video-wrap) {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    :deep(.media-thumbnail-checkbox__video) {
      display: flex;
      justify-content: center;
      align-items: center;

      a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  &__counter-overlay {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: 1;
    color: #fff;
    font: bold 24px/24px sans-serif;
    background: rgba(0, 0, 0, 0.5);
  }
}

// Footer skeleton ============================================================
.footer-skeleton {
  &__top-panel {
    height: 32px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
    display: flex;
    align-items: center;
  }

  &__middle-panel {
    height: 44px;
    margin-bottom: 8px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__bottom-panel {
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__3rd-column {
    width: 33.3%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon-placeholder {
    width: 17px;
    min-width: 17px;
    height: 17px;
    margin-right: 4px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.1);

    &--big {
      width: 23px;
      min-width: 23px;
      height: 23px;
      margin-right: 6px;
    }

    &--huge {
      width: 34px;
      min-width: 34px;
      height: 34px;
      margin-right: 6px;
    }
  }

  &__text-placeholder {
    width: 36px;
    height: 10px;
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.1);

    &--big {
      width: 53px;
      height: 12px;
    }
  }

  &__textarea-placehplder {
    flex-grow: 1;
    height: 34px;
    border-radius: 100px;
    background: rgba(0, 0, 0, 0.1);
  }
}

// Fb post snippet ============================================================
.fb-post-snippet {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__user-info-skeleton {
    width: calc(100% - 32px);
    margin-bottom: 13px;
  }

  &__caption-textarea-wrap {
    width: calc(100% - 32px);
    margin-bottom: 14px;

    :deep(textarea) {
      width: 100%;
      height: 140px;
      min-height: 140px;
      box-sizing: border-box;
      border: 1px #ababab solid;
      border-radius: 6px;
      color: #5b5b5b;
      font-family: sans-serif;
      transition: none !important;
    }
  }

  &__photos-grid {
    width: 100%;
  }

  &__footer-skeleton {
    width: calc(100% - 26px);
  }
}
</style>
