<template>
  <div class="slide-content">
    <img class="slide-content__illustration" alt="slide1" src="/images/onboarding/journey.svg" style="width: 283px" />

    <div class="global-h5 slide-content__subtitle">We’re excited to highlight your destination with the amazing content it deserves!</div>

    <p class="slide-content__text-paragraph">We’ll collect a few high-value pieces of data from you to maximize your success on the platform</p>

    <div class="slide-content__divider"></div>

    <p class="slide-content__text-paragraph slide-content__text-paragraph--bigger-bottom-margin">
      If you ever have questions reach out to via the ? icon in the lower right or email <CopyTextNew>support@shrpa.com</CopyTextNew>
    </p>

    <div class="ui orange large button slide-content__main-cta-button" @click="$emit('goToNextStep')">
      Let’s get started&nbsp;<IconEmbedded name="arrow-bottom_3-5" :size="26" color="rgba(255, 255, 255, 0.4)" />
    </div>

    <p class="slide-content__text-paragraph slide-content__text-paragraph--italic">This typically takes ~5 minutes</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// Components
import CopyTextNew from "@components/CopyTextNew.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export default defineComponent({
  name: "SlideIntro",

  components: {
    IconEmbedded,
    CopyTextNew,
  },

  emits: ["goToNextStep"],
});
</script>

<style scoped lang="scss">
@import "./slide-styles.scss";
</style>
