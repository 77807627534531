<template>
  <div>
    <div
      :class="{
        'code-snippet': true,
        'code-snippet--highlighted': isCodeBlockHighlighted,
      }"
      ref="domRefCodeContainer"
      style="margin-bottom: 10px"
    >
      <table class="code-snippet__table">
        <tbody>
          <tr v-for="(line, index) in Object.keys(slots)">
            <td class="code-snippet__td code-snippet__td--no-user-select">
              <i v-if="isTextCopyingInProgress" g>&nbsp;&nbsp;</i>
              <i v-else g>{{ index + 1 }}&nbsp;</i>
            </td>
            <td class="code-snippet__td">
              <slot :name="`line${index + 1}`" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <SrpButton size="small" @click="copyCodeToClipboard" :isDisabled="isTextCopied">
      <template #icon>
        <IconEmbedded :name="isTextCopied ? 'check_4' : 'copy_2'" color="rgba(255, 255, 255, 0.6)" :size="17" />
      </template>
      {{ isTextCopied ? "Copied to clipboard" : "Copy code" }}
    </SrpButton>
  </div>
</template>

<script setup lang="ts">
import { ref, nextTick, useSlots } from "vue";

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import SrpButton from "@components/ui/SrpButton.vue";

const slots = useSlots();

// Copy to clipboard ==========================================================
const domRefCodeContainer = ref(null);
const isTextCopyingInProgress = ref(false); // hides the line numbers to avoid copying them to the clipboard
const isTextCopied = ref(false);
const isCodeBlockHighlighted = ref(false);

async function copyCodeToClipboard() {
  isTextCopyingInProgress.value = true;

  await nextTick();
  const text = domRefCodeContainer.value.textContent.replace(/\s+/g, " "); // removes all of the "&nbsp;" symbols

  navigator.clipboard
    .writeText(text)
    .then(() => {
      isTextCopied.value = true;
      isCodeBlockHighlighted.value = true;
      console.log("Text copied to clipboard successfully");

      setTimeout(() => (isCodeBlockHighlighted.value = false), 170);
      setTimeout(() => (isTextCopied.value = false), 2000);

      isTextCopyingInProgress.value = false;
    })
    .catch(err => {
      console.error("Error copying to clipboard", err);
    });
}
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Code snippet ===============================================================
.code-snippet {
  padding: 14px 21px 16px;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  position: relative;
  overflow-x: scroll;
  color: #90bc6c;
  font:
    16px/22px "Consolas",
    monospace;
  background: #11242c;
  opacity: 1;
  transition: opacity 0.12s ease-in-out;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 7px !important;
    height: 7px !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.37);
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }

  *::selection {
    color: inherit;
    background-color: rgba(0, 200, 255, 0.2);
  }

  &--highlighted {
    opacity: 0.6;
  }

  &__table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  &__td {
    vertical-align: top;
    word-break: keep-all;
    white-space: nowrap;

    &--no-user-select {
      pointer-events: none;
      user-select: none;
    }
  }

  // Syntax colors ====================
  // green
  :deep(i[g]) {
    color: rgba(77, 77, 77, 1);
    font-style: normal;
  }
  // teal
  :deep(i[t]) {
    color: rgba(79, 172, 142, 1);
    font-style: normal;
  }
  // red
  :deep(i[r]) {
    color: rgba(242, 97, 82, 1);
    font-style: normal;
  }
  // pink
  :deep(i[p]) {
    color: rgba(200, 135, 223, 1);
    font-style: normal;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .code-snippet {
    &__code-column {
    }
  }
}
</style>
