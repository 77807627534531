import { ApplicationInsights, ICustomProperties, SeverityLevel } from "@microsoft/applicationinsights-web";
import Logger from "@helpers/Logger";

let globalRemoteLogger: RemoteLogger;

export function getGlobalRemoteLogger() {
  return globalRemoteLogger;
}

// Fires a log event to AppInsights.
// ALSO logs to the local logger
export class RemoteLogger {
  // https://github.com/microsoft/ApplicationInsights-JS/blob/master/AISKU/API.md
  // https://github.com/microsoft/ApplicationInsights-JS/blob/master/shared/AppInsightsCommon/src/Interfaces/Contracts/SeverityLevel.ts

  appInsights: ApplicationInsights;

  constructor(appInsightsObject: ApplicationInsights) {
    this.appInsights = appInsightsObject;
    globalRemoteLogger = this;
  }

  info(message: string, sendImmediately = false, waitForSend = false, customProperties?: ICustomProperties) {
    this.log(message, SeverityLevel.Information, sendImmediately, waitForSend, customProperties);
    Logger.info(message);
  }

  warn(message: string, sendImmediately = false, waitForSend = false, customProperties?: ICustomProperties) {
    this.log(message, SeverityLevel.Warning, sendImmediately, waitForSend, customProperties);
    Logger.warn(message);
  }

  error(message: string, sendImmediately = false, waitForSend = false, customProperties?: ICustomProperties) {
    this.log(message, SeverityLevel.Error, sendImmediately, waitForSend, customProperties);
    Logger.error(message);
  }

  log(message: string, logLevel: SeverityLevel, sendImmediately = false, waitForSend = false, customProperties?: ICustomProperties) {
    // NOTE! AppInsights requires customProperty values to be strings or it just silently excludes those values.
    this.appInsights.trackTrace({ message: message, severityLevel: logLevel }, this.stringifyProperties(customProperties));
    if (sendImmediately) {
      // If we want to wait for send, then async should be false here.
      // Should be rare that we do this, probably only before a redirect.
      this.appInsights.flush(!waitForSend);
    }
  }

  stringifyProperties(customProperties: ICustomProperties): ICustomProperties {
    if (!customProperties) return customProperties;
    // Note: This will turn null or undefined values into the string "null" or "undefined"
    const stringifiedProperties = Object.keys(customProperties).reduce((acc, key) => {
      acc[key] = String(customProperties[key]);
      return acc;
    }, {});
    return stringifiedProperties;
  }

  setAuthenticatedUserContext(userId: string) {
    // Annoyingly AppInsights doesn't allow the pipe character, which Auth0 uses for their Ids
    const cleanedUserId = userId.replace("|", "-");
    this.appInsights.setAuthenticatedUserContext(cleanedUserId);
    this.info(`RemoteLogger.setAuthenticatedUserContext: Cleaned=${cleanedUserId} Original=${userId}`);
  }

  clearAuthenticatedUserContext() {
    this.appInsights.clearAuthenticatedUserContext();
    this.info(`RemoteLogger.clearAuthenticatedUserContext`);
  }
}
