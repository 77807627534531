<template>Redirecting...</template>
<script setup lang="ts">
import { useUserProfileStore } from "@stores/userProfileStore";
import { watchEffect } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const userProfileStore = useUserProfileStore();

// Watch for getViewingUserProfile to be loaded, then redirect to the appropriate page
watchEffect(() => {
  if (userProfileStore.getViewingUserProfile) {
    const isDestinationAdmin = userProfileStore.getViewingUserProfile.adminOf.length > 0;
    if (isDestinationAdmin) {
      // destination admins go to edit profile page as there is not dedicated notificaiton settings page for admins
      router.replace({ name: "CustomerAdminProfile", params: { creatorId: userProfileStore.getViewingUserProfile.sherpaId } });
    } else {
      // creators go to notification settings page
      router.replace({ name: "CreatorSettings", params: { creatorId: userProfileStore.getViewingUserProfile.sherpaId } });
    }
  }
});
</script>
