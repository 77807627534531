<template>
  <div class="help-floating-button">
    <!-- UI for unauthenticated users -->
    <!-- Help info container -->
    <div
      :class="{
        'help-info-container': true,
        'help-floating-button__help-info-container': true,
        'help-floating-button__help-info-container--invisible': !isHelpVisible,
      }"
    >
      <h2 class="global-h2 help-info-container__title">Questions?</h2>

      <p class="help-info-container__paragraph-1">
        Contact us anytime!<br />
        <CopyTextNew class="help-info-container__copy-text-snippet">
          {{ sendToEmail }}
        </CopyTextNew>
      </p>

      <p class="help-info-container__paragraph-2">
        Thanks for being part of Shrpa,<br />
        we're always happy to help!
      </p>
    </div>
    <!-- / Help info container -->

    <!-- The new UI just uses the ContactUser component -->
    <button class="ui icon circular primary button helpButton help-floating-button__button-itself" @click="toggle()" ref="domRefButtonItself">
      <i v-if="isHelpVisible" class="close icon"></i>
      <i v-else class="question inverted icon"></i>
    </button>

    <!-- Contact Shrpa Modal -->
    <ContactUser
      v-model:visible="isContactShrpaModalVisible"
      :sendToFirstName="'Shrpa'"
      :sendToEmail="sendToEmail"
      :subject="contactShrpaSubject"
      :messagePrefix="contactShrpaSubject"
      @close="isContactShrpaModalVisible = false"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// Components
import CopyTextNew from "@components/CopyTextNew.vue";
import ContactUser from "./Modals/ContactUser.vue";
import { mapState, mapStores } from "pinia";

// Stores
import { useUserProfileStore } from "@stores/userProfileStore";
import { useMiscellaneousStore } from "@stores/miscellaneous";

export default defineComponent({
  name: "HelpFloatingButton",

  components: {
    CopyTextNew,
    ContactUser,
  },

  data() {
    return {
      supportEmail: "support@shrpa.com",
      isContactShrpaModalVisible: false,
      // We show the new "we'll email for you" UI if they're authenticated and the old one if unauthenticated (since we don't want an anonymous email endpoint)

      // Old UI
      isHelpVisible: false,
      isCopiedVisible: false,
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["getViewingUserProfile"]),
    ...mapStores(useMiscellaneousStore),
    contactShrpaSubject(): string {
      return `${this.getViewingUserProfile?.firstName} has a question for Shrpa`;
    },
    sendToEmail(): string {
      // Adjust the group to send the email to depending if they're a customer or creator
      if (this.getViewingUserProfile) {
        if (this.getViewingUserProfile?.adminOf?.length > 0) return this.supportEmail;
        else return "creators@shrpa.com";
      }
      return this.supportEmail;
    },
  },

  async mounted() {
    await this.$nextTick();
    this.setHelpButtonSizeAndPosition();
  },

  unmounted() {
    this.miscellaneousStore.helpButtonSizeAndPosition = { width: 0, height: 0, top: 0, left: 0 };
  },

  async update() {
    await this.$nextTick();
    this.setHelpButtonSizeAndPosition();
  },

  methods: {
    setHelpButtonSizeAndPosition() {
      const buttonClientRect = (this.$refs.domRefButtonItself as HTMLDivElement).getBoundingClientRect();

      this.miscellaneousStore.helpButtonSizeAndPosition = {
        width: buttonClientRect.width,
        height: buttonClientRect.height,
        top: buttonClientRect.top,
        left: buttonClientRect.left,
      };
    },
    toggle() {
      if (this.getViewingUserProfile) {
        this.isContactShrpaModalVisible = true;
      } else {
        // Old (unauthenticated) UI
        this.isHelpVisible = !this.isHelpVisible;
        this.isCopiedVisible = false;
      }
    },
    copy() {
      navigator.clipboard.writeText(this.supportEmail).then(
        () => {
          console.info("Copied to clipboard: " + this.supportEmail);
        },
        function (err) {
          console.error("Failed to copy to clipboard: ", err);
        }
      );
      this.isCopiedVisible = true;
    },
  },
});
</script>

<style scoped lang="scss">
// Help info container ========================================================
.help-info-container {
  width: 260px;
  height: 174px;
  padding: 18px 26px 23px;
  box-sizing: border-box;
  border-radius: 10px;
  color: #5b5b5b;
  font:
    normal 14px/18px "Helvetica Neue",
    sans-serif;
  background: white;
  box-shadow:
    0 0 10px 0 rgba(black, 0.1),
    0 20px 25px -5px rgba(black, 0.12) !important;

  &__title {
    margin-bottom: 10px;
    font-weight: 700;
  }

  &__paragraph-1 {
    padding: 0;
    margin: 0 0 15px 0;
    font:
      normal 14px/20px "Helvetica Neue",
      sans-serif;
  }

  &__paragraph-2 {
    padding: 0;
    margin: 0;
    font:
      italic normal 14px/20px "Helvetica Neue",
      sans-serif;
  }

  &__copy-text-snippet {
    font-weight: 600;
  }
}

// Help floating button =======================================================
.help-floating-button {
  width: 40px;
  height: 40px;
  position: relative;

  &__button-itself {
    box-shadow: 0 10px 10px -5px rgba(black, 0.25) !important;
  }

  &__help-info-container {
    position: absolute;
    inset: -190px 0 auto auto;
    transform: translateY(0);
    opacity: 1;
    transition:
      transform 0.12s ease-in-out,
      opacity 0.12s ease-in-out;

    &--invisible {
      transform: translateY(20px);
      opacity: 0;
      pointer-events: none;
    }
  }
}
</style>
