<template>
  <SrpModal :isVisible="isVisible" @close="close" size="small" :zIndex="zIndex">
    <template #content>
      <div class="global-h1" style="margin-bottom: 18px">Invite a new Admin</div>
      <input type="text" v-model="inviteEmail" style="max-width: 600px" placeholder="Email address" ref="inviteEmailInput" />
    </template>
    <template #footer>
      <span style="flex-grow: 1; display: flex; color: rgb(200, 50, 50)" v-if="isInvitesListIncludesThisEmail">This email address already exists on the invite list</span>
      <span style="flex-grow: 1; display: flex; color: rgb(200, 50, 50)" v-if="isAdminsListIncludesThisEmail">An administrator with this email address already exists</span>
      <SrpButton shape="rectangle" :isLoading="isLoading" :isDisabled="!isEmailValid || isLoading || isInvitesListIncludesThisEmail || isAdminsListIncludesThisEmail" @click="inviteNewAdmin">
        <template #icon>
          <IconEmbedded :name="isLoading ? 'loader_3' : 'plus_3-5'" :size="20" color="rgba(255, 255, 255, 0.5)" />
        </template>
        Invite
      </SrpButton>
    </template>
  </SrpModal>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// Types
import { UserAdmin, InviteSlot } from "@contracts/userAdmins";

// Components
import SrpButton from "@components/ui/SrpButton.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export default defineComponent({
  name: "InviteModal",

  components: {
    IconEmbedded,
    SrpModal,
    SrpButton,
  },

  props: {
    isVisible: { type: Boolean, required: true },
    invitesList: { type: Array as () => Array<InviteSlot>, required: true },
    adminsList: { type: Array as () => Array<UserAdmin>, required: true },
    inviteFunc: { type: Function, required: true },
    zIndex: { type: Number, default: 80, required: false },
  },

  model: { prop: "isVisible", event: "close" },

  emits: ["close", "addInvite"],

  data() {
    return {
      pageId: "",

      inviteEmail: "",
      isLoading: false,

      clearInputTimeout: null,
    };
  },

  computed: {
    isEmailValid(): boolean {
      const validationRegex = /^[\w+-\.]+@([\w-]+\.)+[\w-]{2,4}$/gim;

      return Boolean(this.inviteEmail.match(validationRegex));
    },
    isInvitesListIncludesThisEmail(): boolean {
      return Boolean(this.invitesList.find((item: InviteSlot) => item.email.toLowerCase() === this.inviteEmail.toLowerCase()));
    },
    isAdminsListIncludesThisEmail(): boolean {
      return Boolean(this.adminsList.find((item: UserAdmin) => item.adminEmail.toLowerCase() === this.inviteEmail.toLowerCase()));
    },
  },

  watch: {
    isVisible() {
      if (this.isVisible) {
        setTimeout(() => {
          (this.$refs.inviteEmailInput as HTMLInputElement)?.focus();
        }, 0);
      }
    },
  },

  mounted() {
    // @ts-ignore
    this.pageId = this.$route.params.pageId;
  },

  beforeUnmount() {
    clearTimeout(this.clearInputTimeout);
  },

  methods: {
    close() {
      this.$emit("close");
    },
    async inviteNewAdmin(): Promise<void> {
      this.isLoading = true;

      await this.inviteFunc(this.inviteEmail);

      this.$emit("addInvite");
      this.clearInputTimeout = setTimeout(() => {
        this.inviteEmail = "";
        this.isLoading = false;
      }, 500);
      this.$emit("close");
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/mixins/animations/gradient-spin-white.scss";

.spinning {
  &::before {
    @include gradientSpinWhite;
  }
}
// Invite button ==============================================================
.invite-button {
  height: 40px;
  padding-top: 0;
  padding-bottom: 2px;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>
