<template>
  <div
    :class="{
      'msg-count-badge': true,
      'msg-count-badge--hoverable': props.isHoverable,
    }"
  >
    <IconEmbedded class="msg-count-badge__icon" name="chat_3" :size="12" color="rgba(255, 255, 255, 1)" />
    <div class="msg-count-badge__text">{{ props.count > props.skipAfter ? skipAfter : props.count }}{{ props.count > props.skipAfter ? "+" : "" }}</div>
  </div>
</template>

<script setup lang="ts">
// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";

const props = withDefaults(
  defineProps<{
    count: number;
    skipAfter?: number;
    isHoverable?: boolean;
  }>(),
  {
    count: 0,
    skipAfter: 9, // when to start showing "+" after the count number
    isHoverable: false,
  }
);
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";

// Messages count badge =======================================================
.msg-count-badge {
  height: 18px;
  padding: 0 6px;
  display: inline-flex;
  align-items: center;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 100px;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -1;
    background: rgba(236, 86, 59, 1);
    transition:
      width 0.07s ease-in-out,
      left 0.07s ease-in-out,
      background-color 0.07s ease-in-out;
  }
  &--hoverable:hover::before {
    width: calc(100% + 4px);
    left: -2px;
    background: rgba(173, 64, 44, 1);
  }

  // increases the clickable area
  &--hoverable::after {
    content: "";
    width: calc(100% + 20px);
    height: calc(100% + 15px);
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
  }

  &__icon {
    margin-right: 2px;
  }

  &__text {
    color: rgba(255, 255, 255, 1);
    font: bold 12px/14px sans-serif;
  }
}
</style>
