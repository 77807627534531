import axios from "axios";
import { ref } from "vue";
import { UserAdmin, InviteSlot } from "@contracts/userAdmins";

export function useUserAdmins(pageId: string) {
  // Load admins and invites ==================================================
  const isLoadingAdminInvites = ref<boolean>(false);

  const adminsList = ref<Array<UserAdmin>>([]);

  const adminsListMock: Array<UserAdmin> = [
    { adminFirstName: "James Corden", photo: "", adminEmail: "james.corden@gmail.com", invite: { acceptedOn: "2023-05-12T08:07:30.755Z" }, adminAuthNameId: "1" },
    { adminFirstName: "Michael Scott", photo: "", adminEmail: "prison.mike@mail.com", invite: { acceptedOn: "2023-03-10T08:07:30.755Z" }, adminAuthNameId: "2" },
    { adminFirstName: "Felicia Cox", photo: "", adminEmail: "felicica.cox992@gmail.com", invite: { acceptedOn: "2023-02-01T08:07:30.755Z" }, adminAuthNameId: "3" },
    { adminFirstName: "Oliver Tree", photo: "", adminEmail: "oliver.tree007@gmail.com", invite: { acceptedOn: "2022-12-24T08:07:30.755Z" }, adminAuthNameId: "4" },
    { adminFirstName: "David Wallace", photo: "", adminEmail: "david.w@gmail.com", invite: { acceptedOn: "2022-08-07T08:07:30.755Z" }, adminAuthNameId: "5" },
  ];

  const pendingInvitesList = ref<Array<InviteSlot>>([]);

  const pendingInvitesListMock: Array<InviteSlot> = [
    { email: "banks101@gmail.com", invitedOn: "2023-05-10T08:07:30.755Z" },
    { email: "john.doe1990@gmail.com", invitedOn: "2023-04-28T08:07:30.755Z" },
    { email: "barbra.streisand@gmail.com", invitedOn: "2022-02-03T08:07:30.755Z" },
  ];

  async function loadUserAdminsAndInvites(): Promise<void> {
    isLoadingAdminInvites.value = true;

    const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/pages/${pageId}/admins`);

    adminsList.value = data.currentAdmins;
    pendingInvitesList.value = data.unusedInvites;

    isLoadingAdminInvites.value = false;
  }

  // Delete user admin ========================================================
  const isRemoveAdminLoading = ref<boolean>(false);
  const adminIdToRemove = ref<string>("");

  async function deleteUserAdmin(): Promise<void> {
    isRemoveAdminLoading.value = true;

    await axios.delete(`${import.meta.env.VITE_API_URL}/pages/${pageId}/invite/admin?adminAuthNameId=${adminIdToRemove.value}`);

    isRemoveAdminLoading.value = false;

    await loadUserAdminsAndInvites();
  }

  // Invite new admin =========================================================
  async function inviteNewUserAdmin(email: string): Promise<void> {
    // @ts-ignore
    await axios.put(`${import.meta.env.VITE_API_URL}/pages/${pageId}/invite/admin?email=${encodeURIComponent(email)}`);
  }

  // Delete user admin invite =================================================
  async function deleteUserAdminInvite(email: string): Promise<void> {
    await axios.delete(`${import.meta.env.VITE_API_URL}/pages/${pageId}/invite/admin?invitedEmail=${encodeURIComponent(email)}`);
  }

  return {
    isLoadingAdminInvites,
    adminsList,
    adminsListMock,
    pendingInvitesList,
    pendingInvitesListMock,
    loadUserAdminsAndInvites,
    isRemoveAdminLoading,
    adminIdToRemove,
    deleteUserAdmin,
    inviteNewUserAdmin,
    deleteUserAdminInvite,
  };
}
