<template>
  <div class="location-snippet">
    <div class="location-snippet__image-wrap">
      <MediaThumbnail class="location-snippet__image" :media-url="location.exImages[0]" />
    </div>

    <div class="location-snippet__text-wrap">
      <label v-if="Object.keys(slots).length" class="location-snippet__input-wrap" @click.stop>
        <slot name="inputRadio" />
        <span class="location-snippet__border-overlay"></span>
      </label>

      <div class="location-snippet__title-n-date">
        <div class="global-h5 location-snippet__title">
          {{ location.title }}
        </div>
        <!--<div class="location-snippet__date">-->
        <!--  {{ DateUtils.formatDateRange(location.lastPublished, 0, true) }}-->
        <!--</div>-->
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import DateUtils from "@logic/DateUtils";
import { useSlots } from "vue";

const slots = useSlots();

// Types
import { LocationShareable } from "@contracts/location";
import MediaThumbnail from "@components/MediaThumbnail.vue";

export interface Props {
  location: LocationShareable | null;
}
const props = withDefaults(defineProps<Props>(), {
  location: null,
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Location snippet ===========================================================
.location-snippet {
  position: relative;
  background: #fff;

  &__image-wrap {
    width: 100%;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    :deep(.media-thumbnail-checkbox__video-wrap) {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    :deep(.media-thumbnail-checkbox__video) {
      display: flex;
      justify-content: center;
      align-items: center;

      a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  &__text-wrap {
    padding: 15px 14px 14px;
    display: flex;
  }

  &__input-wrap {
    width: 31px;
    min-width: 31px;
    height: 31px;
    margin: -4px 8px 0 0 !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.09);
    cursor: pointer;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0 auto auto 0;
    }

    :deep(input) {
      cursor: pointer;
    }
  }

  &__border-overlay {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 5px #118689 solid;
    border-radius: 5px;
    position: absolute;
    inset: 0 auto auto 0;
    opacity: 0;
    pointer-events: none;
  }

  :deep(input[type="radio"]:checked) + &__border-overlay {
    opacity: 1;
  }

  &__title-n-date {
  }

  &__title {
    margin-bottom: 3px;
  }

  &__date {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .location-snippet {
    &__text-wrap {
      padding-top: 12px;
      flex-direction: column;
    }

    &__input-wrap {
      margin: 0 0 5px -3px !important;
    }
  }
}
</style>
