import { RestError } from "@azure/storage-blob";
import axios from "axios";

export function getErrorMessage(error: unknown): string {
  // Trying to log as much as we can here
  let errorMessageDetails = "";
  if (axios.isAxiosError(error)) {
    errorMessageDetails = `AxiosError: ${error?.message} ${error?.code}`;
  } else if (error instanceof RestError) {
    errorMessageDetails = "RestError: ";
    if (error.code) errorMessageDetails += ` code=${error.code}`;
    if (error.statusCode) errorMessageDetails += ` statusCode=${error.statusCode}`;
    if (error.details) errorMessageDetails += ` details=${error.details}`;
    if (error.message) errorMessageDetails += ` message=${error.message}`;
  } else if (error instanceof Error) {
    errorMessageDetails = error?.message ?? error?.toString();
  }

  if (errorMessageDetails === "[object Object]") {
    try {
      errorMessageDetails = JSON.stringify(error);
    } catch (e) {
      errorMessageDetails = "Error object could not be stringified";
    }
  }
  return errorMessageDetails;
}

export function checkIsNetworkErrorFromError(error: unknown): boolean {
  const errorMessage = getErrorMessage(error);
  return checkIsNetworkErrorFromMessage(errorMessage);
}

export function checkIsNetworkErrorFromMessage(errorMessage: string): boolean {
  const isNetworkError =
    errorMessage?.toLowerCase()?.includes("network error") === true ||
    errorMessage?.toLowerCase()?.includes("timeout exceeded") === true ||
    errorMessage?.toLowerCase()?.includes("connection reset") === true ||
    // Azure blob uploads issues lead to this sometimes
    errorMessage?.toLowerCase()?.includes("resterror") === true;

  return isNetworkError;
}
