<template>
  <div class="collab-creator-guide">
    <!--&lt;!&ndash; Header &ndash;&gt;-->
    <!--<div class="header collab-creator-guide__header">-->
    <!--  <h1 class="global-h1 header__h1">Your Guide to a Successful Collaboration</h1>-->
    <!--  <div class="header__subtitle">This should take 2-3 minutes to complete</div>-->
    <!--</div>-->
    <!--&lt;!&ndash; / Header &ndash;&gt;-->

    <TransitionedHeightAuto :isOpened="activeSlide === 1 && props.isCongratsVisible">
      <NoteWithIcon class="collab-creator-guide__greeting" color="green">
        <template #icon>
          <div style="width: 50px; min-width: 50px; height: 50px; border-radius: 200px; position: relative; overflow: hidden; background: #fff">
            <img style="width: 100%; height: 100%; object-fit: contain" :src="`${contentBaseUri}/cms/images/orgs/${props.communityIcon}`" :alt="props.communityName" />
          </div>
        </template>
        <template #text>
          <div>
            <h5 class="global-h5" style="margin-bottom: 4px">Congratulations on being chosen for a collaboration with {{ props.communityName }}!</h5>
            <span>We're here to make sure you have a great experience working with this destination</span>
          </div>
        </template>
      </NoteWithIcon>
    </TransitionedHeightAuto>

    <StepWizard
      class="collab-creator-guide__steps-guide"
      @slideChange="() => emit('slideChange')"
      isWithTopCurtain
      v-model:activeSlide="activeSlide"
      :titles="[
        { title: 'Take lots of creative photos at each location', subtitle: 'More than you think you\'ll need!' },
        {
          title: ` Highlight ${props.requestedLocationCount}+ unique experiences during your trip`,
          subtitle: `Plan your trip around capturing lots of photos and videos at ${props.requestedLocationCount} unique locations to build ${props.requestedAdventureCount} itineraries. Your collaboration content is packaged up into adventures, or itineraries.`,
        },
        { title: 'Examples of incredible Shrpa collaborations!' },
      ]"
    >
      <template #[0]="{ goToNextSlide }">
        <div style="margin-bottom: 32px; border-bottom: 1px rgba(0, 0, 0, 0.1) solid"></div>
        <SlideAboutPhotos @goToNextSlide="goToNextSlide" />
      </template>
      <template #[1]="{ goToPrevSlide, goToNextSlide }">
        <div style="margin-bottom: 32px; border-bottom: 1px rgba(0, 0, 0, 0.1) solid"></div>
        <SlideAboutExperiences @goToNextSlide="goToNextSlide" :requestedLocationCount="props.requestedLocationCount" :requestedAdventureCount="props.requestedAdventureCount" />
      </template>
      <template #[2]="{ goToPrevSlide, goToNextSlide }">
        <div style="margin-bottom: 32px; border-bottom: 1px rgba(0, 0, 0, 0.1) solid"></div>
        <SlideAboutExamples @complete="completeGuide" :isLoading="isLoading" />
      </template>
    </StepWizard>
  </div>
</template>

<!-- prettier-ignore -->
<script lang="ts">export default { name: "CollabCreatorGuide" };</script>

<script setup lang="ts">
import axios from "axios";
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

// Components
import NoteWithIcon from "@components/NoteWithIcon.vue";
import StepWizard from "@components/ui/StepWizard.vue";
import TransitionedHeightAuto from "@components/ui/TransitionedHeightAuto.vue";
// slides
import SlideAboutPhotos from "./SlideAboutPhotos.vue";
import SlideAboutExperiences from "./SlideAboutExperiences.vue";
import SlideAboutExamples from "./SlideAboutExamples/index.vue";

const props = withDefaults(
  defineProps<{
    isCongratsVisible: boolean;
    communityName: string;
    communityIcon: string;
    requestedLocationCount: number;
    requestedAdventureCount: number;
  }>(),
  {
    isCongratsVisible: false,
    communityName: "",
    communityIcon: "",
    requestedLocationCount: 10,
    requestedAdventureCount: 4,
  }
);

const emit = defineEmits<{
  (e: "slideChange"): void;
  (e: "complete"): void;
}>();

const router = useRouter();
const route = useRoute();

const contentBaseUri = globalThis.Bootstrap.Config.contentCdnBaseUri;

// Step guide active slide ====================================================
const activeSlide = ref(1);

// "Guide was opened at" timestamp ============================================
let guideWasOpenedAt = 0;

onMounted(() => {
  guideWasOpenedAt = Date.now();
});

// Complete guide =============================================================
const isLoading = ref(false);
async function completeGuide() {
  isLoading.value = true;

  const timeToComplete = Math.round((Date.now() - guideWasOpenedAt) / 1000);

  const uri = `${import.meta.env.VITE_API_URL}/collabs/${route.params.communityId}/inputs/${route.params?.collabInputId}/creator/${
    route.params.creatorId
  }/collab-guidance-complete?timeToCompleteInSec=${timeToComplete}`;

  console.log(`Time to complete CollabCreatorGuide: ${timeToComplete}`);

  const { data } = await axios.put(uri);
  console.log(`Is CollabCreatorGuide was saved: ${data}`);

  isLoading.value = false;
  emit("complete");
}
</script>

<style scoped lang="scss">
// Header =====================================================================
.header {
  &__h1 {
    margin-bottom: 13px;
  }

  &__subtitle {
  }
}

// Collab creator guide =======================================================
.collab-creator-guide {
  color: #5b5b5b;
  font: 14px/18px sans-serif;

  &__header {
    margin-bottom: 25px;
  }

  &__greeting {
    margin-bottom: 23px;
  }

  &__steps-guide {
  }
}
</style>
