import FileUtils from "@logic/FileUtils";

const contentCdnBaseUri = globalThis.Bootstrap?.Config.contentCdnBaseUri;
const contentBlobBaseUri = globalThis.Bootstrap?.Config.contentBlobBaseUri;

export type ThumbSize = "thumb-tiny" | "thumb-med" | "thumb-large";
export type ImageSize = ThumbSize | "full" | "uncompressed";
const VideoThumbnailExtension = ".jpg";

export default function generateSrc(fileName: string, imageSize: ImageSize = "thumb-large"): string {
  const isThumbnail = imageSize === "thumb-tiny" || imageSize === "thumb-med" || imageSize === "thumb-large";

  let subPath = "";
  switch (imageSize) {
    case "thumb-tiny":
      subPath = "thumbtiny/";
      break;
    case "thumb-med":
      subPath = "thumbmed/";
      break;
    case "thumb-large":
      subPath = "thumbnocrop/";
      break;
    case "full":
      subPath = ""; // full size images are in the root of the folder
      break;
    case "uncompressed":
      return "uncompressed/";
  }
  const basePath = `${contentCdnBaseUri}/cms/images/expeditions/${subPath}`;

  if (FileUtils.isVideoFileType(fileName)) {
    if (isThumbnail) return basePath + fileName.slice(0, fileName.lastIndexOf(".")) + VideoThumbnailExtension;
    else return `${contentBlobBaseUri}/cms/videos/${generateVideoFileName(fileName)}`;
  } else {
    return basePath + fileName;
  }
}

function generateVideoFileName(fileName: string): string {
  const videoQuality = "-mid";
  // Note: Used to try to render the uncompressed video but was too flakey so cut that code
  const dotIndex = fileName.lastIndexOf(".");
  return fileName.substring(0, dotIndex) + videoQuality + fileName.substring(dotIndex);
}
