<template>
  <div class="locations-slide">
    <h2 class="global-h2 locations-slide__title">Adventures are day-sized itineraries</h2>

    <div class="locations-slide__illustration-n-benefits">
      <img class="locations-slide__illustration" :src="`${contentBaseUri}/images/misc/benefit_illustration.png`" alt="Adventures are day-sized itineraries" @load="emit('imageLoad')" />
      <ul class="locations-slide__benefits-list">
        <!-- Benefit line -->
        <li class="benefit-line locations-slide__benefit-line" v-for="benefit in benefitsList" :key="benefit">
          <IconEmbedded class="benefit-line__checkmark" name="check_4" :size="19" color="rgba(61, 133, 40, 1)" />
          <h3 class="global-h3 benefit-line__text">{{ benefit }}</h3>
        </li>
        <!-- / Benefit line -->
      </ul>
    </div>

    <h2 class="global-h2 locations-slide__description">Highlight the individual locations and businesses you visited during any of your travel experiences.</h2>
  </div>
</template>

<script setup lang="ts">
import IconEmbedded from "@components/ui/IconEmbedded.vue";

const emit = defineEmits<{
  (e: "imageLoad"): void;
}>();

// Global variables
const contentBaseUri = globalThis.Bootstrap.Config.contentCdnBaseUri;

// Benefits list ==============================================================
const benefitsList = ["Vacations", "Day trips", "Highlight your hometown"];
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Benefit line ===============================================================
.benefit-line {
  display: flex;
  align-items: flex-start;

  &__checkmark {
    margin: 5px 6px 0 0;
  }

  &__text {
    font-weight: 300;
  }
}

// Locations slide ============================================================
.locations-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__title {
    margin-bottom: 25px;
    text-align: center;
  }

  &__illustration-n-benefits {
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__illustration {
    width: 234px;
    min-width: 234px;
    margin-right: 10px;
  }

  &__benefits-list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__benefit-line {
    margin-bottom: 17px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__description {
    font-weight: 400;
    text-align: center;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .locations-slide {
    &__title {
      margin-bottom: 15px;
    }

    &__illustration {
      width: 110px;
      min-width: 110px;
    }

    &__benefit-line {
      margin-bottom: 7px;
    }
  }
}
</style>
