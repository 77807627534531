<template>
  <div class="ui form">
    <div>
      <h1 style="display: inline; margin-right: 25px">Manage your Subscription</h1>
    </div>
    <Loader v-if="isLoading" />
    <template v-else>
      <!-- Spotlight Customer -->
      <div v-if="subscriptionData.isSpotlight">
        <div v-if="subscriptionData.paymentStatus === 'Trial'">
          <p>
            Subscribe now to continue using Spotlight features after your trial ends.<br />
            <span v-if="daysUntilTrialEnd">{{ daysUntilTrialEnd }} days left in trial.</span>
          </p>
          <a :href="subscriptionData.stripePaymentLinkUri" class="ui primary button"> Subscribe to Spotlight </a>
        </div>
        <div v-else-if="subscriptionData.paymentStatus === 'Cancelled'">
          <p>We would love to have you back! Please Update your Subscription to continue using Spotlight.</p>
        </div>
        <div v-else-if="subscriptionData.paymentStatus === 'PaymentFailed'">
          <p>
            There was an issue processing your payment.<br />
            Please update your payment method to continue using Spotlight features. <br /><br />
            If you need assistance, don't hesitate to contact our support team.
          </p>
        </div>
        <div v-else>
          <p>Thank you for subscribing to Spotlight!</p>
        </div>
      </div>
      <!-- Full Creator Visit Customer -->
      <div v-else>
        <label class="cms-section-title">Current Plan</label>
        <div>
          <div style="font-weight: bold">
            {{ subscriptionData.subscriptionName }}<br />
            Your plan {{ subscriptionData.isCancelling ? "ends" : "renews" }} on:
            <span v-if="subscriptionData.subscriptionEndDate">
              {{ moment(subscriptionData.subscriptionEndDate).format("MMMM Do, YYYY") }}
            </span>
            <div v-if="subscriptionData.billingEmail">Billing Email: {{ subscriptionData.billingEmail }}</div>
          </div>
        </div>
      </div>

      <!-- Stripe Subscription Management -->
      <template v-if="subscriptionData.canManageSubscriptionInStripe">
        <label class="cms-section-title">Update your Subscription</label>
        <div>
          <ul>
            <li>Update your payment method</li>
            <li>Update your billing email or address</li>
            <li>View past invoices</li>
          </ul>
          <button :disabled="stripeSessionMessage" class="ui small primary button" @click="navigateToStripe">{{ subscriptionData.isSpotlight ? "Update" : "Manage" }} my Subscription</button>
          <span style="margin-left: 7px" v-if="stripeSessionMessage"
            ><b>{{ stripeSessionMessage }}</b></span
          >
          <br />
        </div>
      </template>

      <template v-if="!subscriptionData.isSpotlight">
        <label class="cms-section-title" style="margin-top: 2em !important">Changes to your Subscription Level?</label>
        <div>
          <template v-if="subscriptionData.partOfCoop"> Your subscription is managed through a Co-op program.<br /> </template>
          Reach out anytime to <CopyText /> and we'll be happy to help!
        </div>
      </template>

      <div>
        <label class="cms-section-title">Need a W9?</label>
        <div style="display: flex; align-items: center">
          Download Shrpa's W9 for your records:
          <a href="https://cdn.shrpa.com/legal/shrpa-w9.pdf" target="_blank" style="display: flex; align-items: center; margin-left: 4px">
            https://cdn.shrpa.com/legal/shrpa-w9.pdf
            <IconEmbedded name="external-link_2-5" :size="15" color="grey" style="margin-left: 4px" />
          </a>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import axios from "axios";
import { Page } from "@contracts/pages";
import OrgContext from "@logic/OrgContext";
import moment from "moment";
import { mapState } from "pinia";
import { useUserProfileStore } from "@stores/userProfileStore";
import { useHead } from "@unhead/vue";

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import CopyText from "@components/CopyText.vue";
import Loader from "@components/Loader/Loader.vue";
import { MetricSender } from "@/helpers/MetricSender";

// Note: Integrates with Stripe Customer Portal
// - https://stripe.com/docs/customer-management/integrate-customer-portal

export default defineComponent({
  name: "SubscriptionSettings",

  components: {
    Loader,
    CopyText,
    IconEmbedded,
  },

  data() {
    return {
      globalRemoteLogger: inject("globalRemoteLogger") as any,

      title: "Subscription Management",
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      pageId: "",
      orgName: null,
      isLoading: true,
      // Tells the user the state of the session setup/redirect to Stripe
      stripeSessionMessage: null,

      subscriptionData: null,
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["getViewingUserProfile"]),
    daysUntilTrialEnd(): number | null {
      if (!this.subscriptionData?.trialEndDate) {
        return null;
      }
      const trialEndDate = moment(this.subscriptionData.trialEndDate);
      const today = moment();
      return trialEndDate.diff(today, "days");
    },
  },

  async mounted() {
    useHead({ title: () => this.title ?? "" });

    this.pageId = this.$route.params.pageId as string;
    const orgInContext = OrgContext.getOrgInContext(this);
    this.orgName = orgInContext?.name;
    this.title += " - " + this.orgName;
    this.loadSubscription();

    MetricSender.sendMetric("Cust-ViewSubscription", null);
  },

  methods: {
    moment,
    async loadSubscription() {
      const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/customers/${this.pageId}/subscriptions/current`);
      this.subscriptionData = data;
      this.isLoading = false;
    },
    async navigateToStripe() {
      try {
        this.stripeSessionMessage = "Redirecting...";
        const returnUrl = window.location.href;
        const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/customers/${this.pageId}/stripe-session?returnUrl=${encodeURIComponent(returnUrl)}`);
        // @ts-ignore
        const currentUserAuthId = this.getViewingUserProfile?.authNameId;
        this.globalRemoteLogger.info(`StripeManageSubscription: ${this.pageId} by ${currentUserAuthId}`, true);
        const stripeUrl = data;
        window.location.href = stripeUrl;
      } catch (error) {
        this.stripeSessionMessage = "Error generating Stripe Session.";
        this.globalRemoteLogger.error(`--ALERT-- StripeSessionGenerate Failed for ${this.pageId} error: ${error.message}`, true);
      }
    },
  },
});
</script>

<style scoped lang="scss"></style>
