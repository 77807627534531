import moment from "moment";

export default {
  // Returns a date with the days added to the passed date.
  // NOTE: Does not modify the passed date
  addDays(input: Date, daysToAdd: number) {
    const date = new Date(input);
    date.setDate(date.getDate() + daysToAdd);
    return date;
  },

  daysBetween(startDate: Date, endDate: Date) {
    var msPerDay = 24 * 60 * 60 * 1000;
    var diff = this.getUTC(endDate) - this.getUTC(startDate);
    return diff / msPerDay;
  },

  getUTC(input): any {
    var date = new Date(input);
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return date;
  },

  formatDateRangeTwoDates(startDateString: string, endDateString: string, includeYear = true): string {
    if (!startDateString || startDateString.length === 0) return "";

    const startDate = new Date(startDateString);
    let result = moment(startDate).format("MMM Do");
    if (endDateString !== startDateString) {
      const differentMonth = startDate.getMonth() !== new Date(endDateString).getMonth();
      const endDate = new Date(endDateString);
      const format = (differentMonth ? "MMM " : "") + "Do" + (includeYear ? ", YYYY" : "");
      result += " - " + moment(endDate).format(format);
    }
    return result;
  },

  formatDateRange(startDateString: string, daysToAdd = 0, includeYear = true, format = ""): string {
    if (!startDateString || startDateString.length === 0) return "";

    const startDate = new Date(startDateString);
    const _format = format || "MMM Do" + (daysToAdd === 0 && includeYear ? ", YYYY" : "");
    let result = moment(startDate).format(_format);
    if (daysToAdd > 0) {
      const endDate = this.addDays(startDate, daysToAdd);
      const differentMonth = startDate.getMonth() !== endDate.getMonth();
      const _format = format || (differentMonth ? "MMM " : "") + "Do" + (includeYear ? ", YYYY" : "");
      result += " - " + moment(endDate).format(_format);
    }
    return result;
  },
};
