<template>
  <SrpModal :isVisible="props.isVisible" @update:isVisible="v => emit('update:isVisible', v)" isWithScroll maxHeight="calc(100dvh - 150px)">
    <template #content>
      <h1 class="global-h1" style="margin-bottom: 15px">{{ props.creatorName || "Creator" }}'s Visit Plan</h1>

      <div class="guidance" style="margin-bottom: 24px">
        <p class="guidance__p">Sign off on the creator's visit plan. Get in touch with {{ props.creatorName || "them" }} if you'd like changes made.</p>

        <h5 class="global-h5 guidance__title">Creator's Deliverables:</h5>
        <ul class="guidance__ul">
          <li>- Highlight {{ props.requestedLocationCount }} locations or activities</li>
          <li>- Create {{ props.requestedAdventureCount }} adventures</li>
        </ul>
      </div>

      <div class="divider" style="margin-bottom: 24px"></div>

      <p style="margin-bottom: 25px; color: #5b5b5b; font: 14px/19px sans-serif; white-space: pre-wrap !important" v-html="DOMPurify.sanitize(props.planText)"></p>

      <div class="divider" style="margin-bottom: 28px"></div>

      <!-- Request changes section -->
      <div class="request-changes-section" style="margin-bottom: 13px">
        <div class="request-changes-section__header">
          <h5 class="global-h5 request-changes-section__title">Need Changes?</h5>

          <LinkWithIcon class="request-changes-section__link" isDottedUnderline color="teal" @click="emit('connectWithTheCreator')">
            <template #icon><IconEmbedded name="chat_2-5" :size="19" color="rgba(0, 0, 0, 0.5)" style="margin-right: -1px" /></template>
            <span>Open Messaging</span>
          </LinkWithIcon>
        </div>

        <textarea class="global-textarea request-changes-section__textarea" :placeholder="`Send ${props.creatorName} a message`" v-model="messageText"></textarea>

        <SrpButton class="request-changes-section__send-btn" color="gray" size="small" @click="sendMessage">Send</SrpButton>
      </div>
      <!-- / Request changes section -->
    </template>

    <template #footer>
      <label style="width: auto">
        <SrpButton v-if="Boolean(props.signOffDate)" :isDisabled="true">Signed off on {{ new Date(props.signOffDate).toDateString().slice(4) }}</SrpButton>
        <SrpButton v-else @click="signOff" :isDisabled="isLoading">Sign off</SrpButton>
      </label>
    </template>
  </SrpModal>
</template>

<script setup lang="ts">
import axios from "axios";
import DOMPurify from "dompurify";
import { ref, onMounted, inject, Ref } from "vue";

// Components
import SrpModal from "@components/ui/SrpModal.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import { ScreenSize } from "@contracts/screenSize";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

const props = withDefaults(
  defineProps<{
    isVisible: boolean;
    planText: string | null;
    signOffDate: string | null;
    communityId: string;
    collabInputId: string;
    creatorName: string;
    requestedLocationCount: number;
    requestedAdventureCount: number;
  }>(),
  {
    isVisible: null,
    planText: "",
    signOffDate: null,
    communityId: "",
    collabInputId: "",
    creatorName: "",
    requestedLocationCount: 10,
    requestedAdventureCount: 4,
  }
);

const emit = defineEmits<{
  (e: "update:isVisible", value: boolean): void;
  (e: "connectWithTheCreator"): void;
  (e: "signOff"): void;
}>();

// Injections =================================================================
const globalRemoteLogger = inject("globalRemoteLogger") as any;

// Sign off ===================================================================
const isLoading = ref(false);

async function signOff(): Promise<void> {
  isLoading.value = true;

  try {
    const uri = `${import.meta.env.VITE_API_URL}/collabs/${props.communityId}/inputs/${props.collabInputId}/signoff-visit-plan`;
    await axios.put(uri);
  } catch (error) {
    globalRemoteLogger.error(`Failed to signoff visit plan. communityId: ${props.communityId}. collabInputId: ${props.collabInputId}. Error: ${error}`);
  }
  isLoading.value = false;

  emit("update:isVisible", false);
  emit("signOff");
}

// Send message ===============================================================
const messageText = ref("");
const setMsgForMessagesThread = inject("setMsgForMessagesThread") as Function;

function sendMessage() {
  setMsgForMessagesThread(messageText.value);
  setTimeout(() => (messageText.value = ""), 1000);
  emit("connectWithTheCreator");
}
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Guidance ===================================================================
.guidance {
  font: 14px/17px sans-serif;

  &__p {
    margin-bottom: 8px;
    font: 14px/17px sans-serif;
  }

  &__title {
    margin-bottom: 7px;
  }

  &__ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
}

// Request changes section ====================================================
.request-changes-section {
  &__header {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__title {
    margin-right: 15px;
  }

  &__link {
  }

  &__textarea {
    margin-bottom: 12px;
  }

  &__send-btn {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .request-changes-section {
    &__header {
      flex-direction: column;
    }

    &__title {
      margin: 0 0 7px 0;
    }
  }
}

// Divider ====================================================================
.divider {
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
}
</style>
