<template>
  <div class="ui container">
    <h1 class="message">Finishing authentication...</h1>
    <div class="spinner"></div>
    <h2>You'll be redirected shortly!</h2>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";

onMounted(() => {
  console.info("AuthCallback Mounted");
});
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";

.container {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spinner {
  border: 6px solid rgba(0, 0, 0, 0.1);
  border-left-color: $brand-color;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.message {
  margin-top: 20px;
}
</style>
