<template>
  <div class="srp-summary">
    <div
      :class="{
        'srp-summary__title-wrap': true,
        'srp-summary__title-wrap--no-margin-bottom': !_isOpened,
      }"
      @click="_isOpened = !_isOpened"
      :style="{ transitionDuration: _transitionDuration + 'ms' }"
    >
      <slot name="heading" :isOpened="_isOpened" />

      <IconEmbedded v-if="_isOpened" class="srp-summary__caret-icon" name="caret-top_4-5" />
      <IconEmbedded v-else class="srp-summary__caret-icon" name="caret-bottom_4-5" />
    </div>
    <div
      :class="{
        'srp-summary__details': true,
        'srp-summary__details--with-top-margin': _isOpened,
        'srp-summary__details--with-overflow-hidden': isWithOverflowHidden,
      }"
    >
      <TransitionedHeightAuto :isOpened="_isOpened" :transitionDuration="_transitionDuration" :isKeepAlive="props.isKeepAlive">
        <template #scoped>
          <div class="srp-summary__details-in-wrap">
            <slot name="details" :isOpened="_isOpened" />
          </div>
        </template>
      </TransitionedHeightAuto>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch, nextTick } from "vue";

// Components
import TransitionedHeightAuto from "@components/ui/TransitionedHeightAuto.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export interface Props {
  isOpened?: boolean;
  transitionDuration?: number;
  isKeepAlive?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  isOpened: false,
  transitionDuration: 70,
  isKeepAlive: false,
});

const emit = defineEmits<{
  (e: "update:isOpened", value: boolean): void;
}>();

// Toggle visibility ==========================================================
const _isOpened = ref(true);

onMounted(() => (_isOpened.value = props.isOpened));

watch(
  () => props.isOpened,
  () => (_isOpened.value = props.isOpened)
);

watch(
  () => _isOpened.value,
  () => emit("update:isOpened", _isOpened.value)
);

// Fix the "open on mount" bug ================================================
const _transitionDuration = ref(0);

onMounted(async () => {
  setTimeout(() => {
    _transitionDuration.value = props.transitionDuration;
  }, 0);
});

watch(
  () => props.transitionDuration,
  () => (_transitionDuration.value = props.transitionDuration)
);

// Toggle overflow hidden =====================================================
// this hack is needed because overflow:hidden breaks the position:sticky for the child elements so we have to remove the overflow:hidden after transition animation
let overflowHiddenTimeout = null;
const isWithOverflowHidden = ref<boolean>(false);

watch(_isOpened, () => {
  clearTimeout(overflowHiddenTimeout);
  isWithOverflowHidden.value = false;

  if (_isOpened.value) {
    isWithOverflowHidden.value = true;
    setTimeout(() => (isWithOverflowHidden.value = false), props.transitionDuration + 200);
  }
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Srp Summary ================================================================
.srp-summary {
  &__title-wrap {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: flex-end;
    position: relative;
    z-index: 2;
    cursor: pointer;
    user-select: none;

    // increases clickable area
    &::before {
      content: "";
      width: calc(100% + 20px);
      height: calc(100% + 20px);
      position: absolute;
      inset: 50% auto auto 50%;
      transform: translate(-50%, -50%);
    }

    :deep(.global-h1),
    :deep(.global-h2),
    :deep(.global-h3),
    :deep(.global-h4),
    :deep(.global-h5),
    :deep(.global-h6) {
      transition: margin 0.07s ease-in-out;
    }

    &--no-margin-bottom {
      :deep(.global-h1),
      :deep(.global-h2),
      :deep(.global-h3),
      :deep(.global-h4),
      :deep(.global-h5),
      :deep(.global-h6) {
        margin-bottom: 0 !important;
      }
    }

    // douple span wrappers adds underline effect to summary title
    :deep(.global-h1 span span),
    :deep(.global-h2 span span),
    :deep(.global-h3 span span),
    :deep(.global-h4 span span),
    :deep(.global-h5 span span),
    :deep(.global-h6 span span) {
      text-decoration: underline;
      text-decoration-color: rgba(0, 0, 0, 0.3);
      text-underline-offset: 3px;
      text-decoration-thickness: 1px;
      text-decoration-style: dashed;
    }

    &:hover :deep(.global-h1 span span),
    &:hover :deep(.global-h2 span span),
    &:hover :deep(.global-h3 span span),
    &:hover :deep(.global-h4 span span),
    &:hover :deep(.global-h5 span span),
    &:hover :deep(.global-h6 span span) {
      text-decoration-color: rgba(0, 0, 0, 0);
    }

    & > :deep(*) {
      transition: opacity 0.07s ease-in-out;
    }
    &:hover > :deep(*) {
      opacity: 0.7;
    }
  }

  &__caret-icon {
    width: 17px;
    margin: 5px 5px 0 -5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    :deep(svg) {
      fill: rgba(0, 0, 0, 0.6);
    }
  }
  :deep(.global-h1 + .srp-summary__caret-icon) {
    width: calc(38px * 0.55);
    min-width: calc(38px * 0.55);
    margin-top: 8px;
  }
  :deep(.global-h2 + .srp-summary__caret-icon) {
    width: calc(30px * 0.55);
    min-width: calc(30px * 0.55);
    margin-top: 6px;
  }
  :deep(.global-h3 + .srp-summary__caret-icon) {
    width: calc(28px * 0.55);
    min-width: calc(28px * 0.55);
    margin-top: 6px;
  }
  :deep(.global-h4 + .srp-summary__caret-icon) {
    width: calc(24px * 0.55);
    min-width: calc(24px * 0.55);
  }
  :deep(.global-h5 + .srp-summary__caret-icon) {
    width: calc(22px * 0.55);
    min-width: calc(22px * 0.55);
  }
  :deep(.global-h6 + .srp-summary__caret-icon) {
    width: calc(18px * 0.55);
    min-width: calc(18px * 0.55);
    margin: 3px 3px 0 -5px;
  }

  &__details {
    position: relative;
    z-index: 1;

    &--with-top-margin {
      margin-top: 15px;
    }

    &--with-overflow-hidden {
      overflow: hidden;
    }
  }

  &__details-in-wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .srp-summary {
    :deep(.global-h1 + .srp-summary__caret-icon) {
      width: 30px;
      min-width: 30px;
    }
    :deep(.global-h2 + .srp-summary__caret-icon) {
      width: 25px;
      min-width: 25px;
      margin-top: 0;
    }
    :deep(.global-h3 + .srp-summary__caret-icon) {
      width: 22px;
      min-width: 22px;
      margin-top: 2px;
    }
    :deep(.global-h4 + .srp-summary__caret-icon) {
      width: 22px;
      min-width: 22px;
      margin-top: 4px;
    }
    :deep(.global-h5 + .srp-summary__caret-icon) {
      width: 19px;
      min-width: 19px;
      margin-top: 3px;
    }
    :deep(.global-h6 + .srp-summary__caret-icon) {
      width: 18px;
      min-width: 18px;
      margin-top: 0;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .srp-summary {
    :deep(.global-h1 + .srp-summary__caret-icon) {
      width: 30px;
      min-width: 30px;
    }
    :deep(.global-h2 + .srp-summary__caret-icon) {
      width: 25px;
      min-width: 25px;
      margin-top: 0;
    }
    :deep(.global-h3 + .srp-summary__caret-icon) {
      width: 22px;
      min-width: 22px;
      margin-top: -1px;
    }
    :deep(.global-h4 + .srp-summary__caret-icon) {
      width: 22px;
      min-width: 22px;
      margin-top: -1px;
    }
    :deep(.global-h5 + .srp-summary__caret-icon) {
      width: 19px;
      min-width: 19px;
      margin-top: -1px;
    }
    :deep(.global-h6 + .srp-summary__caret-icon) {
      width: 18px;
      min-width: 18px;
      margin-top: 0;
    }
  }
}
</style>
