<template>
  <div class="avatar-with-fallback">
    <label v-if="(props.isRequired && props.isError) || props.isWithUploadBtn" class="avatar-with-fallback__required-field-note-n-upload-btn">
      <RequiredFieldNote v-if="props.isRequired && props.isError" class="avatar-with-fallback__required-field-note">required</RequiredFieldNote>

      <SrpButton v-if="props.isWithUploadBtn" class="avatar-with-fallback__upload-button" size="small" fill="outlined">
        <template #icon><IconEmbedded name="upload_2-5" :size="18" /></template>
        Upload
      </SrpButton>

      <input class="avatar-with-fallback__file-input" @change="$event => emit('change', $event)" type="file" id="imageInput" accept="image/*" ref="domRefFileInput" />
    </label>

    <div
      :class="{
        'avatar-with-fallback__img-wrap': true,
        'avatar-with-fallback__img-wrap--error': props.isError,
        'avatar-with-fallback__img-wrap--rounded': props.isRounded,
      }"
      :style="{ backgroundColor: props.bgColor }"
    >
      <img
        v-if="isImgVisible"
        class="avatar-with-fallback__img"
        :src="props.src"
        :alt="props.alt || 'Photo'"
        :onerror="hideImg"
        :style="{
          objectFit: props.objectFit,
        }"
      />
      <div v-else class="avatar-with-fallback__fallback-icon"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import SrpButton from "@components/ui/SrpButton.vue";
import RequiredFieldNote from "@components/ui/RequiredFieldNote.vue";
import { SocialMediaPostData } from "@contracts/shareStop";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

const props = withDefaults(
  defineProps<{
    src?: string;
    alt?: string;
    isRounded?: boolean;
    isWithUploadBtn?: boolean;
    isError?: boolean;
    isRequired?: boolean;
    objectFit?: "cover" | "contain";
    bgColor?: string;
  }>(),
  {
    src: "",
    alt: "",
    isRounded: true,
    isWithUploadBtn: false,
    isError: false,
    isRequired: false,
    objectFit: "cover",
    bgColor: "rgba(255, 255, 255, 1)",
  }
);

const emit = defineEmits<{
  (e: "change", event: Event): void;
}>();

// Hide image =================================================================
const isImgVisible = ref(false);

onMounted(() => (isImgVisible.value = Boolean(props.src)));

function hideImg(): void {
  isImgVisible.value = false;
}

watch(
  () => props.src,
  () => (isImgVisible.value = true)
);

// Define expose ==============================================================
const domRefFileInput = ref<HTMLElement | null>(null);

defineExpose({
  domRefFileInput,
});
</script>

<style scoped lang="scss">
// Avatar with fallback =======================================================
.avatar-with-fallback {
  width: 64px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  position: relative;
  z-index: 0;

  &__required-field-note-n-upload-btn {
    height: 30px;
    padding: 0 !important;
    margin: -20px 0 0 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    z-index: 1;
  }

  &__required-field-note {
    margin-bottom: 4px;
  }

  &__upload-button {
    &::before,
    &:hover::before {
      background: rgba(255, 255, 255, 1);
    }
  }

  &__file-input {
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -1;
    opacity: 0;
  }

  &__img-wrap {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 4px;
    outline: 1px rgba(0, 0, 0, 0.15) solid;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
    overflow: hidden;

    &--rounded {
      border-radius: 50%;
    }

    &--error {
      box-shadow: 0 0 0 5px rgba(188, 73, 73, 0.35);
    }
  }

  &__img {
    width: 100%;
    height: 100%;
  }

  &__fallback-icon {
    width: 170%;
    height: 170%;
    position: absolute;
    z-index: -1;

    &::before {
      content: "";
      width: 60%;
      height: 60%;
      position: absolute;
      inset: 50% auto auto 50%;
      transform: translate(-50%, -37%);
      background: center center / 100% 100%
        url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjYiIHZpZXdCb3g9IjAgMCAyNiAyNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQuNDMzNzEgMTYuNzUwMUM1LjUxMTc3IDE1LjY3MjEgNi45NzM5NCAxNS4wNjY0IDguNDk4NTUgMTUuMDY2NEgxNy42OTYyQzE5LjIyMDggMTUuMDY2NCAyMC42ODMgMTUuNjcyMSAyMS43NjExIDE2Ljc1MDFDMjIuODM5MSAxNy44MjgyIDIzLjQ0NDggMTkuMjkwMyAyMy40NDQ4IDIwLjgxNVYyMy4xMTQ0QzIzLjQ0NDggMjMuNzQ5MyAyMi45MyAyNC4yNjQxIDIyLjI5NTEgMjQuMjY0MUgzLjg5OTcxQzMuMjY0NzQgMjQuMjY0MSAyLjc1IDIzLjc0OTMgMi43NSAyMy4xMTQ0VjIwLjgxNUMyLjc1IDE5LjI5MDMgMy4zNTU2NSAxNy44MjgyIDQuNDMzNzEgMTYuNzUwMVoiIGZpbGw9ImJsYWNrIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNy4zNDc2NiA3LjUyOThDNy4zNDc2NiA0LjM1NDk2IDkuOTIxMzcgMS43ODEyNSAxMy4wOTYyIDEuNzgxMjVDMTYuMjcxIDEuNzgxMjUgMTguODQ0OCA0LjM1NDk2IDE4Ljg0NDggNy41Mjk4QzE4Ljg0NDggMTAuNzA0NiAxNi4yNzEgMTMuMjc4MyAxMy4wOTYyIDEzLjI3ODNDOS45MjEzNyAxMy4yNzgzIDcuMzQ3NjYgMTAuNzA0NiA3LjM0NzY2IDcuNTI5OFoiIGZpbGw9ImJsYWNrIi8+Cjwvc3ZnPgo=")
        no-repeat;
      opacity: 0.1;
    }
  }
}
</style>
