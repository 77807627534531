<template>
  <div
    :class="{
      'collab-suggestion-snippet': true,
      'collab-suggestion-snippet--expanded': isExpanded,
    }"
  >
    <div class="collab-suggestion-snippet__header">
      <TextareaSubtle
        v-if="isEditModeOn"
        v-model:value="_collabSuggestion.title"
        :isWithEditButton="false"
        :isDisabled="!isEditModeOn"
        :isBorderAlwaysVisible="isEditModeOn"
        :fontSize="22"
        :lineHeightCoeff="1.3"
        :fontWeight="800"
      />

      <h3 v-else class="global-h3 collab-suggestion-snippet__title">
        {{ _collabSuggestion.title || "Creator Visit Idea" }}
      </h3>

      <LinkWithIcon
        v-if="!isEditModeOn"
        class="collab-suggestion-snippet__edit-button"
        @click="
          () => {
            isEditModeOn = !isEditModeOn;
            isExpanded = true;
          }
        "
      >
        <template #icon><IconEmbedded name="pencil_2-5" :size="18" /></template>
      </LinkWithIcon>
    </div>

    <!-- Description snippet -->
    <div class="description-snippet collab-suggestion-snippet__description-snippet">
      <img
        v-if="!isEditModeOn"
        class="description-snippet__icon"
        :src="getThemeIcon(convertThemeFormat(_collabSuggestion.theme))"
        :alt="getThemeTypeName(convertThemeFormat(_collabSuggestion.theme))"
      />

      <div class="description-snippet__right-col">
        <CollabThemePicker v-if="isEditModeOn" v-model:modelValue="_collabSuggestion.theme" style="margin: 0 0 10px -5px" />

        <!--<h6 class="global-h6 description-snippet__title">Visit Focus</h6>-->
        <div class="description-snippet__description">
          <TextareaSubtle
            v-model:value="_collabSuggestion.visitFocus"
            :isWithEditButton="false"
            :isDisabled="!isEditModeOn"
            :isBorderAlwaysVisible="isEditModeOn"
            fontFamily="sans-serif"
            :fontWeight="400"
            :lineHeightCoeff="1.35"
          />
        </div>
      </div>
    </div>
    <!-- / Description snippet -->

    <div class="collab-suggestion-snippet__divider"></div>

    <!-- Description snippet -->
    <div v-if="_collabSuggestion?.requiredLocations?.length" class="description-snippet collab-suggestion-snippet__description-snippet">
      <div class="description-snippet__right-col description-snippet__right-col--full-width">
        <h6 class="global-h6 description-snippet__title">Where to Go!</h6>
        <div class="description-snippet__description">
          <TextareaSubtle
            v-if="isEditModeOn"
            style="width: 100%"
            v-model:value="_collabSuggestion.requiredLocations"
            :isWithEditButton="false"
            :isDisabled="!isEditModeOn"
            :isBorderAlwaysVisible="isEditModeOn"
            fontFamily="sans-serif"
            :fontWeight="400"
            :lineHeightCoeff="1.35"
          />

          <ul v-else class="global-ul">
            <li v-for="location in _collabSuggestion.requiredLocations.split(/\n/gim)" :key="location">
              {{ location }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- / Description snippet -->

    <!-- Description snippet -->
    <div
      v-if="_collabSuggestion?.suggestionLocations?.length"
      class="description-snippet collab-suggestion-snippet__description-snippet collab-suggestion-snippet__description-snippet--no-bottom-margin"
    >
      <div class="description-snippet__right-col description-snippet__right-col--full-width">
        <h6 class="global-h6 description-snippet__title">Other Suggested Locations</h6>
        <div class="description-snippet__description">
          <TextareaSubtle
            v-if="isEditModeOn"
            style="width: 100%"
            v-model:value="_collabSuggestion.suggestionLocations"
            :isWithEditButton="false"
            :isDisabled="!isEditModeOn"
            :isBorderAlwaysVisible="isEditModeOn"
            fontFamily="sans-serif"
            :fontWeight="400"
            :lineHeightCoeff="1.35"
          />

          <ul v-else class="global-ul">
            <li v-for="location in _collabSuggestion.suggestionLocations.split(/\n/gim)" :key="location">
              {{ location }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- / Description snippet -->

    <!-- Bottom panel -->
    <div
      :class="{
        'bottom-panel': true,
        'bottom-panel--with-no-bg-gradient': isExpanded,
        'collab-suggestion-snippet__floating-bottom-panel': true,
      }"
    >
      <div class="bottom-panel__left-side">
        <LinkWithIcon v-if="!isEditModeOn" class="bottom-panel__show-more-btn" isDottedUnderline iconInset="1px auto auto 3px" @click="isExpanded = !isExpanded">
          <template v-if="isExpanded" #icon><IconEmbedded name="caret-top_4" :size="13" /></template>
          <template v-else #icon><IconEmbedded name="caret-bottom_4" :size="13" /></template>
          <span>{{ isExpanded ? "Show less" : "Show more" }}</span>
        </LinkWithIcon>
      </div>

      <div v-if="isEditModeOn" class="bottom-panel__right-side">
        <SrpButton size="small" fill="outlined" @click="cancelEdit">Cancel</SrpButton>
        <SrpButton size="small" fill="outlined" @click="save">Save</SrpButton>
      </div>

      <template v-else>
        <div v-if="!props.hideButtons" class="bottom-panel__right-side">
          <SrpButton size="small" fill="outlined" @click="emit('getStarted')">Get Started</SrpButton>
          <SrpButton color="gray" fill="outlined" size="small" style="width: 48px" @click="emit('dislike')">
            <template #icon><IconEmbedded name="dislike_2" /></template>
          </SrpButton>
        </div>
      </template>
    </div>
    <!-- / Bottom panel -->
  </div>
</template>

<script setup lang="ts">
import { getThemeIcon, getThemeTypeName } from "@helpers/ThemeTypeHelper.js";
import { ref, onMounted, watch } from "vue";
import { themeTypes } from "@contracts/collab";

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import SrpButton from "@components/ui/SrpButton.vue";

// Types
import { CollabSuggestion } from "@contracts/collabSuggestions";
import { ThemeType } from "@contracts/collab";
import TextareaSubtle from "@components/ui/TextareaSubtle.vue";
import CollabThemePicker from "@views/PaidCollab/PlannedCollabs/CollabThemePicker.vue";
import axios from "axios";

const props = withDefaults(
  defineProps<{
    collabSuggestion: CollabSuggestion | null;
    hideButtons?: boolean;
    pageId: string;
  }>(),
  {
    collabSuggestion: null,
    hideButtons: false,
    pageId: "",
  }
);

const emit = defineEmits<{
  (e: "getStarted"): void;
  (e: "dislike"): void;
}>();

// Convert BE theme format ====================================================
function convertThemeFormat(theme: string): ThemeType {
  if (!theme || !themeTypes.includes(theme as ThemeType)) {
    return "anyone";
  }
  return theme as ThemeType;
}

// Expand/collapse ============================================================
const isExpanded = ref<boolean>(false);

// Toggle the Edit mode =======================================================
const isEditModeOn = ref<boolean>(false);

// CollabSuggestion local copy ================================================
const _collabSuggestion = ref<CollabSuggestion>({
  _etag: null,
  id: "",

  customerId: "",

  title: "",
  theme: null,
  visitWindow: "",
  visitFocus: "",
  requiredLocations: "",
  suggestionLocations: "",

  createdDate: "",
  createdBy: "",
  updatedDate: "",
  updatedBy: "",

  deletedDateTime: "",
  dislikeReason: "",
});

onMounted(() => {
  _collabSuggestion.value = { ...props.collabSuggestion };
});

// Save =======================================================================
async function save(): Promise<void> {
  let uri = `${import.meta.env.VITE_API_URL}/collab-suggestions/${props.pageId}/suggestions/${props.collabSuggestion.id}`;
  const config = { headers: { "Content-Type": "application/json" } };
  const { data } = await axios.put<CollabSuggestion>(uri, JSON.stringify(_collabSuggestion.value), config);
  // Note: Manually setting the eTag so we don't get a ton of concurrency warnings.
  _collabSuggestion.value._etag = data._etag;

  isEditModeOn.value = false;
}

function cancelEdit(): void {
  isEditModeOn.value = false;
  _collabSuggestion.value = { ...props.collabSuggestion };
}
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Description snippet ========================================================
.description-snippet {
  display: flex;
  align-items: flex-start;

  &__icon {
    width: 35px;
    margin-right: 15px;
  }

  &__right-col {
    &--full-width {
      width: 100%;
    }
  }

  &__title {
    margin-bottom: 7px;
  }

  &__description {
    width: 100%;
  }
}

// Bottom panel ===============================================================
.bottom-panel {
  padding: 15px 22px 15px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    width: 100%;
    height: 110px;
    border-radius: 6px;
    position: absolute;
    inset: auto auto 0 0;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 45%, rgba(255, 255, 255, 0) 100%);
    pointer-events: none;
    transition: background 0.15s ease-in-out;
  }

  &--with-no-bg-gradient {
    &::before {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0) 45%, rgba(255, 255, 255, 0) 100%);
    }
  }

  &__left-side {
    flex-grow: 1;
  }

  &__show-more-btn {
    margin-left: -7px;
  }

  &__right-side {
    gap: 12px;
    display: flex;
  }

  &__get-started-btn {
  }

  &__thumbs-down-btn {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .bottom-panel {
    padding: 15px 18px 15px;
  }
}

// Collab suggestion snippet ==================================================
.collab-suggestion-snippet {
  max-height: 400px;
  padding: 18px 22px 70px;
  border: 1px rgba(0, 0, 0, 0.2) solid;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  color: rgba(91, 91, 91, 1);
  font: 14px/19px sans-serif;
  transition: max-height 0.15s ease-in-out;

  &--expanded {
    max-height: 1000px;
  }

  &__header {
    margin-bottom: 14px;
    display: flex;
    justify-content: space-between;
  }

  &__title {
  }

  &__edit-button {
    margin: -20px -15px 0 0;
  }

  &__description-snippet {
    flex-grow: 1;
    width: 100%;
    min-width: 100%;
    margin-bottom: 18px;

    &--no-bottom-margin {
      margin-bottom: 0;
    }
  }

  &__divider {
    margin-bottom: 20px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
    display: flex;
  }

  &__floating-bottom-panel {
    width: 100%;
    position: absolute;
    inset: auto auto 0 0;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .collab-suggestion-snippet {
    max-height: 300px;

    &--expanded {
      max-height: 1000px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .collab-suggestion-snippet {
    max-height: 265px;
    padding: 14px 18px 66px;

    &--expanded {
      max-height: 1000px;
    }
  }
}
</style>
