import { defineStore } from "pinia";
import { ref, computed } from "vue";
import axios from "axios";

import { checkIsStoreFresh } from "@helpers/CheckStoreFreshness";

// Types
import { AxiosResponse } from "axios";
import { LocationShareable } from "@contracts/location";

export const useLocationsStore = defineStore("locations", () => {
  const isLoading = ref(false);
  let lastLoadTimestamp: number | null = null;

  const allLocations = ref<Array<LocationShareable>>();

  async function loadAllLocations(pageId: string): Promise<void> {
    if (checkIsStoreFresh(lastLoadTimestamp)) return;

    const uri = `${import.meta.env.VITE_API_URL}/social/v2/${pageId}/locations`;

    isLoading.value = true;
    const response: AxiosResponse<any> = await axios.get(uri);
    lastLoadTimestamp = Date.now();
    allLocations.value = response.data;
    isLoading.value = false;
  }

  return {
    isLoading,
    allLocations,
    loadAllLocations,
  };
});
