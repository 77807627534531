import { CollabLocationsData } from "@contracts/collabLocations";
import { shrpaApi } from "./axios";

interface CollabLocationGetResponse {
  locationsInAdventures: string[];
  locationData: CollabLocationsData
}

export async function fetchCollabLocations(collabInputId: string, creatorId: string, customerId: string): Promise<CollabLocationGetResponse> {
  const uri = `/collabs/${customerId}/inputs/${collabInputId}/creator/${creatorId}/locations`;
  const { data } = await shrpaApi.get<CollabLocationGetResponse>(uri);
  return data;
}

export async function saveCollabLocations(collabLocationsData: CollabLocationsData, customerId: string, collabInputId: string, creatorId: string): Promise<CollabLocationsData> {
  const _collabLocationsData = { ...collabLocationsData };
  // Don't save locations without title or externalId. They are not considered valid for adding images to
  _collabLocationsData.locations = _collabLocationsData.locations.filter(location => !!location.title || !!location.externalId);
  const uri = `collabs/${customerId}/inputs/${collabInputId}/creator/${creatorId}/locations`;
  const { data } = await shrpaApi.put<CollabLocationsData>(uri, _collabLocationsData);
  return data;
}
