import type { ThemeType } from "@contracts/collab";
import { shrpaApi } from "./axios";
export interface PlannedCollab {
  id: string;
  customerId: string;
  createdCollaborationId: string | null;
  name: string;
  preferredDateRangeStart: string | null;
  preferredDateRangeEnd: string | null;
  themeType: ThemeType;
  notes: string;

  createdFromCollabSuggestionId?: string | null;

  requiredLocations: string | null;
  suggestedLocations: string | null;
}
export default {
  async fetchPlannedCollabs(customerId: string): Promise<PlannedCollab[]> {
    const { data } = await shrpaApi.get<PlannedCollab[]>(`/planned-collabs/${customerId}`);
    return data;
  },
  async updatePlannedCollabs(customerId: string, plannedCollabs: PlannedCollab[]): Promise<PlannedCollab[]> {
    const { data } = await shrpaApi.put<PlannedCollab[]>(`/planned-collabs/${customerId}`, plannedCollabs);
    return data;
  },
};
