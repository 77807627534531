<template>
  <div class="contact-shrpa-n-emails">
    <ContactUser
      :visible="isContactShrpaModalVisible"
      :sendToFirstName="'Shrpa'"
      :sendToEmail="'collabs@shrpa.com'"
      :subject="contactShrpaSubject"
      :messagePrefix="contactShrpaSubject"
      @close="isContactShrpaModalVisible = false"
    />

    <SrpModal v-model:isVisible="isEmailsModalVisible">
      <template #content>
        <div style="margin-bottom: 20px">
          <h2 class="global-h2" style="margin-bottom: 4px">Who to Notify</h2>
          <div style="color: rgba(91, 91, 91, 1); font-family: sans-serif">The following users will receive notifications for this creator visit.</div>
        </div>

        <Loader v-if="isLoading" style="margin-bottom: 30px" />

        <div v-else style="gap: 8px; margin-bottom: 30px; display: flex; flex-direction: column; align-items: flex-start">
          <SrpCheckbox v-for="email in emailsList" :key="email" class="email-checkbox">
            <template #input><input type="checkbox" v-model="selectedEmails" :value="email" name="overnight" /></template>
            <template #text>{{ email }}</template>
          </SrpCheckbox>
        </div>

        <div style="width: 100%; margin-bottom: 20px; border-bottom: 1px rgba(0, 0, 0, 0.1) solid"></div>

        <LinkWithIcon color="blue" isDottedUnderline @click="isInviteUserAdminModalVisible = true" iconInset="auto auto auto 2px" style="margin-left: -5px">
          <template #icon><IconEmbedded name="plus_3" :size="19" /></template>
          <span>Need to invite another admin?</span>
        </LinkWithIcon>

        <InviteUserAdminModal
          :isVisible="isInviteUserAdminModalVisible"
          @close="isInviteUserAdminModalVisible = false"
          @addInvite="
            () => {
              loadUserAdminsAndInvites();
              getEmails();
            }
          "
          :adminsList="adminsList"
          :invitesList="pendingInvitesList"
          :inviteFunc="inviteNewUserAdmin"
          :zIndex="81"
        />
      </template>

      <template #footer>
        <div style="width: 100%; display: flex; justify-content: space-between; align-items: center">
          <ul v-if="!selectedEmails.length" class="global-form-errors-list">
            <li class="global-form-errors-list__section">
              <div class="global-form-errors-list__title">Please, select at least one email</div>
            </li>
          </ul>

          <div style="flex-grow: 1; display: flex; justify-content: flex-end">
            <SrpButton style="margin-right: 12px" color="gray" fill="outlined" @click="isEmailsModalVisible = false">Cancel</SrpButton>
            <SrpButton @click="saveEmails" :isDisabled="isLoading || isSaving || !selectedEmails.length" iconInset="1px auto auto auto">
              <template #icon>
                <LoadingIcon v-if="isSaving" :size="21" />
                <IconEmbedded v-else name="save_2-5" :size="21" />
              </template>
              Save
            </SrpButton>
          </div>
        </div>
      </template>
    </SrpModal>

    <!-- Notification emails section -->
    <div class="notif-emails-section contact-shrpa-n-emails__notif-emails-section">
      <IconEmbedded class="notif-emails-section__bell-icon" name="envelope_2-5" :size="27" color="rgba(0, 0, 0, 0.3)" />

      <div class="notif-emails-section__title-n-emails">
        <h5 class="global-h5 notif-emails-section__title">
          Notified Admins
          <LinkWithIcon v-if="!collabInput?.collaboration?.completedDate" @click="isEmailsModalVisible = true" isDottedUnderline style="margin-left: 3px"><span>edit</span></LinkWithIcon>
        </h5>
        <div class="notif-emails-section__emails-list">{{ props.collabInput.contactEmail?.split(";").join(", ") }}</div>
      </div>
    </div>
    <!-- / Notification emails section -->

    <div class="contact-shrpa-n-emails__divider">&nbsp;</div>

    <SrpButton class="contact-shrpa-n-emails__contact-shrpa-btn" color="teal" fill="outlined" @click="isContactShrpaModalVisible = true" size="small">
      <template #icon><IconEmbedded name="envelope_2" :size="17" /></template>
      Contact Shrpa
    </SrpButton>
  </div>
</template>

<script setup lang="ts">
import axios from "axios";
import { ref, computed, watch, onMounted } from "vue";

// Components
import ContactUser from "@components/Modals/ContactUser.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import Loader from "@components/Loader/Loader.vue";
import LoadingIcon from "@components/ui/LoadingIcon.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import SrpCheckbox from "@components/ui/SrpCheckbox.vue";
import InviteUserAdminModal from "@components/InviteUserAdminModal.vue";

// Stores
import { useUserProfileStore } from "@stores/userProfileStore";
const userProfileStore = useUserProfileStore();

// Types
import { CollabInput } from "@contracts/collab";
import { InviteSlot, UserAdmin } from "@contracts/userAdmins";

// Composables
import { useUserAdmins } from "@composables/useUserAdmins";

const props = withDefaults(
  defineProps<{
    collabInput: CollabInput | null;
    pageId: string;
  }>(),
  {
    collabInput: null,
    pageId: "",
  }
);

const emit = defineEmits<{
  (e: "update:collabInput", value: CollabInput): void;
}>();

// Toggle "Contact Shrpa" modal & generate subjects ===========================
const isContactShrpaModalVisible = ref<boolean>(false);

const contactShrpaSubject = computed<string>(() => {
  return `Collab question for Shrpa from ${userProfileStore.getViewingUserProfile?.firstName} about ${props.collabInput?.name}`;
});

// Toggle email modal =========================================================
const isEmailsModalVisible = ref<boolean>(false);

// Get emails =================================================================
const emailsList = ref<Array<string>>([]);

const isLoading = ref<boolean>(false);

watch(isEmailsModalVisible, newValue => {
  if (newValue) {
    getEmails();
  }
});

async function getEmails(): Promise<void> {
  isLoading.value = true;
  const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/pages/${props.pageId}/admins`);

  // Get both the admins and invites
  emailsList.value = data.currentAdmins.sort((a, b) => b.lastLogin - a.lastLogin).map((admin: UserAdmin) => admin.adminEmail);
  emailsList.value.push(...data.unusedInvites.map((invite: InviteSlot) => invite.email));
  // Allow supers to add their email
  if (userProfileStore.getActingUserProfile.isSuperuser || userProfileStore.getActingUserProfile.isSalesUser) {
    const currentUserEmail = userProfileStore.getActingUserProfile.email;
    if (!emailsList.value.includes(currentUserEmail)) emailsList.value.push(currentUserEmail);
  }
  // Add emails missing from the list
  props.collabInput.contactEmail.split(";").forEach(email => {
    if (!emailsList.value.includes(email)) {
      // Add it as the first item
      emailsList.value.unshift(email);
    }
  });

  isLoading.value = false;
}

// Save emails ================================================================
const isSaving = ref<boolean>(false);
const selectedEmails = ref<Array<string>>([]);

watch(isEmailsModalVisible, () => {
  selectedEmails.value = props.collabInput.contactEmail.split(";");
});

async function saveEmails(): Promise<void> {
  const uri = `${import.meta.env.VITE_API_URL}/collabs/${props.pageId}/inputs/${props.collabInput.id}/contact`;
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.append("email", selectedEmails.value.join(";"));

  isSaving.value = true;

  const { data } = await axios.put<CollabInput>(uri + "?" + urlSearchParams.toString());
  emit("update:collabInput", data);

  isEmailsModalVisible.value = false;

  isSaving.value = false;
}

// Handle user admin invites ==================================================
const isInviteUserAdminModalVisible = ref<boolean>(false);

const { loadUserAdminsAndInvites, pendingInvitesList, adminsList, inviteNewUserAdmin } = useUserAdmins(props.pageId);

onMounted(async () => {
  await loadUserAdminsAndInvites();
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Email checkbox =============================================================
.email-checkbox {
  :deep(.srp-checkbox__halo-bg) {
    outline: 1px rgba(0, 0, 0, 0.1) solid;
    background: rgba(255, 255, 255, 1);
  }
}

// Notification emails section ================================================
.notif-emails-section {
  display: flex;
  align-items: flex-start;

  &__bell-icon {
    margin: 3px 7px 0 0;
  }

  &__title-n-emails {
    margin-right: 19px;
  }

  &__title {
  }

  &__emails-list {
  }

  &__edit-btn {
    margin-top: 2px;
  }
}

// "Contact Shrpa" & Emails ===================================================
.contact-shrpa-n-emails {
  display: flex;
  align-items: flex-start;

  &__contact-shrpa-btn {
    margin: 0 24px 0 0;
  }

  &__divider {
    width: 0;
    margin-right: 17px;
    border: 1px rgba(0, 0, 0, 0.1) solid;
    border-width: 0 1px 0 0;
    align-self: stretch;
  }

  &__notif-emails-section {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .contact-shrpa-n-emails {
    flex-direction: column;

    &__contact-shrpa-btn {
      margin: 0 0 23px 0;
    }

    &__divider {
      width: 100%;
      height: 0;
      margin: 0 0 23px 0;
      border-width: 0 0 1px 0;
    }
  }
}
</style>
