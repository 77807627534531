export interface RGBColorFormatObj {
  r: number;
  g: number;
  b: number;
}

export function convertHEXToRGB(colorInHEX: string): RGBColorFormatObj {
  if (!colorInHEX) {
    return { r: 0, g: 0, b: 0 };
  }

  if (!colorInHEX.startsWith("#")) {
    colorInHEX = "#" + colorInHEX;
  }

  const r = parseInt(colorInHEX.slice(1, 3), 16);
  const g = parseInt(colorInHEX.slice(3, 5), 16);
  const b = parseInt(colorInHEX.slice(5, 7), 16);

  return { r, g, b };
}

export function calcColorLuminance({ r, g, b }: RGBColorFormatObj): number {
  return (r * 0.299 + g * 0.587 + b * 0.114) / 255;
}
