import { ref, watch, Ref, onMounted, nextTick, onBeforeUnmount } from "vue";
import lodashThrottle from "lodash-es/throttle";
import { AreaQuarter } from "@composables/useMouseDragNDrop";
import { defineQuarter } from "@composables/useMouseDragNDrop";

// TODO: implement the ability to move the element in both places: above and below any other element
// TODO: add "data-dragged-over-above" and "data-dragged-over-below" instead of the "elementIndexTouchDraggedOver" reactive variables to set classes
export function useTouchDragNDrop(
  isEnabled: Ref<boolean>,
  updateKey: Ref<any>,
  parentContainer: Ref<HTMLElement | null>,
  dragNDropElementQuery: string,
  dragNDropHandleQuery: string,
  callback: Function,
  touchDragStartTimeout = 50
) {
  const isTouchInProgress = ref<boolean>(false);
  const isTouchDragInProgress = ref<boolean>(false);

  const touchStartTimeStamp = ref<number>(0);
  const touchFirstMoveTimeStamp = ref<number>(0);

  const elementIdTouchDragged = ref<string>("");
  const elementIdTouchDraggedOver = ref<string>("");
  const elementIndexTouchDragged = ref<number>(-1);
  const elementIndexTouchDraggedOver = ref<number>(-1);
  const elementDatasetTouchDragged = ref<DOMStringMap>(null);
  const elementDatasetTouchDraggedOver = ref<DOMStringMap>(null);
  const elementAreaQuarterTouchDraggedOver = ref<AreaQuarter>(null);

  const draggedElementClone = ref<Node | null>(null);

  let dragInProgressSetTimeout: ReturnType<typeof setTimeout> | null = null;

  // Add drag listeners =======================================================
  onMounted(async () => {
    if (!isEnabled.value) {
      removeListeners();
      return;
    }

    await nextTick();

    removeListeners();
    addListeners();
  });

  onBeforeUnmount(removeListeners);

  function addListeners() {
    parentContainer.value?.querySelectorAll(dragNDropHandleQuery).forEach(domNode => {
      domNode?.addEventListener("touchstart", handleTouchStart);
      domNode?.addEventListener("touchend", handleTouchEnd);
      domNode?.addEventListener("touchmove", handleTouchMove);
      domNode?.addEventListener("touchleave", handleTouchLeave);
      domNode?.addEventListener("touchcancel", handleTouchCancel);

      domNode?.setAttribute("draggable", "true");
    });
  }

  function removeListeners() {
    parentContainer.value?.querySelectorAll(dragNDropHandleQuery).forEach(domNode => {
      domNode?.removeEventListener("touchstart", handleTouchStart);
      domNode?.removeEventListener("touchend", handleTouchEnd);
      domNode?.removeEventListener("touchmove", handleTouchMove);
      domNode?.removeEventListener("touchleave", handleTouchLeave);
      domNode?.removeEventListener("touchcancel", handleTouchCancel);
    });
  }

  watch(updateKey, async () => {
    if (!isEnabled.value) {
      removeListeners();
      return;
    }
    await nextTick();
    removeListeners();

    await nextTick();
    addListeners();
  });

  // Set draggable element same position as cursor ------------------------------
  const touchStartCoordinates = ref<{ x: number; y: number }>({ x: 0, y: 0 });
  const touchCoordinates = ref<{ x: number; y: number }>({ x: 0, y: 0 });

  watch(() => touchCoordinates.value, setDraggableElementSamePositionAsCursor);
  function setDraggableElementSamePositionAsCursor() {
    const draggableElement = document.querySelector("*[data-cloned-element-for-photos-section-touch-drag-event='true']") as HTMLElement;

    if (!draggableElement) return;

    draggableElement.style.left = touchCoordinates.value.x - 40 + "px";
    draggableElement.style.top = touchCoordinates.value.y - 40 + "px";
  }

  function handleTouchStart(event: TouchEvent) {
    if (!isEnabled.value) {
      removeListeners();
      return;
    }

    touchStartTimeStamp.value = Date.now();
    event.preventDefault();

    clearTimeout(dragInProgressSetTimeout);

    getThumbnailCoordinates();

    isTouchInProgress.value = true;
    touchFirstMoveTimeStamp.value = 0;

    elementIdTouchDragged.value = (event.target as HTMLElement)?.dataset?.dragId;
    elementIndexTouchDragged.value = +(event.target as HTMLElement)?.dataset?.index;
    elementDatasetTouchDragged.value = (event.target as HTMLElement)?.dataset;

    dragInProgressSetTimeout = setTimeout(() => {
      if (touchFirstMoveTimeStamp.value === 0 || touchFirstMoveTimeStamp.value - touchStartTimeStamp.value > touchDragStartTimeout) {
        isTouchDragInProgress.value = touchFirstMoveTimeStamp.value === 0 ? true : touchFirstMoveTimeStamp.value - touchStartTimeStamp.value > touchDragStartTimeout;

        if (isTouchDragInProgress.value) {
          const query = `${dragNDropElementQuery}[data-index="${+(event.target as HTMLElement)?.dataset.index}"]`;
          cloneAndAppendDraggedNode((event.target as HTMLElement).parentElement);
        }
      }
    }, touchDragStartTimeout);

    touchStartCoordinates.value = { x: event.touches[0].clientX, y: event.touches[0].clientY };
    touchCoordinates.value = { x: event.touches[0].clientX, y: event.touches[0].clientY };
  }

  function cloneAndAppendDraggedNode(node: HTMLElement) {
    const targetSize = node.getBoundingClientRect();

    draggedElementClone.value = (node as HTMLElement).cloneNode(true);
    (draggedElementClone.value as HTMLElement).setAttribute("data-cloned-element-for-photos-section-touch-drag-event", "true");

    (draggedElementClone.value as HTMLElement).style.position = "fixed";
    (draggedElementClone.value as HTMLElement).style.width = targetSize.width + "px";
    (draggedElementClone.value as HTMLElement).style.height = targetSize.height + "px";
    (draggedElementClone.value as HTMLElement).style.opacity = "0.7";
  }

  // Detect drag over thumbnails ==============================================

  const detectDragOverThumbnailsThrottled = lodashThrottle(detectDragOverThumbnails, 100);
  const allThumbnailCoordinates = ref<Array<{ width: number; height: number; x: number; y: number; dataset: DOMStringMap }>>([]);

  function getThumbnailCoordinates() {
    allThumbnailCoordinates.value = Array.from(parentContainer.value?.querySelectorAll(dragNDropElementQuery)).map(domNode => {
      const { width, height, left, top } = domNode.getBoundingClientRect();
      return { width, height, x: left, y: top, dataset: (domNode as HTMLElement).dataset };
    });
  }

  function detectDragOverThumbnails() {
    const intersectedThumbnail = allThumbnailCoordinates.value.find(({ width, height, x, y }) => {
      const isIntersectHorizontally = touchCoordinates.value.x >= x && touchCoordinates.value.x <= x + width;
      const isIntersectVertically = touchCoordinates.value.y >= y && touchCoordinates.value.y <= y + height;

      return isIntersectHorizontally && isIntersectVertically;
    });

    if (intersectedThumbnail) {
      elementAreaQuarterTouchDraggedOver.value = defineQuarter(
        {
          x: touchCoordinates.value.x,
          y: touchCoordinates.value.y,
        },
        {
          x: intersectedThumbnail.x,
          y: intersectedThumbnail.y,
          width: intersectedThumbnail.width,
          height: intersectedThumbnail.height,
        }
      );
    } else {
      elementAreaQuarterTouchDraggedOver.value = null;
    }

    elementIdTouchDraggedOver.value = intersectedThumbnail?.dataset?.dragId;
    elementIndexTouchDraggedOver.value = +intersectedThumbnail?.dataset?.index;
    elementDatasetTouchDraggedOver.value = intersectedThumbnail?.dataset;
  }

  // Handle touch end =========================================================
  function handleTouchEnd(event: TouchEvent) {
    clearTimeout(dragInProgressSetTimeout);

    if (elementDatasetTouchDragged.value && elementDatasetTouchDraggedOver.value) {
      callback(elementIndexTouchDragged.value, elementIndexTouchDraggedOver.value, elementDatasetTouchDragged.value, elementDatasetTouchDraggedOver.value, elementAreaQuarterTouchDraggedOver.value);
    }

    setTimeout(() => {
      isTouchInProgress.value = false;
      isTouchDragInProgress.value = false;
      elementIdTouchDragged.value = "";
      elementIdTouchDraggedOver.value = "";
      elementIndexTouchDragged.value = -1;
      elementIndexTouchDraggedOver.value = -1;
      elementDatasetTouchDragged.value = null;
      elementDatasetTouchDraggedOver.value = null;
      touchFirstMoveTimeStamp.value = 0;
      elementAreaQuarterTouchDraggedOver.value = null;
    }, 100);
  }

  // Handle touch move ========================================================
  const TOUCH_MOVE_SENSITIVITY_THRESHOLD_PX = 2;

  function handleTouchMove(event: TouchEvent) {
    if (!isEnabled.value) {
      removeListeners();
      return;
    }

    const moveByXAbs = Math.abs(Math.abs(touchStartCoordinates.value.x) - Math.abs(event.touches[0].clientX));
    const moveByYAbs = Math.abs(Math.abs(touchStartCoordinates.value.y) - Math.abs(event.touches[0].clientY));

    if (moveByXAbs <= TOUCH_MOVE_SENSITIVITY_THRESHOLD_PX && moveByYAbs <= TOUCH_MOVE_SENSITIVITY_THRESHOLD_PX) {
      // touchMove event on mobile is very sensitive, and it detects even the smallest fraction of pixel which we don't need
      return;
    }

    if (!touchFirstMoveTimeStamp.value) {
      touchFirstMoveTimeStamp.value = Date.now();
    }

    if (isTouchDragInProgress.value) {
      touchCoordinates.value = { x: event.touches[0].clientX, y: event.touches[0].clientY };

      event.preventDefault();
      event.stopPropagation();

      detectDragOverThumbnailsThrottled();

      return;
    }
  }

  function handleTouchLeave(event: TouchEvent) {}

  function handleTouchCancel(event: TouchEvent) {}

  // Append/remove draggable element ghost ====================================
  watch(
    () => isTouchDragInProgress.value,
    () => {
      if (isTouchDragInProgress.value) {
        parentContainer.value.appendChild(draggedElementClone.value);
        setDraggableElementSamePositionAsCursor();
      } else {
        document.querySelector("*[data-cloned-element-for-photos-section-touch-drag-event='true']").remove();
      }
    }
  );

  // Set data-is-touch-dragged-over/data-touch-dragged-over-q-* tag attrs =================
  watch(
    () => [elementIdTouchDraggedOver.value, elementAreaQuarterTouchDraggedOver.value, isTouchDragInProgress.value],
    (currentValues, prevValues) => {
      if (!currentValues[2]) {
        Array.from(document.querySelectorAll(dragNDropElementQuery)).forEach(el => {
          el?.removeAttribute("data-is-touch-dragged-over");
          el?.removeAttribute("data-touch-dragged-over-q");
        });
        return;
      }

      if (prevValues[0]) {
        const targetElem = document.querySelector(dragNDropElementQuery + `[data-drag-id="${prevValues[0]}"]`);
        targetElem?.removeAttribute("data-is-touch-dragged-over");
        targetElem?.removeAttribute("data-touch-dragged-over-q");
      }

      if (currentValues[0]) {
        const targetElem = document.querySelector(dragNDropElementQuery + `[data-drag-id="${currentValues[0]}"]`);
        targetElem?.setAttribute("data-is-touch-dragged-over", "true");
        targetElem?.setAttribute(`data-touch-dragged-over-q`, String(currentValues[1]));
      }
    }
  );

  return {
    isTouchDragInProgress,
    elementIdTouchDragged,
    elementIdTouchDraggedOver,
    elementIndexTouchDragged,
    elementIndexTouchDraggedOver,
    elementAreaQuarterTouchDraggedOver,
    elementDatasetTouchDragged,
    elementDatasetTouchDraggedOver,
    touchStartTimeStamp,
  };
}
