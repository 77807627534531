<template>
  <div class="steps-progressbar">
    <div class="steps-progressbar__text">Step {{ props.currentStep }} of {{ props.numberOfSteps }}</div>
    <div class="steps-progressbar__scale">
      <div
        class="steps-progressbar__progress-line"
        :style="{
          width: `${(100 / props.numberOfSteps) * props.currentStep}%`,
        }"
      ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    currentStep: number;
    numberOfSteps: number;
  }>(),
  {
    currentStep: 1,
    numberOfSteps: 1,
  }
);
</script>

<style scoped lang="scss">
// Steps progressbar ==========================================================
.steps-progressbar {
  &__text {
    margin-bottom: 7px;
    color: rgba(0, 0, 0, 1);
    font:
      700 14px/14px "Quicksand",
      sans-serif;
  }

  &__scale {
    height: 8px;
    border-radius: 100px;
    position: relative;
    font-size: 0;
    line-height: 0;
    background: rgba(217, 217, 217, 1);
  }

  &__progress-line {
    width: 0;
    height: 100%;
    border-radius: 100px;
    position: absolute;
    inset: 0 auto auto 0;
    background: rgba(80, 163, 56, 1);
    transition: width 0.09s ease-in-out;
  }
}
</style>
