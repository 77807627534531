<template>
  <component
    :is="props.tag"
    class="button-icon-expandable"
    :style="{
      transformOrigin: props.transformOrigin,
    }"
  >
    <IconEmbedded class="button-icon-expandable__icon-thick" :name="props.icons[0]" :size="props.iconSizes[0]" />
    <IconEmbedded class="button-icon-expandable__icon-thin" :name="props.icons[1]" :size="props.iconSizes[1]" />

    <!-- Slot is needed for a hidden <img /> with a thumbnail src which used by fancybox to generate it's own thumbnails -->
    <slot />
  </component>
</template>

<script setup lang="ts">
// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";

// Types
import { IconName } from "@contracts/IconName";

const props = withDefaults(
  defineProps<{
    tag?: string;
    icons?: Array<IconName>;
    iconSizes?: Array<number>;
    transformOrigin?: "top left" | "top right" | "bottom right" | "bottom left";
  }>(),
  {
    tag: "div",
    icons: () => ["maximize_2", "maximize_1-5"],
    iconSizes: () => [19, 19],
    transformOrigin: "top right",
  }
);
</script>

<style scoped lang="scss">
// Button icon expandable =====================================================
.button-icon-expandable {
  width: 21px;
  height: 21px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1);
  color: #fff;
  font-size: 17px;
  line-height: 17px;
  background: rgba(0, 0, 0, 0.15);
  //Caused issues when lots of photos: backdrop-filter: blur(10px);
  cursor: pointer;
  transition: transform 0.07s ease-in-out;

  &__invisible-image {
    display: none;
  }

  &::after {
    content: "";
    width: calc(100% + 14px);
    height: calc(100% + 14px);
    border-radius: 6px;
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    transform: scale(1.4);
  }

  &__icon-thick {
    display: block;

    :deep(svg) {
      fill: rgba(255, 255, 255, 1);
    }
  }
  &:hover &__icon-thick {
    display: none;
  }

  &__icon-thin {
    display: none;

    :deep(svg) {
      fill: rgba(255, 255, 255, 1);
    }
  }
  &:hover &__icon-thin {
    display: block;
  }
}
</style>
