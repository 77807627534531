import { screenSizes } from "@contracts/screenSize";

// Types
import { ScreenSize } from "@contracts/screenSize";

export function isBigger(size1: ScreenSize, size2: ScreenSize): boolean {
  return screenSizes.findIndex(s => s === size1) > screenSizes.findIndex(s => s === size2);
}
export function isSmaller(size1: ScreenSize, size2: ScreenSize): boolean {
  return screenSizes.findIndex(s => s === size1) < screenSizes.findIndex(s => s === size2);
}

export function isEqualOrBigger(size1: ScreenSize, size2: ScreenSize): boolean {
  return screenSizes.findIndex(s => s === size1) >= screenSizes.findIndex(s => s === size2);
}
export function isEqualOrSmaller(size1: ScreenSize, size2: ScreenSize): boolean {
  return screenSizes.findIndex(s => s === size1) <= screenSizes.findIndex(s => s === size2);
}

export default {
  isBigger,
  isSmaller,

  isEqualOrBigger,
  isEqualOrSmaller,
};
