<template>
  <div class="link-with-icon-and-cta">
    <div class="link-with-icon-and-cta__icon-and-text-wrap">
      <div v-if="props.iconSrc" class="link-with-icon-and-cta__icon-wrap">
        <img class="link-with-icon-and-cta__icon" :style="{ width: `${50}px` }" :src="props.iconSrc" :alt="props.text" />
      </div>

      <div
        :class="{
          'global-h3': true,
          'link-with-icon-and-cta__title': true,
          'link-with-icon-and-cta__title--dashed-underline': !props.to,
        }"
      >
        {{ props.text }}
      </div>
    </div>

    <SrpButton v-if="props.ctaText" class="link-with-icon-and-cta__cta-button" fill="outlined">
      {{ props.ctaText }}
    </SrpButton>
  </div>
</template>

<script setup lang="ts">
import SrpButton from "@components/ui/SrpButton.vue";

const props = withDefaults(
  defineProps<{
    text: string;
    ctaText?: string;
    to?: object | null;
    iconSrc?: string;
    iconSize?: number;
  }>(),
  {
    text: "",
    ctaText: "",
    to: null,
    iconSrc: "",
    iconSize: 50,
  }
);
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";
@import "@/scss/variables.scss";

// Link with icon and CTA =====================================================
.link-with-icon-and-cta {
  min-height: 65px;
  padding: 0 20px;
  border-radius: 10px;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  background: $faint-blue;
  user-select: none;
  cursor: pointer;

  &__icon-and-text-wrap {
    margin-right: 45px;
    display: flex;
    align-items: center;

    &:last-child {
      margin-right: 10px;
    }
  }

  &__icon-wrap {
    width: 40px;
    height: 40px;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
  }

  &__title {
    padding-bottom: 4px;
    font-weight: 500;
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.3);
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;

    &--dashed-underline {
      text-decoration-style: dashed;
    }
  }
  &:hover &__title {
    text-decoration: none;
  }

  &__cta-button {
    &::before {
      background: rgba(255, 255, 255, 1) !important;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .link-with-icon-and-cta {
    padding: 10px 23px 7px 20px;
    flex-direction: column;
    align-items: flex-start;

    &__icon-and-text-wrap {
      margin: 0 0 10px 0;

      &:last-child {
        margin: 0;
      }
    }

    &__cta-button {
      margin-bottom: 10px;
    }
  }
}
</style>
