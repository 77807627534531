<template>
  <ul
    class="srp-masonry"
    :style="{
      height: Math.max(...columnHeights) ? `${Math.max(...columnHeights)}px` : 'auto',
      gridTemplateColumns: `repeat(${props.columns},1fr)`,
      gridTemplateRows: rootContainerGridRows,
      gridColumnGap: `${props.columnGap}px`,
    }"
  >
    <li
      class="srp-masonry__item"
      v-for="(item, index) in props.items"
      :key="props.customKey ? item[props.customKey] : item"
      ref="domRefItems"
      :style="{ minWidth: screenSize === 'mobile' ? `calc(100% / ${props.columns} - 20px)` : 'auto', maxWidth: `calc(100vw / ${props.columns})` }"
      :data-index="index"
    >
      <!-- wrappers is needed to getBoundingClientRect().height to return correct values -->
      <div class="srp-masonry__item-in-wrap" ref="domRefItemInWraps" :data-index="index">
        <slot :item="item" :index="index" :rebuildMasonry="distributeItemsAndBuildMasonryDebounced" />
      </div>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { ref, onMounted, nextTick, watch, onBeforeUnmount, inject, Ref } from "vue";

// Types
import { ScreenSize } from "@contracts/screenSize";

// Global variables
const screenSize = inject("screenSize") as Ref<ScreenSize>;

const props = withDefaults(
  defineProps<{
    items: Array<any>;
    columns?: number;
    columnGap?: number;
    rerenderTimeout?: number;
    customKey?: string;
    threshold?: number;
  }>(),
  {
    items: () => [],
    columns: 2,
    columnGap: 10,
    rerenderTimeout: 300,
    customKey: "",
    threshold: 100, // The masonry looks best when the photo is placed in the leftmost column, as long as the difference from the smallest column is no more than this many pixels
  }
);

const emit = defineEmits<{
  (e: "masonryRebuilt"): void;
}>();

// Build masonry ==============================================================
const domRefItems = ref([]);
const domRefItemInWraps = ref([]); // wrappers is needed to getBoundingClientRect().height return correct values
const columnHeights = ref([...[...Array(props.columns)].map(() => 0)]);
const rootContainerGridRows = ref<string>("auto");

/*
 * Sorts the elements in the template refs lists manually to match the order of the elements in the props.items array, this is necessary because the VUE does not guarantee that the order of the elements in the template refs array will be the same as the order of the dom nodes in HTML (for example, when adding elements to the beginning of the props.items array, they appear at the end of the template refs array).*/
function sortTemplateRefs() {
  domRefItems.value = domRefItems.value.sort((a, b) => (+a.dataset.index > b.dataset.index ? 1 : -1));
  domRefItemInWraps.value = domRefItemInWraps.value.sort((a, b) => (+a.dataset.index > +b.dataset.index ? 1 : -1));
}
/*
 * Arranges items in columns because items can have bigger sizes when they're not arranged in columns properly
 * (it should be called BEFORE the buildMasonry function)
 * */
function distributeItemsAmongColumns() {
  sortTemplateRefs();
  columnHeights.value = [...[...Array(props.columns)].map(() => 0)];
  rootContainerGridRows.value = "auto";

  for (let i = 0, col = 1; i < domRefItems.value.length; i++, col++) {
    if (col > props.columns) col = 1;

    domRefItems.value[i].style.gridColumnStart = col;
    domRefItems.value[i].style.gridColumnEnd = col + 1;

    domRefItems.value[i].style.gridRowStart = "auto";
    domRefItems.value[i].style.gridRowEnd = "auto";
  }
}

/*
 * Builds the masonry layout without changing the items order in the html
 * (It's important for FancyBox because fancybox always uses the images order from the html structure and this approach can't be changed)
 *
 * Basic principle: it places every next image intro the smallest column.
 * */
interface DomNodeGridMap {
  gridColumnStart: number;
  gridColumnEnd: number;
  gridRowStart: number;
  gridRowEnd: number;
}

interface MasonryCalcResult {
  domNodesGridMapArray: Array<DomNodeGridMap>;
  wrapperGridRows: string;
  columnHeights: Array<number>;
}

function calculateMasonry(columnsNumber: number, domNodesList: Array<HTMLElement>, threshold = 25, itemBottomMargin = 0): MasonryCalcResult {
  const columnHeights = [...[...Array(columnsNumber)].map(() => 0)];

  const domNodesGridMapArray: Array<DomNodeGridMap> = domNodesList.map(
    () =>
      ({
        gridColumnStart: 0,
        gridColumnEnd: 0,
        gridRowStart: 0,
        gridRowEnd: 0,
      }) as DomNodeGridMap
  );

  for (let i = 0; i < domNodesList.length; i++) {
    const boxHeight = Math.round(domNodesList[i].getBoundingClientRect().height);

    const smallestColumnHeight = Math.min(...columnHeights);
    const smallestColumnIndex = columnHeights.findIndex(c => c === smallestColumnHeight);

    let targetColumnHeight: number;
    let targetColumnIndex: number;

    if (smallestColumnIndex !== 0) {
      // do not do anything if the smallest column is the leftmost already
      for (let i = 0; i < columnHeights.slice(0, smallestColumnIndex).length; i++) {
        if (columnHeights[i] <= smallestColumnHeight + threshold) {
          targetColumnHeight = columnHeights[i];
          targetColumnIndex = i;
          break;
        }
      }
    }

    targetColumnHeight = typeof targetColumnHeight === "undefined" ? smallestColumnHeight : targetColumnHeight;
    targetColumnIndex = typeof targetColumnIndex === "undefined" ? smallestColumnIndex : targetColumnIndex;

    domNodesGridMapArray[i].gridColumnStart = targetColumnIndex + 1;
    domNodesGridMapArray[i].gridColumnEnd = targetColumnIndex + 2;

    domNodesGridMapArray[i].gridRowStart = targetColumnHeight + 1;
    domNodesGridMapArray[i].gridRowEnd = targetColumnHeight + 1 + boxHeight + itemBottomMargin;

    columnHeights[targetColumnIndex] += boxHeight + itemBottomMargin;
  }

  const wrapperGridRows = `repeat(${Math.max(...columnHeights)},1px)`;

  return {
    domNodesGridMapArray,
    wrapperGridRows,
    columnHeights,
  };
}

async function distributeItemsAndBuildMasonry() {
  distributeItemsAmongColumns(); // distribution is needed as a first step to avoid getting the wrong items height values
  await nextTick();
  rootContainerGridRows.value = "auto";
  const masonryCalcResult = calculateMasonry(props.columns, domRefItemInWraps.value, props.threshold);

  masonryCalcResult.domNodesGridMapArray.forEach((value, index) => {
    domRefItems.value[index].style.gridColumnStart = value.gridColumnStart;
    domRefItems.value[index].style.gridColumnEnd = value.gridColumnEnd;

    domRefItems.value[index].style.gridRowStart = value.gridRowStart;
    domRefItems.value[index].style.gridRowEnd = value.gridRowEnd;
  });
  rootContainerGridRows.value = masonryCalcResult.wrapperGridRows;
  columnHeights.value = masonryCalcResult.columnHeights;

  await nextTick();
  emit("masonryRebuilt");
}

// Wrap build func with debounce ==============================================
let rebuildTimeoutId = null;
function distributeItemsAndBuildMasonryDebounced() {
  clearTimeout(rebuildTimeoutId);
  rebuildTimeoutId = setTimeout(distributeItemsAndBuildMasonry, props.rerenderTimeout);
}

// Build masonry on mount =====================================================
onMounted(async () => {
  await nextTick();
  await distributeItemsAndBuildMasonry();
});

// Rebuild on viewport resize =================================================
onMounted(() => {
  window.addEventListener("resize", distributeItemsAndBuildMasonryDebounced);
});
onBeforeUnmount(() => {
  window.removeEventListener("resize", distributeItemsAndBuildMasonryDebounced);
});

// Rerender on items list update ==============================================
watch(() => props.items, distributeItemsAndBuildMasonryDebounced);

// Define expose ==============================================================
defineExpose({ domRefItems, rebuildMasonry: distributeItemsAndBuildMasonryDebounced });
</script>

<style scoped lang="scss">
// Shrpa Masonry ==============================================================
.srp-masonry {
  padding: 0;
  margin: 0;
  display: grid;
  list-style: none;

  &__item {
    display: flex;
    flex-direction: column;
    transition:
      grid-column-start 1s ease-in-out,
      grid-column-end 1s ease-in-out,
      grid-row-start 1s ease-in-out,
      grid-row-end 1s ease-in-out;
  }

  &__in-wrap {
    display: flex;
    flex-direction: column;
  }
}
</style>
