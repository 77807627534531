<template>
  <div>
    <div class="inner-hero" style="background-image: url(https://cdn.shrpa.com/images/backgrounds/typewriter.jpg)">
      <div class="header-overlay wow fadeIn">
        <h2 class="ui inverted header header-title">Contact shrpa</h2>
        <h2 class="ui inverted header header-heading">Get in touch today!</h2>
      </div>
    </div>

    <div class="content-wrapper">
      <div class="stackable very relaxed ui grid container">
        <div class="row">
          <div class="four wide column">
            <img class="ui image" src="https://cdn.shrpa.com/images/shrpa-full-color.png" />
          </div>

          <div class="twelve wide column">
            <h1 class="page-subheader">Feedback?</h1>
            <p class="intro">Our site is constantly evolving and we're adding adventures and features as quickly as possible. If you have an idea that would improve things please let us know!</p>

            <p>
              <b><i class="icon envelope outline"></i> Email:</b> <CopyText />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useHead } from "@unhead/vue";

// Components
import CopyText from "@components/CopyText.vue";

export default defineComponent({
  name: "Contact",

  components: {
    CopyText,
  },

  mounted() {
    useHead({ title: "Contact Shrpa" });
  },
});
</script>
