<template>
  <div class="planned-visit-snippet">
    <h3 class="global-h3 planned-visit-snippet__title">{{ plannedCollab.name }}</h3>

    <div class="planned-visit-snippet__date-n-visit-focus">
      <!-- Date snippet -->
      <div v-if="dateRangeText" class="date-snippet planned-visit-snippet__date-snippet">
        <IconEmbedded class="date-snippet__icon" name="calendar_2" />
        <div class="date-snippet__text">{{ dateRangeText }}</div>
      </div>
      <!-- / Date snippet -->

      <!-- Visit focus snippet -->
      <div v-if="plannedCollab.themeType" class="visit-focus-snippet planned-visit-snippet__visit-focus-snippet">
        <img class="visit-focus-snippet__icon" :src="getThemeIcon(plannedCollab.themeType)" alt="family" />
        <div class="visit-focus-snippet__text">
          {{ getThemeTypeName(plannedCollab.themeType) }}
        </div>
      </div>
      <!-- / Visit focus snippet -->
    </div>

    <div class="planned-visit-snippet__divider"></div>

    <div class="planned-visit-snippet__details-text">{{ plannedCollab.notes }}</div>

    <div class="planned-visit-snippet__buttons-panel">
      <SrpButton class="planned-visit-snippet__button" size="small" @click="emit('click:getStarted')">Get Started</SrpButton>
      <SrpButton class="planned-visit-snippet__button" size="small" color="gray" fill="outlined" style="padding-left: 17px" @click="emit('click:edit')">
        <template #icon><IconEmbedded name="edit-pencil_2" style="margin-right: -5px" :size="17" color="rgba(0, 0, 0, 0.3)" /></template>
        Edit
      </SrpButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { getThemeTypeName, getThemeIcon } from "@helpers/ThemeTypeHelper";

// Components
import SrpButton from "@components/ui/SrpButton.vue";
import { type PlannedCollab } from "@repos/PlannedCollabsRepo";
import moment from "moment";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

const props = defineProps<{ plannedCollab: PlannedCollab }>();

const dateRangeText = computed(() => {
  if (props.plannedCollab.preferredDateRangeStart === null) {
    return "";
  }
  if (props.plannedCollab.preferredDateRangeEnd) {
    const startDate = moment(props.plannedCollab.preferredDateRangeStart).format("MMM D");
    const endDate = moment(props.plannedCollab.preferredDateRangeEnd).format("MMM D");
    return `${startDate} - ${endDate}`;
  }
  return moment(props.plannedCollab.preferredDateRangeStart).format("MMM, YYYY");
});

const emit = defineEmits<{
  (e: "click:edit"): void;
  (e: "click:getStarted"): void;
}>();
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Visit focus snippet ========================================================
.visit-focus-snippet {
  display: flex;
  align-items: center;

  &__icon {
    width: 30px;
    height: 30px;
    margin-right: 2px;
  }

  &__text {
  }
}

// Date snippet ===============================================================
.date-snippet {
  display: flex;
  align-items: center;

  &__icon {
    width: 22px;
    margin-right: 5px;

    :deep(svg) {
      fill: rgba(0, 0, 0, 0.5);
    }
  }

  &__text {
    font-weight: bold;
  }
}

// Planned visit snippet ======================================================
.planned-visit-snippet {
  padding: 16px 22px 21px;
  box-sizing: border-box;
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);

  &__title {
    margin-bottom: 11px;
  }

  &__date-n-visit-focus {
    margin-bottom: 9px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__date-snippet {
    margin-right: 20px;
    display: flex;
    align-items: center;

    &:last-child {
      margin-right: 0;
    }
  }

  &__visit-focus-snippet {
    display: flex;
    align-items: center;
  }

  &__divider {
    width: 100%;
    margin-bottom: 14px;
    height: 0;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__details-text {
    white-space: pre-wrap;
    margin-bottom: 13px;
  }

  &__buttons-panel {
    display: flex;
    align-items: center;
  }

  &__button {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .planned-visit-snippet {
    padding: 13px 17px 16px;

    &__title {
      margin-bottom: 8px;
    }

    &__date-n-visit-focus {
      margin-bottom: 6px;
    }

    &__divider {
      margin-bottom: 10px;
    }
  }
}
</style>
