export interface ImgOrVideoResolution {
  w: number;
  h: number;
}

export async function getImageResolution(imgFile: File): Promise<ImgOrVideoResolution> {
  return new Promise((resolve, reject) => {
    let img;
    const reader = new FileReader();

    reader.onload = async r => {
      img = new Image();
      img.src = r.target.result;
      img.onload = async function () {
        resolve({
          w: this.width,
          h: this.height,
        });
      };
    };

    reader.readAsDataURL(imgFile as Blob);
  });
}

export function getVideoResolution(videoFile: File): Promise<ImgOrVideoResolution> {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");

    video.addEventListener("loadedmetadata", event => {
      resolve({ w: video.videoWidth, h: video.videoHeight });
    });
    video.src = URL.createObjectURL(videoFile);
  });
}
