<template>
  <SrpModal v-model:isVisible="isVisible">
    <template #content>
      <!-- Get started form -->
      <div class="get-started-form">
        <!-- Get started option snippet -->
        <div class="get-started-option-snippet get-started-form__option-snippet">
          <div class="get-started-option-snippet__icon-and-name">
            <img class="get-started-option-snippet__icon" src="/images/rocketIcon.svg" alt="Begin a Creator Visit" />

            <h2 class="global-h2 get-started-option-snippet__name">Begin a Creator Visit</h2>
          </div>

          <div class="get-started-option-snippet__form">
            <!--<div class="get-started-option-snippet__date-input"></div>-->

            <SrpButton
              class="get-started-option-snippet__confirm-btn"
              fill="outlined"
              :to="{
                name: 'PaidCollabCommunityAdmin',
                params: { communityId: String(props.pageId) },
                hash: `#collabSuggestionId=${collabSuggestionId}&theme=${theme}`,
              }"
            >
              Begin
              <template #iconRight><IconEmbedded name="arrow-right_3-5" :size="20" /></template>
            </SrpButton>
          </div>
        </div>
        <!-- / Get started option snippet -->

        <div class="get-started-form__or-snippet">or</div>
        <!-- Get started option snippet -->
        <div class="get-started-option-snippet get-started-form__option-snippet">
          <div class="get-started-option-snippet__icon-and-name">
            <img class="get-started-option-snippet__icon" src="/images/saveIcon.svg" alt="Begin a Creator Visit" />

            <h2 class="global-h2 get-started-option-snippet__name">Save to your Calendar</h2>
          </div>

          <div class="get-started-option-snippet__form">
            <DatePicker class="get-started-option-snippet__date-input" v-model:dateRange="plannedVisitDateRange" :isFormFocused="false" :onlyAllowFutureDates="true" />

            <SrpButton
              class="get-started-option-snippet__confirm-btn"
              fill="outlined"
              :isDisabled="!plannedVisitDateRange.start"
              :to="{
                name: 'PaidCollabCommunityAdmin',
                params: { communityId: String(props.pageId) },
                hash: `#collabSuggestionIdToSaveAsPlanned=${collabSuggestionId}&startDate=${plannedVisitDateRange.start}&endDate=${plannedVisitDateRange.end}`,
              }"
            >
              Save
              <template #iconRight><IconEmbedded name="arrow-right_3-5" :size="20" /></template>
            </SrpButton>
          </div>
        </div>
        <!-- / Get started option snippet -->
      </div>
      <!-- / Get started form -->
    </template>
  </SrpModal>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";

// Components
import SrpModal from "@components/ui/SrpModal.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import DatePicker from "@views/PaidCollab/PlannedCollabs/DatePicker.vue";

// Types
import { DateRange } from "@views/PaidCollab/PlannedCollabs/DatePicker.vue";

const props = withDefaults(
  defineProps<{
    pageId: string | null;
  }>(),
  {
    pageId: null,
  }
);

// Collab suggestion ID =======================================================
const collabSuggestionId = ref<string | null>(null);
const theme = ref<string | null>(null);
// Show modal =================================================================
const isVisible = ref<boolean>(false);

function openModal(_collabSuggestionId: string, _theme: string | null) {
  collabSuggestionId.value = _collabSuggestionId;
  theme.value = _theme;

  isVisible.value = true;
}

// Planned visit date range ===================================================
const plannedVisitDateRange = ref<DateRange>({
  start: null,
  end: null,
});

watch(isVisible, () => {
  plannedVisitDateRange.value = {
    start: null,
    end: null,
  };
});

// Define expose ==============================================================
defineExpose({
  openModal,
});
</script>

<style scoped lang="scss">
// Get started option snippet =================================================
.get-started-option-snippet {
  min-height: 380px;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(231, 237, 240, 1);

  &__icon-and-name {
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__icon {
  }

  &__name {
    text-align: center;
  }

  &__form {
    height: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__date-input {
    margin-bottom: 10px;
  }

  &__confirm-btn {
    &::before {
      background: rgba(255, 255, 255, 1);
    }

    &:hover::before {
      background: rgba(255, 255, 255, 1);
    }
  }
}

// Get started form ===========================================================
.get-started-form {
  display: flex;
  align-items: center;

  &__option-snippet {
    width: calc(50% - 5px);
  }

  &__or-snippet {
    width: 10px;
    height: 10px;
    padding-bottom: 2px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
    color: rgba(0, 0, 0, 1);
    font:
      700 20px/20px "Quicksand",
      sans-serif;

    &::before {
      content: "";
      width: 44px;
      height: 44px;
      border-radius: 100px;
      position: absolute;
      inset: calc(50% - 22px) auto auto calc(50% - 22px);
      z-index: -1;
      background: rgba(255, 255, 255, 1);
    }
  }
}
</style>
