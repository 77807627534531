<template>
  <SrpModal :is-closable="false" :is-visible="props.isVisible" @update:isVisible="emit('update:isVisible', false)" size="large" isWithScroll maxHeight="calc(100dvh - 220px)" ref="domRefSrpModal">
    <template #content>
      <h1 class="global-h1" style="margin-bottom: 10px">Let's plan out your creator visits!</h1>
      <div style="margin-bottom: 15px; color: #5b5b5b; font-family: sans-serif">Make a rough plan for when you need content the most</div>

      <!-- Planned visit form sections -->
      <div class="planned-visit-form-sections">
        <PlannedCollabFormSnippet
          :class="{
            'planned-visit-form-sections__section': true,
            'planned-visit-form-sections__section--active': activeSectionIndex === index,
            'planned-visit-form-sections__section--inactive': activeSectionIndex !== -1 && activeSectionIndex !== index,
          }"
          v-for="(plannedCollab, index) in editablePlannedCollabs"
          :key="plannedCollab.id"
          :planned-collab="plannedCollab"
          @update:planned-collab="editablePlannedCollabs[index] = $event"
          @click="activeSectionIndex = index"
          :display-next-button="index !== editablePlannedCollabs.length - 1 && activeSectionIndex === index"
          :display-clear-button="activeSectionIndex === index"
          :is-form-focused="activeSectionIndex === index"
          @go-to-next="goToPlanAtIndex(index + 1)"
          :data-index="index"
        />
      </div>
      <!-- / Planned visit form sections -->
    </template>
    <template #footer>
      <div class="modal-footer" style="width: 100%">
        <div class="global-h5 modal-footer__counter">{{ completedCollabsLength }} of {{ plannedCollabs.length }} planned</div>

        <div class="modal-footer__buttons">
          <SrpButton class="modal-footer__button" @click="emit('update:isVisible', false)" color="gray" fill="outlined">Cancel</SrpButton>
          <SrpButton class="modal-footer__button" @click="saveAndClose" :isDisabled="false">
            <template #icon><IconEmbedded name="save_2" style="margin-right: 6px" :size="23" color="rgba(255, 255, 255, 0.4)" /></template>
            <template v-if="completedCollabsLength == plannedCollabs.length">I'm Done!</template>
            <template v-else>Save and Finish Later</template>
          </SrpButton>
        </div>
      </div>
    </template>
  </SrpModal>
</template>

<script setup lang="ts">
import { computed, nextTick, ref, watch } from "vue";
import cloneDeep from "lodash-es/cloneDeep";

// Components
import PlannedCollabFormSnippet from "./PlannedCollabFormSnippet.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import { type PlannedCollab } from "@repos/PlannedCollabsRepo";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

const props = withDefaults(
  defineProps<{
    isVisible: boolean;
    activePlanIndex: number;
    plannedCollabs: PlannedCollab[];
  }>(),
  {
    activePlanIndex: 0,
  }
);

const emit = defineEmits<{
  (e: "update:isVisible", isVisible: boolean): void;
  (e: "save", plannedCollabs: PlannedCollab[]): void;
}>();

const editablePlannedCollabs = ref<PlannedCollab[]>([]);

watch(
  () => props.plannedCollabs,
  newVal => {
    editablePlannedCollabs.value = cloneDeep(newVal);
  },
  { deep: true, immediate: true }
);

// Active section =============================================================
const activeSectionIndex = ref<number>(props.activePlanIndex);

watch(
  () => props.isVisible,
  newVal => {
    if (newVal) editablePlannedCollabs.value = cloneDeep(props.plannedCollabs);
    goToPlanAtIndex(activeSectionIndex.value, false);
  },
  { deep: true, immediate: true }
);

watch(
  () => props.activePlanIndex,
  () => {
    activeSectionIndex.value = props.activePlanIndex;
    goToPlanAtIndex(activeSectionIndex.value, false);
  }
);

// Go to next slide ===========================================================
function goToPlanAtIndex(index: number, shouldScroll = true) {
  activeSectionIndex.value = index;
  nextTick(() => {
    scrollModalContentToActiveForm(shouldScroll);
  });
}

function saveAndClose() {
  emit("save", editablePlannedCollabs.value);
  emit("update:isVisible", false);
}

// Scroll modal content to active form ========================================
const domRefSrpModal = ref<typeof SrpModal>();
function scrollModalContentToActiveForm(shouldScroll: boolean) {
  const targetIndex = activeSectionIndex.value;
  if (targetIndex <= 0) return;
  const scrollableContainer = domRefSrpModal.value.domRefContent as HTMLElement;

  // The system of dom refs in VUE 3 doesn't guarantee the preservation of sequence, that's why native DOM api is used
  const targetFormSection = document.querySelector(`.planned-visit-form-sections__section[data-index='${targetIndex}']`) as HTMLElement;

  if (scrollableContainer) {
    scrollableContainer.style.scrollBehavior = shouldScroll ? "smooth" : "auto";
    scrollableContainer.scrollTop = targetFormSection.offsetTop - 70;
  }
}

const completedCollabsLength = computed<number>(() => {
  return editablePlannedCollabs.value.filter(plannedCollab => plannedCollab.name && plannedCollab.preferredDateRangeStart).length;
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Modal footer ===============================================================
.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__counter {
    color: rgba(91, 91, 91, 0.7);
    font-weight: 500;
  }

  &__buttons {
    display: flex;
    align-items: center;
  }

  &__button {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .modal-footer {
    flex-direction: column;

    &__counter {
      margin-bottom: -15px;
      align-self: flex-start;
      position: relative;
      top: 15px;
    }

    &__buttons {
      width: 100%;
      flex-direction: column;
      align-items: flex-end;
    }

    &__button {
      margin: 0 0 10px 0;

      &:last-child {
        margin: 0;
      }
    }
  }
}

// Planned visit form sections ================================================
.planned-visit-form-sections {
  padding-top: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__section {
    width: 100%;
    margin-bottom: 24px;
    position: relative;
    z-index: 0;
    transform: scale(1);
    transition: transform 0.05s ease-in-out;

    &--active {
      z-index: 1;
      //transform: scale(1.05);
      box-shadow: 0 15px 20px -10px rgba(0, 0, 0, 0.45);
    }

    &--inactive {
      opacity: 0.4;
    }
  }
}
</style>
