import { nextTick, onBeforeUnmount, onMounted, Ref, ref, watch } from "vue";
import lodashCloneDeep from "lodash-es/cloneDeep";
import lodashThrottle from "lodash-es/throttle";

export type AreaQuarter = 1 | 2 | 3 | 4; // top-left, top-right, bottom-right, bottom-left

export function moveItemToNewIndex(elementsList: Array<any>, oldIndex: number, newIndex: number, isMasonry = false): Array<any> {
  const reorderedElement = elementsList[oldIndex];
  const newList = [...elementsList];
  newList.splice(oldIndex, 1);

  if (isMasonry) {
    newIndex = newIndex > oldIndex ? newIndex + 1 : newIndex; // FOR MASONRY ONLY: when Dragged element index changes to a higher value it should be compensated by adding +1 to an index otherwise it will end up before the DraggedOver element (it happens because all the element before the DraggedOver element shifts to compensate the empty spaces caused by moving the Dragged element)
  }

  return [...newList.slice(0, newIndex), reorderedElement, ...newList.slice(newIndex)];
}

export function defineQuarter(cursorCoordinates: { x: number; y: number }, elementDimensionsAndCoordinates: { x: number; y: number; width: number; height: number }): AreaQuarter {
  const { x, y } = cursorCoordinates;
  const { x: elementX, y: elementY, width, height } = elementDimensionsAndCoordinates;

  // Calc element center
  const centerX = elementX + width / 2;
  const centerY = elementY + height / 2;

  if (x < centerX) return y < centerY ? 1 : 4;
  else return y < centerY ? 2 : 3;
}

// TODO: use cursor position instead of dragover event to calculate the intersection (same as on the useMouseDragNDrop composable)
// TODO: implement the ability to move the element in both places: above and below any other element
// TODO: add "data-dragged-over-above" and "data-dragged-over-below" instead of the "elementIndexDraggedOver" reactive variables to set classes
export function useMouseDragNDrop(
  isEnabled: Ref<boolean>,
  updateKey: Ref<any>,
  parentContainer: Ref<HTMLElement | null>,
  dragNDropElementQuery: string,
  dragNDropHandleQuery: string,
  callback: Function,
  mouseDragStartTimeout = 10
) {
  const isMouseInProgress = ref<boolean>(false);
  const isDragInProgress = ref<boolean>(false);

  const mouseStartTimeStamp = ref<number>(0);
  const mouseFirstMoveTimeStamp = ref<number>(0);

  const elementIdDragged = ref<string>("");
  const elementIdDraggedOver = ref<string>("");
  const elementIndexDragged = ref<number>(-1);
  const elementIndexDraggedOver = ref<number>(-1);
  const elementDatasetDragged = ref<DOMStringMap>(null);
  const elementDatasetDraggedOver = ref<DOMStringMap>(null);
  const elementAreaQuarterDraggedOver = ref<AreaQuarter>(null);

  const draggedElementClone = ref<Node | null>(null);

  let dragInProgressSetTimeout: ReturnType<typeof setTimeout> | null = null;

  // Add drag listeners =======================================================
  onMounted(async () => {
    if (!isEnabled.value) {
      removeListeners();
      return;
    }

    await nextTick();

    removeListeners();
    addListeners();
  });

  onBeforeUnmount(removeListeners);

  function addListeners() {
    parentContainer.value?.querySelectorAll(dragNDropHandleQuery).forEach(domNode => {
      domNode?.addEventListener("mousedown", handleMouseStart);
      document?.addEventListener("mouseup", handleMouseEnd);
      document?.addEventListener("mousemove", handleMouseMove);
    });
  }

  function removeListeners() {
    parentContainer.value?.querySelectorAll(dragNDropHandleQuery).forEach(domNode => {
      domNode?.removeEventListener("mousedown", handleMouseStart);
      document?.removeEventListener("mouseup", handleMouseEnd);
      document?.removeEventListener("mousemove", handleMouseMove);
    });
  }

  watch(updateKey, async () => {
    if (!isEnabled.value) {
      removeListeners();
      return;
    }
    await nextTick();
    removeListeners();

    await nextTick();
    addListeners();
  });

  // Set draggable element same position as cursor ------------------------------
  const mouseStartCoordinates = ref<{ x: number; y: number }>({ x: 0, y: 0 });
  const mouseCoordinates = ref<{ x: number; y: number }>({ x: 0, y: 0 });

  watch(() => mouseCoordinates.value, setDraggableElementSamePositionAsCursor);
  function setDraggableElementSamePositionAsCursor() {
    const draggableElement = document.querySelector("*[data-cloned-element-for-photos-section-mouse-drag-event='true']") as HTMLElement;

    if (!draggableElement) return;

    draggableElement.style.left = mouseCoordinates.value.x - 40 + "px";
    draggableElement.style.top = mouseCoordinates.value.y - 40 + "px";
  }

  function handleMouseStart(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (!isEnabled.value) {
      removeListeners();
      return;
    }

    parentContainer.value?.querySelectorAll(dragNDropHandleQuery).forEach(domNode => {
      (domNode as HTMLElement).style.cursor = "grabbing";
    });

    mouseStartTimeStamp.value = Date.now();
    event.preventDefault();
    event.stopPropagation();

    clearTimeout(dragInProgressSetTimeout);

    getThumbnailCoordinates();

    isMouseInProgress.value = true;
    mouseFirstMoveTimeStamp.value = 0;

    elementIdDragged.value = (event.target as HTMLElement)?.dataset?.dragId;
    elementIndexDragged.value = +(event.target as HTMLElement)?.dataset?.index;
    elementDatasetDragged.value = (event.target as HTMLElement)?.dataset;

    dragInProgressSetTimeout = setTimeout(() => {
      if (mouseFirstMoveTimeStamp.value === 0 || mouseFirstMoveTimeStamp.value - mouseStartTimeStamp.value > mouseDragStartTimeout) {
        isDragInProgress.value = mouseFirstMoveTimeStamp.value === 0 ? true : mouseFirstMoveTimeStamp.value - mouseStartTimeStamp.value > mouseDragStartTimeout;

        if (isDragInProgress.value) {
          const query = `${dragNDropElementQuery}[data-index="${+(event.target as HTMLElement)?.dataset.index}"]`;
          cloneAndAppendDraggedNode((event.target as HTMLElement).parentElement);
        }
      }
    }, mouseDragStartTimeout);

    mouseStartCoordinates.value = { x: event.x, y: event.y };
    mouseCoordinates.value = { x: event.x, y: event.y };
  }

  function cloneAndAppendDraggedNode(node: HTMLElement) {
    const targetSize = node.getBoundingClientRect();

    draggedElementClone.value = (node as HTMLElement).cloneNode(true);
    (draggedElementClone.value as HTMLElement).style.cursor = "grabbing";

    const dragAndDropHandle = (draggedElementClone.value as HTMLElement).querySelector(".drag-n-drop-handle") as HTMLElement;
    if (dragAndDropHandle) {
      dragAndDropHandle.style.cursor = "grabbing";
    }

    (draggedElementClone.value as HTMLElement).setAttribute("data-cloned-element-for-photos-section-mouse-drag-event", "true");

    (draggedElementClone.value as HTMLElement).style.position = "fixed";
    (draggedElementClone.value as HTMLElement).style.width = targetSize.width + "px";
    (draggedElementClone.value as HTMLElement).style.height = targetSize.height + "px";
    (draggedElementClone.value as HTMLElement).style.opacity = "0.7";
  }

  // Detect drag over thumbnails ==============================================

  const detectDragOverThumbnailsThrottled = lodashThrottle(detectDragOverThumbnails, 100);
  const allThumbnailCoordinates = ref<Array<{ width: number; height: number; x: number; y: number; dataset: DOMStringMap }>>([]);

  function getThumbnailCoordinates() {
    allThumbnailCoordinates.value = Array.from(parentContainer.value?.querySelectorAll(dragNDropElementQuery)).map(domNode => {
      const { width, height, left, top } = domNode.getBoundingClientRect();
      return { width, height, x: left, y: top, dataset: (domNode as HTMLElement).dataset };
    });
  }

  function detectDragOverThumbnails() {
    const intersectedThumbnail = allThumbnailCoordinates.value.find(({ width, height, x, y }) => {
      const isIntersectHorizontally = mouseCoordinates.value.x >= x && mouseCoordinates.value.x <= x + width;
      const isIntersectVertically = mouseCoordinates.value.y >= y && mouseCoordinates.value.y <= y + height;

      return isIntersectHorizontally && isIntersectVertically;
    });

    if (intersectedThumbnail) {
      elementAreaQuarterDraggedOver.value = defineQuarter(
        {
          x: mouseCoordinates.value.x,
          y: mouseCoordinates.value.y,
        },
        {
          x: intersectedThumbnail.x,
          y: intersectedThumbnail.y,
          width: intersectedThumbnail.width,
          height: intersectedThumbnail.height,
        }
      );
    } else {
      elementAreaQuarterDraggedOver.value = null;
    }

    elementIdDraggedOver.value = intersectedThumbnail?.dataset?.dragId;
    elementIndexDraggedOver.value = +intersectedThumbnail?.dataset?.index;
    elementDatasetDraggedOver.value = intersectedThumbnail?.dataset;
  }

  // Handle mouse end =========================================================
  function handleMouseEnd(event: Event) {
    clearTimeout(dragInProgressSetTimeout);

    parentContainer.value?.querySelectorAll(dragNDropHandleQuery).forEach(domNode => {
      (domNode as HTMLElement).style.cursor = "grab";
    });

    if (elementDatasetDragged.value && elementDatasetDraggedOver.value) {
      callback(elementIndexDragged.value, elementIndexDraggedOver.value, elementDatasetDragged.value, elementDatasetDraggedOver.value, elementAreaQuarterDraggedOver.value);
    }

    setTimeout(() => {
      isMouseInProgress.value = false;
      isDragInProgress.value = false;
      elementIdDragged.value = "";
      elementIdDraggedOver.value = "";
      elementIndexDragged.value = -1;
      elementIndexDraggedOver.value = -1;
      elementDatasetDragged.value = null;
      elementDatasetDraggedOver.value = null;
      mouseFirstMoveTimeStamp.value = 0;
      elementAreaQuarterDraggedOver.value = null;
    }, 100);
  }

  // Handle mouse move ========================================================
  const MOUSE_MOVE_SENSITIVITY_THRESHOLD_PX = 2;

  function handleMouseMove(event: MouseEvent) {
    if (!isEnabled.value) {
      removeListeners();
      return;
    }

    const moveByXAbs = Math.abs(Math.abs(mouseStartCoordinates.value.x) - Math.abs(event.x));
    const moveByYAbs = Math.abs(Math.abs(mouseStartCoordinates.value.y) - Math.abs(event.y));

    if (moveByXAbs <= MOUSE_MOVE_SENSITIVITY_THRESHOLD_PX && moveByYAbs <= MOUSE_MOVE_SENSITIVITY_THRESHOLD_PX) {
      // mouseMove event on mobile is very sensitive, and it detects even the smallest fraction of pixel which we don't need
      return;
    }

    if (!mouseFirstMoveTimeStamp.value) {
      mouseFirstMoveTimeStamp.value = Date.now();
    }

    if (isDragInProgress.value) {
      mouseCoordinates.value = { x: event.x, y: event.y };

      event.preventDefault();
      event.stopPropagation();

      detectDragOverThumbnailsThrottled();

      return;
    }
  }

  // Append/remove draggable element ghost ====================================
  watch(
    () => isDragInProgress.value,
    () => {
      if (isDragInProgress.value) {
        document.querySelector("body").appendChild(draggedElementClone.value);
        setDraggableElementSamePositionAsCursor();
      } else {
        document.querySelector("*[data-cloned-element-for-photos-section-mouse-drag-event='true']").remove();
      }
    }
  );

  // Set data-is-mouse-dragged-over/data-mouse-dragged-over-q-* tag attrs =================
  watch(
    () => [elementIdDraggedOver.value, elementAreaQuarterDraggedOver.value, isDragInProgress.value],
    (currentValues, prevValues) => {
      if (!currentValues[2]) {
        Array.from(document.querySelectorAll(dragNDropElementQuery)).forEach(el => {
          el?.removeAttribute("data-is-mouse-dragged-over");
          el?.removeAttribute("data-mouse-dragged-over-q");
        });
        return;
      }

      if (prevValues[0]) {
        const targetElem = document.querySelector(dragNDropElementQuery + `[data-drag-id="${prevValues[0]}"]`);
        targetElem?.removeAttribute("data-is-mouse-dragged-over");
        targetElem?.removeAttribute("data-mouse-dragged-over-q");
      }

      if (currentValues[0]) {
        const targetElem = document.querySelector(dragNDropElementQuery + `[data-drag-id="${currentValues[0]}"]`);
        targetElem?.setAttribute("data-is-mouse-dragged-over", "true");
        targetElem?.setAttribute(`data-mouse-dragged-over-q`, String(currentValues[1]));
      }
    }
  );

  return {
    isDragInProgress,
    elementIdDragged,
    elementIdDraggedOver,
    elementIndexDragged,
    elementIndexDraggedOver,
    elementAreaQuarterDraggedOver,
    elementDatasetDragged,
    elementDatasetDraggedOver,
    mouseStartTimeStamp,
  };
}
