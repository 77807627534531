<template>
  <span class="copy-text-snippet">
    <span v-if="showText">
      <span v-if="$slots.default" class="copy-text-snippet__text-itself">
        <slot />
      </span>
      <span v-else class="copy-text-snippet__text-itself">
        {{ textToShow }}
      </span>
    </span>

    <span class="copy-text-snippet__copy-icon-wrap" @click="copy">
      <IconEmbedded class="copy-text-snippet__copy-icon" name="copy_2-5" :color="color" />
    </span>

    <b v-if="showCopied" class="copy-text-snippet__copied-msg">&nbsp;(Copied!)</b>
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

// Currently used for emails (since mailto often doesn't work well if you don't have the default mail client wired up)

export default defineComponent({
  name: "CopyTextNew",
  components: { IconEmbedded },

  props: {
    // This prop is needed in case the slot content is not just a plain string but also contain tags
    text: { type: String, default: null },
    // If we should show the text or if the parent will display it where needed (lets us put it in a totally different place)
    showText: { type: Boolean, default: true },
    color: { type: String, default: "rgba(0, 0, 0, 0.5)" },
  },

  data() {
    return {
      showCopied: false,
    };
  },

  computed: {
    textToShow(): string {
      const slotText = this.$slots.default()?.[0]?.children;
      return this.text ?? String(slotText);
    },
  },

  methods: {
    copy() {
      const slotText = this.$slots.default ? this.$slots.default()?.[0]?.children : "";
      const text = this.text ? this.text : String(slotText);

      navigator.clipboard.writeText(text).then(
        () => {
          console.info("Copied to clipboard: " + text);
        },
        function (err) {
          console.error("Failed to copy to clipboard: ", err);
        }
      );

      this.showCopied = true;

      setTimeout(() => {
        this.showCopied = false;
      }, 3000);
    },
  },
});
</script>

<style scoped lang="scss">
// Copy text snippet ========================================================
.copy-text-snippet {
  display: inline-flex;
  align-items: center;

  &__text-itself {
    margin-right: 4px;
    user-select: all;
  }

  &__copied-msg {
    font-weight: 600 !important;
    font-size: inherit !important;
    line-height: inherit !important;
    font-family: "Helvetica Neue", sans-serif !important;
  }

  &__copy-icon-wrap {
    width: 15px;
    height: 15px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 1px;
    user-select: none;
    cursor: pointer;

    &::before {
      content: "";
      width: calc(100% + 14px);
      height: calc(100% + 14px);
      position: absolute;
      inset: 50% auto auto 50%;
      transform: translate(-50%, -50%);
    }

    &::after {
      content: "";
      width: calc(100% + 14px);
      height: calc(100% + 14px);
      border-radius: 50%;
      position: absolute;
      inset: 50% auto auto 50%;
      transform: translate(-50%, -50%) scale(0.5);
      background: rgba(#5b5b5b, 0.15);
      opacity: 0;
      transition:
        transform 0.09s ease-in-out,
        opacity 0.09s ease-in-out;
    }

    &:hover::after {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
  }

  &__copy-icon {
    width: 15px;
    height: 15px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    transition: color 0.09s ease-in-out;
  }
}
</style>
