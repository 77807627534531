<template>
  <div class="user-admin-section">
    <!-- Header -->
    <div class="header user-admin-section__header">
      <div class="global-h1 header__h1">User Admin</div>
      <div class="header__subscript">Manage the admins for your account</div>
    </div>
    <!-- / Header -->

    <SrpModal v-model:isVisible="isRemoveAdminModalVisible" size="small">
      <template #content>
        <div class="global-h1" style="margin-bottom: 8px">
          Remove {{ adminsList.find(admin => admin.adminAuthNameId === adminIdToRemove) && adminsList.find(admin => admin.adminAuthNameId === adminIdToRemove).adminFirstName }}?
        </div>
        <p>They will no longer have access to your dashboard or data</p>
      </template>
      <template #footer>
        <SrpButton :isDisabled="isRemoveAdminLoading" @click="isRemoveAdminModalVisible = false" shape="rectangle" color="gray" style="margin-right: 4px">Cancel</SrpButton>
        <SrpButton
          :isLoading="isRemoveAdminLoading"
          :isDisabled="isRemoveAdminLoading"
          @click="
            async () => {
              await deleteUserAdmin();
              isRemoveAdminModalVisible = false;
            }
          "
          shape="rectangle"
          color="orange"
          is-with-or-separator
        >
          Confirm
        </SrpButton>
      </template>
    </SrpModal>

    <!-- Admins list -->
    <div class="admins-list user-admin-section__admins-list">
      <ul v-if="isLoadingAdminInvites ? true : adminsList.length" class="admins-list__list-itself">
        <AdminSnippet
          class="admins-list__admin-snippet"
          v-for="admin in isLoadingAdminInvites ? adminsListMock : adminsList"
          :key="admin.adminAuthNameId"
          :isSkeleton="isLoadingAdminInvites"
          :data="admin"
          @remove="
            id => {
              isRemoveAdminModalVisible = true;
              adminIdToRemove = id;
            }
          "
        />
      </ul>

      <InviteUserAdminModal
        :isVisible="isInviteModalVisible"
        @close="isInviteModalVisible = false"
        @addInvite="loadUserAdminsAndInvites"
        :adminsList="adminsList"
        :invitesList="pendingInvitesList"
        :inviteFunc="inviteNewUserAdmin"
      />

      <div
        :class="{
          'ui primary button': true,
          'admins-list__invite-button': true,
          'admins-list__invite-button--skeleton': isLoadingAdminInvites,
        }"
        @click="isInviteModalVisible = true"
      >
        <IconEmbedded name="plus_3-5" :size="22" color="rgba(255, 255, 255, 0.5)" />&nbsp;Invite Admin
      </div>
    </div>
    <!-- / Admins list -->

    <!-- Pending invites table -->
    <div v-if="isLoadingAdminInvites ? true : pendingInvitesList.length" class="pending-invites-table user-admin-section__pending-invites-table">
      <div
        :class="{
          'global-h2': true,
          'pending-invites-table__title': true,
          'pending-invites-table__title--skeleton': isLoadingAdminInvites,
        }"
      >
        Pending Invites
      </div>
      <div class="pending-invites-table__table-wrap">
        <table class="pending-invites-table__table-itself">
          <thead v-if="['tablet-large', 'laptop', 'desktop'].includes(screenSize)">
            <tr>
              <td>
                <div
                  :class="{
                    'global-h6': true,
                    'pending-invites-table__col-title': true,
                    'pending-invites-table__col-title--skeleton': isLoadingAdminInvites,
                  }"
                >
                  Email
                </div>
              </td>
              <td>
                <div
                  :class="{
                    'global-h6': true,
                    'pending-invites-table__col-title': true,
                    'pending-invites-table__col-title--skeleton': isLoadingAdminInvites,
                  }"
                >
                  Invited on
                </div>
              </td>

              <td>
                <div
                  :class="{
                    'global-h6': true,
                    'pending-invites-table__col-title': true,
                    'pending-invites-table__col-title--skeleton': isLoadingAdminInvites,
                  }"
                ></div>
              </td>
              <td>
                <div
                  :class="{
                    'global-h6': true,
                    'pending-invites-table__col-title': true,
                    'pending-invites-table__col-title--skeleton': isLoadingAdminInvites,
                  }"
                ></div>
              </td>
            </tr>
          </thead>

          <tbody>
            <InviteTableLine
              class="pending-invites-table__table-line"
              v-for="invite in isLoadingAdminInvites ? pendingInvitesListMock : pendingInvitesList"
              :key="invite.email"
              :data="invite"
              :isSkeleton="isLoadingAdminInvites"
              :inviteFunc="inviteNewUserAdmin"
              @deleted="loadUserAdminsAndInvites"
              :deleteFunc="deleteUserAdminInvite"
            />
          </tbody>
        </table>
      </div>
    </div>
    <!-- / Pending invites table -->
  </div>
</template>

<script lang="ts">
import moment from "moment";
import OrgContext from "@logic/OrgContext";
import { defineComponent, inject, onMounted } from "vue";
import { useHead } from "@unhead/vue";
import { useRoute } from "vue-router";

// Types
import { Page } from "@contracts/pages";
import { ScreenSize } from "@contracts/screenSize";

// Composables
import { useUserAdmins } from "@composables/useUserAdmins";

// Components
import AdminSnippet from "./AdminSnippet.vue";
import CopyText from "@components/CopyText.vue";
import InviteUserAdminModal from "@components/InviteUserAdminModal.vue";
import InviteTableLine from "./InviteTableLine.vue";
import Loader from "@components/Loader/Loader.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import { MetricSender } from "@/helpers/MetricSender";

export default defineComponent({
  name: "UserAdmin",

  components: {
    IconEmbedded,
    SrpModal,
    SrpButton,
    AdminSnippet,
    CopyText,
    InviteUserAdminModal,
    InviteTableLine,
    Loader,
  },

  setup() {
    const route = useRoute();

    const {
      isLoadingAdminInvites,
      adminsList,
      adminsListMock,
      pendingInvitesList,
      pendingInvitesListMock,
      loadUserAdminsAndInvites,
      isRemoveAdminLoading,
      adminIdToRemove,
      deleteUserAdmin,
      inviteNewUserAdmin,
      deleteUserAdminInvite,
    } = useUserAdmins(String(route.params.pageId));

    onMounted(async () => {
      await loadUserAdminsAndInvites();
    });

    return {
      isLoadingAdminInvites,
      adminsList,
      adminsListMock,
      pendingInvitesList,
      pendingInvitesListMock,
      loadUserAdminsAndInvites,
      isRemoveAdminLoading,
      adminIdToRemove,
      deleteUserAdmin,
      inviteNewUserAdmin,
      deleteUserAdminInvite,
    };
  },

  data() {
    return {
      screenSize: inject("screenSize") as ScreenSize,

      title: "Subscription Management",
      orgName: null,

      isRemoveAdminModalVisible: false,

      isInviteModalVisible: false,
    };
  },

  async mounted() {
    useHead({ title: () => this.title ?? "" });

    // @ts-ignore
    const orgInContext = OrgContext.getOrgInContext(this);
    this.orgName = orgInContext?.name;
    this.title += " - " + this.orgName;

    MetricSender.sendMetric("Cust-ViewUserAdmin", null);
  },

  methods: {
    moment,
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";
@import "@/scss/mixins/animations/loading-bg.scss";

// Header =====================================================================
.header {
  padding-bottom: 21px;

  &__h1 {
    margin-bottom: 12px;
  }

  &__subscript {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .header {
    padding-bottom: 15px;

    &__h1 {
      margin-bottom: 3px;
    }
  }
}

// Admins list ================================================================
.admins-list {
  &__list-itself {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  &__admin-snippet {
    width: calc(33.3% - 20px);
    margin: 0 30px 30px 0;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  &__invite-button {
    height: 40px;
    padding-top: 0;
    padding-bottom: 2px;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;

    &--skeleton {
      @include loadingBgAnimation;
      color: transparent !important;
      background: rgba(0, 0, 0, 0.2) !important;

      i::before {
        color: transparent !important;
      }
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .admins-list {
    &__admin-snippet {
      width: calc(50% - 15px);

      &:nth-child(3n) {
        margin-right: 30px;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .admins-list {
    &__admin-snippet {
      width: calc(50% - 10px);
      margin-right: 20px;
      margin-bottom: 20px;

      &:nth-child(3n) {
        margin-right: 20px;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .admins-list {
    &__admin-snippet {
      width: 100%;
      margin-right: 0;
      margin-bottom: 15px;

      &:nth-child(3n) {
        margin-right: 0;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}

// Pending invites table ======================================================
.pending-invites-table {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__title {
    margin-bottom: 22px;

    &--skeleton {
      @include loadingBgAnimation;
      color: transparent !important;
    }
  }

  &__table-wrap {
    width: 100%;
    border: 1px rgba(0, 0, 0, 0.2) solid;
    border-radius: 6px !important;
  }

  &__table-itself {
    width: 100%;
    border: 1px rgba(0, 0, 0, 0.1) solid;
    border-radius: 6px !important;
    border-spacing: 0;
    border-collapse: collapse;
    position: relative;
    overflow: hidden;
  }

  &__table-line {
  }

  thead td {
    padding: 14px 18px 10px;
    box-sizing: border-box;
    border: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__col-title {
    display: inline-flex;

    &--skeleton {
      @include loadingBgAnimation;
      color: transparent !important;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .pending-invites-table {
    td {
      padding: 10px 12px;
    }
  }
}

// User admin section =========================================================
.user-admin-section {
  width: 1050px;
  padding-bottom: 65px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  color: #5b5b5b;
  font:
    14px/22px "Helvetica Neue",
    sans-serif;

  &__header {
    margin-bottom: 35px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__admins-list {
    margin-bottom: 70px;
  }

  &__pending-invites-table {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .user-admin-section {
    width: 100%;
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .user-admin-section {
    width: 100%;
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .user-admin-section {
    width: 100%;
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .user-admin-section {
    width: 100%;
    padding-bottom: 5px;

    &__header {
      margin-bottom: 23px;
    }

    &__admins-list {
      margin-bottom: 60px;
    }
  }
}
</style>
