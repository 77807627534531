<template>
  <div>
    <div v-if="selectedImages.length > 0">
      <div v-if="props.isEdited" style="margin-bottom: 3px; margin-top: -8px">Drag and drop images to adjust order.</div>

      <div
        class="shrpa-content"
        :style="{
          overflow: 'hidden',
          maxHeight: `${shrpaGetPhotoContainerHeight}px  !important`,
          margin: `0 -${paddingPixels}px 0 -${paddingPixels}px  !important`,
          userSelect: 'none',
        }"
        ref="domRefImagesListWrap"
      >
        <div
          v-for="(image, index) in props.selectedImages"
          :key="image"
          :class="{
            'shrpa-img-wrap': true,
            'shrpa-img-wrap--drag-n-drop-ghost': (isDragInProgress || isTouchDragInProgress) && (elementIndexDragged === index || elementIndexTouchDragged === index),
            'shrpa-img-wrap--with-left-highlight-line': (isDragInProgress || isTouchDragInProgress) && (elementIndexDraggedOver === index || elementIndexTouchDraggedOver === index),
          }"
          :style="{
            height: `${imageHeightPixels}px !important`,
            margin: `${paddingPixels}px !important`,
          }"
          :data-index="index"
        >
          <DragAndDropHandle
            :class="{
              'drag-n-drop-handle': true,
              'shrpa-img-wrap__drag-n-drop-handle': true,
              'shrpa-img-wrap__drag-n-drop-handle--transparent': !props.isEdited,
            }"
            :data-index="index"
            size="small"
          />
          <CloseDeleteButton v-if="props.isEdited" @click.stop="props.removeImageFunc(image)" size="tiny" style="position: absolute; inset: 5px 5px auto auto; z-index: 4" color="black" />
          <VideoRender
            v-if="FileUtils.isVideoFileType(image, null)"
            :videoId="image"
            :customClasses="'shrpa-img'"
            :customStyles="` height: ${imageHeightPixels}px !important; `"
            showViewerOnClick
            gallery-postfix="-gallery-preview"
          />
          <a v-else data-fancybox="gallery-gallery-preview" :href="`${contentBaseUri}/cms/images/expeditions/${image}`">
            <img class="shrpa-img" :style="{ height: `${imageHeightPixels}px !important` }" loading="lazy" :src="`${contentBaseUri}/cms/images/expeditions/thumbnocrop/${image}`" />
          </a>

          <!--<div v-if="props.isEdited" class="edit-arrows">-->
          <!--  <div class="prev-next-btn" @click.stop="props.changeImageOrderFunc(index, -1)">-->
          <!--    <i class="angle left icon"></i>-->
          <!--  </div>-->
          <!--  <div class="prev-next-btn" @click.stop="props.changeImageOrderFunc(index, 1)">-->
          <!--    <i class="angle right icon"></i>-->
          <!--  </div>-->
          <!--</div>-->
        </div>
        <div class="shrpa-img-wrap last"></div>
      </div>
      <div v-if="!props.settings.alwaysShowAll && props.settings.allowShowMore" class="show-all-btn-wrapper">
        <a
          class="shrpa-show-all-btn"
          type="button"
          :style="{
            borderColor: props.settings.color?.trim().length > 0 ? `#${props.settings.color} !important` : '',
            color: props.settings.color?.trim().length > 0 ? `#${props.settings.color} !important` : '',
          }"
          @click="shrpaShowMore()"
        >
          Show More
        </a>
      </div>
    </div>

    <div v-else>Select some photos below for your photo wall!</div>
  </div>
</template>

<script setup lang="ts">
import FileUtils from "@logic/FileUtils";
import { moveItemToNewIndex } from "@composables/useMouseDragNDrop";
import { ref, computed, onBeforeMount, onBeforeUnmount, toRef } from "vue";
import VideoRender from "@components/VideoRender.vue";

// Types
import { PhotoWidgetSettings } from "@contracts/photoWidget";

// Composables
import { useMouseDragNDrop } from "@composables/useMouseDragNDrop";
import { useTouchDragNDrop } from "@composables/useTouchDragNDrop";
import DragAndDropHandle from "@components/ui/DragAndDropHandle.vue";
import CloseDeleteButton from "@components/ui/CloseDeleteButton.vue";
import SrpFileThumbnail from "@components/ui/SrpFileThumbnail.vue";

// NOTE! This markup and script is based on the photos.js photowall code, so there's duplication here,
// but since we don't have the modal it's not that much code.
// We are able to re-use the styles.

const props = withDefaults(
  defineProps<{
    selectedImages?: Array<string>;
    settings?: PhotoWidgetSettings | null;
    isEdited?: boolean;
    removeImageFunc: Function;
  }>(),
  {
    selectedImages: () => [],
    settings: null,
    isEdited: false,
    removeImageFunc: () => {},
  }
);

const emit = defineEmits<{
  (e: "reorder", selectedImagesReordered: Array<string>): void;
}>();

// Handle Drag&Drop ===========================================================
const domRefImagesListWrap = ref<HTMLElement | null>(null);

const { isDragInProgress, elementIndexDragged, elementIndexDraggedOver } = useMouseDragNDrop(
  computed(() => props.isEdited),
  computed(() => String(props.selectedImages) + String(props.isEdited)),
  domRefImagesListWrap,
  ".shrpa-img-wrap",
  ".shrpa-img-wrap .drag-n-drop-handle",
  (oldIndex: number, newIndex: number) => {
    const selectedImagesReordered = moveItemToNewIndex(props.selectedImages, oldIndex, newIndex, false);
    emit("reorder", selectedImagesReordered);
  }
);

const { isTouchDragInProgress, elementIndexTouchDragged, elementIndexTouchDraggedOver } = useTouchDragNDrop(
  computed(() => props.isEdited),
  computed(() => String(props.selectedImages) + String(props.isEdited)),
  domRefImagesListWrap,
  ".shrpa-img-wrap",
  ".shrpa-img-wrap .drag-n-drop-handle",
  (oldIndex: number, newIndex: number) => {
    const selectedImagesReordered = moveItemToNewIndex(props.selectedImages, oldIndex, newIndex, false);
    emit("reorder", selectedImagesReordered);
  }
);

// Global variables & injections
const contentBaseUri = globalThis.Bootstrap.Config.contentCdnBaseUri;

// Multipliers ================================================================
// NOTE: These multipliers are in photos.js also
const imageHeightMultiplier = ref<number>(25);
const paddingMultiplier = ref<number>(0.1);

// Calc image heights and paddings ============================================
const imageHeightPixels = computed<number>(() => {
  return props.settings.imageHeight * imageHeightMultiplier.value;
});
const paddingPixels = computed<number>(() => {
  return props.settings.padding * paddingMultiplier.value;
});

// Changes number of rows visible =============================================
const additionalRowsDisplayed = ref<number>(0);
function shrpaShowMore() {
  additionalRowsDisplayed.value += 1;
}

const shrpaGetPhotoContainerHeight = computed<number>(() => {
  // NOTE: Similar to shrpaGetPhotoContainerHeight in Photos.js with minor tweaks (ex. multipliers)
  // Also show them all if in edit mode (so they can be moved around easily)
  if (props.settings.alwaysShowAll || props.isEdited) return 9999999;

  let rowCount = props.settings.rowsToShow + additionalRowsDisplayed.value;
  // Image (image height + padding * 2) * rows to show
  let newHeight = (props.settings.imageHeight * imageHeightMultiplier.value + props.settings.padding * paddingMultiplier.value * 2) * rowCount;
  return newHeight;
});
</script>

<style scoped lang="scss">
@import "@/scss/photowall.css";
@import "@/scss/variables.scss";

.remove-icon {
  position: absolute;
  top: 4px;
  right: 4px;
  color: white;
  background-color: rgb(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 9;
  &:hover {
    background-color: rgb(0, 0, 0, 0.4);
  }
  i.close.icon {
    margin-left: 4px;
    margin-top: 2px;
    margin-right: 4px;
  }
}
.edit-arrows {
  position: absolute;
  bottom: 4px;
  left: 4px;
  right: 4px;
  display: flex;
  justify-content: space-between;
  z-index: 9;
}
.prev-next-btn {
  background-color: rgb(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 9;
  i {
    margin: 4px;
    color: white;
  }
  &:hover {
    background-color: rgb(0, 0, 0, 0.4);
  }
}
.shrpa-img-wrap {
  position: relative;
  z-index: 0;
  overflow: hidden;

  &--drag-n-drop-ghost {
    background: rgba(150, 150, 150, 1);
    * {
      opacity: 0 !important;
    }
  }

  &--with-left-highlight-line {
    &::before {
      content: "";
      width: 6px;
      height: 100%;
      position: absolute;
      inset: 0 auto auto 0;
      z-index: 4;
      background: $brand-color;
    }
  }
  &--drag-n-drop-ghost.shrpa-img-wrap--with-left-highlight-line::before {
    display: none;
  }

  &__drag-n-drop-handle {
    position: absolute;
    inset: 5px auto auto 5px;
    z-index: 4;

    &--transparent {
      opacity: 0;
      pointer-events: none;
    }

    // makes the entire image area draggable
    &::before {
      content: "";
      width: calc(100% + 500px);
      height: calc(100% + 220px);
      position: absolute;
      inset: -20px auto auto -20px;
    }
  }
}

.shrpa-show-all-btn {
  display: inline;
}
</style>
