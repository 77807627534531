<template>
  <SrpModal :isVisible="props.isVisible" @close="emit('update:isVisible', false)" maxHeight="calc(100dvh - 100px)" size="large">
    <template #content>
      <div style="padding-bottom: 25px; margin-bottom: 30px; border-bottom: 1px rgba(0, 0, 0, 0.1) solid">
        <h1 class="global-h1" style="margin-bottom: 5px; text-wrap: balance">Start with a Community Upload Link</h1>
        <div style="color: rgba(91, 91, 91, 1); font: 14px/18px sans-serif; text-wrap: balance">Choose a theme and encourage locals to share photos and videos!</div>
      </div>

      <div class="template-tiles" style="margin-top: 10px">
        <UploadCampaignTemplateSnippet
          class="template-tiles__tile"
          v-for="templateTheme in Object.keys(uploadCampaignTemplates)"
          :key="templateTheme"
          :template="uploadCampaignTemplates[templateTheme]"
          :templateTheme="templateTheme as UploadCampaignTemplateTheme"
          @select="templateTheme => emit('selectTemplate', templateTheme)"
        />
      </div>
    </template>
  </SrpModal>
</template>

<script setup lang="ts">
import uploadCampaignTemplatesRaw from "./uploadCampaignTemplates.json";

// Components
import SrpModal from "@components/ui/SrpModal.vue";
import UploadCampaignTemplateSnippet from "./UploadCampaignTemplateSnippet.vue";

// Types
import { UploadCampaignTemplateTheme, UploadCampaignTemplate } from "@contracts/uploadCampaignTemplates";

const props = withDefaults(
  defineProps<{
    isVisible: boolean;
  }>(),
  {
    isVisible: null,
  }
);

const emit = defineEmits<{
  (e: "update:isVisible", isVisible: boolean): void;
  (e: "selectTemplate", templateTheme: UploadCampaignTemplateTheme): void;
}>();

// Set type for the uploadCampaignTemplates ===================================
const uploadCampaignTemplates: Record<UploadCampaignTemplateTheme, UploadCampaignTemplate> = uploadCampaignTemplatesRaw;
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Template tiles =============================================================
.template-tiles {
  gap: 27px;
  display: flex;
  flex-wrap: wrap;

  &__tile {
    width: calc(50% - 14px);
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .template-tiles {
    gap: $desktop-grid-gap-width;
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .template-tiles {
    gap: $laptop-grid-gap-width;
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .template-tiles {
    gap: $tablet-large-grid-gap-width;
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .template-tiles {
    gap: $tablet-grid-gap-width;
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .template-tiles {
    gap: $mobile-grid-gap-width;
  }
}
</style>
