<template>
  <div class="ui container">
    <h2>Uh Oh! We couldn't find that page...</h2>
    <h3>If you think something is wrong please reach out to <CopyText /></h3>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CopyText from "@components/CopyText.vue";
import { useHead } from "@unhead/vue";

export default defineComponent({
  name: "NotFound",

  components: {
    CopyText,
  },

  data() {
    return {
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
    };
  },

  mounted() {
    useHead({ title: "Shrpa - Oops, Not Found" });
  },
});
</script>

<style scoped></style>
