<template>
  <div class="inst-post-snippet">
    <!-- User info skeleton -->
    <div class="user-info-skeleton inst-post-snippet__user-info-skeleton">
      <div class="user-info-skeleton__avatar"></div>
      <div class="user-info-skeleton__text-wrap">
        <div class="user-info-skeleton__thick-line"></div>
        <div class="user-info-skeleton__thin-line"></div>
      </div>
    </div>
    <!-- / User info skeleton -->

    <div class="inst-post-snippet__photo-wrap">
      <MediaThumbnail class="inst-post-snippet__photo" :media-url="props.imagesList[0]" />
    </div>

    <!-- Icon buttons panel -->
    <div class="icon-buttons-panel inst-post-snippet__icon-buttons-panel">
      <div class="icon-buttons-panel__left-side">
        <div class="icon-buttons-panel__round-icon"></div>
        <div class="icon-buttons-panel__round-icon"></div>
        <div class="icon-buttons-panel__round-icon"></div>
      </div>
      <div class="icon-buttons-panel__right-side">
        <div class="icon-buttons-panel__square-icon"></div>
      </div>
    </div>
    <!-- / Icon buttons panel -->

    <div class="inst-post-snippet__likes-text"></div>

    <div class="inst-post-snippet__caption-textarea-wrap">
      <slot name="textarea" />
    </div>

    <!-- Footer skeleton -->
    <div class="footer-skeleton inst-post-snippet__footer-skeleton">
      <div class="footer-skeleton__view-comments-link"></div>
      <div class="footer-skeleton__comment-form-wrap">
        <div class="footer-skeleton__form-itself">
          <div class="footer-skeleton__avatar"></div>
          <div class="footer-skeleton__textarea"></div>
        </div>
        <div class="footer-skeleton__additional-buttons">
          <div class="footer-skeleton__additional-btn"></div>
          <div class="footer-skeleton__additional-btn"></div>
          <div class="footer-skeleton__additional-btn"></div>
        </div>
      </div>
    </div>
    <!-- / Footer skeleton -->
  </div>
</template>

<script setup lang="ts">
import MediaThumbnail from "@components/MediaThumbnail.vue";

export interface Props {
  imagesList: Array<string>;
}
const props = withDefaults(defineProps<Props>(), {
  imagesList: () => [],
});
</script>

<style scoped lang="scss">
@mixin placeholderElement {
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.1);
}

// User info skeleton =========================================================
.user-info-skeleton {
  display: flex;
  align-items: center;

  &__avatar {
    @include placeholderElement;
    width: 42px;
    height: 42px;
    margin-right: 9px;
    border-radius: 500px;
  }

  &__text-wrap {
  }

  &__thick-line {
    @include placeholderElement;
    width: 172px;
    height: 14px;
    margin-bottom: 7px;
  }

  &__thin-line {
    @include placeholderElement;
    width: 55px;
    height: 8px;
  }
}

// Icon buttons panel =========================================================
.icon-buttons-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__left-side {
    display: flex;
    align-items: center;
  }

  &__right-side {
  }

  &__round-icon {
    @include placeholderElement;
    width: 37px;
    height: 37px;
    margin-right: 11px;
    border-radius: 500px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__square-icon {
    @include placeholderElement;
    width: 37px;
    height: 37px;
    border-radius: 6px;
  }
}

// Footer skeleton ============================================================
.footer-skeleton {
  padding-right: 34px;
  box-sizing: border-box;

  &__view-comments-link {
    @include placeholderElement;
    width: 150px;
    height: 13px;
    margin-bottom: 8px;
  }

  &__comment-form-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__form-itself {
    display: flex;
    align-items: center;
  }

  &__avatar {
    @include placeholderElement;
    width: 28px;
    height: 28px;
    margin-right: 8px;
    border-radius: 500px;
  }

  &__textarea {
    @include placeholderElement;
    width: 100px;
    height: 15px;
  }

  &__additional-buttons {
    display: flex;
    align-items: center;
  }

  &__additional-btn {
    @include placeholderElement;
    width: 18px;
    height: 18px;
    margin-right: 9px;
    border-radius: 500px;

    &:last-child {
      margin-right: 0;
    }
  }
}

// Instagram post snippet =====================================================
.inst-post-snippet {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__user-info-skeleton {
    width: calc(100% - 32px);
    margin-bottom: 13px;
  }

  &__photo-wrap {
    width: 100%;
    aspect-ratio: 1/1;
    margin-bottom: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  &__photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__icon-buttons-panel {
    width: calc(100% - 26px);
    margin-bottom: 17px;
  }

  &__likes-text {
    @include placeholderElement;
    width: 74px;
    height: 17px;
    margin: 0 0 13px 16px;
    align-self: flex-start;
  }

  &__caption-textarea-wrap {
    width: calc(100% - 32px);
    margin-bottom: 14px;

    :deep(textarea) {
      width: 100%;
      height: 95px;
      min-height: 95px;
      box-sizing: border-box;
      border: 1px #ababab solid;
      border-radius: 6px;
      color: #5b5b5b;
      font-family: sans-serif;
      transition: none !important;
    }
  }

  &__footer-skeleton {
    width: calc(100% - 32px);
  }
}
</style>
