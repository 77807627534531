<template>
  <div class="snippets-list-section">
    <!-- Header -->
    <div v-if="props.title || props.subTitle" class="header snippets-list-section__header">
      <div class="header__left-side">
        <h2 v-if="props.title" class="global-h2 header__title">{{ props.title }}</h2>
        <div v-if="props.subTitle" class="header__subtitle">
          <p class="global-p">{{ props.subTitle }}</p>
        </div>
      </div>

      <div class="header__right-side">
        <slot name="headerRightSide" />
      </div>
    </div>
    <!-- / Header -->

    <ul class="snippets-list-section__list-itself">
      <li
        class="snippets-list-section__snippet-wrap"
        v-for="snippetData in props.howManyToShowInitially ? props.snippetsList.slice(0, howManyShowed) : props.snippetsList"
        :key="snippetData.uniqueName"
      >
        <slot :snippetData="snippetData" />
      </li>

      <ShowMoreBtnSnippet v-if="props.howManyToShowInitially ? Boolean(props.snippetsList.length > howManyShowed) : false" class="snippets-list-section__snippet" @click="showMore" />
    </ul>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";

// Components
import ShowMoreBtnSnippet from "./snippets/ShowMoreBtnSnippet.vue";

// Types
import { DestinationCollabSummaryForCreator } from "@contracts/collabCredits";

const props = withDefaults(
  defineProps<{
    title?: string;
    subTitle?: string;
    snippetsList: Array<DestinationCollabSummaryForCreator>;
    howManyToShowInitially?: number; // number or null (all)
  }>(),
  {
    title: null,
    subTitle: null,
    snippetsList: () => [],
    howManyToShowInitially: 3,
  }
);

// Show more snippets =========================================================
const howManyShowed = ref<number>(0);

onMounted(() => {
  howManyShowed.value = props.howManyToShowInitially;
});

function showMore() {
  howManyShowed.value += 3;
}
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Header =====================================================================
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  // left side ------------------------
  &__left-side {
  }

  &__title {
    margin-bottom: 8px;
  }

  &__subtitle {
  }

  // right side -----------------------
  &__right-side {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .header {
    flex-direction: column;
    align-items: flex-start;

    &__left-side {
      margin-bottom: 12px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .header {
    flex-direction: column;
    align-items: flex-start;

    &__left-side {
      margin-bottom: 12px;
    }
  }
}

// Snippets list section ======================================================
.snippets-list-section {
  &__header {
    margin-bottom: 20px;
  }

  &__list-itself {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__snippet-wrap {
    margin-bottom: 27px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
