import { createApp } from "vue";
import axios from "axios";
import createAuth0Client from "@auth0/auth0-spa-js";
import { MetricSender } from "@helpers/MetricSender";
import Logger from "@helpers/Logger";
import { getGlobalRemoteLogger } from "@helpers/RemoteLogger";
import { useUserProfileStore } from "@stores/userProfileStore";

let instance;

export const getInstance = () => instance;

// https://auth0.github.io/auth0-spa-js/classes/auth0client.html

// Note: Redirecting to a specific finish-login page instead of the homepage,
// which was fairly jarring since users get redirected to the dashboard after auth is finished initializing.
export const useAuth0 = ({ redirectUri = window.location.origin + "/finish-login", ...options }) => {
  if (instance) return instance;

  const app = createApp({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null,
      };
    },

    async created() {
      this.auth0Client = await createAuth0Client({
        domain: options.domain,
        client_id: options.clientId,
        audience: options.audience,
        redirect_uri: redirectUri,
        cacheLocation: "localstorage", // valid values are: 'memory' or 'localstorage'
      });

      try {
        if (window.location.search.includes("code=") && window.location.search.includes("state=")) {
          const { appState } = await this.auth0Client.handleRedirectCallback();
          this.error = null;
          // Note: Remved the old onRedirectCallback that wasn't really doing anything
        }
      } catch (e) {
        this.error = e;
      } finally {
        this.isAuthenticated = await this.auth0Client.isAuthenticated();
        this.user = await this.auth0Client.getUser();

        try {
          if (this.isAuthenticated) {
            const userAuthId = this.user?.sub;
            try {
              getGlobalRemoteLogger().setAuthenticatedUserContext(userAuthId);
            } catch (ex) {
              Logger.error(`RemoteLogger.setAuthenticatedUserContext FAILED! ${ex.message}`);
            }
            // Update: Moved the metrics identify call after the profile is loaded

            // Auto-provision the user's profile (we fire this everytime and if the user already exists then it's a no-op)
            // Note: Originally this was a fire-and-forget but now that we have membership we want that user provisioned before the user starts navigating around.
            await this.autoProvisionProfile(this.user.email, this.user.name, this.user.email_verified);
            // Ensure the profile has been loaded
            // Note: This used to happen after the authGuard let the page load, but that lead
            // to pages having to check if the profile was loaded, which was annoying.
            const profileStore = useUserProfileStore();
            if (!profileStore.loggedInUser) {
              // NOTE: The call to autoProvisionProfile above should just return the profile, and then we wouldn't need this call,
              // but we would need to set that returned profile in the store.
              await profileStore.setProfileForCurrentUser();
            }

            // For testing missing emails: await this.autoProvisionProfile(this.user.email234, this.user.name, this.user.email_verified);
            // Note: If they don't have an email, the above re-routes them to the profile page to add one
          }
        } catch (innerEx) {
          getGlobalRemoteLogger().error(`--ALERT--profile/login call failed: ${innerEx?.message}, stack=${innerEx?.stack}`);
          console.error("profile/login call failed: " + innerEx);
        } finally {
          // NOTE! This should fire the watch in App.vue
          this.loading = false;
        }
      }
    },

    methods: {
      async getUser(): Promise<any> {
        // @ts-ignore
        return this.auth0Client.getUser();
      },
      async loginWithPopup(o) {
        // @ts-ignore
        this.popupOpen = true;

        try {
          // @ts-ignore
          await this.auth0Client.loginWithPopup(o);
          // @ts-ignore
          this.user = await this.auth0Client.getUser();
          // @ts-ignore
          this.isAuthenticated = await this.auth0Client.isAuthenticated();
          // @ts-ignore
          this.error = null;
        } catch (e) {
          console.error(e);
          // @ts-ignore
          this.error = e;
        } finally {
          // @ts-ignore
          this.popupOpen = false;
        }
      },
      async handleRedirectCallback(): Promise<void> {
        // @ts-ignore
        this.loading = true;
        try {
          // @ts-ignore
          await this.auth0Client.handleRedirectCallback();
          // @ts-ignore
          this.user = await this.auth0Client.getUser();
          // @ts-ignore
          this.isAuthenticated = true;
          // @ts-ignore
          this.error = null;
        } catch (e) {
          // @ts-ignore
          this.error = e;
        } finally {
          // @ts-ignore
          this.loading = false;
        }
      },
      loginWithRedirect(o, authProviderHint, emailHint, defaultToSignUp) {
        // https://auth0.com/docs/libraries/lock/lock-configuration
        // - note: there seem to be some inconsistencies in param names between the old and new lock screens

        // (optional) authProviderHint is the name of the connection in Auth0
        // Ex. facebook, google-oauth2, windowslive, apple
        if (authProviderHint) {
          o.connection = authProviderHint;
        }
        o.allowShowPassword = true;
        if (emailHint) {
          o.login_hint = emailHint;
        }
        if (defaultToSignUp) {
          o.screen_hint = "signup";
        }
        // Text customization for Universal Login is done via management api calls: https://auth0.com/docs/universal-login/new-experience/text-customization-new-universal-login
        // Note: Could probably use the custom field logic to collect FirstName if we want

        // @ts-ignore
        return this.auth0Client.loginWithRedirect(o);
      },
      getIdTokenClaims(o) {
        // @ts-ignore
        return this.auth0Client.getIdTokenClaims(o);
      },
      getTokenSilently(o) {
        // @ts-ignore
        return this.auth0Client.getTokenSilently(o);
      },
      getTokenWithPopup(o) {
        // @ts-ignore
        return this.auth0Client.getTokenWithPopup(o);
      },
      logout(o) {
        o = {};
        // Return to the root on logout
        o.returnTo = location.origin;
        // o.federated = true;
        try {
          getGlobalRemoteLogger().clearAuthenticatedUserContext();
        } catch (ex) {
          Logger.error(`RemoteLogger.clearAuthenticatedUserContext FAILED! ${ex.message}`);
        }
        // @ts-ignore
        return this.auth0Client.logout(o);
      },
      async autoProvisionProfile(email, name, ev) {
        // Note: We should really encapsulate and move some of this login into another file
        let queryParams = `?name=${encodeURIComponent(name)}`;
        // Sometime we don't get their email from the auth provider
        if (email) {
          queryParams += `&email=${encodeURIComponent(email)}`;
        }
        if (ev) {
          queryParams += `&ev=${encodeURIComponent(ev)}`;
        }
        const membershipId = localStorage.getItem("joincommunity");
        const referredByUserId = localStorage.getItem("referredByUserId");
        const utm_id = localStorage.getItem("utm_id");
        if (membershipId) {
          queryParams += `&membershipId=${encodeURIComponent(membershipId)}`;
        }
        if (referredByUserId) {
          queryParams += `&referredByUserId=${encodeURIComponent(referredByUserId)}`;
        }
        if (utm_id) {
          queryParams += `&utmId=${encodeURIComponent(utm_id)}`;
        }
        // User also has family_name, given_name, nickname, and email_verified=bool
        getGlobalRemoteLogger().info(`AutoProvisionProfile email=${email}, membershipId=${membershipId}, referredByUserId=${referredByUserId}, utmId=${utm_id}`);
        const { data } = await axios.put(`${import.meta.env.VITE_API_URL}/profile/login${queryParams}`);
        // If true is returned the user was just provisioned so go get the profile
        if (data.userWasJustCreated) {
          getGlobalRemoteLogger().info(`New User Provisioned! email=${email}`);
          MetricSender.userProvisioned(email);
        } else {
          getGlobalRemoteLogger().info(`User already exists, no provision needed. email=${email}`);
        }
        // Remove membership from localstore now that it's provisioned
        if (membershipId) localStorage.removeItem("joincommunity");
        if (referredByUserId) localStorage.removeItem("referredByUserId");
        if (utm_id) localStorage.removeItem("utm_id");

        // Note: Missing email logic is moved to the creator dashboard
      },
    },

    template: `<div></div>`,
  });

  instance = app.mount(document.createElement("div"));

  return instance;
};

export const Auth0Plugin = {
  install(app, options) {
    app.config.globalProperties.globalAuth = useAuth0(options);
  },
};
