<template>
  <div
    :class="{
      'srp-switch': true,
      'srp-switch--disabled': isDisabled,
    }"
  >
    <div class="srp-switch__in-wrap">
      <input class="srp-switch__input" type="checkbox" v-model="_isChecked" @change="$event => emit('change', $event)" :disabled="isDisabled" />
      <div class="srp-switch__handle">
        <div class="srp-switch__handle-in-wrap"></div>
      </div>
      <div class="srp-switch__bg-layer"></div>
    </div>
  </div>
</template>

<!-- prettier-ignore -->
<script lang="ts">export default { name: "SrpSwitch" };</script>

<script setup lang="ts">
import { ref, watch, onMounted } from "vue";
export interface Props {
  isDisabled?: boolean;
  isChecked: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  isDisabled: false,
  isChecked: false,
});

const emit = defineEmits<{
  (e: "change", event: Event): void;
  (e: "update:isChecked", value: boolean): void;
}>();

// Initialize state ===========================================================
const _isChecked = ref(false);

onMounted(() => {
  _isChecked.value = props.isChecked;
});

// Handle checkbox state ======================================================
watch(
  () => props.isChecked,
  () => {
    _isChecked.value = props.isChecked;
  }
);
watch(
  () => _isChecked.value,
  () => {
    emit("update:isChecked", _isChecked.value);
  }
);
</script>

<style scoped lang="scss">
// Srp switch =================================================================
.srp-switch {
  width: 42px;
  height: 26px;
  display: flex;
  align-items: center;

  &__in-wrap {
    width: 42px;
    height: 20px;
    position: relative;
    opacity: 1;
    transition: opacity 0.12s ease-in-out;
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &__input {
    width: calc(100% + 16px);
    height: calc(100% + 16px);
    position: absolute;
    inset: 50% auto auto 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    opacity: 0;
    cursor: pointer;
  }

  &__handle {
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: -1px auto auto -1px;
    z-index: 1;
    transition: left 0.1s cubic-bezier(1, 0, 1, 1);
    pointer-events: none;
  }

  &__handle-in-wrap {
    min-width: 26px;
    min-height: 26px;
    border: 1px #cacaca solid;
    border-radius: 100px;
    background: linear-gradient(180deg, #fff 0%, #f0f0f0 100%);
    box-shadow: 0 7px 7px -4px rgba(0, 0, 0, 0.3);
    transition:
      min-width 0.07s ease-in-out,
      min-height 0.07s ease-in-out;
  }

  &__input:checked + &__handle {
    left: 21px;
  }

  &:hover &__handle &__handle-in-wrap,
  &__input:focus + &__handle &__handle-in-wrap {
    min-width: 26px;
    min-height: 26px;
  }

  &__bg-layer {
    width: 100%;
    height: 100%;
    border-radius: 100px;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: 0;
    background: #ddd;
    transition: background 0.1s cubic-bezier(1, 0, 1, 1);
  }

  &:hover &__bg-layer {
    background: #d0d0d0;
  }

  &__input:checked + &__handle + &__bg-layer {
    background: #118689;
  }

  &:hover &__input:checked + &__handle + &__bg-layer {
    background: #0d6d70;
  }
}
</style>
