export interface CollabLocationsData {
  id: string; // "b1ef61c0-2865-491e-bb7c-e77d18be2abd"
  pkey: string; // "funtown"
  type: "CollabLocations";

  createdBy: string; // "google-oauth2|117256173970531098594"
  createdDate: string; // "2024-05-16T10:25:17.517449+00:00"
  updatedBy: string; // "google-oauth2|117256173970531098594"
  updatedDate: string; // "2024-05-16T10:25:17.5174499+00:00"

  collabInputId: string; // "e17715bf-4e74-44d8-bb22-a43e61e77d8c"
  creatorId: string; // "f4f377bc-0106-488f-8db1-8d57d5a7b22f"
  customerId: string; // "funtown"

  locations: Array<CollabLocation>;
  visitPlanNotes: string | null;
}

export interface CollabLocation {
  id: string;

  // NOTE! The following fields match what's on ItineraryStep, since these copy into those fields when creating adventures
  externalId: string | null;
  latitude: number | null;
  longitude: number | null;

  title: string | null;
  address: string | null;
  city: string | null;
  state: string | null;
  country: string | null;
  website: string | null;

  visitPlanNotes: string | null;

  // Photo or Videos uploaded to this location
  mediaIds: Array<string>;
}

export class CollabLocationEmpty implements CollabLocation {
  constructor(id: string) {
    this.id = id || "";
  }

  id = null;

  externalId = null;
  latitude: number | null = null;
  longitude: number | null = null;

  title = null;
  address = null;
  city = null;
  state = null;
  country = null;
  website = null;

  visitPlanNotes = null;

  mediaIds: Array<string> = [];
}
