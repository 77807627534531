<template>
  <div class="incomp-browser-info">
    <!-- Restricted browser icon -->
    <div class="restricted-browser-icon incomp-browser-info__restricted-browser-icon">
      <img class="restricted-browser-icon__block-ted-diagonal-line-icon" src="/images/block-red-diagonal-line-sign.svg" alt="Block red diagonal line" />
      <!--<img class="restricted-browser-icon__browser-icon" src="/images/browserIcons/brave.jpg" alt="Incompatible browser" />-->
    </div>
    <!-- / Restricted browser icon -->

    <div class="incomp-browser-info__text-side">
      <div class="global-h1 incomp-browser-info__title">
        Sorry, this browser<br v-if="screenSize !== 'mobile'" />
        is not supported
      </div>

      <div class="incomp-browser-info__other-browsers-text">
        Please use a different browser:<br />
        <!-- Browser link -->
        <a class="browser-link incomp-browser-info__browser-link" @click="handleClickOnChromeLink">
          <img class="browser-link__inline-browser-icon" src="/images/browserIcons/chrome.jpg" alt="Chrome" />
          <span class="browser-link__text">Chrome</span>
          <IconEmbedded class="browser-link__external-link-icon" name="external-link_2-5" :size="14" color="rgba(91, 91, 91, 0.5)" /> </a
        ><!-- / Browser link -->,
        <template v-if="isIOS">
          <!-- Browser link -->
          <a class="browser-link incomp-browser-info__browser-link" href="https://www.apple.com/safari/" target="_blank">
            <img class="browser-link__inline-browser-icon" src="/images/browserIcons/safari.jpg" alt="Safari" />
            <span class="browser-link__text">Safari</span>
            <IconEmbedded class="browser-link__external-link-icon" name="external-link_2-5" :size="14" color="rgba(91, 91, 91, 0.5)" />
          </a>
          <!-- / Browser link -->,
        </template>
        <!-- Browser link -->
        <a class="browser-link incomp-browser-info__browser-link" href="https://www.microsoft.com/edge/download" target="_blank">
          <img class="browser-link__inline-browser-icon" src="/images/browserIcons/edge.jpg" alt="Edge" />
          <span class="browser-link__text">Edge</span>
          <IconEmbedded class="browser-link__external-link-icon" name="external-link_2-5" :size="14" color="rgba(91, 91, 91, 0.5)" /> </a
        ><!-- / Browser link -->
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { inject, Ref } from "vue";

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";

// Types
import { ScreenSize } from "@contracts/screenSize";

// Global variables
const screenSize = inject("screenSize", null) as Ref<ScreenSize>;

// Get OS type ================================================================
const isAndroid = /Android/i.test(navigator.userAgent);
const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

// Handle click on Chrome link ================================================
function handleClickOnChromeLink(event) {
  event.preventDefault();

  if (isAndroid) {
    window.location.href = "intent://shrpa.com#Intent;scheme=https;package=com.android.chrome;end;";
  } else if (isIOS) {
    window.location.href = "googlechrome://shrpa.com";
    setTimeout(function () {
      // If Chrome is not installed, redirect to App Store
      window.location.href = "https://apps.apple.com/app/google-chrome/id535886823";
    }, 1000);
  } else {
    window.location.href = "https://www.google.com/chrome/";
  }
}
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Restricted browser icon ====================================================
.restricted-browser-icon {
  aspect-ratio: 1/1;
  position: relative;
  z-index: 0;

  &__block-ted-diagonal-line-icon {
    width: 100%;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: 2;
  }

  &__browser-icon {
    width: 65%;
    position: absolute;
    inset: 50% auto auto 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
  }
}

// Browser link ===============================================================
.browser-link {
  white-space: nowrap;

  &__inline-browser-icon {
    width: 17px;
    margin-right: 3px;
    position: relative;
    top: 3px;
  }

  &__text {
    margin-right: 3px;
    text-decoration: underline;
  }

  &__external-link-icon {
    position: relative;
    top: 2px;
  }

  &:hover &__text {
    text-decoration: none !important;
  }
}

// Incompatible browser info ==================================================
.incomp-browser-info {
  display: flex;
  align-items: flex-start;

  &__restricted-browser-icon {
    width: 140px;
    min-width: 140px;
    margin-right: 30px;
  }

  &__text-side {
    padding-top: 7px;
  }

  &__title {
    margin-bottom: 10px;
    font-weight: 400;
  }

  &__other-browsers-text {
    color: rgba(91, 91, 91, 1);
    font-family: sans-serif;
  }

  &__browser-link {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .incomp-browser-info {
    flex-direction: column;

    &__restricted-browser-icon {
      width: 110px;
      min-width: 110px;
      margin-bottom: 10px;
    }

    &__text-side {
      padding-top: 0;
    }
  }
}
</style>
