<template>
  <li
    :class="{
      'collab-snippet': true,
      'collab-snippet--small': props.isSmall,
    }"
  >
    <div class="collab-snippet__in-wrap">
      <label class="collab-snippet__label-wrap">
        <AvatarWithFallback
          :class="{
            'collab-snippet__avatar': true,
            'collab-snippet__avatar--small': props.isSmall,
          }"
          :src="props.collab.creatorProfileImage"
        />

        <!-- Creator info -->
        <div v-if="!props.isSmall" class="creator-info collab-snippet__creator-info">
          <div class="creator-info__icon-n-title">
            <IconEmbedded class="creator-info__icon" name="user_2" />
            <div class="creator-info__title">Creator</div>
          </div>
          <div class="creator-info__name">{{ props.collab.creatorName || "&nbsp;" }}</div>
        </div>
        <!-- / Creator info -->

        <div v-if="!props.isSmall" class="collab-snippet__title">
          {{ props.collab.collabName }}
        </div>

        <!-- Visit dates -->
        <div v-if="!props.isSmall" class="visit-dates collab-snippet__visit-dates">
          <div class="visit-dates__icon-n-title">
            <IconEmbedded class="visit-dates__icon" name="calendar_2" />
            <div class="visit-dates__title">Completed on</div>
          </div>
          <div class="visit-dates__date">
            {{ DateUtils.formatDateRange(props.collab.completedDate, 0, true) }}
          </div>
        </div>
        <!-- / Visit dates -->

        <div v-if="!props.isSmall" class="collab-snippet__radio-input-bg"></div>
        <slot v-if="!props.isSmall" />
        <div
          v-if="!props.isSmall"
          :class="{
            'collab-snippet__bg-layer': true,
            'collab-snippet__bg-layer--small': props.isSmall,
          }"
        ></div>

        <!-- Bg images stack -->
        <div
          :class="{
            'bg-images-stack': true,
            'collab-snippet__bg-images-stack': true,
            'collab-snippet__bg-images-stack--small': props.isSmall,
          }"
        >
          <div class="bg-images-stack__img-wrap bg-images-stack__img-wrap--top">
            <div
              v-if="averageColor"
              class="bg-images-stack__gradient"
              :style="{
                background: `linear-gradient(0deg, rgba(${averageColor.r}, ${averageColor.g}, ${averageColor.b}, 1) 20%, rgba(${averageColor.r}, ${averageColor.g}, ${averageColor.b}, 0) 100%)`,
              }"
            ></div>
            <img class="bg-images-stack__img" :src="`${contentBaseUri}/cms/images/expeditions/thumbnails/${props.collab.coverImage}`" alt="Photo" />
            <img class="bg-images-stack__img bg-images-stack__img--hidden" :src="`${contentBaseUri}/cms/images/expeditions/thumbnails/${props.collab.coverImage}`" alt="Photo" ref="refImageMain" />
          </div>
          <!--<div class="bg-images-stack__img-wrap bg-images-stack__img-wrap&#45;&#45;middle">-->
          <!--  <img class="bg-images-stack__img" :src="`https://datagile.com/photo${Math.floor(Math.random() * 10 + 1)}.jpg`" alt="Photo" />-->
          <!--</div>-->
          <!--<div class="bg-images-stack__img-wrap bg-images-stack__img-wrap&#45;&#45;bottom">-->
          <!--  <img class="bg-images-stack__img" :src="`https://datagile.com/photo${Math.floor(Math.random() * 10 + 1)}.jpg`" alt="Photo" />-->
          <!--</div>-->
        </div>
        <!-- / Bg images stack -->
      </label>
    </div>
  </li>
</template>

<script setup lang="ts">
import { onMounted, ref, onBeforeUnmount } from "vue";
import DateUtils from "@logic/DateUtils";
import getAverageColor from "@helpers/GetAverageColor";

// Types
import { CreatorCollabSummary } from "@contracts/collab";

// Components
import AvatarWithFallback from "@components/AvatarWithFallback.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export interface Props {
  collab: CreatorCollabSummary;
  isSmall?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  collab: null,
  isSmall: false,
});

const contentBaseUri = globalThis.Bootstrap.Config.contentCdnBaseUri;

// Get average color ==========================================================
const refImageMain = ref(null);
const averageColor = ref<{ r: number; g: number; b: number } | null>(null);

onMounted(() => {
  refImageMain.value.addEventListener("load", setAverageColor);
});
onBeforeUnmount(() => {
  refImageMain.value?.removeEventListener("load", setAverageColor);
});

async function setAverageColor(): Promise<void> {
  refImageMain.value.crossOrigin = "Anonymous";

  let _averageColor: { r: number; g: number; b: number } | null = null;
  try {
    _averageColor = getAverageColor(refImageMain.value);
  } catch (error) {
    console.log("Failed to get image average color");
  }

  if (_averageColor) averageColor.value = _averageColor;
}
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Creator info ===============================================================
.creator-info {
  opacity: 0.5;

  &--invisible {
    opacity: 0;
  }

  &__icon-n-title {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }

  &__icon {
    width: 14px;
    height: 14px;
    margin-right: 2px;
    color: #fff;
    font-size: 14px;
    line-height: 14px;
  }

  &__title {
    color: #fff;
    font: 11px/11px sans-serif;
    text-transform: uppercase;
  }

  &__name {
    color: #fff;
    font: 14px/14px sans-serif;
  }
}

// Visit dates ================================================================
.visit-dates {
  display: flex;
  align-items: center;
  line-height: 13px;
  opacity: 0.5;

  &__icon-n-title {
    margin: 0 12px 4px 0;
    display: flex;
    align-items: center;
  }

  &__icon {
    width: 13px;
    margin-right: 3px;
    font-size: 13px;
    color: #fff;
  }

  &__title {
    color: #fff;
    font-size: 11px;
    line-height: 14px;
    text-transform: uppercase;
    white-space: nowrap;
  }

  &__date {
    margin-bottom: 4px;
    line-height: 14px;
    white-space: nowrap;
  }
}

// Bg images stack ============================================================
.bg-images-stack {
  position: relative;

  &__img-wrap {
    width: 100%;
    height: 100%;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 0 auto auto 0;
    overflow: hidden;
    background: white;

    &--top {
      z-index: 3;
    }
    &--middle {
      inset: 5px auto auto 5px;
      z-index: 2;

      &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0 auto auto 0;
        z-index: 2;
        background: rgba(255, 255, 255, 0.2);
      }
    }
    &--bottom {
      inset: 10px auto auto 10px;
      z-index: 1;

      &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0 auto auto 0;
        z-index: 2;
        background: rgba(255, 255, 255, 0.87);
      }
    }
  }

  &__gradient {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0 auto auto 0;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &--hidden {
      position: absolute;
      opacity: 0;
    }
  }
}

// Collab snippet =============================================================
.collab-snippet {
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  color: #fff;
  font: 14px/22px sans-serif;
  user-select: none;

  &--small {
    width: 190px;
    height: 115px;
  }

  &__in-wrap {
    height: 100%;
    position: relative;
  }

  &__label-wrap {
    width: 100%;
    height: 100%;
    padding: 50px 25px 22px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
  }

  &__avatar {
    width: 100px;
    min-width: 100px;
    position: absolute !important;
    inset: 0 auto auto 25px;

    &--small {
      width: 60px;
      min-width: 60px;
      left: 12px;
    }
  }

  &__creator-info {
    margin: 0 0 33px 120px;
  }

  &__title {
    flex-grow: 1;
    padding: 0 0 10px 40px;
    margin-bottom: 14px;
    border-bottom: 1px rgba(255, 255, 255, 0.2) solid;
    color: #fff;
    font:
      400 26px/31px "Quicksand",
      sans-serif;
  }

  &__visit-dates {
  }

  :deep(input[type="radio"]) {
    width: 15px;
    min-width: 15px;
    height: 15px;
    position: absolute;
    inset: 126px auto auto 33px;
    z-index: 2;
    cursor: pointer;
    transition: none;
  }

  &__radio-input-bg {
    width: 31px;
    height: 31px;
    border-radius: 50%;
    position: absolute;
    inset: 118px auto auto 25px;
    z-index: 1;
    background: rgba(255, 255, 255, 0.5);
    //backdrop-filter: blur(10px); // Caused issues when lots of photos
    pointer-events: none;
  }

  &__bg-layer {
    width: 100%;
    height: calc(100% - 27px);
    border-radius: 7px;
    position: absolute;
    inset: 27px auto auto 0;
    z-index: -1;
    background: rgba(0, 0, 0, 0.4);
    transition: background-color 0.07s ease-in-out;

    &--small {
      top: 14px;
    }
  }

  &__bg-images-stack {
    width: 100%;
    height: calc(100% - 27px);
    position: absolute;
    inset: 27px auto auto 0;
    z-index: -2;

    &--small {
      height: calc(100% - 14px);
      top: 14px;
    }
  }

  &:hover &__bg-layer {
    background: rgba(0, 0, 0, 0.3);
  }

  :deep(input[type="radio"]:checked) + &__bg-layer {
    outline: 5px #118689 solid;
    outline-offset: -5px;
    transition: none;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .collab-snippet {
    &__label-wrap {
      padding: 40px 15px 12px;
    }

    &__avatar {
      width: 80px;
      min-width: 80px;
      inset: 0 auto auto 15px;

      &--small {
        width: 60px;
        min-width: 60px;
        left: 12px;
      }
    }

    &__creator-info {
      margin: 0 0 20px 100px;
    }

    :deep(input[type="radio"]) {
      inset: 102px auto auto 23px;
    }

    &__radio-input-bg {
      inset: 94px auto auto 15px;
    }

    &__bg-layer {
      height: calc(100% - 20px);
      inset: 20px auto auto 0;
    }

    &__bg-images-stack {
      height: calc(100% - 20px);
      inset: 20px auto auto 0;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .collab-snippet {
    &__label-wrap {
      padding: 40px 15px 12px;
    }

    &__avatar {
      width: 80px;
      min-width: 80px;
      inset: 0 auto auto 15px;

      &--small {
        width: 60px;
        min-width: 60px;
        left: 12px;
      }
    }

    &__creator-info {
      margin: 0 0 20px 100px;
    }

    :deep(input[type="radio"]) {
      inset: 102px auto auto 23px;
    }

    &__radio-input-bg {
      inset: 94px auto auto 15px;
    }

    &__bg-layer {
      height: calc(100% - 20px);
      inset: 20px auto auto 0;
    }

    &__bg-images-stack {
      height: calc(100% - 20px);
      inset: 20px auto auto 0;
    }
  }
}
</style>
