<template>
  <slot :stopVisualStatus="currentStopVisualStatus" />

  <div :class="`roadmap-snippet ${$attrs.class}`">
    <!-- Icon container -->
    <div
      :class="{
        'icon-container': true,
        'roadmap-snippet__icon-container': true,
        'roadmap-snippet__icon-container--drag-title-closer': currentStopVisualStatus !== 'current',
      }"
    >
      <div
        :class="{
          'icon-container__circle': true,
          'icon-container__circle--small': currentStopVisualStatus !== 'current',

          'icon-container__circle--green': currentStopVisualStatus === 'finished' || (props.stopSnippet.title === StopTitles.Destinations && currentStopVisualStatus === 'current'),
          'icon-container__circle--teal': currentStopVisualStatus === 'current' && props.stopSnippet.title !== StopTitles.Destinations,
          'icon-container__circle--gray': currentStopVisualStatus === 'future',
        }"
      >
        <div
          v-if="currentStopVisualStatus !== 'future' && props.stopSnippet.title !== StopTitles.Destinations"
          :class="{
            'icon-container__edit-button': true,
            'icon-container__edit-button--closer-to-center': currentStopVisualStatus !== 'current',
          }"
        >
          <IconEmbedded
            :name="currentStopVisualStatus === 'current' ? 'hourglass_2-5' : 'check_4'"
            :size="currentStopVisualStatus === 'current' ? 21 : 22"
            :color="currentStopVisualStatus === 'current' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(76, 150, 55, 1)'"
          />
        </div>

        <img
          v-if="
            (userProfileStore.getViewingUserProfile?.tileImageLocation && props.stopSnippet.title === StopTitles.Portfolio) ||
            (isAvatarLoadedSuccessfully && props.stopSnippet.title === StopTitles.Portfolio && isCurrentStopCompleted && userProfileStore.getViewingUserProfile?.tileImageLocation)
          "
          :class="{
            'icon-container__avatar': true,
            'icon-container__avatar--big': currentStopVisualStatus === 'current',
          }"
          :src="(userProfileStore.getViewingUserProfile.imageIsExternal ? '' : `${contentBaseUri}/cms/images/profiles/`) + userProfileStore.getViewingUserProfile?.tileImageLocation"
          :alt="userProfileStore.getViewingUserProfile.firstName"
          @error="isAvatarLoadedSuccessfully = false"
        />

        <IconEmbedded
          v-if="props.stopSnippet.icon"
          class="icon-container__icon"
          :name="props.stopSnippet.icon.name"
          :size="currentStopVisualStatus === 'current' ? props.stopSnippet.icon.size : props.stopSnippet.icon.size * 0.7"
          :color="
            props.stopSnippet.title === StopTitles.Destinations && currentStopVisualStatus === 'current'
              ? 'rgba(76, 150, 55, 1)'
              : { finished: 'rgba(76, 150, 55, 1)', current: 'rgba(17, 134, 137, 1)', future: 'rgba(133, 147, 154, 1)' }[currentStopVisualStatus]
          "
        />
        <!-- Adventure status -->
        <div
          v-else
          :class="{
            'adventure-status': true,
            'adventure-status--inactive': currentStopVisualStatus === 'future',
            'icon-container__adventure-status': true,
          }"
        >
          <template v-if="draftedAdventuresCount === 0 && publishedAdventuresCount === 0">
            <div
              :class="{
                'adventure-status__first-line': true,
                'adventure-status__first-line--big': currentStopVisualStatus === 'current',
              }"
            >
              <span class="adventure-status__big-letters">0</span><span class="adventure-status__small-letters"> of </span><span class="adventure-status__big-letters">1</span>
            </div>
            <div
              :class="{
                'adventure-status__second-line': true,
                'adventure-status__second-line--big': currentStopVisualStatus === 'current',
              }"
            >
              created
            </div>
          </template>

          <template v-else-if="draftedAdventuresCount > 0 && publishedAdventuresCount === 0">
            <div
              :class="{
                'adventure-status__first-line': true,
                'adventure-status__first-line--big': currentStopVisualStatus === 'current',
              }"
            >
              {{ draftedAdventuresCount }}
            </div>
            <div
              :class="{
                'adventure-status__second-line': true,
                'adventure-status__second-line--big': currentStopVisualStatus === 'current',
              }"
            >
              started
            </div>
          </template>

          <template v-else-if="publishedAdventuresCount > 0">
            <div
              :class="{
                'adventure-status__first-line': true,
                'adventure-status__first-line--big': currentStopVisualStatus === 'current',
              }"
            >
              {{ publishedAdventuresCount }}
            </div>
            <div
              :class="{
                'adventure-status__second-line': true,
                'adventure-status__second-line--big': currentStopVisualStatus === 'current',
              }"
            >
              created
            </div>
          </template>
        </div>
        <!-- / Adventure status -->
      </div>
    </div>
    <!-- / Icon container -->

    <div class="roadmap-snippet__text-content">
      <div
        :class="{
          'global-h3': true,
          'roadmap-snippet__title': true,
          'roadmap-snippet__title--disabled': currentStopVisualStatus !== 'current',
          'roadmap-snippet__title--small': currentStopVisualStatus !== 'current',
          'roadmap-snippet__title--clickable': props.stopSnippet?.editCallback,
        }"
        @click="() => props.stopSnippet?.editCallback()"
      >
        {{ props.stopSnippet.title }}
      </div>

      <template v-if="!isCurrentStopCompleted && props.stopSnippet.title !== StopTitles.Destinations && currentStopVisualStatus === 'current'">
        <SrpButton v-if="props.stopSnippet.buttonText" class="roadmap-snippet__button" color="orange" @click="props.stopSnippet.editCallback">
          {{ props.stopSnippet.buttonText }}
        </SrpButton>

        <div v-if="props.stopSnippet.time" class="roadmap-snippet__time">{{ props.stopSnippet.time }}</div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

defineOptions({
  inheritAttrs: false,
});

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import SrpButton from "@components/ui/SrpButton.vue";

// Types
import { ItinerarySummaryCms } from "@contracts/itinerary";
import { StopSnippet, StopTitles } from "./types";

// Stores
import { useUserProfileStore } from "@stores/userProfileStore";
const userProfileStore = useUserProfileStore();

// Global variables
const contentBaseUri = globalThis.Bootstrap.Config.contentCdnBaseUri;

const props = withDefaults(
  defineProps<{
    draftedAdventuresCount: number;
    publishedAdventuresCount: number;
    stopSnippet: StopSnippet | null;
    profileIsSetup: boolean;
  }>(),
  {
    draftedAdventuresCount: 0,
    publishedAdventuresCount: 0,
    stopSnippet: null,
    profileIsSetup: false,
  }
);

// Is avatar img loaded =======================================================
const isAvatarLoadedSuccessfully = ref<boolean>(true);

// Calc isCompleted ===========================================================
const isBoundaryCompleted = computed<boolean>(() => {
  if (userProfileStore.getViewingUserProfile?.collabPreferences) {
    const { latitude, longitude, travelBoundaryAreaInMiles } = userProfileStore.getViewingUserProfile.collabPreferences;
    return Boolean(latitude && longitude && travelBoundaryAreaInMiles);
  }
  return false;
});
const isPortfolioCompleted = computed<boolean>(() => {
  return props.profileIsSetup;
});
const isAdventureCompleted = computed<boolean>(() => {
  return props.publishedAdventuresCount > 0;
});

const isCurrentStopCompleted = computed<boolean>(() => {
  const title = props.stopSnippet.title;

  if (title === StopTitles.Boundary) return isBoundaryCompleted.value;
  else if (title === StopTitles.Portfolio) return isPortfolioCompleted.value;
  else if (title === StopTitles.Adventure) return isAdventureCompleted.value;

  return false;
});

// Visual status ==============================================================
const currentStopVisualStatus = computed<"finished" | "current" | "future">(() => {
  const title = props.stopSnippet.title;
  const currentStepIndex = [StopTitles.Boundary, StopTitles.Portfolio, StopTitles.Adventure].findIndex(t => t === title);
  const stopStatuses = [isBoundaryCompleted.value, isPortfolioCompleted.value, isAdventureCompleted.value, true];

  const isAllPrevCompleted = stopStatuses.slice(0, currentStepIndex).every(s => s === true);

  return isAllPrevCompleted ? (stopStatuses[currentStepIndex] ? "finished" : "current") : "future";
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Adventure status ===========================================================
.adventure-status {
  &--inactive {
    opacity: 0.5;
  }

  &__first-line {
    color: rgba(0, 0, 0, 1);
    font:
      700 22px/22px "Quicksand",
      sans-serif;

    &--big {
      font-size: 35px;
      line-height: 35px;
    }

    text-align: center;
  }

  &__big-letters {
  }

  &__small-letters {
    margin: 0 -4px;
    font-size: 0.7em;
  }

  &__second-line {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    line-height: 14px;

    &--big {
      font-size: 18px;
      line-height: 18px;
    }

    text-align: center;
  }
}

// Icon container =============================================================
.icon-container {
  width: 130px;
  min-width: 130px;
  height: 130px;
  min-height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &__circle {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(231, 237, 240, 1);

    &--small {
      width: 90px;
      height: 90px;
    }

    &--green {
      border: 6px rgba(76, 150, 55, 1) solid;
      background: rgba(219, 244, 212, 1);
    }
    &--teal {
      border: 6px rgba(17, 134, 137, 1) solid;
      background: rgba(231, 237, 240, 1);
    }
    &--gray {
      background: rgba(231, 237, 240, 1);
    }
  }

  &__edit-button {
    min-width: 37px;
    min-height: 37px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 0 0 auto auto;
    z-index: 3;
    user-select: none;

    &--closer-to-center {
      inset: 16px 16px auto auto;
    }

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      border: 1px rgba(224, 224, 224, 1) solid;
      border-radius: 100px;
      position: absolute;
      inset: 0 auto auto 0;
      z-index: -1;
      background: rgba(255, 255, 255, 1);
      transition:
        width 0.05s ease-in-out,
        height 0.05s ease-in-out,
        inset 0.05s ease-in-out;
    }
  }

  &__avatar {
    width: calc(100% - 52px);
    height: calc(100% - 52px);
    border-radius: 1000px;
    position: absolute;
    inset: 26px auto auto 26px;
    z-index: 2;

    &--big {
      width: calc(100% - 12px);
      height: calc(100% - 12px);
      inset: 6px auto auto 6px;
    }
  }

  &__icon {
  }

  &__adventure-status {
  }
}

// Roadmap snippet ============================================================
.roadmap-snippet {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgba(91, 91, 91, 1);
  font: 14px/18px sans-serif;

  &--finished {
  }
  &--current {
  }
  &--future {
  }

  &__icon-container {
    margin-bottom: 10px;
    position: relative;
    z-index: 0;

    &--drag-title-closer {
      margin-bottom: -13px;
    }
  }

  &__text-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    margin-bottom: 10px;
    position: relative;
    text-align: center;
    white-space: pre-wrap;

    &:last-child {
      margin-bottom: 0;
    }

    &--disabled {
      color: rgba(0, 0, 0, 0.3);

      &:hover {
        text-decoration: none;
      }
    }

    &--clickable {
      text-decoration: underline;
      text-decoration-color: rgba(0, 0, 0, 0.3);
      text-underline-offset: 2px;
      text-decoration-thickness: 1px;
      text-decoration-style: dashed;
      user-select: none;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }

    &--small {
      font-size: 17px;
      line-height: 19px;
    }
  }

  &__button {
    margin-bottom: 10px;
  }

  &__time {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .roadmap-snippet {
    flex-direction: row;

    &__icon-container {
      margin: 0 8px 0 0;
      transform: scale(0.9);
      transform-origin: left center;

      &--drag-title-closer {
        margin: 0 -15px 0 0;
      }
    }

    &__text-content {
      align-items: flex-start;
    }

    &__title {
      text-align: left;
      white-space: normal;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .roadmap-snippet {
    flex-direction: row;

    &__icon-container {
      margin: 0 8px 0 0;
      transform: scale(0.9);
      transform-origin: left center;

      &--drag-title-closer {
        margin: 0 -15px 0 0;
      }
    }

    &__text-content {
      align-items: flex-start;
    }

    &__title {
      text-align: left;
      white-space: normal;
    }
  }
}
</style>
