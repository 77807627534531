<template>
  <div class="ui form">
    <div class="field">
      <div class="global-h4">Area Boundaries</div>
      <p class="cms">(Optional) The area you would like the adventures to stay within (ex. within 10 miles of town, within a specific county).</p>
      <textarea type="text" maxlength="1000" v-model="collabInput.areaBoundaries" ref="areaBoundariesTextarea"></textarea>
    </div>
    <div class="field">
      <div class="global-h4">Locations to Exclude</div>
      <p class="cms">(Optional) Are there locations the creator should avoid covering in this visit?</p>
      <textarea type="text" maxlength="1000" v-model="collabInput.excludedAttractions"></textarea>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// Types
import { CollabInput } from "@contracts/collab";

export default defineComponent({
  name: "StepBoundaries",

  components: {},

  props: {
    collabInput: { type: Object as () => CollabInput | null, required: true },
  },

  data() {
    return {};
  },

  mounted() {
    this.$nextTick(() => {
      (this.$refs.areaBoundariesTextarea as HTMLInputElement)?.focus();
    });
  },
});
</script>

<style scoped lang="scss"></style>
