<template>
    <span>
        <template v-if="showText">{{textToShow}}</template>
        <i class="copy outline icon copyIcon dark-grey"
            @click="copy()">
        </i>
        <b class="dark-grey" v-if="showCopied"> (Copied!)</b>
    </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";

//Currently used for emails (since mailto often doesn't work well if you don't have the default mail client wired up)

export default defineComponent({
  name: 'CopyText',
  props: {
    text: {
        type: String,
        default: null
    },
    //If we should show the text or if the parent will display it where needed (lets us put it in a totally different place)
    showText: {
        type: Boolean,
        default: true
    },
  },
  data() {
    return {
        fallbackText: 'support@shrpa.com',
        showCopied: false,
    }
  },
  computed: {
      textToShow(): string {
          return this.text ?? this.fallbackText;
      }
  },
  async mounted() {
  },
  methods: {
    copy() {
        var text = this.text ?? this.fallbackText;
        navigator.clipboard.writeText(text).then(() => {
            console.info('Copied to clipboard: ' + this.text);
        }, function(err) {
            console.error('Failed to copy to clipboard: ', err);
        });
        this.showCopied = true;
        setTimeout(() => {
            this.showCopied = false;
        }, 3000);
    }
  }
});
</script>


<style scoped>
    .copyIcon {
        cursor: pointer;
        margin: 0px;
        margin-left: 3px;
    }
    .dark-grey {
        /*Currently forcing it to the default color*/
        color: rgba(0, 0, 0, 0.87);
    }
</style>