import axios, { type AxiosInstance } from "axios";
import type { App } from "vue";

/**
 * Axios instance with base URL set to the API URL.
 */
const shrpaApi = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});

export { shrpaApi };


export function setupAxiosAuthInterceptor(axiosInstance: AxiosInstance, app: App) {
  axiosInstance.interceptors.request.use(
    async config => {
      // Note! The auth SDK loads async so we can't send requests that need auth until that's done loading.
      if (app.config.globalProperties.globalAuth.loading) {
        console.info(`Auth still loading. isAuthenticated=${app.config.globalProperties.globalAuth.isAuthenticated}`);
      }
      // Adjusted the below since we moved the authWrapper loading = false check further down to load the profile
      // which causes a chicken and egg problem since we need auth completed to make this call.
      // if(!Vue.prototype.auth.loading && Vue.prototype.auth.isAuthenticated) {
      if (app.config.globalProperties.globalAuth.isAuthenticated) {
        var token = await app.config.globalProperties.globalAuth.getTokenSilently();
        // console.debug("Token ", token);
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }
  
      // if (true) { console.info("✉️ ", config); }
      return config;
    },
    error => {
      // if (true) { console.error("✉️ ", error); }
      return Promise.reject(error);
    }
  )
}