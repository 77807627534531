<template>
  <teleport to="#app">
    <ul class="problem-badges-list">
      <!-- Problem badge -->
      <li
        :class="{
          'problem-badge': true,
          'problem-badge--yellow': badge.color === 'yellow',
          'problem-badge--red': badge.color === 'red',
          'problem-badges-list__problem-badge': true,
          'problem-badges-list__problem-badge--invisible': !badge.isVisible,
        }"
        v-for="badge in badgesList"
        :key="badge.id"
        @click="clickBadge(badge)"
      >
        <IconEmbedded
          class="problem-badge__icon"
          name="warning_2-5"
          :size="25"
          :color="
            {
              red: 'rgba(255, 255, 255, 0.7)',
              yellow: 'rgba(0, 0, 0, 0.4)',
            }[badge.color]
          "
        />

        <div
          :class="{
            'problem-badge__text': true,
            'problem-badge__text--red': badge.color === 'red',
            'problem-badge__text--yellow': badge.color === 'yellow',
          }"
        >
          {{ badge.description }}
        </div>
      </li>
      <!-- / Problem badge -->
    </ul>
  </teleport>
</template>

<script setup lang="ts">
import { ref, watch, nextTick, onMounted, inject, Ref } from "vue";
import { useRoute } from "vue-router";

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";

// Types
export interface ProblemBadge {
  id: symbol;
  color: "yellow" | "red";
  isVisible: boolean;
  description: string;
  // callback is needed to do something on the page before scrolling to the section with the error
  // (such as switch tab or open modal etc.)
  callback?: () => Promise<void>;
  elemToScrollTo: HTMLElement | null;
}
export interface FloatingProblemBadgesExpose {
  upsertBadge: (problemBadge: ProblemBadge) => void;
  removeBadge: (id: symbol) => void;
  checkIfBadgeExists: (id: symbol) => boolean;
  removeAllBadges: () => void;
}

const route = useRoute();

// Badges list ================================================================
const badgesList = ref<Array<ProblemBadge>>([]);

// Upsert badge ===============================================================
function upsertBadge(problemBadge: ProblemBadge) {
  removeBadge(problemBadge.id);

  badgesList.value.push(problemBadge);
}

// Set intersectionObserver ===================================================
let observer: IntersectionObserver | null = null;

onMounted(() => {
  observer = new IntersectionObserver(handleIntersection, {
    root: null,
    threshold: 0.1,
  });

  badgesList.value.forEach(b => {
    observer.observe(b.elemToScrollTo);
  });
});

watch(badgesList, () => {
  observer.disconnect();

  badgesList.value.forEach(b => observer.observe(b.elemToScrollTo));
});

function handleIntersection(entries: Array<IntersectionObserverEntry>) {
  // find the badge which "elemToScrollTo" intersection changed
  const entryTargets = entries.map(e => e.target);

  badgesList.value.forEach(badge => {
    if (entryTargets.includes(badge.elemToScrollTo)) {
      badge.isVisible = !entries.find(e => e.target === badge.elemToScrollTo)?.isIntersecting;
    }
  });
}

// Remove badge ===============================================================
function removeBadge(id: symbol) {
  badgesList.value = badgesList.value.filter(b => b.id !== id);
}

// Remove all badges ==========================================================
function removeAllBadges() {
  badgesList.value = [];
}

// Remove all badges when route changes =======================================
watch(route, removeAllBadges);

// Click badge ================================================================
async function clickBadge(problemBadge: ProblemBadge): Promise<void> {
  problemBadge.callback();
  await nextTick();

  problemBadge.elemToScrollTo.scrollIntoView({
    behavior: "smooth",
    block: "center",
  });
}

// Check if badge exists ======================================================
function checkIfBadgeExists(id: symbol): boolean {
  return Boolean(badgesList.value.find(b => b.id === id));
}

defineExpose({
  upsertBadge,
  checkIfBadgeExists,
  removeBadge,
  removeAllBadges,
} as FloatingProblemBadgesExpose);
</script>

<style scoped lang="scss">
@import "@/scss/z-indexes.scss";
@import "@/scss/screen-size-ranges.scss";

// Problem badge ==============================================================
.problem-badge {
  height: 36px;
  min-height: 36px;
  padding: 0 14px 0 5px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;
  cursor: pointer;
  user-select: none;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 0 5px 5px 0;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -1;
    transition: width 0.05s ease-in-out;
  }
  &:hover::before {
    width: calc(100% + 7px);
  }

  &--red {
    color: rgba(255, 255, 255, 1);

    &::before {
      background: rgba(233, 79, 79, 1);
    }
  }
  &--yellow {
    color: rgba(65, 65, 65, 1);

    &::before {
      background: rgba(243, 202, 94, 1);
    }
  }

  &__icon {
    margin: -1px 5px 0 0;

    &:last-child {
      margin-right: 0;
    }
  }

  &__text {
    font-family: sans-serif;
    text-decoration: underline;
    text-underline-offset: 2px;
    text-decoration-thickness: 1px;
    text-decoration-style: dashed;
    white-space: nowrap;

    &--red {
      text-decoration-color: rgba(255, 255, 255, 0.45);
    }
    &--yellow {
      text-decoration-color: rgba(0, 0, 0, 0.2);
    }
  }
  &:hover &__text {
    text-decoration: none;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .problem-badge {
    padding-right: 9px;

    &__icon {
      margin-right: 0;
    }

    &__text {
      display: none;
    }
  }
}

// Problem badges list ========================================================
.problem-badges-list {
  width: 0;
  height: 0;
  gap: 8px;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  list-style: none;
  position: fixed;
  inset: 50% auto auto 0;
  z-index: $z-index-problem-badges-list;

  &__problem-badge {
    &--invisible {
      position: absolute;
      opacity: 0;
      pointer-events: none;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .problem-badges-list {
    transform: scale(1.2);
    transform-origin: left center;
  }
}
</style>
