<template>
  <ul class="thesis-list">
    <li class="thesis-list__thesis">Visit {{ requestedLocationCount }} locations in the destination.</li>
    <li class="thesis-list__thesis">Upload 80+ pieces of content, including at least 8 video clips.</li>
    <li class="thesis-list__thesis">Create {{ requestedAdventureCount }} adventures.</li>
    <li class="thesis-list__thesis">Submit all content within 10 days after your visit.</li>
    <li class="thesis-list__thesis">Social media post (in feed or reel) tagging the destination and @tryshrpa as collaborators.</li>
  </ul>
</template>

<script setup lang="ts">
import { computed } from "vue";

const requestedLocationCountDefault = 10;
const requestedAdventureCountDefault = 4;

// Computed that returns the default if the props are 0
// This happens before the creator is chosen, and we show this when the creator is applying for a collab
const requestedLocationCount = computed(() => props.requestedLocationCount || requestedLocationCountDefault);
const requestedAdventureCount = computed(() => props.requestedAdventureCount || requestedAdventureCountDefault);

const props = withDefaults(
  defineProps<{
    requestedLocationCount: number;
    requestedAdventureCount: number;
  }>(),
  {
    requestedLocationCount: requestedLocationCountDefault,
    requestedAdventureCount: requestedAdventureCountDefault,
  }
);
</script>

<style scoped lang="scss">
// Thesis list ================================================================
.thesis-list {
  padding: 0;
  margin: 0;
  list-style: none;

  &__thesis {
    padding-left: 12px;
    margin-bottom: 5px;
    position: relative;
    color: #5b5b5b;
    font-family: sans-serif;

    &::before {
      content: "";
      width: 6px;
      height: 2px;
      display: block;
      position: absolute;
      inset: 9px auto auto 0;
      font-size: 0;
      line-height: 0;
      background: rgba(0, 0, 0, 0.2);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  :deep(a) {
    color: #2165a6;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
}
</style>
