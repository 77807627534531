<template>
  <div style="text-align: right">
    <button class="ui button small primary preview-btn" @click="openPreview">
      <span>Preview</span>
    </button>

    <!--Preview Modal-->
    <SrpModal v-model:isVisible="showPreviewModal">
      <template #content>
        The portion of Shrpa is in Preview and is still being developed.<br />
        Feel free to use it in the meantime and email support@shrpa.com if you find any issues.
      </template>
      <template #footer>
        <div class="ui small button" @click="closePreview()">Close</div>
      </template>
    </SrpModal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SrpModal from "@components/ui/SrpModal.vue";

export default defineComponent({
  name: "PreviewComponent",

  components: { SrpModal },

  data() {
    return {
      showPreviewModal: false,
    };
  },

  methods: {
    closePreview() {
      this.showPreviewModal = false;
    },
    openPreview() {
      this.showPreviewModal = true;
    },
  },
});
</script>

<style scoped lang="scss">
.preview-btn {
  background-color: #0585871a !important;
  color: #058587 !important;
}
</style>
