import Links from "@logic/ExternalLinks";

// Create an easy way for any
const externalLinkPlugin = {
  install(app, options) {
    app.provide("globalGetLink", key => {
      return Links.ExternalLinks[key];
    });
  },
};

export default externalLinkPlugin;
