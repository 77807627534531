<template>
	<div class="ajax-loader">
		<span class="ui active centered inline loader" style="z-index:100"></span>
	</div>
</template>

<style scoped>
.ajax-loader {
	padding: 10px;
	margin: 50px 0 0 0;
}
</style>

<script>
export default {
  name: 'loader',
}
</script>

