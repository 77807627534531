<template>
  <div v-if="adventureList">
    <div v-if="adventureList.adventures.length > 0" class="ui container list-section">
      <label class="cms-section-title">Included Adventures</label>
      <p style="margin-top: 5px">Drag adventures to reorder</p>

      <!-- Adventure tiles list -->
      <ul class="adventure-tiles-list" ref="domRefTilesListContainer">
        <!-- Tile with delete and Drag&Drop -->
        <li
          :class="{
            'tile-with-delete-and-dnd': true,
            'tile-with-delete-and-dnd--gray-placeholder': (isDragInProgress || isTouchDragInProgress) && (elementIndexDragged === index || elementIndexTouchDragged === index),
            'tile-with-delete-and-dnd--with-left-highlighting-line': (isDragInProgress || isTouchDragInProgress) && (elementIndexDraggedOver === index || elementIndexTouchDraggedOver === index),
            'adventure-tiles-list__tile-wrap': true,
          }"
          v-for="(adventure, index) in props.adventureList.adventures"
          :key="adventure.id"
          :data-index="index"
        >
          <CloseDeleteButton
            v-if="!((isDragInProgress || isTouchDragInProgress) && (elementIndexDragged === index || elementIndexTouchDragged === index))"
            class="tile-with-delete-and-dnd__delete-btn"
            @click="removeAdventureFromList(adventure)"
            icon="trashcan"
            size="small"
          />

          <div class="tile-with-delete-and-dnd__dnd-handle-wrap" :data-index="index">
            <DragAndDropHandle class="tile-with-delete-and-dnd__dnd-handle" />
          </div>

          <ItineraryTile class="tile-with-delete-and-dnd__tile-itself" :itinerary="adventure" :allowShortImage="false" :disableLink="true" :source="null" />
        </li>
        <!-- / Tile with delete and Drag&Drop -->
      </ul>
      <!-- / Adventure tiles list -->

      <div class="ui container edit-lists-section">
        <div class="list-option">
          <button class="ui small primary button" @click="save()">Save Changes</button>
          <button class="ui small button" @click="cancelEdit()">Cancel</button>
          <h5 v-if="saveMessage">{{ saveMessage }}</h5>
        </div>
      </div>
    </div>

    <SrpDetailsSummary class="pick-adventures-spoiler" :transition-duration="150" v-model:is-opened="isPickAdventuresOpened" style="margin-top: 25px">
      <template #heading="{ isOpened }">
        <h3
          :class="{
            'global-h3': true,
            'pick-adventures-spoiler__summary': true,
            'pick-adventures-spoiler__summary--smaller-bottom-padding': isOpened,
          }"
        >
          <span
            ><span> Pick {{ props.adventureList.adventures.length > 0 ? "more" : "the" }} Adventures to include </span></span
          >
        </h3>
      </template>
      <template #details>
        <!-- Adventure tiles list -->
        <ul class="adventure-tiles-list pick-adventures-spoiler__adventure-tiles-list" v-if="props.allAdventures?.length > 0">
          <!-- Adventure tile with plus -->
          <li class="adventure-tile-with-plus adventure-tiles-list__tile-wrap" v-for="itinerary in props.allAdventures" :key="itinerary.id">
            <div class="adventure-tile-with-plus__plus-icon-wrap">
              <IconEmbedded class="adventure-tile-with-plus__plus-icon" name="plus_2-5" :size="34" color="rgba(0, 0, 0, 0.35)" />
            </div>

            <ItineraryTile
              class="adventure-tile-with-plus__tile-itself"
              :itinerary="itinerary"
              :allowShortImage="false"
              :source="null"
              :openNewWindow="true"
              :invertClickForView="true"
              :customButtonText="'View Adventure'"
              @customButtonClick="addAdventureToList"
            />
          </li>
          <!-- / Adventure tile with plus -->
        </ul>
        <!-- / Adventure tiles list -->

        <div v-else>No adventures yet</div>
      </template>
    </SrpDetailsSummary>
  </div>
</template>

<script setup lang="ts">
import { computed, inject, onMounted, ref } from "vue";

// Types
import { PageAdventureList } from "@contracts/pages";
import { ItinerarySummary } from "@contracts/itinerary";

// Components
import CloseDeleteButton from "@components/ui/CloseDeleteButton.vue";
import DragAndDropHandle from "@components/ui/DragAndDropHandle.vue";
import ItineraryTile from "@components/ItineraryTile.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import SrpDetailsSummary from "@components/ui/SrpDetailsSummary.vue";

// Composables
import { moveItemToNewIndex, useMouseDragNDrop } from "@composables/useMouseDragNDrop";
import { useTouchDragNDrop } from "@composables/useTouchDragNDrop";

// Provides a quick and easy way to pick and order a set of adventures.

// Global variables & injections
const globalLog = inject("globalLog") as any;

const props = withDefaults(
  defineProps<{
    customerId: string;
    saveMessage: string | null;
    adventureList: PageAdventureList | null;
    allAdventures: Array<ItinerarySummary> | null;
  }>(),
  {
    customerId: "",
    saveMessage: "",
    adventureList: null,
    allAdventures: null,
  }
);

const emit = defineEmits<{
  (e: "cancel"): void;
  (e: "save"): void;
}>();

// Set isPickAdventuresOpened =================================================
const isPickAdventuresOpened = ref<boolean>(false);

onMounted(setIsPickAdventuresOpened);

function setIsPickAdventuresOpened() {
  // If they've already picked adventures hide the 'all adventures' section by default
  isPickAdventuresOpened.value = !props.adventureList?.adventures || props.adventureList?.adventures?.length === 0;
}

// Add/remove adventures ======================================================
function addAdventureToList(adventure: ItinerarySummary) {
  props.adventureList.adventures.push(adventure);
  const index = props.allAdventures.findIndex(a => a === adventure);
  props.allAdventures.splice(index, 1);
}
function removeAdventureFromList(adventure: ItinerarySummary) {
  // Add this as the first item
  props.allAdventures.unshift(adventure);
  var index = props.adventureList.adventures.findIndex(a => a === adventure);
  props.adventureList.adventures.splice(index, 1);
}

// Reorder ====================================================================
function onReorder(event, adventureList) {
  const element = adventureList[event.from];
  adventureList.splice(event.from, 1);
  adventureList.splice(event.to, 0, element);
}

// Handle drag&drop ===========================================================
const domRefTilesListContainer = ref<HTMLElement | null>(null);

const { isDragInProgress, elementIndexDragged, elementIndexDraggedOver } = useMouseDragNDrop(
  computed(() => true),
  computed(() => String(props.adventureList.adventures)),
  domRefTilesListContainer,
  ".tile-with-delete-and-dnd",
  ".tile-with-delete-and-dnd__dnd-handle-wrap",
  (oldIndex: number, newIndex: number) => {
    const reorderedList = moveItemToNewIndex(props.adventureList.adventures, oldIndex, newIndex, false);
    props.adventureList.adventures = reorderedList;
  }
);

const { isTouchDragInProgress, elementIndexTouchDragged, elementIndexTouchDraggedOver } = useTouchDragNDrop(
  computed(() => true),
  computed(() => String(props.adventureList.adventures)),
  domRefTilesListContainer,
  ".tile-with-delete-and-dnd",
  ".tile-with-delete-and-dnd__dnd-handle-wrap",
  (oldIndex: number, newIndex: number) => {
    const reorderedList = moveItemToNewIndex(props.adventureList.adventures, oldIndex, newIndex, false);
    props.adventureList.adventures = reorderedList;
  }
);

// Cancel =====================================================================
async function cancelEdit() {
  emit("cancel");
}

// Save =======================================================================
async function save() {
  globalLog.info(`Saving Homepage Lists! for ${props.customerId}`);
  // Parent component does the actual saving since what the list is being used for varies (and has different endpoints)
  emit("save");
}
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";
@import "@/scss/variables.scss";

// Tile with delete and Drag&Drop =============================================
.tile-with-delete-and-dnd {
  position: relative;
  z-index: 0;
  user-select: none;

  &--with-left-highlighting-line {
    &::before {
      content: "";
      width: 5px;
      height: 100%;
      border-radius: 10px;
      position: absolute;
      inset: 0 auto auto -15px;
      background: $brand-color;
    }
  }

  &--gray-placeholder {
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 5px;
      position: absolute;
      inset: 0 auto auto 0;
      z-index: 4;
      background: rgba(190, 190, 190, 1);
    }
  }

  &__dnd-handle-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: 2;
    overflow: hidden;
  }

  &__dnd-handle {
    position: absolute;
    inset: 10px auto auto 10px;
    z-index: 2;
    pointer-events: none;
  }

  &__delete-btn {
    position: absolute;
    inset: -10px -10px auto auto;
    z-index: 3;
  }

  &__tile-itself {
    height: 100%;
    margin: 0;
    pointer-events: none;
  }
}

// Adventure tile with plus ===================================================
.adventure-tile-with-plus {
  position: relative;
  z-index: 0;

  &__plus-icon-wrap {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: absolute;
    inset: 135px auto auto calc(50% - 20px);
    z-index: 2;
    pointer-events: none;

    &::before {
      content: "";
      width: calc(100% + 16px);
      height: calc(100% + 16px);
      border-radius: 100px;
      position: absolute;
      inset: -8px auto auto -8px;
      background: rgba(255, 255, 255, 1);
      transition:
        width 0.07s ease-in-out,
        height 0.07s ease-in-out,
        inset 0.07s ease-in-out;
    }
  }

  &__plus-icon {
  }

  &__tile-itself {
    height: 100%;
  }

  &:hover &__plus-icon-wrap::before {
    width: calc(100% + 22px);
    height: calc(100% + 22px);
    inset: -11px auto auto -11px;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .adventure-tile-with-plus {
    &__plus-icon-wrap {
      top: 80px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .adventure-tile-with-plus {
    &__plus-icon-wrap {
      top: 75px;
    }
  }
}

// Adventure tiles list =======================================================
.adventure-tiles-list {
  gap: 27px;
  padding: 25px 0 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;

  &__tile-wrap {
    width: calc(100% / 4 - 23px);
    margin: 0;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .adventure-tiles-list {
    gap: 17px;

    &__tile-wrap {
      width: calc(100% / 4 - 13px);
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .adventure-tiles-list {
    gap: 17px;

    &__tile-wrap {
      width: calc(100% / 3 - 13px);
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .adventure-tiles-list {
    gap: 17px;

    &__tile-wrap {
      width: calc(100% / 3 - 13px);
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .adventure-tiles-list {
    gap: 17px;

    &__tile-wrap {
      width: calc(100% / 2 - 13px);
    }
  }
}

// Pick adventures spoiler ====================================================
.pick-adventures-spoiler {
  padding: 21px 25px 11px;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px #ccd3d7 solid;
    border-radius: 6px;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: 5;
    pointer-events: none;
  }

  &__summary {
    padding-bottom: 15px;

    &--smaller-bottom-padding {
      padding-bottom: 10px;
    }
  }

  &__adventure-tiles-list {
    padding-bottom: 30px;
    border-top: 1px rgba(0, 0, 0, 0.1) solid;
  }
}

// ============================================================================
// ============================================================================
// ============================================================================

.list-section {
  position: relative;
  margin-bottom: 30px;
  .edit-list {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
  }
}
.edit-lists-section {
  padding-bottom: 2rem;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
.ui.card.drag {
  position: relative;
}
</style>
