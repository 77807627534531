<template>
  <div class="print-media-container">
    <div class="ui form">
      <!-- Delete Modal-->
      <SrpModal v-model:isVisible="isDeleteModalVisible">
        <template #header>
          <h2 class="global-h2">Cancel Request</h2>
        </template>
        <template #content>
          <div>Are you sure you want to cancel this request?</div>
        </template>
        <template #footer>
          <div class="ui basic black button" @click="rejectDeletion">Back</div>

          <SrpButton color="orange" :isDisabled="isDeletionInProgress" @click="deleteRequestedMedia(deletionRequestId)">Yes, Cancel it</SrpButton>

          <span v-if="isDeletionInProgress"><b>Canceling...</b></span>
        </template>
      </SrpModal>
      <!-- / Delete modal -->

      <!-- Preview Modal -->
      <SrpModal v-model:isVisible="isRequestFormVisible" ref="modal" :size="isRequestSentSuccessfully ? 'small' : 'medium'">
        <template #content>
          <!-- Sent successfully message -->
          <div class="sent-successfully-msg">
            <div class="sent-successfully-msg__checkmark-icon">
              <i class="checkmark icon"></i>
            </div>

            <div class="sent-successfully-msg__titles">
              <h2 class="sent-successfully-msg__title">Request sent</h2>
              <p class="sent-successfully-msg__subtitle">We'll create the download and let you know when it's ready.</p>
            </div>
          </div>
          <!-- / Sent successfully message -->
        </template>
        <template #footer>
          <span
            v-if="!isRequestSentSuccessfully"
            :class="{
              'ui primary large button': true,
              disabled: isLoading,
            }"
            @click="sendDownloadRequest"
          >
            <i v-if="!isLoading" class="send icon"></i>
            <i v-else class="spinner icon spinningAnimation"></i>Send request
          </span>

          <div class="ui small button" @click="() => (isRequestFormVisible = false)">Got it!</div>
        </template>
      </SrpModal>
      <!-- / Preview Modal -->

      <h1 class="print-media-container__h1">Flyer Studio</h1>

      <div>
        <p class="print-media-container__description">
          Help travelers and community members discover new adventures once they are already in market! <br />
          Flyer Studio makes it easy to share your
          <router-link v-if="pageId" :to="{ name: 'Pages', params: { pageId: pageId } }" target="_blank" style="text-decoration: underline">Destination Page</router-link>
          via flyers created for your community or downloadable QR codes for your custom designs.<br />
          Share around town with lodging partners, your busiest locations, and at your local visitor center.
        </p>
      </div>
      <div class="print-media-container__description-n-templates">
        <!-- Templates checkboxes -->
        <div class="templates-checkboxes print-media-container__templates">
          <h3 class="templates-checkboxes__title">Request a Template</h3>
          <Loader v-if="isExistingTemplatesLoading"></Loader>
          <div v-else class="templates-checkboxes__list">
            <div v-for="template in availableTemplates" :key="template.id" class="templates-checkboxes__label-wrap">
              <!-- Single template checkbox -->
              <label class="single-template-checkbox templates-checkboxes__label">
                <input class="single-template-checkbox__radio-but" type="radio" name="templateId" :value="template.templateId" />

                <span class="single-template-checkbox__checkmark">
                  <i style="margin-top: 25px" class="checkmark icon"></i>
                </span>

                <span class="single-template-checkbox__image-wrap">
                  <img class="single-template-checkbox__image" :src="`${contentBaseUri}${template.templateThumbnail}`" :alt="template.name" />
                </span>
              </label>
              <!-- / Single template checkbox -->

              <a class="templates-checkboxes__fullsize-link" data-fancybox="gallery" :href="`${contentBaseUri}${template.exampleLocation}`">
                <span>Full size</span>
              </a>

              <button
                :class="{
                  'templates-checkboxes__request-button ui primary mini button': template.templateId !== selectedTemplate,
                  'templates-checkboxes__request-button ui gray mini button': isLoading && template.templateId === selectedTemplate,
                }"
                :disabled="isTemplateRequested(template.templateId)"
                @click="
                  $event => {
                    selectedTemplate = template.templateId;
                    sendDownloadRequest($event);
                  }
                "
              >
                <template v-if="isLoading && template.templateId === selectedTemplate"> <i class="spinner icon"></i>Requesting </template>

                <template v-else> <i class="plus icon"></i> {{ isTemplateRequested(template.templateId) ? "Requested" : "Request" }} </template>
              </button>
            </div>
          </div>
        </div>
        <!-- / Templates checkboxes -->

        <div class="print-media-container__qr-code">
          <h3 class="templates-checkboxes__title">QR Code</h3>
          <Loader v-if="qrCodeImageData == null"></Loader>
          <p v-else>
            <img style="max-width: 100px; margin-bottom: 8px; border: 1px #bbb solid; border-radius: 6px" :src="`data:image/png;base64, ${qrCodeImageData}`" alt="qr code" />
            <br />
            <a style="margin-top: 17px" class="ui primary mini button" download="shrpa-qrcode.png" :href="qrCodeBinaryData"> <i class="download icon"></i>Download </a>
            <span v-if="justCopied"><b>Done!</b></span>
          </p>
        </div>
      </div>

      <template v-if="existingRequests.length || isLoading">
        <h3 style="margin-top: 0">Your Flyers</h3>

        <div class="print-media-container__print-items-list-wrap">
          <ul class="print-media-container__print-items-list">
            <PrintMediaSnippet
              v-for="item in existingRequests"
              :key="item.id"
              class="print-media-container__print-item"
              :data="item"
              :thumbnail="getThumbnail(item.templateId)"
              :is-skeleton="isLoading"
              @delete="confirmDeletion"
            />
          </ul>
        </div>
      </template>

      <div class="print-media-container__warning ui container banner-message">
        <div class="ui warning message" style="display: block">
          <div class="header">
            <i class="info icon"></i>
            Please email <CopyText /> if you need adjustments or would like to propose other templates.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import { defineComponent } from "vue";

// Types
import { PrintMediaTemplate } from "@contracts/pages";

// Components
import Loader from "@components/Loader/Loader.vue";
import PrintMediaSnippet from "@components/PrintMediaSnippet.vue";
import CopyText from "@components/CopyText.vue";
import OrgContext from "@logic/OrgContext";
import SrpModal from "@components/ui/SrpModal.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import { useHead } from "@unhead/vue";
import { MetricSender } from "@/helpers/MetricSender";
import { Base64ToBinary } from "@/logic/FileConversions";

export default defineComponent({
  name: "PrintMedia",

  components: {
    SrpButton,
    SrpModal,
    Loader,
    PrintMediaSnippet,
    CopyText,
  },

  data() {
    return {
      title: "Flyer Studio",
      isRequestFormVisible: false,
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,

      availableTemplates: [] as Array<PrintMediaTemplate>,

      existingRequests: [] as Array<PrintMediaTemplate>,

      // request form
      isLoading: false,
      isExistingTemplatesLoading: false,

      isQRCodeLoading: false,

      isRequestSentSuccessfully: false,

      selectedTemplate: "",

      deletionRequestId: "",
      isDeleteModalVisible: false,
      isDeletionInProgress: false,

      pageId: "",
      justCopied: false,
      qrCodeImageData: null,
      qrCodeBinaryData: null,
    };
  },

  watch: {
    isRequestFormVisible(): void {
      if (!this.isRequestFormVisible) {
        setTimeout(() => (this.isRequestSentSuccessfully = false), 1000);
      }
    },
  },

  async mounted() {
    useHead({ title: () => this.title ?? "" });

    // @ts-ignore
    this.pageId = this.$route.params.pageId;

    const orgInContext = OrgContext.getOrgInContext(this);
    if (orgInContext) this.title += " - " + orgInContext?.name;

    await this.getTheTemplates();
    await this.getQRCode();

    MetricSender.sendMetric("Cust-ViewPrintMedia", null);
  },

  methods: {
    async getQRCode(): Promise<void> {
      this.isQRCodeLoading = true;
      const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/qrcodes/${this.pageId}/destination-page`);
      this.qrCodeImageData = data;
      // Mac needs a binary png format for downloads
      const binaryData = Base64ToBinary(data);
      const blob = new Blob([binaryData], { type: "image/png" });
      this.qrCodeBinaryData = URL.createObjectURL(blob);
      this.isQRCodeLoading = false;
    },

    async getTheTemplates(): Promise<void> {
      this.isLoading = true;
      this.isExistingTemplatesLoading = true;
      const { data } = await axios({
        method: "GET",
        url: `${import.meta.env.VITE_API_URL}/printmedia/${this.$route.params.pageId}`,
      });

      this.availableTemplates = data.templates;
      this.existingRequests = data.existingRequests;

      this.isExistingTemplatesLoading = false;
      this.isLoading = false;
    },

    getThumbnail(templateId: string): string {
      return this.availableTemplates.find(i => i.templateId === templateId)?.templateThumbnail;
    },

    isTemplateRequested(templateId: string): boolean {
      return this.existingRequests.some(i => i.templateId === templateId);
    },

    async sendDownloadRequest(event): Promise<void> {
      event.preventDefault();

      this.isLoading = true;

      const payload = {
        selectedTemplate: this.selectedTemplate,
      };

      await axios.post(`${import.meta.env.VITE_API_URL}/printmedia/${this.$route.params.pageId}`, payload);

      this.selectedTemplate = "";

      this.isRequestSentSuccessfully = true;

      this.isRequestFormVisible = true;

      this.isLoading = false;

      this.getTheTemplates();
    },

    confirmDeletion(id): void {
      this.deletionRequestId = id;
      this.isDeleteModalVisible = true;
    },

    rejectDeletion(): void {
      this.deletionRequestId = "";
      this.isDeleteModalVisible = false;
    },

    async deleteRequestedMedia(id): Promise<void> {
      this.isDeletionInProgress = true;

      await axios.delete(`${import.meta.env.VITE_API_URL}/printmedia/${this.$route.params.pageId}/request/${id}`);

      this.isDeletionInProgress = false;
      this.isDeleteModalVisible = false;

      await this.getTheTemplates();
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/mixins/animations/spin-simple.scss";

.spinningAnimation {
  @include spinningAnimation;
}

// Sent successfully message ==================================================
.sent-successfully-msg {
  display: flex;
  justify-content: center;
  align-items: center;

  &__checkmark-icon {
    width: 97px;
    min-width: 97px;
    height: 97px;
    padding: 0 0 10px 6px;
    box-sizing: border-box;
    margin-right: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #518345;
    font-size: 37px;
    background: #dcf5d6;
  }

  &__titles {
  }

  &__title {
  }

  &__subtitle {
  }
}

// Single template checkbox ===================================================
.single-template-checkbox {
  width: 75px;
  aspect-ratio: 10/13;
  position: relative;

  &__radio-but {
    position: absolute;
    opacity: 0;
  }

  &__checkmark {
    width: 30px;
    height: 30px;
    padding: 0 0 3px 4px;
    box-sizing: border-box;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: calc(50% - 15px) auto auto calc(50% - 15px);
    z-index: 3;
    color: #fff;
    background: #118689;
    opacity: 0;
  }

  //&__radio-but:checked + &__checkmark {
  //  opacity: 1;
  //}

  &__image-wrap {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border: 1px #ccd3d7 solid;
      border-radius: 4px;
      position: absolute;
      inset: 0 auto auto 0;
      z-index: 2;
    }
  }

  //&__radio-but:checked + &__checkmark + &__image-wrap::before {
  //  border-width: 2px;
  //  border-color: #118689;
  //  background: rgb(231, 237, 240, .62);
  //}

  &__image {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: cover;
  }
}

// Templates checkboxes =======================================================
.templates-checkboxes {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &__list {
    width: calc(100% + 21px);
    display: flex;
    flex-wrap: wrap;
  }

  &__label-wrap {
    margin: 0 20px 20px 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__label {
    margin-bottom: 5px !important;
  }

  &__fullsize-link {
    margin-bottom: 8px;
    cursor: pointer;
    user-select: none;

    span {
      height: 17px;
      border-bottom: 1px #aaa dashed;
      display: inline-block;
    }

    &:hover {
      color: #058587;

      span {
        border-color: #058587;
      }
    }
  }

  &__request-button {
    width: 100px;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
  }
}

// Print media container ======================================================
.print-media-container {
  &__h1 {
    margin: 0 0 8px 0 !important;
  }

  &__form-wrap {
    position: relative;
    z-index: 0;
  }

  &__loading-overlay {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  }

  &__loading-spinner {
  }

  &__description-n-templates {
    display: flex;
  }

  &__description {
    padding-top: 25px;
    margin: 0 0 28px 0 !important;
  }

  &__templates {
  }

  &__qr-code {
  }

  &__request-button {
    margin-bottom: 42px !important;
  }

  &__warning {
    margin-bottom: 75px !important;
  }

  &__subtitle {
    margin: 0 0 23px 0 !important;
  }

  &__print-items-list-wrap {
    width: 100%;
  }

  &__print-items-list {
    width: calc(100% + 40px);
    min-width: calc(100% + 40px);
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  &__print-item {
    margin: 0 27px 30px 0 !important;
  }
}

@media (max-width: 1024px) {
  .print-media-container {
    &__description-n-templates {
      margin-bottom: 30px;
      flex-direction: column;
    }

    &__description,
    &__templates,
    &__qr-code {
      width: 100%;
    }
  }
}
</style>
