import { ThemeType } from "@contracts/collab";

export function getThemeIcon(themeType: ThemeType): string {
  return "/images/collabThemeType/" + themeType + ".svg";
}

export function getThemeTypeName(themeType: ThemeType): string {
  switch (themeType) {
    case "couple":
      return "Couple's Trip";
    case "event":
      return "Event Focused";
    case "family":
      return "Family";
    case "foodie":
      return "Foodie/Drinks Tour";
    case "guysgals":
      return "Gals/Guys Trip";
    case "location":
      return "Location Focused";
    case "outdoors":
      return "Outdoors";
    case "seasonal":
      return "Seasonal";
    case "town":
      return "Exploring Town";
    case "anyone":
      return "Anyone";
    default:
      return "";
  }
}
