<template>
  <div class="photo-size-radio">
    <label class="photo-size-radio__label-overlay" :for="`PhotoSizeRadio-${props.size}`"></label>

    <div class="photo-size-radio__header">
      <slot />
      <span class="photo-size-radio__title">
        {{ capitalize(props.size) }}
      </span>

      <div class="photo-size-radio__darken-overlay"></div>
    </div>

    <ul
      :class="{
        'photo-size-radio__icon-tiles-list': true,
        'photo-size-radio__icon-tiles-list--small': props.size === 'small',
        'photo-size-radio__icon-tiles-list--medium': props.size === 'medium',
        'photo-size-radio__icon-tiles-list--large': props.size === 'large',
      }"
    >
      <li
        :class="{
          'photo-size-radio__icon-tile': true,
          'photo-size-radio__icon-tile--small': props.size === 'small',
          'photo-size-radio__icon-tile--medium': props.size === 'medium',
          'photo-size-radio__icon-tile--large': props.size === 'large',
        }"
        v-for="_ in 6"
        :key="_"
      >
        <IconEmbedded
          :name="
            (
              {
                small: 'image_2-5',
                medium: 'image_2',
                large: 'image_1-5',
              } as Record<IconsSize, IconName>
            )[props.size]
          "
          :size="
            {
              small: 15,
              medium: 21,
              large: 28,
            }[props.size]
          "
        />
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";

// Types
import { IconName } from "@contracts/IconName";

type IconsSize = "small" | "medium" | "large";
const props = withDefaults(
  defineProps<{
    size: IconsSize;
  }>(),
  {
    size: "small",
  }
);

// Capitalize =================================================================
function capitalize(word: string): string {
  return word[0].toUpperCase() + word.slice(1);
}
</script>

<style scoped lang="scss">
// Photo size radio ===========================================================
.photo-size-radio {
  padding: 13px 12px 9px;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 4px;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -2;
    background: rgba(232, 232, 232, 1);
  }

  &__label-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: 2;
    cursor: pointer;
  }

  &__header {
    margin-bottom: 8px;
    display: flex;
    align-content: center;
    cursor: pointer;
  }

  :deep(input) {
    width: 18px;
    max-width: 18px;
    height: 18px;
    margin: 0 6px 0 0;
  }

  &__title {
    color: rgba(0, 0, 0, 1);
    font-weight: bold;
    user-select: none;
  }
  &__header:hover &__title {
    opacity: 0.7;
  }

  &__darken-overlay {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 2px rgba(84, 179, 180) solid;
    border-radius: 4px;
    display: block;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.05s ease-in-out;
  }
  input:checked + * + &__darken-overlay {
    opacity: 1;
  }

  &__icon-tiles-list {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;

    &--small {
      gap: 0;
      width: 45px;
    }
    &--medium {
      width: 61px;
    }
    &--large {
      width: 80px;
    }
  }

  &__icon-tile {
    &--small {
    }
    &--medium {
      margin: 0 -1px -1px 0;
    }
    &--large {
      margin: 0 -2px -2px 0;
    }
  }
}
</style>
