<template>
  <SrpModal v-model:isVisible="isVisible">
    <template #content>
      <!-- Dislike form -->
      <div class="dislike-form">
        <div class="dislike-form__header">
          <h2 class="global-h2 dislike-form__title">What's missing the mark? <span class="dislike-form__optional-mark">(optional)</span></h2>
          <div class="dislike-form__subtitle">Any guidance helps us improve future suggestions</div>
        </div>

        <div class="dislike-form__checkboxes-row">
          <SrpCheckbox class="dislike-form__checkbox">
            <template #input><input type="checkbox" v-model="formData.isWrongTheme" /></template>
            <template #text>Wrong Theme</template>
          </SrpCheckbox>
          <SrpCheckbox class="dislike-form__checkbox">
            <template #input><input type="checkbox" v-model="formData.isAlreadyHaveThis" /></template>
            <template #text>Redundant</template>
          </SrpCheckbox>
          <SrpCheckbox class="dislike-form__checkbox">
            <template #input><input type="checkbox" v-model="formData.isPoorAI" /></template>
            <template #text>Bad AI</template>
          </SrpCheckbox>
          <SrpCheckbox class="dislike-form__checkbox">
            <template #input><input type="checkbox" v-model="formData.isOther" /></template>
            <template #text>Other</template>
          </SrpCheckbox>
        </div>

        <!--<div class="dislike-form__textarea-title">or just tell us:</div>-->

        <textarea class="global-textarea dislike-form__textarea" v-model="formData.feedbackText"></textarea>
      </div>
      <!-- / Dislike form -->
    </template>

    <template #footer>
      <SrpButton
        @click="
          () => {
            resetFormData();
            isVisible = false;
          }
        "
        color="gray"
        fill="outlined"
        style="margin-right: 12px"
        >Cancel</SrpButton
      >
      <SrpButton
        :isDisabled="isRemoving"
        @click="
          async () => {
            await remove();
            resetFormData();
            isVisible = false;
          }
        "
      >
        <template v-if="isRemoving" #icon><LoadingIcon :size="23" /></template>
        Remove
      </SrpButton>
    </template>
  </SrpModal>
</template>

<script setup lang="ts">
import axios from "axios";
import { ref, computed } from "vue";

// Components
import SrpButton from "@components/ui/SrpButton.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import SrpCheckbox from "@components/ui/SrpCheckbox.vue";
import LoadingIcon from "@components/ui/LoadingIcon.vue";

const props = withDefaults(
  defineProps<{
    pageId: string;
  }>(),
  {
    pageId: "",
  }
);

const emit = defineEmits<{
  (e: "update:isVisible", value: boolean): void;
  (e: "remove", collabSuggestionId: string): void;
}>();

// Form Data ==================================================================
class FormData {
  isWrongTheme = false;
  isAlreadyHaveThis = false;
  isPoorAI = false;
  isOther = false;

  feedbackText = "";
}

const formData = ref<FormData>(new FormData());

function resetFormData() {
  formData.value = new FormData();
}

// Collab suggestion id =======================================================
const collabSuggestionId = ref<string>("");

// Open modal =================================================================
const isVisible = ref<boolean>(false);

async function openModal(_collabSuggestionId: string) {
  isVisible.value = true;

  collabSuggestionId.value = _collabSuggestionId;
}

// Build the "Reason" string from the formData ================================
const reasonString = computed<string>(() => {
  const reasonsArray = [];
  if (formData.value.isWrongTheme) reasonsArray.push("Wrong theme");
  if (formData.value.isAlreadyHaveThis) reasonsArray.push("Already have this");
  if (formData.value.isPoorAI) reasonsArray.push("Poor AI");
  if (formData.value.isOther) reasonsArray.push("Other reasons");

  let reasonText = reasonsArray.join(", ");
  if (formData.value.feedbackText) reasonText += `,\n ${formData.value.feedbackText}`;

  return reasonText;
});

// Send remove request ========================================================
const isRemoving = ref<boolean>(false);

async function remove() {
  isRemoving.value = true;

  let uri = `${import.meta.env.VITE_API_URL}/collab-suggestions/${props.pageId}/suggestions/${collabSuggestionId.value}/dislike`;
  const config = { headers: { "Content-Type": "application/json" } };
  await axios.put<boolean>(uri, JSON.stringify(reasonString.value), config);

  emit("remove", collabSuggestionId.value);
  isRemoving.value = false;
}

defineExpose({
  openModal,
});
</script>

<style scoped lang="scss">
// Dislike form ===============================================================
.dislike-form {
  color: rgba(91, 91, 91, 1);
  font: 14px/19px sans-serif;

  &__header {
    margin-bottom: 25px;
  }

  &__title {
    margin-bottom: 2px;
  }

  &__optional-mark {
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
  }

  &__subtitle {
  }

  &__checkboxes-row {
    gap: 10px;
    margin-bottom: 25px;
    display: flex;
    flex-wrap: wrap;
  }

  &__checkbox {
  }

  &__textarea-title {
    margin-bottom: 10px;
  }

  &__textarea {
    height: 70px;
    min-height: 70px;
  }
}
</style>
