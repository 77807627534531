<template>
  <div class="creator-onboarding-page">
    <h1 class="global-h1 creator-onboarding-page__title">How to become paid eligible</h1>

    <div class="creator-onboarding-page__divider"></div>

    <!-- Roadmap -->
    <div class="roadmap creator-onboarding-page__roadmap">
      <ul class="roadmap__snippets-list">
        <li class="roadmap__snippet-wrap" v-for="(stopSnippet, index) in roadmapStopsList" :key="stopSnippet.title">
          <RoadmapStopSnippet
            class="roadmap__stop-snippet"
            :draftedAdventuresCount="dashboardSummary?.createdAdventureCount"
            :publishedAdventuresCount="dashboardSummary?.publishedAdventureCount"
            :stopSnippet="stopSnippet"
            :profileIsSetup="dashboardSummary?.profileIsSetup"
            v-slot="{ stopVisualStatus }"
          >
            <template v-if="index !== 0">
              <div
                v-if="stopVisualStatus === 'finished' || (stopSnippet.title === StopTitles.Destinations && stopVisualStatus === 'current')"
                class="roadmap__connection-line roadmap__connection-line--green"
              ></div>
              <div v-else-if="stopVisualStatus === 'current'" class="roadmap__connection-line roadmap__connection-line--green-to-teal"></div>
              <div v-else-if="stopVisualStatus === 'future'" class="roadmap__connection-line roadmap__connection-line--gray-dashed"></div>
            </template>
          </RoadmapStopSnippet>
        </li>
      </ul>

      <SrpModal v-model:isVisible="isBoundaryModalVisible" size="large" isWithScroll :isClosable="false" :isCloseButtonVisible="false">
        <template #header>
          <div style="padding-bottom: 15px; border-bottom: 1px rgba(0, 0, 0, 0.2) solid">
            <h1 class="global-h1" style="margin-bottom: 5px">Your Travel Range</h1>
            <!--            <div style="color: rgba(91, 91, 91, 1); font-family: sans-serif">How far are you able to travel for a collaboration?</div>-->
          </div>
        </template>
        <template #content>
          <PaidCreatorFields :currentProfile="userProfileStore.getViewingUserProfile" />
        </template>
        <template #footer>
          <span v-if="travelBoundaryError" class="creator-onboarding-page__travel-boundary-error">{{ travelBoundaryError }}</span>
          <SrpButton @click="savePaidCreatorFields" :isDisabled="isSavingPaidCreatorFields">
            <template #icon><IconEmbedded name="save_2" style="margin-right: 6px" :size="22" color="rgba(255, 255, 255, 0.4)" /></template>
            {{ dashboardSummary?.profileIsSetup ? "Save Boundary" : "Save & Continue" }}
          </SrpButton>
        </template>
      </SrpModal>

      <div class="roadmap__footer-links">
        <LinkWithIcon class="roadmap__link" color="teal" tag="a" :href="Links.ExternalLinks['BecomingACreator']" target="_blank">
          <template #icon><IconEmbedded name="aperture_2" :size="23" /></template>
          <span>Learn about becoming a creator</span>
        </LinkWithIcon>
      </div>
    </div>
    <!-- / Roadmap -->

    <div class="creator-onboarding-page__divider"></div>

    <h2 class="global-h1 creator-onboarding-page__title creator-onboarding-page__title--thin creator-onboarding-page__title--centered">What's happening on Shrpa</h2>

    <ActivityFeed class="creator-onboarding-page__activity-feed" :isWithH1="false" :allowShowMore="false" />

    <div class="creator-onboarding-page__divider"></div>

    <h2 class="global-h1 creator-onboarding-page__title creator-onboarding-page__title--thin creator-onboarding-page__title--centered">At Shrpa we're</h2>

    <StatsCircles class="creator-onboarding-page__stats-circles" />
  </div>
</template>

<!-- prettier-ignore -->
<script lang="ts">export default { name: "CreatorOnboardingV2" };</script>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import Links from "@logic/ExternalLinks";
import FeatureFlags from "@/logic/FeatureFlags";
import { MetricSender } from "@/helpers/MetricSender";

// Components
import ActivityFeed from "@components/ActivityFeed/index.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import PaidCreatorFields from "@components/PaidCreatorFields.vue";
import RoadmapStopSnippet from "./RoadmapStopSnippet.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import StatsCircles from "./StatsCircles.vue";

// Types
import { StopSnippet, StopTitles } from "./types";
import { CreatorDashboardSummary } from "@contracts/creatorPublicProfileFields";

// Stores
import { useUserProfileStore } from "@stores/userProfileStore";
const userProfileStore = useUserProfileStore();

// Router
const router = useRouter();

const props = withDefaults(
  defineProps<{
    dashboardSummary: CreatorDashboardSummary | null;
  }>(),
  {
    dashboardSummary: () => null,
  }
);

// Toggle boundary modal ======================================================
const isBoundaryModalVisible = ref<boolean>(false);

// Roadmap stops list =========================================================
const roadmapStopsList = computed<Array<StopSnippet>>(() => {
  return [
    {
      title: StopTitles.Boundary,
      icon: { name: "map-pin-solid", size: 78 },
      buttonText: "Choose",
      editCallback: () => {
        isBoundaryModalVisible.value = true;
        MetricSender.setPropertyOnSession("OnboardingState", "ChooseBoundary");
      },
      time: "~30 sec",
    },
    {
      title: StopTitles.Portfolio,
      icon: { name: "user-solid", size: 65 },
      buttonText: "Set up",
      editCallback: async () => {
        MetricSender.setPropertyOnSession("OnboardingState", "StartPortfolio");
        await router.push({ name: "CreatorProfilePublic", params: { creatorId: userProfileStore.getViewingUserProfile.sherpaId }, query: { isOnboarding: "true" } });
      },
      time: "~5 min",
    },
    {
      title: StopTitles.Adventure,
      buttonText: props.dashboardSummary?.createdAdventureCount === 0 ? "Create an adventure" : "Finish my adventure",
      editCallback: async () => {
        MetricSender.setPropertyOnSession("OnboardingState", "StartAdventure");
        await router.push(getCmsEditorLink());
      },
      time: "~10 min",
    },
    {
      title: StopTitles.Destinations,
      icon: { name: "party-popper", size: 82 },
    },
  ];
});

// Save paid creator fields ===================================================
const isSavingPaidCreatorFields = ref<boolean>(false);
const travelBoundaryError = ref<string>(null);

async function savePaidCreatorFields() {
  if (!userProfileStore.getViewingUserProfile?.collabPreferences.latitude || !userProfileStore.getViewingUserProfile.collabPreferences?.longitude) {
    travelBoundaryError.value = "Select a Home Town";
    return;
  } else if (!userProfileStore.getViewingUserProfile?.collabPreferences.travelBoundaryAreaInMiles) {
    travelBoundaryError.value = "Select Travel Radius or Draw a Boundary";
    return;
  }

  travelBoundaryError.value = null;
  isSavingPaidCreatorFields.value = true;

  await userProfileStore.saveProfile(userProfileStore.getViewingUserProfile);
  // If they're profile isn't complete, send them to the new profile page
  if (!props.dashboardSummary?.profileIsSetup) {
    await router.push({ name: "CreatorProfilePublic", params: { creatorId: userProfileStore.getViewingUserProfile.sherpaId }, query: { isOnboarding: "true" } });
  }
  isBoundaryModalVisible.value = false;

  isSavingPaidCreatorFields.value = false;
}

// Get CMS editor link ========================================================
function getCmsEditorLink(): any {
  return { name: "OnboardingAdventure", params: { creatorId: userProfileStore.getViewingUserProfile.sherpaId } };
}
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Roadmap ====================================================================
.roadmap {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__snippets-list {
    padding: 0;
    margin: 0 0 25px;
    display: flex;
    list-style: none;
    position: relative;
    z-index: 0;
  }

  &__snippet-wrap {
    width: calc(var(--grid-col-width) * 2 + var(--grid-col-gap-width) * 2);
    position: relative;
    z-index: 0;

    &:nth-child(1) {
      z-index: 4;
    }
    &:nth-child(2) {
      z-index: 3;
    }
    &:nth-child(3) {
      z-index: 2;
    }
    &:nth-child(4) {
      z-index: 1;
    }
  }

  &__stop-snippet {
    position: relative;
    z-index: 1;
  }

  &__connection-line {
    width: 60%;
    height: 6px;
    position: absolute;
    inset: 63px auto auto -30%;
    z-index: 0;

    &--green {
      background: rgba(76, 150, 55, 1);
    }
    &--green-to-teal {
      background: linear-gradient(90deg, rgba(76, 150, 55, 1) 0%, rgba(17, 134, 137, 1) 100%);
    }
    &--gray-dashed {
      &::before {
        content: "";
        width: 100%;
        height: 0;
        border: 2px rgba(0, 0, 0, 0.2) dashed;
        border-width: 2px 0 0 0;
        position: absolute;
        inset: 2px auto auto 0;
      }
    }
  }

  &__footer-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  &__link {
    margin: 10px 15px;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .roadmap {
    width: 100%;

    &__snippets-list {
      width: 100%;
      flex-direction: column;
    }

    &__snippet-wrap {
      width: 100%;
      height: 120px;
      display: flex;
      align-items: center;
    }

    &__connection-line {
      width: 6px;
      height: 40%;
      inset: -25% auto auto 57px;

      &::before {
        width: 0;
        height: 100%;
        inset: 0 auto auto 2px;
        border-width: 0 0 0 2px;
      }
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .roadmap {
    width: 100%;

    &__snippets-list {
      width: 100%;
      flex-direction: column;
    }

    &__snippet-wrap {
      width: 100%;
      height: 120px;
      display: flex;
      align-items: center;
    }

    &__connection-line {
      width: 6px;
      height: 40%;
      inset: -25% auto auto 57px;

      &::before {
        width: 0;
        height: 100%;
        inset: 0 auto auto 2px;
        border-width: 0 0 0 2px;
      }
    }
  }
}

// Creator onboarding page ====================================================
.creator-onboarding-page {
  width: calc(var(--grid-col-width) * 9 + var(--grid-col-gap-width) * 8);
  display: flex;
  flex-direction: column;
  align-items: center;

  &__divider {
    width: 100%;
    height: 0;
    margin-bottom: 50px;
    border-bottom: 1px rgba(0, 0, 0, 0.15) solid;
  }

  &__title {
    width: 100%;
    margin-bottom: 40px;

    &--thin {
      font-weight: 400;
    }

    &--centered {
      text-align: center;
    }
  }

  &__roadmap {
    margin-bottom: 60px;
  }

  &__activity-feed {
    width: calc(var(--grid-col-width) * 6 + var(--grid-col-gap-width) * 5);
    margin-bottom: 40px !important;
  }

  &__stats-circles {
    width: 100%;
  }

  &__travel-boundary-error {
    color: firebrick;
    font-weight: bold;
    margin-right: 12px;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .creator-onboarding-page {
    &__activity-feed {
      width: calc(var(--grid-col-width) * 7 + var(--grid-col-gap-width) * 6);
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .creator-onboarding-page {
    width: calc(var(--grid-content-full-width));

    &__activity-feed {
      width: calc(var(--grid-col-width) * 7 + var(--grid-col-gap-width) * 6);
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .creator-onboarding-page {
    width: calc(var(--grid-content-full-width));

    &__divider {
      margin-bottom: 35px;
    }

    &__title {
      margin-bottom: 30px;
    }

    &__roadmap {
      margin-bottom: 40px;
    }

    &__activity-feed {
      width: 100%;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .creator-onboarding-page {
    width: calc(var(--grid-content-full-width));

    &__divider {
      margin-bottom: 35px;
    }

    &__title {
      margin-bottom: 25px;
    }

    &__roadmap {
      margin-bottom: 40px;
    }

    &__activity-feed {
      width: 100%;
    }

    &__travel-boundary-error {
      margin-right: 0;
      margin-bottom: 5px;
    }
  }
}
</style>
