<template>
  <div class="tabs-section">
    <!-- Tabs itself -->
    <div class="tabs-itself tabs-section__tabs-itself">
      <template v-for="(tabName, index) in props.tabNames">
        <SrpButton
          :class="{
            'tabs-itself__tab': true,
            'tabs-itself__tab--inactive': activeTab !== tabName,
          }"
          size="small"
          :color="activeTab === tabName ? 'teal' : 'gray'"
          @click="activeTab = tabName"
        >
          <template #icon>
            <slot :name="`${index + 1}TabIcon`" />
          </template>

          {{ tabName }}
        </SrpButton>
      </template>
    </div>
    <!-- / Tabs itself -->

    <div class="tabs-section__tabs-content-wrap">
      <div class="tabs-section__tabs-content" v-for="(tabName, index) in props.tabNames">
        <slot v-if="activeTab === tabName" :name="`${index + 1}TabContent`" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from "vue";

// Components
import SrpButton from "@components/ui/SrpButton.vue";

const props = withDefaults(
  defineProps<{
    tabNames: Array<string>;
  }>(),
  {
    tabNames: () => [],
  }
);

const emit = defineEmits<{
  (e: "switchTab", tabName: string, tabIndex: number): void;
}>();

// Switch tabs ================================================================
const activeTab = ref<string>("");

watch(
  () => props.tabNames,
  () => (activeTab.value = props.tabNames[0]),
  { deep: true }
);

onMounted(() => {
  activeTab.value = props.tabNames[0];
});

// Emit tab switch ============================================================
watch(activeTab, () => {
  emit(
    "switchTab",
    activeTab.value,
    props.tabNames.findIndex(t => t === activeTab.value)
  );
});
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";

// Tabs itself ================================================================
.tabs-itself {
  max-width: 100%;
  gap: 0 2px;
  padding: 5px;
  border-radius: 8px;
  display: inline-flex;
  flex-wrap: wrap;
  position: relative;
  background: $faint-blue;

  &__tab {
    pointer-events: none;

    &::before {
      border-radius: 8px;
    }

    &:hover {
      position: relative;
      z-index: 2;
    }

    &--inactive {
      pointer-events: auto;

      &:before {
        border-color: $faint-blue;
        background: $faint-blue;
      }
      &:hover {
        &::before {
          border-color: rgba(201, 207, 210, 1);
          background: rgba(201, 207, 210, 1);
        }
      }
    }
  }
}

// Tabs section ===============================================================
.tabs-section {
  &__tabs-itself {
    margin-bottom: 30px;
  }

  &__tabs-content-wrap {
  }

  &__tabs-content {
  }
}
</style>
