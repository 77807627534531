<template>
  <div v-if="shouldShow" class="ui raised segment">
    <div>
      <h3 class="cms-section-title title">
        You have an active Paid Collaboration!
        <LinkWithIcon class="back-link-section__link" @click="showContactShrpaModal = true" style="margin-left: 10px">
          <template #icon><IconEmbedded name="envelope_2" :size="22" /></template>
          <span>Questions for Shrpa?</span>
        </LinkWithIcon>
      </h3>
      <div>
        <b class="shrpa-orange">Reminder:</b>&nbsp;
        <a href="https://explore.shrpa.com/creating-quality-adventures-for-collaborations" target="_blank" style="text-decoration: underline">Paid Creation Guidelines</a>
      </div>

      <!--Collab iteration-->
      <span v-for="collab in collabs" :key="collab.collabInputId">
        <!--Check if there are adventures for it yet-->
        <div v-if="adventuresCreatedForCollab(collab.collabInputId)" class="custom-content">
          <h3 style="margin-bottom: 5px">{{ collab.communityName }} - {{ collab.collabName }}</h3>
          <!--Creator has an action-->
          <div v-if="collab.correctionsStatus === 'PendingCreator'">
            <div>
              <div class="subsection-title" style="margin-top: 5px">
                <!--Customer requested corrections-->
                <span v-if="collab.correctionsForCreator">
                  <b>Requested changes:</b><br />
                  <div style="white-space: pre-wrap">"{{ collab.correctionsForCreator }}"</div>
                </span>
                <!--Initial Submission-->
                <span v-else>
                  When you're done creating adventures for <b>{{ collab.communityName }}</b
                  >, click below to view and complete your collab.
                </span>
              </div>
              <SrpButton size="tiny" :to="{ name: 'CollabOpportunity', params: { communityId: collab.communityId, collabInputId: collab.collabInputId, creatorId: getViewingUserProfile?.sherpaId } }"
                >View Collab</SrpButton
              >
            </div>

            <div style="margin-top: 10px">
              <router-link
                style="margin-botom: 5px"
                :to="{
                  name: 'CollabOpportunity',
                  params: { communityId: collab.communityId, collabInputId: collab.collabInputId, creatorId: getViewingUserProfile?.sherpaId },
                  hash: '#messages=true',
                }"
              >
                <LinkWithIcon class="back-link-section__link" style="margin-left: 10px">
                  <template #icon><IconEmbedded name="envelope_2" :size="22" /></template>
                  <span>Questions for {{ collab.communityName }}?</span>
                </LinkWithIcon>
              </router-link>
            </div>
          </div>

          <!--No Creator Action-->
          <div v-else>
            <div>
              Thanks for doing the collab!<br />
              We will notify {{ collab.communityName }} and they will review and let you know of any feedback before the adventures are published!
            </div>
          </div>
        </div>
      </span>
    </div>

    <!--Contact Shrpa Modal-->
    <ContactUser
      :visible="showContactShrpaModal"
      :sendToFirstName="'Shrpa'"
      :sendToEmail="'collabs@shrpa.com'"
      :subject="contactShrpaSubject"
      :messagePrefix="contactShrpaSubject"
      @close="showContactShrpaModal = false"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import { CreatorCollabSummary } from "@contracts/collab";
import { ItinerarySummaryCms } from "@contracts/itinerary";
import CopyText from "@components/CopyText.vue";
import ContactUser from "@components/Modals/ContactUser.vue";
import { inject } from "vue";
import SrpModal from "@components/ui/SrpModal.vue";
import { mapState } from "pinia";
import { useUserProfileStore } from "@stores/userProfileStore";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import SrpButton from "@components/ui/SrpButton.vue";

// NOTE!!! This has morphed into just an informational component shown on MyItineraries for active collabs
// All functionality has been moved to the new collab opportunity page.

export default defineComponent({
  name: "CreatorCollabComplete",
  components: {
    IconEmbedded,
    LinkWithIcon,
    SrpModal,
    SrpButton,
    CopyText,
    ContactUser,
  },
  props: {
    adventures: {
      type: Array as () => Array<ItinerarySummaryCms>,
      default: () => [],
    },
  },
  data() {
    return {
      globalLog: inject("globalLog") as any,

      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      shouldShow: false,
      collabs: [] as Array<CreatorCollabSummary>,
      collabInputIdsCreatedFor: [] as Array<string>,

      showContactShrpaModal: false,
    };
  },
  computed: {
    ...mapState(useUserProfileStore, ["getViewingUserProfile", "isSuperOrSalesUser", "isImpersonating"]),
    contactShrpaSubject(): string {
      return `Collab question for Shrpa from ${this.getViewingUserProfile?.firstName}`;
    },
  },
  async mounted() {
    this.loadMyCollabs();
  },
  methods: {
    async loadMyCollabs() {
      this.globalLog.info("Loading my collabs");
      let uri = `${import.meta.env.VITE_API_URL}/collabs/my/active`;
      // Pass through the override from the adventures page
      if (this.isImpersonating === true) {
        uri += `?creatorIdOverride=${this.getViewingUserProfile?.sherpaId}`;
      }
      const { data } = await axios.get(uri);
      this.collabs = data;

      if (this.collabs && this.collabs.length > 0) {
        // Cache the collabInputIds this user has createdFor
        // @ts-ignore
        this.collabInputIdsCreatedFor = (this.adventures as Array<CreatorCollabSummary>)
          .filter(a => a.collabInputId && a.collabInputId.length > 0)
          .map(a => a.collabInputId)
          // Unique only https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
          .filter((v, i, a) => a.indexOf(v) === i);

        // See if they've created any adventures for the community they have a collab for
        var collabInputIds = this.collabs.map(c => c.collabInputId);
        const intersects = this.collabInputIdsCreatedFor.filter(value => collabInputIds.includes(value));
        this.globalLog.info(
          `Collabs: ${collabInputIds.join()} for ${this.collabs.map(c => c.communityId).join()} Adventures for: ${this.collabInputIdsCreatedFor.join()}  Intersects=${intersects.join()}`
        );

        this.shouldShow = intersects.length > 0;
      }
    },
    adventuresCreatedForCollab(collabInputId: string) {
      return this.collabInputIdsCreatedFor.includes(collabInputId);
    },
  },
});
</script>

<style scoped lang="scss">
.title {
  margin: 0 0 16px 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subsection-title {
  margin-bottom: 8px;
}

.custom-content {
  background: #f8f8f8;
  padding: 10px;
  margin-top: 18px;
  border: lightgray solid 1px;
  border-radius: 7px;
}

.saving-text {
  margin-left: 8px;
  display: inline;
}

.adventure-count {
  margin-left: 5px;
  font-weight: bold;
}
</style>
