<template>
  <div class="selected-files-panel">
    <!-- Header -->
    <div class="header selected-files-panel__header">
      <div class="header__left-side">
        <div class="header__title">
          Selected photos & videos <span class="header__title-counter">{{ selectedFiles.length ? selectedFiles.length : "" }}</span>
        </div>
        <!-- Drag & Drop note -->
        <div class="drag-n-drop-note header__drag-n-drop-note">
          <IconEmbedded class="drag-n-drop-note__icon" name="hand-3-fingers_2" />
          Drag & drop to change order
        </div>
        <!-- / Drag & Drop note -->
      </div>

      <LinkWithIcon v-if="props.isPreviewLinkVisible" class="header__preview-link" isDottedUnderline @click="isPreviewModalVisible = true">
        <template #icon><IconEmbedded name="grid_2" :size="19" /></template>
        <span>Preview</span>
      </LinkWithIcon>
    </div>
    <!-- / Header -->

    <div
      :class="{
        'selected-files-panel__thumbnails-list-wrap': true,
        'selected-files-panel__thumbnails-list-wrap--with-left-curtain': isLeftCurtainVisible,
        'selected-files-panel__thumbnails-list-wrap--with-right-curtain': isRightCurtainVisible,
      }"
    >
      <Draggable class="selected-files-panel__thumbnails-list" v-model="_selectedFiles" :itemKey="i => i.serverId" @scroll="defineCurtainsVisibility" ref="domRefScrollableContainer">
        <template #item="{ element }">
          <SrpFileThumbnail class="selected-files-panel__thumbnail" :fileName="element.serverId" :thumbSize="'thumb-tiny'" isRemoveButton @remove="serverId => emit('remove', serverId)" />
        </template>
      </Draggable>
    </div>

    <SrpModal v-model:isVisible="isPreviewModalVisible" isWithScroll>
      <template #header>
        <h2 class="global-h2">This Stop's Photo and Videos</h2>
        <div>Note: The layout can change for different screen sizes.</div>
      </template>
      <template #content>
        <!--Note: The "how many columns" logic matches what we use in StopsMap.vue-->
        <SrpMasonry :items="_selectedFiles" :columns="_selectedFiles?.length > 10 ? 3 : 2" :columnGap="screenSize === 'mobile' ? 9 : 11" customKey="serverId" style="margin-bottom: -10px">
          <template #default="{ item, index, rebuildMasonry }">
            <SrpFileThumbnail :fileName="item.serverId" style="margin-bottom: 10px" :isFixedAspectRatio="false" @imageLoad="rebuildMasonry" />
          </template>
        </SrpMasonry>
      </template>
    </SrpModal>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, Ref, onMounted, nextTick, watch, inject } from "vue";

// Types
import { ScreenSize } from "@contracts/screenSize";
import { UploadedImage } from "@contracts/uploadedImage";

// Components
import Draggable from "vuedraggable";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import SrpFileThumbnail from "@components/ui/SrpFileThumbnail.vue";
import SrpMasonry from "@components/ui/SrpMasonry.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

// Global variables
const screenSize = inject("screenSize") as Ref<ScreenSize>;

const emit = defineEmits<{
  (e: "remove", fileName: string): void;
  (e: "reorder", newArray: Array<UploadedImage>): void;
}>();

export interface Props {
  selectedFiles: Array<UploadedImage>;
  isPreviewLinkVisible?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  selectedFiles: () => [],
  isPreviewLinkVisible: true,
});

// Mirror selectedFiles =======================================================
const _selectedFiles = computed<Array<UploadedImage>>({
  get: () => props.selectedFiles,
  set: newArray => {
    emit("reorder", newArray);
  },
});

// Toggle curtains ============================================================
const isLeftCurtainVisible = ref(false);
const isRightCurtainVisible = ref(false);

const domRefScrollableContainer = ref(null);

function defineCurtainsVisibility(event, element?) {
  const target = event ? event?.target : element;
  isLeftCurtainVisible.value = target.scrollLeft > 10;
  isRightCurtainVisible.value = target.scrollLeft < target.scrollWidth - 10;
}

onMounted(async () => {
  await nextTick();
  defineCurtainsVisibility(null, domRefScrollableContainer.value?.$el);
});

watch(
  () => props.selectedFiles,
  () => {
    defineCurtainsVisibility(null, domRefScrollableContainer.value?.$el);
  }
);

// Scroll the list to the end on image add ====================================
watch(
  () => props.selectedFiles.length,
  async (newVal, oldVal) => {
    if (newVal > oldVal) {
      await nextTick(); // wait for the new thumbnail to render
      domRefScrollableContainer.value.targetDomElement.scrollLeft = domRefScrollableContainer.value.targetDomElement.scrollWidth;
    }
  }
);

// Toggle preview modal =======================================================
const isPreviewModalVisible = ref(false);
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Drag & Drop note ===========================================================
.drag-n-drop-note {
  display: flex;
  align-items: center;
  color: #5b5b5b;
  font-family: sans-serif;

  &__icon {
    width: 21px;
    margin-right: 5px;

    :deep(svg) {
      color: rgba(0, 0, 0, 0.5);
    }
  }
}

// Header =====================================================================
.header {
  display: flex;
  align-items: center;

  &__left-side {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  &__title {
    margin-right: 23px;
    font-weight: bold;
    font-family: sans-serif;
  }

  &__title-counter {
    font-weight: normal;
    color: #5b5b5b;
  }

  &__drag-n-drop-note {
  }

  &__preview-link {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .header {
    align-items: flex-start;

    &__left-side {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .header {
    align-items: flex-start;

    &__left-side {
      flex-direction: column;
      align-items: flex-start;
    }

    &__drag-n-drop-note {
      margin-left: -4px;
    }
  }
}

// Selected files panel =======================================================
.selected-files-panel {
  padding: 12px 20px 3px;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    width: calc(100% + 50px);
    height: calc(100% + 20px);
    position: absolute;
    inset: auto auto 0 -26px;
    z-index: -2;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 80%, rgba(255, 255, 255, 0) 100%);
    pointer-events: none;
  }

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px #b4b4b4 solid;
    border-radius: 6px;
    position: absolute;
    inset: auto auto 0 0;
    z-index: -1;
    background: #f3f3f3;
  }

  &__header {
    margin-bottom: 4px;
  }

  &__thumbnails-list-wrap {
    position: relative;

    &::before,
    &::after {
      content: "";
      width: 15px;
      height: calc(100% - 60px);
      position: absolute;
      top: 50px;
      z-index: 3;
      opacity: 0;
      background: rgba(255, 0, 0, 0.3);
      transition: opacity 0.07s ease-in-out;
      pointer-events: none;
    }

    &::before {
      left: 0;
      background: linear-gradient(90deg, rgba(#f3f3f3, 1) 0%, rgba(#f3f3f3, 0) 100%);
    }
    &::after {
      right: 0;
      background: linear-gradient(90deg, rgba(#f3f3f3, 0) 0%, rgba(#f3f3f3, 1) 100%);
    }

    &--with-left-curtain {
      &::before {
        opacity: 1;
      }
    }
    &--with-right-curtain {
      &::after {
        opacity: 1;
      }
    }
  }

  &__thumbnails-list {
    width: 100%;
    height: 136px;
    padding: 56px 0 0;
    margin: -50px 0 0;
    display: flex;
    align-items: flex-start;
    position: relative;
    overflow-x: scroll;
    scrollbar-width: thin; /* Firefox */
    scrollbar-gutter: stable;
    scrollbar-color: rgba(black, 0.25) transparent;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      width: 7px !important;
      height: 7px !important;
    }

    &::-webkit-scrollbar-track {
      background: #f3f3f3;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      background-color: rgba(black, 0.25);
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(black, 0.35);
    }
  }

  &__thumbnail {
    width: 90px;
    min-width: 90px !important;
    margin-right: 10px;
    cursor: grab;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .selected-files-panel {
    padding-bottom: 25px;

    &::before {
      width: 100%;
    }
  }
}
</style>
