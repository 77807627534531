<template>
  <ul
    :class="{
      'highlights-section': true,
      'highlights-section--small': size === 'sm',
    }"
    :style="`
      --highlights-section-line-height: ${thumbnailHeights[props.size]}px;
      --highlights-section-max-height: ${thumbnailHeights[props.size] * props.visibleLines + 5 * (props.visibleLines - 1)}px;
    `"
  >
    <SrpFileThumbnail
      class="highlights-section__thumbnail"
      :thumbSize="size === 'sm' ? 'thumb-tiny' : 'thumb-med'"
      v-for="file in highlightAssetIds"
      :key="file"
      :fileName="file"
      :galleryPostfix="randomGalleryPostfix"
      :isFixedAspectRatio="false"
      preloaderMinWidth="110px"
    />
    <template v-if="placeholders.length">
      <SrpFileThumbnail
        class="highlights-section__thumbnail"
        :thumbSize="size === 'sm' ? 'thumb-tiny' : 'thumb-med'"
        v-for="(placeholder, idx) in placeholders"
        :key="`${placeholder}-${idx}`"
        :placeholderType="placeholder"
        :isFixedAspectRatio="false"
        preloaderMinWidth="110px"
        :style="{
          width: `${Math.floor(Math.random() * ((props.size === 'sm' ? 130 : 240) - 30 + 1)) + 30}px`,
          minWidth: `${props.size === 'sm' ? 40 : 80}px`,
          maxWidth: `${{ sm: 130, default: 240 }[props.size]}px`,
        }"
      />
    </template>
  </ul>
</template>

<script setup lang="ts">
import { computed, inject, Ref } from "vue";

// Components
import SrpFileThumbnail from "@components/ui/SrpFileThumbnail.vue";

// Types
import { ScreenSize } from "@contracts/screenSize";

type SectionSize = "sm" | "default";

const props = withDefaults(
  defineProps<{
    highlightAssetIds: Array<string> | null;
    padPlaceholders?: number;
    size?: SectionSize;
    visibleLines?: number;
  }>(),
  {
    padPlaceholders: 0,
    highlightAssetIds: () => [],
    size: "default",
    visibleLines: 2,
  }
);

// Injections =================================================================
const screenSize = inject("screenSize") as Ref<ScreenSize>;

// Calc thumbnail height ======================================================
// Optional chaining on the screenSize is needed for the Storybook
const smThumbnailHeight = computed<number>(() => (screenSize?.value === "mobile" ? 40 : 60));
const defaultThumbnailHeight = computed<number>(() => (screenSize?.value === "mobile" ? 90 : 130));
// const bigThumbnailHeight = computed<number>(() => (screenSize?.value === "mobile" ? 0 : 0));

const thumbnailHeights = computed<{ [key in SectionSize]: number }>(() => ({
  sm: smThumbnailHeight.value,
  default: defaultThumbnailHeight.value,
}));

// Random gallery postfix =====================================================
const randomGalleryPostfix = "-" + Array(3).join(Math.random().toString(36).slice(2));

// Add padding placeholders based on highlights length and padPlaceholders ====
const placeholders = computed<("image" | "video")[]>(() => {
  if (props.highlightAssetIds.length >= props.padPlaceholders) return [];

  return [...Array(props.padPlaceholders - props.highlightAssetIds.length)].map((_, idx) => (idx % 3 === 0 ? "image" : "video"));
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Highlights section =========================================================
.highlights-section {
  max-height: var(--highlights-section-max-height);
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  outline: 1px var(--some-color) solid;

  &__thumbnail {
    flex-grow: 1;
    max-width: 270px;
    height: var(--highlights-section-line-height);
    margin: 0 5px 5px 0;

    :deep(.file-thumbnail__fancybox-button) {
      width: 100%;
      height: 100%;
      transform: scale(1) !important;
      opacity: 0;
    }

    :deep(.file-thumbnail__bg-image) {
      width: auto !important;
      flex-grow: 1 !important;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .highlights-section {
    &__thumbnail {
    }
  }
}
</style>
