<template>
  <div v-if="org && org.name">
    <div class="organization">
      <a lang="en" hreflang="en" v-if="org.siteUrl" :href="buildWebsiteLink(org.siteUrl)" target="_blank" class="ui header">{{ org.name }}</a>
      <template v-else>{{ org.name }}</template>
    </div>
    <a lang="en" hreflang="en" v-if="org.orgImage" :href="buildWebsiteLink(org.siteUrl)" target="_blank">
      <img class="ui tiny image right floated" :src="`${contentBaseUri}/cms/images/orgs/${org.orgImage}`" />
    </a>
  </div>
</template>

<script>
export default {
  name: "OrgSummary",
  
  props: {
    org: {
      required: true,
    },
  },
  
  data() {
    return {
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
    };
  },
  
  methods: {
    buildWebsiteLink(link) {
      // This logic is repeated in a few places
      if (!link) return "";
      if (!link.trim().startsWith("http")) link = "https://" + link.trim();

      return link;
    },
  },
};
</script>
