export interface DomNodeClientRect {
  width: number;
  height: number;
  top: number;
  left: number;
}
export default function checkIfBlocksIntersect(block1: DomNodeClientRect, block2: DomNodeClientRect): boolean {
  if (!block1 || !block2) {
    console.error("checkDoBlocksIntersect: invalid blocks values");
  }

  const isOverlapByX = block1.left < block2.left + block2.width && block1.left + block1.width > block2.left;
  const isOverlapByY = block1.top < block2.top + block2.height && block1.top + block1.height > block2.top;

  return isOverlapByX && isOverlapByY;
}
