import { CollabValueFields } from "@contracts/collab";

export default {
  // NOTE: This is in some other places also (if changing, do a search both client and server)
  HotelCoverageFromShrpa: 250,

  overallCollabValue(collabInput: CollabValueFields, creatorCurrentRate: number, other: number): number {
    return this.totalPaymentFromShrpa(collabInput, creatorCurrentRate, other) + this.estimatedActivityValue(collabInput) + this.estimatedLodgingValue(collabInput);
  },

  totalPaymentFromShrpa(collabInput: CollabValueFields, creatorCurrentRate: number, other): number {
    let total = creatorCurrentRate + other;
    // Note: Showing this even if they don't need a hotel (since they haven't applied yet and set that field)
    if (collabInput.hotelNightsCovered === 0) total += this.HotelCoverageFromShrpa;
    return total;
  },

  estimatedActivityValue(collabInput: CollabValueFields): number {
    // Logic is also in CollabInputValidator (and the inverse in CreateCollab)
    if (collabInput.requestingBigTicketItems === true || collabInput.compedActivitiesTags.length > 0) return collabInput.compedActivitiesEstimatedValue ?? 0;
    return 0;
  },
  estimatedLodgingValue(collabInput: CollabValueFields): number {
    if (collabInput.hotelNightsCovered > 0) return collabInput.hotelEstimatedValue ?? 0;
    return 0;
  },
};
