<template>
  <li class="time-frame-divider">
    <div class="time-frame-divider__divider-container">
      <span class="time-frame-divider__text">
        <slot />
      </span>
    </div>

    <ul v-if="Object.values(props.eventsStats).reduce((acc, val) => acc + val, 0) > 0" class="time-frame-divider__stats">
      <template v-for="key in Object.keys(props.eventsStats)" :key="key">
        <EventTypeTag
          v-if="props.eventsStats[key] > 0"
          class="time-frame-divider__stat-snippet"
          :color="
            (
              {
                CollabCompleted: 'yellow',
                CollabPosted: 'green',
                NewPaidCreator: 'purple',
                NewDestination: 'blue',
              } as Record<string, 'blue' | 'green' | 'yellow' | 'purple'>
            )[key]
          "
        >
          <!-- Stat snippet -->
          <li class="stat-snippet">
            <div class="stat-snippet__number">{{ props.eventsStats[key] }}&nbsp;</div>
            <div class="stat-snippet__text">
              {{ generateEventNames(key as keyof EventStats, props.eventsStats[key]) }}
            </div>
          </li>
          <!-- / Stat snippet -->
        </EventTypeTag>
      </template>
    </ul>
  </li>
</template>

<script setup lang="ts">
import { onMounted } from "vue";

// Components
import EventTypeTag from "@components/ActivityFeed/EventTypeTag.vue";

const props = withDefaults(
  defineProps<{
    eventsStats: EventStats;
  }>(),
  {
    eventsStats: null,
  }
);

export interface EventStats {
  CollabCompleted: number;
  CollabPosted: number;
  NewPaidCreator: number;
  NewDestination: number;
}

// Generate event names =======================================================
function generateEventNames(type: keyof EventStats, count: number): string {
  if (type === "CollabCompleted") return count === 1 ? "Completed Collab" : "Completed Collabs";
  if (type === "CollabPosted") return count === 1 ? "Posted Collab" : "Posted Collabs";
  if (type === "NewPaidCreator") return count === 1 ? "New Creator Joined" : "New Creators Joined";
  if (type === "NewDestination") return count === 1 ? "New Destination" : "New Destinations";
}
</script>

<style scoped lang="scss">
// Stat snippet ===============================================================
.stat-snippet {
  display: flex;
  align-items: center;

  &__number {
    color: rgba(0, 0, 0, 1);
    font:
      700 14px/14px "Quicksand",
      sans-serif;
  }

  &__text {
  }
}

// Time frame divider =========================================================
.time-frame-divider {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__divider-container {
    width: 100%;
    margin-bottom: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: "";
      width: 100%;
      height: 0;
      border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
      position: absolute;
      inset: 50% auto auto 0;
      z-index: -1;
      font-size: 0;
      line-height: 0;
    }
  }

  &__text {
    padding: 0 10px;
    display: flex;
    color: #5b5b5b;
    font: 14px/14px sans-serif;
    background: white;
  }

  &__stats {
    padding: 0;
    gap: 10px;
    margin: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    list-style: none;
  }

  &__stat-snippet {
  }
}
</style>
