<template>
  <div class="thumbnails-carousel">
    <CircleWithIconBtn
      v-if="curtainsVisibility.isLeft.value"
      class="thumbnails-carousel__arrow-btn thumbnails-carousel__arrow-btn--left"
      iconName="caret-left_4-5"
      size="big"
      color="white"
      :iconSize="14"
      @click="handleArrowClick('left')"
    />
    <CircleWithIconBtn
      v-if="curtainsVisibility.isRight.value"
      class="thumbnails-carousel__arrow-btn thumbnails-carousel__arrow-btn--right"
      iconName="caret-right_4-5"
      size="big"
      color="white"
      :iconSize="14"
      iconInset="auto -1px auto auto"
      @click="handleArrowClick('right')"
    />

    <div
      :class="{
        'thumbnails-carousel__thumbnails-wrap-1': true,
        'thumbnails-carousel__thumbnails-wrap-1--left-curtain': curtainsVisibility.isLeft.value,
        'thumbnails-carousel__thumbnails-wrap-1--right-curtain': curtainsVisibility.isRight.value,
        'thumbnails-carousel__thumbnails-wrap-1--both-curtains': curtainsVisibility.isLeft.value && curtainsVisibility.isRight.value,
      }"
    >
      <ul
        class="thumbnails-carousel__thumbnails-wrap-2"
        ref="domRefThumbnailsWrap2"
        :style="{
          gap: `${props.gap}px`,
        }"
        @scroll="
          $event => {
            scrollPosition = ($event.target as HTMLElement).scrollLeft;
            scrollWidth = ($event.target as HTMLElement).scrollWidth;
          }
        "
      >
        <SrpFileThumbnail
          :class="{
            'thumbnails-carousel__thumbnail': true,
            'thumbnails-carousel__thumbnail--highlighted': !props.fancyboxPrefix && thumbnailId === _selectedImageId,
          }"
          :style="{
            width: `${props.thumbnailSize.width}px`,
            minWidth: `${props.thumbnailSize.width}px !important`,
            height: `${props.thumbnailSize.height}px`,
          }"
          v-for="thumbnailId in props.thumbnailsList"
          :key="thumbnailId"
          thumb-size="thumb-tiny"
          :isFixedAspectRatio="true"
          :fileName="thumbnailId"
          @click="_selectedImageId = thumbnailId"
          :galleryPostfix="props.fancyboxPrefix"
          isEntireAreaClickable
        />
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, nextTick, watch, inject, Ref } from "vue";

// Components
import CircleWithIconBtn from "@components/ui/CircleWithIconBtn.vue";
import SrpFileThumbnail from "@components/ui/SrpFileThumbnail.vue";

// Composables
import useScrollCurtains from "@composables/useScrollCurtains";

// Types
import { ScreenSize } from "@contracts/screenSize";

const props = withDefaults(
  defineProps<{
    thumbnailSize?: { width: number; height: number };
    gap?: number;
    thumbnailsList: Array<string>;
    fancyboxPrefix?: string;
  }>(),
  {
    thumbnailSize: () => ({ width: 65, height: 47 }),
    gap: 5,
    thumbnailsList: () => [],
    fancyboxPrefix: "",
  }
);

const emit = defineEmits<{
  (e: "selectImage", imageId: string): void;
}>();

// Global variables & injections ==============================================
const screenSize = inject("screenSize") as Ref<ScreenSize>;

// Selected image id ==========================================================
const _selectedImageId = ref<string>(null);

watch(_selectedImageId, () => emit("selectImage", _selectedImageId.value));

// Switch gallery carousel ====================================================
const domRefThumbnailsWrap2 = ref<HTMLElement>(null);

watch(_selectedImageId, scrollCarouselToActiveThumbnail);
function scrollCarouselToActiveThumbnail() {
  const oneThumbnailWidth = domRefThumbnailsWrap2.value?.scrollWidth / props.thumbnailsList.length;
  const activeThumbnailIndex = props.thumbnailsList.findIndex(t => t === _selectedImageId.value);

  if (domRefThumbnailsWrap2.value) {
    domRefThumbnailsWrap2.value.scrollLeft = (activeThumbnailIndex - 1) * oneThumbnailWidth;
  }
}

// Handle arrow click =========================================================
function handleArrowClick(direction: "left" | "right") {
  const activeThumbnailIndex = props.thumbnailsList.findIndex(t => t === _selectedImageId.value);

  if (direction === "left") {
    const lastIndex = props.thumbnailsList.length - 1;
    const previousIndex = props.thumbnailsList[activeThumbnailIndex - 1] ? activeThumbnailIndex - 1 : lastIndex;
    _selectedImageId.value = props.thumbnailsList[previousIndex];
  } else if (direction === "right") {
    const nextIndex = props.thumbnailsList[activeThumbnailIndex + 1] ? activeThumbnailIndex + 1 : 0;
    _selectedImageId.value = props.thumbnailsList[nextIndex];
  }
}

onMounted(async () => {
  await nextTick();
  scrollCarouselToActiveThumbnail();
});

// Curtains toggle threshold ==================================================
const curtainsToggleThreshold = 10;

// Track the scroll position (to set the curtains visibility) =================
const scrollContainerWidth = ref<number>(0);
const scrollPosition = ref<number>(0);
const scrollWidth = ref<number>(0);

onMounted(async () => {
  await nextTick();
  setScrollContainerParams();
});

watch(screenSize, async () => {
  await nextTick();
  setScrollContainerParams();
});

function setScrollContainerParams() {
  scrollContainerWidth.value = domRefThumbnailsWrap2.value.getBoundingClientRect().width;
  scrollPosition.value = domRefThumbnailsWrap2.value.scrollLeft;
  scrollWidth.value = domRefThumbnailsWrap2.value.scrollWidth;
}

// Set curtains visibility ====================================================
const curtainsVisibility = useScrollCurtains(domRefThumbnailsWrap2);

watch(
  () => props.thumbnailsList,
  async () => {
    await nextTick();
    curtainsVisibility.setCurtainsVisibility();
  }
);
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Thumbnails carousel ========================================================
.thumbnails-carousel {
  height: 47px;
  display: flex;
  align-items: flex-start;
  position: relative;

  &__arrow-btn {
    position: absolute !important;
    inset: 8px auto auto auto;
    z-index: 2;

    &--left {
      left: -13px;
    }
    &--right {
      right: -13px;
    }
  }

  &__thumbnails-wrap-1 {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    &--left-curtain {
      mask-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 1) 12%);
    }
    &--right-curtain {
      mask-image: linear-gradient(90deg, rgba(0, 0, 0, 1) 88%, rgba(0, 0, 0, 0) 99%);
    }
    &--both-curtains {
      mask-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 1) 12%, rgba(0, 0, 0, 1) 88%, rgba(0, 0, 0, 0) 99%) !important;
    }
  }

  &__thumbnails-wrap-2 {
    width: 100%;
    max-width: 100%;
    padding: 0 !important;
    margin: 0;
    display: flex;
    align-items: flex-end;
    overflow-x: scroll;
    overflow-y: hidden;
    list-style: none;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.25);
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(0, 0, 0, 0.35);
    }
  }

  &__thumbnail {
    cursor: pointer;

    :deep(.file-thumbnail__thumbnail-wrap) {
      border-radius: 4px;
      background: rgba(200, 207, 210, 1);
    }

    &--highlighted {
      outline: 3px rgba(17, 134, 137, 1) solid;
      outline-offset: -3px;
      cursor: default;

      :deep(.file-thumbnail__thumbnail-wrap) {
        outline: 3px rgba(17, 134, 137, 1) solid;
        outline-offset: -3px;
      }
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .thumbnails-carousel {
    &__thumbnail {
      min-width: 50px !important;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .thumbnails-carousel {
    &__thumbnail {
      min-width: 50px !important;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .thumbnails-carousel {
    &__thumbnail {
      min-width: 50px !important;
    }
  }
}
</style>
