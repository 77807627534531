<template>
  <!--Note: Hide/show is owned by the parent since the buttons firing this live there-->
  <SrpModal :isVisible="visible" @close="close" :isClosable="false" className="new-dates-modal-message" isWithScroll>
    <template #header>
      <h2 class="global-h2">
        <div class="header-margin-override">Propose a new Visit Date?</div>
      </h2>
    </template>
    <template #content>
      <div>
        <div v-if="dateChangeProposalString" class="ui info message">
          <b>You've proposed changing the visit date to {{ dateChangeProposalString }}</b>
        </div>
        <h3 class="ui small header">The Visit Date is currently {{ currentVisitDateString }}</h3>
        <div style="margin-bottom: 20px; margin-left: 10px">- Preferred Timing was originally {{ originalPreferredTiming }}</div>

        <b>Propose a new Visit Date:</b>
        <CollabDatePicker :collabInput="proposedDates" :resetCounter="collabDateResetCounter" @dateChanged="collabDateChanged" style="max-width: 350px; display: flex" />

        <NoteWithIcon size="tiny" style="margin-bottom: 22px; margin-top: 22px">
          <template #icon><IconEmbedded name="bulb_2-5" :size="17" /></template>
          <template #text>
            Destinations invest considerable effort in coordinating collaboration details.<br />
            <b>Last-minute date change requests can impact your reputation and cause significant inconvenience for the destination.</b>
          </template>
        </NoteWithIcon>

        <div style="margin-top: 13px">
          <b>Change Reason: <span v-if="showRequired" style="color: red">Required</span></b>
        </div>
        <textarea
          v-model="proposedDates.changeReason"
          autocapitalize="sentences"
          :placeholder="`Tell the ${recipientText} why you would like to adjust the date`"
          style="height: 4.5em; min-height: 2em"
        ></textarea>

        <div style="margin-top: 25px">
          After submitting, the {{ recipientText }} will be notified via email and can accept or decline the proposed change.<br />
          If you have any questions, reach out to <CopyText :text="'collabs@shrpa.com'"></CopyText>
        </div>
      </div>
    </template>
    <template #footer>
      <div>
        <h3 v-if="saving" style="display: inline; margin-right: 8px">Saving...</h3>
        <SrpButton @click="close" color="gray" fill="outlined" style="margin-right: 10px">Cancel</SrpButton>
        <SrpButton :isDisabled="saving || !isDirty" @click="save()">Submit</SrpButton>
      </div>
    </template>
  </SrpModal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import { RouteHelper } from "@helpers/RouteHelper";
import CopyText from "@components/CopyText.vue";
import CollabDatePicker from "@views/PaidCollab/CollabDatePicker.vue";
import { CollabInput, CollabCreatorInput, CollabDates } from "@contracts/collab";
import DateUtils from "@logic/DateUtils";
import { PropType } from "vue";
import SrpModal from "@components/ui/SrpModal.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export default defineComponent({
  name: "CollabDateChangeProposal",

  components: {
    IconEmbedded,
    SrpButton,
    SrpModal,
    CopyText,
    CollabDatePicker,
    NoteWithIcon,
  },

  props: {
    visible: { type: Boolean, required: true },
    isCreatorFlow: { type: Boolean, required: true },
    currentVisitDateString: { type: String, required: true },
    // If they've already proposed a new date
    dateChangeProposalString: {
      // Without this we were getting a "null is not Null" warning from Vue
      type: null as unknown as PropType<string | null>,
      validator: (v: any) => typeof v === "string" || v === null,
      required: true,
    },
    originalPreferredTiming: { type: String, required: true },
    collabInput: { type: Object as () => CollabInput | null, required: false, default: null },
  },

  emits: ["update:visible", "close"],

  data() {
    return {
      proposedDates: {
        preferredDateRangeStart: null,
        preferredDateRangeEnd: null,
        changeReason: null,
      } as CollabDates,
      collabDateResetCounter: 1,
      isDirty: false,
      saving: false,
      showRequired: false,
    };
  },

  computed: {
    recipientText(): string {
      return this.isCreatorFlow ? "community" : "creator";
    },
  },

  methods: {
    collabDateChanged(dateRange: Array<Date>) {
      this.isDirty = dateRange && dateRange[0] != null;
      // not used: this.dateRange = dateRange;
    },
    async save() {
      this.showRequired = false;
      if (this.proposedDates?.preferredDateRangeStart === null) return;
      if (this.proposedDates.changeReason == null || this.proposedDates.changeReason.trim().length === 0) {
        this.showRequired = true;
        return;
      }

      this.saving = true;
      const config = { headers: { "Content-Type": "application/json" } };
      const uri = `${import.meta.env.VITE_API_URL}/collab-date-change/${this.collabInput.customerId}/inputs/${this.collabInput.id}?creatorProposed=${this.isCreatorFlow}`;
      const { data } = await axios.post(uri, JSON.stringify(this.proposedDates), config);
      this.collabInput.proposedDateChange = data;
      this.saving = false;

      this.close();
    },
    close() {
      this.resetDatePicker();
      this.$emit("close");
    },
    resetDatePicker() {
      this.isDirty = false;
      this.collabDateResetCounter++;
    },
  },
});
</script>

<style scoped lang="scss">
.new-dates-modal-message {
  .action-buttons {
    margin-top: 3rem;
  }

  /* Some pages apply a broad header margin, which we don't want here (ex. featured-expeditions) */
  .header-margin-override {
    margin-top: 0;
  }

  .ui.small.info.message h3 {
    margin-bottom: 0.5rem !important;
  }
}
</style>
