<template>
  <div class="ui segment">
    <h2>This is part of a Paid Collaboration</h2>
    <h3 style="display: inline; margin-top: 5px">{{ collab.communityName }} - {{ collab.collabName }}</h3>
    <SrpButton
      class="view-collab-button"
      size="tiny"
      @click="emit('click:viewCollab')"
      >View Collab</SrpButton
    >
    <div style="font-size: 1.1em; margin-top: 15px">
      Find all of Shrpa's collab expectations: <a :href="Links.ExternalLinks.CreatorCollabAdvExp" target="_blank" style="text-decoration: underline">{{ Links.ExternalLinks.CreatorCollabAdvExp }}</a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CreatorCollabSummary } from "@contracts/collab";
import { useUserProfileStore } from "@stores/userProfileStore";
import Links from "@logic/ExternalLinks";
import SrpButton from "@components/ui/SrpButton.vue";

defineProps<{
  collab: CreatorCollabSummary;
}>();
const emit = defineEmits<{
  (e: 'click:viewCollab'): void;
}>();

const userProfileStore = useUserProfileStore();
</script>

<style scoped lang="scss">
.view-collab-button {
  margin-inline-start: 15px;
}
</style>
