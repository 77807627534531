import { ItinerarySummaryCms } from "@contracts/itinerary";

export default {
  isDraft(status: string): boolean {
    const draft = status === "Draft";
    return draft;
  },
  isLive(status: string): boolean {
    const draft = status === "Live";
    return draft;
  },

  isPendingReview(subStatus: string): boolean {
    var pending = subStatus === "PendingReview";
    return pending;
  },

  getPreviewLink(itinerary: ItinerarySummaryCms) {
    var queryParams = {};
    if (!this.isLive(itinerary.status)) {
      // @ts-ignore
      queryParams.status = itinerary.status;
    }
    return { name: "ItineraryPreview", params: { itineraryId: itinerary.uniqueName }, query: queryParams };
  },
};
