export class RouteHelper {
  static getQueryStringParam(inputKey: string): string | null {
    if (window.location.search && window.location.search.length > 0) {
      const queryString = window.location.search.substring(1);
      var searchParams = new URLSearchParams(queryString);
      let result = null as string;
      // NOTE! The checks seem to be case sensitive so we need to correct that
      const keyToFind = inputKey.toLowerCase();
      searchParams.forEach((value, key) => {
        if (key.toLowerCase() === keyToFind) result = value;
      });
      return result;
    }
    return null;
  }

  // Gets the first hash value to match the input key
  static getHashParam(inputKey: string): string | null {
    var result = this.getHashParamMultiple(inputKey);
    if (result?.length > 0) {
      return result[0];
    }
    return null;
  }

  // Gets values from the hash
  static getHashParamMultiple(inputKey: string): Array<string> {
    const result = [] as Array<string>;
    if (window.location.hash && window.location.hash.length > 1) {
      const queryString = window.location.hash.substring(1).toLowerCase();
      var searchParams = new URLSearchParams(queryString);
      // NOTE! The checks seem to be case sensitive so we need to correct that
      const keyToFind = inputKey.toLowerCase();
      searchParams.forEach((value, key) => {
        if (key.toLowerCase() === keyToFind) result.push(value);
      });
    }
    return result;
  }

  static getQueryStringOrHashParam(key: string): string | null {
    let result = RouteHelper.getQueryStringParam(key);
    if (!result) {
      result = RouteHelper.getHashParam(key);
    }
    return result;
  }
}
