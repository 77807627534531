<template>
  <div class="media-thumbnail">
    <label
      v-if="Object.keys(slots).length"
      :class="{
        'media-thumbnail__checkbox-label': true,
        'media-thumbnail__checkbox-label--covers-entire-thumbnail': props.isInputCoversImage,
      }"
    >
      <slot />
      <span class="media-thumbnail__border-layer"></span>
    </label>

    <IconEmbedded v-if="props.isOpenFullButtonVisible" class="media-thumbnail__fullsize-button" name="maximize_2" @click="reassignClickToImage" />

    <div v-if="checkIsVideo(props.mediaUrl)" class="media-thumbnail__video-wrap">
      <VideoRender :videoId="props.mediaUrl" class="media-thumbnail__video" :showControls="false" showViewerOnClick />
    </div>
    <div v-else class="media-thumbnail__img-wrap">
      <!--Note: src is the thumbnail and then the image display library is smart enough to use the href to pull the full res when showing full-screen-->
      <a class="media-thumbnail__img-a" lang="en" hreflang="en" data-fancybox="gallery" :href="`${contentBaseUri}/cms/images/expeditions/${props.mediaUrl}`">
        <img class="media-thumbnail__img" :src="`${contentBaseUri}/cms/images/expeditions/thumbnocrop/${props.mediaUrl}`" alt="Photo" ref="refImage" />
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSlots, ref } from "vue";

export interface Props {
  mediaUrl: string;
  isInputCoversImage?: boolean;
  isOpenFullButtonVisible?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  mediaUrl: "",
  isInputCoversImage: false,
  isOpenFullButtonVisible: false,
});

const slots = useSlots();

import FileUtils from "@logic/FileUtils";

// Components
import VideoRender from "@components/VideoRender.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

const contentBaseUri = globalThis.Bootstrap.Config.contentCdnBaseUri;

function checkIsVideo(assetName: string): boolean {
  return FileUtils.isVideoFileType(assetName, null);
}

// Reassign click to image ====================================================
const refImage = ref(null);

function reassignClickToImage() {
  refImage.value.click();
}
</script>

<style scoped lang="scss">
// Media thumbnail checkbox ===================================================
.media-thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;

  &__checkbox-label {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: 3;
    cursor: pointer;
    user-select: none;

    &--covers-entire-thumbnail {
      width: 100%;
      height: 100%;
    }

    &::before {
      content: "";
      width: 31px;
      height: 31px;
      border-radius: 3px;
      position: absolute;
      inset: 5px auto auto 5px;
      z-index: 2;
      background: rgba(255, 255, 255, 0.5);
      //backdrop-filter: blur(10px); // Caused issues when lots of photos
      cursor: pointer;
      user-select: none;
    }

    :deep(input) {
      width: 15px;
      height: 15px;
      position: absolute;
      inset: 13px auto auto 13px;
      z-index: 3;
      cursor: pointer;
    }
  }

  &__fullsize-button {
    width: 31px;
    height: 31px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 5px 5px auto auto;
    z-index: 4;
    transform: scale(1);
    color: #fff;
    font-size: 23px;
    line-height: 23px;
    //background: rgba(0, 0, 0, 0.3);
    //backdrop-filter: blur(10px); // Caused issues when lots of photos
    transition: transform 0.07s ease-in-out;
    cursor: pointer;

    &:hover {
      transform: scale(1.2);
    }
  }

  &__border-layer {
  }

  &__video-wrap {
  }

  &__video {
  }

  &__img-wrap {
    width: 100%;
    height: 100%;
  }

  &__img-a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__img {
    width: 100%;
    height: 100%;
    margin: 0;
    object-fit: cover;
  }
}
</style>
