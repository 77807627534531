<template>
  <div class="creator-avatar">
    <!--<div v-if="isWithPlusIcon" class="creator-avatar__plus-icon-wrap">
      <img class="creator-avatar__plus-icon" :src="`${contentBaseUri}/images/misc/badges/shrpa-plus.svg`" alt="Collab Eligible" />
    </div>-->
    <img class="creator-avatar__avatar-itself" :src="imageUri" alt="Creator Avatar" />
  </div>
</template>

<!-- prettier-ignore -->
<script lang="ts">export default { name: "ProfileAvatar" };</script>

<script setup lang="ts">
export interface Props {
  imageUri: string;
  isWithPlusIcon?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  imageUri: "",
  isWithPlusIcon: false,
});

const contentBaseUri = globalThis.Bootstrap.Config.contentCdnBaseUri;
</script>

<style scoped lang="scss">
// Creator avatar =============================================================
.creator-avatar {
  max-width: 300px;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;

  &__plus-icon-wrap {
    width: 20%;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 12px 0 auto auto;
    z-index: 1;

    &::before {
      content: "";
      width: 160%;
      height: 160%;
      border-radius: 50%;
      position: absolute;
      inset: 50% auto auto 50%;
      z-index: -1;
      transform: translate(-50%, -50%);
      background: white;
    }
  }

  &__plus-icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__avatar-itself {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}
</style>
