import { defineStore } from "pinia";
import { ref } from "vue";
import axios, { AxiosResponse } from "axios";
import { CreatorCollabMatch } from "@contracts/collab";

export const useInvitedCreatorsStore = defineStore("invitedCreators", () => {
  interface InvitedCreators {
    communityId: string;
    collabInputId: string;
    creatorsList: Array<CreatorCollabMatch>;
  }

  const invitedCreatorsList = ref<Array<InvitedCreators>>([]);

  function addCreatorsList(communityId: string, collabInputId: string, creatorsList: Array<CreatorCollabMatch>): void {
    const existingListIndex = invitedCreatorsList.value.findIndex(i => i.communityId === communityId && i.collabInputId === collabInputId);

    if (existingListIndex !== -1) {
      invitedCreatorsList.value[existingListIndex] = { communityId, collabInputId, creatorsList };
    } else {
      invitedCreatorsList.value.push({ communityId, collabInputId, creatorsList });
    }
  }

  function getCreatorsList(communityId: string, collabInputId: string): Array<CreatorCollabMatch> | null {
    return invitedCreatorsList.value.find(i => i.communityId === communityId && i.collabInputId === collabInputId)?.creatorsList || null;
  }

  return {
    invitedCreatorsList,
    addCreatorsList,
    getCreatorsList,
  };
});
