<template>
  <div class="steps-summary">
    <!-- Header -->
    <div class="header steps-summary__header">
      <!--<div class="global-h1 header__title">Let's get started on your Creator Visit!</div>
      <div class="header__subtitle">A Creator Visit results in 4 adventures and 10+ locations visited</div>-->
    </div>
    <!-- / Header -->

    <ul class="steps-summary__steps-list">
      <!-- Single step -->
      <li class="single-step steps-summary__single-step" v-for="(step, index) in stepsList" :key="step.title">
        <!-- Step number -->
        <div class="step-number single-step__step-number">
          <div
            :class="{
              'step-number__number': true,
              'step-number__number--highlighted': index === 0,
            }"
          >
            {{ index + 1 }}

            <!-- You are here label -->
            <div v-if="index === 0" class="you-are-here-label step-number__you-are-here-label">
              You are here
              <div class="you-are-here-label__right-arrow"></div>
            </div>
            <!-- / You are here label -->
          </div>
          <div
            :class="{
              'step-number__guide-line': true,
              'step-number__guide-line--only-bottom': index === 0,
              'step-number__guide-line--only-top': index === stepsList.length - 1,
            }"
          ></div>
        </div>
        <!-- / Step number -->
        <div class="single-step__content-wrap">
          <div class="global-h4 single-step__title">
            {{ step.title }}
          </div>
          <div class="single-step__subtitle">{{ step.subtitle }}</div>
          <div class="single-step__note">
            <IconEmbedded class="single-step__info-icon" name="info-simple_4" />
            {{ step.note }}
          </div>
        </div>
      </li>
      <!-- / Single step -->
    </ul>

    <!-- Additional links -->
    <!--<div class="additional-links steps-summary__additional-links">-->
    <!--  <LinkWithIcon class="additional-links__link" tag="a" target="_blank" :href="globalGetLink('CommunityCollabInspire')">-->
    <!--    <IconEmbedded name="bulb_2-5" size="21" />-->
    <!--    <span>Get inspiration</span>-->
    <!--  </LinkWithIcon>-->

    <!--  <LinkWithIcon class="additional-links__link" tag="a" target="_blank" :href="globalGetLink('CommunityPaidCollabHelp')">-->
    <!--    <IconEmbedded name="lifebuoy_2" size="21" />-->
    <!--    <span>View Additional Guidance</span>-->
    <!--  </LinkWithIcon>-->
    <!--</div>-->
    <!-- / Additional links -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// Contracts
import CollabConsts from "@contracts/collab";

// Components
import LinkWithIcon from "@components/LinkWithIcon.vue";
import { inject } from "vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export default defineComponent({
  name: "StepsSummary",

  components: {
    IconEmbedded,
    LinkWithIcon,
  },

  data() {
    return {
      globalGetLink: inject("globalGetLink") as any,

      firstNumberDivPosition: { x: 0, y: 0 },

      stepsList: [
        {
          title: "Create the Visit Details",
          subtitle: "Tell us what you'd like the visit to be about",
          note: "Allow 4+ weeks to find a creator and coordinate the details",
        },
        {
          title: "Creators Apply",
          subtitle: "We'll share this with creators in your area and they can apply to participate",
          note: `Creators have ${CollabConsts.CollabInviteExpirationInDays} days to apply once a visit is posted`,
        },
        {
          title: "Choose the Creator",
          subtitle: "You can choose which creator you like best for the visit",
          note: "We recommend choosing a creator soon after to lock in the dates and details",
        },
        {
          title: "Creator Visits and Creates Content",
          subtitle: "After the creator visits, we'll share the amazing content that is now yours to use! (review and request edits at this time)",
          note: `Creators have ${CollabConsts.DaysCreatorHasToCreateAdventures} days after their visit to create content`,
        },
      ],
    };
  },

  computed: {
    CollabConsts() {
      return CollabConsts;
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Header =====================================================================
.header {
  &__title {
    margin-bottom: 9px;
  }

  &__subtitle {
  }
}

// You are here label =========================================================
.you-are-here-label {
  min-width: 105px;
  height: 25px;
  padding: 0 17px 1px 9px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
  color: #fff;
  white-space: nowrap;
  font:
    14px/14px "Helvetica Neue",
    sans-serif;

  &::before {
    content: "";
    width: calc(100% - 11px);
    height: 100%;
    border-radius: 3px;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -1;
    background: #118689;
  }

  &__right-arrow {
    width: 19px;
    height: 19px;
    position: absolute;
    inset: 3px 2px auto auto;
    z-index: -1;

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 3px;
      position: absolute;
      inset: 0 auto auto 0;
      transform: rotate(45deg);
      background: #118689;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
  .you-are-here-label {
    &::before {
      width: calc(100% - 7px);
    }
    &__right-arrow {
      inset: auto auto -3px 4px;
      transform: scaleX(0.85);
    }
  }
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .you-are-here-label {
    &::before {
      width: calc(100% - 7px);
    }
    &__right-arrow {
      inset: auto auto -3px 4px;
      transform: scaleX(0.85);
    }
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .you-are-here-label {
    &::before {
      width: calc(100% - 7px);
    }
    &__right-arrow {
      inset: auto auto -3px 4px;
      transform: scaleX(0.85);
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .you-are-here-label {
    &::before {
      width: calc(100% - 7px);
    }
    &__right-arrow {
      inset: auto auto -3px 4px;
      transform: scaleX(0.85);
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .you-are-here-label {
    &::before {
      width: calc(100% - 7px);
    }
    &__right-arrow {
      inset: auto auto -3px 4px;
      transform: scaleX(0.85);
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .you-are-here-label {
    &::before {
      width: calc(100% - 7px);
    }
    &__right-arrow {
      inset: auto auto -3px 4px;
      transform: scaleX(0.85);
    }
  }
}

// Step number ================================================================
.step-number {
  padding-top: 11px;
  position: relative;
  z-index: 0;

  &__number {
    width: 28px;
    min-width: 28px;
    height: 28px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    color: #fff;
    font:
      700 15px/15px "Quicksand",
      sans-serif;
    background: #6b6b6b;

    &--highlighted {
      background: #118689;
    }
  }

  &__you-are-here-label {
    position: absolute;
    inset: 2px 33px auto auto;
    z-index: 0;
  }

  &__guide-line {
    width: 2px;
    height: calc(100% + 16px);
    position: absolute;
    inset: 0 auto auto calc(50% - 1px);
    z-index: 0;
    background: #d5d5d5;

    &--only-bottom {
      top: 11px;
      height: calc(100% + 5px);
    }

    &--only-top {
      height: 11px;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
  .step-number {
    &__you-are-here-label {
      inset: -48px auto auto 0;
    }
  }
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .step-number {
    &__you-are-here-label {
      inset: -48px auto auto 0;
    }
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .step-number {
    &__you-are-here-label {
      inset: -48px auto auto 0;
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .step-number {
    &__you-are-here-label {
      inset: -48px auto auto 0;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .step-number {
    &__you-are-here-label {
      inset: -48px auto auto 0;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .step-number {
    &__you-are-here-label {
      inset: -48px auto auto 0;
    }
  }
}

// Single step ================================================================
.single-step {
  display: flex;
  align-content: stretch;
  align-items: stretch;

  &__step-number {
    margin-right: 13px;
  }

  &__content-wrap {
    height: 100%;
    flex-grow: 1;
    padding: 13px 20px;
    border: 1px #d4d4d4 solid;
    border-radius: 6px;
    background: white;
  }

  &__title {
    margin-bottom: 6px;
  }

  &__subtitle {
    margin-bottom: 1px;
  }

  &__note {
    display: flex;
    align-items: flex-start;
    color: #ac892d;
    font-style: italic;
  }

  &__info-icon {
    width: 16px;
    min-width: 16px;
    height: 16px;
    margin-right: 6px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 2px;
    background: rgba(172, 137, 45, 0.2);

    :deep(svg) {
      fill: #ac892d;
    }
  }
}

// Additional links ===========================================================
.additional-links {
  &__link {
    margin-right: 25px;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .additional-links {
    display: flex;
    flex-direction: column;

    &__link {
      &:first-child {
        margin-bottom: 11px;
      }
    }
  }
}

// Steps summary ==============================================================
.steps-summary {
  color: #5b5b5b;
  font-family: "Helvetica Neue", sans-serif;

  &__header {
    margin-bottom: 32px;
  }

  &__steps-list {
    padding: 0;
    margin: 0 0 25px 0;
    list-style: none;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__single-step {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__additional-links {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
  .steps-summary {
    &__header {
      margin-bottom: 44px;
    }
  }
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .steps-summary {
    &__header {
      margin-bottom: 44px;
    }
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .steps-summary {
    &__header {
      margin-bottom: 44px;
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .steps-summary {
    &__header {
      margin-bottom: 44px;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .steps-summary {
    &__header {
      margin-bottom: 44px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .steps-summary {
    &__header {
      margin-bottom: 44px;
    }
  }
}
</style>
