<template>
  <NoteWithIcon color="red">
    <template #icon>
      <IconEmbedded name="info-simple_4" :size="25" />
    </template>
    <template #text>
      <div>
        <h3 class="global-h3" style="margin-bottom: 4px">This account is disabled</h3>
        <div>
          <div style="margin-bottom: 5px">
            <div style="margin-bottom: 5px">The profile is no longer publicly visible.</div>
            <template v-if="props.showReactivateButton">
              <SrpButton variant="primary" size="small" :isDisabled="isReactivating" @click="handleReactivate"> {{ isReactivating ? "Reactivating..." : "Reactivate Account" }} </SrpButton>
            </template>
            <template v-else>
              You can <RouterLink :to="{ name: 'CreatorSettings', params: { creatorId: userProfileStore.getViewingUserProfile.sherpaId } }">reactivate on the settings page</RouterLink>.
            </template>
          </div>
          <div style="margin-bottom: 10px">Or contact <CopyTextNew>creators@shrpa.com</CopyTextNew> if you have any questions.</div>
        </div>
      </div>
    </template>
  </NoteWithIcon>
</template>

<script setup lang="ts">
import { inject, ref } from "vue";
import axios from "axios";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import CopyTextNew from "@components/CopyTextNew.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import { useUserProfileStore } from "@stores/userProfileStore";

const globalRemoteLogger = inject("globalRemoteLogger") as any;

const props = defineProps<{
  showReactivateButton?: boolean;
}>();

const isReactivating = ref(false);
const userProfileStore = useUserProfileStore();

const handleReactivate = async () => {
  isReactivating.value = true;

  var uri = `${import.meta.env.VITE_API_URL}/profile/${userProfileStore.getViewingUserProfile.sherpaId}/enable`;
  var updated = await axios.put(uri);
  globalRemoteLogger.info(`profileReactivate: ${userProfileStore.getViewingUserProfile.sherpaId}, wasUpdated=${updated.data}`);

  userProfileStore.getViewingUserProfile.disabledDate = null;

  isReactivating.value = false;
};
</script>
