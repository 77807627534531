<template>
  <span v-if="canShow" class="ui form">
    <!--Button to fire the modal-->
    <button @click.prevent="show()" class="ui large basic primary button" style="padding-right: 12px">
      <!--<i class="exclamation triangle icon"></i>-->
      <i class="flag outline icon"></i>
      <!--Issue-->
    </button>

    <!--Report Issue Modal-->
    <SrpModal v-model:isVisible="showModal" :isClosable="false" :zIndex="400">
      <template #header>
        <h2 class="global-h2">Find an issue with {{ entityName }}?</h2>
      </template>
      <template #content>
        <div style="margin: 10px 20px">
          <h3>Summary of the issue</h3>
          <textarea v-model="issueDetails" autocapitalize="sentences" class="detail_textarea" placeholder="Is a location closed?  Something inaccurate?" style="height: 100px"></textarea>
          <div class="ui checkbox" style="margin-top: 10px">
            <input type="checkbox" v-model="removeFromCommunityPage" id="removeFromCommunityPage" />
            <label for="removeFromCommunityPage" style="cursor: pointer; user-select: none">Remove this adventure from my Destination Page for {{ customerNameInContext }}?</label>
          </div>
          <h5 v-if="showErrorText" style="color: red">Please summarize the issue, thanks!</h5>
        </div>
      </template>
      <template #footer>
        <SrpButton @click="cancelReportIssue" color="gray" fill="outlined" style="margin-right: 10px">Cancel</SrpButton>
        <SrpButton @click="reportIssue" color="orange">{{ isSaving ? "Sending..." : "Report Issue" }}</SrpButton>
      </template>
    </SrpModal>
  </span>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { PropType } from "vue";
import axios from "axios";
import OrgContext from "@logic/OrgContext";
import AdminContext from "@logic/AdminContext";
import SrpModal from "@components/ui/SrpModal.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import { mapState } from "pinia";
import { useUserProfileStore } from "@stores/userProfileStore";

// NOTE! This currently only shows for Customer Admins, eventually we could open this up to all users (might need more controls/management)

export default defineComponent({
  name: "ReportButton",
  components: { SrpButton, SrpModal },

  props: {
    entityName: { default: "" },
    entityId: { type: String as PropType<string | null>, required: false, default: null },
  },

  data() {
    return {
      globalLog: inject("globalLog") as any,

      showModal: false,
      customerIdInContext: null as string | null,
      customerNameInContext: null as string | null,

      userProfileStore: useUserProfileStore(),

      issueDetails: "",
      removeFromCommunityPage: true,
      showErrorText: false,
      isSaving: false,
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["getViewingUserProfile", "getActingUserProfile"]),
    canShow(): boolean {
      if (this.customerIdInContext) return true;
      return false;
    },
  },

  watch: {
    // Little hacky but works
    "userProfileStore.loggedInUser": function (profile) {
      if (profile) {
        this.profileLoaded();
      }
    },
    "globalRoot.orgInContext": function () {
      this.profileLoaded();
    },
  },

  async mounted() {
    if (this.getActingUserProfile) {
      this.profileLoaded();
    }
  },

  methods: {
    async profileLoaded() {
      this.globalLog.info("Reportbutton: profileLoaded()");
      await this.loadLists();
    },
    async loadLists() {
      // Load the customerId in context
      var orgInContext = OrgContext.getOrgInContext(this);
      if (orgInContext) {
        this.customerIdInContext = orgInContext.customerId;
        this.customerNameInContext = orgInContext.name;
        // Check to see if this user is an admin for that org
        const isAdmin = AdminContext.isAdminOf(this.customerIdInContext, this.getActingUserProfile, true);
        if (isAdmin) {
          // NOTE: Not currently showing that they've previously reported an issue for this adventure
        }
      }
    },
    show() {
      this.showModal = true;
    },
    async reportIssue() {
      if (this.isSaving) return;
      if (!this.issueDetails || this.issueDetails.trim().length === 0) {
        this.showErrorText = true;
        return;
      }
      this.showErrorText = false;
      this.isSaving = true;
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } = await axios.put(
        `${import.meta.env.VITE_API_URL}/${this.customerIdInContext}/issues/${this.entityId}?removeFromPage=${this.removeFromCommunityPage}`,
        JSON.stringify(this.issueDetails),
        config
      );
      this.isSaving = false;
      this.showModal = false;
      this.issueDetails = "";
    },
    cancelReportIssue() {
      this.showModal = false;
      this.issueDetails = "";
    },
  },
});
</script>

<style scoped></style>
