<template>
  <span>
    <span class="date-range-wrap">
      <VueDatePicker
        class="date-range-picker"
        type="date"
        format="MMM d yyyy"
        v-model="dateRange"
        @update:model-value="dateChanged"
        placeholder="Choose a date range"
        range
        multi-calendars
        :teleport="true"
        autoApply
        :enableTimePcker="false"
        :teleportCenter="['mobile'].includes(screenSize)"
        @open="onDatePickerOpen"
      >
      </VueDatePicker>
    </span>
  </span>
</template>

<script lang="ts">
/* eslint vue/no-mutating-props: 0 */
import { defineComponent, inject } from "vue";
import DateUtils from "@logic/DateUtils";

// Types
import { CollabDates } from "@contracts/collab";
import { ScreenSize } from "@contracts/screenSize";

// Components
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default defineComponent({
  name: "CollabDatePicker",

  components: {
    VueDatePicker,
  },

  props: {
    collabInput: { type: Object as () => CollabDates, required: true },
    // Used to reset the dateRange field
    resetCounter: { type: Number, required: false, default: 0 },
    shouldDefaultDates: { type: Boolean, required: false, default: false },
  },

  emits: ["dateChanged"],

  data() {
    return {
      globalLog: inject("globalLog") as any,

      screenSize: inject("screenSize") as ScreenSize,

      dateRange: null as Array<Date> | null,
    };
  },

  watch: {
    // Resets the state since they changed collabs
    collabInput: function (newVal, oldVal) {
      this.setupDatePicker();
    },
    resetCounter: function (newVal, oldVal) {
      this.globalLog.info(`CollabDatePicker resetCounter fired`);
      this.dateRange = null;
    },
  },

  async mounted() {
    this.setupDatePicker();
  },

  methods: {
    onDatePickerOpen() {
      if (this.shouldDefaultDates) {
        this.setDefaultDateRangeIfEmpty();
      }
    },
    setDefaultDateRangeIfEmpty() {
      if (!this.dateRange) {
        // Start the calendar a 6 weeks out for 6 weeks long
        this.dateRange = [DateUtils.addDays(new Date(), 42), DateUtils.addDays(new Date(), 84)];
        this.dateChanged(this.dateRange);
      }
    },
    setupDatePicker() {
      this.globalLog.info(`setupDatePicker: start=${this.collabInput?.preferredDateRangeStart}`);
      // Sets the field that the datepicker binds to
      if (this.collabInput?.preferredDateRangeStart && this.collabInput.preferredDateRangeStart.length > 0) {
        var startDate = new Date(this.collabInput.preferredDateRangeStart);
        var endDate = new Date(this.collabInput.preferredDateRangeEnd);
        this.dateRange = [startDate, endDate];
      } else {
        this.dateRange = null;
      }
      // Without this the state field on CommunityInput wouldn't get set on initial load
      this.$emit("dateChanged", this.dateRange);
    },
    dateChanged(dates: Array<Date | null>) {
      // If the date is cleared, we get an array with null vales
      if (!dates || dates.length === 0 || dates[0] === null) {
        this.collabInput.preferredDateRangeStart = null;
        this.collabInput.preferredDateRangeEnd = null;
      } else {
        // NOTE: We're forcing the time to noon so the conversion to local time that automatically happens doesn't switch the day.
        // This is kind of a workaround, ideally we'd completely nuke the time component and use DateOnly style logic.
        const startDate = this.forceToNoonUTC(dates[0]);
        const endDate = this.forceToNoonUTC(dates[1]);
        this.collabInput.preferredDateRangeStart = startDate.toISOString();
        this.collabInput.preferredDateRangeEnd = endDate.toISOString();
        this.globalLog.info(`Date set: ${this.collabInput.preferredDateRangeStart} to ${this.collabInput.preferredDateRangeEnd}`);
      }

      this.$emit("dateChanged", dates);
    },
    forceToNoonUTC(date: Date) {
      // Repeated other places DatePicker is used
      // Note: Previously we were using setUTCHours(12) but that was causing the date to be off by a day
      // Have to use Date.UTC or it will adjust hours to local time
      const local = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 12, 11, 11));
      return local;
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

.date-range-wrap {
  display: block;

  :deep(.dp__input) {
    padding-left: 35px !important;
  }
}

// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  :global(.dp__outer_menu_wrap) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
