<template>
  <div class="slide-content">
    <img class="slide-content__illustration" alt="slide1" src="/images/onboarding/photos.svg" style="width: 235px" />

    <div class="global-h5 slide-content__subtitle">
      {{ onboardingParams.onboardingPhotoWallId ? "You already have photo walls" : "Upload ~10 photos or short-form videos from your community to see an example" }}
    </div>

    <UploadPhotoForm
      v-if="!onboardingParams.onboardingPhotoWallId"
      class="slide-content__upload-photo-form slide-content__upload-photo-form--bigger-bottom-margin"
      :img="images"
      :isCollab="false"
      :showSlose="false"
      :showRemove="true"
      :autoSelect="false"
    />

    <div class="slide-content__buttons-section">
      <div
        :class="{
          'ui orange large button': true,
          'slide-content__main-cta-button': true,
          'slide-content__main-cta-button--disabled': isLoading,
          'slide-content__main-cta-button--loading': isLoading && isClickedNextButton,
        }"
        @click="goToNextStep"
      >
        Next step&nbsp;<IconEmbedded :name="isLoading && isClickedNextButton ? 'loader_3-5' : 'arrow-bottom_3-5'" :size="26" color="rgba(255, 255, 255, 0.4)" />
      </div>

      <div
        v-if="existingPhotoWallId || images.length"
        :class="{
          'ui gray small button': true,
          'slide-content__additional-cta-button': true,
          'slide-content__additional-cta-button--disabled': isLoading,
          'slide-content__additional-cta-button--loading': isLoading && isClickedContinueButton,
        }"
        @click="() => navigateToPhotoWalls()"
      >
        Review my PhotoWall&nbsp;<IconEmbedded :name="isLoading && isClickedContinueButton ? 'loader_3-5' : 'arrow-right_3-5'" :size="16" color="rgba(0, 0, 0, 0.2)" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import { defineComponent } from "vue";

// Types
import { OnboardingCustomer } from "@contracts/onboarding";
import { UploadedImage } from "@contracts/uploadedImage";

// Components
import CopyTextNew from "@components/CopyTextNew.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import UploadPhotoForm from "@views/CMS/UploadPhotoForm.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export default defineComponent({
  name: "SlidePhotoWall",

  components: {
    IconEmbedded,
    CopyTextNew,
    LinkWithIcon,
    UploadPhotoForm,
  },

  props: {
    onboardingParams: { type: Object as () => OnboardingCustomer, required: true },
    communityIdInContext: { type: String, required: true },
  },

  emits: ["close", "clickOnLink", "goToNextStep"],

  data() {
    return {
      images: [] as Array<UploadedImage>,

      isClickedNextButton: false,
      isClickedContinueButton: false,

      existingPhotoWallId: "",
      newPhotoWallId: "",

      isLoading: false,
    };
  },

  watch: {
    onboardingParams: {
      handler() {
        this.updateExistingWallId();
      },
      deep: true,
    },
  },

  mounted() {
    this.updateExistingWallId();
  },

  methods: {
    updateExistingWallId(): void {
      this.existingPhotoWallId = this.onboardingParams.onboardingPhotoWallId;
    },
    async saveImages(): Promise<void> {
      this.isLoading = true;

      const { data } = await axios.put(
        // @ts-ignore
        `${import.meta.env.VITE_API_URL}/customer-onboard/${this.globalRoot.orgInContext.customerId}/photo-wall`,
        { assetIds: this.images.map(img => img.serverId) }
      );
      this.newPhotoWallId = data;
      // @ts-ignore
      await Promise.all([this.globalRoot.widgetDirectionsComponent?.loadData(), this.globalRoot.customerAdminLeftNavComponent?.getOnboardingCustomerParams()]);

      this.isLoading = false;
    },
    async goToNextStep(): Promise<void> {
      if (!this.onboardingParams.onboardingPhotoWallId) {
        this.isClickedNextButton = true;
        await this.saveImages();
        this.isClickedNextButton = false;
      }

      this.$emit("goToNextStep");
    },
    async navigateToPhotoWalls(noHistory = false) {
      if (!this.onboardingParams.onboardingPhotoWallId && this.images.length) {
        this.isClickedContinueButton = true;
        await this.saveImages();
        this.isClickedContinueButton = false;
      }

      const destination = {
        name: "PhotoWallAdmin",
        params: {
          pageId: this.communityIdInContext,
          photoWallId: this.newPhotoWallId ? this.newPhotoWallId : this.onboardingParams.onboardingPhotoWallId,
        },
      };

      if (noHistory === true) await this.$router.replace(destination);
      else await this.$router.push(destination);

      this.$emit("clickOnLink");
      this.$emit("close");
    },
  },
});
</script>

<style scoped lang="scss">
@import "./slide-styles.scss";
</style>
