<template>
  <div class="drag-n-drop-area">
    <LinkWithIcon v-if="props.isWithToggleLink" class="drag-n-drop-area__toggle-link" color="blue" isDottedUnderline @click="_isOpened = !_isOpened">
      <template #icon><IconEmbedded name="upload_2" :size="24" /></template>
      <span>Upload new files</span>
    </LinkWithIcon>

    <TransitionedHeightAuto class="drag-n-drop-area__area-itself-wrap" :isOpened="_isOpened" isKeepAlive>
      <!-- Pointing triangle -->
      <span v-if="props.isWithToggleLink" class="pointing-triangle drag-n-drop-area__pointing-triangle">
        <svg class="pointing-triangle__svg" width="26" height="13" viewBox="0 0 26 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13 0L26 13H0L13 0Z" />
        </svg>
      </span>
      <!-- / Pointing triangle -->
      <span class="drag-n-drop-area__area-itself">
        <slot />
      </span>
    </TransitionedHeightAuto>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";

// Components
import LinkWithIcon from "@components/LinkWithIcon.vue";
import TransitionedHeightAuto from "@components/ui/TransitionedHeightAuto.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export interface Props {
  isWithToggleLink?: boolean;
  isOpened?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  isWithToggleLink: false,
  isOpened: false,
});

const emit = defineEmits<{
  (e: "upload", value: Event): void;
}>();

// Toggle drag&drop area ======================================================
const _isOpened = ref(false);

onMounted(() => {
  _isOpened.value = props.isWithToggleLink ? props.isOpened : true;
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Pointing triangle ==========================================================
.pointing-triangle {
  width: 26px;
  height: 13px;
  display: block;

  &__svg {
    width: 100%;
    height: 100%;
    fill: #e7e7e7;
  }
}

// Drag & Drop area ===========================================================
.drag-n-drop-area {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &__toggle-link {
    margin-bottom: 20px;
  }

  &__pointing-triangle {
    position: absolute;
    inset: -14px 45px auto auto;
  }

  &__area-itself-wrap {
    width: 100%;
  }

  &__area-itself {
    display: flex;

    & > :deep(div) {
      width: 100%;
    }

    :deep(.uploadedContent) {
      min-height: 90px;
      padding-bottom: 0;
    }

    :deep(.uploadBtn) {
      min-height: 90px;
    }

    :deep(.uploadBtn + div + .uploadBtn) {
      display: none;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .drag-n-drop-area {
    &__toggle-link {
      align-self: flex-start;
    }

    &__pointing-triangle {
      position: absolute;
      inset: -14px auto auto 60px;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .drag-n-drop-area {
    &__toggle-link {
      align-self: flex-start;
    }

    &__pointing-triangle {
      position: absolute;
      inset: -14px auto auto 60px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .drag-n-drop-area {
    &__toggle-link {
      align-self: flex-start;
    }

    &__pointing-triangle {
      position: absolute;
      inset: -14px auto auto 60px;
    }
  }
}
</style>
