<template>
  <div
    :class="{
      'print-media-snippet': true,
      'print-media-snippet--not-clickable': !data.isReady,
      'ui segment': true,
    }"
  >
    <div class="print-media-snippet__image-wrap">
      <img class="print-media-snippet__image" :src="`${contentBaseUri}${thumbnail}`" />
    </div>

    <div v-if="data.createdAssetId" class="print-media-snippet__download-but ui orange tiny button" @click="downloadFile"><i class="download icon"></i>Download</div>

    <template v-else>
      <span class="print-media-snippet__download-but ui gray tiny disabled button"> <i class="clock icon"></i>In progress </span>

      <!-- Cancel Button -->
      <span v-if="!data.createdAssetId" class="delete-but print-media-snippet__delete-but ui negative basic button" @click="$emit('delete', data.id)">
        <i class="delete-but__icon remove icon"></i>
        <span class="delete-but__text">Cancel</span>
      </span>
      <!-- / Cancel Button -->
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FileDownload from "@logic/FileDownload";

// Types
import { PrintMediaTemplate } from "@contracts/pages";

export default defineComponent({
  name: "PrintMediaSnippet",

  props: {
    data: { type: Object as () => PrintMediaTemplate | null, required: true },
    isSkeleton: { type: Boolean },
    thumbnail: { type: String, default: "" },
  },

  emits: ["delete"],

  data() {
    return {
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
    };
  },

  methods: {
    downloadFile(): void {
      FileDownload.downloadFileByURI(`${this.contentBaseUri}${this.data.createdAssetId}`);
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/mixins/animations/loading-bg.scss";

// Image skeleton =============================================================
.image-skeleton {
  width: 100%;
  margin-bottom: 10px;
  aspect-ratio: 10/13;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @mixin skeletonElement {
    border-radius: 3px;
    background: #e8e8e8;
  }

  &__title-1 {
    @include skeletonElement;
    width: 106px;
    height: 21px;
    margin-bottom: 7px;

    &--skeleton {
      @include loadingBgAnimation;
    }
  }

  &__title-2 {
    @include skeletonElement;
    width: 50px;
    height: 9px;
    margin-bottom: 17px;

    &--skeleton {
      @include loadingBgAnimation;
    }
  }

  &__qr-code {
    @include skeletonElement;
    width: 53px;
    height: 48px;
    margin-bottom: 15px;

    &--skeleton {
      @include loadingBgAnimation;
    }
  }

  &__subscript-1 {
    @include skeletonElement;
    width: 113px;
    height: 10px;
    margin-bottom: 3px;

    &--skeleton {
      @include loadingBgAnimation;
    }
  }

  &__subscript-2 {
    @include skeletonElement;
    width: 53px;
    height: 9px;
    margin-bottom: 3px;

    &--skeleton {
      @include loadingBgAnimation;
    }
  }

  &__subscript-3 {
    @include skeletonElement;
    width: 73px;
    height: 9px;

    &--skeleton {
      @include loadingBgAnimation;
    }
  }
}

// Print media snippet ========================================================
.print-media-snippet {
  width: 154px;
  cursor: pointer;

  &--not-clickable {
    cursor: default;
  }

  &__image-wrap {
    width: 100%;
    margin-bottom: 10px;
    aspect-ratio: 10/13;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    background: white;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__image-skeleton {
  }

  &__title {
    margin-bottom: 14px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    word-wrap: break-word;

    &--dimmed {
      color: rgba(0, 0, 0, 0.5);
    }

    &--skeleton {
      @include loadingBgAnimation;
      height: 20px;
      position: relative;
    }
  }

  &__download-but {
    margin-bottom: 6px;

    &--skeleton {
      @include loadingBgAnimation;
      width: 100px;
      height: 30px;
      display: block;
    }
  }

  &__delete-but {
  }

  // Delete button ====================
  .delete-but {
    padding: 0;
    border: none;
    outline: none;
    cursor: pointer;
    user-select: none;
    box-shadow: none !important;

    &__icon {
    }

    &__text {
      text-decoration: underline;
    }

    &:hover {
      .delete-but__text {
        text-decoration: none;
      }
    }
  }
}
</style>
