<template>
  <SrpDetailsSummary class="tile collab-opportunity__tile collab-opportunity__tile--collab-expectations" :isOpened="false" :transitionDuration="150">
    <template #heading>
      <div class="global-h3 tile__title">
        <span><span>Collab Expectations and Payment</span></span>
      </div>
    </template>
    <template #details>
      <CollabExpectations :requestedLocationCount="props.requestedLocationCount" :requestedAdventureCount="props.requestedAdventureCount" class="tile__content" style="margin-top: 20px" />
    </template>
  </SrpDetailsSummary>
</template>

<script setup lang="ts">
import SrpDetailsSummary from "@components/ui/SrpDetailsSummary.vue";
import CollabExpectations from "../CollabExpectations.vue";

const props = withDefaults(
  defineProps<{
    requestedLocationCount: number;
    requestedAdventureCount: number;
  }>(),
  {
    requestedLocationCount: 10,
    requestedAdventureCount: 4,
  }
);
</script>
