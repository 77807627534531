<template>
  <label>
    <slot name="label">
      <span class="global-p">{{ label }}</span>
    </slot>
    <input class="global-text-input" v-maska:[maskOptions]="maskDetails" type="tel" placeholder="+1 (555) 555-1234" v-model="maskedPhoneNumber" />
  </label>
</template>

<script setup lang="ts">
import { MaskOptions, MaskaDetail, vMaska } from "maska";
import { reactive, ref, watchEffect, watch } from "vue";

const props = defineProps<{ modelValue: string; label?: string }>();
const emit = defineEmits<{ (e: "update:modelValue", value: string): void }>();
defineSlots<{ label: void }>();

const maskDetails = reactive<MaskaDetail>({ completed: false, masked: "", unmasked: "" });
const maskOptions = reactive<MaskOptions>({
  mask: "+1 (###) ###-####",
});
const maskedPhoneNumber = ref(props.modelValue);
watchEffect(() => {
  emit("update:modelValue", maskDetails.unmasked);
});

// Handles the case when the phone number is changed externally ===============
watch(
  () => props.modelValue,
  () => {
    if (maskDetails.unmasked !== props.modelValue) {
      maskedPhoneNumber.value = props.modelValue;
    }
  }
);
</script>
