<template>
  <div
    :class="{
      'files-select-form': true,
      'files-select-form--with-error-overlay': props.isError,
    }"
  >
    <!-- Header -->
    <div class="header files-select-form__header">
      <h4 class="global-h4 header__title">
        Choose the photos and videos for this stop
        <!--<span class="header__count-number">{{ props.allItineraryFiles.length > 0 ? props.allItineraryFiles.length : "" }}</span>-->
      </h4>
      <div class="header__sub-title-wrap">
        <!--<div class="header__sub-title">Media files from the adventure</div>-->
        <LinkWithIcon class="header__upload-files-link"></LinkWithIcon>
      </div>
    </div>
    <!-- / Header -->
    <DragAndDropArea
      :class="{
        'files-select-form__drag-n-drop-area': true,
        'files-select-form__drag-n-drop-area--negative-top-margin': ['laptop', 'desktop', 'desktop-wide'].includes(screenSize),
      }"
      @upload="e => emit('upload', e)"
      isWithToggleLink
      :isOpened="allUnusedFiles.length === 0"
    >
      <UploadPhotoForm
        callerName="AdvStop"
        :img="mediaFromCollabLocationAsUploadedImages"
        :collabImagesUsed="mediaFromCollabLocation"
        :collabImagesUnused="props.collabImagesUnused"
        :singlePickMode="false"
        :autoSelect="false"
        :isCollab="props.isCollab"
        @imageUploadedToServer="event => emit('onImageUploadedToServer', event)"
        @removeMediaFile="image => emit('removeMediaFile', image)"
        ref="domRefUploadPhotoForm"
      >
        <template #thumbnailsList></template>
      </UploadPhotoForm>
    </DragAndDropArea>

    <div v-if="allUnusedFiles.length > 0 && props.isTabActive" class="files-select-form__not-used-files-section">
      <!-- Thumbnails list -->
      <ul class="thumbnails-list files-select-form__thumbnails-list">
        <SrpFileThumbnail
          class="thumbnails-list__thumbnail"
          v-for="file in allUnusedFiles"
          :key="file.serverId"
          :fileName="file.serverId"
          :thumbSize="'thumb-tiny'"
          :galleryPostfix="`${props.stopIndex}`"
          :isDeleteButton="isRemoveFilesMode && !Boolean(props.selectedFiles.find(f => f.serverId === file.serverId))"
          @delete="emit('removeMediaFile', file.serverId)"
        >
          <input
            type="checkbox"
            :value="file.serverId"
            :checked="Boolean(props.selectedFiles.find(f => f.serverId === file.serverId))"
            @change="
              emit('selectFile', {
                file,
                includeInStop: !Boolean(props.selectedFiles.find(f => f.serverId === file.serverId)),
              })
            "
          />
        </SrpFileThumbnail>
      </ul>
      <!-- / Thumbnails list -->
    </div>

    <div style="display: flex; justify-content: space-between">
      <LinkWithIcon v-if="currentStop.images.length < allUnusedFiles.length" isDottedUnderline @click="onClickSelectAll" style="margin-bottom: 30px; align-self: flex-end">
        <span>Select All</span>
      </LinkWithIcon>

      <LinkWithIcon v-else-if="allUnusedFiles.length > 0" isDottedUnderline @click="onClickDeselectAll" style="margin-bottom: 30px; align-self: flex-end">
        <span>Deselect All</span>
      </LinkWithIcon>

      <LinkWithIcon v-if="allUnusedFiles.length > 0" isDottedUnderline @click="isRemoveFilesMode = !isRemoveFilesMode" style="margin-bottom: 30px; align-self: flex-end">
        <template #icon><IconEmbedded name="trashcan-alt_2" :size="20" /></template>
        <span>{{ isRemoveFilesMode ? "Cancel remove mode" : "Select files to remove" }}</span>
      </LinkWithIcon>
    </div>

    <DragAndDropArea v-if="allUnusedFiles.length > 0" class="files-select-form__drag-n-drop-area">
      <UploadPhotoForm
        callerName="AdvStop"
        v-if="props.allItineraryFiles.length > 0"
        :img="props.allItineraryFiles"
        :singlePickMode="false"
        :autoSelect="false"
        :isCollab="props.isCollab"
        @imageUploadedToServer="event => emit('onImageUploadedToServer', event)"
        @removeMediaFile="image => emit('removeMediaFile', image)"
      >
        <template #thumbnailsList></template>
      </UploadPhotoForm>
    </DragAndDropArea>

    <!-- Used files section -->
    <SrpDetailsSummary v-if="!stopCollabLocation && allUsedFiles.length && props.isTabActive" class="used-files-section files-select-form__used-files-section" :transitionDuration="150">
      <template #heading>
        <h5 class="global-h5 used-files-section__title">
          <span>
            <span> {{ `Photos and videos used in other stops ${props.isCollab ? " or collab adventures" : ""}` }} </span>&nbsp;<span class="used-files-section__counter">{{
              allUsedFiles.length
            }}</span>
          </span>
        </h5>
      </template>
      <template #details>
        <!-- Thumbnails list -->
        <ul class="thumbnails-list used-files-section__thumbnails-list">
          <SrpFileThumbnail
            class="thumbnails-list__thumbnail"
            v-for="file in toReversed(allUsedFiles)"
            :key="file.serverId"
            :fileName="file.serverId"
            :thumbSize="'thumb-tiny'"
            :galleryPostfix="`${props.stopIndex}Used`"
          >
            <input
              type="checkbox"
              :value="file.serverId"
              :checked="Boolean(props.selectedFiles.find(f => f.serverId === file.serverId))"
              @change="
                emit('selectFile', {
                  file,
                  includeInStop: !Boolean(props.selectedFiles.find(f => f.serverId === file.serverId)),
                })
              "
            />
          </SrpFileThumbnail>
        </ul>
        <!-- / Thumbnails list -->
      </template>
    </SrpDetailsSummary>
    <!-- / Used files section -->

    <div class="files-select-form__selected-files-panel-wrap">
      <SelectedFilesPanel
        v-if="props.selectedFiles.length && props.isTabActive"
        class="files-select-form__selected-files-panel"
        :selectedFiles="selectedFiles"
        @remove="
          fileName => {
            emit('selectFile', {
              file: props.selectedFiles.find(f => f.serverId === fileName),
              includeInStop: false,
            });
          }
        "
        @reorder="newArray => emit('reorderSelectedFiles', newArray)"
        ref="domRefSelectedFilesPanel"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, inject, Ref, onMounted, onBeforeUnmount, nextTick } from "vue";
import lodashDebounce from "lodash-es/debounce";
import lodashUniqBy from "lodash-es/uniqBy";
import checkIfBlocksIntersect from "@helpers/CheckIfBlockIntersect";

// Types
import { ItineraryStep } from "@contracts/itinerary";
import { ScreenSize } from "@contracts/screenSize";
import { UploadedImage } from "@contracts/uploadedImage";
import { DomNodeClientRect } from "@helpers/CheckIfBlockIntersect";
import { CollabLocation } from "@contracts/collabLocations";

// Components
import DragAndDropArea from "./DragAndDropArea.vue";
import SrpFileThumbnail from "@components/ui/SrpFileThumbnail.vue";
import SelectedFilesPanel from "@views/CMS/StopsForm/SelectedFilesPanel.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import SrpDetailsSummary from "@components/ui/SrpDetailsSummary.vue";
import UploadPhotoForm from "@views/CMS/UploadPhotoForm.vue";

// Global variables
const screenSize = inject("screenSize") as Ref<ScreenSize>;

// Stores
import { useMiscellaneousStore } from "@stores/miscellaneous";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import SrpButton from "@components/ui/SrpButton.vue";
const miscellaneousStore = useMiscellaneousStore();

export interface Props {
  allItineraryFiles: Array<UploadedImage>;
  collabImagesUsed: Array<string>;
  collabImagesUnused: Array<string>;
  allStops: Array<ItineraryStep>;
  stopIndex: number;
  selectedFiles: Array<UploadedImage>;
  unselectedFiles: Array<UploadedImage>;
  isCollab?: boolean;
  isError?: boolean;
  isTabActive: boolean;
  collabLocations: CollabLocation[];
  useCollabLocationsLogic: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  allItineraryFiles: () => [],
  collabImagesUsed: () => [],
  collabImagesUnused: () => [],
  allStops: () => [],
  stopIndex: -1,
  selectedFiles: () => [],
  isCollab: false,
  isError: false,
  isTabActive: false,
  collabLocations: () => [],
});

interface ToggleFileEvent {
  file: UploadedImage;
  includeInStop: boolean;
}

const emit = defineEmits<{
  (e: "selectFile", value: ToggleFileEvent): void;
  (e: "deleteFile", fileName: string): void;
  (e: "reorderSelectedFiles", value: Array<UploadedImage>): void;
  (e: "upload", value: Event): void;
  (e: "onImageUploadedToServer", event: string | object): void;
  (e: "removeMediaFile", value: string): void;
}>();

const currentStop = computed(() => props.allStops[props.stopIndex]);
const mediaFromCollabLocationAsUploadedImages = computed<UploadedImage[]>(() => {
  return mediaFromCollabLocation.value.map(id => ({ serverId: id }) as UploadedImage);
});
const stopCollabLocation = computed<CollabLocation | null>(() => {
  if (!currentStop.value?.collabLocationId) return null;
  return props.collabLocations.find(l => l.id === currentStop.value.collabLocationId);
});
const mediaFromCollabLocation = computed(() => {
  return stopCollabLocation.value ? stopCollabLocation.value.mediaIds : [];
});

// Set upload photo form component ref ========================================
// to access it's methods
const domRefUploadPhotoForm = ref(null);

// All used files =============================================================
const itineraryUsedFiles = computed<Array<UploadedImage>>(() => {
  const allOtherStops = props.allStops.filter((item, index) => index !== props.stopIndex);
  const imagesFromAllOtherStops = allOtherStops.map(i => i.images).flat();

  return props.allItineraryFiles.filter(i => imagesFromAllOtherStops.includes(i.serverId));
});

const allUsedFiles = computed<Array<UploadedImage>>(() => {
  if (props.useCollabLocationsLogic) {
    if (!stopCollabLocation.value) return [];
    const collabLocationImages = stopCollabLocation.value.mediaIds;
    const useFilesIncludedInCollabLocation = itineraryUsedFiles.value.filter(file => collabLocationImages.includes(file.serverId));

    return useFilesIncludedInCollabLocation;
  }
  return [...itineraryUsedFiles.value, ...props.collabImagesUsed.map(id => ({ serverId: id }))] as Array<UploadedImage>;
});

// All unused files ===========================================================
const allUnusedFiles = computed<UploadedImage[]>(() => {
  if (props.useCollabLocationsLogic) {
    // The "unused" files for a stop using collab locations is ALL of the media for the collab location
    // even the media used in other stops.
    // Media for a stop are all selected automatically when the location is selected.
    // Collab location media are never considered "used" like media prior to collab locations
    return [...mediaFromCollabLocationAsUploadedImages.value];
  }

  const itineraryAndCollabUnusedFiles = [...props.allItineraryFiles, ...props.collabImagesUnused.map<UploadedImage>(id => ({ serverId: id }) as UploadedImage)];
  const justUnsedFiles = itineraryAndCollabUnusedFiles.filter(f => !allUsedFiles.value.map(i => i.serverId).includes(f.serverId));
  const uniqueUnused = lodashUniqBy(justUnsedFiles, ({ serverId }) => serverId);
  return uniqueUnused;
});

// Remove files mode ==========================================================
const isRemoveFilesMode = ref(false);

// Toggle help button z-index =================================================
// set it to -1 when it's over the selected files panel
const domRefSelectedFilesPanel = ref(null);

onMounted(() => document.addEventListener("scroll", setHelpButtonZindexDebounced));
onBeforeUnmount(() => document.removeEventListener("scroll", setHelpButtonZindexDebounced));

function setHelpButtonZindex(): void {
  if (!props.isTabActive || !domRefSelectedFilesPanel.value?.$el) return;

  miscellaneousStore.helpButtonZindex = checkIfBlocksIntersect(domRefSelectedFilesPanel.value?.$el?.getBoundingClientRect(), miscellaneousStore.helpButtonSizeAndPosition) ? -1 : 100;
}

const setHelpButtonZindexDebounced = lodashDebounce(setHelpButtonZindex, 50);

function onClickSelectAll() {
  allUnusedFiles.value.forEach(async file => {
    if (currentStop.value.images.includes(file.serverId)) return;
    // only emit one event per tick to allow computeds and watches to update
    await nextTick();
    emit("selectFile", {
      file,
      includeInStop: true,
    });
  });
}

function onClickDeselectAll() {
  allUnusedFiles.value.forEach(async file => {
    if (!currentStop.value.images.includes(file.serverId)) return;
    // only emit one event per tick to allow computeds and watches to update
    await nextTick();
    emit("selectFile", {
      file,
      includeInStop: false,
    });
  });
}

function toReversed<T>(array: T[]): T[] {
  // Reason: This is native now, but in Safari, only in 16+ and some users have older than that,
  // so polyfilling it
  // Note: We could probably check for the native presence and use that if present
  return array?.slice().reverse() ?? [];
}
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Header =====================================================================
.header {
  &__title {
  }

  &__count-number {
    width: 25px;
    height: 25px;
    border-radius: 100px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.5);
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    text-decoration: none !important;
    text-align: center;
    background: rgba(0, 0, 0, 0.1);
  }

  &__sub-title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__sub-title {
    color: #5b5b5b;
    font: 14px/18px sans-serif;
  }

  &__upload-files-link {
  }
}

// Thumbnails list ============================================================
.thumbnails-list {
  width: calc(100% + 15px);
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;

  &__thumbnail {
    width: 124px;
    margin: 0 11px 11px 0;
  }
}

// Used files section =========================================================
.used-files-section {
  padding: 20px 0 24px;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    width: calc(100% + 52px);
    height: 100%;
    position: absolute;
    inset: 0 auto auto -26px;
    z-index: -1;
    background: #e8e8e8;
  }

  &__title {
  }

  &__counter {
    width: 25px;
    height: 25px;
    border-radius: 100px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.5);
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    text-decoration: none !important;
    text-align: center;
    background: rgba(0, 0, 0, 0.1);
  }

  &__thumbnails-list {
    margin-top: 18px;
    margin-bottom: -7px;
  }
}

// Files select form ==========================================================
.files-select-form {
  display: flex;
  flex-direction: column;
  position: relative;

  &--with-error-overlay {
    &::before {
      content: "";
      width: calc(100% + 30px);
      height: calc(100% + 30px);
      border: 1px #dbb4b4 solid;
      border-radius: 5px;
      position: absolute;
      inset: -15px auto auto -15px;
      z-index: 10;
      background: rgba(#bf4545, 0.1);
      pointer-events: none;
    }
  }

  &__header {
    margin-bottom: 19px;
  }

  &__drag-n-drop-area {
    margin-bottom: 10px !important;

    &--negative-top-margin {
      margin-top: -38px;
    }
  }

  &__not-used-files-section {
  }

  &__thumbnails-list {
    margin: 0 0 12px;
  }

  &__used-files-section {
    margin-bottom: 25px;
  }

  &__selected-files-panel-wrap {
    position: sticky;
    bottom: 0;
    z-index: 4;
  }

  &__selected-files-panel {
    position: sticky;
    bottom: 0;
    z-index: 4;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .files-select-form {
    &__selected-files-panel-wrap {
    }

    &__selected-files-panel {
      width: 100vw;
      position: relative;
      left: -54px;
    }
  }
}
</style>
