<template>
  <div class="your-compensation">
    <div class="your-compensation__description">
      <!-- Collabs are a combination of payments from Shrpa and activities or lodging that's covered -->
      Below is a breakout of the payment you'll receive and the estimated non-payment value of other items that are part of this collab.
    </div>

    <!-- Formula -->
    <div class="formula your-compensation__formula">
      <div class="formula__number">${{ totalPaymentFromShrpaWithoutOther }}</div>
      <div class="formula__description">
        Payment from Shrpa <span class="formula__thin-text">({{ shrpaPaymentComponentsText }})</span>
      </div>

      <template v-if="estimatedLodgingValue > 0">
        <div class="formula__number">+ ${{ estimatedLodgingValue }}</div>
        <div class="formula__description">Est. Lodging Value <span class="formula__thin-text">(coordinated by destination)</span></div>
      </template>

      <template v-if="estimatedActivityValue > 0">
        <div class="formula__number">+ ${{ estimatedActivityValue }}</div>
        <div class="formula__description">Est. Activity Value <span class="formula__thin-text">(coordinated by destination)</span></div>
      </template>
      <template v-if="otherPaymentAmount > 0">
        <div class="formula__number">+ ${{ otherPaymentAmount }}</div>
        <div class="formula__description">{{ otherPaymentDetails ? otherPaymentDetails : "Bonus" }} <span class="formula__thin-text">(paid by Shrpa)</span></div>
      </template>

      <div class="formula__number formula__number--big">= ${{ overallCollabValue }}</div>
      <div class="formula__description formula__description--big">Overall Collab Value</div>
    </div>
    <!-- / Formula -->

    <div class="your-compensation__help-note">Questions? Reach out anytime to <CopyText :text="'collabs@shrpa.com'" /></div>

    <template v-if="currentUserIsAssignedToCollab">
      <div style="margin-top: 15px; margin-bottom: 13px; border-bottom: 1px rgba(0, 0, 0, 0.1) solid"></div>

      <SrpDetailsSummary :isOpened="false">
        <template #heading>
          <h4 class="global-h5">
            <span><span>When will you get paid?</span></span>
          </h4>
        </template>

        <template #details>
          <WhenWillIGetPaidNote :totalPaymentFromShrpa="totalPaymentFromShrpaWithOther" :titleSize="5" :isTitleVisible="false" />
        </template>
      </SrpDetailsSummary>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import CollabValueCalculator from "@logic/CollabValueCalculator";

// Types
import { CollabInput } from "@contracts/collab";

// Components
import CopyText from "@components/CopyText.vue";
import SrpDetailsSummary from "@components/ui/SrpDetailsSummary.vue";
import WhenWillIGetPaidNote from "@components/WhenWillIGetPaidNote.vue";

// Stores
import { useUserProfileStore } from "@stores/userProfileStore";
const userProfileStore = useUserProfileStore();

export interface Props {
  collabInput: CollabInput | null;
  creatorCurrentRate?: number;
  otherPaymentAmount?: number;
  otherPaymentDetails?: string | null;
}
const props = withDefaults(defineProps<Props>(), {
  collabInput: null,
  creatorCurrentRate: 0,
  otherPaymentAmount: 0,
  otherPaymentDetails: null,
});

// ============================================================================
const estimatedLodgingValue = computed<number>(() => {
  return CollabValueCalculator.estimatedLodgingValue(props.collabInput);
});

const estimatedActivityValue = computed<number>(() => {
  return CollabValueCalculator.estimatedActivityValue(props.collabInput);
});

const totalPaymentFromShrpaWithoutOther = computed<number>(() => {
  // Note: Passing in 0 here since we split "other" out onto a separate line
  return CollabValueCalculator.totalPaymentFromShrpa(props.collabInput, props.creatorCurrentRate, 0);
});
const totalPaymentFromShrpaWithOther = computed<number>(() => {
  // Note: Passing in 0 here since we split "other" out onto a separate line
  return CollabValueCalculator.totalPaymentFromShrpa(props.collabInput, props.creatorCurrentRate, props.otherPaymentAmount);
});

const shrpaPaymentComponentsText = computed<string>(() => {
  let text = "current rate";
  if (props.collabInput.hotelNightsCovered === 0) text += " and lodging";
  return text;
});

const overallCollabValue = computed<number>(() => {
  return CollabValueCalculator.overallCollabValue(props.collabInput, props.creatorCurrentRate, props.otherPaymentAmount);
});

const currentUserIsAssignedToCollab = computed<boolean>(() => {
  return userProfileStore.getViewingUserProfile?.sherpaId === props.collabInput?.collaboration?.creatorId;
});
</script>

<style scoped lang="scss">
// Formula ====================================================================
.formula {
  display: flex;
  flex-wrap: wrap;
  color: #000;
  font-weight: 600;
  font-family: "Quicksand", sans-serif;

  &__number {
    width: 80px;
    min-width: 60px;
    padding-bottom: 2px;
    margin: 0 6px 0 0;
    text-align: right;
    white-space: nowrap;
    line-height: 16px;
    font-weight: 700;

    &--big {
      padding: 4px 0 0;
      border-top: 1px black solid;
      color: #549e3f;
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
    }
  }

  &__description {
    width: calc(100% - 125px);
    min-width: calc(100% - 125px);
    padding: 0 0 2px 5px;
    box-sizing: border-box;
    line-height: 16px;
    font-weight: 700;

    &--big {
      padding: 4px 0 0 5px;
      color: #549e3f;
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
    }
  }

  &__thin-text {
    font-weight: 400;
    white-space: nowrap;
  }
}

// Your compensation ==========================================================
.your-compensation {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #5b5b5b;
  line-height: 18px;
  font-family: sans-serif;

  &__description {
    padding-bottom: 15px;
    margin-bottom: 14px;
    border-bottom: 1px rgba(0, 0, 0, 0.15) solid;
  }

  &__formula {
    padding-bottom: 15px;
    margin-bottom: 11px;
  }

  &__help-note {
  }
}
</style>
