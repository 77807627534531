<template>
  <SrpModal :isVisible="isVisible" :isClosable="false" @update:isVisible="emit('update:isVisible', $event)">
    <template #header>
      <h2 class="global-h2">Not able to make it?</h2>
    </template>
    <template #content>
      <template v-if="collab && collab.creatorInput">
        <div v-if="!collab.creatorInput.manuallyInvited" style="margin-bottom: 8px">
          Any detail you provide helps us coordinate collabs better.<br />
          We don't show this to the community and it won't negatively impact any future collab opportunities. Thank you!
        </div>
        <b>Reason (optional):</b><br />
        <select class="ui dropdown" :value="declineReason" @input="emit('update:declineReason', ($event.target as HTMLSelectElement).value)">
          <option v-for="reason in declineReasons" :key="`declinereason-${reason.key}`" :value="reason.key">{{ reason.text }}</option>
        </select>
        <textarea
          type="text"
          maxlength="3000"
          autocapitalize="sentences"
          style="height: 70px; margin-top: 10px"
          :value="notesForShrpa"
          @input="emit('update:notesForShrpa', ($event.target as HTMLTextAreaElement).value)"
          placeholder="Any other details you'd like to provide?"
        ></textarea>
      </template>
    </template>
    <template #footer>
      <SrpButton @click="emit('click:cancel')" color="gray" fill="outlined" style="margin-right: 10px">Cancel</SrpButton>
      <SrpButton @click="emit('click:declineCollab')">
        {{ collab && collab.creatorInput && collab.creatorInput.manuallyInvited ? "Decline Collab" : "Can't make it" }}
      </SrpButton>
    </template>
  </SrpModal>
</template>

<script setup lang="ts">
import SrpButton from "@components/ui/SrpButton.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import { CreatorCollab } from "@contracts/collab";
import { computed } from "vue";

defineProps<{
  isVisible: boolean;
  collab: CreatorCollab;
  notesForShrpa: string | null;
  declineReason: string | null;
}>();
const emit = defineEmits<{
  (e: "update:isVisible", value: boolean): void;
  (e: "update:declineReason", value: string): void;
  (e: "update:notesForShrpa", value: string): void;
  (e: "click:declineCollab"): void;
  (e: "click:cancel"): void;
}>();

export type DeclineReason = "unavailable" | "nogear" | "notafitforthemes" | "other";

const declineReasons = computed<{ key: DeclineReason; text: string }[]>(() => [
  { key: "unavailable", text: "I'm not available on those dates" },
  { key: "nogear", text: "I don't have the necessary gear" },
  { key: "notafitforthemes", text: "I'm not a good fit for the themes or activities" },
  { key: "other", text: "Other (tell us why below please)" },
]);
</script>
