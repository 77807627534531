// Draws a square around a center point
export function buildSquareAroundCenterAsGeoJson(centerLat: number, centerLng: number, miles: number): string {
  // For this to work with the Features logic this uses, we need to build and set the geojson (vs. another layer using setMap)
  const bounds = calculateSquareBounds(centerLat, centerLng, miles);
  const squareGeoJson = {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [bounds.west, bounds.north], // NW corner
          [bounds.east, bounds.north], // NE corner
          [bounds.east, bounds.south], // SE corner
          [bounds.west, bounds.south], // SW corner
          [bounds.west, bounds.north], // Closing the loop to NW corner
        ],
      ],
    },
  };

  return JSON.stringify(squareGeoJson);
}

// Helper function to calculate square bounds given the center point and distance in miles
function calculateSquareBounds(lat: number, lng: number, miles: number): google.maps.LatLngBoundsLiteral {
  const north = lat + degreesInMiles(lat, "lat", miles);
  const south = lat - degreesInMiles(lat, "lat", miles);
  const east = lng + degreesInMiles(lat, "lng", miles);
  const west = lng - degreesInMiles(lat, "lng", miles);

  return { north, south, east, west };
}

// Helper function to calculate degrees in miles for latitude or longitude
function degreesInMiles(lat: number, type: "lat" | "lng", miles: number): number {
  const milesPerDegreeLatitude = 69.0; // Roughly constant
  const milesPerDegreeLongitude = Math.cos(lat * (Math.PI / 180)) * 69.0; // Varies by latitude

  if (type === "lat") {
    return miles / milesPerDegreeLatitude;
  } else if (type === "lng") {
    return miles / milesPerDegreeLongitude;
  } else {
    throw new Error("Invalid type for degree conversion.");
  }
}
