<template>
  <ul class="our-benefits-section">
    <!-- Benefit snippet -->
    <li
      :class="{
        'benefit-snippet': true,
        'benefit-snippet--blue': benefit.color === 'blue',
        'benefit-snippet--teal': benefit.color === 'teal',
        'our-benefits-section__benefit-snippet': true,
      }"
      v-for="benefit in benefitsList"
      :key="benefit.title + benefit.number + benefit.subscript"
    >
      <div class="benefit-snippet__title">{{ benefit.title }}</div>
      <div class="benefit-snippet__number">{{ benefit.number }}</div>
      <div class="benefit-snippet__subscript">{{ benefit.subscript }}</div>
    </li>
    <!-- / Benefit snippet -->
  </ul>
</template>

<script setup lang="ts">
// Benefits list ==============================================================
const benefitsList = [
  { title: "Working With", number: "150+", subscript: "Destinations", color: "blue" },
  { title: "Coordinating", number: "900+", subscript: "Collabs", color: "teal" },
  // { title: "Paid over", number: "$100k", subscript: "to Creators", color: "teal" },
  { title: "Helping", number: "500+", subscript: "Creators", color: "blue" },
  // { title: "Sold", number: "400+", subscript: "Upcoming Collabs", color: "gray" },
];
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Benefit snippet ============================================================
.benefit-snippet {
  width: 165px;
  min-width: 165px;
  aspect-ratio: 1/1;
  border-radius: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f0f0f0;

  &--blue {
    background: #d7eef9;
  }

  &--teal {
    background: #d2f3f3;
  }

  &__title {
    color: #5b5b5b;
    font: 14px/14px sans-serif;
  }

  &__number {
    font:
      400 42px/42px "Quicksand",
      sans-serif;
  }

  &__subscript {
    width: 80%;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    line-height: 14px;
    text-align: center;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .benefit-snippet {
    width: 160px;
    min-width: 160px;
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .benefit-snippet {
    width: 135px;
    min-width: 135px;

    &__number {
      font:
        400 38px/38px "Quicksand",
        sans-serif;
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .benefit-snippet {
    width: 135px;
    min-width: 135px;

    &__number {
      font:
        400 38px/38px "Quicksand",
        sans-serif;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .benefit-snippet {
    width: 135px;
    min-width: 135px;

    &__number {
      font:
        400 38px/38px "Quicksand",
        sans-serif;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .benefit-snippet {
    width: 135px;
    min-width: 135px;

    &__number {
      font:
        400 38px/38px "Quicksand",
        sans-serif;
    }
  }
}

// Our benefits section =======================================================
.our-benefits-section {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style: none;

  &__title {
    margin-bottom: 30px;
    font-weight: 400;
  }

  &__snippets-list {
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
  }

  &__benefit-snippet {
    margin: 0 calc(var(--grid-col-gap-width) / 2);
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .our-benefits-section {
    &__benefit-snippet {
      margin: 0 calc(var(--grid-col-gap-width) / 2) calc(var(--grid-col-gap-width) / 2) !important;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .our-benefits-section {
    &__benefit-snippet {
      margin: 0 calc(var(--grid-col-gap-width) / 2) calc(var(--grid-col-gap-width) / 2) !important;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .our-benefits-section {
    &__benefit-snippet {
      margin: 0 calc(var(--grid-col-gap-width) / 2) calc(var(--grid-col-gap-width) / 2) !important;
    }
  }
}
</style>
