import DateUtils from "./DateUtils";

const MinimumRangeInDays = 2;
const MinimumEndDaysInTheFuture = 14;

export default {
  // Validates the passed collab dates.  If valid returns null, otherwise returns an error message.
  validateCollabDates(preferredDateRangeStart: string, preferredDateRangeEnd: string) {
    var startDate = new Date(preferredDateRangeStart);
    var endDate = new Date(preferredDateRangeEnd);
    // Note: Updated to validate on the end date (previously it was the start)
    var minEndDateAllowed = DateUtils.addDays(new Date(), MinimumEndDaysInTheFuture);
    if (endDate < minEndDateAllowed) {
      return `Preferred Visit End must be at least ${MinimumEndDaysInTheFuture} days from today`;
    }
    // Just check that start date isn't in the past (using a few days ago in case they set the startDate and then update it the next day)
    var minStartDateAllowed = new Date();
    minStartDateAllowed.setDate(minStartDateAllowed.getDate() - 3);
    if (startDate < minStartDateAllowed) {
      return `Preferred Visit Window can't be in the past`;
    }
    var selectedRangeLength = Math.abs(DateUtils.daysBetween(startDate, endDate));
    if (selectedRangeLength < MinimumRangeInDays - 1) {
      return `Preferred Visit Window must be at least ${MinimumRangeInDays} days from start to end`;
    }

    return null;
  },
};
