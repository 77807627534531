<template>
  <div class="content-wrapper">
    <h1>Privacy Policy</h1>

    <p>
      <strong>Last Updated on January 15, 2019.</strong> This Privacy Policy is effective <strong>January 15, 2019</strong> for all users.
      <!-- To review the previous policy, please click <a lang="en" hreflang="en" href="/tos/privacy_en_us_20180525">here</a>. -->
    </p>
    <p>
      This Privacy Policy describes our policies on the collection, use, and disclosure of information about you in connection with your use of our services, including those offered through our
      websites, emails, and mobile applications (collectively, the <strong>"Service"</strong>). The terms <strong>"we"</strong>, <strong>"us"</strong>, and <strong>"Shrpa"</strong> refer to: (i) Shrpa
      LLC, a Minnesota limited liability corporation with its headquarters in Rochester, Minnesota.
    </p>
    <p>
      When you use the Service, you consent to our collection, use, and disclosure of information about you as described in this Privacy Policy. We may translate this Privacy Policy into other
      languages for your convenience. Nevertheless, the English version governs your relationship with Shrpa, and any inconsistencies among the different versions will be resolved in favor of the
      English version available <a lang="en" hreflang="en" href="https://shrpa.com/home/privacy">here</a>.
    </p>
    <h2 id="table-of-contents">
      <strong>TABLE OF CONTENTS</strong>
    </h2>
    <ol class="numeric-list">
      <li>
        <p><a lang="en" hreflang="en" href="#information">Information We Collect and How We Use It</a></p>
      </li>
      <li>
        <p><a lang="en" hreflang="en" href="#cookies">Cookies</a></p>
      </li>
      <li>
        <p><a lang="en" hreflang="en" href="#third-parties">Third Parties</a></p>
      </li>
      <li>
        <p><a lang="en" hreflang="en" href="#control-personal-data">Controlling Your Personal Data</a></p>
      </li>
      <li>
        <p><a lang="en" hreflang="en" href="#data-retention">Data Retention and Account Termination</a></p>
      </li>
      <li>
        <p><a lang="en" hreflang="en" href="#children">Children</a></p>
      </li>
      <li>
        <p><a lang="en" hreflang="en" href="#security">Security</a></p>
      </li>
      <li>
        <p><a lang="en" hreflang="en" href="#contact-information">Contact Information</a></p>
      </li>
      <li>
        <p><a lang="en" hreflang="en" href="#modifications">Modifications to This Privacy Policy</a></p>
      </li>
      <li>
        <p><a lang="en" hreflang="en" href="#california-residents">California Residents: Your California Privacy Rights</a></p>
      </li>
      <li>
        <p><a lang="en" hreflang="en" href="#international-data-transfer">International Data Transfer</a></p>
      </li>
    </ol>
    <h2 id="information">
      <strong>1. INFORMATION WE COLLECT AND HOW WE USE IT</strong>
    </h2>
    <p>
      We may collect and store information about you in connection with your use of the Service, including any information you transmit to or through the Service. We use that information to provide
      the Service’s functionality, fulfill your requests, improve the Service’s quality, engage in research and analysis relating to the Service, personalize your experience, track usage of the
      Service, provide feedback to third party businesses that are listed on the Service, display relevant advertising, market the Service, provide customer support, message you, back up our systems,
      allow for disaster recovery, enhance the security of the Service, and comply with legal obligations. Even when we do not retain such information, it still must be transmitted to our servers
      initially and stored long enough to process.
    </p>
    <p>Please also note:</p>
    <ol type="a">
      <li>
        <p>
          <strong>Account Information:</strong> If you create a Shrpa account, we may store and use the information you provide during that process, such as your full name, email address, zip code,
          physical address, and other information you may provide with your account, such as your gender, phone number, and birth date. We may publicly display your first name and last initial, as
          well as any photo or other content you submit through the registration process, as part of your account profile. You can modify some of the information associated with your account through
          your account settings. If you believe that someone has created an unauthorized account depicting you or your likeness, you can request its removal by flagging it.
        </p>
      </li>
      <li>
        <p>
          <strong>Public Content:</strong> Your contributions to the Service are intended for public consumption and are therefore viewable by the public. Your account profile (e.g., first name, last
          initial, city, neighborhood, month and year joined, profile photos and friends) is also intended for public consumption, as is some of your other activity through the Service, like how you
          vote on other people’s contributions (star rating), which contributions you like, where you check-in or where your friends check-in on your behalf, which contributions or users you follow,
          and which businesses you bookmark.
        </p>
      </li>
      <li>
        <p>
          <strong>Communications:</strong> When you sign up for an account or use certain features, you are opting to receive messages from other users, businesses, and Shrpa. You cannot opt out of
          receiving certain administrative, transactional, or legal messages from Shrpa.
          <strong
            >If you exchange messages with others through the Service, we may store them in order to process and deliver them, allow you to manage them, and we may review and disclose them in
            connection with investigations related to use of the Service, as well as our efforts to improve the Service.</strong
          >
          We may not deliver messages that we believe are objectionable, such as spam messages, fraudulent solicitations, or requests to exchange reviews for compensation. If you send or receive
          messages through the Service via SMS text message, we may log phone numbers, phone carriers, and the date and time that the messages were processed. Carriers may charge recipients for texts
          that they receive. We may also store information that you provide through communications to us, including from phone calls, letters, emails and other electronic messages, or in person. If
          you are a representative of a business listed on Shrpa, we may contact you, including by phone or email, using the contact information you provide us, make publicly available, or that we
          have on record for your business. Our calls with you may be monitored and recorded for quality purposes.
        </p>
      </li>

      <li>
        <p>
          <strong>Activity:</strong> We may store information about your use of the Service, such as your search activity, the pages you view, the date and time of your visit, businesses you call
          using our mobile applications, and reservations, purchases, or transactions you make through the Service. We may also store information that your computer or mobile device may provide to us
          in connection with your use of the Service, such as your browser type, type of computer or mobile device, browser language, IP address, WiFi information such as SSID, mobile carrier, phone
          number, unique device identifier, advertising identifier, location (including geolocation, beacon based location, and GPS location), and requested and referring URLs and mobile applications.
          We may also receive and store your location whenever our mobile applications are running, including when running in the background, if you enable our mobile apps to access such information
          in the course of using the Service. You may be able to limit or disallow our use of certain location data through your device or browser settings, for example by adjusting the "Location
          Services" settings for our applications in iOS privacy settings.
        </p>
      </li>
      <li>
        <p>
          <strong>Different Devices:</strong> You may access the Service through different devices (e.g., your mobile phone or personal computer) and different platforms (e.g., the Shrpa website or
          Shrpa mobile apps). The information that we collect and store through those different uses may be cross-referenced and combined, and your contributions through one Shrpa platform will
          typically be similarly visible and accessible through all other Shrpa platforms.
        </p>
      </li>
      <li>
        <p>
          <strong>Legal Basis for Processing:</strong> For residents of the EEA and Switzerland only, our legal basis for collecting and using the information described above will depend on the
          specific information concerned and the context in which we collect it. We, however, will normally collect personal information from you only where we have your consent to do so, where we
          need the personal information to perform a contract with you, or where the processing is in our legitimate interests and not overridden by your data protection interests or fundamental
          rights and freedoms. In some cases, we may also have a legal obligation to collect personal information from you or may otherwise need the personal information to protect your vital
          interests or those of another person (for instance, to prevent, investigate, or identify possible wrongdoing in connection with the Service or to comply with legal obligations). If we ask
          you to provide personal information to comply with a legal requirement or to perform a contract with you, we will make this clear at the relevant time and advise you whether the provision of
          your personal information is mandatory or not (as well as of the possible consequences if you do not provide your personal information). If we collect and use your personal information in
          reliance on our legitimate interests (or those of any third party), this interest will typically be to operate our Services, communicate with you in relation to our Services, or for our
          other legitimate commercial interests, for instance, when responding to your queries, to analyze and improve our platform, engage in marketing, or for the purposes of detecting or preventing
          fraud. If you have questions about or need further information concerning the legal basis on which we collect and use your personal information, please contact us at the address listed below
          in the “Contact” section.
        </p>
      </li>
    </ol>
    <h2 id="cookies">
      <strong>2. COOKIES</strong>
    </h2>
    <p>
      We, and third parties with whom we partner, may use cookies, web beacons, tags, scripts, local shared objects such as HTML5 and Flash (sometimes called "flash cookies"), advertising identifiers
      (including mobile identifiers such as Apple’s IDFA or Google’s Advertising ID) and similar technology (<strong>"Cookies"</strong>) in connection with your use of the Service, third party
      websites, and mobile applications. Cookies may have unique identifiers, and reside, among other places, on your computer or mobile device, in emails we send to you, and on our web pages. Cookies
      may transmit information about you and your use of the Service, such as your browser type, search preferences, IP address, data relating to advertisements that have been displayed to you or that
      you have clicked on, and the date and time of your use. Cookies may be persistent or stored only during an individual session.
    </p>
    <p>The purposes for which we use Cookies in the Service include:</p>
    <table class="table table-bordered">
      <thead>
        <tr class="header">
          <th>
            <strong>Purpose</strong>
          </th>
          <th>
            <strong>Explanation</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="odd">
          <td>Processes</td>
          <td>Intended to make the Service work in the way you expect. For example, we use a Cookie that tells us whether you have already signed up for an account.</td>
        </tr>
        <tr class="even">
          <td>Authentication, Security, and Compliance</td>
          <td>Intended to prevent fraud, protect your data from unauthorized parties, and comply with legal requirements. For example, we use Cookies to determine if you are logged in.</td>
        </tr>
        <tr class="odd">
          <td>Preferences</td>
          <td>
            Intended to remember information about how you prefer the Service to behave and look. For example, we use a Cookie that tells us whether you have declined to allow us to send push
            notifications to your phone.
          </td>
        </tr>
        <tr class="even">
          <td>Notifications</td>
          <td>
            Intended to allow or prevent notices of information or options that we think could improve your use of the Service. For example, we use a Cookie that stops us from showing you the signup
            notification if you have already seen it.
          </td>
        </tr>
        <tr class="odd">
          <td>Advertising</td>
          <td>
            Intended to make advertising more relevant to users and more valuable to advertisers. For example, we may use Cookies to serve you interest-based ads, such as ads that are displayed to you
            based on your visits to other websites, or to tell us if you have recently clicked on an ad.
          </td>
        </tr>
        <tr class="even">
          <td>Analytics</td>
          <td>
            Intended to help us understand how visitors use the Service. For example, we use a Cookie that tells us how our search suggestions correlate to your interactions with the search page.
          </td>
        </tr>
      </tbody>
    </table>
    <p>
      You can set some Cookie preferences through your device or browser settings, but doing so may affect the functionality of the Service. The method for disabling Cookies may vary by device and
      browser, but can usually be found in your device or browser preferences or security settings. For example, iOS and Android devices each have settings which are designed to limit forms of ad
      tracking. For flash cookies, you can manage your privacy settings by clicking
      <a href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html#117118">here</a>. Please note that changing any of these settings does not prevent the display
      of certain advertisements to you.
    </p>
    <h2 id="third-parties">
      <strong>3. THIRD PARTIES</strong>
    </h2>
    <p>Third parties may receive information about you as follows:</p>
    <ol type="a">
      <li>
        <p>
          <strong>Advertisers:</strong> We may allow third parties to use Cookies through the Service to collect the same type of information for the same purposes as we do. In doing so, we adhere to
          the Digital Advertising Alliance’s Self-Regulatory Principles for Online Behavioral Advertising. Third parties may be able to associate the information they collect with other information
          they have about you from other sources. We do not necessarily have access to or control over the Cookies they use, but you may be able to opt out of some of their practices by visiting the
          following links: <a lang="en" hreflang="en" href="http://www.networkadvertising.org/choices/">Network Advertising Initiative</a>,
          <a href="https://www.d1.sc.omtrdc.net/optout.html?omniture=1&amp;popup=1&amp;locale=en_US&amp;second=1&amp;second_has_cookie=0">Omniture</a> and
          <a lang="en" hreflang="en" href="http://www.aboutads.info/choices/">Digital Advertising Alliance</a>. Please note that opting out does not prevent the display of all advertisements to you.
          Additionally, we may share non-personally identifiable information from or about you, such as location data, advertising identifiers, or a cryptographic hash of a common account identifier
          (such as an email address), with third parties in connection with advertising programs and data analytics, including with third parties that help determine the efficacy of such advertising
          programs by aggregating information they receive from multiple services like the Service. You may be able to limit our sharing of some of this information through your mobile device
          settings, as described in Section 2 above, or through the Service’s settings.
        </p>
      </li>
      <li>
        <p>
          <strong>Content Partners:</strong> We allow third party partners to use and display some of the public content available through the Service, such as your photos, reviews, and other
          information detailed in Section 1(b) above.
        </p>
      </li>
      <li>
        <p>
          <strong>Service Providers:</strong> We may rely on third party providers to support or provide some of the services that are available through the Service, such as reservations and food
          delivery. We may also rely on third party providers to perform certain services for us in connection with your use of the Service, such as communications and hosting services, network
          security, technical and customer support, tracking and reporting functions, quality assurance testing, payment processing, our own marketing of the Service, and other functions. We may share
          information from or about you with these third party providers so that they can perform their services or complete your requests. These third party providers may share information with us
          that they obtain from or about you in connection with providing their services or completing your requests. Third party providers may also share this information with their subsidiaries,
          joint ventures, or other companies under common control. Some of our web pages utilize framing techniques to serve content to you from our third party providers, while preserving the look
          and feel of the Service. In such cases, please note that the information you provide is being provided to the third party.
        </p>
      </li>
      <li>
        <p>
          <strong>Aggregate or Anonymous Information:</strong> We may share user information in the aggregate with third parties, such as businesses that are listed on Shrpa and content distributors.
          For example, we may disclose the number of users that have been exposed to or clicked on advertisements. We may also disclose anonymized information about your use on Shrpa. For example, if
          you engage in a transaction in connection with Shrpa, we may publicly disclose information about the transaction without providing additional identifying information about you or otherwise
          disclosing your participation in the transaction.
        </p>
      </li>
      <li>
        <p>
          <strong>Business Transfers:</strong> We may share information from or about you with our parent companies, subsidiaries, joint ventures, or other companies under common control, in which
          case we will require them to honor this Privacy Policy. If another company acquires Shrpa, or all or substantially all of our assets, that company will possess the same information, and will
          assume the rights and obligations with respect to that information as described in this Privacy Policy.
        </p>
      </li>
      <li>
        <p>
          <strong>Businesses on Shrpa:</strong> We may share information from or about you (such as your age, gender, and city), your devices, and your use of the Service (such as which businesses you
          bookmark or call, or if you visit a business’s URL) with businesses listed on Shrpa. Keep in mind that businesses may still see your public activity and posts, and may receive information
          from or about you when you transact or communicate with them, through Shrpa or otherwise, regardless of your settings (see Section 1 above). Additionally, if you make a phone call to a
          business through or in connection with your use of the Service, we may share information about your call with the business that the business would have received had you called them directly,
          such as the date and time of your call and your phone number. You may be able to limit our ability to collect and share your phone number through your phone’s settings or phone service
          provider.
        </p>
      </li>
      <li>
        <p>
          <strong>Investigations and Legal Disclosures:</strong> We may investigate and disclose information from or about you if we have a good faith belief that such investigation or disclosure: (a)
          is reasonably necessary to comply with legal processes and law enforcement instructions and orders, such as a search warrant, subpoena, statute, judicial proceeding, or other legal process
          or law enforcement requests served on us; (b) is helpful to prevent, investigate, or identify possible wrongdoing in connection with the Service; or (c) protects our rights, reputation,
          property, or that of our users, affiliates, or the public, such as disclosures in connection with Shrpa's Consumer Alerts program. If you flag or otherwise complain to us about content
          through the Service, we may share the substance of your complaint with the contributor of that content in order to provide an opportunity for the contributor to respond.
        </p>
      </li>
      <li>
        <p>
          <strong>Links:</strong> The Service may link to third party services, like a business’s URL. Except as set forth herein, we do not share your personal information with them, and are not
          responsible for their privacy practices. We suggest you read the privacy policies on or applicable to all such third party services.
        </p>
      </li>
      <li>
        <p>
          <strong>Third Party Accounts:</strong> If you sign up for, or log into, Shrpa using a third party service like Facebook or Google, or link your Shrpa account to your account with a third
          party service like Facebook, Instagram or Twitter, we may receive information about you from such third party service. If you post content to a third party service through the Service, that
          third party service will also receive that content, which will be visible to anyone that has access to it through that third party service.
        </p>
      </li>
    </ol>
    <h2 id="control-personal-data">
      <strong>4. CONTROLLING YOUR PERSONAL DATA</strong>
    </h2>
    <p>
      Other users may be able to identify you, or associate you with your account, if you include personal information in the content you post publicly. You can reduce the risk of being personally
      identified by using the Service pseudonymously, though doing so could detract from the credibility of your contributions to the Service. Please also note that the messages you send or receive
      using the Service are only private to the extent that both you and the person you are communicating with keep them private. For example, if you send a message to another user, that user may
      choose to publicly post it. Also, we may access, review, and disclose such messages in connection with investigations related to use of the Service, as well as our efforts to improve the
      Service.
    </p>
    <p>
      If you are a resident of the EEA or Switzerland, you have the right to access, correct, update or request deletion of your personal information. You can object to the processing of your personal
      information, ask us to restrict processing of your personal information or request portability of your personal information. Similarly, if we have collected and processed your personal
      information with your consent, then you can withdraw your consent at any time. Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal,
      nor will it affect processing of your personal information conducted in reliance on lawful processing grounds other than consent. You also have the right to opt-out of marketing communications
      we send you at any time.
    </p>
    <h2 id="data-retention">
      <strong>5. DATA RETENTION AND ACCOUNT TERMINATION</strong>
    </h2>
    <p>
      You can close your account by contacting Shrpa at hello@shrpa.com. We will remove certain public posts from view and/or dissociate them from your account profile, but we may retain information
      about you for the purposes authorized under this Privacy Policy unless prohibited by law. For example, we may retain information to prevent, investigate, or identify possible wrongdoing in
      connection with the Service or to comply with legal obligations. Please note that businesses cannot remove their business listings, ratings, or reviews by closing their accounts.
    </p>
    <h2 id="children">
      <strong>6. CHILDREN</strong>
    </h2>
    <p>
      The Service is intended for general audiences and is not directed to children under 13. We do not knowingly collect personal information from children under 13. If you become aware that a child
      has provided us with personal information without parental consent, please contact us at hello@shrpa.com. If we become aware that a child under 13 has provided us with personal information
      without parental consent, we take steps to remove such information and terminate the child's account.
    </p>
    <h2 id="security">
      <strong>7. SECURITY</strong>
    </h2>
    <p>
      We use various safeguards to protect the personal information submitted to us, both during transmission and once we receive it. However, no method of transmission over the Internet or via mobile
      device, or method of electronic storage, is 100% secure. Therefore, while we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute
      security.
    </p>
    <h2 id="contact-information">
      <strong>8. CONTACT</strong>
    </h2>
    <p>You may contact us at hello@shrpa.com concerning our Privacy Policy, or write to us at the following address:</p>

    <p>
      Shrpa, Attn: Data Privacy Manager<br />
      14 4th St SW Suite 203<br />
      Rochester, MN 55902
    </p>

    <p>
      We will take such steps as we deem necessary to confirm your identity before sharing any personal data with you. We will respond to proper and confirmed requests relating to personal data within
      30 days, or as otherwise required by applicable law.
    </p>
    <h2 id="modifications">
      <strong>9. MODIFICATIONS TO THIS PRIVACY POLICY</strong>
    </h2>
    <p>
      We may revise this Privacy Policy from time to time. The most current version of the Privacy Policy will govern our collection, use, and disclosure of information about you and will be located
      here. If we make material changes to this Privacy Policy, we will notify you by email or by posting a notice on the Service prior to or on the effective date of the changes. By continuing to
      access or use the Service after those changes become effective, you acknowledge the revised Privacy Policy.
    </p>
    <h2 id="california-residents">
      <strong>10. CALIFORNIA RESIDENTS: YOUR CALIFORNIA PRIVACY RIGHTS</strong>
    </h2>
    <p>
      We do not disclose your personal information to third parties for the purpose of directly marketing their goods or services to you unless you first agree to such disclosure. If you have any
      questions regarding this policy, or would like to change your preferences, you may contact us at the address listed above in Section 8.
    </p>
    <h2 id="international-data-transfer">
      <strong>11. INTERNATIONAL DATA TRANSFER</strong>
    </h2>
    <p>
      For residents of the EEA or Switzerland, please note that the personal data information we obtain from or about you may be transferred, processed and stored outside of the EEA or Switzerland for
      the purposes described in this Privacy Policy, including in the United States of America. We take the privacy of our users seriously and therefore take steps to safeguard your information,
      including ensuring an adequate level of data protection in accordance with E.U. standards. These include implementing the European Commission’s Standard Contractual Clauses for transfers of
      personal information between our group companies, which require all group companies to protect personal information they process from the EEA in accordance with European Union data protection
      laws. We have implemented similar appropriate safeguards with our third party service providers and partners and further details can be provided upon request.
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useHead } from "@unhead/vue";

export default defineComponent({
  name: "Privacy",

  mounted() {
    useHead({ title: "Privacy Policy - Shrpa" });
  },
});
</script>
