<template>
  <div class="progress-bar-wrapper">
    <ul class="progress-bar" @click="$emit('clicked')" style="cursor: pointer">
      <template v-for="(step, index) in getStepsSummary()" :key="index">
        <li v-if="step" class="active">{{ shortenTitle(step) }}</li>
        <li v-else class="active" :key="index">({{ notShownStopCount }} more)</li>
      </template>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ItineraryPreview } from "@contracts/itinerary";

export default defineComponent({
  name: "DestinationsBar",

  props: {
    itinerary: {
      type: Object as () => ItineraryPreview,
      required: true,
    },
  },

  emits: ["clicked"],

  data() {
    return {
      maxStopsToShowInBullets: 4,
    };
  },

  computed: {
    notShownStopCount(): number {
      if (!this.itinerary.stepNames) {
        return 0;
      }
      return this.itinerary.stepNames?.length - this.maxStopsToShowInBullets + 1;
    },
  },

  methods: {
    shortenTitle: function (fullTitle) {
      var maxLength = 30;
      if (fullTitle.length <= maxLength) return fullTitle;

      var trimmedString = fullTitle.substr(0, maxLength);
      return trimmedString + " ...";
    },
    getStepsSummary: function () {
      if (!this.itinerary || !this.itinerary.stepNames) return [];
      if (this.itinerary.stepNames.length <= this.maxStopsToShowInBullets) return this.itinerary.stepNames;
      // If more than 4 steps add the first two, null for ellipsis, and last
      var summary = [this.itinerary.stepNames[0], this.itinerary.stepNames[1], null, this.itinerary.stepNames[this.itinerary.stepNames.length - 1]];
      return summary;
    },
  },
});
</script>

<style scoped></style>
