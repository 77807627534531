<template>
  <div class="step-intro">
    <!-- Header -->
    <div class="header step-intro__header">
      <h1 class="global-h1 header__h1">Kickstart your Creator Visit!</h1>
      <div class="header__subtext">
        <p style="padding: 0; margin: 0 0 10px 0">
          What type of creator visit would you like to get started?<br />
          There are an endless number of ways to highlight your destination!
        </p>

        <LinkWithIcon
          :class="{
            'header__how-it-works-link': true,
            'header__how-it-works-link--with-bottom-margin': isHowItWorksOpened,
          }"
          is-dotted-underline
          color="teal"
          @click="isHowItWorksOpened = !isHowItWorksOpened"
        >
          <template #icon><IconEmbedded name="bulb_2" :size="22" /></template>
          <span>How do Creator Visits work?</span>
          <IconEmbedded v-if="!isHowItWorksOpened" name="caret-bottom_2-5" :size="21" />
          <IconEmbedded v-else name="caret-top_2-5" :size="21" />
        </LinkWithIcon>

        <div
          :class="{
            'header__steps-guide-wrap': true,
            'header__steps-guide-wrap--bigger-bottom-padding': isHowItWorksOpened,
          }"
        >
          <TransitionedHeightAuto class="header__steps-guide" :isOpened="isHowItWorksOpened">
            <template #scoped>
              <StepsSummary />
            </template>
          </TransitionedHeightAuto>
        </div>
      </div>
    </div>
    <!-- / Header -->

    <div class="step-intro__section">
      <h2 class="global-h2 step-intro__section-title">Common Types</h2>
      <ul class="step-intro__section-content">
        <li class="step-intro__icon-snippet-wrap">
          <!-- Icon snippet -->
          <div class="icon-snippet step-intro__icon-snippet" :class="{ 'icon-snippet__chosen': collabInput?.themeType === 'outdoors' }" @click="selectThemeType('outdoors')">
            <img class="icon-snippet__icon" :src="getThemeIcon('outdoors')" :alt="getThemeTypeName('outdoors')" />
            <div class="icon-snippet__text-wrap">
              <h5 class="global-h5 icon-snippet__title">Outdoors</h5>
            </div>
          </div>
          <!-- / Icon snippet -->
        </li>
        <li class="step-intro__icon-snippet-wrap">
          <!-- Icon snippet -->
          <div class="icon-snippet step-intro__icon-snippet" :class="{ 'icon-snippet__chosen': collabInput?.themeType === 'town' }" @click="selectThemeType('town')">
            <img class="icon-snippet__icon" :src="getThemeIcon('town')" :alt="getThemeTypeName('town')" />
            <div class="icon-snippet__text-wrap">
              <h5 class="global-h5 icon-snippet__title">Exploring Town</h5>
            </div>
          </div>
          <!-- / Icon snippet -->
        </li>
        <li class="step-intro__icon-snippet-wrap">
          <!-- Icon snippet -->
          <div class="icon-snippet step-intro__icon-snippet" :class="{ 'icon-snippet__chosen': collabInput?.themeType === 'foodie' }" @click="selectThemeType('foodie')">
            <img class="icon-snippet__icon" :src="getThemeIcon('foodie')" :alt="getThemeTypeName('foodie')" />
            <div class="icon-snippet__text-wrap">
              <h5 class="global-h5 icon-snippet__title">Foodie/Drinks Tour</h5>
            </div>
          </div>
          <!-- / Icon snippet -->
        </li>
        <li class="step-intro__icon-snippet-wrap">
          <!-- Icon snippet -->
          <div class="icon-snippet step-intro__icon-snippet" :class="{ 'icon-snippet__chosen': collabInput?.themeType === 'seasonal' }" @click="selectThemeType('seasonal')">
            <img class="icon-snippet__icon" :src="getThemeIcon('seasonal')" :alt="getThemeTypeName('seasonal')" />
            <div class="icon-snippet__text-wrap">
              <h5 class="global-h5 icon-snippet__title">Seasonal</h5>
            </div>
          </div>
          <!-- / Icon snippet -->
        </li>
      </ul>
    </div>

    <div class="step-intro__section">
      <h2 class="global-h2 step-intro__section-title">Perspective Based</h2>
      <ul class="step-intro__section-content">
        <li class="step-intro__icon-snippet-wrap">
          <!-- Icon snippet -->
          <div class="icon-snippet step-intro__icon-snippet" :class="{ 'icon-snippet__chosen': collabInput?.themeType === 'family' }" @click="selectThemeType('family')">
            <img class="icon-snippet__icon" :src="getThemeIcon('family')" :alt="getThemeTypeName('family')" />
            <div class="icon-snippet__text-wrap">
              <h5 class="global-h5 icon-snippet__title">Family</h5>
            </div>
          </div>
          <!-- / Icon snippet -->
        </li>
        <li class="step-intro__icon-snippet-wrap">
          <!-- Icon snippet -->
          <div class="icon-snippet step-intro__icon-snippet" :class="{ 'icon-snippet__chosen': collabInput?.themeType === 'guysgals' }" @click="selectThemeType('guysgals')">
            <img class="icon-snippet__icon" :src="getThemeIcon('guysgals')" :alt="getThemeTypeName('guysgals')" />
            <div class="icon-snippet__text-wrap">
              <h5 class="global-h5 icon-snippet__title">Gals/Guys Trip</h5>
            </div>
          </div>
          <!-- / Icon snippet -->
        </li>
        <li class="step-intro__icon-snippet-wrap">
          <!-- Icon snippet -->
          <div class="icon-snippet step-intro__icon-snippet" :class="{ 'icon-snippet__chosen': collabInput?.themeType === 'couple' }" @click="selectThemeType('couple')">
            <img class="icon-snippet__icon" :src="getThemeIcon('couple')" :alt="getThemeTypeName('couple')" style="position: relative; top: -10px" />
            <div class="icon-snippet__text-wrap">
              <h5 class="global-h5 icon-snippet__title">Couple's Trip</h5>
            </div>
          </div>
          <!-- / Icon snippet -->
        </li>
        <li class="step-intro__icon-snippet-wrap">
          <!-- Icon snippet -->
          <div class="icon-snippet step-intro__icon-snippet" @click="selectThemeType(null)">
            <img class="icon-snippet__icon" :src="getThemeIcon('anyone')" :alt="getThemeTypeName('anyone')" />
            <div class="icon-snippet__text-wrap">
              <h5 class="global-h5 icon-snippet__title">Anyone</h5>
            </div>
          </div>
          <!-- / Icon snippet -->
        </li>
      </ul>
    </div>

    <div class="step-intro__section">
      <ul class="step-intro__section-content">
        <li
          :class="{
            'step-intro__icon-snippet-wrap': true,
            'step-intro__icon-snippet-wrap--wide': true,
            'step-intro__icon-snippet-wrap--horizontal': screenSize === 'mobile',
          }"
        >
          <!-- Icon snippet -->
          <div
            @click="selectThemeType('event')"
            :class="{
              'icon-snippet': true,
              'icon-snippet__chosen': collabInput?.themeType === 'event',
              'icon-snippet--horizontal': screenSize === 'mobile',
              'step-intro__icon-snippet': true,
            }"
          >
            <img class="icon-snippet__icon" :src="getThemeIcon('event')" :alt="getThemeTypeName('event')" />
            <div
              :class="{
                'icon-snippet__text-wrap': true,
                'icon-snippet__text-wrap--left-aligned': screenSize === 'mobile',
              }"
            >
              <h3 class="global-h3 icon-snippet__title">Event Focused</h3>
              <div
                :class="{
                  'icon-snippet__text': true,
                  'icon-snippet__text--left-aligned': screenSize === 'mobile',
                }"
              >
                Have a creator experience and highlight a local event
              </div>
            </div>
          </div>
          <!-- / Icon snippet -->
        </li>
        <li
          :class="{
            'step-intro__icon-snippet-wrap': true,
            'step-intro__icon-snippet-wrap--wide': true,
            'step-intro__icon-snippet-wrap--horizontal': screenSize === 'mobile',
          }"
        >
          <!-- Icon snippet -->
          <div
            @click="selectThemeType('location')"
            :class="{
              'icon-snippet': true,
              'icon-snippet__chosen': collabInput?.themeType === 'location',
              'icon-snippet--horizontal': screenSize === 'mobile',
              'step-intro__icon-snippet': true,
            }"
          >
            <img class="icon-snippet__icon" :src="getThemeIcon('location')" :alt="getThemeTypeName('location')" />
            <div
              :class="{
                'icon-snippet__text-wrap': true,
                'icon-snippet__text-wrap--left-aligned': screenSize === 'mobile',
              }"
            >
              <h3 class="global-h3 icon-snippet__title">Location Focused</h3>
              <div
                :class="{
                  'icon-snippet__text': true,
                  'icon-snippet__text--left-aligned': screenSize === 'mobile',
                }"
              >
                <span>Spend more time at fewer locations</span><br />
                <div style="margin-bottom: 7px"></div>
                <b>Ex:</b> Local Fair or Trail Loop
              </div>
            </div>
          </div>
          <!-- / Icon snippet -->
        </li>
      </ul>

      <NoteWithIcon class="step-intro__note" color="blue" style="margin-top: 12px">
        <!--<template #icon><IconEmbedded name="info-simple_4" /></template>-->
        <template #text>
          <div>
            <h5 class="global-h5" style="display: inline; margin-right: 10px">None of these a fit?</h5>
            <SrpButton size="small" fill="outlined" @click="selectThemeType(null)">Just get started!</SrpButton>
          </div>
        </template>
      </NoteWithIcon>
    </div>

    <!--<div class="step-intro__section">-->
    <!--  <h2 class="global-h2 step-intro__section-title step-intro__section-title&#45;&#45;bigger-bottom-margin">Use a creator you've previously seen</h2>-->
    <!--  <div class="step-intro__section-content step-intro__section-content&#45;&#45;overflow-visible">-->
    <!--    <ul class="step-intro__creators-list">-->
    <!--      &lt;!&ndash; Creator snippet &ndash;&gt;-->
    <!--      <li class="creator-snippet step-intro__creator-snippet" v-for="(img, index) in exampleCreatorImages" :key="img">-->
    <!--        <AvatarWithFallback class="creator-snippet__avatar" :src="img" />-->
    <!--        <div class="creator-snippet__name">-->
    <!--          {{ ["Irene & Family", "James Dean", "Elisabeth"][index] }}-->
    <!--        </div>-->
    <!--      </li>-->
    <!--      &lt;!&ndash; / Creator snippet &ndash;&gt;-->
    <!--    </ul>-->
    <!--  </div>-->
    <!--</div>-->

    <div v-if="collabInput?.id == null && collabSummariesRecentFirst.length" class="step-intro__section">
      <h2 class="global-h2 step-intro__section-title step-intro__section-title--smaller-bottom-margin">Clone a previous Visit</h2>
      <div class="step-intro__section-subtitle">Copy a previous visit's guidance so you can submit with a new date and creator.</div>
      <div class="step-intro__section-content">
        <ul class="step-intro__collabs-list">
          <li class="step-intro__collab-snippet-wrap" v-for="collab in collabSummariesRecentFirst.slice(0, howManyCollabsToShow)" :key="collab.collabInputId">
            <CollabSnippetV2 class="step-intro__collab-snippet" :collab="collab" @click="emit('cloneCollab', collab.collabInputId)" />
          </li>
        </ul>
      </div>

      <LinkWithIcon v-if="collabSummariesRecentFirst?.length > 3" class="additional-links__link" @click="toggleCollabsList" is-dotted-underline iconInset="1px auto auto 2px" style="margin-left: -5px">
        <template #icon>
          <IconEmbedded v-if="howManyCollabsToShow === 3" name="caret-bottom_4" :size="13" />
          <IconEmbedded v-else name="caret-top_4" :size="13" />
        </template>
        <span>Show {{ howManyCollabsToShow === 3 ? "more" : "less" }}</span>
      </LinkWithIcon>
    </div>

    <div class="step-intro__note-wrap">
      <NoteWithIcon class="step-intro__note" color="green">
        <template #icon><IconEmbedded name="info-simple_4" /></template>
        <template #text>
          <div>
            <h5 class="global-h5">Questions or not sure where to start?</h5>
            <span>We can help! Reach out any time to <CopyTextNew>collabs@shrpa.com</CopyTextNew></span>
          </div>
        </template>
      </NoteWithIcon>
    </div>

    <!-- Additional links -->
    <div class="additional-links step-intro__additional-links">
      <LinkWithIcon class="additional-links__link" tag="a" target="_blank" :href="globalGetLink('CommunityCollabInspire')">
        <template #icon><IconEmbedded name="bulb_2" :size="22" /></template>
        <span>Get inspiration</span>
      </LinkWithIcon>

      <LinkWithIcon class="additional-links__link" tag="a" target="_blank" :href="globalGetLink('CommunityPaidCollabHelp')">
        <template #icon><IconEmbedded name="lifebuoy_2" :size="21" /></template>
        <span>View Additional Guidance</span>
      </LinkWithIcon>
    </div>
    <!-- / Additional links -->
  </div>
</template>

<!-- prettier-ignore -->
<script lang="ts">export default { name: "StepIntro" };</script>

<script setup lang="ts">
import axios from "axios";
import { inject, Ref, ref, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

// Types
import { CollabInput } from "@contracts/collab";
import { ScreenSize } from "@contracts/screenSize";

// Components
import CollabSnippetV2 from "@components/collabSnippets/CollabSnippetV2.vue";
import CopyTextNew from "@components/CopyTextNew.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import StepsSummary from "@views/PaidCollab/CreateCollab/StepsSummary.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

// Stores
import { useCollabSummariesStore } from "@stores/collabSummaries";
import TransitionedHeightAuto from "@components/ui/TransitionedHeightAuto.vue";
import { getThemeIcon, getThemeTypeName } from "@helpers/ThemeTypeHelper";
const collabSummariesStore = useCollabSummariesStore();

interface Props {
  collabInput: CollabInput | null;
}
const props = withDefaults(defineProps<Props>(), {
  collabInput: null,
  errors: { type: Array<string>, required: true },
});

const emit = defineEmits<{
  (e: "changeStep"): void;
  (e: "cloneCollab", id: string): void;
  (e: "mount"): void;
}>();

const route = useRoute();

const globalGetLink = inject("globalGetLink") as any;
const screenSize = inject("screenSize") as Ref<ScreenSize>;

// Load creator photo examples ================================================
const exampleCreatorImages = ref([]);
async function loadExampleCreatorImages(identifier) {
  const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/community/${identifier}/creator-match-summary?creatorExampleCount=3`);
  exampleCreatorImages.value = data.exampleCreatorImages;
}

onMounted(async () => {
  await loadExampleCreatorImages(route.params.communityId);
  emit("mount");
});

// Load collab summaries ======================================================
onMounted(async () => {
  const communityId = String(route.params.communityId);
  // Loading draft collabs for everyone now
  const justLoadCompletedCollabs = false;
  await collabSummariesStore.loadAllCollabSummaries(communityId, justLoadCompletedCollabs, false, false);
});

const collabSummariesRecentFirst = computed(() => {
  return collabSummariesStore.allCollabSummaries.slice().sort((a, b) => {
    // nulls sort after anything else
    if (a.completedDate === null) return 1;
    if (b.completedDate === null) return -1;
    return b.completedDate - a.completedDate;
  });
});

function selectThemeType(themeType: string) {
  props.collabInput.themeType = themeType;
  emit("changeStep");
}

// Toggle collabs list ========================================================
const howManyCollabsToShow = ref(3);

function toggleCollabsList(): void {
  howManyCollabsToShow.value = howManyCollabsToShow.value === 3 ? 100 : 3;
}

// ============================================================================
const isHowItWorksOpened = ref(false);
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Additional links ===========================================================
.additional-links {
  &__link {
    margin-right: 25px;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .additional-links {
    display: flex;
    flex-direction: column;

    &__link {
      &:first-child {
        margin-bottom: 11px;
      }
    }
  }
}

// Header =====================================================================
.header {
  &__h1 {
    margin-bottom: 5px;
    text-wrap: balance;
  }

  &__subtext {
    color: #5b5b5b;
    font-family: sans-serif;
  }

  &__how-it-works-link {
    &--with-bottom-margin {
      margin-bottom: 20px;
    }
  }

  &__steps-guide-wrap {
    padding-bottom: 25px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;

    &--bigger-bottom-padding {
      padding-bottom: 33px;
    }
  }

  &__steps-guide {
    padding: 20px 20px 24px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 6px;
  }
}

// Icon snippet ===============================================================
.icon-snippet {
  padding: 5px 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 6px;
    position: absolute;
    inset: 0 auto auto 0;
    transform: scale(0.92);
    background: rgba(0, 0, 0, 0.07);
    opacity: 0;
    pointer-events: none;
    transition:
      transform 0.05s ease-in-out,
      opacity 0.05s ease-in-out;
  }

  &__chosen {
    opacity: 1 !important;
    border: 2px solid #00b4b4;
    border-radius: 6px;
  }

  &:hover::before {
    transform: scale(1);
    opacity: 1;
  }

  &--horizontal {
    padding-top: 15px;
    flex-direction: row;
  }

  &__icon {
    width: 15vw;
    max-width: 110px;
  }

  &__text-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &--left-aligned {
      align-items: flex-start;
    }
  }

  &__title {
    margin-bottom: 12px;
    text-align: center;
    text-wrap: balance;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__text {
    width: 80%;
    max-width: 250px;
    color: #5b5b5b;
    font: 14px/18px sans-serif;
    text-align: center;
    text-wrap: balance;

    &--left-aligned {
      text-align: left;
    }
  }
}

// Creator snippet ============================================================
.creator-snippet {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  &:hover {
  }

  &__avatar {
    width: 100px;
    max-width: calc(100% - 20px);
    margin-bottom: 7px;
    transform: scale(1);
    transition: transform 0.05s ease-in-out;
  }
  &:hover &__avatar {
    transform: scale(1.07);
  }

  &__name {
    font-weight: 600;
    line-height: 17px;
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.15);
    text-underline-offset: 2px;
    text-decoration-thickness: 1px;
    text-align: center;
  }
  &:hover &__name {
    text-decoration: none;
  }
}

// Step intro =================================================================
.step-intro {
  display: flex;
  flex-direction: column;

  &__header {
    margin-bottom: 25px;
  }

  &__section {
    padding-bottom: 15px;
    margin-bottom: 25px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__section-title {
    margin-bottom: 10px;

    &--bigger-bottom-margin {
      margin-bottom: 20px;
    }

    &--smaller-bottom-margin {
      margin-bottom: 5px;
    }
  }

  &__section-subtitle {
    margin-bottom: 25px;
    color: #5b5b5b;
    font: 14px/17px sans-serif;
  }

  &__section-content {
    padding: 0;
    margin: -6px;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    position: relative;
    overflow: hidden;

    &--overflow-visible {
      overflow: visible;
    }
  }

  &__icon-snippet-wrap {
    width: 25%;
    padding: 6px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &::before {
      content: "";
      width: 1px;
      height: 40%;
      position: absolute;
      inset: 30% -2px auto auto;
      background: rgba(0, 0, 0, 0.1);
    }

    &::after {
      content: "";
      width: 40%;
      height: 1px;
      position: absolute;
      inset: auto auto -2px 30%;
      background: rgba(0, 0, 0, 0.1);
    }

    &--wide {
      width: 50%;
    }
  }

  &__icon-snippet {
    width: 100%;
    height: 100%;
  }

  &__creators-list {
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
  }

  &__creator-snippet {
    width: 20%;
    min-width: 20%;
    margin-bottom: 20px;
  }

  &__collabs-list {
    padding: 0;
    margin: 6px 6px 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  &__collab-snippet-wrap {
    width: calc(33.3% - 22px);
    margin: 0 22px 31px 0;
    position: relative;

    &::before {
      content: "Clone";
      width: 80px;
      height: 30px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      inset: 40px auto auto calc(50% - 50px);
      z-index: 2;
      color: #fff;
      font: 14px/14px sans-serif;
      text-align: center;
      background: rgba(17, 134, 137, 1);
      opacity: 0;
      //backdrop-filter: blur(5px); // Caused issues when lots of photos
      pointer-events: none;
      transition: opacity 0.07s ease-in-out;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }

  &__collab-snippet {
    opacity: 1;
    transition: opacity 0.05s ease-in-out;
    cursor: pointer;
    user-select: none;
  }

  &__note-wrap {
    margin-bottom: 20px;
  }

  &__note {
  }

  &__additional-links {
    margin-bottom: 10px;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .step-intro {
    &__creator-snippet {
      width: 25%;
      min-width: 25%;
    }

    &__collab-snippet-wrap {
      width: calc(50% - 22px);
      margin: 0 22px 31px 0;

      &::before {
        left: calc(50% - 98px);
      }

      &:nth-child(3n) {
        margin-right: 22px;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .step-intro {
    &__icon-snippet-wrap {
      width: 33.3%;

      &--wide {
        width: 100%;
        padding: 6px 0;

        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          padding-bottom: 0;
        }
      }
    }

    &__creator-snippet {
      width: 33.3%;
      min-width: 33.3%;
    }

    &__collab-snippet-wrap {
      width: calc(50% - 22px);
      margin: 0 22px 31px 0;

      &::before {
        top: 18px;
        left: calc(50% - 55px);
      }

      &:nth-child(3n) {
        margin-right: 22px;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
</style>
