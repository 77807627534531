import axios from "axios";
import { ref } from "vue";
import { defineStore } from "pinia";

// Types
import { Itinerary } from "@contracts/itinerary";
import { CreatorPublicProfileFields } from "@contracts/creatorPublicProfileFields";

export const useCreatorPublicProfileStore = defineStore("creatorPublicProfile", () => {
  const isLoading = ref<boolean>(false);

  const creator = ref<CreatorPublicProfileFields>({} as CreatorPublicProfileFields);
  const itineraries = ref<Array<Itinerary>>([]);
  const collabSummaries = ref<Array<any>>([]);
  const nonCollabAdventures = ref<Array<any>>([]);

  async function loadCreatorSummary(id) {
    isLoading.value = true;
    const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/sherpas/${id}`);
    creator.value = data.sherpa;
    itineraries.value = data.itineraries;
    isLoading.value = false;
  }

  async function loadCreatorSummaryV2(id) {
    isLoading.value = true;
    const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/sherpas/${id}/v2`);
    creator.value = data.sherpa;
    collabSummaries.value = data.collabSummaries;
    nonCollabAdventures.value = data.nonCollabAdventures;
    isLoading.value = false;
  }

  return {
    isLoading,
    creator,
    itineraries,
    collabSummaries,
    nonCollabAdventures,

    loadCreatorSummary,
    loadCreatorSummaryV2,
  };
});
