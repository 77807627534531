<template>
  <div class="search-and-filters">
    <div class="search-and-filters__section search-and-filters__section--search search-and-filters__section--flex-grow-1">
      <!-- Section title -->
      <div class="global-h5 section-title search-and-filters__section-title">
        <span class="section-title__search-word">Search</span> <span class="section-title__search-by-words">by {{ props.groupTypeText }} name</span>
      </div>
      <!-- / Section title -->
      <div class="search-and-filters__section-content">
        <slot name="searchInput" />
      </div>
    </div>
    <div class="search-and-filters__section search-and-filters__section--source">
      <div class="global-h5 search-and-filters__section-title">Source</div>
      <div class="search-and-filters__section-content">
        <SrpCheckbox size="tiny" :isWithHalo="false">
          <template #input><slot name="creatorVisitsCheckbox" /></template>
          <template #text>{{ useNewCollabLogic ? "Creator Visit" : "Adventure" }}</template>
        </SrpCheckbox>
        <SrpCheckbox size="tiny" :isWithHalo="false">
          <template #input><slot name="communityUploadsCheckbox" /></template>
          <template #text>Community Uploads</template>
        </SrpCheckbox>
        <SrpCheckbox size="tiny" :isWithHalo="false">
          <template #input><slot name="myUploadsCheckbox" /></template>
          <template #text>My Uploads</template>
        </SrpCheckbox>
      </div>
    </div>
    <div class="search-and-filters__section search-and-filters__section--content-type">
      <div class="global-h5 search-and-filters__section-title">Content type</div>
      <div class="search-and-filters__section-content">
        <SrpCheckbox size="tiny" :isWithHalo="false">
          <template #input><slot name="photosCheckbox" /></template>
          <template #text>Photos</template>
        </SrpCheckbox>
        <SrpCheckbox size="tiny" :isWithHalo="false">
          <template #input><slot name="videosCheckbox" /></template>
          <template #text>Videos</template>
        </SrpCheckbox>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// Components
import SrpCheckbox from "@components/ui/SrpCheckbox.vue";

const props = withDefaults(
  defineProps<{
    useNewCollabLogic: boolean;
    groupTypeText: string;
  }>(),
  {
    useNewCollabLogic: false,
    groupTypeText: "",
  }
);

const emit = defineEmits<{
  (e: "update:searchValue", value: string): void;
}>();
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Section title ==============================================================
.section-title {
  &__search-word {
  }

  &__search-by-words {
    font-weight: 400;
    white-space: nowrap;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .section-title {
    &__search-by-words {
      display: block;
      white-space: wrap;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
}

// Search & Filters ===========================================================
.search-and-filters {
  gap: 27px;
  padding: 20px 0 25px;
  display: flex;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    width: calc(100% + 50px);
    height: 100%;
    border-radius: 6px;
    position: absolute;
    inset: 0 auto auto -25px;
    z-index: -1;
    background: rgba(232, 232, 232, 1);
  }

  &__section {
    padding-right: 27px;
    border: 1px rgba(0, 0, 0, 0.15) solid;
    border-width: 0 1px 0 0;

    &:last-child {
      padding-right: 0;
      border-right: none;
    }

    &--search {
      flex-grow: 1;
    }
    &--source {
    }
    &--content-type {
    }
  }

  &__section-title {
    margin-bottom: 8px;
  }

  &__section-content {
    min-height: 40px;
    gap: 15px 30px;
    display: flex;
    flex-wrap: wrap;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .search-and-filters {
    &__section {
      &--search {
        width: 375px;
        max-width: 375px;
      }
      &--source {
      }
      &--content-type {
      }
    }
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .search-and-filters {
    &__section {
      &--search {
        width: 285px;
        min-width: 285px;
        max-width: 285px;
      }
      &--source {
      }
      &--content-type {
      }
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .search-and-filters {
    gap: 15px;
    flex-direction: column;

    &::before {
      content: "";
      width: calc(100% + 50px);
      height: 100%;
      border-radius: 6px;
      position: absolute;
      inset: 0 auto auto -25px;
      z-index: -1;
      background: rgba(232, 232, 232, 1);
    }

    &__section {
      width: 100%;
      padding: 0 0 17px;
      border-width: 0 0 1px 0;

      &:last-child {
        padding: 0;
        border: none;
      }
    }

    &__section-content {
      min-height: unset;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .search-and-filters {
    gap: 15px;
    flex-direction: column;

    &::before {
      content: "";
      width: calc(100% + 50px);
      height: 100%;
      border-radius: 6px;
      position: absolute;
      inset: 0 auto auto -25px;
      z-index: -1;
      background: rgba(232, 232, 232, 1);
    }

    &__section {
      width: 100%;
      padding: 0 0 17px;
      border-width: 0 0 1px 0;

      &:last-child {
        padding: 0;
        border: none;
      }
    }

    &__section-content {
      min-height: unset;
    }
  }
}
</style>
